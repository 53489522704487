define('due-dashboard/initializers/asset-map-load', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = {
    name: 'asset-map-load',

    initialize: function initialize(application) {
      application.deferReadiness();
      _ember['default'].$.get('/assets/assetMap.json').done(function (data) {
        var asset_mapper = {
          _map: data,

          asset_url: function asset_url(asset_name) {
            var prepend = 'assets/';
            var rel_url = this._map.assets[prepend + asset_name];

            if (!rel_url) {
              throw 'Asset not found: ' + asset_name;
            }
            return '/' + rel_url;
          }
        };

        application.register('asset-mapper:main', asset_mapper, { instantiate: false });
        application.inject('route', 'asset_mapper', 'asset-mapper:main');
        application.inject('controller', 'asset_mapper', 'asset-mapper:main');
        application.inject('model', 'asset_mapper', 'asset-mapper:main');
        application.inject('component', 'asset_mapper', 'asset-mapper:main');
        application.inject('object', 'asset_mapper', 'asset-mapper:main');
      })
      // .fail(function(e) {
      //   throw e;
      // })
      .always(function () {
        application.advanceReadiness();
      });
    }
  };
});