define('due-dashboard/components/tags/breakdown-item-td', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    showSubTags: false,
    tagName: '',

    /**
     * Find range in ref values
     * @param {Integer} value Feeling value
     * @param {Object} ref Key & Value range
     * @param {*} nullValue Value to return if value is null
     * @return {String} Key from ref object
     */
    _buildFeelingRef: function _buildFeelingRef(value, ref, nullValue) {
      if (value === null) return nullValue;
      for (var mention in ref) {
        if (value < ref[mention]) return mention;
      }
      return nullValue;
    },

    /** Get current color to display for each  range */
    feelingColor: _ember['default'].computed('avg_feeling', function () {
      return this._buildFeelingRef(this.get('avg_feeling'), { 'red': -0.1, 'orange': 0.2, 'green': 1.1 }, 'no-data');
    }),

    /** Get current mention to display for each range */
    feelingMention: _ember['default'].computed('avg_feeling', function () {
      return this._buildFeelingRef(this.get('avg_feeling'), { 'very_negative': -0.5, 'negative': -0.1, 'neutral': 0.2, 'positive': 0.5, 'very_positive': 1.1 }, 'no_result');
    }),

    /** Replace null value for avg_feeling by 0 */
    realAvgFeeling: _ember['default'].computed('avg_feeling', function () {
      return this.get('avg_feeling') || 0;
    }),

    actions: {
      toggleSubTags: function toggleSubTags(event) {
        this.toggleProperty('showSubTags');
        event.stopPropagation();
      }
    }
  });
});