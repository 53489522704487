define('due-dashboard/components/integrations/salesforce-form', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    accountObject: _ember['default'].computed.oneWay('getAccountObject'),

    getAccountObject: _ember['default'].computed('model.company_integration.no_object', 'model.company_integration.custom_account_email', function () {
      if (this.get('model.company_integration.no_object')) {
        return 'None';
      }
      if (this.get('model.company_integration.custom_account_email')) {
        return 'Account';
      }
      return 'Contact';
    }),

    saveSfInte: function saveSfInte() {
      // TODO: Move to back-end
      if (this.get('accountObject') === 'Contact') {
        this.model.company_integration.set('no_object', false);
        this.model.company_integration.set('custom_account_email', null);
      } else if (this.get('accountObject') === 'Account') {
        this.model.company_integration.set('no_object', false);
      } else {
        this.model.company_integration.set('no_object', true);
        this.model.company_integration.set('custom_account_email', null);
      }
      return this.model.company_integration.save();
    },

    actions: {
      completeSalesforceIntegration: function completeSalesforceIntegration() {
        this.model.company_integration.set('completed', false);
        this.set('promise', this.saveSfInte().then(function (salesforce_it) {
          window.location.replace(salesforce_it.get('oauth_url'));
        }));
      },

      updateSalesforceIntegration: function updateSalesforceIntegration() {
        var _this = this;

        this.saveSfInte().then(function () {
          _this.set('promise', Promise.resolve());
        });
      }
    }

  });
});