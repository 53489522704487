define("due-dashboard/templates/settings/integrations/salesforce/manage", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/salesforce/manage.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "target", "_blank");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createAttrMorph(element0, 'href');
        morphs[2] = dom.createMorphAt(element0, 1, 1);
        morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        return morphs;
      },
      statements: [["inline", "due-btn", [], ["icon", "tio-sync", "size", "medium", "intent", "primary", "textKey", "settings.integrations.salesforce.refresh_token", "customClass", "refresh-token-button", "action", ["subexpr", "action", ["updateToken"], [], ["loc", [null, [8, 9], [8, 31]]], 0, 0], "disabled", ["subexpr", "not", [["get", "parent.salesforce_it.is_active", ["loc", [null, [9, 16], [9, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 11], [9, 47]]], 0, 0]], ["loc", [null, [2, 0], [10, 2]]], 0, 0], ["attribute", "href", ["concat", [["get", "downloadLink", ["loc", [null, [11, 11], [11, 23]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "due-btn", [], ["icon", "tio-publish", "size", "medium", "intent", "primary", "textKey", "settings.integrations.salesforce.upgrade_package", "customClass", "refresh-token-button", "disabled", ["subexpr", "not", [["get", "parent.salesforce_it.is_active", ["loc", [null, [18, 18], [18, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 13], [18, 49]]], 0, 0]], ["loc", [null, [12, 2], [19, 4]]], 0, 0], ["inline", "integrations/salesforce-form", [], ["status", "manage", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [23, 8], [23, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "promise", ["subexpr", "@mut", [["get", "parent.promise", ["loc", [null, [24, 10], [24, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "savable", ["subexpr", "@mut", [["get", "parent.savable", ["loc", [null, [25, 10], [25, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "validAccount", ["subexpr", "@mut", [["get", "parent.validAccount", ["loc", [null, [26, 15], [26, 34]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 0], [27, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});