define('due-dashboard/initializers/load-translations', ['exports'], function (exports) {
  exports['default'] = {

    name: 'load-translations',

    initialize: function initialize(application) {
      application.inject('controller', 'i18n', 'service:i18n');
      application.inject('model', 'i18n', 'service:i18n');
      application.inject('component', 'i18n', 'service:i18n');
      application.inject('view', 'i18n', 'service:i18n');
      application.inject('object', 'i18n', 'service:i18n');
      application.inject('route', 'i18n', 'service:i18n');
    }

  };
});