define('due-dashboard/components/templates/previews/parent-preview', ['exports', 'ember', 'due-dashboard/templates/components/templates/previews/parent-preview'], function (exports, _ember, _dueDashboardTemplatesComponentsTemplatesPreviewsParentPreview) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    layout: _dueDashboardTemplatesComponentsTemplatesPreviewsParentPreview['default'],
    wrapper: 'email',
    classNames: ['template-preview'],
    classNameBindings: ['hideTittle'],

    init: function init() {
      this._super.apply(this, arguments);
      // caller = 'basic' || 'nps' || 'html' || 'sms' ...
      var caller = Object.getPrototypeOf(this).templateType;
      this.set('preview-wrapper', 'templates/previews/' + this.get('wrapper') + '-wrapper');
      this.set('partial', 'components/templates/previews/' + caller);
    }

  });
});