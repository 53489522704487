define('due-dashboard/models/match-question-and-ratings-scenario-condition', ['exports', 'due-dashboard/models/scenario-condition', 'ember-data', 'due-dashboard/utils/has-many-through', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioCondition, _emberData, _dueDashboardUtilsHasManyThrough, _emberCpValidations) {
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;

  var Validations = (0, _emberCpValidations.buildValidations)({
    question: (0, _emberCpValidations.validator)('presence', true),
    condition_ratings: (0, _emberCpValidations.validator)('presence', true)
  });

  var M = _dueDashboardModelsScenarioCondition['default'].extend(Validations, {
    question: belongsTo('question', { async: true }),
    match_question_and_ratings_scenario_conditions_ratings: hasMany('match-question-and-ratings-scenario-conditions-rating', { async: true }),

    defineSavepoint: function defineSavepoint() {
      var _this = this;

      return this.get('match_question_and_ratings_scenario_conditions_ratings').then(function (condition_ratings) {
        _this.set('_savepoint_condition_ratings', condition_ratings.toArray());
        return _this;
      });
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_condition_ratings');
      if (savepoint) {
        this.set('match_question_and_ratings_scenario_conditions_ratings', savepoint);
        savepoint.map(function (a) {
          return a.rollbackAttributes();
        });
      }
    }
  });

  _dueDashboardUtilsHasManyThrough['default'].define(M, 'match_question_and_ratings_scenario_conditions_ratings', {
    foreignKey: 'condition',
    hasManyKey: 'condition_ratings',
    sourceKey: 'rating'
  });

  exports['default'] = M;
});