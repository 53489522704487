define('due-dashboard/components/digests/digest-toolbar-message', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',

    displayTextKey: computed('digest', 'digest.unpublished_change', 'errorMessage', function () {
      if (this.get('errorMessage')) {
        return 'digest.error.' + this.get('errorMessage');
      }
      if (!this.get('digest.unpublished_change')) {
        return 'digest.edit.messages.no_changes';
      }
      return 'digest.edit.messages.draft_saved';
    }),

    displayIcon: computed('digest', 'digest.unpublished_change', 'errorMessage', function () {
      if (this.get('errorMessage')) {
        return 'tio-error red';
      }
      if (!this.get('digest.unpublished_change')) {
        return '';
      }
      return 'tio-checkmark_circle green';
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },

    processPromises: function processPromises(promisKey, promise, successIcon, sucessTextKey) {
      var _this = this;

      var secondMessageKey = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

      if (promise && (promise.isSettled && !promise.get('isSettled') || !promise.isSettled) && this.get('_' + promisKey) !== promise) {
        this.set('_' + promisKey, promise);
        promise.then(function () {
          _this.display('success', successIcon, sucessTextKey, secondMessageKey);
        })['catch'](function () {
          _this.display('error', 'tio-error red', 'digest.edit.messages.draft_save_error');
        });
      }
    },

    promiseObserver: on('init', observer('promise', 'publishPromise', 'discardPromise', function () {
      var _this2 = this;

      var promisesArray = [{ key: 'promise', value: this.get('promise') }, { key: 'publishPromise', value: this.get('publishPromise') }, { key: 'discardPromise', value: this.get('discardPromise') }];
      promisesArray.forEach(function (obj) {
        var icon = 'tio-checkmark_circle green',
            sucessText = '',
            seccondMessage = false;
        switch (obj.key) {
          case 'promise':
            sucessText = 'digest.edit.messages.draft_saved';
            break;
          case 'publishPromise':
            sucessText = _this2.get('publishMessage');
            seccondMessage = 'digest.edit.messages.no_changes';
            break;
          case 'discardPromise':
            sucessText = 'digest.edit.messages.no_changes';
            icon = '';
            break;
        }
        _this2.processPromises(obj.key, obj.value, icon, sucessText, seccondMessage);
      });
    })),

    display: function display(state, icon, textKey) {
      var _this3 = this;

      var secondMessageKey = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];

      if (state === 'error') {
        this.set('displayTextKey', textKey);
        this.set('displayIcon', icon);
        return;
      }
      if (state === 'success') {
        this.set('displayTextKey', textKey);
        this.set('displayIcon', icon);
        if (secondMessageKey) {
          setTimeout(function () {
            _this3.set('displayTextKey', secondMessageKey);
            _this3.set('displayIcon', '');
          }, 2500);
        }
        return;
      }
    }
  });
});