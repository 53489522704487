define('due-dashboard/components/v2/libraries/surveys/navbar/translations/elements/-rating', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['survey-translations-element'],
    ratings: null,

    ratingValues: computed('ratings', function () {
      if (this.get('ratings') == undefined) {
        return;
      }
      return this.get('ratings').map(function (rating) {
        return { score: rating.get('score'), value: rating.get('display_scale_value') };
      });
    }),

    languages: computed('languagesToDisplay', 'ratingValues', function () {
      var _this = this;

      var languages = [];
      var mainLanguageTag = this.get('mainLanguage.tag');
      var ratingValues = this.get('ratingValues');
      this.get('languagesToDisplay').forEach(function (lang) {
        if (lang == mainLanguageTag) {
          var surveyElement = _this.get('surveyElement');
          languages.push({
            name: lang,
            mainLanguage: true,
            label: surveyElement.content.label,
            naLabel: surveyElement.content.na_label,
            lowScore: surveyElement.content.legend.low_score,
            highScore: surveyElement.content.legend.high_score,
            values: ratingValues
          });
        } else {
          var element = _this._getElement(lang.toLowerCase());
          languages.push({
            name: lang,
            mainLanguage: false,
            label: _this._readNestedObject(element, 'label'),
            naLabel: _this._readNestedObject(element, 'na_label'),
            lowScore: _this._readNestedObject(element, 'legend.low_score'),
            highScore: _this._readNestedObject(element, 'legend.high_score'),
            values: _this._getTranslatedRatingValues(ratingValues, _this._readNestedObject(element, 'rating') || {})
          });
        }
      });
      return languages;
    }),

    init: function init() {
      var _this2 = this;

      var ratingScaleId = this.get('surveyElement.content.rating_scale_id');
      this.store.findRecord('rating_scale', ratingScaleId, { include: 'ratings' }).then(function (ratingScale) {
        _this2.set('ratings', ratingScale.get('ratings'));
        _this2.notifyPropertyChange('ratings');
      });
      this._super.apply(this, arguments);
    },

    _getTranslatedRatingValues: function _getTranslatedRatingValues(ratingValues, options) {
      if (ratingValues == undefined) {
        return [];
      }
      return ratingValues.map(function (rating) {
        return { score: rating.score, value: options[String(rating.score)] };
      });
    },

    _getElement: function _getElement(lang) {
      var translations = this.get('translations');
      var stepPosition = String(this.get('stepPosition'));
      var elementPosition = String(this.get('surveyElement.position'));

      if (translations == undefined) {
        return {};
      }
      return (((translations[lang] || {})['elements'] || {})[stepPosition] || {})[elementPosition];
    },

    _ensureExistence: function _ensureExistence(lang, stepPosition, elementPosition) {
      var translations = this.get('translations') || {};

      if (translations[lang] == undefined) {
        translations[lang] = {};
      }
      if (translations[lang]['elements'] == undefined) {
        translations[lang]['elements'] = {};
      }
      if (translations[lang]['elements'][stepPosition] == undefined) {
        translations[lang]['elements'][stepPosition] = {};
      }
      if (translations[lang]['elements'][stepPosition][elementPosition] == undefined) {
        translations[lang]['elements'][stepPosition][elementPosition] = {};
      }
      return translations;
    },

    _updateTranslations: function _updateTranslations(translationsElement, elementPath, value) {
      var _elementPath$split = elementPath.split('.');

      var _elementPath$split2 = _toArray(_elementPath$split);

      var head = _elementPath$split2[0];

      var rest = _elementPath$split2.slice(1);

      return _extends({}, translationsElement, _defineProperty({}, head, rest.length ? this._updateTranslations(translationsElement[head], rest.join('.'), value) : value));
    },

    _readNestedObject: function _readNestedObject(translationsElement, elementPath) {
      var _elementPath$split3 = elementPath.split('.');

      var _elementPath$split32 = _toArray(_elementPath$split3);

      var head = _elementPath$split32[0];

      var rest = _elementPath$split32.slice(1);

      if (translationsElement == undefined || translationsElement[head] == undefined) {
        return undefined;
      }
      return rest.length ? this._readNestedObject(translationsElement[head], rest.join('.')) : translationsElement[head];
    },

    actions: {
      setTranslations: function setTranslations(lg, elementPath, value) {
        var lang = lg.toLowerCase();
        var stepPosition = String(this.get('stepPosition'));
        var elementPosition = String(this.get('surveyElement.position'));
        var translations = this._ensureExistence(lang, stepPosition, elementPosition);
        var translationsElement = translations[lang]['elements'][stepPosition][elementPosition];

        translations[lang]['elements'][stepPosition][elementPosition] = this._updateTranslations(translationsElement, elementPath, value);
        this.set('translations', translations);
        this.sendAction('updateSurveyTranslations', translations);
      },
      setTranslatedOption: function setTranslatedOption(lg, score, value) {
        var lang = lg.toLowerCase();
        var stepPosition = String(this.get('stepPosition'));
        var elementPosition = String(this.get('surveyElement.position'));
        var translations = this._ensureExistence(lang, stepPosition, elementPosition);
        var rating = translations[lang]['elements'][stepPosition][elementPosition]['rating'] || {};

        translations[lang]['elements'][stepPosition][elementPosition]['rating'] = _extends({}, rating, _defineProperty({}, score, value));
        this.set('translations', translations);
        this.sendAction('updateSurveyTranslations', translations);
      }
    }
  });
});