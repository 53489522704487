define("due-dashboard/templates/components/resolution-modal", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 10
                  },
                  "end": {
                    "line": 23,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "tag-badge", [], ["tag", ["subexpr", "@mut", [["get", "feedbackTag.tag", ["loc", [null, [16, 18], [16, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "removeTagAction", ["subexpr", "action", ["rootCauseRemove"], [], ["loc", [null, [17, 30], [17, 56]]], 0, 0], "canClose", ["subexpr", "@mut", [["get", "canUseTags", ["loc", [null, [18, 23], [18, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "canAutoClose", ["subexpr", "@mut", [["get", "autoTagsRights", ["loc", [null, [19, 27], [19, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "from_due", ["subexpr", "@mut", [["get", "feedbackTag.from_due", ["loc", [null, [20, 23], [20, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "readOnly", ["subexpr", "@mut", [["get", "isReadOnly", ["loc", [null, [21, 23], [21, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 12], [22, 14]]], 0, 0]],
              locals: ["feedbackTag"],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 35,
                      "column": 12
                    },
                    "end": {
                      "line": 44,
                      "column": 12
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "due-btn", [], ["customClass", "action", "icon", "tio-add", "textKey", "settings.company.add_root_cause", "size", "small", "intent", "secondary", "action", ["subexpr", "@mut", [["get", "onToggle", ["loc", [null, [42, 23], [42, 31]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 14], [43, 16]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 12
                    },
                    "end": {
                      "line": 52,
                      "column": 12
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "due-btn", [], ["size", "small", "intent", "secondary", "icon", "tio-labels", "disabledEnter", true, "action", ["subexpr", "@mut", [["get", "onToggle", ["loc", [null, [50, 23], [50, 31]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [45, 14], [51, 16]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 10
                  },
                  "end": {
                    "line": 53,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "feedbackRootCauses.length", ["loc", [null, [35, 22], [35, 47]]], 0, 0, 0, 0], 0], [], ["loc", [null, [35, 18], [35, 50]]], 0, 0]], [], 0, 1, ["loc", [null, [35, 12], [52, 19]]]]],
              locals: ["onToggle"],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 4
                },
                "end": {
                  "line": 56,
                  "column": 4
                }
              },
              "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "action-container");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h4");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "text-span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "root-causes-management");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [5]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(element3, 1, 1);
              morphs[3] = dom.createMorphAt(element3, 2, 2);
              return morphs;
            },
            statements: [["content", "rootCauseTitle", ["loc", [null, [11, 12], [11, 30]]], 0, 0, 0, 0], ["inline", "t", ["settings.company.modal_resolution_root_cause_context"], [], ["loc", [null, [12, 32], [12, 92]]], 0, 0], ["block", "each", [["get", "feedbackRootCauses", ["loc", [null, [14, 18], [14, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 10], [23, 19]]]], ["block", "due-dropdown/due-dropdown-multilevel", [], ["scrollHeight", 350, "maxWidth", 300, "keyDisplay", "title", "options", ["subexpr", "@mut", [["get", "rootCauses", ["loc", [null, [28, 20], [28, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "onSearch", ["subexpr", "action", ["searchRootCause"], [], ["loc", [null, [29, 21], [29, 47]]], 0, 0], "onSelect", ["subexpr", "action", ["rootCauseUpdated"], [], ["loc", [null, [30, 21], [30, 48]]], 0, 0], "onClose", ["subexpr", "action", ["closeRootCause"], [], ["loc", [null, [31, 20], [31, 45]]], 0, 0], "closeOnSelect", true], 1, null, ["loc", [null, [24, 10], [53, 51]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 10
                  },
                  "end": {
                    "line": 72,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "tag-badge", [], ["tag", ["subexpr", "@mut", [["get", "actionTaken.tag", ["loc", [null, [64, 18], [64, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "removeTagAction", ["subexpr", "action", ["actionTakenRemoved"], [], ["loc", [null, [65, 30], [65, 59]]], 0, 0], "canClose", ["subexpr", "@mut", [["get", "canUseTags", ["loc", [null, [66, 23], [66, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "canAutoClose", ["subexpr", "@mut", [["get", "autoTagsRights", ["loc", [null, [67, 27], [67, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "from_due", ["subexpr", "@mut", [["get", "actionTaken.from_due", ["loc", [null, [68, 23], [68, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "readOnly", ["subexpr", "@mut", [["get", "isReadOnly", ["loc", [null, [69, 23], [69, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "hideBadge", true], ["loc", [null, [63, 12], [71, 14]]], 0, 0]],
              locals: ["actionTaken"],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 84,
                      "column": 12
                    },
                    "end": {
                      "line": 93,
                      "column": 12
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "due-btn", [], ["icon", "tio-add", "customClass", "action", "textKey", "settings.company.add_action_taken", "size", "small", "intent", "secondary", "action", ["subexpr", "@mut", [["get", "onToggle", ["loc", [null, [91, 23], [91, 31]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [85, 14], [92, 16]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 93,
                      "column": 12
                    },
                    "end": {
                      "line": 101,
                      "column": 12
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "due-btn", [], ["size", "small", "intent", "secondary", "icon", "tio-add", "disabledEnter", true, "action", ["subexpr", "@mut", [["get", "onToggle", ["loc", [null, [99, 23], [99, 31]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [94, 14], [100, 16]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 73,
                    "column": 10
                  },
                  "end": {
                    "line": 102,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "feedbackActionsTaken.length", ["loc", [null, [84, 22], [84, 49]]], 0, 0, 0, 0], 0], [], ["loc", [null, [84, 18], [84, 52]]], 0, 0]], [], 0, 1, ["loc", [null, [84, 12], [101, 19]]]]],
              locals: ["onToggle"],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 4
                },
                "end": {
                  "line": 105,
                  "column": 4
                }
              },
              "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "action-container");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h4");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "text-span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "root-causes-management");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [5]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(element1, 1, 1);
              morphs[3] = dom.createMorphAt(element1, 2, 2);
              return morphs;
            },
            statements: [["content", "actionTakenTitle", ["loc", [null, [59, 12], [59, 32]]], 0, 0, 0, 0], ["inline", "t", ["settings.company.modal_resolution_action_taken_context"], [], ["loc", [null, [60, 32], [60, 94]]], 0, 0], ["block", "each", [["get", "feedbackActionsTaken", ["loc", [null, [62, 18], [62, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [62, 10], [72, 19]]]], ["block", "due-dropdown/due-dropdown-multilevel", [], ["scrollHeight", 350, "maxWidth", 300, "keyDisplay", "title", "options", ["subexpr", "@mut", [["get", "actionsTaken", ["loc", [null, [77, 20], [77, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "onSearch", ["subexpr", "action", ["searchActionTaken"], [], ["loc", [null, [78, 21], [78, 49]]], 0, 0], "onSelect", ["subexpr", "action", ["actionTakenUpdated"], [], ["loc", [null, [79, 21], [79, 50]]], 0, 0], "onClose", ["subexpr", "action", ["closeActionTaken"], [], ["loc", [null, [80, 20], [80, 47]]], 0, 0], "closeOnSelect", true], 1, null, ["loc", [null, [73, 10], [102, 51]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 122,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "icon tio-clear");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h1");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "buttons-container");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(fragment, [8]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element4, 'onclick');
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
            morphs[4] = dom.createMorphAt(element5, 1, 1);
            morphs[5] = dom.createMorphAt(element5, 3, 3);
            return morphs;
          },
          statements: [["attribute", "onclick", ["subexpr", "action", ["closeModal"], [], ["loc", [null, [null, null], [7, 40]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["settings.company.feedback_resolution"], [], ["loc", [null, [8, 8], [8, 52]]], 0, 0], ["block", "if", [["get", "currentCompany.enabled_root_cause", ["loc", [null, [9, 10], [9, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 4], [56, 11]]]], ["block", "if", [["get", "currentCompany.action_taken_enabled", ["loc", [null, [57, 10], [57, 45]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [57, 4], [105, 11]]]], ["inline", "due-btn", [], ["textKey", "words.cancel", "size", "medium", "intent", "secondary", "action", ["subexpr", "action", ["closeModal"], [], ["loc", [null, [111, 15], [111, 36]]], 0, 0]], ["loc", [null, [107, 6], [112, 8]]], 0, 0], ["inline", "due-btn", [], ["customClass", "resolve-button", "textKey", "chats.solve", "size", "medium", "intent", "primary", "action", ["subexpr", "action", ["resolveModal"], [], ["loc", [null, [118, 15], [118, 38]]], 0, 0], "disabled", ["subexpr", "@mut", [["get", "resolutionDisabled", ["loc", [null, [119, 17], [119, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [113, 6], [120, 8]]], 0, 0]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 123,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-dialog", [], ["close", "closeModal", "container-class", "resolution-modal", "translucentOverlay", true], 0, null, ["loc", [null, [2, 2], [122, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 124,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/resolution-modal.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "modalIsOpen", ["loc", [null, [1, 6], [1, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [123, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});