define('due-dashboard/models/survey-revision', ['exports', 'ember', 'ember-data', 'ember-data-model-fragments', 'due-dashboard/mixins/filthy-model', 'ember-cp-validations'], function (exports, _ember, _emberData, _emberDataModelFragments, _dueDashboardMixinsFilthyModel, _emberCpValidations) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    activity_sector: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = Model.extend(_dueDashboardMixinsFilthyModel['default'], Validations, {
    mailings: hasMany('Mailing', { async: true }),
    survey_languages: hasMany('SurveyLanguage'),
    social_networks: hasMany('SurveySocialNetwork'),
    survey: belongsTo('Survey', { inverse: 'revisions', async: true }),
    activity_sector: belongsTo('ActivitySector'),
    rating_scale: belongsTo('RatingScale', { async: true }),
    closed_questions_rating_scale: belongsTo('RatingScale', { async: true }),
    website: attr('string'),
    background_image: attr('string'),
    survey_color: attr('string'),
    logo_image: attr('string'),
    active: attr('boolean', { defaultValue: false }),
    is_similar_to_previous: attr('boolean', { defaultValue: true }),
    survey_revision_question_ordered_ids: _emberDataModelFragments['default'].array('string'),
    custom_box_bg_color: attr(),
    custom_text_color: attr(),

    survey_revision_questions: hasMany('SurveyRevisionQuestion', { async: true }),

    finalize: function finalize() {
      this.set('active', true);
      return this.save();
    },

    languages: computed.mapBy('survey_languages', 'language'),

    currentLanguage: computed('languages.@each.tag', 'i18n.locale', function () {
      return this.get('survey_languages').filterBy('language.tag', this.get('i18n.locale').toUpperCase());
    }),

    currentLanguageOrFirst: computed.or('currentLanguage.firstObject', 'survey_languages.firstObject')
  });
});