define('due-dashboard/components/hover-zone', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    name: 'hover-zone',
    active: null,

    registerZone: function registerZone() {
      var name = this.get('name');
      var active = this.get('active');
      active.addObject(name);
      this.set('active', active);
    },

    unregisterZone: function unregisterZone() {
      var name = this.get('name');
      var active = this.get('active');
      active.removeObject(name);
      this.set('active', active);
    },

    mouseEnter: function mouseEnter() {
      this.registerZone();
    },

    mouseLeave: function mouseLeave() {
      this.unregisterZone();
    },

    willDestroyElement: function willDestroyElement() {
      this.unregisterZone();
    }

  });
});