define("due-dashboard/templates/components/messaging/status-filter-item", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 5
          }
        },
        "moduleName": "due-dashboard/templates/components/messaging/status-filter-item.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("li");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[3] = dom.createAttrMorph(element1, 'class');
        return morphs;
      },
      statements: [["attribute", "class", ["subexpr", "if", [["get", "isSelected", ["loc", [null, [1, 48], [1, 58]]], 0, 0, 0, 0], "selected"], [], ["loc", [null, [null, null], [1, 71]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["selectOption", ["get", "status", ["loc", [null, [1, 28], [1, 34]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 4], [1, 36]]], 0, 0], ["content", "status.translate", ["loc", [null, [2, 8], [2, 28]]], 0, 0, 0, 0], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "isSelected", ["loc", [null, [2, 53], [2, 63]]], 0, 0, 0, 0], "tio-done"], [], ["loc", [null, [2, 48], [2, 76]]], 0, 0], " selected-icon"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});