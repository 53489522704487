define('due-dashboard/components/zendesk/all-triggers', ['exports', 'ember', 'due-dashboard/utils/zendesk/client-api'], function (exports, _ember, _dueDashboardUtilsZendeskClientApi) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  /* global _ */

  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var Component = _ember['default'].Component;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Component.extend({

    triggersWithId: computed('triggers.[].id', function () {
      var triggers = this.get('triggers');
      if (triggers) {
        return triggers.filter(function (t) {
          return !!t.id;
        });
      }
    }),

    triggers: [],

    ZendeskClient: computed('company_integration.{company_zendesk_url,api_access_token}', function () {
      if (this.get('company_integration.api_access_token') && this.get('company_integration.company_zendesk_url')) {
        return new _dueDashboardUtilsZendeskClientApi['default']({
          apiToken: this.get('company_integration.api_access_token'),
          hostname: this.get('company_integration.company_zendesk_url') + '.zendesk.com'
        });
      }
    }),

    findTemplate: function findTemplate(trigger) {
      var body = JSON.parse(trigger.actions[0].value[1]);
      return this.get('store').findRecord('template', body.template_id);
    },

    findSurvey: function findSurvey(trigger) {
      var body = JSON.parse(trigger.actions[0].value[1]);
      return this.get('store').findRecord('survey', body.survey_id);
    },

    loadTriggers: function loadTriggers(targets) {
      var _this = this;

      var params = arguments.length <= 1 || arguments[1] === undefined ? "" : arguments[1];

      this.get('ZendeskClient').get('triggers.json' + params).then(function (res) {
        var targets_ids = targets.map(function (t) {
          return t.id.toString();
        });
        var triggers = res.triggers.filter(function (t) {
          return targets_ids.indexOf(t.actions[0].value[0]) !== -1;
        });
        triggers.forEach(function (trigger) {
          trigger.survey = _this.findSurvey(trigger);
          trigger.template = _this.findTemplate(trigger);
          trigger.conditions.all = _dueDashboardUtilsZendeskClientApi['default'].deserialize_conditions(trigger.conditions.all);
          trigger.conditions.any = _dueDashboardUtilsZendeskClientApi['default'].deserialize_conditions(trigger.conditions.any);
        });
        RSVP.all(triggers.mapBy('survey')).then(function () {
          _this.get('triggers').addObjects(triggers.filterBy('survey.id'));
        });
        if (res.next_page) {
          _this.loadTriggers(targets, res.next_page.split("json")[1]);
        }
      });
    },

    createTarget: function createTarget(api_key) {
      return this.get('ZendeskClient').post('targets.json', { target: Object.assign({ username: api_key }, _dueDashboardUtilsZendeskClientApi['default'].DIDUENJOY_TARGET_BASE)
      });
    },

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.findOrCreateActions().then(function (actions) {
        _this2.set('trigger_action', actions[0]);
        _this2.loadTriggers(actions);
      });
      this.get("setCallback")(this.get("disableTarget"), this.get("deleteTarget"), this);
      this._super.apply(this, arguments);
    },

    findOrCreateActions: function findOrCreateActions() {
      var _this3 = this;

      return new RSVP.Promise(function (resolve) {
        var api_keys = _this3.get('store').findAll('api_key');
        _this3.get('ZendeskClient').get('targets.json?').then(function (res) {
          api_keys.then(function (api_keys) {
            api_keys = api_keys.mapBy('id');
            var targets = res.targets.filter(function (target) {
              return api_keys.indexOf(target.username) !== -1 && target.active && _.isMatch(target, _dueDashboardUtilsZendeskClientApi['default'].DIDUENJOY_TARGET_BASE);
            });
            if (!targets.length) {
              _this3.createTarget(api_keys[0]).then(function (data) {
                return resolve([data.target]);
              });
            } else {
              resolve(targets);
            }
          });
        });
      });
    },

    getJsonBody: function getJsonBody(survey, template) {
      return JSON.stringify({
        survey_id: survey.get('id'),
        template_id: template.get('id'),
        email: "{{ticket.requester.email}}",
        segments: Object.assign(_dueDashboardUtilsZendeskClientApi['default'].DIDUENJOY_SEGMENTS, {
          language: "{{ticket.requester.language}}"
        })
      }, null, 2);
    },

    disableTarget: function disableTarget() {
      var context = arguments.length <= 0 || arguments[0] === undefined ? this : arguments[0];

      context.set('trigger_action.active', !context.get('trigger_action.active'));
      var target = context.get('trigger_action');
      return context.get('ZendeskClient').put('targets/' + target.id + '.json', { target: { active: target.active } });
    },

    deleteTarget: function deleteTarget() {
      var context = arguments.length <= 0 || arguments[0] === undefined ? this : arguments[0];

      var target = context.get('trigger_action');
      return context.get('ZendeskClient')['delete']('targets/' + target.id + '.json');
    },

    setupFreshTrigger: function setupFreshTrigger(trigger) {
      var fresh_trigger = Object.assign({}, trigger);
      fresh_trigger.conditions = {
        all: _dueDashboardUtilsZendeskClientApi['default'].serialize_conditions(fresh_trigger.conditions.all),
        any: _dueDashboardUtilsZendeskClientApi['default'].serialize_conditions(fresh_trigger.conditions.any)
      };
      var action_id = fresh_trigger.actions[0].value;
      action_id = (action_id && action_id[0] || fresh_trigger.actions[0].id).toString();
      fresh_trigger.actions = [{
        field: 'notification_target',
        value: [action_id, this.getJsonBody(trigger.survey, trigger.template)]
      }];
      delete fresh_trigger.survey;
      return fresh_trigger;
    },

    updateTrigger: function updateTrigger(dest, origin) {
      set(dest, 'id', origin.id);
      set(dest, 'conditions.all', _dueDashboardUtilsZendeskClientApi['default'].deserialize_conditions(origin.conditions.all));
      set(dest, 'conditions.any', _dueDashboardUtilsZendeskClientApi['default'].deserialize_conditions(origin.conditions.any));
    },

    actions: {
      editTrigger: function editTrigger(trigger) {
        this.set('selectedTrigger', trigger);
      },

      closeEdit: function closeEdit() {
        var trigger = this.get('selectedTrigger');
        if (!trigger.id) {
          this.get('triggers').removeObject(trigger);
        }
        this.send('editTrigger');
      },

      createTrigger: function createTrigger() {
        var new_trigger = {
          title: 'Diduenjoy - ',
          description: '',
          conditions: { any: [], all: [] },
          actions: [this.get('trigger_action')],
          active: true
        };
        this.get('triggers').addObject(new_trigger);
        this.set('selectedTrigger', new_trigger);
      },

      deleteTrigger: function deleteTrigger(trigger) {
        var _this4 = this;

        var unloadTrigger = function unloadTrigger(trigger) {
          return _this4.get('triggers').removeObject(trigger);
        };
        if (trigger.id) {
          this.set('promise', this.get('ZendeskClient')['delete']('triggers/' + trigger.id + '.json').then(function () {
            return unloadTrigger(trigger);
          }));
        } else {
          unloadTrigger(trigger);
        }
      },

      toggleActive: function toggleActive(trigger) {
        this.get('ZendeskClient').put('triggers/' + trigger.id + '.json', { trigger: { active: !trigger.active } }).then(function (res) {
          set(trigger, 'active', res.trigger.active);
        });
      },

      saveTrigger: function saveTrigger(trigger_p) {
        var _this5 = this;

        var _ref = trigger_p.id ? ["put", 'triggers/' + trigger_p.id + '.json'] : ["post", 'triggers.json'];

        var _ref2 = _slicedToArray(_ref, 2);

        var method = _ref2[0];
        var url = _ref2[1];

        var trigger = this.setupFreshTrigger(trigger_p);
        this.get('ZendeskClient')[method](url, {
          trigger: trigger
        }).then(function (res) {
          _this5.updateTrigger(trigger_p, res.trigger);
          _this5.propertyDidChange('triggersWithId');
          _this5.set('selectedTrigger', null);
        });
      }
    }

  });
});