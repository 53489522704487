define('due-dashboard/mixins/survey-step', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    prefix: 'companies.surveys.edit', //??

    renderTemplate: function renderTemplate() {
      var route_name = this.routeName;
      var layout = this.get('stepConfig.layout');
      var parentLayoutName = this.get('stepConfig.parentLayout');
      if (layout === 'preview') {
        var preview_layout = this.get('prefix') + '.preview_layout';
        if (parentLayoutName) {
          var parent_layout = parentLayoutName + '-layout';
          this.render(parent_layout);
          this.render(preview_layout, {
            into: parent_layout
          });
        } else {
          this.render(preview_layout);
        }
        this.render(route_name, {
          into: preview_layout,
          outlet: 'form'
        });
        this.render(route_name + '-preview', {
          into: preview_layout,
          view: 'surveyStepPreview',
          outlet: 'preview'
        });
      } else {
        this._super.apply(this, arguments);
      }
    },

    prevStep: function prevStep(step) {
      var model = this.modelFor('companies.surveys.edit');
      var stepGroups = model.stepGroups;
      var last = null;
      for (var gi = 0; gi < stepGroups.length; ++gi) {
        var group = stepGroups[gi];
        for (var si = 0; si < group.steps.length; si++) {
          var _step = group.steps[si];
          if (_step.name === step) {
            return last;
          }
          if (Array.isArray(_step.name) && _step.name.indexOf(step) !== -1) {
            return last;
          }
          last = group.steps[si].name;
        }
      }
      return null;
    },

    nextStep: function nextStep(step) {
      var model = this.modelFor('companies.surveys.edit');
      var stepGroups = model.stepGroups;
      var get = false;
      for (var gi = 0; gi < stepGroups.length; ++gi) {
        var group = stepGroups[gi];
        for (var si = 0; si < group.steps.length; si++) {
          if (get) {
            return group.steps[si].name;
          }
          if (group.steps[si].name === step) {
            get = true;
          }
        }
      }
      return null;
    },

    actions: {

      nextStep: function nextStep() {
        var model = this.modelFor('companies.surveys.edit');
        var currentStep = model.currentStep;
        if (!currentStep) {
          return;
        }
        var next = this.nextStep(currentStep);
        if (!next) {
          throw "No next step for: " + currentStep;
        }
        this.transitionTo(this.get('prefix') + '.' + next);
      },

      prevStep: function prevStep() {
        var model = this.modelFor('companies.surveys.edit');
        var currentStep = model.currentStep;
        if (!currentStep) {
          return;
        }
        var prev = this.prevStep(currentStep);
        if (!prev) {
          throw "No prev step for: " + currentStep;
        }
        this.transitionTo(this.get('prefix') + '.' + prev);
      }

    }

  });
});