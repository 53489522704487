define("due-dashboard/templates/components/v2/-switch", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/-switch.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'onclick');
        morphs[2] = dom.createAttrMorph(element1, 'class');
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["switch-container-", ["subexpr", "if", [["get", "on", ["loc", [null, [1, 34], [1, 36]]], 0, 0, 0, 0], "on", "off"], [], ["loc", [null, [1, 29], [1, 49]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["onToggleSwitchState"], [], ["loc", [null, [null, null], [1, 91]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["switch-", ["subexpr", "if", [["get", "on", ["loc", [null, [2, 26], [2, 28]]], 0, 0, 0, 0], "on", "off"], [], ["loc", [null, [2, 21], [2, 41]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});