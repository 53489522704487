define('due-dashboard/controllers/settings/integrations/integration-parent', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    hideSave: false,

    actions: {
      callbackSetter: function callbackSetter(disableTarget, deleteTarget, context) {
        this.set('model.callBackDisable', disableTarget);
        this.set('model.callBackDelete', deleteTarget);
        this.set('model.context', context);
      },

      saveIntegration: function saveIntegration() {
        this.set('promise', this.get('model.company_integration').save());
      },

      deleteIntegration: function deleteIntegration() {
        var _this = this;

        if (this.get('model.callBackDelete')) {
          this.get('model.callBackDelete')(this.get('model.context'));
        }
        this.set('promise', this.get('model.company_integration').destroyRecord().then(function () {
          _this.transitionToRoute('settings.integrations');
        }));
      },

      toggleActive: function toggleActive() {
        var integration = this.get('model.company_integration');
        if (integration.get('is_active') && !confirm(this.get('i18n').t('settings.integrations.confirm_turn_off', { integration: integration.get('name') }))) {
          return;
        }
        if (this.get('model.callBackDisable')) {
          this.get('model.callBackDisable')(this.get('model.context'));
        }
        integration.toggleProperty('is_active');
        this.set('promise', integration.save());
      }
    }
  });
});