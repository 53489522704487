define('due-dashboard/controllers/settings/users/edit', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var service = _ember['default'].inject.service;
  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  var observer = _ember['default'].observer;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    currentAccount: service(),
    account: alias('model.account'),
    selectedLanguage: alias('model.account.language'),
    show_new_profile: alias('model.show_new_profile'),
    samlOptions: [true, false],
    samlEnabled: alias('model.account.saml_enabled'),
    availableSegments: alias('model.available_segments'),
    segmentSelection: [],
    selectedSegmentsTouched: false,
    zoneOptions: [{ name: 'Europe' }, { name: 'China' }],
    segmentValuesHash: {},
    segmentValuesHashFiltered: {},

    newAccountSegments: computed('model.account.segments', 'model.account', function () {
      if (!this.get('model.new_account_segments')) {
        this.set('model.new_account_segments', this.get('model.account.segments'));
        this._queryAccountSegmentValues();
      }

      return this.get('model.new_account_segments');
    }),

    selectedSegments: computed('newAccountSegments', 'model.account.segments', function () {
      var account_segments = this.get('newAccountSegments');
      var selected_segments = [];

      if (this.get('availableSegments')) {
        this.get('availableSegments').forEach(function (segment) {
          if (Object.keys(account_segments).includes(segment.get('id'))) {
            segment.set('values', account_segments[segment.get('id')]);
            var segObj = { id: segment.get('id'), name: segment.get('name'), values: account_segments[segment.get('id')] };
            selected_segments.push(segObj);
          }
        });
      }

      return selected_segments;
    }),

    emailEditionDisabled: computed('currentAccount.canCreateUsers', function () {
      return !this.get('currentAccount.canCreateUsers');
    }),

    languages: computed('model.languages', function () {
      return this.get('model.languages').map(function (l) {
        return { name: l.get('name') + ' (' + l.get('tag') + ')' };
      });
    }),

    duplicatedAccount: observer('model', function () {
      var duplicate_account = this.get('model.duplicate_account');
      var target = duplicate_account ? 'duplicate' : 'edit';
      this.set('user_title', this.get('i18n').t('settings.users.' + target + '_user_title'));
      this.set('user_infos', this.get('i18n').t('settings.users.' + target + '_user_infos'));
      this.set('save_account', duplicate_account ? 'settings.users.send' : 'words.save');
      if (duplicate_account) {
        this.set('model.account.email', '');
        this.set('model.account.firstname', '');
        this.set('model.account.lastname', '');
        this.set('model.account.phone_number', '');
      }
    }),

    selectedLanguageText: computed('selectedLanguage', function () {
      var selectedLanguage = this.get('selectedLanguage');
      return selectedLanguage.get('name') + ' (' + selectedLanguage.get('tag') + ')';
    }),

    setCurrentLanguage: observer('model', function () {
      this.set('current_language', this.get('model.account.language'));
    }),

    readOnlySSO: computed('currentAccount.content.company.saml_enabled', function () {
      return this.get('currentAccount.content.company.saml_enabled') ? '' : 'read-only';
    }),

    canChangeZone: computed('currentAccount.content.company.replicate_to_china', 'account.profile', function () {
      return this.get('currentAccount.content.company.replicate_to_china') && this.get('account.profile') === 'custom';
    }),

    disableSave: computed('newAccountSegments', 'show_new_profile', 'new_profile.name.length', 'selectedProfile', 'account.hasDirtyAttributes', 'initial_custom_profile', 'current_language.id', 'account.language.id', function () {
      if (this.get('show_new_profile')) {
        return (this.get('new_profile.name.length') || 0) === 0;
      }
      if (this.get('account.hasDirtyAttributes')) {
        return false;
      }
      if (this.get('account.profile') === 'custom' && this.get('selectedProfile.id') !== this.get('initial_custom_profile')) {
        return false;
      }
      if (this.get('selectedSegmentsTouched')) {
        return false;
      }
      return this.get('current_language.id') === this.get('account.language.id');
    }),

    _clearAndTransition: function _clearAndTransition() {
      getOwner(this).lookup('controller:application').transitionToRoute('settings.users.index');
    },

    _queryAccountSegmentValues: function _queryAccountSegmentValues() {
      var _this = this;

      var segments = this.get('model.new_account_segments');
      var valuesHash = this.get('segmentValuesHash');

      Object.keys(segments).forEach(function (segmentId) {
        if (!Object.keys(valuesHash).includes(segmentId)) _this._querySegmentValues({ id: segmentId });
      });
    },

    _querySegmentValues: function _querySegmentValues(segment) {
      var _this2 = this;

      var valuesHash = this.get('segmentValuesHash');
      var promise = this.store.queryRecord('segment-value', {
        filter: {
          segment_id: segment.id,
          start_date: new Date(2000, 0, 1),
          end_date: new Date()
        }
      });

      promise.then(function (segmentValues) {
        valuesHash[segment.id] = segmentValues.get('values').toArray();
        _this2.set('segmentValuesHash', valuesHash);
        _this2.notifyPropertyChange('segmentValuesHash');
      });
    },

    actions: {
      createProfile: function createProfile() {
        this.set('new_profile', this.store.createRecord('account_custom_profile_right'));
        this.set('new_profile.name', this.get('account.email') + ' profile');
      },

      profileChanged: function profileChanged(choosed) {
        this.set('selectedCustomProfile', choosed);
        this.set('show_new_profile', get(choosed, 'type') === 'new_profile');
        if (this.get('account.profile') === 'custom') this.set('model.account.account_custom_profile_right', choosed);
      },

      cancelProfile: function cancelProfile() {
        this.get('account').rollbackAttributes();
        this._clearAndTransition();
      },

      saveAccount: function saveAccount() {
        var _this3 = this;

        var duplicate_account = this.get('model.duplicate_account');
        var account_custom_profile_right = this.get('show_new_profile') && this.get('new_profile');
        this.set('model.account.segments', this.get('newAccountSegments'));
        if (account_custom_profile_right) {
          this.set('saveAccountsPromise', account_custom_profile_right.save().then(function (acc) {
            _this3.set('model.account.account_custom_profile_right', acc);
            _this3.set('model.account.profile', 'custom');
            (duplicate_account ? _this3.get('model.account').copy() : new Promise(function (resolve) {
              return resolve(_this3.get('model.account'));
            })).then(function (a) {
              return a.save();
            }).then(function () {
              return _this3._clearAndTransition();
            });
          }));
        } else {
          var customProfile = this.get('selectedCustomProfile');
          if (customProfile) this.set('account.account_custom_profile_right', customProfile);
          (duplicate_account ? this.get('account').copy() : new Promise(function (resolve) {
            return resolve(_this3.get('account'));
          })).then(function (a) {
            return a.save();
          }).then(function () {
            return _this3._clearAndTransition();
          });
        }
      },

      toggleIcon: function toggleIcon(onToggle) {
        onToggle();
      },

      updateAccountsDefaultLanguage: function updateAccountsDefaultLanguage(language) {
        this.set('selectedLanguage', this.get('model.languages').find(function (l) {
          return l.get('name') === language.name.split(' (')[0];
        }));
      },

      updateAccountsZone: function updateAccountsZone(zone) {
        this.set('account.zone', zone.name);
      },

      addSegmentClick: function addSegmentClick() {
        this.set('showSegmentsDropdown', true);
        this.get('segmentsDropDownApi').actions.open();
      },

      addSegment: function addSegment(segment) {
        this.set('selectedSegmentsTouched', true);
        this.get('newAccountSegments')[segment.id] = [];
        this.notifyPropertyChange('newAccountSegments');
        this._querySegmentValues(segment);
      },

      removeSegment: function removeSegment(segment) {
        this.set('selectedSegmentsTouched', true);
        delete this.get('newAccountSegments')[segment.id];
        this.notifyPropertyChange('newAccountSegments');
      },

      addSegmentValueFromDropdown: function addSegmentValueFromDropdown(segment, value) {
        this.set('selectedSegmentsTouched', true);
        var newAccountSegments = this.get('newAccountSegments');
        var values = newAccountSegments[segment.id] || [];
        values.push(value);
        newAccountSegments[segment.id] = values;
        this.set('model.account.segments', newAccountSegments);
        this.notifyPropertyChange('model.account.segments');
      },

      addSegmentValues: function addSegmentValues(segment, values) {
        this.set('selectedSegmentsTouched', true);
        var newAccountSegments = this.get('newAccountSegments');
        var lastValue = values.slice(-1)[0];
        if (lastValue && lastValue.includes(';')) {
          var parsed = lastValue.split(';');
          values[values.length - 1] = parsed[0];
          parsed.shift();
          values = values.concat(parsed);
        }
        newAccountSegments[segment.id] = values;
        this.set('model.account.segments', newAccountSegments);
        this.notifyPropertyChange('model.account.segments');
      },

      updateSegmentFilter: function updateSegmentFilter(segment, input) {
        var filterHash = this.get('segmentValuesHashFiltered');
        var values = this.get('segmentValuesHash');

        if (input.length > 0) {
          filterHash[segment.id] = values[segment.id].filter(function (segment) {
            return segment.toLowerCase().includes(input.toLowerCase());
          });
          this.set('segmentValuesHashFiltered', filterHash);
          this.notifyPropertyChange('segmentValuesHashFiltered');
        } else {
          filterHash[segment.id] = null;
          this.set('segmentValuesHashFiltered', filterHash);
          this.notifyPropertyChange('segmentValuesHashFiltered');
        }
      },

      setAddSegmentController: function setAddSegmentController(dropdownApi) {
        this.set('segmentsDropDownApi', dropdownApi);
      }
    }
  });
});