define('due-dashboard/helpers/compute-duration', ['exports', 'ember'], function (exports, _ember) {
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = _ember['default'].Helper.helper(function (i18n, duration) {
    if (!duration || !i18n) return '-';

    var days = Math.floor(duration / (24 * 3600));
    var hours = Math.floor(duration % (24 * 3600) / 3600);
    var minutes = Math.floor(duration % 3600 / 60);

    if (duration < 60) return i18n.t('words.min_less_minute');

    var result = [];
    if (days > 0) result.push('' + days + i18n.t('words.min_day'));
    if (hours > 0) result.push('' + hours + i18n.t('words.min_hour'));
    if (minutes > 0) result.push('' + minutes + i18n.t('words.min_minute'));

    return htmlSafe(result.join(" "));
  });
});