define('due-dashboard/initializers/reset-errors-on-transition', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = {

    initialize: function initialize() {
      _ember['default'].Route.reopen({

        errors: _ember['default'].inject.service(),

        actions: {
          willTransition: function willTransition() {
            this.controller.set('isTransitioning', true);
            return true;
          },

          didTransition: function didTransition() {
            this.get('errors').resetErrors();
            this.controller.set('isTransitioning', false);
            return true;
          }
        }

      });
    }
  };
});