define('due-dashboard/routes/settings/integrations/facebook', ['exports', 'due-dashboard/routes/settings/integrations/integration-parent', 'due-dashboard/config/environment', 'ember'], function (exports, _dueDashboardRoutesSettingsIntegrationsIntegrationParent, _dueDashboardConfigEnvironment, _ember) {
  /* global FB */

  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({

    activate: function activate() {
      var _this = this;

      _ember['default'].run.schedule("afterRender", this, function () {
        if (!_this.get('controller.model.company_integration.id')) {
          window.fbAsyncInit = function () {
            FB.init({
              appId: _dueDashboardConfigEnvironment['default'].EmberENV.FACEBOOK_APP_ID,
              cookie: true,
              xfbml: true,
              version: _dueDashboardConfigEnvironment['default'].EmberENV.FACEBOOK_APP_API_VERSION
            });
          };
          (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
              return;
            }
            js = d.createElement(s);js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          })(document, 'script', 'facebook-jssdk');
        }
      });
    },

    deactivate: function deactivate() {
      var $fb_sdk = document.getElementById('facebook-jssdk');
      if ($fb_sdk) {
        $fb_sdk.remove();
      }
    }

  });
});