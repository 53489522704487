define('due-dashboard/controllers/settings/subscription', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    standardPlans: ['entrepreneur', 'salesforce', 'unicorn', 'trial'],

    company: computed.alias('model.account.company'),

    entrepreneurPlans: computed.filterBy('model.subscription_plans', 'name', 'entrepreneur'),
    salesforcePlans: computed.filterBy('model.subscription_plans', 'name', 'salesforce'),
    unicornPlans: computed.filterBy('model.subscription_plans', 'name', 'unicorn'),
    entrepreneurPlan: computed.alias('entrepreneurPlans.firstObject'),
    salesforcePlan: computed.alias('salesforcePlans.firstObject'),
    unicornPlan: computed.alias('unicornPlans.firstObject'),

    subscribed: computed('model.account.company.subscription_status', function () {
      return this.get('model.account.company.subscription_status') === 'subscriber';
    }),

    uniquePlan: computed('subscribed', 'model.account.company.subscription_plan.name', 'standardPlans', function () {
      return this.get('subscribed') || !this.get('standardPlans').includes(this.get('model.account.company.subscription_plan.name'));
    }),

    actions: {
      updateSubscription: function updateSubscription(stripe_source_token, subscription_plan) {
        var that = this;
        this.get('company').then(function (company) {
          company.set('stripe_source_token', stripe_source_token);
          company.set('subscription_plan', subscription_plan);
          that.send('companyChanged');
        });
      }
    }

  });
});