define('due-dashboard/controllers/companies/libraries/fonts/new', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    newFontName: '',
    cancelAlertIsOpen: false,
    saveAlertIsOpen: false,
    savePromise: null,
    uploadedFont: null,
    fontExtesion: null,

    FALLBACKS: ['Arial, Helvetica, sans-serif', 'Inter, sans-serif', 'Time New Roman, serif'],
    selectedFallback: 'Inter, sans-serif',

    saveDisabled: computed('newFontName', 'uploadedFont', function () {
      var uploadedFont = this.get('uploadedFont');
      var newFontName = this.get('newFontName');

      return newFontName.length === 0 || !uploadedFont;
    }),

    _resetPageState: function _resetPageState() {
      this.set('newFontName', '');
      this.set('savePromise', null);
      this.set('uploadedFont', null);
      this.set('cancelAlertIsOpen', false);
      this.set('saveAlertIsOpen', false);
      this.set('nameError', false);
      this.set('selectedFallback', 'Inter, sans-serif');
    },

    _transitionTo: function _transitionTo(route) {
      var _this = this;

      this.transitionToRoute(route).then(function () {
        _this._resetPageState();
      });
    },

    actions: {
      onChangeFontName: function onChangeFontName(text) {
        if (this.get('nameError')) {
          this.set('nameError', false);
        }
        this.set('newFontName', text);
      },

      toggleModalIsOpen: function toggleModalIsOpen(modal) {
        this.toggleProperty(modal + 'AlertIsOpen');
      },

      cancel: function cancel() {
        this._transitionTo('companies.libraries.fonts');
      },

      save: function save() {
        var _this2 = this;

        var uploadedFont = this.get('uploadedFont');
        var fontExtension = this.get('fontExtension');
        var newFontName = this.get('newFontName');
        var selectedFallback = this.get('selectedFallback');

        var model = this.store.createRecord('font', {
          name: newFontName,
          fallback: selectedFallback,
          'font-raw-data': uploadedFont,
          'font-content-type': fontExtension
        });

        var promise = model.save();

        this.set('savePromise', promise);

        promise.then(function () {
          _this2._transitionTo('companies.libraries.fonts');
        })['catch'](function () {
          _this2.set('saveAlertIsOpen', false);
          _this2.set('nameError', true);
        });
      },

      changeFallback: function changeFallback(selectedFallback) {
        this.set('selectedFallback', selectedFallback);
      },

      setUploadedFont: function setUploadedFont(font) {
        var extension = font.split(';')[0].split(':')[1];
        this.set('uploadedFont', font);
        this.set('fontExtension', extension);
      }
    }
  });
});