define("due-dashboard/templates/components/loading-skeleton/due-animate-skeleton", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/loading-skeleton/due-animate-skeleton.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("defs");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("linearGradient");
        dom.setAttribute(el2, "x2", "0");
        dom.setAttribute(el2, "y2", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("stop");
        dom.setAttribute(el3, "offset", "0%");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("stop");
        dom.setAttribute(el3, "offset", "80%");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("stop");
        dom.setAttribute(el3, "offset", "90%");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("stop");
        dom.setAttribute(el3, "offset", "99.9%");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("stop");
        dom.setAttribute(el3, "offset", "100%");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "x2");
        dom.setAttribute(el3, "from", "0%");
        dom.setAttribute(el3, "to", "100%");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element0, [5]);
        var element4 = dom.childAt(element0, [7]);
        var element5 = dom.childAt(element0, [9]);
        var element6 = dom.childAt(element0, [11]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element1, 'stop-color');
        morphs[2] = dom.createAttrMorph(element2, 'stop-color');
        morphs[3] = dom.createAttrMorph(element3, 'stop-color');
        morphs[4] = dom.createAttrMorph(element4, 'stop-color');
        morphs[5] = dom.createAttrMorph(element5, 'stop-color');
        morphs[6] = dom.createAttrMorph(element6, 'id');
        morphs[7] = dom.createAttrMorph(element6, 'dur');
        morphs[8] = dom.createAttrMorph(element6, 'begin');
        return morphs;
      },
      statements: [["attribute", "id", ["concat", [["get", "animationUID", ["loc", [null, [2, 24], [2, 36]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "stop-color", ["get", "baseColor", ["loc", [null, [3, 35], [3, 44]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "stop-color", ["get", "animateColor", ["loc", [null, [4, 36], [4, 48]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "stop-color", ["get", "animateColor", ["loc", [null, [5, 36], [5, 48]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "stop-color", ["get", "middleColor", ["loc", [null, [6, 38], [6, 49]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "stop-color", ["get", "baseColor", ["loc", [null, [7, 37], [7, 46]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["concat", [["get", "uid", ["loc", [null, [8, 19], [8, 22]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "dur", ["concat", [["get", "duration", ["loc", [null, [8, 52], [8, 60]]], 0, 0, 0, 0], "ms"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "begin", ["concat", [["get", "begin", ["loc", [null, [8, 75], [8, 80]]], 0, 0, 0, 0], "ms;", ["get", "uid", ["loc", [null, [8, 87], [8, 90]]], 0, 0, 0, 0], ".begin+", ["get", "delay", ["loc", [null, [8, 101], [8, 106]]], 0, 0, 0, 0], "ms"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});