define('due-dashboard/components/segments-qualifier', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    errors: service(),
    listSwap: null,
    currentStep: 1,
    filterCurrentStep: 1,
    filteredCompaniesSegments: [],
    searchField: '',
    isLoading: false,
    availableProfiles: ['qualified', 'auto_qualified', 'unqualified'],
    computedValue: [],

    computedValueDisplay: computed('computedValue', {
      get: function get() {
        return this.get('computedValue');
      }, set: function set(_, value) {
        this.set('computedValue', value);
        _ember['default'].run.debounce(this, this.reloadSegments, 200);
        return value;
      }
    }),

    whitelistedInChat: computed('companies_segments_handler.whitelist_chat_segments', function () {
      return this.getList("whitelist_chat_segments");
    }),

    updateScroll: function updateScroll() {
      var _this = this;

      var leftToQuery = arguments.length <= 0 || arguments[0] === undefined ? 50 : arguments[0];

      this.set('currentStep', this.get('currentStep') + 1);
      var filters = {};
      filters.page = { number: this.get('currentStep'), size: 50 };
      var allCompaniesSegments = this.get('model.allCompaniesSegments').toArray();
      this.get('store').query('companies_segment', filters).then(function (results) {
        var difference = results.toArray().filter(function (x) {
          return !allCompaniesSegments.includes(x);
        });
        allCompaniesSegments = [].concat(_toConsumableArray(allCompaniesSegments), _toConsumableArray(difference));
        leftToQuery = leftToQuery - difference.length;
        _this.set('model.allCompaniesSegments', allCompaniesSegments);
        if (leftToQuery < 0 || results.toArray().length === 0) {
          _this.set('isLoading', false);
        } else {
          _this.updateScroll(leftToQuery);
        }
      });
    },

    filtercompaniesSegments: function filtercompaniesSegments() {
      var _this2 = this;

      var term = this.get('searchField');
      this.set('filterCurrentStep', this.get('filterCurrentStep') + 1);
      var filters = { filter: { name: term, status: this.get('computedValue') } };
      filters.page = { number: this.get('filterCurrentStep'), size: 50 };
      var filteredCompaniesSegments = this.get('filteredCompaniesSegments');
      this.get('store').query('companies_segment', filters).then(function (results) {
        filteredCompaniesSegments = [].concat(_toConsumableArray(filteredCompaniesSegments), _toConsumableArray(results.toArray()));
        _this2.set('filteredCompaniesSegments', filteredCompaniesSegments);
        _this2.set('isLoading', false);
      });
    },

    reloadSegments: function reloadSegments() {
      this.set('filterCurrentStep', 0);
      this.set('filteredCompaniesSegments', []);
      this.set('isLoading', true);
      this.filtercompaniesSegments();
    },

    fullReloadSegments: function fullReloadSegments() {
      this.set('filterCurrentStep', 0);
      this.set('filteredCompaniesSegments', []);
      this.set('currentStep', 0);
      this.set('model.allCompaniesSegments', []);
      this.set('isLoading', true);
      if (this.get('searchField').length > 0 || this.get('computedValue.length') > 0) {
        _ember['default'].run.debounce(this, this.filtercompaniesSegments, 200);
      }
      _ember['default'].run.debounce(this, this.updateScroll, 200);
    },

    actions: {

      notifySegmentCreation: function notifySegmentCreation(segment) {
        this.get('model.allCompaniesSegments').pushObject(segment);
        this.notifyPropertyChange('model.allCompaniesSegments');
      },

      reloadCompaniesSegments: function reloadCompaniesSegments() {
        this.set('isLoading', true);
        if (this.get('searchField').length > 0 || this.get('computedValue.length') > 0) {
          _ember['default'].run.debounce(this, this.filtercompaniesSegments, 200);
        } else {
          _ember['default'].run.debounce(this, this.updateScroll, 200);
        }
      },

      searchCompaniesSegments: function searchCompaniesSegments() {
        this.set('filterCurrentStep', 0);
        this.set('filteredCompaniesSegments', []);
        this.set('isLoading', true);
        _ember['default'].run.debounce(this, this.filtercompaniesSegments, 200);
      },

      resetScrollSteps: function resetScrollSteps() {
        this.set('currentStep', 1);
      },

      fullReloadSegments: function fullReloadSegments() {
        this.fullReloadSegments();
      }
    }
  });
});