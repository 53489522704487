define('due-dashboard/controllers/settings/account', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  /* global _ */

  var computed = _ember['default'].computed;
  var _Ember$inject = _ember['default'].inject;
  var service = _Ember$inject.service;
  var controller = _Ember$inject.controller;
  var observer = _ember['default'].observer;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    frequencies: ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'],

    updateUserPromise: null,
    updatePasswordPromise: null,

    errors: service(),
    settings: controller(),
    currentCompany: computed.alias('currentAccount.content.company'),
    pickedButton: null,

    languages: computed.alias('model.languages'),

    setCurrentLanguage: observer('model', function () {
      var tag = this.get('model.account.language.tag').toLowerCase();
      this.set('current_language', this.get('model.account.language'));
      _moment['default'].locale(tag);
      this.get('cookie').setCookie('locale', tag);
      this.get('i18n').set('locale', tag);
    }),

    disableGeneral: computed.not('showModifyGeneral'),
    showModifyGeneral: computed('model.account.{email,firstname,lastname,phone_number,language.tag,hasDirtyAttributes,want_digest,timezone_id,custom_stats_digest_subscriptions}', function () {
      var _this = this;

      var pp = ['firstname', 'phone_number', 'lastname', 'timezone_id', 'email', 'want_digest', 'custom_stats_digest_subscriptions'];
      this.get('model.account.hasDirtyAttributes');
      // avoid dirtyState when start edit something empty and undone it
      pp.forEach(function (attr) {
        var value = _this.get('model.account.' + attr);
        if (value === '') {
          _this.set('model.account.' + attr, null);
        }
      });
      var ch_atts = this.get('model.account').changedAttributes();
      if (_.intersection(pp, Object.keys(ch_atts)).length) {
        return true;
      }
      // kinda relationship belongsTo language dirty tracking
      return this.get('current_language.id') !== this.get('model.account.language.id');
    }),

    showModifyPassword: computed('model.account.{current_password,password,password_confirmation}', function () {
      if (this.get('model.account.current_password.length') && this.get('model.account.password.length') && this.get('model.account.password_confirmation.length')) {
        return true;
      }
      return false;
    }),

    timezone_notice: computed('currentCompany.{timezone_in_dashboard,timezone_in_digest}', function () {
      var notice = 'settings.account.timezone.notice_';
      notice += this.get('currentCompany.timezone_in_dashboard') ? 'dashboard_' : 'no_dashboard_';
      notice += this.get('currentCompany.timezone_in_digest') ? 'digest' : 'no_digest';
      return notice;
    }),

    actions: {

      toggleDigest: function toggleDigest() {
        this.toggleProperty('model.account.want_digest');
      },

      updateUser: function updateUser(type) {
        var _this2 = this;

        var account_model = this.get('model.account'),
            email = account_model.get('email'),
            firstname = account_model.get('firstname'),
            lastname = account_model.get('lastname'),
            timezone_id = account_model.get('timezone_id'),
            phone_number = account_model.get('phone_number'),
            language = account_model.get('language'),
            want_digest = account_model.get('want_digest'),
            custom_stats_digest_subscriptions = account_model.get('custom_stats_digest_subscriptions');
        if (type === 'general') {
          account_model.rollbackAttributes();
          account_model.setProperties({
            'email': email,
            'firstname': firstname,
            'lastname': lastname,
            'timezone_id': timezone_id,
            'phone_number': phone_number,
            'language': language,
            'want_digest': want_digest,
            'custom_stats_digest_subscriptions': custom_stats_digest_subscriptions
          });
        }

        var promise = this.get('model.account').save();
        promise.then(function (account) {
          _this2.setCurrentLanguage();
          _this2.get('model.account').setProperties({ current_password: '', password: '', password_confirmation: '' });
          account.reload().then(function (acc) {
            return _this2.set('model.account', acc);
          });
        })['finally'](function () {
          if (type === 'general') {
            _this2.set('displayGeneral', true);
            _this2.set('displayPassword', false);
          } else if (type === 'password') {
            _this2.set('displayPassword', true);
            _this2.set('displayGeneral', false);
          }
          window.location.reload(true);
        });
        this.set('updateUserPromise', promise);
      }
    }

  });
});