define('due-dashboard/components/toggle-button', ['exports', 'ember'], function (exports, _ember) {
  var readOnly = _ember['default'].computed.readOnly;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['toggle-button'],
    classNameBindings: ['stateText'],
    tagName: 'button',

    clickMe: readOnly('texts.clickMe'),
    cancel: readOnly('texts.cancel'),
    clikd: readOnly('texts.cliked'),

    i18nAndStateObserver: _ember['default'].observer('state', 'i18n.locale', function () {
      this.updateText();
    }).on('didInsertElement'),

    updateText: function updateText(on_hover) {
      if (on_hover && !this.get('just_clicked')) {
        if (this.get('state')) {
          this.setText('cancel');
        } else {
          this.setText('clickMe');
        }
      } else {
        if (this.get('state')) {
          this.setText('clikd');
        } else {
          this.setText('clickMe');
        }
      }
    },

    setText: function setText(key) {
      this.set('stateText', key);
      var i18n = this.get('i18n');
      this.$()[0].textContent = i18n.t(this.get(key));
    },

    // events
    click: function click() {
      this.attrs.toggleAction();
      this.set('just_clicked', true);
      return true;
    },

    mouseEnter: function mouseEnter() {
      this.updateText(true);
      return true;
    },

    mouseLeave: function mouseLeave() {
      this.set('just_clicked', false);
      this.updateText();
      return true;
    }

  });
});