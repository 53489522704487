define('due-dashboard/initializers/define-entries', ['exports'], function (exports) {
  exports['default'] = {
    name: 'define-entries',

    initialize: function initialize() {
      if (!Object.entries) Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
        return resArray;
      };
    }
  };
});