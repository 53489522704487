define('due-dashboard/models/account-custom-profile-right', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var Model = _emberData['default'].Model;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Model.extend({
    questions_rights: attr('boolean', { defaultValue: true }),
    scenarios_rights: attr('boolean', { defaultValue: false }),
    has_access_to_dashboard: attr('boolean', { defaultValue: true }),
    chats_rights: attr({ defaultValue: 'none' }), // enum ['none', 'read', 'write', 'all']
    surveys_rights: attr({ defaultValue: 'none' }), // enum ['none', 'read', 'write', 'all']
    analysis_rights: attr({ defaultValue: 'none' }), // enum ['none', 'read']
    verbatims_rights: attr({ defaultValue: 'none' }), // enum ['none', 'read']
    exports_rights: attr({ defaultValue: 'all' }), // enum ['none', 'all']
    custom_reports_rights: attr({ defaultValue: 'read' }), // enum ['none', 'read']
    templates_rights: attr({ defaultValue: 'none' }), // enum ['none', 'all', 'read']
    tags_rights: attr({ defaultValue: 'use' }), // enum ['read', 'write']
    graphs_rights: attr({ defaultValue: 'all' }), // enum ['none', 'read', 'all']
    rating_scales_rights: attr({ defaultValue: 'all' }), // enum ['none', 'read', 'all']
    fonts_rights: attr({ defaultValue: 'all' }), // enum ['none', 'read', 'all']
    name: attr(),
    account_count: attr(),
    reports_hierarchy: attr(),
    users_rights: attr(),
    user_rights_management: attr({ defaultValue: [] }), // set of enum ['create', 'edit', 'delete']
    updated_at: attr('date'),
    auto_tags_rights: attr({ defaultValue: 'hidden' }), // enum ['hidden', 'read', 'assign']

    company: belongsTo('company'),
    feedback_filters: hasMany('feedback-filters', { polymorphic: true }),
    accounts: hasMany('accounts'),

    limit_user_management_by_custom_roles: hasMany('limit-user-management-by-custom-role', { inverse: 'custom_role' }),
    sidebar: belongsTo('sidebar', { async: true }),
    report_custom_rights: hasMany('report-custom-rights'),

    defineSavepoint: function defineSavepoint() {
      var _this = this;

      var saveHasMany = function saveHasMany(array) {
        return RSVP.all(array.map(function (c) {
          return c.defineSavepoint();
        }));
      };
      RSVP.hash({
        feedback_filters: this.get('feedback_filters'),
        limit_user_management_by_custom_roles: this.get('limit_user_management_by_custom_roles')
      }).then(function (h) {
        return RSVP.hash({
          feedback_filters: saveHasMany(h.feedback_filters),
          limit_user_management_by_custom_roles: saveHasMany(h.limit_user_management_by_custom_roles)
        });
      }).then(function (h) {
        _this.set('_savepoint_filters', h.feedback_filters);
        _this.set('_savepoint_whitelisted_custom_roles', h.limit_user_management_by_custom_roles);
        return _this;
      });
    },

    save: function save(options) {
      var _this2 = this;

      // Workaround for https://github.com/emberjs/data/issues/1829
      var unloadLocalGhosts = function unloadLocalGhosts(records) {
        if (records) {
          records.filterBy('isNew').forEach(function (r) {
            records.removeObject(r);
            r.deleteRecord();
            _this2.store.unloadRecord(r);
          });
        }
      };
      return this._super(options).then(function () {
        if (!_this2.get('isDeleted')) {
          return RSVP.all([_this2.get('feedback_filters').reload(), _this2.get('limit_user_management_by_custom_roles').reload()]).then(function () {
            unloadLocalGhosts(_this2.get('feedback_filters'));
            unloadLocalGhosts(_this2.get('limit_user_management_by_custom_roles'));
            return _this2;
          });
        }
        return _this2;
      });
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var filters = this.get('_savepoint_filters');
      var whitelisted_custom_roles = this.get('_savepoint_whitelisted_custom_roles');
      if (filters) {
        filters.forEach(function (c) {
          return c.rollbackToSavepoint();
        });
        this.set('feedback_filters', filters);
      }
      if (whitelisted_custom_roles) {
        whitelisted_custom_roles.forEach(function (c) {
          return c.rollbackToSavepoint();
        });
        this.set('limit_user_management_by_custom_roles', whitelisted_custom_roles);
      }
      return RSVP.resolve(this);
    }

  });
});