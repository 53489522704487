define('due-dashboard/models/question-family-question', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    question_family_id: attr(),
    new_question_id: attr(),

    question_family: belongsTo('QuestionFamily'),
    new_question: belongsTo('NewQuestion')
  });
});