define('due-dashboard/components/v2/-navigator', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['due-navigator'],

    actions: {
      leftAction: function leftAction() {
        var leftFunc = this.get('leftButtonAction');
        if (leftFunc) {
          leftFunc();
        }
      },

      rightAction: function rightAction() {
        var rightFunc = this.get('rightButtonAction');
        if (rightFunc) {
          rightFunc();
        }
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-navigator}} navigator component, can be used for number picker or array footer
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */