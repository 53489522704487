define('due-dashboard/routes/companies/surveys/edit/bulk-dispatch/template', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    stepConfig: {
      layout: 'preview'
    },

    model: function model(params) {
      return _rsvp['default'].hash({
        bulk_dispatch: this.store.find('bulk-dispatch', params.bulk_dispatch_id),
        bulk_dispatch_id: params.bulk_dispatch_id,
        survey: this.modelFor('companies.surveys.edit').survey
      });
    },

    actions: {

      edit: function edit(template, bulk_dispatch_id, required_languages) {
        this.transitionTo('companies.templates.edit', template, { queryParams: { bulk_dispatch_id: bulk_dispatch_id, required_languages: required_languages } });
      },

      prevStep: function prevStep() {
        this.transitionTo('companies.surveys.edit.bulk-dispatch', this.currentModel.bulk_dispatch.get('survey.id'));
      }
    }

  });
});