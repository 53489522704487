define('due-dashboard/components/due-dropdown-element', ['exports', 'ember'], function (exports, _ember) {

  /**
   * @author Youba SALMI <youba@diduenjoy.com>
   * @summary {{due-dropdown-element}} component of due-dropdown rows
   *
   * @module
   * @argument {array}    option - object of the item
   * @argument {object}   [selected] - selected item
   * @argument {number}   index - position on the option object
   * @argument {number}   highlightedIndex - highlighted object position
   * @argument {string}   displayAttribute - name of the property to display
   * @argument {function} triggerOption - handler of select action
   * @example <caption>Full usage example of {{due-dropdown-element}} component</caption>
   * Usage :
   * ```hbs
   * {{due-dropdown-element
   *   option=option
   *   index=index
   *   highlightedIndex=hoveredOptIdx
   *   displayAttribute=displayAttribute
   *   triggerOption=triggerAction
   * }}
   *
   * ```
   */

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',

    isHighlighted: computed('index', 'highlightedIndex', function () {
      return this.get('index') === this.get('highlightedIndex');
    }),

    isSelected: computed('selected', 'option', function () {
      var selected = this.get('selected');
      var option = this.get('option');

      if (!selected || !option) return false;
      if (typeof selected === 'string') return selected === option;else {
        var same;

        var _ret = (function () {
          var selectedKeys = Object.keys(selected);
          var optionKeys = Object.keys(option);
          same = true;

          selectedKeys.forEach(function (selectedKey, index) {
            if (selectedKey !== optionKeys[index] || selected[selectedKey] !== option[optionKeys[index]]) same = false;
          });
          return {
            v: same
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    }),

    textTitle: computed('valueKey', 'option', function () {
      return this.get('option.' + this.get('valueKey'));
    }),

    displayText: computed('option', 'displayAttribute', function () {
      return this.get('option.' + this.get('displayAttribute'));
    }),

    icon: computed('option', function () {
      return this.get('option.icon') || '';
    }),

    color: computed('option', function () {
      return this.get('option.color') || '#71899C';
    }),

    optionAction: computed('option', function () {
      return this.get('option.action') || this.get('option');
    }),

    actions: {
      selectOption: function selectOption(triggerOption, optionAction) {
        if (triggerOption) {
          triggerOption(optionAction);
        }
      }
    }
  });
});