define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/card-design', ['exports', 'ember', 'due-dashboard/helpers/color-formating'], function (exports, _ember, _dueDashboardHelpersColorFormating) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['setting-element-container'],
    name: 'card',
    UNITIES: ['px', 'em', 'ch'],
    COLOR_REGEX: /(#[0-9a-fA-F]*|rgba\((\d+,\s?){3}\d*\.\d*\))/g,
    DEFAULT_COLOR: '#FFFFFF',
    SCREEN_SIZE: [{ value: 'desktop', name: 'desktop' }, { value: 'tablet', name: 'tablet' }, { value: 'mobile', name: 'mobile' }],
    BOOLEAN: [{ name: 'yes', value: true }, { name: 'no', value: false }],
    POSITION: [{ name: 'left', value: 'left' }, { name: 'center', value: 'center' }, { name: 'right', value: 'right' }, { name: 'language', value: 'language' }],
    selectedScreenSize: 'desktop',

    currentDesignOptions: computed('styleName', 'currentItem', 'designOptions', 'isLoading', function () {
      var currentBackground = this.get('designOptions.' + this.get('styleName') + '_current_background');
      return {
        cardFullHeight: this._extractOption('designOptions.card_full_height', 'BOOLEAN'),
        cardPosition: this._extractOption('designOptions.card_alignment', 'POSITION'),
        textPosition: this._extractOption('designOptions.text_alignment', 'POSITION'),
        mobileTextPosition: this._extractOption('designOptions.mobile_text_alignment', 'POSITION'),
        cardBorderRadius: this._extractValue('designOptions.card_border_radius'),
        cardBorderRadiusSuffix: this._extractSuffix('designOptions.card_border_radius'),
        background: this.get('designOptions.' + this.get('styleName') + '_background'),
        backgroundColor: this._extractColors(this.get('designOptions.' + this.get('styleName') + '_background_color')),
        backgroundGradient: this.get('designOptions.' + this.get('styleName') + '_background_gradient'),
        displayCardBackground: this.get('designOptions.' + this.get('styleName') + '_display_card_background'),
        currentItem: currentBackground && currentBackground === 'gradient' ? 1 : 0,
        currentBackground: currentBackground
      };
    }),

    currentItem: computed('currentDesignOptions.currentItem', function () {
      return this.get('currentDesignOptions.currentItem');
    }),

    isFullHeight: computed('currentDesignOptions.cardFullHeight', function () {
      return this.get('currentDesignOptions.cardFullHeight').value;
    }),

    styleName: computed('selectedScreenSize', function () {
      return this.get('selectedScreenSize') + '_' + this.name;
    }),

    state: computed(function () {
      return this.SCREEN_SIZE[0];
    }),

    _extractColors: function _extractColors(color) {
      if (color == undefined) {
        return this.get('DEFAULT_COLOR');
      }
      var match = color.match(this.get('COLOR_REGEX'));
      if (match == undefined) {
        return this.get('DEFAULT_COLOR');
      }
      return match[0];
    },

    _extractValue: function _extractValue(designOptionName) {
      var defaultValue = arguments.length <= 1 || arguments[1] === undefined ? '0' : arguments[1];

      var value = this.get(designOptionName);
      if (value == undefined) return defaultValue;
      var valueRegexp = /[0-9\.]/g;
      var numberValue = value.match(valueRegexp);
      return numberValue ? numberValue.join('') : defaultValue;
    },

    _extractSuffix: function _extractSuffix(designOptionName) {
      var defaultValue = arguments.length <= 1 || arguments[1] === undefined ? 'px' : arguments[1];

      var value = this.get(designOptionName);
      if (value == undefined) return defaultValue;
      var unityRegexp = /[a-z\%]/g;
      var unityDetect = value.match(unityRegexp);
      if (unityDetect == undefined) return defaultValue;
      var unity = unityDetect.join('');
      return this.UNITIES.includes(unity) ? unity : defaultValue;
    },

    _extractOption: function _extractOption(designOptionName, optionsName) {
      var options = this.get(optionsName);
      var designOptions = this.get(designOptionName);
      return options.find(function (obj) {
        return obj.value === designOptions;
      }) || options[0];
    },

    actions: {
      setUnity: function setUnity(designOption, frontElement, unity) {
        this.set('currentDesignOptions.' + frontElement + 'Suffix', unity.value);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, this.get('currentDesignOptions.' + frontElement) + unity.value));
      },

      setValueWithUnity: function setValueWithUnity(designOption, frontElement, value) {
        this.set('currentDesignOptions.' + frontElement, value);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, value + this.get('currentDesignOptions.' + frontElement + 'Suffix')));
      },

      selectValue: function selectValue(frontElement, designOption, selectedElement) {
        this.set('currentDesignOptions.' + frontElement, selectedElement);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, selectedElement.value));
      },

      setScreenSize: function setScreenSize(screenSize) {
        this.set('state', screenSize);
        this.set('selectedScreenSize', screenSize.name);
      },

      setColor: function setColor(frontElement, value) {
        var _designOption;

        value = _dueDashboardHelpersColorFormating['default'].compute(value);
        this.set('currentDesignOptions.' + frontElement, value);
        var designOption = (_designOption = {}, _defineProperty(_designOption, this.get('styleName') + '_background', value), _defineProperty(_designOption, this.get('styleName') + '_background_color', value), _designOption);
        this.sendAction('updateDesignOptions', designOption);
      },

      toggleDisplayCardBackground: function toggleDisplayCardBackground() {
        var _designOptions;

        this.toggleProperty('currentDesignOptions.displayCardBackground');
        var displayCardBackground = this.get('currentDesignOptions.displayCardBackground');
        var backgroundColor = this.get('currentDesignOptions.backgroundColor');
        var backgroundGradient = this.get('currentDesignOptions.backgroundGradient');
        var currentBackground = this.get('currentDesignOptions.currentBackground');
        var color = currentBackground && currentBackground === 'gradient' ? backgroundGradient : backgroundColor;
        var designOptions = (_designOptions = {}, _defineProperty(_designOptions, this.get('styleName') + '_background', displayCardBackground ? color : "none"), _defineProperty(_designOptions, this.get('styleName') + '_display_card_background', this.get('currentDesignOptions.displayCardBackground')), _designOptions);
        this.sendAction('updateDesignOptions', designOptions);
      },

      setCurrentItem: function setCurrentItem(designOption, backgroudType, currentItem) {
        this.sendAction('updateDesignOptions', _defineProperty({}, this.get('styleName') + '_current_background', backgroudType));
        this.sendAction('updateDesignOptions', _defineProperty({}, this.get('styleName') + '_background', this.get('currentDesignOptions.' + designOption)));
        this.set('currentDesignOptions.currentItem', currentItem);
      },

      updateGradient: function updateGradient(value) {
        var _designOptions2;

        this.set('currentDesignOptions.backgroundGradient', value);
        var designOptions = (_designOptions2 = {}, _defineProperty(_designOptions2, this.get('styleName') + '_background', value), _defineProperty(_designOptions2, this.get('styleName') + '_background_gradient', value), _designOptions2);
        this.sendAction('updateDesignOptions', designOptions);
      }
    }
  });
});