define('due-dashboard/models/tag-deep-analysis', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var computed = _ember['default'].computed;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    name: attr('string'),
    profiles_distribution_history: attr('array'),
    total_profiles_distribution: attr('array'),
    date_range: attr(),
    step_type: attr('string'),

    simpleName: computed('name', function () {
      var splittedName = this.get('name').split(' > ');
      return splittedName.pop();
    }),

    path: computed('name', function () {
      var splittedName = this.get('name').split(' > ');
      splittedName.pop();
      var path = splittedName.join(' > ');
      return path && path.length > 0 ? path + ' > ' : null;
    })
  });
});