define('due-dashboard/controllers/companies/templates/edit', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    queryParams: ['bulk_dispatch_id', 'required_languages'],

    actions: {

      saveTempl: function saveTempl() {
        this.send('saveTemplate', this.get('model'), this.get('bulk_dispatch_id'));
      },

      prev: function prev() {
        this.send('previous', this.get('model'), this.get('bulk_dispatch_id'));
      }

    }

  });
});