define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/single-select-design', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['design-element-container'],
    name: 'single_select',
    BUTTON_STATES: [{ value: 'dropdown', name: '_dropdown' }, { value: 'placeholder', name: '_placeholder' }],
    selectedStyleName: '',

    state: computed(function () {
      return this.BUTTON_STATES[0];
    }),

    currentDesignOptions: computed('isLoading', 'designOptions', function () {
      return this.get('designOptions');
    }),

    styleName: computed('selectedStyleName', function () {
      return '' + this.name + this.get('selectedStyleName');
    }),

    isNotPlaceholder: computed('state', function () {
      return this.get('state.value') != 'placeholder';
    }),

    actions: {
      setState: function setState(newState) {
        this.set('state', newState);
        this.set('selectedStyleName', newState.name);
      }
    }
  });
});