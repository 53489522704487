define('due-dashboard/models/graph', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember'], function (exports, _emberData, _emberDataModelFragments, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string', { defaultValue: 'Graphique' }),
    updated_at: _emberData['default'].attr('date'),
    is_favorite: _emberData['default'].attr('boolean', { readOnly: true }),
    lines: _emberDataModelFragments['default'].fragmentArray('graph-line'),
    events: _emberDataModelFragments['default'].fragmentArray('graph-event'),
    reversedLines: _ember['default'].computed('lines.[]', function () {
      return this.get('lines').toArray().reverse();
    })
  });
});