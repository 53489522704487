define('due-dashboard/models/match-segment-and-values-feedback-filter', ['exports', 'due-dashboard/models/feedback-filter', 'ember-data', 'due-dashboard/utils/has-many-through'], function (exports, _dueDashboardModelsFeedbackFilter, _emberData, _dueDashboardUtilsHasManyThrough) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;

  var M = _dueDashboardModelsFeedbackFilter['default'].extend({
    segment: belongsTo('segment', { async: true }),
    match_segment_and_values_feedback_filters_values: hasMany('match-segment-and-values-feedback-filters-values', { async: true }),
    operator: attr(),
    delegate_to_user: attr('boolean', { defaultValue: false }),

    defineSavepoint: function defineSavepoint() {
      var _this = this;

      return this.get('match_segment_and_values_feedback_filters_values').then(function (filter_values) {
        _this.set('_savepoint_filter_values', filter_values.toArray());
        return _this;
      });
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_filter_values');
      if (savepoint) {
        this.set('match_segment_and_values_feedback_filters_values', savepoint);
        savepoint.map(function (a) {
          return a.rollbackAttributes();
        });
      }
    }
  });

  _dueDashboardUtilsHasManyThrough['default'].define(M, 'match_segment_and_values_feedback_filters_values', {
    foreignKey: 'filter',
    hasManyKey: 'filter_values',
    sourceKey: 'value'
  });

  exports['default'] = M;
});