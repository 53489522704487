define('due-dashboard/components/question-item', ['exports', 'ember', 'ember-sortable/mixins/sortable-item'], function (exports, _ember, _emberSortableMixinsSortableItem) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_emberSortableMixinsSortableItem['default'], {
    classNames: ['question-item'],

    attributeBindings: ['dataId:data-id'],
    dataId: computed('question.position', function () {
      return this.get('question.position');
    }),

    error_key: computed('question', function () {
      var question = this.get('question');
      return 'question-' + question.position;
    }),

    placeholder: computed('questions', 'tags', function () {
      var _this = this;

      var questions = this.get('questions');
      var tags = this.get('tags');
      var returned = "";
      if (!questions) return;
      tags.forEach(function (tag) {
        if (questions[tag].closed[_this.get('question').position - 1] && questions[tag].closed[_this.get('question').position - 1].text && questions[tag].closed[_this.get('question').position - 1].text.length > 0) {
          returned = questions[tag].closed[_this.get('question').position - 1].text;
        }
      });
      return returned;
    }),

    validQuestion: computed('question.text', function () {
      return !_ember['default'].isEmpty(this.get('question.text'));
    }),

    actions: {

      questionChanged: function questionChanged() {
        var q = this.get('question');
        if (q.get('hasDirtyAttributes')) {
          q.save();
        }
      },

      deleteQuestion: function deleteQuestion(question) {
        this.sendAction('deleteQuestion', question);
        return true;
      }

    }
  });
});