define('due-dashboard/models/companies-segments-handler', ['exports', 'ember-data', 'due-dashboard/mixins/filthy-model', 'ember-cp-validations'], function (exports, _emberData, _dueDashboardMixinsFilthyModel, _emberCpValidations) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    activity_sector: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = Model.extend(_dueDashboardMixinsFilthyModel['default'], Validations, {
    whitelist_filter: attr('boolean'),
    whitelist_export: attr('boolean'),
    whitelist_chat: attr('boolean'),
    whitelist_verbatim: attr('boolean'),
    whitelist_alert: attr('boolean'),
    whitelist_filter_segments: attr(),
    whitelist_export_segments: attr(),
    whitelist_chat_segments: attr(),
    whitelist_verbatim_segments: attr(),
    whitelist_alert_segments: attr(),
    blacklist_filter_segments: attr(),
    blacklist_export_segments: attr(),
    blacklist_chat_segments: attr(),
    blacklist_verbatim_segments: attr(),
    blacklist_alert_segments: attr()
  });
});