define('due-dashboard/models/aircall-company-integration', ['exports', 'due-dashboard/models/company-integration', 'ember-data', 'ember'], function (exports, _dueDashboardModelsCompanyIntegration, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _dueDashboardModelsCompanyIntegration['default'].extend({
    name: 'aircall',

    api_token: attr(),
    api_id: attr(),
    token: attr(),
    aircall_filters: hasMany('AircallFilters'),

    save: function save() {
      return this._super(arguments).then(function (aircall_ci) {
        var to_delete = aircall_ci.get('aircall_filters').filter(function (filter) {
          return filter.get('isDeleted') || !filter.get('is_valid');
        });
        to_delete.forEach(function (filter) {
          var del_func = filter.get('isNew') ? 'deleteRecord' : 'destroyRecord';
          filter[del_func]();
        });
        return _ember['default'].RSVP.all(aircall_ci.get('aircall_filters').filterBy('isDeleted', false).map(function (filter) {
          return filter.save();
        }));
      });
    }
  });
});