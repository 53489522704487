define("due-dashboard/templates/settings/integrations/aircall/manage", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 7,
                    "column": 8
                  },
                  "end": {
                    "line": 9,
                    "column": 8
                  }
                },
                "moduleName": "due-dashboard/templates/settings/integrations/aircall/manage.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "handle");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("/");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ↕");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1, 0]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element1, 0, 0);
                morphs[1] = dom.createMorphAt(element1, 2, 2);
                return morphs;
              },
              statements: [["content", "filter.position", ["loc", [null, [8, 37], [8, 56]]], 0, 0, 0, 0], ["content", "parent.orderedFilters.length", ["loc", [null, [8, 57], [8, 89]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 6
                },
                "end": {
                  "line": 11,
                  "column": 6
                }
              },
              "moduleName": "due-dashboard/templates/settings/integrations/aircall/manage.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "gt", [["get", "parent.orderedFilters.length", ["loc", [null, [7, 18], [7, 46]]], 0, 0, 0, 0], 1], [], ["loc", [null, [7, 14], [7, 49]]], 0, 0]], [], 0, null, ["loc", [null, [7, 8], [9, 15]]]], ["inline", "aircall-filter", [], ["model", ["subexpr", "@mut", [["get", "filter", ["loc", [null, [10, 31], [10, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "teams", ["subexpr", "@mut", [["get", "parent.aircall_teams", ["loc", [null, [10, 44], [10, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "aircall_users", ["subexpr", "@mut", [["get", "parent.aircall_users", ["loc", [null, [10, 79], [10, 99]]], 0, 0, 0, 0]], [], [], 0, 0], "tags", ["subexpr", "@mut", [["get", "parent.tags", ["loc", [null, [10, 105], [10, 116]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-block"], ["loc", [null, [10, 8], [10, 137]]], 0, 0]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 12,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/settings/integrations/aircall/manage.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "sortable-item", [], ["model", ["subexpr", "@mut", [["get", "filter", ["loc", [null, [6, 29], [6, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "group", ["subexpr", "@mut", [["get", "group", ["loc", [null, [6, 42], [6, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "handle", ".handle"], 0, null, ["loc", [null, [6, 6], [11, 24]]]]],
          locals: ["filter"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 13,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/aircall/manage.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "parent.orderedFilters", ["loc", [null, [5, 12], [5, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 4], [12, 13]]]]],
        locals: ["group"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/aircall/manage.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["get", "button.class", ["loc", [null, [16, 58], [16, 70]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "parent.actions.createFilter", ["loc", [null, [16, 19], [16, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 10], [16, 48]]], 0, 0], ["content", "button.text", ["loc", [null, [16, 74], [16, 89]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 15
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/aircall/manage.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1, "class", "filters-title");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "filters aircall manage");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element2, 0, 0);
        morphs[1] = dom.createMorphAt(element2, 2, 2);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["settings.integrations.aircall.manage.title"], [], ["loc", [null, [1, 26], [1, 76]]], 0, 0], ["inline", "info-tooltip", [], ["message", ["subexpr", "t", ["settings.integrations.aircall.manage.info"], [], ["loc", [null, [2, 25], [2, 72]]], 0, 0]], ["loc", [null, [2, 2], [2, 74]]], 0, 0], ["block", "sortable-group", [], ["tagName", "div", "class", "filters aircall manage", "onChange", ["subexpr", "action", [["get", "parent.actions.reorderFilters", ["loc", [null, [4, 82], [4, 111]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 74], [4, 112]]], 0, 0]], 0, null, ["loc", [null, [4, 2], [13, 21]]]], ["block", "due-button", [], ["type", "action", "icon", "plus", "customClass", "add-filter", "text", ["subexpr", "t", ["settings.integrations.aircall.manage.add_filter"], [], ["loc", [null, [15, 70], [15, 123]]], 0, 0]], 1, null, ["loc", [null, [15, 0], [17, 15]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});