define('due-dashboard/routes/password-reset', ['exports', 'ember', 'due-dashboard/config/environment', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _dueDashboardConfigEnvironment, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {

    model: function model(params) {
      var _this = this;

      return _ember['default'].RSVP.hash({
        password_reset: this.store.find('password-reset', params.token)['catch'](function () {
          if (window.location.origin === _dueDashboardConfigEnvironment['default'].EmberENV['DASHBOARD_URL_CHINA']) {
            return null;
          } else {
            _this.transitionTo('sign-in');
          }
        })
      });
    },

    actions: {
      resetPassword: function resetPassword() {
        var _this2 = this;

        var pr = this.currentModel.password_reset;
        var email = pr.get('email');
        var password = pr.get('password');
        var promise = this.controller.set('promise', this.currentModel.password_reset.save());
        promise.then(function () {
          pr.unloadRecord();
          return _this2.get('session').authenticate('authenticator:oauth2', email, password).then(function () {
            _this2.transitionTo('companies.index');
          });
        });
      }
    }

  });
});