define('due-dashboard/controllers/companies', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    queryParams: ['filterStartDate', 'filterEndDate', 'filters'],
    sidebar: _ember['default'].inject.service(),
    company: _ember['default'].computed.alias('currentAccount.content.company'),

    version: computed('currentAccount.content.company.enable_new_survey_dashboard', function () {
      return this.get('currentAccount.content.company.enable_new_survey_dashboard') ? 'v2' : '';
    })
  });
});