define('due-dashboard/models/company-integration', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var underscore = _ember['default'].String.underscore;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    is_active: attr('boolean'),
    completed: attr('boolean'),
    company_integration_type: attr('string'),

    company: belongsTo('company', { async: true }),

    type: attr('string', { defaultValue: function defaultValue(condition) {
        return underscore(condition.get('modelName'));
      } })
  });
});