define('due-dashboard/routes/companies/graphs/index', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    limit: 3,

    model: function model() {
      var _this = this;

      var account = this.get('currentAccount.content');
      var page = { number: this.controller && this.controller.get('currentPage') || 1, size: this.get('limit') };
      return account.get('company').then(function (company) {
        return {
          account: _this.store.findRecord('account', account.get('id')),
          graphs: _this.store.query('graph', { reload: true, sort: '-updated_at', page: page }),
          company: company
        };
      });
    },

    setFavoriteGraph: function setFavoriteGraph(graph) {
      var account = this.get('currentAccount.content');
      account.set('favorite_graph', graph);
      account.save();
    },

    actions: {

      addCustomGraph: function addCustomGraph() {
        this.transitionTo('companies.graphs.edit', 'new');
      },

      deleteGraph: function deleteGraph(graph) {
        var _this2 = this;

        var message = this.get('i18n').t('graphs.messages.confirm_delete');
        if (window.confirm(message)) {
          graph.destroyRecord().then(function () {
            _this2.currentModel.graphs.removeObject(graph);
          }, function () {
            window.alert(_this2.get('i18n').t('graphs.error.delete_fav'));
          });
        }
      },

      editGraph: function editGraph(graph) {
        this.transitionTo('companies.graphs.edit', graph.get('id'));
      },

      unfavoriteGraph: function unfavoriteGraph() {
        this.setFavoriteGraph(null);
      },

      favoriteGraph: function favoriteGraph(graph) {
        this.setFavoriteGraph(graph);
      }
    }
  });
});