define('due-dashboard/routes/settings/groups/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var account = this.get('currentAccount.content');
      return account.get('company').then(function (company) {
        return _ember['default'].RSVP.hash({
          profile: _this.controller && _this.controller.get('profile') || _this.store.createRecord('account-custom-profile-right'),
          company: company
        });
      });
    }
  });
});