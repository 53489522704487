define('due-dashboard/components/templates/edit-view', ['exports', 'ember', 'npm:due-sms-counter'], function (exports, _ember, _npmDueSmsCounter) {
  /* global _ */

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var _set = _ember['default'].set;
  exports['default'] = Component.extend({
    selectedLanguages: [],
    isUnicode: false,

    targets: computed({
      get: function get() {
        var model = this.get('model'),
            dynamic_attributes = model.get('dynamic_attributes'),
            default_contents = model.get('default_bodies'),
            default_objects = model.get('default_subjects'),
            type = model.get('sending_type'),
            html_contents = model.get('html_contents');
        return model.get('text_contents').map(function (c) {
          var language = c.get('language');
          var da = dynamic_attributes.get('sending_objects').findBy('language.tag', language.get('tag'));
          var html_content = html_contents.findBy('language.tag', language.get('tag')) ? html_contents.findBy('language.tag', language.get('tag')).get('content') ? html_contents.findBy('language.tag', language.get('tag')).get('content') : default_contents[language.get('lower_tag')]['htmlcore'] : default_contents[language.get('lower_tag')]['htmlcore'];
          return {
            content: c.get('content') || default_contents[language.get('lower_tag')][type],
            html_content: html_content,
            language: language,
            sending_object: da && da.get('content') || model.get('sending_type') !== 'sms' && default_objects[language.get('lower_tag')][type] || ""
          };
        });
      },

      set: function set(_, text_values) {
        var model_contents = this.get('model.text_contents'),
            model_html_contents = this.get('model.html_contents'),
            model_sending_objs = this.get('model.dynamic_attributes.sending_objects');
        text_values.forEach(function (element) {
          var tag = get(element, 'language.tag');
          _set(model_contents.findBy('language.tag', tag), 'content', element.content);
          if (element.html_content && model_html_contents.findBy('language.tag', tag)) {
            _set(model_html_contents.findBy('language.tag', tag), 'content', element.html_content);
          }
          _set(model_sending_objs.findBy('language.tag', tag), 'content', element.sending_object);
        });
        this.get('model').notifyPropertyChange('isValid');
        this.notifyPropertyChange('languagesAreValid');
        return text_values;
      }
    }),

    languagesAreValid: computed('required_languages.[]', 'model.text_contents.[].language', function () {
      return _.difference((this.get('required_languages') || "").split(','), this.get('model.text_contents').mapBy('language.tag')).join(', ');
    }),

    isValid: computed('model.isValid', 'languagesAreValid', function () {
      return this.get('model.isValid') && !this.get('languagesAreValid');
    }),

    resources: computed(function () {
      return this.store.peekAll('resources').get('firstObject');
    }),

    maxSizeSms: computed('currentAccount.content.company.max_sms_per_feedback', 'isUnicode', function () {
      var max_sms_per_feedback = this.get('currentAccount.content.company.max_sms_per_feedback');
      var maxSizeSms = this.get('_maxSizeSms');
      if (!maxSizeSms || maxSizeSms.max_sms !== max_sms_per_feedback) {
        maxSizeSms = this.set('_maxSizeSms', Object.assign({ max_sms: max_sms_per_feedback }, _npmDueSmsCounter['default'].calcMaxCharCount(max_sms_per_feedback)));
      }
      return maxSizeSms[this.get('isUnicode') ? 'unicode' : 'gsm7'];
    }),

    messageSize: computed('templateLanguage.content.length', function () {
      var _SmsCounter$calcCharCount = _npmDueSmsCounter['default'].calcCharCount(this.get('templateLanguage.content'));

      var char_count = _SmsCounter$calcCharCount.char_count;
      var is_unicode = _SmsCounter$calcCharCount.is_unicode;

      this.set('isUnicode', is_unicode);
      var stop_msg_length = this.get('currentAccount.content.company.short_url_sms') ? 66 : 52; // 66 is bc 'https://due.st/XXXXX'
      return char_count + stop_msg_length;
    }),

    aliasSize: computed('model.sending_alias.length', function () {
      if (this.get('model.sending_alias.length')) {
        return this.get('model.sending_alias.length');
      }
      return 0;
    }),

    is_bulk_dispatch: (function () {
      return typeof this.get('bulk_dispatch_id') !== 'undefined' && this.get('bulk_dispatch_id') !== "null";
    }).property('bulk_dispatch_id'),

    actions: {

      setAndCheckIsValid: function setAndCheckIsValid() {
        this.set('targets', this.get('targets'));
      },

      toggleIsFavorite: function toggleIsFavorite() {
        this.toggleProperty('model.is_favorite');
      },

      addLanguage: function addLanguage(language) {
        this.get('model.text_contents').createFragment({ language: language });
        this.get('model.html_contents').createFragment({ language: language });
        this.get('model.dynamic_attributes.sending_objects').createFragment({ language: language });
        this.notifyPropertyChange('targets');
        this.notifyPropertyChange('isValid');
        return false;
      },

      removeLanguage: function removeLanguage(language) {
        var tag = get(language, 'tag'),
            contents = this.get('model.text_contents'),
            html_contents = this.get('model.html_contents'),
            content_to_rm = contents.findBy('language.tag', tag),
            html_content_to_rm = html_contents.findBy('language.tag', tag),
            dyn_attr = this.get('model.dynamic_attributes.sending_objects'),
            attrs_to_rm = dyn_attr.findBy('language.tag', tag);
        contents.removeObject(content_to_rm);
        html_contents.removeObject(html_content_to_rm);
        dyn_attr.removeObject(attrs_to_rm);
        this.notifyPropertyChange('targets');
        this.notifyPropertyChange('isValid');
      },

      prevStep: function prevStep() {
        this.transitionTo(history.back());
      },

      nextStep: function nextStep() {
        this.set('languages', this.get('languages'));
        this.sendAction('saveTemplate');
      },

      typeChanged: function typeChanged() {
        this.notifyPropertyChange('targets');
      }

    }
  });
});