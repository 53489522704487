define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/legend-design', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    name: 'legend',

    currentDesignOptions: computed('isLoading', function () {
      return this.get('designOptions');
    })
  });
});