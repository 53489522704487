define('due-dashboard/models/company', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var sort = _ember['default'].computed.sort;
  var Model = _emberData['default'].Model;
  var belongsTo = _emberData['default'].belongsTo;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({

    // must be in a different model
    address_country: attr('string', { readOnly: true }),
    address_street: attr('string', { readOnly: true }),
    address_postcode: attr('string', { readOnly: true }),
    address_city: attr('string', { readOnly: true }),

    days_before_lock: attr('number', { readOnly: true }),
    latitude: attr('string', { readOnly: true }),
    longitude: attr('string', { readOnly: true }),
    telephone: attr('string', { readOnly: true }),
    email: attr('string', { readOnly: true }),
    permalink: attr('string', { readOnly: true }),

    dashboard_logo: attr('string', { readOnly: true }),
    dashboard_banner: attr('string', { readOnly: true }),

    from_email: attr('string', { readOnly: true }),
    has_dashboard_theme: attr('boolean', { readOnly: true }),
    max_sms_per_feedback: attr('number', { readOnly: true }),
    max_digests_limit: attr('number', { readOnly: true }),

    anti_spamming_delay_days: attr('number'),
    anti_spamming_delay_hours: attr('number'),
    max_account: attr('number'),
    name: attr('string'),
    new_feedback_url: attr('string'),
    subscription_status: attr('string'),
    stripe_source_token: attr('string'),
    custom_surveys_legend: attr('string'),
    timezone_id: attr('string'),
    satisfaction_index_100: attr('boolean'),
    show_answer_details: attr('boolean'),
    show_custom_stats: attr('boolean'),
    public_feedback_default: attr('boolean'),
    show_keywords_adjustment: attr('boolean'),
    show_verbatim: attr('boolean'),
    show_analysis: attr('boolean'),
    show_due_digests: attr('boolean'),
    show_public_reviews: attr('boolean'),
    note_by_default: attr('boolean'),
    verbatim_translation: attr('boolean'),
    verbatim_auto_translation: attr('boolean'),
    is_blocked: attr('boolean'),
    saml_enabled: attr('boolean'),
    trial_start: attr('date'),
    created_at: attr('date'),
    logo_image: attr('string'),
    background_image: attr('string'),
    alert_banner_color: attr('string'),
    alert_button_color: attr('string'),
    dashboard_bg_color: attr('string'),
    dashboard_menu_color: attr('string'),
    saml_sso_url: attr('string'),
    saml_metadata_url: attr('string'),
    saml_certificate: attr('string'),
    sort_question_categories_by_position: attr('boolean'),
    has_nps_surveys: attr('boolean'),
    enable_new_survey_dashboard: attr('boolean'),
    enable_new_survey_respondant: attr('boolean'),
    hide_email_in_export: attr('boolean'),
    enabled_root_cause: attr('boolean'),
    resolution_time_threshold: attr('number'),
    short_url_sms: attr('boolean'),
    modal_resolution_enabled: attr('boolean'),
    resolution_popup_for_detractor: attr('boolean'),
    action_taken_enabled: attr('boolean'),
    root_cause_mandatory: attr('boolean'),
    action_taken_mandatory: attr('boolean'),
    replicate_to_china: attr('boolean'),
    saml_sso_url_cn: attr('string'),
    saml_metadata_url_cn: attr('string'),
    saml_certificate_cn: attr('string'),
    timezone_in_dashboard: attr('boolean'),
    timezone_in_digest: attr('boolean'),
    open_feedback_on_note_creation: attr('boolean'),

    custom_http_headers: attr(),

    subscription_plan: belongsTo('SubscriptionPlan', { async: true }),
    language: belongsTo('Language', { async: true }),
    company_notifications: hasMany('company-notification'),

    scenariosSorting: ['position'],
    orderedScenarios: sort('scenarios', 'scenariosSorting')
  });
});