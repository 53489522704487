define('due-dashboard/app', ['exports', 'ember', 'due-dashboard/resolver', 'ember-load-initializers', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardResolver, _emberLoadInitializers, _dueDashboardConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _dueDashboardConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _dueDashboardConfigEnvironment['default'].podModulePrefix,
    Resolver: _dueDashboardResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _dueDashboardConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});