define('due-dashboard/models/magento-survey-filter', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    magento_company_integration: belongsTo('MagentoCompanyIntegration'),
    survey: belongsTo('Survey'),
    template: belongsTo('Template'),
    segment_value: attr()
  });
});