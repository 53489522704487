define('due-dashboard/models/rating-scale', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    is_nps: attr('boolean'),
    scale: attr('number'),
    rating_scale_type: attr('string'),

    // v2 edition ui editables attrs
    name: attr('string'),
    status: attr('string'),
    kind: attr('string'),
    v2_builtin: attr('boolean'),
    default_rating_scale: attr('boolean'),
    ratings_attributes: attr(),
    na_design: attr(),
    updated_at: attr('string'),
    created_at: attr('string'),

    // Relationships
    ratings: hasMany('Rating', { async: true })
  });
});