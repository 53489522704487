define("due-dashboard/templates/components/digests/filters/tag-filter", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 8
              },
              "end": {
                "line": 5,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/components/digests/filters/tag-filter.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat", ["sidebar.filters.tagged.", ["get", "option", ["loc", [null, [4, 48], [4, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 14], [4, 55]]], 0, 0]], [], ["loc", [null, [4, 10], [4, 57]]], 0, 0]],
          locals: ["option"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/filters/tag-filter.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "button-group", [], ["class", "filter-child equal-child", "options", ["subexpr", "@mut", [["get", "taggedOptions", ["loc", [null, [3, 65], [3, 78]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "taggedFilter", ["loc", [null, [3, 88], [3, 100]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [3, 8], [5, 25]]]], ["inline", "due-btn", [], ["action", ["subexpr", "action", ["deleteFilter"], [], ["loc", [null, [6, 25], [6, 48]]], 0, 0], "icon", "tio-clear", "intent", "tertiary", "size", "medium"], ["loc", [null, [6, 8], [6, 99]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 16
                  },
                  "end": {
                    "line": 37,
                    "column": 16
                  }
                },
                "moduleName": "due-dashboard/templates/components/digests/filters/tag-filter.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "tag-badge", [], ["tag", ["subexpr", "@mut", [["get", "item.tag", ["loc", [null, [36, 34], [36, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "feeling", ["subexpr", "@mut", [["get", "feeling", ["loc", [null, [36, 51], [36, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "removeTagAction", ["subexpr", "action", ["deleteTag"], [], ["loc", [null, [36, 75], [36, 95]]], 0, 0], "canClose", true, "canAutoClose", ["subexpr", "@mut", [["get", "autoTagsRights", ["loc", [null, [36, 123], [36, 137]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 18], [36, 140]]], 0, 0]],
              locals: ["feeling"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 14
                },
                "end": {
                  "line": 38,
                  "column": 14
                }
              },
              "moduleName": "due-dashboard/templates/components/digests/filters/tag-filter.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "item.feelings", ["loc", [null, [35, 24], [35, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [35, 16], [37, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 14
                },
                "end": {
                  "line": 40,
                  "column": 14
                }
              },
              "moduleName": "due-dashboard/templates/components/digests/filters/tag-filter.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "tag-badge", [], ["tag", ["subexpr", "@mut", [["get", "item", ["loc", [null, [39, 32], [39, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "removeTagAction", ["subexpr", "action", ["deleteTag"], [], ["loc", [null, [39, 53], [39, 73]]], 0, 0], "canClose", true, "canAutoClose", true, "canAutoClose", ["subexpr", "@mut", [["get", "autoTagsRights", ["loc", [null, [39, 119], [39, 133]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [39, 16], [39, 136]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 12
              },
              "end": {
                "line": 41,
                "column": 12
              }
            },
            "moduleName": "due-dashboard/templates/components/digests/filters/tag-filter.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "item.feelings", ["loc", [null, [34, 20], [34, 33]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [34, 14], [40, 21]]]]],
          locals: ["item"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 10
            },
            "end": {
              "line": 42,
              "column": 10
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/filters/tag-filter.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "input-display-item", [], ["onClick", ["subexpr", "@mut", [["get", "onToggle", ["loc", [null, [27, 22], [27, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "items", ["subexpr", "@mut", [["get", "tagFilterItem", ["loc", [null, [28, 20], [28, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "inputProps", ["subexpr", "@mut", [["get", "dueInputProps", ["loc", [null, [29, 25], [29, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "onInput", ["subexpr", "action", ["searchTags"], [], ["loc", [null, [30, 22], [30, 43]]], 0, 0]], 0, null, ["loc", [null, [26, 12], [41, 35]]]]],
        locals: ["onToggle"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/components/digests/filters/tag-filter.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "digest-section-tags-filter");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "tags-filter-management");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [2]), 1, 1);
        return morphs;
      },
      statements: [["block", "labeled-input", [], ["label", ["subexpr", "t", ["digest.filter.tag"], [], ["loc", [null, [2, 25], [2, 48]]], 0, 0], "contentClass", "tag-filter-selector with-remove-button"], 0, null, ["loc", [null, [2, 2], [7, 22]]]], ["attribute", "class", ["concat", ["labeled-input tag-spacing filter-tag ", ["subexpr", "if", [["subexpr", "eq", [["get", "taggedFilter", ["loc", [null, [8, 62], [8, 74]]], 0, 0, 0, 0], "both"], [], ["loc", [null, [8, 58], [8, 82]]], 0, 0], "hidden"], [], ["loc", [null, [8, 53], [8, 93]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "due-dropdown/due-dropdown-multilevel", [], ["fullView", true, "searchEnabled", false, "keyDisplay", "title", "options", ["subexpr", "@mut", [["get", "realTags", ["loc", [null, [15, 20], [15, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "closeOnSelect", false, "onSelect", ["subexpr", "action", ["addTag"], [], ["loc", [null, [17, 21], [17, 38]]], 0, 0], "additionalOptionsEnabled", ["subexpr", "@mut", [["get", "withSentiment", ["loc", [null, [18, 37], [18, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "keyAdditionalOptions", "feeling_enabled", "additionalSentimentOptions", true, "disabledSentiments", ["subexpr", "@mut", [["get", "tagSentimentFilter", ["loc", [null, [21, 31], [21, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "scrollHeight", 200], 1, null, ["loc", [null, [11, 10], [42, 51]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});