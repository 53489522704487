define('due-dashboard/routes/companies/analysis/with-tags', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var service = _ember['default'].inject.service;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    filters: service(),
    controllerName: 'companies.analysis.without-tags.index',
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('tag_filter', 'tagged');
    },
    model: function model() {
      var filters = this.get('filters').get('queryFilters');
      var verbatim_filters = this.get('filters').get('queryFilters');
      verbatim_filters.filter.tagged = 'tagged';
      return {
        tag_analyses: this.store.query('tag-analysis', _extends({ id: 'all' }, filters)),
        company: this.get('currentAccount.content.company'),
        keywords: this.store.query('keyword', { filter: filters['filter'], sort: '-counter', page: { number: 1, size: 5 } }),
        verbatims: this.store.query('verbatim', verbatim_filters),
        segmentsToDisplay: this.store.query('segment', { filter: { display: 'chat' } }),
        displaySettings: this.store.query('verbatim-display-option', { filter: { account_id: this.currentAccount.content.id } }),
        languages: this.store.query('language', { filter: { account_id: this.currentAccount.content.id } })
      };
    },

    actions: {

      debouncedSearchAlias: function debouncedSearchAlias() {
        _ember['default'].run.debounce(this, this.resetPageAndUpdate, 500);
      },

      searchKeyword: function searchKeyword(keyword) {
        if (this.get('selectedKeywords').indexOf(keyword) !== -1) {
          this.get('selectedKeywords').removeObject(keyword);
        } else {
          this.get('selectedKeywords').addObject(keyword);
        }
        this.get('selectedKeywords').arrayContentDidChange();
        this.updateVerbatimsList();
      }
    }
  });
});