define("due-dashboard/templates/components/labeled-input", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 37
            },
            "end": {
              "line": 1,
              "column": 85
            }
          },
          "moduleName": "due-dashboard/templates/components/labeled-input.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "info-tooltip", [], ["message", ["subexpr", "@mut", [["get", "tooltip", ["loc", [null, [1, 76], [1, 83]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 53], [1, 85]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 92
            },
            "end": {
              "line": 1,
              "column": 138
            }
          },
          "moduleName": "due-dashboard/templates/components/labeled-input.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "red-star");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/labeled-input.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 0, 0);
        morphs[2] = dom.createMorphAt(element0, 1, 1);
        morphs[3] = dom.createMorphAt(element0, 2, 2);
        morphs[4] = dom.createAttrMorph(element1, 'class');
        morphs[5] = dom.createMorphAt(element1, 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["get", "labelClass", ["loc", [null, [1, 15], [1, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "label", ["loc", [null, [1, 28], [1, 37]]], 0, 0, 0, 0], ["block", "if", [["get", "tooltip", ["loc", [null, [1, 43], [1, 50]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 37], [1, 92]]]], ["block", "if", [["get", "required", ["loc", [null, [1, 98], [1, 106]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [1, 92], [1, 145]]]], ["attribute", "class", ["get", "contentClass", ["loc", [null, [2, 13], [2, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [2, 28], [2, 37]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});