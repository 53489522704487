define('due-dashboard/components/add-companies-segment', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    modalIsOpened: false,
    dataTypes: ['personal_data', 'sensitive_personal_data', 'not_personal_data'],
    dataSubjects: ['consultant', 'contractor', 'customer', 'employee', 'other', 'prospect', 'suppliers', 'trainee', 'visitor'],
    monitoredFocus: false,
    filteredCategories: [],

    disabledSave: computed('newName', 'newType', 'newSubject', function () {
      return !this.get('newName') || !this.get('newType') || !this.get('newSubject');
    }),

    newCategoryArray: computed('newCategory', function () {
      if (this.get('newCategory')) {
        return [this.get('newCategory')];
      }
      return [];
    }),

    displayedCategories: computed('filteredCategories', function () {
      var categories = this.get('filteredCategories');
      var index = categories.indexOf(this.get('newCategory'));
      if (index >= 0) {
        categories.splice(index, 1);
      }
      return categories;
    }),

    dueInputProps: computed('i18n.locale', 'current_company', function () {
      return {
        'class': 'filter-label',
        label: this.get('i18n').t('settings.company_segment.category'),
        placeholder: this.get('i18n').t('settings.company_segment.create_or_choose_category')
      };
    }),

    multiDropdownDynamicOptionProps: computed('i18n.locale', 'current_company', 'newCategoryName', function () {
      return {
        label: this.get('i18n').t('settings.company_segment.create_category'),
        icon: 'tio-add',
        color: '#23afcb',
        component: 'v2/multi-dropdown-dynamic-options/settings/segments/create-segment-category',
        onClick: 'createCategory',
        value: this.get('newCategoryName')
      };
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._searchCategory('');
    },

    closeModal: function closeModal() {
      this.set('modalIsOpened', false);
    },

    _searchCategory: function _searchCategory(term) {
      var _this = this;

      var searchPromise = this.store.query('companies-segment', {
        filter: { category: term }
      });
      this.set('search_promise', searchPromise);
      searchPromise.then(function (segments) {
        var categories = segments.map(function (segment) {
          return segment.get('category');
        });
        var existingCategory = categories.find(function (element) {
          return element == term;
        });
        if (term && !existingCategory && term != _this.get('newCategory')) {
          _this.set('displayDynamicOption', true);
        }
        _this.set('filteredCategories', categories);
      });
    },

    verifyCategoryName: function verifyCategoryName() {
      var _this2 = this;

      this.set('showSegmentError', false);
      this.set('showCharacterError', false);
      if (this.get('newCategory') === "") {
        this.set('newCategory', null);
      }
      if (this.get('newCategory')) {
        var forbiddenCategoryNames = ['segments', 'segment', 'no category'];
        var found = forbiddenCategoryNames.find(function (element) {
          return element == _this2.get('newCategory').toLowerCase();
        });
        if (found) {
          this.set('showSegmentError', true);
          return false;
        }
        var format = /[!-\/:-@[-`{-~]/;
        if (format.test(this.get('newCategory'))) {
          this.set('showCharacterError', true);
          return false;
        }
      }
      return true;
    },

    actions: {

      searchCategory: function searchCategory(onToggle, term) {
        this.set('displayDynamicOption', false);
        this.set('monitoredFocus', true);
        this.set('newCategoryName', term);
        onToggle('open');
        this._searchCategory(term);
      },

      addCategory: function addCategory(category) {
        this.set('newCategory', null);
        this.set('newCategory', category);
        this.set('displayDynamicOption', false);
        this.set('newCategoryName', null);
        this._searchCategory('');
      },

      createCategory: function createCategory() {
        this.set('newCategory', this.get('newCategoryName'));
        this.set('displayDynamicOption', false);
        this.set('newCategoryName', null);
        this._searchCategory('');
      },

      dispatchActions: function dispatchActions(action) {
        this.send(action);
      },

      onDeleteCategory: function onDeleteCategory() {
        this.set('newCategory', null);
        this.set('displayDynamicOption', false);
        this._searchCategory(this.get('newCategoryName'));
      },

      openModal: function openModal() {
        this.set('newName', '');
        this.set('newCategory', '');
        this.set('newDescription', '');
        this.set('newType', 'personal_data');
        this.set('newSubject', 'customer');
        this.set('newDelay', null);
        this.set('segmentExists', false);
        this.set('showDelayError', false);
        this.set('showSegmentError', false);
        this.set('showCharacterError', false);
        this.set('modalIsOpened', true);
      },

      closeModal: function closeModal() {
        this.closeModal();
      },

      clearDelay: function clearDelay() {
        this.set('newDelay', null);
      },

      saveSegment: function saveSegment() {
        var _this3 = this;

        this.set('showDelayError', this.get('newDelay') && this.get('newDelay') < 1);
        if (!this.verifyCategoryName()) {
          return;
        }
        if (this.get('showDelayError')) {
          return;
        }
        var newName = this.get('newName');
        var companiesSegments = this.get('store').peekAll('companies_segment');
        if (companiesSegments.find(function (companiesSegment) {
          return companiesSegment.get('name') === newName;
        })) {
          this.set('segmentExists', true);
        } else {
          this.get('store').createRecord('companies_segment', {
            name: newName,
            description: this.get('newDescription'),
            data_type: this.get('newType'),
            data_subject: this.get('newSubject'),
            category: this.get('newCategory') ? this.get('newCategory').toLowerCase() : null,
            anonymization_delay: this.get('newDelay')
          }).save().then(function (addedSegment) {
            _this3.get('notifySegmentCreation')(addedSegment);
          });
          this.closeModal();
        }
      }
    }
  });
});