define('due-dashboard/serializers/survey-language', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    currentAccount: _ember['default'].inject.service(),
    attrs: {
      submit_button_text: { serialize: false },
      question_word: { serialize: false },
      thank_you_message: { serialize: false },
      nps_text: { serialize: false }
    }
  });
});