define("due-dashboard/templates/components/tags/merge-tag", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 4
                },
                "end": {
                  "line": 12,
                  "column": 4
                }
              },
              "moduleName": "due-dashboard/templates/components/tags/merge-tag.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "tag-to-merge");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "fa fa-tag");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var element4 = dom.childAt(element3, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element3, 'style');
              morphs[1] = dom.createAttrMorph(element4, 'style');
              morphs[2] = dom.createMorphAt(element3, 2, 2);
              return morphs;
            },
            statements: [["attribute", "style", ["concat", ["background-color:", ["get", "tag.color", ["loc", [null, [9, 57], [9, 66]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", ["color:", ["get", "tag.color", ["loc", [null, [10, 44], [10, 53]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "tag.name", ["loc", [null, [10, 61], [10, 73]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 4
                  },
                  "end": {
                    "line": 21,
                    "column": 4
                  }
                },
                "moduleName": "due-dashboard/templates/components/tags/merge-tag.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "fa fa-tag");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element2, 'style');
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["attribute", "style", ["concat", ["color:", ["get", "tag.color", ["loc", [null, [20, 40], [20, 49]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "tag.name", ["loc", [null, [20, 57], [20, 69]]], 0, 0, 0, 0]],
              locals: ["tag"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 4
                },
                "end": {
                  "line": 22,
                  "column": 4
                }
              },
              "moduleName": "due-dashboard/templates/components/tags/merge-tag.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "power-select", [], ["searchEnabled", true, "options", ["subexpr", "@mut", [["get", "tagsList", ["loc", [null, [16, 16], [16, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selectedTag", ["loc", [null, [17, 17], [17, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", ["setDestinationTag"], [], ["loc", [null, [18, 17], [18, 45]]], 0, 0]], 0, null, ["loc", [null, [14, 4], [21, 21]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 8
                },
                "end": {
                  "line": 28,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/tags/merge-tag.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(element1, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", [["get", "button.class", ["loc", [null, [27, 49], [27, 61]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["closeModal"], [], ["loc", [null, [27, 16], [27, 39]]], 0, 0], ["content", "button.text", ["loc", [null, [27, 65], [27, 80]]], 0, 0, 0, 0]],
            locals: ["button"],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 8
                },
                "end": {
                  "line": 33,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/tags/merge-tag.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createElementMorph(element0);
              morphs[2] = dom.createMorphAt(element0, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", [["get", "button.class", ["loc", [null, [32, 46], [32, 58]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["saveTag"], [], ["loc", [null, [32, 16], [32, 36]]], 0, 0], ["content", "button.text", ["loc", [null, [32, 62], [32, 77]]], 0, 0, 0, 0]],
            locals: ["button"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 0
              },
              "end": {
                "line": 36,
                "column": 0
              }
            },
            "moduleName": "due-dashboard/templates/components/tags/merge-tag.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("h2");
            var el2 = dom.createTextNode("Merge tags");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "labeled-form");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-buttons");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "grey-button");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "blue-button");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [2]);
            var element6 = dom.childAt(fragment, [4]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element5, 1, 1);
            morphs[1] = dom.createMorphAt(element5, 2, 2);
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
            return morphs;
          },
          statements: [["block", "labeled-input", [], ["label", "Merge all 9000 responses from tag"], 0, null, ["loc", [null, [8, 4], [12, 22]]]], ["block", "labeled-input", [], ["label", "to selected tag"], 1, null, ["loc", [null, [13, 4], [22, 22]]]], ["block", "due-button", [], ["type", "cancel", "text", ["subexpr", "t", ["words.cancel"], [], ["loc", [null, [26, 41], [26, 59]]], 0, 0]], 2, null, ["loc", [null, [26, 8], [28, 23]]]], ["block", "due-button", [], ["disabled", ["subexpr", "@mut", [["get", "disabledSave", ["loc", [null, [31, 31], [31, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "action", "text", "Merge"], 3, null, ["loc", [null, [31, 8], [33, 23]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 37,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/tags/merge-tag.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-dialog", [], ["close", "closeModal", "container-class", "tags-modal", "translucentOverlay", true], 0, null, ["loc", [null, [3, 0], [36, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 37,
            "column": 7
          }
        },
        "moduleName": "due-dashboard/templates/components/tags/merge-tag.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("i");
        dom.setAttribute(el1, "class", "fa fa-code-fork");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element7, 'onclick');
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "onclick", ["subexpr", "action", ["openModal"], [], ["loc", [null, [null, null], [1, 57]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "modalIsOpened", ["loc", [null, [2, 6], [2, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [37, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});