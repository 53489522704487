define('due-dashboard/routes/account-activation', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    model: function model(params) {
      var _this = this;

      return _ember['default'].RSVP.hash({
        password_reset: this.store.find('password-reset', params.token)['catch'](function () {
          _this.transitionTo('sign-in');
        })
      });
    },

    renderTemplate: function renderTemplate() {
      this.render('passwordReset', {
        controller: 'passwordReset',
        model: this.currentModel
      });
    },

    actions: {
      resetPassword: function resetPassword() {
        var _this2 = this;

        var pr = this.currentModel.password_reset;
        var email = pr.get('email');
        var password = pr.get('password');
        var promise = this.controllerFor('passwordReset').set('promise', pr.save());
        promise.then(function () {
          pr.unloadRecord();

          return _this2.get('session').authenticate('authenticator:oauth2', email, password).then(function () {
            _this2.transitionTo('companies.index');
          })['catch'](function (e) {
            return _this2.set('lastError', e);
          });
        });
      }
    }

  });
});