define('due-dashboard/models/companies-segment', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({

    name: attr('string'),
    description: attr('string'),
    data_subject: attr('string'),
    data_type: attr('string'),
    category: attr('string'),
    anonymization_delay: attr('number'),
    is_system: attr('boolean'),
    segment: belongsTo('segment')
  });
});