define('due-dashboard/components/list-segments', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    store: _ember['default'].inject.service(),
    allSegments: [],
    searchRight: '',
    queriedSegments: [],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!this.get('selectedSegments')) {
        return;
      }
      this._searchSegments("");
    },

    unselectedSegments: _ember['default'].computed.setDiff('queriedSegments', 'selectedSegments'),

    listRight: _ember['default'].computed('selectedSegments', 'selectedSegments.[]', 'searchRight', function () {
      var _this = this;

      if (!this.get('selectedSegments')) {
        return;
      }
      return this.get('selectedSegments').filter(function (segment) {
        if (typeof segment.name == "string") return segment.name.toLowerCase().includes(_this.get('searchRight').toLowerCase());else return segment.get('name').toLowerCase().includes(_this.get('searchRight').toLowerCase());
      });
    }),

    canHide: _ember['default'].computed('selectedSegments', function () {
      if (this.get('selectedSegments').length != 0) {
        return;
      }
      return 'disabled';
    }),

    canAddAll: _ember['default'].computed('unselectedSegments', function () {
      if (this.get('unselectedSegments') && this.get('unselectedSegments').length != 0) {
        return;
      }
      return 'disabled';
    }),

    canAddSome: _ember['default'].computed('unselectedSegments', function () {
      var clickeds = [];
      if (this.get('unselectedSegments')) {
        this.get('unselectedSegments').forEach(function (companies_segment) {
          if (companies_segment.selected) {
            clickeds.push(companies_segment);
          }
        });
        if (clickeds.length != 0) {
          return;
        }
      }
      return 'disabled';
    }),

    canRemoveSome: _ember['default'].computed('selectedSegments', function () {
      var clickeds = [];
      if (this.get('selectedSegments')) {
        this.get('selectedSegments').forEach(function (companies_segment) {
          if (companies_segment.selected) {
            clickeds.push(companies_segment);
          }
        });
        if (clickeds.length != 0) {
          return;
        }
      }
      return 'disabled';
    }),

    canRemoveAll: _ember['default'].computed('selectedSegments', function () {
      if (this.get('selectedSegments') && this.get('selectedSegments').length != 0) {
        return;
      }
      return 'disabled';
    }),

    canShow: _ember['default'].computed('selectedSegments', 'allSegments', function () {
      if (this.get('selectedSegments').length != this.get('allSegments').toArray().length) {
        return;
      }
      return 'disabled';
    }),

    canPaste: _ember['default'].computed('listSwap', function () {
      if (this.get('listSwap')) {
        return;
      }
      return 'disabled';
    }),

    _searchSegments: function _searchSegments(term, resolve, reject) {
      var _this2 = this;

      var excludedSegments = [];
      this.get('selectedSegments').toArray().forEach(function (segment) {
        return excludedSegments.push(typeof segment.name == "string" ? segment.name : segment.get('name'));
      });
      this.get('store').query('segment', {
        filter: {
          name: term,
          excluded_names: excludedSegments.join('#$#')
        },
        page: {
          number: 1,
          size: 200
        }
      }).then(function (segments) {
        return _this2.set('queriedSegments', segments.toArray().map(function (elem) {
          return {
            name: elem.get('name'),
            segment_id: elem.get('id')
          };
        }));
      }, reject);
    },

    clickedSegments: function clickedSegments(list) {
      var clickeds = [];
      this.get(list).forEach(function (companies_segment) {
        if (companies_segment.selected) {
          _ember['default'].set(companies_segment, 'selected', null);
          clickeds.push(companies_segment);
        }
      });
      return clickeds;
    },

    actions: {

      searchSegments: function searchSegments(term) {
        var _this3 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this3, _this3._searchSegments, term, resolve, reject, 600);
        });
      },

      hideAll: function hideAll() {
        this.set('selectedSegments', []);
        this.set("needSaving", true);
      },

      showAll: function showAll() {
        this.set('selectedSegments', this.get('allSegments').toArray().map(function (elem) {
          return {
            name: elem.get('name'),
            segment_id: elem.get('id')
          };
        }));
        this.set("needSaving", true);
      },

      segmentClicked: function segmentClicked(segment) {
        segment.selected ? _ember['default'].set(segment, 'selected', false) : _ember['default'].set(segment, 'selected', "selected");
        this.notifyPropertyChange('selectedSegments');
      },

      addSegments: function addSegments() {
        this.get("selectedSegments").addObjects(this.clickedSegments("unselectedSegments"));
        this.notifyPropertyChange('selectedSegments');
        this.set("needSaving", true);
      },

      removeSegments: function removeSegments() {
        var _this4 = this;

        this.set("needSaving", true);
        this.get("selectedSegments").removeObjects(this.clickedSegments("selectedSegments"));
        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this4, _this4._searchSegments, '', resolve, reject, 600);
        });
      },

      copy: function copy() {
        this.set('listSwap', this.get('selectedSegments'));
      },

      paste: function paste() {
        if (confirm(this.get('i18n').t('settings.segments.confirm_paste'))) {
          this.set("needSaving", true);
          this.set('selectedSegments', this.get('listSwap').toArray().map(function (elem) {
            return {
              name: elem.name,
              segment_id: elem.segment_id
            };
          }));
        }
      }
    }
  });
});