define("due-dashboard/templates/components/loading-skeleton/due-number-skeleton", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/loading-skeleton/due-number-skeleton.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-skeleton/due-animate-skeleton", [], ["width", ["subexpr", "@mut", [["get", "recWidth", ["loc", [null, [5, 52], [5, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "xOffset", ["subexpr", "@mut", [["get", "xOffset", ["loc", [null, [5, 69], [5, 76]]], 0, 0, 0, 0]], [], [], 0, 0], "windowWidth", ["subexpr", "@mut", [["get", "windowWidth", ["loc", [null, [5, 89], [5, 100]]], 0, 0, 0, 0]], [], [], 0, 0], "animationUID", ["subexpr", "@mut", [["get", "animationUID", ["loc", [null, [5, 114], [5, 126]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [5, 6], [5, 128]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/loading-skeleton/due-number-skeleton.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "svg-ctn");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2, "fill", "none");
        dom.setAttribute(el2, "xmlns", "http://www.w3.org/2000/svg");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("rect");
        dom.setAttribute(el3, "rx", "10");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [5]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'viewBox');
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(element0, 3, 3);
        morphs[5] = dom.createAttrMorph(element1, 'width');
        morphs[6] = dom.createAttrMorph(element1, 'height');
        morphs[7] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [["attribute", "width", ["get", "recWidth", ["loc", [null, [2, 15], [2, 23]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "height", ["get", "recHeight", ["loc", [null, [2, 35], [2, 44]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "viewBox", ["concat", ["0 0 ", ["get", "recWidth", ["loc", [null, [2, 62], [2, 70]]], 0, 0, 0, 0], " ", ["get", "recHeight", ["loc", [null, [2, 75], [2, 84]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "emptyState", ["loc", [null, [3, 10], [3, 24]]], 0, 0, 0, 0], ["block", "if", [["get", "rendered", ["loc", [null, [4, 10], [4, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 4], [6, 11]]]], ["attribute", "width", ["get", "recWidth", ["loc", [null, [7, 18], [7, 26]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "height", ["get", "recHeight", ["loc", [null, [7, 38], [7, 47]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "fill", ["concat", [["get", "computedAnimationUID", ["loc", [null, [7, 66], [7, 86]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});