define("due-dashboard/translations/de", ["exports"], function (exports) {
  exports["default"] = {
    "de": {
      "survey_preview": {
        "social": {
          "title": "Danke!"
        }
      }
    }
  };
});