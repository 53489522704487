define("due-dashboard/templates/components/scenario-click-rate-chart", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 8
                },
                "end": {
                  "line": 28,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/scenario-click-rate-chart.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "click-icon-wrapper");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "legend");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "percentage");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("%");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "counter");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" / ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1, 1]);
              var element2 = dom.childAt(element0, [7]);
              var morphs = new Array(8);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createAttrMorph(element0, 'onmouseover');
              morphs[2] = dom.createAttrMorph(element0, 'onmouseout');
              morphs[3] = dom.createAttrMorph(element1, 'class');
              morphs[4] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
              morphs[5] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
              morphs[6] = dom.createMorphAt(element2, 0, 0);
              morphs[7] = dom.createMorphAt(element2, 2, 2);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["legend-wrapper ", ["subexpr", "get", [["get", "click_data", ["loc", [null, [19, 43], [19, 53]]], 0, 0, 0, 0], "0"], [], ["loc", [null, [19, 37], [19, 59]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onmouseover", ["subexpr", "action", ["focusData", ["subexpr", "get", [["get", "click_data", ["loc", [null, [19, 99], [19, 109]]], 0, 0, 0, 0], "0"], [], ["loc", [null, [19, 94], [19, 114]]], 0, 0]], [], ["loc", [null, [null, null], [19, 116]]], 0, 0], 0, 0, 0, 0], ["attribute", "onmouseout", ["subexpr", "action", ["unfocusData"], [], ["loc", [null, [null, null], [20, 27]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["square-color ", ["subexpr", "get", [["get", "click_data", ["loc", [null, [22, 45], [22, 55]]], 0, 0, 0, 0], "0"], [], ["loc", [null, [22, 39], [22, 61]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat", ["components.scenario-click-rate-chart.legend.", ["subexpr", "get", [["get", "click_data", ["loc", [null, [24, 96], [24, 106]]], 0, 0, 0, 0], "0"], [], ["loc", [null, [24, 91], [24, 111]]], 0, 0]], [], ["loc", [null, [24, 36], [24, 112]]], 0, 0]], [], ["loc", [null, [24, 32], [24, 114]]], 0, 0], ["inline", "get", [["get", "click_data", ["loc", [null, [25, 42], [25, 52]]], 0, 0, 0, 0], "2"], [], ["loc", [null, [25, 36], [25, 58]]], 0, 0], ["inline", "get", [["get", "click_data", ["loc", [null, [26, 39], [26, 49]]], 0, 0, 0, 0], "1"], [], ["loc", [null, [26, 33], [26, 55]]], 0, 0], ["content", "data.total_clicks", ["loc", [null, [26, 58], [26, 79]]], 0, 0, 0, 0]],
            locals: ["click_data"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 4
              },
              "end": {
                "line": 30,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/scenario-click-rate-chart.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "legends");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "dataChunk", ["loc", [null, [18, 16], [18, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [18, 8], [28, 17]]]]],
          locals: ["dataChunk"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 32,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/scenario-click-rate-chart.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "c3-wrapper");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "icon-wrapper");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "fa fa-hand-pointer-o");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "center-current-hovered");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "legends-wrapper");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h5");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(element3, 3, 3);
          return morphs;
        },
        statements: [["inline", "c3-chart", [], ["data", ["subexpr", "@mut", [["get", "donutData", ["loc", [null, [8, 11], [8, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "donut", ["subexpr", "@mut", [["get", "chartDonut", ["loc", [null, [9, 12], [9, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "legend", ["subexpr", "hash", [], ["show", false], ["loc", [null, [10, 13], [10, 30]]], 0, 0], "tooltip", ["subexpr", "hash", [], ["show", false], ["loc", [null, [11, 14], [11, 31]]], 0, 0]], ["loc", [null, [7, 4], [12, 6]]], 0, 0], ["inline", "t", ["components.scenario-click-rate-chart.clicks_distribution"], [], ["loc", [null, [15, 8], [15, 72]]], 0, 0], ["block", "each", [["get", "chunkedData", ["loc", [null, [16, 12], [16, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [16, 4], [30, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/scenario-click-rate-chart.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "data", ["loc", [null, [1, 6], [1, 10]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [32, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});