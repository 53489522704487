define('due-dashboard/models/template-content', ['exports', 'ember-data-model-fragments', 'ember-data', 'ember'], function (exports, _emberDataModelFragments, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberDataModelFragments['default'].Fragment.extend({
    language: attr(),
    content: attr(),

    isValid: _ember['default'].computed('language', 'content', function () {
      return (this.get('content') === undefined || !!this.get('content').trim()) && !!this.get('language');
    })
  });
});