define('due-dashboard/components/v2/-editable-label', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['due-editable-label'],
    classNameBindings: ['disabled'],
    state: 'label',
    cmpFocus: false,

    init: function init() {
      this._super.apply(this, arguments);
      var forceInput = this.get('alwaysInput');

      if (forceInput) {
        this.set('state', 'edition');
      }
    },

    actions: {
      toggleEditionState: function toggleEditionState(source) {
        var onChangeFunc = this.get('onChange');
        var clickOutsideFunc = this.get('clickOutsideCallback');
        var state = this.get('state');
        var error = this.get('inputError');
        var disabled = this.get('disabled');
        var frozen = this.get('frozen');
        var cmpFocus = this.get('cmpFocus');
        var forceInput = this.get('alwaysInput');
        var states = {
          'inside': 'edition',
          'outside': 'label'
        };

        if (source === 'outside' && state === 'label' || disabled || frozen || source === 'outside' && error) {
          return;
        }

        if ((state === 'edition' && source === 'outside' || forceInput) && onChangeFunc) {
          if (forceInput && clickOutsideFunc && cmpFocus) {
            this.set('cmpFocus', false);
            clickOutsideFunc();
            return;
          }
        }

        if (!this.isDestroyed && cmpFocus) {
          this.set('state', states[source]);
        }
        var inputId = this.get('inputId');
        if (source === 'inside' && inputId) {
          (function () {
            var input = document.getElementById(inputId);
            setTimeout(function () {
              input.focus();
            }, 0);
          })();
        }

        if (clickOutsideFunc && source === 'outside' && cmpFocus) {
          clickOutsideFunc();
        }
        if (onChangeFunc && source === 'outside' && !forceInput) {
          onChangeFunc(this.get('value'));
        }

        var callback = this.get('getStateCallback');
        if (callback && source === 'inside' && state === 'label') {
          callback();
        }
        if (callback && source === 'outside' && state === 'edition') {
          callback(true);
        }
      },

      toggleEditionStateFocusIn: function toggleEditionStateFocusIn(source) {
        this.send('onFocusIn');
        this.send('toggleEditionState', source);
      },

      onChangeValue: function onChangeValue(_, event) {
        var value = this.get('value');
        var maxSize = this.get('maxInputSize') || -1;
        var onChangeFunc = this.get('onChange');

        if (maxSize > 0 && value.length > maxSize) {
          this.set('value', value.slice(0, 3));
        }

        if (event && event.originalEvent.code === 'Enter') {
          onChangeFunc(this.get('value'));
          this.send('toggleEditionState', 'outside');
        }

        if (onChangeFunc) {
          onChangeFunc(this.get('value'));
        }
      },

      onFocusOut: function onFocusOut() {
        var onChangeFunc = this.get('onChange');
        if (onChangeFunc) {
          onChangeFunc(this.get('value'));
          this.send('toggleEditionState', 'outside');
        }
      },

      onFocusIn: function onFocusIn() {
        this.set('cmpFocus', true);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-editable-input}} input/label component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */