define('due-dashboard/components/tags/edit-tag', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'span',
    modalIsOpened: false,

    init: function init() {
      this.set('surveys', this.get('tag.surveys').toArray() || []);
      this.set('surveyLimited', this.get('tag.survey_limited'));
      this._super.apply(this, arguments);
    },

    currentTab: computed('kind', function () {
      var i18n = this.get('i18n');
      var options = {
        'tag': {
          title: i18n.t('settings.tags.edit_tag_button'),
          inputName: i18n.t('settings.tags.tag_name'),
          assignability: { title: i18n.t('settings.tags.assignability.tag.title'), options: 'settings.tags.assignability.tag.' },
          color: i18n.t('settings.tags.tag_color'),
          error: {
            name: i18n.t('settings.tags.error.tag_name'),
            duplicate: i18n.t('settings.tags.error.tag_duplicated')
          }
        },
        'root_cause': {
          title: i18n.t('settings.tags.edit_root_cause_btn'),
          inputName: i18n.t('settings.tags.root_cause_name'),
          assignability: { title: i18n.t('settings.tags.assignability.root_cause.title'), options: 'settings.tags.assignability.root_cause.' },
          color: i18n.t('settings.tags.root_cause_color'),
          error: {
            name: i18n.t('settings.tags.error.root_cause_name'),
            duplicate: i18n.t('settings.tags.error.root_cause_duplicate')
          }
        },
        'action_taken': {
          title: i18n.t('settings.tags.edit_action_taken'),
          inputName: i18n.t('settings.tags.action_taken_name'),
          assignability: { title: i18n.t('settings.tags.assignability.action_taken.title'), options: 'settings.tags.assignability.action_taken.' },
          color: i18n.t('settings.tags.action_taken_color'),
          error: {
            name: i18n.t('settings.tags.error.action_taken_name'),
            duplicate: i18n.t('settings.tags.error.action_taken_duplicate')
          }
        }
      };
      return options[this.get('kind')];
    }),

    surveyFilterType: computed('surveyLimited', 'surveys', {
      get: function get() {
        if (this.get('surveyLimited')) {
          return 'only_selected';
        }
        return 'all';
      }, set: function set(_, value) {
        if (value === 'all') {
          this.set('surveys', []);
          this.set('surveyLimited', false);
        }
        return value;
      }
    }),

    hasErrorInTagName: computed('tagObjectName', function () {
      return this.get('tagObjectName') && this.get('tagObjectName').includes('>');
    }),

    disabledSave: computed('tagObjectName', 'selectedTagLanguage', 'hasErrorInTagName', function () {
      return !this.get('tagObjectName') || !this.get('selectedTagLanguage') || this.get('hasErrorInTagName');
    }),

    closeModal: function closeModal() {
      this.set('modalIsOpened', false);
      this.set('surveys', this.get('tag.surveys').toArray() || []);
      this.set('surveyLimited', this.get('tag.survey_limited'));
    },

    selectedTagColor: computed('tag', function () {
      return this.get('tag.color');
    }),

    tagObjectName: computed('tag', function () {
      return this.get('tag.displayName');
    }),

    selectedTagLanguage: computed('tag', function () {
      return this.get('tag.language');
    }),

    tagAssignability: computed('tag.not_assignable', function () {
      return this.get('tag.not_assignable') ? 'category' : 'standard';
    }),

    initFields: function initFields() {
      this.set('tagAssignability', !this.get('tag.not_assignable') ? 'standard' : 'category');
      this.set('selectedTagLanguage', this.get('tag.language'));
      this.set('tagObjectName', this.get('tag.displayName'));
      this.set('selectedTagColor', this.get('tag.color'));
    },

    actions: {
      openModal: function openModal() {
        if (this.get('isTagEditable')) {
          this.initFields();
          this.set('modalIsOpened', true);
        }
      },

      setTagColor: function setTagColor(color) {
        this.set('selectedTagColor', color);
      },

      editTag: function editTag() {
        var tag = this.get('tag'),
            newDisplayName = this.get('tagObjectName'),
            splitName = tag.get('name').split(' > ');
        splitName[tag.depth] = newDisplayName;
        this.set('tag.name', splitName.join(' > '));
        this.set('tag.displayName', newDisplayName);
        this.set('tag.survey_limited', this.get('surveyLimited'));
        if (this.get('tag.surveys') !== this.get('surveys')) {
          this.set('tag.surveys', this.get('surveys'));
        }
        this.set('tag.color', this.get('selectedTagColor'));
        if (!['root_cause', 'action_taken'].includes(this.get('kind'))) {
          this.set('tag.color', this.get('selectedTagColor'));
        }
        this.set('tag.language', this.get('selectedTagLanguage'));
        this.set('tag.not_assignable', this.get('tagAssignability') === 'category');
        this.get('editAction')(this.get('tag'));
        this.closeModal();
      },

      changeSurveyFilter: function changeSurveyFilter(value) {
        this.set('surveyLimited', value == 'only_selected');
      },

      closeModal: function closeModal() {
        this.closeModal();
      },

      toggleDueExclusiveTag: function toggleDueExclusiveTag() {
        this.toggleProperty('tag.due_exclusive');
      },

      toggleTagFeeling: function toggleTagFeeling() {
        this.toggleProperty('tag.feeling_enabled');
      },

      toggleTagAssignation: function toggleTagAssignation(option) {
        this.set('tagAssignability', option);
      }
    }
  });
});