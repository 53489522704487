define('due-dashboard/models/change-end-fb-share-scenario-action', ['exports', 'due-dashboard/models/scenario-action', 'ember-data', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioAction, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    page_url: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'url' })]
  });

  exports['default'] = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    page_url: attr('string', { defaultValue: 'http://' })
  });
});