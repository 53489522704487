define('due-dashboard/components/management-question-category/question-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    PtagName: 'li',
    classNames: ['qcm-item'],
    actions: {
      removeQuestion: function removeQuestion(question) {
        this.get('deleteAction')(question);
      }
    }
  });
});