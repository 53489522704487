define('due-dashboard/routes/companies/custom-stats/show', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({

    filters: service(),

    model: function model(params) {
      var filters = this.get('filters').get('queryFilters');
      filters.filter.id = params.custom_stat_id;
      return {
        cusomStatId: params.custom_stat_id,
        statsPromise: {
          stats: this.store.queryRecord('custom-stat', filters)
        }
      };
    }
  });
});