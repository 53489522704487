define('due-dashboard/models/mailing', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    from_email_local: attr('string'),
    from_email_domain: attr('string'),
    from_alias: attr('string'),
    line_color: attr('string', { defaultValue: '#7dc2e9' }),
    button_color: attr('string', { defaultValue: '#7dc2e9' }),
    active: attr('boolean', { defaultValue: false }),
    is_sms: attr('boolean'),
    updated_at: attr('date'),
    csv_headers: attr(),
    /* json */csv_body: attr(),

    /* json */survey_revision: belongsTo('SurveyRevision', { async: true }),
    mailing_contents: hasMany('MailingContent', { async: true }),

    from_email: computed('from_email_local', 'from_email_domain', function () {
      return this.get('from_email_local') + '@' + this.get('from_email_domain');
    }),

    bannerColor: computed('line_color', function () {
      return 'background-color: ' + this.get('line_color') + ';';
    }),

    buttonColor: computed('button_color', function () {
      return 'background-color: ' + this.get('button_color') + ';';
    })

  });
});