define('due-dashboard/components/reports/-small-title', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-small-title-container'],
    classNameBindings: ['layout'],

    title: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].title.toUpperCase();
    })
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{small-title}} component displaying little title
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */