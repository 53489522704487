define("due-dashboard/templates/components/due-translation", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 8
            },
            "end": {
              "line": 10,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/components/due-translation.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element3, 'class');
          morphs[1] = dom.createElementMorph(element3);
          morphs[2] = dom.createMorphAt(element3, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["get", "button.class", ["loc", [null, [9, 53], [9, 65]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["cancelAction"], [], ["loc", [null, [9, 18], [9, 43]]], 0, 0], ["content", "button.text", ["loc", [null, [9, 69], [9, 84]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 8
            },
            "end": {
              "line": 15,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/components/due-translation.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(element2, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["get", "button.class", ["loc", [null, [14, 56], [14, 68]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["saveTranslation"], [], ["loc", [null, [14, 18], [14, 46]]], 0, 0], ["content", "button.text", ["loc", [null, [14, 72], [14, 87]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 14
              },
              "end": {
                "line": 29,
                "column": 14
              }
            },
            "moduleName": "due-dashboard/templates/components/due-translation.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "remove-language-icon");
            var el2 = dom.createTextNode("x");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'onclick');
            return morphs;
          },
          statements: [["attribute", "onclick", ["subexpr", "action", ["removeLanguage", ["get", "language", ["loc", [null, [28, 83], [28, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [28, 93]]], 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 10
            },
            "end": {
              "line": 31,
              "column": 10
            }
          },
          "moduleName": "due-dashboard/templates/components/due-translation.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("th");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" (");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(")\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 0, 0);
          morphs[1] = dom.createMorphAt(element1, 2, 2);
          morphs[2] = dom.createMorphAt(element1, 4, 4);
          return morphs;
        },
        statements: [["content", "language.name", ["loc", [null, [26, 16], [26, 33]]], 0, 0, 0, 0], ["content", "language.tag", ["loc", [null, [26, 35], [26, 51]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "not", [["subexpr", "eq", [["get", "language.tag", ["loc", [null, [27, 29], [27, 41]]], 0, 0, 0, 0], ["get", "companyLanguageTag", ["loc", [null, [27, 42], [27, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 25], [27, 61]]], 0, 0]], [], ["loc", [null, [27, 20], [27, 62]]], 0, 0]], [], 0, null, ["loc", [null, [27, 14], [29, 21]]]]],
        locals: ["language"],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 10
            },
            "end": {
              "line": 34,
              "column": 10
            }
          },
          "moduleName": "due-dashboard/templates/components/due-translation.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "tags/add-language", [], ["languages", ["subexpr", "@mut", [["get", "remainingLanguages", ["loc", [null, [33, 42], [33, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "addLanguageAction", ["subexpr", "action", ["addLanguage"], [], ["loc", [null, [33, 79], [33, 101]]], 0, 0]], ["loc", [null, [33, 12], [33, 103]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 10
              },
              "end": {
                "line": 41,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/components/due-translation.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "due-translation-row", [], ["line", ["subexpr", "@mut", [["get", "line", ["loc", [null, [40, 39], [40, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "languages", ["subexpr", "@mut", [["get", "selectedLanguages", ["loc", [null, [40, 54], [40, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "updateTranslation", ["subexpr", "@mut", [["get", "updateTranslation", ["loc", [null, [40, 90], [40, 107]]], 0, 0, 0, 0]], [], [], 0, 0], "forbiddenChars", ["subexpr", "@mut", [["get", "forbiddenChars", ["loc", [null, [40, 123], [40, 137]]], 0, 0, 0, 0]], [], [], 0, 0], "dueExclusiveEdit", ["subexpr", "@mut", [["get", "dueExclusiveEdit", ["loc", [null, [40, 155], [40, 171]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [40, 12], [40, 173]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 8
            },
            "end": {
              "line": 42,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/components/due-translation.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "line.depth", ["loc", [null, [39, 20], [39, 30]]], 0, 0, 0, 0], 0], [], ["loc", [null, [39, 16], [39, 33]]], 0, 0]], [], 0, null, ["loc", [null, [39, 10], [41, 17]]]]],
        locals: ["line"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 46,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/components/due-translation.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "translations-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "translations-header");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "translations-title");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "translations-cta");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "grey-button");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "blue-button");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "translations-content-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("table");
        dom.setAttribute(el3, "class", "translations-table");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("thead");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tr");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tbody");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element4, [3, 1]);
        var element8 = dom.childAt(element7, [1, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(element8, 5, 5);
        morphs[4] = dom.createMorphAt(element8, 6, 6);
        morphs[5] = dom.createMorphAt(dom.childAt(element7, [3]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["components.due-translation.title"], [], ["loc", [null, [4, 12], [4, 52]]], 0, 0], ["block", "due-button", [], ["type", "cancel", "text", ["subexpr", "t", ["words.cancel"], [], ["loc", [null, [8, 41], [8, 59]]], 0, 0]], 0, null, ["loc", [null, [8, 8], [10, 23]]]], ["block", "due-button", [], ["type", "action", "text", ["subexpr", "t", ["words.save"], [], ["loc", [null, [13, 41], [13, 57]]], 0, 0], "promise", ["subexpr", "@mut", [["get", "translatePromise", ["loc", [null, [13, 66], [13, 82]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [13, 8], [15, 23]]]], ["block", "each", [["get", "selectedLanguages", ["loc", [null, [25, 18], [25, 35]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [25, 10], [31, 19]]]], ["block", "if", [["get", "showAddLanguage", ["loc", [null, [32, 16], [32, 31]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [32, 10], [34, 17]]]], ["block", "each", [["get", "sortedTags", ["loc", [null, [38, 16], [38, 26]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [38, 8], [42, 17]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});