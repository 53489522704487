define('due-dashboard/routes/settings/subscription', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        account: this.get('currentAccount.content'),
        subscription_plans: this.store.query('subscription_plan', { custom: false }),
        company_subscribtion_plan: this.get('currentAccount.content.company.subscription_plan')
      });
    }

  });
});