define('due-dashboard/components/digests/parts/kpi-list-item-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    // private:

    _analysingYear: computed('period', function () {
      return this.get('period') && this.get('period').includes('year');
    }),

    _kpi: computed('kpis.[]', 'kpiItem.kpi', function () {
      return this.get('kpis').findBy('id', this.get('kpiItem.kpi'));
    }),

    _basePath: computed('compId', 'index', 'currentTag', function () {
      var tag = this.get('currentTag').toLowerCase();

      return 'translations.' + tag + '.components.' + this.get('compId') + '.kpi_list.' + this.get('index');
    }),

    _titlePath: computed('_basePath', function () {
      return this.get('_basePath') + '.title';
    }),

    _yoyPath: computed('_basePath', function () {
      return this.get('_basePath') + '.yoy_title';
    }),

    _ytdPath: computed('_basePath', function () {
      return this.get('_basePath') + '.year_to_date_title';
    }),

    _pdrPath: computed('_basePath', function () {
      return this.get('_basePath') + '.previous_range_title';
    }),

    _baseTitle: computed('_basePath', '_kpi.label', function () {
      var kpi = this.get('_kpi'),
          base_path = this.get('_basePath');

      return this.get(base_path + '.title') || kpi && kpi.get('label');
    }).volatile(),

    actions: {

      removeKpiItem: function removeKpiItem() {
        var _this = this;

        var translations = this.get('translations');
        var comp_id = this.get('compId');
        Object.keys(translations).forEach(function (tag) {
          var to_remove = translations[tag].components[comp_id].kpi_list[_this.get('index')];
          translations[tag].components[comp_id].kpi_list.removeObject(to_remove);
        });
        this.get('removeKpiItem')(this.get('kpiItem'));
      }
    }
  });
});