define('due-dashboard/components/digests/filters/segment-filter', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Component.extend({

    allSegments: [],

    init: function init() {
      var _this = this;

      this.store.query('segment', { filter: {}, page: { number: 1, size: 60 } }).then(function (segments) {
        return _this.set('allSegments', segments);
      });
      if (!this.get('filter.value')) {
        this.set('filter.value', []);
      }
      this._super.apply(this, arguments);
    },

    segmentFilter: computed('filter.value', 'filter.value.length', 'allSegments', function () {
      var segments = this.get('filter.value').mapBy('segment_id');
      return this.get('allSegments').toArray().filter(function (a) {
        return !segments.includes(a.id);
      });
    }),

    _searchSegment: function _searchSegment(term) {
      var _this2 = this;

      var selectedSegments = this.get('filter.value').mapBy('segment_id');
      this.store.query('segment', {
        filter: {
          name: term
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (segments) {
        _this2.set("allSegments", segments.toArray().filter(function (segs) {
          return !selectedSegments.includes(segs.id);
        }));
      });
    },

    actions: {

      setAddSegmentController: function setAddSegmentController(remoteController) {
        this.set('addSegmentController', remoteController);
      },

      addSegmentClick: function addSegmentClick() {
        this.get('addSegmentController').actions.open();
      },

      searchSegments: function searchSegments(term) {
        debounce(this, this._searchSegment, term, 600);
      },

      addSegment: function addSegment(segment) {
        this.get('filter.value').pushObject({ segment_id: segment.id, name: segment.get('name'), values: [] });
        this.get('saveDigestDelay')();
      },

      removeSegment: function removeSegment(segment) {
        this.get('filter.value').removeObject(segment);
        this.get('saveDigestDelay')();
      },

      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});