define('due-dashboard/components/has-comment-scenario-condition', ['exports', 'due-dashboard/components/scenario-condition-item'], function (exports, _dueDashboardComponentsScenarioConditionItem) {
  exports['default'] = _dueDashboardComponentsScenarioConditionItem['default'].extend({
    with_comment: null,

    tagName: 'tr',
    classNames: ['filter'],

    attributeBindings: ['colspan:colspan'],
    colspan: 2
  });
});