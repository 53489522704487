define('due-dashboard/models/scenario-action', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var Model = _emberData['default'].Model;
  var belongsTo = _emberData['default'].belongsTo;
  var attr = _emberData['default'].attr;

  var M = Model.extend({
    scenario: belongsTo('scenario'),
    position: attr('number'),

    type: _ember['default'].computed('isLoaded', function () {
      if (this.constructor) {
        return this.get('modelName');
      }
    }),

    defineSavepoint: function defineSavepoint() {
      return this;
    },
    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
    }

  });

  exports['default'] = M;
});