define('due-dashboard/components/messaging/messaging-status-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['messaging-status-filter'],
    filterValue: '',
    fromSelf: false,
    show_dropdown: false,

    show_class: computed('showDropDown', function () {
      this.set('fromSelf', false);
      if (this.get('showDropDown')) {
        return 'show-class';
      }
      return 'hide-class';
    }),

    hideDropDown: function hideDropDown() {
      this.set('showDropDown', false);
    },

    actions: {
      clickOutsideComponent: function clickOutsideComponent() {
        if (this.get('fromSelf')) {
          this.set('fromSelf', false);
          this.hideDropDown();
        }
      },

      clickInsideComponent: function clickInsideComponent() {
        this.set('fromSelf', true);
      },

      selectStatus: function selectStatus(status) {
        this.get('onchange')(status);
        this.hideDropDown();
      },

      triggerStatusDropDown: function triggerStatusDropDown() {
        this.toggleProperty('showDropDown');
      }
    }
  });
});