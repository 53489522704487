define('due-dashboard/components/satisfaction-modal', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var RSVP = _ember['default'].RSVP;
  var on = _ember['default'].on;
  exports['default'] = Component.extend({

    classNames: ['satisfaction-modal'],
    questionsPosition: ['question.position'],
    sortedChoices: computed.sort('feedback.choices', 'questionsPosition'),

    asChanged: on('init', observer('feedback', function () {
      var _this = this;

      this.get('feedback.choices').then(function (choices) {
        RSVP.all(choices.mapBy('question')).then(function () {
          _this.notifyPropertyChange('sortedChoices');
        });
      });
    }))

  });
});