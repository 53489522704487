define('due-dashboard/models/send-survey-scenario-action', ['exports', 'due-dashboard/models/scenario-action', 'ember-data', 'ember-cp-validations', 'ember'], function (exports, _dueDashboardModelsScenarioAction, _emberData, _emberCpValidations, _ember) {
  var computed = _ember['default'].computed;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  var Validations = (0, _emberCpValidations.buildValidations)({
    survey: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: computed.not('model.requireSurvey')
    }),
    template: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: computed.not('model.requireTemplate')
    }),
    segment_name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: computed.not('model.requireSegmentName')
    })
  });

  exports['default'] = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    sending_type: attr('string'),
    survey: belongsTo('survey', { async: true }),
    template: belongsTo('template', { async: true }),
    dispatch_delay_hours: attr('number', { defaultValue: 0 }),
    segment_name: attr('string'),

    requireSurvey: false,
    requireTemplate: false,
    requireSegmentName: false
  });
});