define("due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/logo-design", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/logo-design.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "element-header-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "design-element-title-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "due-typo-h5 bold");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "setting-element-container setting-element-upload margin-top-24");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "due-typo-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "setting-element-container setting-element-toggle");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "labeled-switch");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "due-typo-default");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [4, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", [["subexpr", "concat", ["words.", ["get", "currentElement.name", ["loc", [null, [3, 56], [3, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 39], [3, 76]]], 0, 0]], [], ["loc", [null, [3, 35], [3, 78]]], 0, 0], ["inline", "t", ["words.logo"], [], ["loc", [null, [7, 33], [7, 51]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "currentDesignOptions.image", ["loc", [null, [9, 10], [9, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "accept", "image/png,image/jpeg", "placeholder", "", "type", "file", "inputId", "design-logo", "onChange", ["subexpr", "action", ["setLogoImage"], [], ["loc", [null, [14, 13], [14, 36]]], 0, 0], "blockDisplay", true, "uploadText", "Select a picture"], ["loc", [null, [8, 2], [17, 4]]], 0, 0], ["inline", "component", ["v2/-switch"], ["on", ["subexpr", "@mut", [["get", "currentDesignOptions.displayLogo", ["loc", [null, [21, 32], [21, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "onSwitch", ["subexpr", "action", ["toggleDisplayLogo"], [], ["loc", [null, [21, 74], [21, 102]]], 0, 0]], ["loc", [null, [21, 4], [21, 104]]], 0, 0], ["inline", "t", ["words.display_logo"], [], ["loc", [null, [22, 35], [22, 61]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});