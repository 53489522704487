define('due-dashboard/components/nav/footer-bar', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    classNames: ['footer-bar'],

    language: computed('i18n.locale', function () {
      return this.get('i18n.locale');
    }),

    init: function init() {
      var displayCn = window.location.href.includes(".cn");
      this.set('displayCn', displayCn);
      this._super.apply(this, arguments);
    },

    currentYear: new Date().getFullYear(),

    actions: {

      languageSelection: function languageSelection(language) {
        _moment['default'].locale(language);
        this.get('cookie').setCookie('locale', language);
        this.get('i18n').set('locale', language);
        // this.transitionTo(this._pathForLanguage(language));
      }
    }

  });
});