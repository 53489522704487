define('due-dashboard/components/digests/parts/preview-part', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Component.extend({
    classNames: ['content-preview'],
    filteredSubscriptions: [],
    searchInput: '',
    pageCounter: 2,
    filterPageCounter: 2,

    displayWarning: computed('subscriptions.[]', function () {
      var _this = this;

      if (!this.get('subscriptions').length) {
        return true;
      }
      var accountInSubscriptions = this.get('subscriptions').filter(function (s) {
        return s.get('account.email') === _this.get('account.email');
      }).length;
      if (!this.get('account') || !accountInSubscriptions) {
        this.set('account', this.get('subscriptions')[0].get('account'));
      }
      return false;
    }),

    selectedAccounts: computed('subscriptions.[]', 'filteredSubscriptions', function () {
      var subscriptions = this.get('subscriptions');
      return this.get('searchInput.length') === 0 ? subscriptions.map(function (sub) {
        return sub.get('account');
      }) : this.get('filteredSubscriptions').map(function (sub) {
        return sub.get('account');
      });
    }),

    iframeSrc: _ember['default'].computed('account', 'reloadIframe', function () {
      if (document.getElementById('preview_iframe')) {
        document.getElementById('preview_iframe').src += '';
      }
      return _dueDashboardConfigEnvironment['default'].EmberENV.DIGEST_PREVIEW_URL + '/' + this.get('dueDigestId') + '/preview/' + this.get('account.id');
    }),

    filterSubscriptions: function filterSubscriptions() {
      var _this2 = this;

      var term = this.get('searchInput'),
          filters = { filter: { due_digest_id: this.get('dueDigestId'), email: term, live: false }, include: 'account' };
      var filteredSubscriptions = this.get('filteredSubscriptions'),
          subscriptions = this.get('subscriptions');
      filters.page = { number: this.get(term.length ? 'filterPageCounter' : 'pageCounter'), size: 50 };
      this.set('isLoading', true);
      var promise = this.get('store').query('due_digest_subscription', filters);
      this.set('loadMorePromise', promise);
      promise.then(function (results) {
        if (_this2.get('searchInput.length') > 0) {
          _this2.set('filterPageCounter', _this2.get('filterPageCounter') + 1);
          filteredSubscriptions = [].concat(_toConsumableArray(filteredSubscriptions), _toConsumableArray(results.toArray()));
          _this2.set('filteredSubscriptions', filteredSubscriptions);
        } else {
          _this2.set('pageCounter', _this2.get('pageCounter') + 1);
          subscriptions = [].concat(_toConsumableArray(subscriptions), _toConsumableArray(results.toArray()));
          _this2.set('subscriptions', subscriptions);
          _this2.set('filteredSubscriptions', []);
        }
        _this2.set('isLoading', false);
      });
    },

    actions: {
      changeAccount: function changeAccount(account) {
        this.set('account', account);
      },

      searchUsers: function searchUsers(value) {
        var _this3 = this;

        this.set('searchInput', value || '');
        this.set('filteredSubscriptions', []);
        this.set('isLoading', true);
        if (value && value.length > 0) {
          this.set('filterPageCounter', 2);
          var promise = this.get('store').query('due-digest-subscription', {
            filter: { due_digest_id: this.get('dueDigestId'), email: value }
          });
          this.set('searchPromise', promise);
          promise.then(function (subs) {
            _this3.set('filteredSubscriptions', subs.toArray());
            _this3.set('isLoading', false);
          });
        }
      },

      loadMoreUsers: function loadMoreUsers() {
        debounce(this, this.filterSubscriptions, 600);
      }
    }
  });
});