define('due-dashboard/controllers/settings/api', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({
    displayNewFeedbackValidation: computed.notEmpty('model.company.new_feedback_url'),

    addHttpHeader: function addHttpHeader() {
      var headers = this.get('model.company.custom_http_headers');
      headers.addObject({ name: '', value: '' });
    },

    actions: {
      addApiKey: function addApiKey() {
        this.set('promise', this.store.createRecord('ApiKey', {
          company: this.get('model.company')
        }).save());
      },

      addNewCustomHeader: function addNewCustomHeader() {
        this.addHttpHeader();
      },

      deleteHeader: function deleteHeader(header) {
        this.get('model.company.custom_http_headers').removeObject(header);
      }
    }
  });
});