define("due-dashboard/models/aircall-filter", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
  var computed = _ember["default"].computed;
  var attr = _emberData["default"].attr;
  var belongsTo = _emberData["default"].belongsTo;
  var Model = _emberData["default"].Model;
  exports["default"] = Model.extend({
    minimum_call_length: attr("number", { defaultValue: 20 }),
    maximum_call_length: attr("number", { defaultValue: null }),
    survey_delay: attr("number", { defaultValue: 60 }),
    position: attr("number"),
    has_tags: attr(),
    has_no_tags: attr(),
    call_status: attr({ defaultValue: 'all' }),
    inbound: attr("boolean", { defaultValue: true }),
    outbound: attr("boolean", { defaultValue: false }),

    number_countries: attr(),
    number_timezones: attr(),
    number_open: attr("nullable-boolean"),
    number_is_ivr: attr("nullable-boolean"),
    number_live_recording_activated: attr("nullable-boolean"),
    number_availability_status: attr(),

    call_archived: attr("nullable-boolean"),
    call_missed_call_reasons: attr(),
    users: attr(),
    assigned_tos: attr(),
    teams: attr(),

    sending_channel: attr({ defaultValue: "sms" }),
    channel_fallback: attr("boolean"),

    aircall_company_integration: belongsTo("AircallCompanyIntegration"),
    survey: belongsTo("Survey"),
    template: belongsTo("template"),
    fallback_template: belongsTo("template"),

    is_valid: computed(function () {
      return this.get("aircall_company_integration") && (this.get("minimum_call_length") > 0 || parseInt(this.get("minimum_call_length")) === 0) && this.get("maximum_call_length") >= 0 && (this.get("inbound") || this.get("outbound")) && this.get("survey");
    }).volatile(),

    next_position: function next_position() {
      return (this.get("aircall_company_integration").get("aircall_filters").sortBy("position").mapBy("position").pop() || 0) + 1;
    },

    onReady: (function () {
      if (!this.get("position")) {
        this.set("position", this.next_position());
      }
    }).on("ready")
  });
});