define('due-dashboard/routes/settings/billing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      var _this = this;

      return _ember['default'].RSVP.hash({
        billing_profile: this.store.findAll('billing-profile').then(function (b) {
          return b.get('firstObject') || _this.store.createRecord('billing-profile');
        }),
        billings: this.store.findAll('company-subscription-plan-billing')
      });
    }

  });
});