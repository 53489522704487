define("due-dashboard/templates/companies/surveys/edit/layout-preview", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 35
          }
        },
        "moduleName": "due-dashboard/templates/companies/surveys/edit/layout-preview.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "survey-preview", [], ["survey_draft", ["subexpr", "@mut", [["get", "survey_draft", ["loc", [null, [1, 30], [1, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "previewDevice", ["subexpr", "@mut", [["get", "previewDevice", ["loc", [null, [1, 57], [1, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [1, 72]]], 0, 0], ["inline", "t", [["get", "subtitleTranslationKey", ["loc", [null, [2, 7], [2, 29]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 3], [2, 31]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});