define('due-dashboard/components/v2/tabs-navigation/-tab-navigation-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['due-tab-navigation-item'],
    classNameBindings: ['selected:selected'],

    selected: computed('currentItem', function () {
      return this.get('index') === this.get('currentItem');
    }),

    actions: {}
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-radio-buttons}} radio button child item
 */