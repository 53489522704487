define('due-dashboard/components/digests/parts/subscribers-part', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Component.extend({

    classNames: ['digest-settings-block'],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('subscribedAccounts', []);
      this.updateScroll();
      this.store.query('account', { filter: { due_digest_id: this.get('dueDigestId') } }).then(function (accounts) {
        return _this.set('accounts', accounts);
      });
    },

    _searchAccount: function _searchAccount(term) {
      var _this2 = this;

      this.set('search_promise', RSVP.allSettled(this.get('subscriberPromises')));
      RSVP.all(this.get('subscriberPromises')).then(function () {
        var searchPromise = _this2.store.query('account', {
          filter: { email: term, due_digest_id: _this2.get('dueDigestId') }
        });
        _this2.set('search_promise', searchPromise);
        searchPromise.then(function (accounts) {
          return _this2.set('filteredAccounts', accounts);
        });
      });
    },

    subListLimit: 5,
    subscriberPromises: [],
    subscribedAccounts: [],
    currentStep: 0,
    filterCurrentStep: 1,
    isLoading: false,
    searchField: '',
    filteredSubscriptions: [],

    remainingAccounts: computed.setDiff('accounts', 'subscribedAccounts'),

    dropdownAccounts: computed('filteredAccounts', function () {
      return this.get('filteredAccounts');
    }),

    firstSubscribedAccounts: computed('subscribedAccounts', 'subscribedAccounts.[]', function () {
      if (this.get('subscribedAccounts')) {
        return this.get('subscribedAccounts').slice(0, this.subListLimit);
      }
    }),

    missingSubscribedCount: computed('subscribedAccounts', 'subscribedAccounts.[]', function () {
      if (this.get('subscribedAccounts')) {
        var count = this.get('subscribedAccounts').length - this.subListLimit;
        return count > 0 ? count : 0;
      } else {
        return 0;
      }
    }),

    closeModal: function closeModal() {
      this.set('modalIsOpened', false);
    },

    updateScroll: function updateScroll() {
      var _this3 = this;

      var leftToQuery = arguments.length <= 0 || arguments[0] === undefined ? 50 : arguments[0];

      this.set('currentStep', this.get('currentStep') + 1);
      var filters = { filter: { due_digest_id: this.get('dueDigestId'), live: false }, include: 'account' };
      filters.page = { number: this.get('currentStep'), size: 50 };
      var subscribedAccounts = this.get('subscribedAccounts');
      this.get('store').query('due_digest_subscription', filters).then(function (results) {
        var difference = results.toArray().filter(function (x) {
          return !subscribedAccounts.includes(x);
        });
        subscribedAccounts = [].concat(_toConsumableArray(subscribedAccounts), _toConsumableArray(difference));
        leftToQuery = leftToQuery - difference.length;
        _this3.set('subscribedAccounts', subscribedAccounts);
        if (leftToQuery < 0 || results.toArray().length === 0) {
          _this3.set('isLoading', false);
        } else {
          _this3.updateScroll(leftToQuery);
        }
      });
    },

    filterSubscriptions: function filterSubscriptions() {
      var _this4 = this;

      var term = this.get('searchField');
      this.set('filterCurrentStep', this.get('filterCurrentStep') + 1);
      var filters = { filter: { due_digest_id: this.get('dueDigestId'), email: term, live: false }, include: 'account' };
      filters.page = { number: this.get('filterCurrentStep'), size: 50 };
      var filteredSubscriptions = this.get('filteredSubscriptions');
      this.get('store').query('due_digest_subscription', filters).then(function (results) {
        filteredSubscriptions = [].concat(_toConsumableArray(filteredSubscriptions), _toConsumableArray(results.toArray()));
        _this4.set('filteredSubscriptions', filteredSubscriptions);
        _this4.set('isLoading', false);
      });
    },

    actions: {

      reloadSubscription: function reloadSubscription() {
        this.set('isLoading', true);
        if (this.get('searchField').length > 0) {
          _ember['default'].run.debounce(this, this.filterSubscriptions, 200);
        } else {
          _ember['default'].run.debounce(this, this.updateScroll, 200);
        }
      },

      searchSubscriptions: function searchSubscriptions() {
        this.set('filterCurrentStep', 0);
        this.set('filteredSubscriptions', []);
        this.set('isLoading', true);
        _ember['default'].run.debounce(this, this.filterSubscriptions, 200);
      },

      searchAccounts: function searchAccounts(term) {
        this._searchAccount(term);
      },

      addSubscriber: function addSubscriber(account) {
        var _this5 = this;

        this.get('filteredAccounts').removeObject(account);
        this.get('subscriberPromises').addObject(this.get('store').createRecord('due-digest-subscription', {
          due_digest: this.get('digest'),
          account: account,
          live: false
        }).save().then(function (new_sub) {
          if (!_this5.get('subscribedAccounts').map(function (sub) {
            return sub.get('id');
          }).includes(new_sub.id)) {
            _this5.get('subscribedAccounts').addObject(new_sub);
            _this5.get('saveDigestDelay')();
          }
        })['catch'](function () {}));
        this.set('currentStep', 0);
        if (this.get('filteredAccounts.length') === 0) {
          this._searchAccount('');
        }
      },

      deleteSubscriber: function deleteSubscriber(subscription) {
        var _this6 = this;

        this.get('subscribedAccounts').removeObject(subscription);
        this.get('filteredSubscriptions').removeObject(subscription);
        this.set('currentStep', 0);
        this.get('subscriberPromises').addObject(subscription.destroyRecord().then(function () {
          _this6.get('saveDigestDelay')();
          if (_this6.get('subscribedAccounts.length') === 49) {
            _this6.updateScroll();
          }
        }));
      },

      openModal: function openModal() {
        this.set('modalIsOpened', true);
      },

      closeModal: function closeModal() {
        this.closeModal();
      }
    }
  });
});