define("due-dashboard/templates/components/question-detail-item-satisfaction", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 10
              },
              "end": {
                "line": 21,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/components/question-detail-item-satisfaction.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "c3-tooltip-container question-detail-hover");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("table");
            dom.setAttribute(el2, "class", "c3-tooltip");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tbody");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            dom.setAttribute(el4, "class", "c3-tooltip-name--promoter");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "name");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "style", "background-color:#2fcc76");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "value");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("% (");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(")");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1, 1, 1, 1]);
            var element11 = dom.childAt(element10, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element11, 0, 0);
            morphs[2] = dom.createMorphAt(element11, 2, 2);
            return morphs;
          },
          statements: [["inline", "t", ["overview.chart.legend.promoters"], [], ["loc", [null, [15, 83], [15, 122]]], 0, 0], ["content", "values.promoter.percent", ["loc", [null, [16, 38], [16, 65]]], 0, 0, 0, 0], ["content", "values.promoter.value", ["loc", [null, [16, 68], [16, 93]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 28,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/components/question-detail-item-satisfaction.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "note-graph progress-bar promoter");
          dom.setAttribute(el1, "role", "progressbar");
          dom.setAttribute(el1, "aria-valuemin", "0");
          dom.setAttribute(el1, "aria-valuemax", "100");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "percent-inline");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "percent-sign");
          var el4 = dom.createTextNode("% (");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(")");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "number-inline");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "on-mobile");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "/assets/images/icons/promoter.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("% (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var element13 = dom.childAt(element12, [3]);
          var element14 = dom.childAt(fragment, [3, 3]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element12, 'aria-valuenow');
          morphs[1] = dom.createUnsafeAttrMorph(element12, 'style');
          morphs[2] = dom.createMorphAt(element12, 1, 1);
          morphs[3] = dom.createUnsafeAttrMorph(element13, 'style');
          morphs[4] = dom.createMorphAt(element13, 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element13, [1]), 1, 1);
          morphs[6] = dom.createMorphAt(dom.childAt(element12, [4]), 0, 0);
          morphs[7] = dom.createMorphAt(element14, 0, 0);
          morphs[8] = dom.createMorphAt(element14, 2, 2);
          return morphs;
        },
        statements: [["attribute", "aria-valuenow", ["get", "values.promoter.percent", ["loc", [null, [9, 89], [9, 112]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["get", "values.promoter.barStyle", ["loc", [null, [9, 162], [9, 186]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "displayOnHover", ["loc", [null, [10, 16], [10, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 10], [21, 17]]]], ["attribute", "style", ["get", "values.promoter.percentInlineStyle", ["loc", [null, [22, 48], [22, 82]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "values.promoter.percent", ["loc", [null, [22, 86], [22, 113]]], 0, 0, 0, 0], ["content", "values.promoter.value", ["loc", [null, [22, 143], [22, 168]]], 0, 0, 0, 0], ["content", "values.promoter.value", ["loc", [null, [22, 211], [22, 236]]], 0, 0, 0, 0], ["content", "values.promoter.percent", ["loc", [null, [26, 29], [26, 56]]], 0, 0, 0, 0], ["content", "values.promoter.value", ["loc", [null, [26, 59], [26, 84]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 10
              },
              "end": {
                "line": 42,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/components/question-detail-item-satisfaction.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "c3-tooltip-container question-detail-hover");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("table");
            dom.setAttribute(el2, "class", "c3-tooltip");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tbody");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            dom.setAttribute(el4, "class", "c3-tooltip-name--passive");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "name");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "style", "background-color:#23afcb");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "value");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("% (");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(")");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1, 1, 1, 1]);
            var element6 = dom.childAt(element5, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element6, 0, 0);
            morphs[2] = dom.createMorphAt(element6, 2, 2);
            return morphs;
          },
          statements: [["inline", "t", ["overview.chart.legend.passives"], [], ["loc", [null, [36, 83], [36, 121]]], 0, 0], ["content", "values.passive.percent", ["loc", [null, [37, 38], [37, 64]]], 0, 0, 0, 0], ["content", "values.passive.value", ["loc", [null, [37, 67], [37, 91]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 6
            },
            "end": {
              "line": 49,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/components/question-detail-item-satisfaction.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "note-graph progress-bar passive");
          dom.setAttribute(el1, "role", "progressbar");
          dom.setAttribute(el1, "aria-valuemin", "0");
          dom.setAttribute(el1, "aria-valuemax", "100");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "percent-inline");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "percent-sign");
          var el4 = dom.createTextNode("% (");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(")");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "number-inline");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "on-mobile");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "/assets/images/icons/passive.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("% (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [3]);
          var element9 = dom.childAt(fragment, [3, 3]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element7, 'aria-valuenow');
          morphs[1] = dom.createUnsafeAttrMorph(element7, 'style');
          morphs[2] = dom.createMorphAt(element7, 1, 1);
          morphs[3] = dom.createUnsafeAttrMorph(element8, 'style');
          morphs[4] = dom.createMorphAt(element8, 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
          morphs[6] = dom.createMorphAt(dom.childAt(element7, [4]), 0, 0);
          morphs[7] = dom.createMorphAt(element9, 0, 0);
          morphs[8] = dom.createMorphAt(element9, 2, 2);
          return morphs;
        },
        statements: [["attribute", "aria-valuenow", ["get", "values.passive.percent", ["loc", [null, [30, 88], [30, 110]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["get", "values.passive.barStyle", ["loc", [null, [30, 160], [30, 183]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "displayOnHover", ["loc", [null, [31, 16], [31, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [31, 10], [42, 17]]]], ["attribute", "style", ["get", "values.passive.percentInlineStyle", ["loc", [null, [43, 48], [43, 81]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "values.passive.percent", ["loc", [null, [43, 85], [43, 111]]], 0, 0, 0, 0], ["content", "values.passive.value", ["loc", [null, [43, 141], [43, 165]]], 0, 0, 0, 0], ["content", "values.passiv.evalue", ["loc", [null, [43, 208], [43, 232]]], 0, 0, 0, 0], ["content", "values.passive.percent", ["loc", [null, [47, 29], [47, 55]]], 0, 0, 0, 0], ["content", "values.passive.value", ["loc", [null, [47, 58], [47, 82]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 10
              },
              "end": {
                "line": 63,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/components/question-detail-item-satisfaction.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "c3-tooltip-container question-detail-hover");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("table");
            dom.setAttribute(el2, "class", "c3-tooltip");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tbody");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            dom.setAttribute(el4, "class", "c3-tooltip-name--detractor");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "name");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "style", "background-color:#ee6352");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "value");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("% (");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(")");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1, 1, 1]);
            var element1 = dom.childAt(element0, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element1, 0, 0);
            morphs[2] = dom.createMorphAt(element1, 2, 2);
            return morphs;
          },
          statements: [["inline", "t", ["overview.chart.legend.detractors"], [], ["loc", [null, [57, 83], [57, 123]]], 0, 0], ["content", "values.detractor.percent", ["loc", [null, [58, 38], [58, 66]]], 0, 0, 0, 0], ["content", "values.detractor.value", ["loc", [null, [58, 69], [58, 95]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 6
            },
            "end": {
              "line": 70,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/components/question-detail-item-satisfaction.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "note-graph progress-bar detractor");
          dom.setAttribute(el1, "role", "progressbar");
          dom.setAttribute(el1, "aria-valuenow", "100");
          dom.setAttribute(el1, "aria-valuemin", "0");
          dom.setAttribute(el1, "aria-valuemax", "100");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "percent-inline");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "percent-sign");
          var el4 = dom.createTextNode("% (");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(")");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "number-inline");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "on-mobile");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "/assets/images/icons/detractor.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("% (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [3]);
          var element4 = dom.childAt(fragment, [3, 3]);
          var morphs = new Array(8);
          morphs[0] = dom.createUnsafeAttrMorph(element2, 'style');
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          morphs[2] = dom.createUnsafeAttrMorph(element3, 'style');
          morphs[3] = dom.createMorphAt(element3, 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [4]), 0, 0);
          morphs[6] = dom.createMorphAt(element4, 0, 0);
          morphs[7] = dom.createMorphAt(element4, 2, 2);
          return morphs;
        },
        statements: [["attribute", "style", ["get", "values.detractor.barStyle", ["loc", [null, [51, 141], [51, 166]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "displayOnHover", ["loc", [null, [52, 16], [52, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [52, 10], [63, 17]]]], ["attribute", "style", ["get", "values.detractor.percentInlineStyle", ["loc", [null, [64, 48], [64, 83]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "values.detractor.percent", ["loc", [null, [64, 87], [64, 115]]], 0, 0, 0, 0], ["content", "values.detractor.value", ["loc", [null, [64, 145], [64, 171]]], 0, 0, 0, 0], ["content", "values.detractor.value", ["loc", [null, [64, 214], [64, 240]]], 0, 0, 0, 0], ["content", "values.detractor.percent", ["loc", [null, [68, 29], [68, 57]]], 0, 0, 0, 0], ["content", "values.detractor.value", ["loc", [null, [68, 60], [68, 86]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 81,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/question-detail-item-satisfaction.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "question-detail");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "on-mobile title-main-question");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "before");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "after");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" : ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "note-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "note-container");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h4");
        dom.setAttribute(el3, "class", "no-mobile");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "on-mobile");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "total-main-question");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "number");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [0]);
        var element16 = dom.childAt(element15, [1]);
        var element17 = dom.childAt(element16, [3]);
        var element18 = dom.childAt(element15, [3]);
        var element19 = dom.childAt(element18, [1]);
        var element20 = dom.childAt(element15, [5, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element17, 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element17, [2]), 0, 0);
        morphs[3] = dom.createMorphAt(element19, 1, 1);
        morphs[4] = dom.createMorphAt(element19, 2, 2);
        morphs[5] = dom.createMorphAt(element19, 3, 3);
        morphs[6] = dom.createMorphAt(dom.childAt(element18, [3]), 0, 0);
        morphs[7] = dom.createMorphAt(element20, 1, 1);
        morphs[8] = dom.createMorphAt(dom.childAt(element20, [3]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["questions.list.recommendation"], [], ["loc", [null, [3, 24], [3, 61]]], 0, 0], ["inline", "t", ["questions.list.satisfaction_rate"], [], ["loc", [null, [4, 23], [4, 63]]], 0, 0], ["content", "precisionValue", ["loc", [null, [4, 72], [4, 90]]], 0, 0, 0, 0], ["block", "if", [["get", "values.promoter.value", ["loc", [null, [8, 12], [8, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 6], [28, 13]]]], ["block", "if", [["get", "values.passive.value", ["loc", [null, [29, 12], [29, 32]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [29, 6], [49, 13]]]], ["block", "if", [["get", "values.detractor.value", ["loc", [null, [50, 12], [50, 34]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [50, 6], [70, 13]]]], ["content", "precisionValue", ["loc", [null, [72, 26], [72, 44]]], 0, 0, 0, 0], ["inline", "t", ["questions.list.big_total_answers"], [], ["loc", [null, [76, 6], [76, 46]]], 0, 0], ["content", "question.number_of_choices", ["loc", [null, [77, 27], [77, 57]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});