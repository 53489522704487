define('due-dashboard/components/digests/parts/options-part', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    /* Default props */
    'default': 0, // Default index array
    containerSelector: '.content-settings',
    key_trad: 'digest.component.respondant_',

    init: function init() {
      /* Check props */
      if (!this.get('options') || this.get('options').length === 0) {
        throw new Error('[options-part.js:init] - Options must be defined & not empty');
      }
      if (!this.get('saveDigestDelay')) {
        throw new Error('[options-part.js:init] - Function saveDigestDelay is mandatory');
      }
      if (!this.get('value')) {
        this.set('value', this.get('options')[this.get('default')]);
      }
      this._super.apply(this, arguments);
    },

    actions: {
      /**
       * Change current props value & notify update
       * @param {Any} option Option selected
       */
      onChange: function onChange(option) {
        this.set('value', option);
        this.get('saveDigestDelay')();
      }
    }
  });
});
/**
 * @fileoverview Multi select component for digest
 * @author Eliott Martin <eliott@diduenjoy.com>
 * 
 * @created 03/12/2021
 * @updated 03/12/2021
 */