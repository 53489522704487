define('due-dashboard/serializers/match-segment-value-scenario-condition', ['exports', 'ember-data', 'due-dashboard/mixins/embedded-jsonapi-records'], function (exports, _emberData, _dueDashboardMixinsEmbeddedJsonapiRecords) {
  var JSONAPISerializer = _emberData['default'].JSONAPISerializer;
  exports['default'] = JSONAPISerializer.extend(_dueDashboardMixinsEmbeddedJsonapiRecords['default'], {
    attrs: {
      segment: {
        serialize: 'records',
        deserialize: 'ids-and-type'
      }
    }
  });
});