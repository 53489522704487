define('due-dashboard/components/due-table/elements/-impact-level', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-impact-level'],

    LOW_THRESHOLD_DEFAULT: 0.01,
    MODERATE_THRESHOLD_DEFAULT: 0.1,
    STRONG_THRESHOLD_DEFAULT: 0.3,

    lowThreshold: computed('elem', function () {
      return this.get('elem.lowThreshold') || this.LOW_THRESHOLD_DEFAULT;
    }),

    moderateThreshold: computed('elem', function () {
      return this.get('elem.moderateThreshold') || this.MODERATE_THRESHOLD_DEFAULT;
    }),

    strongThreshold: computed('elem', function () {
      return this.get('elem.strongThreshold') || this.STRONG_THRESHOLD_DEFAULT;
    }),

    level: computed('value', 'lowThreshold', 'moderateThreshold', 'strongThreshold', function () {
      var value = this.get('value');
      var level = 'empty';
      if (value.total == null || value.total < this.get('lowThreshold')) {
        return level;
      }
      if (value.total > this.get('lowThreshold') && value.total <= this.get('moderateThreshold')) {
        level = 'low';
      } else if (value.total > this.get('moderateThreshold') && value.total <= this.get('strongThreshold')) {
        level = 'moderate';
      } else if (value.total > this.get('strongThreshold')) {
        level = 'strong';
      }
      return level;
    })
  });
});