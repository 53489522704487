define('due-dashboard/controllers/settings/integrations/zendesk', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({
    zendesk_url: null,
    submitEnabled: computed.notEmpty('model.company_integration.company_zendesk_url'),
    hideSave: true,
    zendesk_module_dl_link: _dueDashboardConfigEnvironment['default'].EmberENV.ZENDESK_MODULE_URL,

    zendesk_auth: computed('model.compnay.id', 'model.company_integration.company_zendesk_url', function () {
      var url = this.get('model.company_integration.company_zendesk_url');
      if (url !== '') {
        return _dueDashboardConfigEnvironment['default'].EmberENV.ZENDESK_CONNECT_URL + '?zendesk_url=' + url + '&company=' + this.get('model.company.id');
      } else {
        return "";
      }
    }),

    actions: {
      toggleTicketOnFeedback: function toggleTicketOnFeedback() {
        this.toggleProperty('model.company_integration.ticket_on_feedback');
        this.get('model.company_integration').save();
      }
    }

  });
});