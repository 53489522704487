define('due-dashboard/components/digests/parts/one-period-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    classNames: ['one-period-part'],

    init: function init() {
      this._super.apply(this, arguments);

      if ((this.get('period') === null || this.get('period') === undefined) && this.get('toggleByDefault')) {
        this.toggleProperty('period');
      }
    },

    actions: {
      togglePeriod: function togglePeriod() {
        this.toggleProperty('period');
        this.get('saveDigestDelay')();
      }
    }
  });
});