define('due-dashboard/components/email-alert-scenario-action', ['exports', 'ember', 'due-dashboard/components/scenario-action-item'], function (exports, _ember, _dueDashboardComponentsScenarioActionItem) {
  /* global _ */

  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var filterBy = computed.filterBy;
  var empty = computed.empty;
  var and = computed.and;
  var sort = computed.sort;
  exports['default'] = _dueDashboardComponentsScenarioActionItem['default'].extend({
    action: null,
    store: _ember['default'].inject.service,

    /*
    ** Error handling
    */

    emptyAcc: empty('action.accounts'),
    emptyCustom: empty('action.segments'),
    noAccError: and('emptyAcc', 'display_error', 'emptyCustom'),

    errorObserver: _ember['default'].observer('try_step', function () {
      this.set('display_error', true);
    }),

    /*
    ** Core
    */

    closeOnSelect: true,
    allCompanyAccounts: _ember['default'].computed(function () {
      return this.store.findAll('account');
    }),
    accountSortKey: ['created_at:desc'],
    sortedCompanyAccounts: sort('allCompanyAccounts', 'accountSortKey'),
    rawAllAccounts: _ember['default'].computed('searchedAccounts', 'sortedCompanyAccounts', function () {
      var accounts = this.get('searchedAccounts') || this.get('sortedCompanyAccounts');
      return accounts;
    }),
    allAccounts: filterBy('rawAllAccounts', 'isLoaded', true),
    remainingRecipients: computed('allAccounts', 'action.{accounts,segments}', function () {
      return _.concat(_.difference(this.get('allAccounts'), this.get('action.accounts')), 'segment');
    }),
    selectedAccounts: computed('action.{accounts,segments}', function () {
      return _.concat(this.get('action.accounts').toArray(), this.get('action.segments').toArray());
    }),

    addSegment: function addSegment(value) {
      var _this = this;

      this.get('closeOnSelect', true);
      this.set('customScountSegment', null);
      this.get('_select.actions').close();
      if (!this.get('action.segments').filterBy('name', value)[0]) {
        this.get('store').queryRecord('segment', {
          filter: {
            name: value
          }
        }).then(function (segment) {
          if (segment[0] && segment[0].get('value')) {
            _this.get('action').updateSegments(_.concat(_this.get('action.segments').toArray(), segment[0]));
          } else {
            _this.get('store').createRecord('segment', {
              name: value,
              position: 0
            }).save().then(function (s) {
              return _this.get('action').updateSegments(_.concat(_this.get('action.segments').toArray(), s));
            });
          }
        });
      }
    },

    handleCustomInput: function handleCustomInput(select) {
      this.set('closeOnSelect', false);
      this.set('_select', select);
      document.getElementById("input-custom").focus();
    },

    updateSegments: function updateSegments(segments) {
      if (segments.length !== this.get('action.segments.length')) {
        var diff = _.difference(this.get('action.segments').toArray(), segments);
        diff.map(function (record) {
          return record.destroyRecord();
        });
      }
    },

    _searchAccount: function _searchAccount(email) {
      var _this2 = this;

      if (email.length) {
        this.store.query('account', { filter: { email: email } }).then(function (accounts) {
          return _this2.set('searchedAccounts', accounts);
        });
      } else {
        this.set('searchedAccounts', null);
      }
    },

    actions: {
      selectActionRecipients: function selectActionRecipients(accounts, select) {
        if (accounts.indexOf('segment') !== -1) {
          this.set('closeOnSelect', false);
          var value = document.getElementById("input-custom").value;
          if (value === '') {
            this.handleCustomInput(select);
          } else {
            this.set('closeOnSelect', true);
            this.addSegment(value);
          }
        } else {
          this.set('closeOnSelect', true);
          this.get('action').updateSegments(accounts.filterBy('modelName', 'segment'));
          this.get('action').updateAccounts(accounts.filterBy('modelName', 'account'));
        }
      },

      setSelect: function setSelect(select) {
        this.set('_select', select);
      },

      newSegment: function newSegment(value) {
        if (value !== '') {
          this.get('_select').actions.close();
          this.set('closeOnSelect', true);
          this.addSegment(value);
        }
      },

      searchAccount: function searchAccount(email) {
        run.debounce(this, this._searchAccount, email, 300);
      }

    }
  });
});