define('due-dashboard/components/reports/-export-modal', ['exports', 'ember', 'due-dashboard/helpers/current-timezone'], function (exports, _ember, _dueDashboardHelpersCurrentTimezone) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-export-report-modal'],

    displayColumnsOptions: computed('dataType', function () {
      return this.get('dataType') !== 'segment-breakdown';
    }),

    fileContent: computed(function () {
      return { key: 'displayed_view', label: this.get('i18n').t('words.displayed_view') };
    }),
    fileContentOptions: computed(function () {
      var _this = this;

      return ['displayed_view', 'full_hierarchy'].map(function (opt) {
        return { key: opt, label: _this.get('i18n').t('words.' + opt) };
      });
    }),

    exportName: computed(function () {
      return 'export_' + this.get('currentAccount.content.company.name') + '_' + this.get('dataType') + '_diduenjoy';
    }),

    underscoredDataType: computed('dataType', function () {
      var type = this.get('dataType');
      return type.replace('-', '_');
    }),

    exportFormatsDropdownDisabled: computed('availableExportFormats', function () {
      var formats = this.get('availableExportFormats');
      return formats.length === 1;
    }),
    selectedExportFormat: computed('availableExportFormats', function () {
      var formats = this.get('availableExportFormats');
      return formats.slice(-1)[0];
    }),
    availableExportFormats: computed('formats', function () {
      var _this2 = this;

      var formats = this.get('formats') || [];
      return formats.map(function (format) {
        return { label: _this2.get('i18n').t('words.' + format).string, key: format };
      });
    }),

    contentOptionsDropdownDisabled: computed('availableContentOptions', function () {
      var options = this.get('availableContentOptions');
      return options.length === 1 || this.get('dataType') === 'segment-breakdown';
    }),
    selectedContentOption: computed('availableContentOptions', function () {
      var options = this.get('availableContentOptions');
      return options.filter(function (opt) {
        return opt.key === 'all';
      })[0];
    }),
    availableContentOptions: computed(function () {
      return [{ label: this.get('i18n').t('words.everything').string, key: 'all' }, { label: this.get('i18n').t('words.custom').string, key: 'custom' }];
    }),

    exportCustomContentList: computed('customExportOptions', function () {
      var opts = this.get('customExportOptions') || [];
      var key = this.get('customExportOptionsKey');

      return opts.map(function (opt) {
        return _extends({}, opt, { selected: true, _label: opt[key] });
      });
    }),

    actions: {
      closeModal: function closeModal() {
        var callback = this.get('onClose');
        if (callback) callback();
      },

      validateExport: function validateExport() {
        var callback = this.get('onValidate');
        var selectedExportFormat = this.get('selectedExportFormat');
        var selectedContentOption = this.get('selectedContentOption');
        var formattedCustomList = this.get('exportCustomContentList').map(function (opt) {
          if (selectedContentOption.key === 'all' || opt.selected) return _extends({}, opt, { selected: true });
          return opt;
        });
        var contentType = this.get('fileContent.key');
        var fileName = this.get('exportName');
        var exportConfig = {
          export_format: selectedExportFormat.key,
          content_option: selectedContentOption.key,
          custom_columns_list: formattedCustomList,
          file_name: fileName,
          content_type: contentType,
          timezone: _dueDashboardHelpersCurrentTimezone['default'].compute()
        };
        if (callback) callback(exportConfig);
      },

      onSelectExportFormat: function onSelectExportFormat(option) {
        this.set('selectedExportFormat', option);
      },

      onSelectContentOption: function onSelectContentOption(option) {
        this.set('selectedContentOption', option);
      },

      onToggleCustomOption: function onToggleCustomOption(index) {
        var customOpts = this.get('exportCustomContentList');
        set(customOpts[index], 'selected', !customOpts[index].selected);
        this.set('exportCustomContentList', customOpts);
      },

      changeFileContent: function changeFileContent(fileContent) {
        this.set('fileContent', fileContent);
      }
    }
  });
});