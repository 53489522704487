define('due-dashboard/models/has-comment-scenario-condition', ['exports', 'due-dashboard/models/scenario-condition', 'ember-data', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioCondition, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    dummy: (0, _emberCpValidations.validator)('always-valid')
  });

  exports['default'] = _dueDashboardModelsScenarioCondition['default'].extend(Validations, {
    dummy: 42,
    with_comment: attr('boolean')
  });
});