define('due-dashboard/routes/companies/exports/history', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return null;
    },

    afterModel: function afterModel() {
      var controller = this.controllerFor('companies.exports.history');
      setTimeout(function () {
        controller.send('setModel');
      }, 0);
    }
  });
});