define('due-dashboard/components/survey-preview', ['exports', 'ember'], function (exports, _ember) {
  /* global _ */

  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    displayNavArrows: false,
    forcedStepType: null,
    currentStepIndex: 0,
    surveyLanguage: null,

    previewLanguage: computed('surveyLanguage', 'survey_draft.currentLanguageOrFirst', function () {
      return this.get('surveyLanguage') || this.get('survey_draft.currentLanguageOrFirst');
    }),

    basicLanguage: computed.alias('survey_draft.currentBasicOrFirst'),

    questionsPosition: ['position'],
    previewQuestions: computed.sort('previewLanguage.closed', 'questionsPosition'),

    previewDesktop: computed.equal('previewDevice', 'desktop'),
    previewMobile: computed.equal('previewDevice', 'mobile'),
    questionsStepCount: computed.alias('previewQuestions.length'),
    hasSocialNetworks: computed.notEmpty('survey_draft.social_networks'),
    hasWebsite: computed.bool('survey_draft.website'),

    stepIndex: function stepIndex(step) {
      var steps = this.get('steps');
      if (!steps.findBy('name', step)) {
        throw 'Unknown step';
      }
      return _.chain(steps).takeWhile(function (s) {
        return s.name !== step;
      }).map('length').compact().sum().value();
    },

    forceStepType: (function () {
      var forced = this.get('forcedStepType');
      if (forced) {
        var forcedStepIndex = this.stepIndex(forced);
        this.set('currentStepIndex', forcedStepIndex);
      }
    }).on('init').observes('forcedStepType', 'steps.@each.length'),

    stepTemplate: computed('stepType', function () {
      var type = this.get('stepType') || 'questions';
      return 'companies/surveys/edit/survey-preview-' + type;
    }),

    steps: computed('questionsStepCount', function () {
      return [{ length: this.get('questionsStepCount'), name: 'questions' }, { length: 1, name: 'recommendation' }, { length: 1, name: 'open_question' }, { length: 1, name: 'social_networks' }];
    }),

    stepCounts: computed.mapBy('steps', 'length'),

    stepCount: computed.sum('stepCounts'),

    websiteLinkContent: computed('previewLanguage.website_link_text', 'hasWebsite', function () {
      if (this.get('hasWebsite') && this.get('previewLanguage.website_link_text')) {
        return this.get('previewLanguage.website_link_text');
      } else {
        return '';
      }
    }),

    stepType: computed('steps.@each.{name,length}', 'currentStepIndex', function () {
      var idx = this.get('currentStepIndex');
      var sum = 0;
      var currentStep = this.get('steps').find(function (step) {
        sum += step.length;
        return idx < sum;
      });
      if (!currentStep) {
        return null;
        //throw 'Unknown step';
      }
      return currentStep.name;
    }),

    backgroundImage: computed('survey_draft.background_image', function () {
      if (this.get('survey_draft.background_image') !== undefined) {
        return 'background-image: url(' + this.get('survey_draft.background_image') + ');';
      } else {
        return '';
      }
    }),

    selectedQuestion: computed('previewQuestions.[]', 'currentStepIndex', function () {
      var questions = this.get('previewQuestions');
      if (!questions) {
        return null;
      }
      var idx = this.get('currentStepIndex');
      return questions.objectAt(idx);
    }),

    prettySelectedQuestionIndex: computed('currentStepIndex', function () {
      return this.get('currentStepIndex') + 1;
    }),

    questionsLength: computed('previewQuestions.length', function () {
      return (this.get('previewQuestions.length') || 0) + 1;
    }),

    hasPrevPreviewStep: computed('currentStepIndex', function () {
      var idx = this.get('currentStepIndex');
      return idx > 0;
    }),

    hasNextPreviewStep: computed('currentStepIndex', 'previewQuestions.[]', function () {
      var idx = this.get('currentStepIndex');
      var length = this.get('stepCount');
      return idx + 1 < length;
    }),

    actions: {
      setDevice: function setDevice(device) {
        this.set('previewDevice', device);
      },

      prevPreviewStep: function prevPreviewStep() {
        if (this.get('hasPrevPreviewStep')) {
          this.decrementProperty('currentStepIndex');
        }
      },

      nextPreviewStep: function nextPreviewStep() {
        if (this.get('hasNextPreviewStep')) {
          this.incrementProperty('currentStepIndex');
        }
      }
    }

  });
});