define('due-dashboard/mixins/filthy-model', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create({

    isRelationshipFilthy: function isRelationshipFilthy(name) {
      var rel = this.relationshipFor(name);
      if (!rel) {
        throw 'Unknown relationship: ' + name;
      }
      return this.get('_data.' + name + '.id') !== this.get(name + '.id');
    },

    isFilthy: computed(function () {
      if (this.get('hasDirtyAttributes')) {
        return true;
      }

      var self = this,
          dirty = false;
      this.eachRelationship(function (name, relationship) {
        // If we know it's dirty, don't bother checking any more
        if (dirty) {
          return;
        }

        if (relationship.kind === 'belongsTo') {
          dirty = self.get('_data.' + name + '.id') !== self.get(name + '.id');
        }
      });

      return dirty;
    }).volatile()

  });
});
// SOURCE: http://paulferrett.com/2014/ember-model-hasDirtyAttributes-when-belongsto-changes/