define('due-dashboard/components/v2/libraries/surveys/navbar/-design', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-design'],

    surveyDraft: computed('group.survey_draft', function () {
      return this.get('group.survey_draft');
    }),

    actions: {
      updateCurrentElement: function updateCurrentElement(currentElement) {
        this.set('selectedDesignElement', currentElement);
      },

      dispatchAction: function dispatchAction(action, arg) {
        var dispatcher = this.get('actionsDispatcher');
        if (dispatcher) {
          dispatcher(action, arg);
        }
      },

      updateDesignOptions: function updateDesignOptions(value) {
        this.set('group.survey_draft.design_options', _extends({}, this.get('group.survey_draft.design_options'), value));
        var saveCallback = this.get('group.autoSaveRevisionCallback');
        if (saveCallback) {
          this.send('dispatchAction', saveCallback);
        }
      },

      updateSurveyDraft: function updateSurveyDraft(field, value) {
        this.set('group.survey_draft.' + field, value);
        var saveCallback = this.get('group.autoSaveRevisionCallback');
        if (saveCallback) {
          this.send('dispatchAction', saveCallback);
        }
      }
    }
  });
});