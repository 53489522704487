define('due-dashboard/routes/settings/segments', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return _ember['default'].RSVP.hash({
        company: this.get('currentAccount.content.company'),
        languages: this.modelFor('application').dashboard_languages,
        allCompaniesSegments: this.store.query('companies_segment', { page: { number: 1, size: 50 } })
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('controller.model.needSaving')) {
          if (!confirm(this.get('i18n').t('settings.segments.confirm'))) {
            transition.abort();
            return false;
          }
        }
        return true;
      }
    }

  });
});