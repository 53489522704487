define('due-dashboard/models/match-profiles-scenario-condition', ['exports', 'due-dashboard/models/scenario-condition', 'ember-data', 'ember-cp-validations', 'due-dashboard/utils/has-many-through'], function (exports, _dueDashboardModelsScenarioCondition, _emberData, _emberCpValidations, _dueDashboardUtilsHasManyThrough) {
  var hasMany = _emberData['default'].hasMany;

  var Validations = (0, _emberCpValidations.buildValidations)({
    profiles: (0, _emberCpValidations.validator)('presence', true)
  });

  var M = _dueDashboardModelsScenarioCondition['default'].extend(Validations, {
    match_profiles_scenario_conditions_profiles: hasMany('match-profiles-scenario-conditions-profile', { async: true }),
    // condition_profiles: computed.mapBy('match_profiles_scenario_conditions_profile', 'profile'),

    defineSavepoint: function defineSavepoint() {
      var _this = this;

      return this.get('match_profiles_scenario_conditions_profiles').then(function (condition_profiles) {
        _this.set('_savepoint_condition_profiles', condition_profiles.toArray());
        return _this;
      });
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_condition_profiles');
      if (savepoint) {
        this.set('match_profiles_scenario_conditions_profiles', savepoint);
        savepoint.map(function (a) {
          return a.rollbackAttributes();
        });
      }
    }
  });

  _dueDashboardUtilsHasManyThrough['default'].define(M, 'match_profiles_scenario_conditions_profiles', {
    foreignKey: 'condition',
    hasManyKey: 'profiles',
    sourceKey: 'profile'
  });

  exports['default'] = M;
});