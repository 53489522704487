define('due-dashboard/components/v2/tabs-navigation/-tabs-navigation', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['due-tabs-navigation'],
    selectedTab: 0,

    actions: {
      updateSelectedTab: function updateSelectedTab(index) {
        this.set('selectedTab', index);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-radio-buttons}} radio buttons with selected yield display
 */