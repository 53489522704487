define('due-dashboard/components/v2/multi-dropdown-dynamic-options/settings/segments/create-segment-category', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  exports['default'] = _ember['default'].Component.extend({
    mouseEnter: function mouseEnter() {
      var hoverCallback = this.get('onHover');
      if (hoverCallback) {
        hoverCallback();
      }
    }
  });
});