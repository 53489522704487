define('due-dashboard/components/my-checkbox', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    mutable: true,

    actions: {
      click: function click() {
        var action = this.get('onClick');
        if (this.get('mutable')) {
          this.toggleProperty('checked');
        }
        if (action) {
          action();
        }
      }
    }
  });
});