define('due-dashboard/routes/companies/surveys/edit/mailing-qrcode', ['exports', 'ember', 'due-dashboard/mixins/survey-step'], function (exports, _ember, _dueDashboardMixinsSurveyStep) {
  exports['default'] = _ember['default'].Route.extend(_dueDashboardMixinsSurveyStep['default'], {

    model: function model() {
      return _ember['default'].RSVP.hash({
        survey: this.modelFor('companies.surveys.edit').survey
      });
    },

    stepConfig: {
      layout: 'preview'
    },

    actions: {
      nextStep: function nextStep() {
        this.replaceWith('companies.overview');
      }
    }

  });
});