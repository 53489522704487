define('due-dashboard/components/v2/libraries/surveys/navbar/translations/-general', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['survey-translations-general'],

    htmlMetaTranslations: computed('languagesToDisplay', function () {
      var htmlMeta = this.get('htmlMeta') || {};

      return this.get('languagesToDisplay').map(function (lang) {
        return { name: lang, htmlTitle: (htmlMeta[lang ? lang.toLowerCase() : ''] || {})['title'] || '' };
      });
    }),

    actions: {
      setMetaTitle: function setMetaTitle(lang, value) {
        var valueLower = lang.toLowerCase();
        var htmlMeta = this.get('htmlMeta') || {};
        if (value.length == 0 && htmlMeta[valueLower]) {
          delete htmlMeta[valueLower];
        } else {
          htmlMeta = _extends({}, htmlMeta, _defineProperty({}, valueLower, { title: value }));
        }
        this.sendAction('updateSurveyHtmlMeta', htmlMeta);
      }
    }
  });
});