define('due-dashboard/controllers/settings/management-question-category/edit', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    suggestions: [],
    selected: null,

    questions: computed.alias('model.questions'),

    _searchQuestion: function _searchQuestion(term, resolve, reject) {
      this.get('store').query('questions-suggestion', { filter: { text: term } }).then(function (questions) {
        resolve(questions), reject;
      });
    },

    actions: {
      saveUpdates: function saveUpdates() {
        this.set('suggestions', []);
        this.send('saveCategory', this.get('model'));
      },

      removeQuestion: function removeQuestion(question) {
        this.get('model.questions').removeObject(question);
      },

      prevStep: function prevStep() {
        this.set('suggestions', []);
        this.send('previous', this.get('model'));
      },

      addSuggestedQuestion: function addSuggestedQuestion(question) {
        this.get('model.questions').createFragment({ tag: question.get('tag'), text: question.get('text') });
        this.get('suggestions').removeObject(question);
      },

      addAllQuestionSuggestions: function addAllQuestionSuggestions() {
        var _this = this;

        this.get('suggestions').forEach(function (question) {
          _this.get('model.questions').createFragment({ tag: question.get('tag'), text: question.get('text') });
        });
        this.set('suggestions', []);
      }
    }
  });
});