define('due-dashboard/components/v2/-collapse-section', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['due-collapse'],
    classNameBindings: ['customClasses'],
    collapsed: false,

    actions: {
      toggleProp: function toggleProp() {
        this.toggleProperty('collapsed');

        var alwaysToggle = this.get('alwaysToggle');
        var onToggle = this.get('onToggle');

        if (this.get('collapsed') && onToggle || alwaysToggle && onToggle) onToggle();
      },

      onClickCombo: function onClickCombo(selectedOption) {
        this.get('triggerAction')(selectedOption);
      }
    }
  });
});