define('due-dashboard/objects/reports/due-report-table-params', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Object.extend({

    type: null,
    properties: null,
    front_format: true,
    view_by: null,
    default_view_by: null,
    segments: null,
    view_type: null,
    object_ids: null,
    object_id: null,
    sort: null,
    kind: null,
    group: null,
    subkind: null,
    page_number: null,
    page_size: null,
    filters: null,
    componentFilters: null,
    date_range: null,
    tz: null,
    query_segments: null,
    title: null,
    subtitle: null,
    hasTitle: null,

    init: function init() {
      var _this = this;

      this.set('properties', this.get('component.properties'));
      this.set('view_by', this.get('component.config.view_by'));
      this.set('default_view_by', this.get('component.config.default_view_by'));
      this.set('segments', this.get('component.config.view_by.segments'));
      this.set('view_type', this.get('component.view_type'));
      this.set('object_ids', this.get('component.config.object_ids'));
      this.set('object_id', this.get('component.config.object_id'));
      this.set('group', this.get('component.view_type'));
      this.set('sort', this.get('component.config.sort'));
      this.set('lvls', this.get('component.config.lvls'));
      this.set('kind', this.get('component.config.kind'));
      this.set('subkind', this.get('component.config.subkind'));
      this.set('pagination', this.get('component.config.pagination'));
      this.set('index', this.get('component.config.index'));
      this.set('translations', this.get('component.config.translations'));
      this.set('page_size', this.get('component.config.page_size') || 25);
      this.set('page_number', this.get('component.config.page_number') || 1);
      this.set('componentFilters', this.get('component.filters') || {});
      Object.entries(this.get('component.config')).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var key = _ref2[0];
        var value = _ref2[1];

        if (key.startsWith('distribution_')) {
          _this.set(key, value);
        }
      });
      this.set('trendHideValue', this.get('component.config.trend.hideValue'));

      // Impact score
      this.set('positiveImpactHideValue', this.get('component.config.positive_impact.hideValue'));
      this.set('negativeImpactHideValue', this.get('component.config.negative_impact.hideValue'));

      // Impact level
      this.set('lowThreshold', this.get('component.config.impact_level.low_threshold'));
      this.set('moderateThreshold', this.get('component.config.impact_level.moderate_threshold'));
      this.set('strongThreshold', this.get('component.config.impact_level.strong_threshold'));

      this.set('disableHover', this.get('component.config.disableHover'));

      this._setType();

      if (this.get('type') === 'segment-breakdown') {
        this.set('segment_limits', this.get('component.config.limits.segments'));
        this.set('segment_ids', Object.values(this.get('view_by.segments')));
      }
    },

    filterSegments: function filterSegments(profile, hierarchy) {
      if (profile !== 'custom') {
        return;
      }
      var segments = this.get('segments');
      segments = Object.keys(segments).filter(function (key) {
        return hierarchy.includes(key.toLowerCase());
      }).reduce(function (cur, key) {
        return Object.assign(cur, _defineProperty({}, key, segments[key]));
      }, {});
      this.set('view_by', { segments: segments });
      this.set('segments', segments);
      if (this.get('type') === 'segment-breakdown') {
        this.set('segment_ids', Object.values(segments));
      }
    },

    _setType: function _setType() {
      // Currently "tag-breakdown" and "array" component types are splitted.
      // So we can't only use 'kind' to get the right converter
      var type = this.get('component.type');
      if (type == 'tag-breakdown') {
        this.set('type', 'tag-breakdown');
      } else {
        this.set('type', this.get('view_type'));
      }
    },

    setFilters: function setFilters(filters) {
      this.set('filters', _.omit(filters, 'start_date', 'end_date'));
    },

    setDateRange: function setDateRange(start_date, end_date) {
      var dateRange = { from: '', to: '' };
      if (start_date && end_date) {
        dateRange.from = (0, _moment['default'])(start_date).format().split('T')[0];
        dateRange.to = (0, _moment['default'])(end_date).format().split('T')[0];
      } else {
        // Set past 30 days if not in fitlers
        var date = new Date();
        dateRange.to = (0, _moment['default'])(Date.now()).format().split('T')[0];
        date.setDate(date.getDate() - 30);
        dateRange.from = (0, _moment['default'])(date).format().split('T')[0];
      }
      this.set('date_range', dateRange);
    },

    setQuerySegments: function setQuerySegments(segments) {
      this.set('query_segments', segments);
    },

    setTimezone: function setTimezone(timezone) {
      this.set('tz', timezone);
    },

    setPagination: function setPagination(pageNumber, pageSize) {
      this.set('page_number', pageNumber);
      this.set('page_size', pageSize);
    },

    setTranslations: function setTranslations(currentLanguage) {
      var translations = this.translations[currentLanguage];
      this.set('trendTextTooltip', translations.trendTextTooltip);
      this.set('title', translations.title);
      this.set('subtitle', translations.subtitle);
      this.set('hasTitle', (translations.title || translations.subtitle) != null);
    },

    toJson: function toJson() {
      var _this2 = this;

      var fields = ['date_range', 'filters', 'properties', 'front_format', 'group', 'object_ids', 'object_id', 'sort', 'lvls', 'kind', 'subkind', 'translations', 'query_segments', 'page_size', 'pagination'];
      return fields.reduce(function (json, field) {
        json[field] = _this2[field];return json;
      }, {});
    }
  });
});