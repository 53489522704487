define('due-dashboard/initializers/string', ['exports'], function (exports) {
  exports['default'] = {
    name: 'extend-string',

    initialize: function initialize() {
      String.prototype.capitalize = function () {
        return this.replace(/^\w/, function (c) {
          return c.toUpperCase();
        });
      };
    }

  };
});