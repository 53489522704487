define('due-dashboard/routes/settings/integrations/woocommerce', ['exports', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({

    includeSurveys: 'post-confirmed-order-survey',

    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      if (!model.company_integration.get('api_version')) {
        model.company_integration.set('api_version', 'v3');
      }
    }

  });
});