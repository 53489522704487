define('due-dashboard/components/tags/create-subtag', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagManager: _ember['default'].inject.service(),

    tagName: '',
    subTagName: null,
    saveSubTagPromise: null,
    selectedTagColor: null,
    feelingEnabled: false,
    modalIsOpened: false,
    tagExists: false,

    /** Private props */
    rootCauseColor: '#71899C',
    actionTakenColor: '#71899C',

    currentTab: computed('kind', function () {
      var i18n = this.get('i18n');
      var options = {
        'tag': {
          title: i18n.t('settings.tags.add_subtag_button'),
          inputName: i18n.t('settings.tags.tag_name'),
          assignability: { title: i18n.t('settings.tags.assignability.tag.title'), options: 'settings.tags.assignability.tag.' },
          color: i18n.t('settings.tags.tag_color'),
          error: {
            name: i18n.t('settings.tags.error.tag_name'),
            duplicate: i18n.t('settings.tags.error.tag_duplicated')
          }
        },
        'root_cause': {
          title: i18n.t('settings.tags.add_subroot_cause_button'),
          inputName: i18n.t('settings.tags.root_cause_name'),
          assignability: { title: i18n.t('settings.tags.assignability.root_cause.title'), options: 'settings.tags.assignability.root_cause.' },
          color: i18n.t('settings.tags.root_cause_color'),
          error: {
            name: i18n.t('settings.tags.error.root_cause_name'),
            duplicate: i18n.t('settings.tags.error.root_cause_duplicate')
          }
        },
        'action_taken': {
          title: i18n.t('settings.tags.add_sub_action_taken'),
          inputName: i18n.t('settings.tags.action_taken_name'),
          assignability: { title: i18n.t('settings.tags.assignability.action_taken.title'), options: 'settings.tags.assignability.action_taken.' },
          color: i18n.t('settings.tags.action_taken_color'),
          error: {
            name: i18n.t('settings.tags.error.action_taken_name'),
            duplicate: i18n.t('settings.tags.error.action_taken_duplicate')
          }
        }
      };
      return options[this.get('kind')];
    }),

    tagAssignability: computed('parentTag', function () {
      return this.get('parentTag.not_assignable') ? 'category' : 'standard';
    }),

    tagDueExclusive: computed.oneWay('parentTag.due_exclusive'),

    hasErrorInSubTagName: computed('subTagName', function () {
      return this.get('subTagName') && this.get('subTagName').includes('>');
    }),

    disabledSave: computed('subTagName', 'hasErrorInSubTagName', 'selectedTagColor', function () {
      if (!['root_cause', 'action_taken'].includes(this.get('kind'))) {
        return !this.get('subTagName') || this.get('hasErrorInSubTagName') || this.get('tagExists');
      }
      return !this.get('subTagName') || this.get('hasErrorInSubTagName') || this.get('tagExists') || !this.get('selectedTagColor');
    }),

    _updateTagsView: function _updateTagsView(parentTag, newTag) {
      parentTag.subTags.pushObject(newTag);
      this.get('notifyTagCreation')(newTag);
      this.closeModal();
    },

    _createSubTag: function _createSubTag() {
      var _this = this;

      var parentTag = this.get('parentTag');
      var subTagName = this.get('subTagName');
      var tagDueExclusive = this.get('tagDueExclusive');
      var feelingEnabled = this.get('feelingEnabled');
      var colors = {
        'tag': this.get('selectedTagColor'),
        'root_cause': this.get('rootCauseColor'),
        'action_taken': this.get('actionTakenColor')
      };
      var tagExists = false;
      this.get('tagManager').getAll({}, this.get('kind')).then(function (tags) {
        var tagName = parentTag.get('name') + ' > ' + subTagName;
        tags.forEach(function (tag) {
          if (tag.get('name') === tagName) {
            tagExists = true;
            _this.set('tagExists', tagExists);
            return;
          }
        });
        if (!tagExists) {
          _this.store.createRecord('tag', {
            name: tagName,
            color: colors[_this.get('kind')],
            due_exclusive: tagDueExclusive,
            feeling_enabled: ['root_cause', 'action_taken'].includes(_this.get('kind')) ? false : feelingEnabled,
            kind: _this.get('kind')
          }).save().then(function (newTag) {
            return _this._updateTagsView(parentTag, newTag);
          });
        }
      });
    },

    _subTagValidation: function _subTagValidation() {
      if (this.get('subTagName').includes('>') || !this.get('subTagName').length) {
        alert("The name shoud not be empty and the character > is not allowed");
        return;
      }
      this._createSubTag();
    },

    closeModal: function closeModal() {
      if (!this.get('isDestroyed')) {
        this.set('modalIsOpened', false);
      }
    },

    initFields: function initFields() {
      var colors = {
        'tag': this.get('selectedTagColor'),
        'root_cause': this.get('rootCauseColor'),
        'action_taken': this.get('actionTakenColor')
      };
      this.set('tagName', '');
      this.set('subTagName', null);
      this.set('selectedTagColor', colors[this.get('kind')]);
    },

    actions: {
      validateSubTagCreation: function validateSubTagCreation() {
        this._subTagValidation();
      },

      checkKeyValue: function checkKeyValue(_, e) {
        if (e.keyCode == 13) {
          this._subTagValidation();
        } else if (e.keyCode == 27) {
          this.toggleProperty('showSubTagCreation');
        }
      },

      cancelSubTagCreation: function cancelSubTagCreation() {
        this.toggleProperty('showSubTagCreation');
      },

      showSubTagCreation: function showSubTagCreation() {
        if (this.get('isTagEditable')) {
          this.initFields();
          this.set('modalIsOpened', true);
        }
      },
      closeModal: function closeModal() {
        this.closeModal();
      },

      toggleTagAssignation: function toggleTagAssignation(option) {
        this.set('tagAssignability', option);
      },

      toggleDueExclusiveTag: function toggleDueExclusiveTag() {
        this.toggleProperty('tagDueExclusive');
      },

      setTagColor: function setTagColor(color) {
        this.set('selectedTagColor', color);
      },

      toggleTagFeeling: function toggleTagFeeling() {
        this.toggleProperty('feelingEnabled');
      }
    }
  });
});