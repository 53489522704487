define('due-dashboard/initializers/store-in-routes', ['exports'], function (exports) {
  // import Ember from 'ember';

  exports['default'] = {
    name: 'store-in-routes',

    initialize: function initialize(application) {
      application.inject('component', 'store', 'service:store');
    }
  };
});