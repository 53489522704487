define('due-dashboard/models/match-devices-scenario-condition', ['exports', 'due-dashboard/models/scenario-condition', 'ember-data', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioCondition, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    devices: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _dueDashboardModelsScenarioCondition['default'].extend(Validations, {
    devices: attr('array', { defaultValue: [] }),

    defineSavepoint: function defineSavepoint() {
      return this._super.apply(this, arguments);
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      return this._super.apply(this, arguments);
    }
  });
});