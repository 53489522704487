define('due-dashboard/helpers/truncate-float', ['exports', 'ember'], function (exports, _ember) {
  exports.truncateFloat = truncateFloat;

  function truncateFloat(params, hash) {
    var value = params[0];
    var len = hash.limit === undefined ? 2 : hash.limit;
    return Math.round(value * 10 * len) / 10 * len; // jshint ignore:line
  }

  exports['default'] = _ember['default'].Helper.helper(truncateFloat);
});