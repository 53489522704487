define('due-dashboard/components/note-count', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    noteImage: computed('profile', function () {
      return this.asset_mapper.asset_url('images/icons/' + this.get('profile') + '.png');
    })

  });
});