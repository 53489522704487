define('due-dashboard/controllers/companies/exports/history', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var debounce = _ember['default'].run.debounce;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    selectedPagination: { key: 25, text: '25' },
    isLoading: true,
    availablePaginations: computed(function () {
      return [{ key: 25, text: '25' }, { key: 50, text: '50' }, { key: 100, text: '100' }];
    }),

    isEmpty: computed('model', function () {
      var model = this.get('model');

      return model ? model.toArray().length === 0 : false;
    }),

    computedExportRequests: computed('model', function () {
      var exportRequests = this.get('model');
      var statusClass = { processing: 'draft', ready_to_download: 'live', error: 'error', scheduled: 'deleted' };
      if (!exportRequests) {
        return;
      }
      var computedExportRequests = exportRequests.map(function (exportRequest) {
        return {
          name: exportRequest.get('file_file_name'),
          status: statusClass[exportRequest.get('status')],
          statusText: exportRequest.get('status'),
          prettyCreatedAt: exportRequest.get('prettyCreatedAt'),
          url: exportRequest.get('url')
        };
      });
      return computedExportRequests;
    }),

    paginationMeta: computed('model.meta', function () {
      var model = this.get('model');

      return {
        page_count: model ? model.get('meta').page_count : 0,
        current_page: model ? model.get('meta').current_page : 0
      };
    }),

    _queryExportRequests: function _queryExportRequests() {
      var _this = this;

      var pagination = this.get('selectedPagination');
      var actualPage = this.get('paginationMeta');
      var filters = { page: { size: pagination.key, number: actualPage.current_page } };
      this.set('isLoading', true);
      this.notifyPropertyChange('isLoading');
      this.store.query('unioned_export_request', filters).then(function (exportRequests) {
        _this.set('model', exportRequests);
        _this.set('isLoading', false);
        set(pagination, 'current_page', exportRequests.get('meta').current_page);
        set(pagination, 'page_count', exportRequests.get('meta').page_count);
        _this.set('paginationMeta', pagination);
      });
    },

    actions: {
      setModel: function setModel() {
        this._queryExportRequests();
      },

      setdisplayExport: function setdisplayExport() {
        this.transitionToRoute('companies.exports.index');
      },

      changePagination: function changePagination(selectedPagination) {
        var _this2 = this;

        var meta = this.get('paginationMeta');
        if (!this.get('isEmpty')) {
          this.set('selectedPagination', selectedPagination);
          set(meta, 'current_page', 1);
          this.set('paginationMeta', meta);
          return new Promise(function (resolve, reject) {
            debounce(_this2, _this2._queryExportRequests, resolve, reject, 600);
          });
        }
      },

      previousPage: function previousPage() {
        var _this3 = this;

        if (!this.get('isEmpty')) {
          var meta = this.get('paginationMeta');
          set(meta, 'current_page', meta['current_page'] - 1);
          this.set('paginationMeta', meta);
          return new Promise(function (resolve, reject) {
            debounce(_this3, _this3._queryExportRequests, resolve, reject, 600);
          });
        }
      },

      nextPage: function nextPage() {
        var _this4 = this;

        if (!this.get('isEmpty')) {
          var meta = this.get('paginationMeta');
          set(meta, 'current_page', meta['current_page'] + 1);
          this.set('paginationMeta', meta);
          return new Promise(function (resolve, reject) {
            debounce(_this4, _this4._queryExportRequests, resolve, reject, 600);
          });
        }
      }
    }
  });
});