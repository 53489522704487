define('due-dashboard/controllers/settings/digests/edit', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;
  var copy = _ember['default'].copy;
  var service = _ember['default'].inject.service;
  exports['default'] = Controller.extend({
    currentAccount: service(),
    tempName: '',
    editingName: false,
    savingDraft: false,
    save_promise: false,
    publish_promise: false,
    subscribedAccounts: [],
    digest: computed.alias('model.digest'),
    properties: ['timeframe', 'send_period', 'scheduled_day', 'scheduled_at', 'segment', 'components', 'segment_id', 'language_id', 'translations'],

    componentsCopy: computed('digest', {
      get: function get() {
        return copy(this.get('digest.draft_components'), true);
      },
      set: function set(_, value) {
        return value;
      }
    }),

    translationsCopy: computed('digest', {
      get: function get() {
        return copy(this.get('digest.draft_translations'), true);
      },
      set: function set(_, value) {
        return value;
      }
    }),

    hideQuestion: computed('digest', function () {
      return this.get('model.company.enable_new_survey_dashboard');
    }),

    addableComponents: computed('digest', function () {
      var components = [{ icon: 'plus', action: 'profiles_breakdown', text: this.get('i18n').t('digest.component.profiles_breakdown') }, { icon: 'plus', action: 'feedback_counter', text: this.get('i18n').t('digest.component.feedback_counter') }, { icon: 'plus', action: 'detailed_feedback_counter', text: this.get('i18n').t('digest.component.detailed_feedback_counter') }, { icon: 'plus', action: 'sat_score_resume', text: this.get('i18n').t('digest.component.sat_score_resume') }, { icon: 'plus', action: 'feedback_list', text: this.get('i18n').t('digest.component.feedback_list') }, { icon: 'plus', action: 'top_progression', text: this.get('i18n').t('digest.component.top_progression') }, { icon: 'plus', action: 'segment_breakdown', text: this.get('i18n').t('digest.component.segment_breakdown') }, { icon: 'plus', action: 'strength_and_weakness', text: this.get('i18n').t('digest.component.strength_and_weakness') }, { icon: 'plus', action: 'redirection_button', text: this.get('i18n').t('digest.component.redirection_button') }, { icon: 'plus', action: 'tag_breakdown', text: this.get('i18n').t('digest.component.tag_breakdown') }, { icon: 'plus', action: 'tag_top_flop', text: this.get('i18n').t('digest.component.tag_top_flop') }, { icon: 'plus', action: 'single_custom_metric', text: this.get('i18n').t('digest.component.single_custom_metric') }];
      if (!this.get('hideQuestion')) {
        components.push({ icon: 'plus', action: 'closed_question', text: this.get('i18n').t('digest.component.closed_question') });
      }
      return components;
    }),

    getCopies: function getCopies() {
      this.set('digest.draft_components', copy(this.get('componentsCopy'), true));
      this.set('digest.draft_translations', copy(this.get('translationsCopy'), true));
    },

    setCopies: function setCopies() {
      this.set('componentsCopy', copy(this.get('digest.draft_components'), true));
      this.set('translationsCopy', copy(this.get('digest.draft_translations'), true));
    },

    move: function move(element, delta) {
      var array = this.get('componentsCopy.data');
      var index = array.indexOf(element);
      var newIndex = index + delta;
      var first = array[index];
      var last = array[newIndex];
      if (newIndex < 0 || newIndex == array.length) return;
      this.get('componentsCopy.data').replace(index, 1, last).replace(newIndex, 1, first);
    },

    scrollTo: function scrollTo(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },

    saveDigest: function saveDigest() {
      var _this2 = this;

      this.set('errorMessage', null);
      this.set('compErrorMessage', null);
      this.getCopies();
      this.set('digest.unpublished_change', true);
      var savePromise = this.get('digest').save();
      this.set('save_promise', savePromise);
      savePromise.then(function () {
        _this2.set('reloadIframe', Date.now());
        _this2.set('savingDraft', false);
      });
    },

    validateDigest: function validateDigest() {
      var digest = this.get('digest');
      var currentTag = this.get('currentTag');
      if (!digest.get('draft_translations.' + currentTag + '.email_subject') || digest.get('draft_translations.' + currentTag + '.email_subject').length < 2) {
        return [false, "empty_subject"];
      }
      if (!digest.get('name') || digest.get('name').length < 2) {
        return [false, "empty_name"];
      }
      if (!digest.get('draft_translations.' + currentTag + '.title') || digest.get('draft_translations.' + currentTag + '.title').length < 2) {
        return [false, "empty_title"];
      }
      return [true, 'valid'];
    },

    validateComponents: function validateComponents() {
      var result = [true, 'valid'];
      var digest = this.get('digest');
      var currentTag = this.get('currentTag');
      this.get('componentsCopy.data').forEach(function (component, index) {
        if (Object.entries(digest.get('draft_translations.' + currentTag + '.components.' + component.id)).length == 0) {
          result = [false, "component_empty_title", index];
        }
        Object.entries(digest.get('draft_translations.' + currentTag + '.components.' + component.id)).forEach(function (keyAndValue) {
          if (keyAndValue[0] == 'title' && (!keyAndValue[1] || keyAndValue[1].length < 2)) {
            result = [false, "component_empty_title", index];
          }
        });
        Object.entries(component.options).forEach(function (keyAndValue) {
          if (keyAndValue[0] == 'segment' && (!keyAndValue[1] || keyAndValue[1].length < 2)) {
            result = [false, "component_empty_segment", index];
          }
        });
      });
      return result;
    },

    publish: function publish() {
      var _this3 = this;

      this.getCopies();
      var digestValid = this.validateDigest();
      var compValid = this.validateComponents();
      this.set('errorMessage', null);
      this.set('compErrorMessage', null);
      if (digestValid[0] && compValid[0]) {
        this.get('properties').forEach(function (porperty) {
          _this3.set('digest.live_' + porperty, _this3.get('digest.draft_' + porperty));
        });
        this.set('digest.live', true);
        this.set('digest.unpublished_change', false);
        var publishPromise = this.get('digest').save();
        this.set('publish_promise', publishPromise);
        publishPromise.then(function () {
          return _this3.set('savingDraft', false);
        });
      } else {
        this.set('savingDraft', false);
        if (!digestValid[0]) {
          this.set('errorMessage', digestValid[1]);
        }
        if (!compValid[0]) {
          this.set('compErrorMessage', compValid[1]);
          this.set('compErrorIndex', compValid[2]);
          this.set('errorMessage', 'unvalid_component');
        }
      }
    },

    currentTag: computed('digest.draft_language', function () {
      return this.get('digest.draft_language.tag').toLowerCase();
    }),

    hasSeveralLanguagues: computed('model.languages', function () {
      if (this.get('model.languages').toArray().length > 1) {
        return true;
      }
      return false;
    }),

    currentTitle: computed('currentTag', 'translationsCopy', {
      get: function get() {
        return this.get('translationsCopy.' + this.get('currentTag') + '.title');
      },
      set: function set(_, value) {
        this.set('translationsCopy.' + this.get('currentTag') + '.title', value);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDigest'), 2500));
        return value;
      }
    }),

    currentSubject: computed('currentTag', 'translationsCopy', {
      get: function get() {
        return this.get('translationsCopy.' + this.get('currentTag') + '.email_subject');
      },
      set: function set(_, value) {
        this.set('translationsCopy.' + this.get('currentTag') + '.email_subject', value);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDigest'), 2500));
        return value;
      }
    }),

    dropDownActionsList: computed('digest', 'digest.status', function () {
      var status = this.get('digest.status');
      var digestActions = [{ icon: 'tio-edit', action: 'editName', text: this.get('i18n').t('digest.edit.edit_name') }];
      if (status === 'pause') {
        digestActions.push({ icon: 'tio-play', action: 'resume', text: this.get('i18n').t('digest.resume') });
      } else if (status === 'live') {
        digestActions.push({ icon: 'tio-pause', action: 'pause', text: this.get('i18n').t('digest.pause') });
      }
      return digestActions;
    }),

    discardModal: computed('digest', 'digest.status', 'digest.unpublished_change', function () {
      return {
        trigger: {
          textKey: 'digest.discard',
          disabled: !this.get('digest.unpublished_change'),
          buttonIcon: 'tio-clear',
          buttonClass: '',
          buttonIntent: 'secondary',
          triggerCtnClass: 'new-2020-btn large-icon'
        },
        title: { textKey: 'digest.edit.modals.discard.title', 'class': '' },
        content: { textKey: 'digest.edit.modals.discard.content', 'class': '' },
        cancelBtn: { textKey: 'words.cancel', intent: 'secondary', 'class': '' },
        buttons: [{ action: 'discard', textKey: 'digest.discard_changes', customClass: '', intent: 'danger' }]
      };
    }),

    publishModal: computed('digest', 'digest.status', 'digest.unpublished_change', function () {
      var triggerTextKey = 'digest.update',
          titleText = 'digest.edit.modals.update.title',
          contentText = 'digest.edit.modals.update.content';

      if (this.get('digest.status') === 'draft') {
        triggerTextKey = 'digest.publish';
        titleText = 'digest.edit.modals.publish.title';
        contentText = 'digest.edit.modals.publish.content';
      }
      return {
        trigger: {
          textKey: triggerTextKey,
          disabled: !this.get('digest.unpublished_change'),
          buttonIcon: 'tio-done',
          buttonClass: '',
          buttonIntent: 'primary',
          triggerCtnClass: 'new-2020-btn large-icon'
        },
        title: { textKey: titleText, 'class': '' },
        content: { textKey: contentText, 'class': '' },
        cancelBtn: { textKey: 'words.cancel', 'class': '', intent: 'secondary' },
        buttons: [{ action: 'publishDelay', textKey: 'digest.publish_confirm', customClass: '', intent: 'primary' }]
      };
    }),

    publishMessage: computed('digest', 'digest.status', function () {
      if (this.get('digest.status') === 'draft') {
        return 'digest.edit.messages.digest_published';
      }
      return 'digest.edit.messages.digest_updated';
    }),

    actions: {
      triggerAction: function triggerAction(action) {
        this.send(action);
      },

      editName: function editName() {
        this.set('tempName', this.get('digest.name'));
        this.set('editingName', true);
        window.setTimeout(function () {
          document.getElementById('digestNameInput').focus();
        }, 0);
      },

      saveNameAndDigest: function saveNameAndDigest() {
        this.send('saveName');
        this.send('saveDigestDelay');
      },

      saveName: function saveName() {
        this.set('digest.name', this.get('tempName'));
        this.set('editingName', false);
        this.get('digest').save();
      },

      saveDigestDelay: function saveDigestDelay() {
        this.set('savingDraft', true);
        this.set('loadingMessage', 'digest.edit.messages.draft_saving');
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDigest'), 2500));
      },

      goToTranslation: function goToTranslation() {
        var _this4 = this;

        this.get('digest').save().then(function () {
          return _this4.transitionToRoute('settings.digests.translations', _this4.get('digest.id'));
        });
      },

      publishDelay: function publishDelay() {
        this.set('savingDraft', true);
        if (this.get('digest.status') === 'draft') {
          this.set('loadingMessage', 'digest.edit.messages.digest_publishing');
        } else {
          this.set('loadingMessage', 'digest.edit.messages.digest_updating');
        }
        _ember['default'].run.debounce(this, this.get('publish'), 2500);
      },

      discard: function discard() {
        this.set('errorMessage', null);
        this.set('compErrorMessage', null);
        this.set('digest.draft_email_subject', this.get('digest.live_email_subject'));
        this.set('digest.draft_title', this.get('digest.live_title'));
        this.set('digest.draft_timeframe', this.get('digest.live_timeframe'));
        this.set('digest.draft_send_period', this.get('digest.live_send_period'));
        this.set('digest.draft_scheduled_day', this.get('digest.live_scheduled_day'));
        this.set('digest.draft_scheduled_at', this.get('digest.live_scheduled_at'));
        this.set('digest.draft_segment', this.get('digest.live_segment'));
        this.set('digest.draft_components', this.get('digest.live_components'));
        this.set('digest.draft_segment_id', this.get('digest.live_segment_id'));
        this.set('digest.draft_language', this.get('digest.live_language'));
        this.set('digest.draft_translations', this.get('digest.live_translations'));
        this.set('digest.unpublished_change', false);
        this.setCopies();
        this.set('discard_promise', this.get('digest').save());
      },

      publishWithoutLive: function publishWithoutLive() {
        this.saveDigest();
        this.transitionToRoute('settings.digests.index');
      },

      backToDigestsList: function backToDigestsList() {
        if (!this.get('savingDraft')) {
          this.set('subscribedAccounts', []);
          this.transitionToRoute('settings.digests.index');
        }
      },

      pause: function pause() {
        this.set('digest.live', false);
        this.set('pause_promise', this.get('digest').save());
      },

      resume: function resume() {
        this.set('digest.live', true);
        this.set('live_promise', this.get('digest').save());
      },

      moveCompUp: function moveCompUp(element) {
        this.move(element, -1);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDigest'), 2500));
      },

      moveCompDown: function moveCompDown(element) {
        this.move(element, 1);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDigest'), 2500));
      },

      deleteComp: function deleteComp(component) {
        var translations = this.get('translationsCopy');
        Object.keys(translations).forEach(function (tag) {
          return translations[tag].components[component.id] = null;
        });
        translations[this.get('currentTag')].components[component.id] = null;
        this.set('translationsCopy', translations);
        this.get('componentsCopy.data').removeObject(component);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDigest'), 2500));
      },

      duplicateComponent: function duplicateComponent(comp) {
        var new_comp = copy(comp, true);
        new_comp.id = Date.now();
        var index = this.get('componentsCopy.data').indexOf(comp) + 1;
        var translations = this.get('translationsCopy');
        var trueLocal = this.get('i18n').get('locale');
        var _this = this;
        Object.keys(translations).forEach(function (tag) {
          translations[tag].components[new_comp.id] = copy(translations[tag].components[comp.id], true);
          _this.get('i18n').set('locale', tag);
          translations[tag].components[new_comp.id].title = _this.get('i18n').t('digest.component.copy') + translations[tag].components[comp.id].title;
        });
        this.get('i18n').set('locale', trueLocal);
        this.get('componentsCopy.data').insertAt(index, new_comp);
        this.set('translationsCopy', translations);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDigest'), 2500));
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('scrollTo'), new_comp.id, 100));
      },

      addComponent: function addComponent(type) {
        var _this5 = this;

        var translations = this.get('translationsCopy');
        var id = Date.now();
        var defaultTitle = this.get('i18n').t('digest.component.' + type).toString();
        switch (type) {
          case 'segment_breakdown':
            this.get('componentsCopy.data').addObject({
              id: id,
              type: type,
              options: {
                score: true,
                max_list_size_top: 10,
                max_list_size_bottom: 10,
                segment: null,
                max_tag_list: 3,
                tag_include: 'excluded',
                tag_order_mode: 'volume',
                feeling_mode: 'percent',
                tag_order_threshold: 0
              },
              filters: []
            });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                title: defaultTitle,
                subtitle: '',
                segment_column_title: _this5.get('i18n').t('digest.component.segment_breakdown_default_segment_column_title').toString(),
                score_column_title: _this5.get('i18n').t('digest.component.segment_breakdown_default_score_column_title').toString(),
                change_column_title: _this5.get('i18n').t('digest.component.segment_breakdown_default_change_column_title').toString(),
                counter_column_title: _this5.get('i18n').t('digest.component.segment_breakdown_default_counter_column_title').toString(),
                respondants_name: _this5.get('i18n').t('digest.component.segment_breakdown_default_respondants_column_title').toString(),
                feelings_name: _this5.get('i18n').t('digest.component.feelings_default').toString()
              };
            });
            break;

          case 'closed_question':
            this.get('componentsCopy.data').addObject({ id: id, type: type, options: { max_list_size: 10 }, filters: [] });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                title: defaultTitle,
                subtitle: '',
                question_column_title: '',
                score_column_title: '',
                change_column_title: '',
                counter_column_title: ''
              };
            });
            break;

          case 'redirection_button':
            this.get('componentsCopy.data').addObject({ id: id, type: type, options: { url: '', stat_id: '' }, filters: [] });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = { title: defaultTitle, subtitle: '', button_title: _this5.get('i18n').t('digest.component.click_here').toString() };
            });
            break;

          case 'feedback_list':
            this.get('componentsCopy.data').addObject({ id: id, type: type, options: { max_list_size: 10 }, filters: [] });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                title: defaultTitle,
                subtitle: ''
              };
            });
            break;

          case 'feedback_counter':
            this.get('componentsCopy.data').addObject({ id: id, type: type, options: { previous_period: true, year_2_date: true, year_2_year: true }, filters: [] });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                title: defaultTitle,
                subtitle: ''
              };
            });
            break;

          case 'detailed_feedback_counter':
            this.get('componentsCopy.data').addObject({ id: id, type: type, options: {}, filters: [] });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                title: _this5.get('i18n').t('digest.component.detailed_feedback_counter_title').toString(),
                subtitle: ''
              };
            });
            break;

          case 'top_progression':
            this.get('componentsCopy.data').addObject({ id: id, type: type, options: { segment: null }, filters: [] });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                title: defaultTitle,
                subtitle: ''
              };
            });
            break;

          case 'single_custom_metric':
            this.get('componentsCopy.data').addObject({ id: id, type: type, options: { kpi: null }, filters: [] });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                title: defaultTitle,
                subtitle: ''
              };
            });
            break;

          case 'tag_breakdown':
            this.get('componentsCopy.data').addObject({
              id: id,
              type: type,
              options: {
                max_list_size: 10,
                feedback_volume: true,
                breakdown_respondants: true,
                category_level: 0,
                display_parent: true,
                threshold: 1,
                tag_include: 'excluded',
                respondant_mode: 'volume',
                mention_mode: 'volume',
                feeling_mode: 'volume',
                order_by: 'count',
                score_mode: 'nps'
              },
              filters: []
            });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                title: defaultTitle,
                subtitle: '',
                tags_name: _this5.get('i18n').t('digest.component.tags_name').toString(),
                score_name: _this5.get('i18n').t('digest.component.score').toString(),
                score_variation_name: _this5.get('i18n').t('digest.component.variation').toString(),
                feedback_volume_name: _this5.get('i18n').t('digest.component.feedback_volume_default').toString(),
                volume_variation_name: _this5.get('i18n').t('digest.component.variation').toString(),
                respondants_name: _this5.get('i18n').t('digest.component.respondants_default').toString(),
                feelings_name: _this5.get('i18n').t('digest.component.feelings_default').toString()
              };
            });
            break;

          case 'tag_top_flop':
            this.get('componentsCopy.data').addObject({
              id: id,
              type: type,
              options: {
                max_list_size: 10,
                category_level: 0,
                threshold: 1,
                tag_include: 'excluded',
                order_by: 'variation',
                mention_mode: 'volume',
                display_parent: true,
                share_of_mention: true,
                feeling_mention: true,
                variation: true,
                longest_verbatim: false
              },
              filters: []
            });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                tag_column_name: _this5.get('i18n').t('digest.component.tag_default_column_name').toString(),
                positive_title: _this5.get('i18n').t('digest.component.positive_default_title').toString(),
                negative_title: _this5.get('i18n').t('digest.component.negative_default_title').toString(),
                share_column_name: _this5.get('i18n').t('digest.component.share_default_column_name').toString(),
                positive_mention_column_name: _this5.get('i18n').t('digest.component.positive_mention_default_column_name').toString(),
                negative_mention_column_name: _this5.get('i18n').t('digest.component.negative_mention_default_column_name').toString(),
                variation_column_name: _this5.get('i18n').t('digest.component.variation').toString()
              };
            });
            break;

          default:
            this.get('componentsCopy.data').addObject({ id: id, type: type, options: {}, filters: [] });
            Object.keys(translations).forEach(function (tag) {
              return translations[tag].components[id] = {
                title: defaultTitle,
                subtitle: ''
              };
            });
        }
        this.set('translationsCopy', translations);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDigest'), 2500));
      }
    }
  });
});