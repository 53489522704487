define('due-dashboard/components/due-loading-button', ['exports', 'ember', 'due-dashboard/utils/promise-object'], function (exports, _ember, _dueDashboardUtilsPromiseObject) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /* global _ */

  var Component = _ember['default'].Component;
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    classNames: ['due-button'],
    classNameBindings: ['customClass'],
    buttonClasses: [],

    loadingPromise: computed({
      get: function get() {
        this.get('_loadingPromiseObject.promise');
      },
      set: function set(_, promise) {
        this.set('_loadingPromiseObject', (0, _dueDashboardUtilsPromiseObject['default'])(promise));
      }
    }),

    actions: {
      onClick: function onClick() {
        var handler = this.get('on-click');

        if (typeof handler === 'function') {
          handler();
        }
      }
    },

    // private:
    _loadingPromiseObject: (0, _dueDashboardUtilsPromiseObject['default'])(RSVP.resolve()),

    _isLoading: computed.readOnly('_loadingPromiseObject.isPending'),
    _labelToDisplay: computed('text', '_isLoading', function () {
      var loading = this.get('_isLoading');

      return loading ? this.get('i18n').t('due_button.default.loading') : this.get('text');
    }),

    _classToDisplay: computed('_isLoading', 'buttonClasses', function () {
      var loading = this.get('_isLoading'),
          button_classes = this.get('buttonClasses'),
          classes = ['action'].concat(_toConsumableArray(button_classes));

      if (loading) {
        classes.push('loading');
      }
      return classes.join(' ');
    })
  });
});