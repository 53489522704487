define('due-dashboard/components/survey-line-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    tagName: 'li',
    currentAccount: service(),

    surveyActions: computed('i18n.locale', 'survey', 'survey.is_favorite', 'currentAccount', 'filterStatus', 'canModifySurvey', 'canChangeSurveyStatus', 'canCreateSurvey', 'canExportSurveyStatus', 'canDistributeSurvey', function () {
      var survey_actions = [];
      var survey = this.get('survey');

      if (this.get('canChangeSurveyStatus')) {
        if (this.get('filterStatus') === 'deleted' || this.get('filterStatus') === 'archived') {
          survey_actions.push({ icon: 'undo', action: 'restoreSurvey', textKey: 'surveys.restore' });
        }
      }
      if (this.get('filterStatus') !== 'deleted') {
        if (this.get('canDistributeSurvey') && this.get('filterStatus') !== 'deleted' && this.get('survey.creation_status') !== 'new') {
          survey_actions.push({ icon: 'send', action: 'publishSurvey', textKey: 'words.distribute' });
        }
        if (this.get('canModifySurvey') && !survey.get('is_new_survey')) {
          survey_actions.push({ icon: 'gears', action: 'editSurvey', textKey: 'surveys.edit' });
        }
        if (this.get('canCreateSurvey') && this.get('survey.creation_status') !== 'new' && !survey.get('is_new_survey')) {
          survey_actions.push({ icon: 'copy', action: 'duplicateSurvey', textKey: 'surveys.duplicate' });
        }
        survey_actions.push({ icon: 'clipboard', action: 'copySurveyId', textKey: 'surveys.copy_id' });
        if (this.get('canModifySurvey')) {
          survey_actions.push({ icon: 'star', action: 'makeFavorite', textKey: 'templates.edit.' + (this.get('survey.is_favorite') ? 'un' : '') + 'make_favorite' });
        }
        if (this.get('canExportSurveyStatus') && this.get('survey.creation_status') !== 'new') {
          survey_actions.push({ icon: 'filter', action: 'exportSurvey', textKey: 'surveys.show_in_export' });
        }
        if (this.get('canChangeSurveyStatus') && !survey.get('is_new_survey')) {
          if (this.get('filterStatus') !== 'archived') {
            survey_actions.push({ icon: 'archive', action: 'archiveSurvey', textKey: 'surveys.archive' });
          }
          survey_actions.push({ icon: 'trash', action: 'deleteSurvey', textKey: 'surveys.delete' });
        }
      }
      return survey_actions;
    }),

    idToCopy: computed('survey.id', function () {
      return 'survey-id-to-copy-' + this.get('survey.id');
    }),

    actions: {
      triggerAction: function triggerAction(action) {
        this.send(action);
      },

      archiveSurvey: function archiveSurvey() {
        this.get('updateSurveyStatus')(this.get('survey'), 'archived');
      },

      copySurveyId: function copySurveyId() {
        // can't copy it directly in clipboard
        // TODO: copy to clipboard component
        var $copyText = document.getElementById(this.get('idToCopy'));
        $copyText.select();
        document.execCommand("Copy");
      },

      restoreSurvey: function restoreSurvey() {
        this.get('updateSurveyStatus')(this.get('survey'), 'live');
      },

      deleteSurvey: function deleteSurvey() {
        if (window.confirm(this.get('i18n').t('surveys.confirm_delete', { survey: this.get('survey.name') }).string)) {
          this.get('updateSurveyStatus')(this.get('survey'), 'deleted');
        }
      },

      duplicateSurvey: function duplicateSurvey() {
        this.get('duplicateSurvey')(this.get('survey'));
      },

      makeFavorite: function makeFavorite() {
        this.toggleProperty('survey.is_favorite');
        this.get('survey').save();
      },

      editSurvey: function editSurvey() {
        getOwner(this).lookup('controller:application').transitionToRoute('companies.surveys.edit.main', this.get('survey.id'));
      },

      publishSurvey: function publishSurvey() {
        getOwner(this).lookup('controller:application').transitionToRoute('companies.surveys.edit.publishing', this.get('survey.id'));
      },

      exportSurvey: function exportSurvey() {
        getOwner(this).lookup('controller:application').transitionToRoute('companies.exports', { queryParams: { selectedSurvey: this.get('survey.id') } });
      },

      downloadSurvey: function downloadSurvey() {
        getOwner(this).lookup('controller:companies.exports').send('exportFromExternalController', this.get('survey.id'));
      }
    }
  });
});