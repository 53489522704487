define('due-dashboard/components/digests/parts/tag-filter-part', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  exports['default'] = Component.extend({
    tagManager: _ember['default'].inject.service(),

    compTag: computed.alias('tagList'),
    filter: {},
    filters: {},
    tagsFilterValue: '',
    allTags: [],
    searchTags: [],
    showTagDropDown: false,
    filterDropdownElement: null,
    remainingTags: computed.setDiff('allTags', 'tagFilter'),

    init: function init() {
      var _this = this;

      this.get('tagManager').getAll().then(function (tags) {
        _this.set('allTags', tags);
        _this.set('searchTags', tags);
      });
      if (!this.get('compTag')) {
        this.set('compTag', []);
      }
      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      this.set('filterDropdownElement', $(this.parentView.element).find('.filter-parent' + this.get('compId')));
    },

    taggedOptions: ['both', 'tagged', 'not_tagged'],

    tagFilter: computed('filter.valueTag', 'allTags', {
      get: function get() {
        var tags = this.get('compTag');
        return this.get('allTags').toArray().filter(function (a) {
          return tags.includes(a.id);
        });
      },
      set: function set(_, filter) {
        this.set('compTag', filter.mapBy('id'));
        this.get('saveDigestDelay')();
        return filter;
      }
    }),

    autoTagsRights: computed.alias('currentAccount.canUseAutoTags'),

    _cleanTagsDisplay: function _cleanTagsDisplay() {
      this.set('tagsFilterValue', '');
      this._searchTags('');
    },

    _searchTags: function _searchTags(term) {
      var _this2 = this;

      this.set('showLoadingLine', 'show-loading-li');
      this.get('tagManager').getAll({ name: term }).then(function (tags) {
        if (!_this2.get('isDestroyed')) {
          _this2.set('searchTags', tags);
          _this2.set('showLoadingLine', '');
        }
      });
    },

    actions: {
      searchTags: function searchTags(term) {
        this._searchTags(term);
      },

      deleteFilter: function deleteFilter() {
        this.set('tagFilter', []);
        this.set('filters.tagged', 'both');
        this.get('deleteFilter')(this.get('filter'));
      },

      triggerTagDropDown: function triggerTagDropDown() {
        this.toggleProperty('showTagDropDown');
        if (this.get('showTagDropDown')) {
          this.get('filterDropdownElement').find('.tag-field')[0].classList.remove('hide-class');
        } else {
          this.get('filterDropdownElement').find('.tag-field')[0].classList.add('hide-class');
        }
      },

      clickOutsideTagsFilter: function clickOutsideTagsFilter() {
        this._cleanTagsDisplay();
        this.set('showTagDropDown', false);
        this.get('filterDropdownElement').find('.tag-field')[0].classList.add('hide-class');
      },

      removeTagAction: function removeTagAction(tag) {
        var temptag = this.get('tagFilter');
        temptag.removeObject(tag);
        this.set('tagFilter', temptag);
      },

      selectTagAction: function selectTagAction(tag) {
        this.toggleProperty('showTagDropDown');
        var temptag = this.get('tagFilter');
        temptag.pushObject(tag);
        this.set('tagFilter', temptag);
      },

      updateAllTags: function updateAllTags(term) {
        if (!this.get('showTagDropDown')) {
          this.set('showTagDropDown', true);
          this.get('filterDropdownElement').find('.tag-field')[0].classList.remove('hide-class');
        }
        run.debounce(this, this._searchTags, term, 500);
      },

      closeTagsDropDown: function closeTagsDropDown() {
        this._cleanTagsDisplay();
      }

    }

  });
});