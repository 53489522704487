define('due-dashboard/models/web-snippet', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({

    // feedbacks_filters
    survey: belongsTo('survey'),
    question_category: belongsTo('question-category'),
    segment_filter: belongsTo('segment'),

    // to_display
    display_comment: attr('boolean'),
    display_date: attr('boolean'),
    display_global_note: attr('boolean', { defaultValue: true }),
    display_list: attr('boolean', { defaultValue: true }),
    note_display_type: attr('string', { defaultValue: 'stars' }),
    segment_to_display: belongsTo('segment'),

    // customization
    //  colors
    text_color: attr('string', { defaultValue: '#364854' }),
    background_color: attr('string', { defaultValue: 'rgba(0, 0, 0, 0)' }),
    font_size: attr('string', { defaultValue: null }),
    font_name: attr('string', { defaultValue: null }),
    title_color: attr('string', { defaultValue: '#3c3c3c' }),
    subtitle_color: attr('string', { defaultValue: '#aaaaaa' }),
    star_color0: attr('string', { defaultValue: '#ee6352' }),
    star_color1: attr('string', { defaultValue: '#ee6352' }),
    star_color2: attr('string', { defaultValue: '#f7b267' }),
    star_color3: attr('string', { defaultValue: '#58bfeb' }),
    star_color4: attr('string', { defaultValue: '#23afcb' }),
    star_color5: attr('string', { defaultValue: '#2fcc76' }),
    max_per_page: attr('string', { defaultValue: 5 }),
    is_vertical: attr('boolean', { defaultValue: false }),
    with_comments_only: attr('boolean', { defaultValue: true }),

    // other
    name: attr('string'),
    views_count: attr('number', { readOnly: true }),
    unique_views_count: attr('number', { readOnly: true }),

    snippet_type_icon: computed('snippet_type', function () {
      return ({
        'feedback_list': 'fa-list',
        'global_rating': 'fa-star-half-o'
      })[this.get('snippet_type')];
    }),

    font_size_px: computed('font_size', {
      get: function get() {
        var font_size = this.get('font_size');
        return font_size ? this.get('font_size').split('px')[0] : null;
      }, set: function set(_, value) {
        this.set('font_size', value && value + 'px');
        return value;
      }
    })

  });
});