define('due-dashboard/models/account', ['exports', 'ember-data', 'ember-cli-copyable'], function (exports, _emberData, _emberCliCopyable) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend(_emberCliCopyable['default'], /*EmberValidations.Mixin,*/{
    company: belongsTo('Company'),
    // role: belongsTo('Role', {async: true}),

    favorite_graph: belongsTo('Graph', { async: true }),
    language: belongsTo('Language', { async: true }),
    account_custom_profile_right: belongsTo('AccountCustomProfileRight'),
    email: attr('string'),
    timezone_id: attr('string'),
    firstname: attr('string', { defaultValue: '' }),
    lastname: attr('string', { defaultValue: '' }),
    phone_number: attr('string', { defaultValue: '' }),
    current_password: attr('string', { defaultValue: '' }),
    password: attr('string', { defaultValue: '' }),
    password_confirmation: attr('string', { defaultValue: '' }),
    survey_popin: attr('string'),
    profile: attr('string'),
    geoscope_name: attr('string'),
    zone: attr('string', { defaultValue: 'Europe' }),
    last_connection: attr('date', { readOnly: true }),
    is_limited: attr('boolean'),
    saml_enabled: attr('boolean'),
    timezone_in_dashboard: attr('boolean', { readOnly: true }),
    want_digest: attr('boolean', { defaultValue: true }),
    segments: attr('object'),
    created_at: attr('date'),
    custom_stats_digest_subscriptions: attr()
  });
});