define("due-dashboard/templates/settings/integrations/integration-parent", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 14,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/settings/integrations/integration-parent.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "integration-action-bar", [], ["disable_save", ["subexpr", "@mut", [["get", "disable_save", ["loc", [null, [5, 19], [5, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model.company_integration", ["loc", [null, [6, 12], [6, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "canToggle", ["subexpr", "@mut", [["get", "model.company_integration.completed", ["loc", [null, [7, 16], [7, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "hideSave", ["subexpr", "@mut", [["get", "hideSave", ["loc", [null, [8, 15], [8, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "promise", ["subexpr", "@mut", [["get", "promise", ["loc", [null, [9, 14], [9, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "toggleActive", ["subexpr", "action", ["toggleActive"], [], ["loc", [null, [10, 19], [10, 42]]], 0, 0], "deleteIntegration", ["subexpr", "action", ["deleteIntegration"], [], ["loc", [null, [11, 24], [11, 52]]], 0, 0], "saveIntegration", ["subexpr", "action", ["saveIntegration"], [], ["loc", [null, [12, 22], [12, 48]]], 0, 0]], ["loc", [null, [4, 4], [13, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/integration-parent.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["content", "outlet", ["loc", [null, [2, 2], [2, 12]]], 0, 0, 0, 0], ["block", "if", [["get", "model.company_integration.id", ["loc", [null, [3, 8], [3, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [14, 9]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 21
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/integration-parent.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "integration-item", [], ["integration", ["subexpr", "@mut", [["get", "model.integration", ["loc", [null, [1, 32], [1, 49]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [15, 21]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});