define('due-dashboard/models/has-segment-scenario-condition', ['exports', 'due-dashboard/models/scenario-condition', 'ember-data', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioCondition, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  var Validations = (0, _emberCpValidations.buildValidations)({
    segment: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _dueDashboardModelsScenarioCondition['default'].extend(Validations, {
    segment: belongsTo('segment', { async: true }),
    operator: attr('string')
  });
});