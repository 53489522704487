define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/components/-text', ['exports', 'ember', 'due-dashboard/helpers/color-formating'], function (exports, _ember, _dueDashboardHelpersColorFormating) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['setting-element-container', 'element-component-container', 'text'],

    DEFAULT_VALUE: '',
    DEFAULT_COLOR: '#000000',
    DEFAULT_BG_COLOR: '#FFFFFF',
    DEFAULT_BOX_SHADOW: ['0px', '0px', '0px', '0px', '#FFFFFF'],
    UNITIES: ['px', 'em', 'ch', '%'],
    DEFAULT_UNITY: 'px',
    FONT_WEIGHTS: [{ text: 'words.normal', value: 'normal' }, { text: 'words.300', value: '300' }, { text: 'words.400', value: '400' }, { text: 'words.500', value: '500' }, { text: 'words.600', value: '600' }, { text: 'words.700', value: '700' }, { text: 'words.800', value: '800' }],
    FALLBACK_FONTS: [{ name: 'Arial, sans-serif', value: 'Arial, sans-serif' }, { name: 'Inter, sans-serif', value: 'Inter, sans-serif' }, { name: 'Time New Roman, serif', value: 'Time New Roman, serif' }],
    TEXT_DECORATIONS: [{ text: 'no', value: 'none' }, { text: 'underline', value: 'underline' }],
    displayFontFamily: true,
    displayColor: true,
    displayFontSize: true,
    displayFontWeight: true,
    displayLetterSpacing: true,
    displayLineHeight: true,
    displayTextDecoration: true,

    suffixOptions: computed(function () {
      return this.UNITIES.reduce(function (a, v) {
        a.push({ value: v });return a;
      }, []);
    }),

    currentDesignOptions: computed('styleName', 'customFonts', 'designOptions', function () {
      var styleName = 'designOptions.' + this.get('styleName');
      return {
        color: this.get(styleName + '_color') || this.DEFAULT_COLOR,
        fontSize: this._extractValue(styleName + '_font_size'),
        fontSizeSuffix: this._extractSuffix(styleName + '_font_size'),
        fontFamily: this._extractOption(styleName + '_font_family', 'customFonts'),
        letterSpacing: this._extractValue(styleName + '_letter_spacing'),
        letterSpacingSuffix: this._extractSuffix(styleName + '_letter_spacing'),
        lineHeight: this._extractValue(styleName + '_line_height'),
        lineHeightSuffix: "px",
        fontWeight: this._extractOption(styleName + '_font_weight', 'FONT_WEIGHTS'),
        textDecoration: this._extractOption(styleName + '_text_decoration', 'TEXT_DECORATIONS')
      };
    }),

    _extractValue: function _extractValue(designOptionName) {
      var defaultValue = arguments.length <= 1 || arguments[1] === undefined ? '0' : arguments[1];

      var value = this.get(designOptionName);
      if (value == undefined) return defaultValue;
      var valueRegexp = /[0-9\.]/g;
      var number_value = value.match(valueRegexp);
      return number_value ? number_value.join('') : defaultValue;
    },

    _extractSuffix: function _extractSuffix(designOptionName) {
      var defaultValue = arguments.length <= 1 || arguments[1] === undefined ? 'px' : arguments[1];

      var value = this.get(designOptionName);
      if (value == undefined) return defaultValue;
      var unityRegexp = /[a-z\%]/g;
      var unityDetect = value.match(unityRegexp);
      if (unityDetect == undefined) return defaultValue;
      var unity = unityDetect.join('');
      return this.UNITIES.includes(unity) ? unity : defaultValue;
    },

    _extractOption: function _extractOption(designOptionName, optionsName) {
      var options = this.get(optionsName);
      if (options == undefined) return;
      var designOptions = this.get(designOptionName);
      return options.find(function (obj) {
        return obj.value === designOptions;
      }) || options[0];
    },

    actions: {
      setUnity: function setUnity(designOption, frontElement, unity) {
        this.set('currentDesignOptions.' + frontElement + 'Suffix', unity.value);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, this.get('currentDesignOptions.' + frontElement) + unity.value));
      },

      setValueWithUnity: function setValueWithUnity(designOption, frontElement, value) {
        this.set('currentDesignOptions.' + frontElement, value);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, value + this.get('currentDesignOptions.' + frontElement + 'Suffix')));
      },

      setValue: function setValue(frontElement, value) {
        if (frontElement.toLowerCase().includes('color')) value = _dueDashboardHelpersColorFormating['default'].compute(value);
        this.set('currentDesignOptions.' + frontElement, value);
        this.sendAction('updateDesignOptions', _defineProperty({}, frontElement, value));
      },

      selectDropdownValue: function selectDropdownValue(designOption, frontElement, selectedElement) {
        this.set('currentDesignOptions.' + frontElement, selectedElement);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, selectedElement ? selectedElement.value : selectedElement));
      }
    }
  });
});