define('due-dashboard/components/survey-picker', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({
    classNames: ['survey-picker-container'],
    value: null,
    survey_filter_value: '',
    survey_filters: [],
    allSurveys: [],
    remainingSurveys: computed.setDiff('allSurveys', 'surveyFilter'),
    remainingFilteredSurveys: computed.setDiff('filteredSurveys', 'surveyFilter'),
    inputValue: '',

    init: function init() {
      var _this = this;

      var promise = this.store.query('survey', { filter: { status: 'live' }, page: { number: 1, size: 60 } });
      this.set('promise', promise);
      promise.then(function (surveys) {
        return _this.set('allSurveys', surveys);
      });
      this.set('survey_filters', this.get('value').toArray());
      if (!this.get('survey_filter_value')) {
        this.set('survey_filter_value', this.get('survey_filters').mapBy('id'));
      }
      this.set('dropdownInputProps', {
        'class': 'sp-input',
        placeholder: this.get('i18n').t('global.choose_surveys')
      });
      this._super.apply(this, arguments);
    },

    availableSurveys: computed('inputValue', 'remainingSurveys', 'remainingFilteredSurveys', function () {
      return this.get('inputValue.length') === 0 ? this.get('remainingSurveys') : this.get('remainingFilteredSurveys');
    }),

    surveyFilter: computed('survey_filter_value', 'allSurveys', function () {
      var surveys = this.get('survey_filter_value');
      this.set('survey_filters', this.get('allSurveys').toArray().filter(function (a) {
        return surveys.includes(a.id);
      }));
      return this.get('allSurveys').toArray().filter(function (a) {
        return surveys.includes(a.id);
      });
    }),

    _searchSurvey: function _searchSurvey(term) {
      var _this2 = this;

      this.set('inputValue', term);
      var promise = this.store.query('survey', {
        filter: { name: term, status: 'live' },
        page: { number: 1, size: 20 }
      });
      this.set('promise', promise);
      promise.then(function (surveys) {
        _this2.set('filteredSurveys', surveys);
      });
    },

    actions: {
      searchSurveys: function searchSurveys(term) {
        run.debounce(this, this._searchSurvey, term, 600);
      },

      selectSurvey: function selectSurvey(survey) {
        this.set('inputValue', '');

        var surveyFilterValue = this.get('survey_filter_value');
        surveyFilterValue.push(survey.get('id'));
        this.set('survey_filter_value', surveyFilterValue);

        var surveyFilters = this.get('survey_filters');
        surveyFilters.push(survey);
        this.set('survey_filters', surveyFilters);

        this.set('value', surveyFilters);
        this.notifyPropertyChange('survey_filter_value');
      },

      deleteSurvey: function deleteSurvey(survey) {
        this.set('survey_filter_value', this.get('survey_filter_value').filter(function (v) {
          return v !== survey.get('id');
        }));
        var surveyFilters = this.get('survey_filters').filter(function (s) {
          return s.get('id') !== survey.get('id');
        });
        this.set('survey_filters', surveyFilters);
        this.set('value', surveyFilters);
        this.notifyPropertyChange('survey_filter_value');
      }
    }
  });
});