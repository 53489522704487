define('due-dashboard/components/digests/parts/score-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    classNames: ['score-part'],

    init: function init() {
      if (!this.get('scoreOptions')) {
        this.set('scoreOptions', ['nps', 'avg', 'avg_pos']);
      }
      if (!this.get('comp.options.score_type')) {
        this.set('comp.options.score_type', this.get('scoreOptions')[0]);
      }
      this._super.apply(this, arguments);
    },

    actions: {

      changeScore: function changeScore(score) {
        this.set('comp.options.score_type', score);
        this.get('saveDigestDelay')();
      }
    }
  });
});