define('due-dashboard/components/dashboard-filters/tags', ['exports', 'ember', 'due-dashboard/helpers/tag-display-name'], function (exports, _ember, _dueDashboardHelpersTagDisplayName) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Component.extend({
    tagManager: _ember['default'].inject.service('tag-manager'),

    init: function init() {
      this._super.apply(this, arguments);
      this._searchTags();
    },

    /* Private props */
    taggedOptions: ['both', 'tagged', 'not_tagged'],
    foundTags: [],
    currentLanguage: computed.readOnly('currentAccount.content.language.tag'),
    value: computed.alias('filter.value'),
    tagsSentiments: computed.readOnly('value.tags_sentiments'),
    triggerDropDownReset: false,
    flatLevelDisplay: false,

    /* Defined due input props */
    dueInputProps: computed('value.included', function () {
      var is_tagged = this.get('value.included') === 'tagged',
          i18n = this.get('i18n'),
          prefix = 'sidebar.filters.tagged';

      return {
        'class': 'input-tag-text',
        label: i18n.t(is_tagged ? prefix + '.title' : prefix + '.not_tagged_title'),
        placeholder: i18n.t(is_tagged ? prefix + '.placeholder' : prefix + '.not_tagged_placeholder')
      };
    }).readOnly(),

    enabledTagSentiments: computed('tagsSentiments', function () {
      var _this = this;

      var selected = this.get('tagsSentiments');

      return Object.entries(selected).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var id = _ref2[0];
        var feelings = _ref2[1];

        var tag = _this.get('store').peekOrFind('tag', id);

        return { tag: tag, feelings: feelings };
      });
    }).readOnly(),

    remainingList: computed('foundTags.[]', 'tagsSentiments', 'currentLanguage', function () {
      var found = this.get('foundTags'),
          selected = this.get('tagsSentiments'),
          language = this.get('currentLanguage');

      return found.map(function (tag) {
        return {
          id: tag.id,
          title: _dueDashboardHelpersTagDisplayName['default'].compute(tag, language),
          disabled: tag.id in selected,
          tag: tag,
          feeling_enabled: tag.feeling_enabled
        };
      });
    }).readOnly(),

    remainingTags: computed('remainingList.[]', 'flatLevelDisplay', function () {
      var list = this.get('remainingList');

      if (this.get('flatLevelDisplay') === false) {
        return this._computeTagHierarchy(list);
      } else {
        return list;
      }
    }).readOnly(),

    // private:

    _computeChildren: function _computeChildren(element, remaining) {
      var _this2 = this;

      var feeling_enabled = element.tag.get('feeling_enabled'),
          children = _.remove(remaining, function (other) {
        return other.depth === element.depth + 1 && _.every(element.components, function (c, i) {
          return c === other.components[i];
        });
      }).map(function (child) {
        return _this2._computeChildren(child, remaining);
      });

      element.feeling_enabled = feeling_enabled;
      if (children.length > 0 || feeling_enabled) {
        element.disabled = false;
      }
      if (children.length > 0) {
        var general = {
          id: element.id,
          title: this.get('i18n').t('feedback_list.head.titles.all').string,
          name: 'general', type: 'general', must_be_first: true,
          feeling_enabled: feeling_enabled || element.tag.get('not_assignable')
        };

        children.unshiftObject(general);
        element.options = children;
      }
      return element;
    },

    _computeTagHierarchy: function _computeTagHierarchy(list) {
      var _this3 = this;

      var remaining = list.map(function (element) {
        var components = element.tag.get('name').split(' > '),
            depth = components.length - 1,
            titleComponents = element.title.split(' > '),
            title = titleComponents[depth];

        return _extends({}, element, { title: title, components: components, depth: depth });
      });
      var roots = _.remove(remaining, function (element) {
        return element.depth === 0;
      });

      return roots.map(function (element) {
        return _this3._computeChildren(element, remaining);
      });
    },

    _searchTags: function _searchTags() {
      var _this4 = this;

      var query = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var foundTags = this.get('tagManager').getAll(_extends({
        dropdown_list_restrictions: JSON.stringify(['hidden', 'none']),
        cr_tag_survey_limited: true
      }, query));

      foundTags.then(function (tags) {
        if (!_this4.get('isDestroyed')) {
          _this4.set('foundTags', tags);
        }
      });
      return foundTags;
    },

    _searchTagsNamed: function _searchTagsNamed(term) {
      var _this5 = this;

      var condition = term !== '';
      this._searchTags({ name: term, not_assignable: !condition }).then(function () {
        _this5.set('flatLevelDisplay', condition);
      });
    },

    _addTagSentiment: function _addTagSentiment(tag, feeling) {
      if (feeling === undefined) {
        feeling = 'allfeedback';
      }
      var value_copy = _extends({}, this.get('value')),
          ts = value_copy.tags_sentiments;

      if (!(tag.id in ts)) {
        ts[tag.id] = [];
      }
      ts[tag.id].addObject(feeling);
      this.set('value', value_copy);
    },

    _removeTagSentiment: function _removeTagSentiment(tag, feeling) {
      var value_copy = _extends({}, this.get('value')),
          ts = value_copy.tags_sentiments;

      if (tag.id in ts) {
        var feelings = ts[tag.id];

        feelings.removeObject(feeling);
        if (feelings.length === 0) {
          delete ts[tag.id];
        }
        this.set('value', value_copy);
      }
    },

    actions: {
      addTag: function addTag(tag, feeling) {
        this._addTagSentiment(tag, feeling);
      },

      deleteTag: function deleteTag(tag, feeling) {
        this._removeTagSentiment(tag, feeling);
      },

      changeIncluded: function changeIncluded(included) {
        var value_copy = _extends({}, this.get('value'));

        value_copy.included = included;
        this.set('value', value_copy);
      },

      searchTags: function searchTags(term) {
        debounce(this, this._searchTagsNamed, term, 600);
      },

      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});