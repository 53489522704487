define('due-dashboard/components/reports/array/-tag-line', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: [''],

    showSubLines: false,

    sortedLines: computed('line', function () {
      return this.get('line') && this.get('line')[0].hasOwnProperty('subLines') && this.get('line')[0].subLines;
    }),

    hasSubLines: computed('line', function () {
      return this.get('line') && this.get('line')[0].hasOwnProperty('subLines') && this.get('line')[0].subLines && this.get('line')[0].subLines.length > 0;
    }),

    emptyPage: computed('', function () {
      return {
        left: [{ type: 'label-with-sub', component: 'text', width: 50 }],
        right: [{ type: 'label-with-sub', component: 'text', width: 60 }, { type: 'label-with-sub', component: 'text', width: 60 }, { type: 'label-with-sub', component: 'text', width: 60 }]
      };
    }),

    computedLine: computed(function () {
      var line = this.get('line'),
          computedLine = { left: [], right: [] };

      if (!line) {
        return [];
      }
      line.forEach(function (elem, idx) {
        if (idx === 0 || elem.main_column) {
          computedLine.left.push(elem);
        } else {
          computedLine.right.push(elem);
        }
      });

      return computedLine;
    }),

    compPadding: computed('depth', function () {
      var padding = this.get('depth') * 16;

      if (!this.get('hasSubLines') && this.get('depth') > 0) {
        padding += 16;
      }

      return padding;
    }),

    actions: {
      toggleSubLines: function toggleSubLines() {
        this.toggleProperty('showSubLines');
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{reports/array/-selected-item}} selected pagination component used by {{reports/-array}}
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */