define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/components/-background', ['exports', 'ember', 'due-dashboard/helpers/color-formating'], function (exports, _ember, _dueDashboardHelpersColorFormating) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['setting-element-container', 'element-component-container', 'background'],

    DEFAULT_VALUE: '',
    DEFAULT_COLOR: '#000000',
    DEFAULT_BG_COLOR: '#FFFFFF',
    DEFAULT_BOX_SHADOW: ['0px', '0px', '0px', '0px', '#FFFFFF'],
    UNITIES: ['px', 'em', 'ch', '%'],
    DEFAULT_UNITY: 'px',
    displayBackgroundColor: true,
    displayBorderRadius: true,
    displayBorderWidth: true,
    displayBorderColor: true,
    displayBoxShadow: true,

    suffixOptions: computed(function () {
      return this.UNITIES.reduce(function (a, v) {
        a.push({ value: v });return a;
      }, []);
    }),

    currentDesignOptions: computed('styleName', 'designOptions', function () {
      var styleName = 'designOptions.' + this.get('styleName');
      return {
        backgroundColor: this.get(styleName + '_bg_color') || this.DEFAULT_BG_COLOR,
        borderRadius: this._extractValue(styleName + '_border_radius'),
        borderRadiusSuffix: this._extractSuffix(styleName + '_border_radius'),
        borderWidth: this._extractValue(styleName + '_border_width'),
        borderWidthSuffix: this._extractSuffix(styleName + '_border_width'),
        borderColor: this.get(styleName + '_border_color') || this.DEFAULT_COLOR
      };
    }),

    _extractValue: function _extractValue(designOptionName) {
      var defaultValue = arguments.length <= 1 || arguments[1] === undefined ? '0' : arguments[1];

      var value = this.get(designOptionName);
      if (value == undefined) return defaultValue;
      var valueRegexp = /[0-9\.]/g;
      var number_value = value.match(valueRegexp);
      return number_value ? number_value.join('') : defaultValue;
    },

    _extractSuffix: function _extractSuffix(designOptionName) {
      var defaultValue = arguments.length <= 1 || arguments[1] === undefined ? 'px' : arguments[1];

      var value = this.get(designOptionName);
      if (value == undefined) return defaultValue;
      var unityRegexp = /[a-z\%]/g;
      var unityDetect = value.match(unityRegexp);
      if (unityDetect == undefined) return defaultValue;
      var unity = unityDetect.join('');
      return this.UNITIES.includes(unity) ? unity : defaultValue;
    },

    _extractOption: function _extractOption(designOptionName, optionsName) {
      var options = this.get(optionsName);
      if (options == undefined) return;
      var designOptionsValue = this.get(designOptionName);
      return options.find(function (obj) {
        return obj.value === designOptionsValue;
      });
    },

    actions: {
      setUnity: function setUnity(designOption, frontElement, unity) {
        this.set('currentDesignOptions.' + frontElement + 'Suffix', unity.value);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, this.get('currentDesignOptions.' + frontElement) + unity.value));
      },

      setValueWithUnity: function setValueWithUnity(designOption, frontElement, value) {
        this.set('currentDesignOptions.' + frontElement, value);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, value + this.get('currentDesignOptions.' + frontElement + 'Suffix')));
      },

      setValue: function setValue(frontElement, value) {
        if (frontElement.toLowerCase().includes('color')) value = _dueDashboardHelpersColorFormating['default'].compute(value);
        this.set('currentDesignOptions.' + frontElement, value);
        this.sendAction('updateDesignOptions', _defineProperty({}, frontElement, value));
      }
    }
  });
});