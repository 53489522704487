define('due-dashboard/components/segments-wrapper', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    errors: service(),
    allSegments: [],
    listSwap: null,

    whitelistedInChat: computed('companies_segments_handler.whitelist_chat_segments', function () {
      return this.getList("whitelist_chat_segments");
    }),

    whitelistedInVerbatim: computed('companies_segments_handler.whitelist_verbatim_segments', function () {
      return this.getList("whitelist_verbatim_segments");
    }),

    whitelistedInAlert: computed('companies_segments_handler.whitelist_alert_segments', function () {
      return this.getList("whitelist_alert_segments");
    }),

    whitelistedInFilter: computed('companies_segments_handler.whitelist_filter_segments', function () {
      return this.getList("whitelist_filter_segments");
    }),

    whitelistedInExport: computed('companies_segments_handler.whitelist_export_segments', function () {
      return this.getList("whitelist_export_segments");
    }),

    blacklistedInChat: computed('companies_segments_handler.blacklist_chat_segments', function () {
      return this.getList("blacklist_chat_segments");
    }),

    blacklistedInVerbatim: computed('companies_segments_handler.blacklist_verbatim_segments', function () {
      return this.getList("blacklist_verbatim_segments");
    }),

    blacklistedInAlert: computed('companies_segments_handler.blacklist_alert_segments', function () {
      return this.getList("blacklist_alert_segments");
    }),

    blacklistedInFilter: computed('companies_segments_handler.blacklist_filter_segments', function () {
      return this.getList("blacklist_filter_segments");
    }),

    blacklistedInExport: computed('companies_segments_handler.blacklist_export_segments', function () {
      return this.getList("blacklist_export_segments");
    }),

    getList: function getList(list) {
      if (!this.get('companies_segments_handler.' + list)) {
        return;
      }
      return JSON.parse(this.get('companies_segments_handler.' + list));
    },

    init: function init() {
      var _this = this;

      this.get('store').find('companies_segments_handler', this.get('currentAccount.content.company.id')).then(function (companies_segments_handler) {
        _this.set('companies_segments_handler', companies_segments_handler);
      });
      this.get('store').query('segment', {}).then(function (segments) {
        _this.set('allSegments', segments);
      });
      this.set("model.needSaving", false);
      this._super.apply(this, arguments);
    },

    actions: {
      saveDraft: function saveDraft() {
        var _this2 = this;

        ["Chat", "Verbatim", "Filter", "Export", "Alert"].forEach(function (type) {
          _this2.set('companies_segments_handler.whitelist_' + type.toLowerCase() + '_segments', JSON.stringify(_this2.get('whitelistedIn' + type)));
          _this2.set('companies_segments_handler.blacklist_' + type.toLowerCase() + '_segments', JSON.stringify(_this2.get('blacklistedIn' + type)));
        });
        this.set('savePromise', this.get('companies_segments_handler').save().then(function () {
          return _this2.set('model.isSaving', false);
        }));
        this.set("model.needSaving", false);
      }
    }
  });
});