define('due-dashboard/components/send-survey-scenario-action', ['exports', 'ember', 'due-dashboard/components/scenario-action-item'], function (exports, _ember, _dueDashboardComponentsScenarioActionItem) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var and = computed.and;
  exports['default'] = _dueDashboardComponentsScenarioActionItem['default'].extend({
    surveys: [],
    templates: [],

    surveyOptions: ['same', 'custom'],
    templateOptions: ['same', 'custom'],
    recipientOptions: ['same', 'custom'],

    surveyOption: 'same',
    templateOption: 'same',
    recipientOption: 'same',

    error_keys: _ember['default'].computed(function () {
      return ['action.validations.attrs.message.error'];
    }),

    emptySurvey: computed('action.survey.id', 'surveyOption', function () {
      return this.get('surveyOption') === 'custom' && !this.get('action.survey.id');
    }),

    emptyTemplate: computed('action.template.id', 'templateOption', function () {
      return this.get('templateOption') === 'custom' && !this.get('action.template.id');
    }),

    emptyRecipient: computed('action.segment_name', 'recipientOption', function () {
      return this.get('recipientOption') === 'custom' && (this.get('action.segment_name.length') === undefined || this.get('action.segment_name.length') === 0);
    }),

    noSurveyError: and('emptySurvey', 'display_error'),
    noTemplateError: and('emptyTemplate', 'display_error'),
    noRecipientError: and('emptyRecipient', 'display_error'),

    errorObserver: _ember['default'].observer('try_step', function () {
      this.set('display_error', true);
    }),

    errorObserverVars: {
      single_error_target: 'action.validations.attrs.message.error'
    },

    sendingTypeObserver: observer('action.sending_type', function () {
      var _this = this;

      this._searchTemplates('', function (templates) {
        _this.set('templates', templates);
        _this.set('action.template', null);
      });
    }),

    _searchSurveys: function _searchSurveys(term, resolve, reject) {
      this.store.query('survey', this.query_filter(term)).then(function (surveys) {
        return resolve(surveys);
      }, reject);
    },

    _searchTemplates: function _searchTemplates(term, resolve, reject) {
      this.store.query('template', this.query_filter_template(term)).then(function (templates) {
        return resolve(templates);
      }, reject);
    },

    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      if (this.get('action.survey.id') !== undefined) {
        this.set('surveyOption', 'custom');
        this.get('action').set('requireSurvey', true);
      }
      if (this.get('action.template.id') !== undefined) {
        this.set('templateOption', 'custom');
        this.get('action').set('requireTemplate', true);
      }
      if (this.get('action.segment_name.length') !== undefined && this.get('action.segment_name.length') >= 1) {
        this.set('recipientOption', 'custom');
        this.get('action').set('requireSegmentName', true);
      }
      this._searchSurveys('', function (surveys) {
        _this2.set('surveys', surveys);
      });
      if (this.get('action.sending_type')) {
        this._searchTemplates('', function (templates) {
          _this2.set('templates', templates);
        });
      }
    },

    query_filter_template: function query_filter_template() {
      var term = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

      return { filter: { name: term, type: this.get('action.sending_type') } };
    },

    query_filter: function query_filter() {
      var term = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

      return {
        filter: {
          name: term,
          status: 'live',
          survey_type: 'diduenjoy'
        }
      };
    },

    actions: {
      setSurveyOption: function setSurveyOption(option) {
        this.set('surveyOption', option);
        if (option === 'same') {
          this.get('action').set('survey', null);
          this.get('action').set('requireSurvey', false);
        } else {
          this.get('action').set('requireSurvey', true);
        }
      },

      setTemplateOption: function setTemplateOption(option) {
        this.set('templateOption', option);
        if (option === 'same') {
          this.get('action').set('template', null);
          this.get('action').set('requireTemplate', false);
        } else {
          this.get('action').set('requireTemplate', true);
        }
      },

      setRecipientOption: function setRecipientOption(option) {
        this.set('recipientOption', option);
        if (option === 'same') {
          this.get('action').set('segment_name', null);
          this.get('action').set('requireSegmentName', false);
        } else {
          this.get('action').set('requireSegmentName', true);
        }
      },

      searchSurveys: function searchSurveys(term) {
        var _this3 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this3, _this3._searchSurveys, term, resolve, reject, 600);
        });
      },

      searchTemplates: function searchTemplates(term) {
        var _this4 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this4, _this4._searchTemplates, term, resolve, reject, 600);
        });
      }
    }

  });
});