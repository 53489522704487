define('due-dashboard/components/reports/popups/questions-list/show-item', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    tagName: 'div',
    classNames: ['verbatim-container'],
    translationKey: 'see',
    originalTextDisplay: false,
    showTranslation: false,

    currentLanguage: computed.alias('currentAccount.content.language.tag'),

    email: computed('verbatim.email', function () {
      return this.get('verbatim.email');
    }),

    fromTrustpilot: computed('verbatim.rating.rating_scale.rating_scale_type', function () {
      return this.get('verbatim.rating.rating_scale.rating_scale_type') == "trustpilot_review" ? true : false;
    }),

    profile: computed('verbatim.rating', function () {
      // const rating = this.get('verbatim.rating');

      return 'positive';
    }),

    displayText: computed('text', function () {
      return this.get('verbatim.text') ? this.get('verbatim.text') : this.get('verbatim.comment');
    }),

    isTranslation: computed('verbatim', 'i18n.locale', 'language', 'verbatimTranslation', function () {
      if (!this.get('verbatimTranslation')) {
        return false;
      } else if (this.get('language') && this.get('unavailable_locales').includes(this.get('i18n.locale'))) {
        return this.get('verbatim.tag').toLowerCase() !== this.get('language.tag').toLowerCase();
      } else if (this.get('verbatim.tag')) {
        return this.get('verbatim.tag').toLowerCase() !== this.get('i18n.locale');
      }
      return false;
    }),

    verbatimDateTime: computed('verbatim.sorting_date', function () {
      return (0, _moment['default'])(this.get('verbatim.sorting_date')).format('LLL');
    }),

    actions: {
      toggleDisplayText: function toggleDisplayText() {
        this.toggleProperty('showTranslation');
        if (!this.get('showTranslation')) {
          this.set('translationKey', 'see');
        } else {
          this.set('translationKey', 'hide');
        }
      }
    }
  });
});