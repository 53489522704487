define('due-dashboard/models/survey-language', ['exports', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _emberData, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    would_recommend_text: (0, _emberCpValidations.validator)('length', { min: 1 }),
    open_question: (0, _emberCpValidations.validator)('length', { min: 1 })
  });

  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend(Validations, {
    survey_revision: belongsTo('SurveyRevision', { async: true }),
    language: belongsTo('Language', { async: true }),
    questions: hasMany('Question', { async: true }),
    open_question: attr('string'),
    would_recommend_text: attr('string'),
    landing_page_text: attr('string'),
    website_link_text: attr('string'),
    submit_button_text: attr('string'),
    mailing_contents: hasMany('MailingContent', { async: true }),
    nps_text: attr(),
    thank_you_message: attr('string'),
    question_word: attr('string'),

    sortedQuestions: computed('questions.@each.position', function () {
      return this.get('questions').sortBy('position');
    })
  });
});