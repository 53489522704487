define('due-dashboard/components/account-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var getOwner = _ember['default'].getOwner;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    canCreateUsers: alias('currentAccount.canCreateUsers'),
    canEditUsers: alias('currentAccount.canEditUsers'),
    canDeleteUsers: alias('currentAccount.canDeleteUsers'),
    tagName: 'tr',
    genericProfilesNames: ['admin', 'manager', 'read_only'],

    isCurrentAccount: computed('currentAccount.content', 'account', function () {
      return this.get('currentAccount.content') === this.get('account');
    }),

    hasItemActions: computed('canEdit', 'canDelete', 'isCurrentAccount', function () {
      return !this.get('isCurrentAccount') && (this.get('canEdit') || this.get('canDelete'));
    }),

    canCreate: computed('canCreateUsers', function () {
      return this.get('canCreateUsers');
    }),

    canEdit: computed('canEditUsers', function () {
      return this.get('canEditUsers');
    }),

    canDelete: computed('canDeleteUsers', function () {
      return this.get('canDeleteUsers');
    }),

    createdAt: computed('account.created_at', 'i18n.locale', function () {
      var created_at = this.get('account.created_at');
      return created_at ? created_at.toLocaleDateString(this.get('i18n.locale'), { day: 'numeric', month: 'long', year: 'numeric' }) : '...';
    }),

    lastConnection: computed('account.last_connection', 'i18n.locale', function () {
      var connectionDate = this.get('account.last_connection');
      return connectionDate ? connectionDate.toLocaleDateString(this.get('i18n.locale'), { day: 'numeric', month: 'long', year: 'numeric' }) : '...';
    }),

    accountProfile: computed('account.profile', 'account.account_custom_profile_right.name', 'i18n.locale', function () {
      var profile = this.get('account.profile');
      if (this.genericProfilesNames.includes(profile)) {
        return this.get('i18n').t('settings.groups.' + profile);
      }
      if (profile === 'custom') {
        return 'custom (' + this.get('account.account_custom_profile_right.name') + ')';
      }
      return profile;
    }),

    accountZone: computed('account.zone', 'i18n.locale', function () {
      var zone = this.get('account.zone');
      return this.get('i18n').t('settings.users.' + zone);
    }),

    actions: {
      editAccount: function editAccount() {
        getOwner(this).lookup('controller:application').transitionToRoute('settings.users.edit', this.get('account.id'));
      },
      duplicateAccount: function duplicateAccount() {
        getOwner(this).lookup('controller:application').transitionToRoute('settings.users.duplicate', this.get('account.id'));
      }
    }

  });
});