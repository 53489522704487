define('due-dashboard/components/send-delay-time', ['exports', 'ember'], function (exports, _ember) {
  /* global _ */

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['send-delay-time'],

    options: [{ key: 'seconds', factor: 1 }, { key: 'minutes', factor: 60 }, { key: 'hours', factor: 60 * 60 }, { key: 'days', factor: 60 * 60 * 24 }],

    changedTime: computed('time', {
      get: function get() {
        var time = this.get('time');
        if (!this.get('selectedOption')) {
          if (time % 60 || time < 60) {
            this.set('selectedOption', this.get('options').findBy('key', 'seconds'));
          } else if (time < 60 * 60) {
            this.set('selectedOption', this.get('options').findBy('key', 'minutes'));
          } else if (time < 60 * 60 * 24) {
            this.set('selectedOption', this.get('options').findBy('key', 'hours'));
          } else {
            this.set('selectedOption', this.get('options').findBy('key', 'days'));
          }
        }
        return time / this.get('selectedOption.factor');
      },
      set: function set(___, new_value) {
        this.set('time', _.max([new_value * this.get('selectedOption.factor'), 0]));
        return new_value;
      }
    }),

    setNewTime: observer('selectedOption', function () {
      this.set('time', this.get('changedTime') * this.get('selectedOption.factor'));
    })

  });
});