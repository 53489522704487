define('due-dashboard/routes/settings/web-snippets/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return _ember['default'].RSVP.hash({
        web_snippets: this.store.findAll('web-snippet')
      });
    }

  });
});