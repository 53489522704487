define("due-dashboard/templates/components/web-snippet-code-preview", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/web-snippet-code-preview.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["get", "button.class", ["loc", [null, [2, 42], [2, 54]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "toggleModal", ["loc", [null, [2, 19], [2, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 10], [2, 32]]], 0, 0], ["content", "button.text", ["loc", [null, [2, 58], [2, 73]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/web-snippet-code-preview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "fa fa-copy");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "text");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "unless", [["get", "clicked", ["loc", [null, [9, 32], [9, 39]]], 0, 0, 0, 0], ["subexpr", "t", ["copy_to_clipboard"], [], ["loc", [null, [9, 40], [9, 63]]], 0, 0], ["subexpr", "t", ["copied"], [], ["loc", [null, [9, 64], [9, 76]]], 0, 0]], [], ["loc", [null, [9, 23], [9, 78]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 3
            },
            "end": {
              "line": 21,
              "column": 3
            }
          },
          "moduleName": "due-dashboard/templates/components/web-snippet-code-preview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("?filter[");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("]={{value}}");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          return morphs;
        },
        statements: [["content", "model.segment_filter.name", ["loc", [null, [20, 11], [20, 40]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 2
            },
            "end": {
              "line": 28,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/web-snippet-code-preview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "comment");
          var el2 = dom.createTextNode("\n    <!-- replace the {{value}} with your page's related ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" -->\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          return morphs;
        },
        statements: [["content", "model.segment_filter.name", ["loc", [null, [26, 87], [26, 116]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/components/web-snippet-code-preview.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "title");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "subtitle");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "code-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tag");
        var el3 = dom.createTextNode("<iframe ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "attr");
        var el3 = dom.createTextNode("src");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "op");
        var el3 = dom.createTextNode("=");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "attr-value");
        var el3 = dom.createTextNode("\"");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("/");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\"");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tag");
        var el3 = dom.createTextNode("></iframe>");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [5]);
        var element2 = dom.childAt(fragment, [7]);
        var element3 = dom.childAt(element2, [7]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
        morphs[3] = dom.createAttrMorph(element1, 'class');
        morphs[4] = dom.createMorphAt(element1, 1, 1);
        morphs[5] = dom.createMorphAt(element3, 2, 2);
        morphs[6] = dom.createMorphAt(element3, 5, 5);
        morphs[7] = dom.createMorphAt(element3, 7, 7);
        morphs[8] = dom.createMorphAt(element2, 11, 11);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "due-button", [], ["type", "action", "customClass", "close", "icon", "close", "textStyle", true], 0, null, ["loc", [null, [1, 0], [3, 15]]]], ["inline", "t", ["settings.web_snippets.code_title"], [], ["loc", [null, [4, 19], [4, 59]]], 0, 0], ["inline", "t", ["settings.web_snippets.code_subtitle"], [], ["loc", [null, [5, 22], [5, 65]]], 0, 0], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "clicked", ["loc", [null, [6, 17], [6, 24]]], 0, 0, 0, 0], "clicked"], [], ["loc", [null, [6, 12], [6, 36]]], 0, 0], " copy due-button"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "copy-button", [], ["clipboardText", ["subexpr", "@mut", [["get", "iframe", ["loc", [null, [7, 31], [7, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "success", "clickedAction", "class", "action"], 1, null, ["loc", [null, [7, 2], [10, 18]]]], ["content", "snippetUrl", ["loc", [null, [17, 3], [17, 17]]], 0, 0, 0, 0], ["content", "model.id", ["loc", [null, [18, 3], [18, 15]]], 0, 0, 0, 0], ["block", "if", [["get", "model.segment_filter.name", ["loc", [null, [19, 9], [19, 34]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [19, 3], [21, 10]]]], ["block", "if", [["get", "model.segment_filter.name", ["loc", [null, [24, 8], [24, 33]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [24, 2], [28, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});