define('due-dashboard/components/titled-combo-button', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    classNames: ['title-combo-button'],
    openSurveyOptions: false,
    bubbleUp: true,

    whereToAlign: computed('displayRight', function () {
      if (this.get('displayRight')) {
        return htmlSafe('left: 0;');
      }
      return htmlSafe('right: 0;');
    }),

    // prevent drag/drop from parent
    click: function click() {
      return this.get('bubbleUp');
    },

    actions: {
      openSurveyOptions: function openSurveyOptions() {
        this.toggleProperty('openSurveyOptions');
      },

      closeSurveyOptions: function closeSurveyOptions() {
        this.set('openSurveyOptions', false);
      },

      triggerAndClose: function triggerAndClose(triggerAction, actionName) {
        this.send('closeSurveyOptions');
        triggerAction(actionName);
      }
    }
  });
});