define('due-dashboard/components/digests/digest-modal', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',
    modalIsOpened: false,

    actions: {
      triggerAndClose: function triggerAndClose(modalAction, actionName) {
        modalAction(actionName);
        this.set('modalIsOpened', false);
      },

      openDigestModal: function openDigestModal() {
        this.set('modalIsOpened', true);
      },

      closeDigestsModal: function closeDigestsModal() {
        this.set('modalIsOpened', false);
      }
    }
  });
});