define('due-dashboard/controllers/settings/integrations/desk', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({
    desk_subdomain: null,
    submitEnabled: computed.notEmpty('model.company_integration.company_desk_subdomain'),
    hideSave: true,

    desk_auth: computed('model.company_integration.company_desk_subdomain', function () {
      var url = this.get('model.company_integration.company_desk_subdomain');
      if (url !== '') {
        return _dueDashboardConfigEnvironment['default'].EmberENV.DESK_CONNECT_URL + '/' + url + '/' + this.get('currentAccount.content.company.id');
      } else {
        return "";
      }
    })
  });
});