define('due-dashboard/models/template', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember'], function (exports, _emberData, _emberDataModelFragments, _ember) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var fragmentArray = _emberDataModelFragments['default'].fragmentArray;
  var fragment = _emberDataModelFragments['default'].fragment;
  exports['default'] = Model.extend({
    currentAccount: service(),

    name: attr(),
    sending_alias: attr(),
    sending_type: attr(),
    is_favorite: attr('boolean'),
    logo_image: attr('string'),
    text_contents: fragmentArray('template-content'),
    html_contents: fragmentArray('template-content'),
    dynamic_attributes: fragment('template-dynamic-attribute'),
    integrations_logos: attr(),
    /*array<url>*/duplicate_from: attr('string'),

    isSms: computed('sending_type', function () {
      return this.get('sending_type') === 'sms';
    }),

    onInit: on('ready', function () {
      this.loadRessources();
      if (this.get('isNew') && !this.get('duplicate_from')) {
        this.setDefaults();
      }
    }),

    loadRessources: function loadRessources() {
      var _this = this;

      var resources = this.store.peekAll('resources').get('firstObject');
      var survey = this.get('survey.active_revision');
      if (survey) {
        survey.then(function (s) {
          _this.set('default_logo', s.get('background_image'));
        });
      } else {
        this.set('default_logo', this.get('currentAccount.content.company.logo_image') || resources.get('default_logo'));
      }
      this.set('wordings', resources.get('templates_wordings'));
      this.set('default_bodies', resources.get('templates_default_bodies'));
      this.set('default_subjects', resources.get('templates_default_subjects'));
    },

    setDefaults: function setDefaults() {
      var language = this.get('currentAccount.content.language.content');
      this.get('text_contents').createFragment({ language: language });
      this.get('html_contents').createFragment({ language: language });
      this.set('sending_alias', this.get('currentAccount.content.company.name'));
      this.set('dynamic_attributes', this.store.createFragment('template-dynamic-attribute'));
      this.get('dynamic_attributes.sending_objects').createFragment({ language: language });
    },

    commitDefaults: function commitDefaults() {
      var _this2 = this;

      var type = this.get('sending_type');
      this.get('text_contents').map(function (content) {
        if (content.get('content') === undefined) {
          content.set('content', _this2.default_bodies[content.get('language.lower_tag')][type]);
        }
      });
      if (type !== 'sms') {
        this.get('dynamic_attributes.sending_objects').map(function (content) {
          if (content.get('content') === undefined) {
            content.set('content', _this2.default_subjects[content.get('language.lower_tag')][type]);
          }
        });
        if (type === 'html') {
          this.get('html_contents').map(function (html_content) {
            if (html_content.get('content') === undefined) {
              html_content.set('content', _this2.default_bodies[html_content.get('language.lower_tag')]['htmlcore']);
            }
          });
        }
      }
      return this;
    },

    // validations

    isValid: computed('name', 'sending_alias', 'sending_type', 'text_contents.@each.content', 'dynamic_attributes.isValid', 'html_contents.@each.content', function () {
      return this.validatesBase() && this.get('sending_type') && this.validateForEmail();
    }),

    validatesBase: function validatesBase() {
      return !!this.get('name') && !this.get('text_contents').filter(function (c) {
        return !c.get('isValid');
      }).length;
    },

    validateForEmail: function validateForEmail() {
      return !!this.get('sending_alias') && (this.get('sending_type') == 'sms' || this.get('dynamic_attributes.isValid'));
    }

  });
});