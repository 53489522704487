define("due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-background", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-background.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "due-typo-default");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element1, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["words.color"], [], ["loc", [null, [3, 35], [3, 54]]], 0, 0], ["inline", "due-input", [], ["type", "color", "placeholder", "", "value", ["subexpr", "@mut", [["get", "currentDesignOptions.backgroundColor", ["loc", [null, [7, 12], [7, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValue", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [8, 42], [8, 51]]], 0, 0, 0, 0], "_bg_color"], [], ["loc", [null, [8, 34], [8, 64]]], 0, 0]], [], ["loc", [null, [8, 15], [8, 65]]], 0, 0]], ["loc", [null, [4, 4], [9, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 0
            },
            "end": {
              "line": 23,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-background.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "component", ["v2/-range-slider"], ["label", ["subexpr", "t", ["words.border_radius"], [], ["loc", [null, [15, 12], [15, 37]]], 0, 0], "min", 0, "max", 50, "value", ["subexpr", "@mut", [["get", "currentDesignOptions.borderRadius", ["loc", [null, [18, 12], [18, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValueWithUnity", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [19, 51], [19, 60]]], 0, 0, 0, 0], "_border_radius"], [], ["loc", [null, [19, 43], [19, 78]]], 0, 0], "borderRadius"], [], ["loc", [null, [19, 15], [19, 94]]], 0, 0], "suffixLabel", ["subexpr", "@mut", [["get", "currentDesignOptions.borderRadiusSuffix", ["loc", [null, [20, 18], [20, 57]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 4], [21, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 0
            },
            "end": {
              "line": 35,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-background.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "component", ["v2/-range-slider"], ["label", ["subexpr", "t", ["words.border_width"], [], ["loc", [null, [27, 12], [27, 36]]], 0, 0], "min", 0, "max", 50, "value", ["subexpr", "@mut", [["get", "currentDesignOptions.borderWidth", ["loc", [null, [30, 12], [30, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValueWithUnity", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [31, 51], [31, 60]]], 0, 0, 0, 0], "_border_width"], [], ["loc", [null, [31, 43], [31, 77]]], 0, 0], "borderWidth"], [], ["loc", [null, [31, 15], [31, 92]]], 0, 0], "suffixLabel", ["subexpr", "@mut", [["get", "currentDesignOptions.borderWidthSuffix", ["loc", [null, [32, 18], [32, 56]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [26, 4], [33, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 0
            },
            "end": {
              "line": 46,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-background.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "due-typo-default");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["words.border_color"], [], ["loc", [null, [38, 35], [38, 61]]], 0, 0], ["inline", "due-input", [], ["type", "color", "placeholder", "", "value", ["subexpr", "@mut", [["get", "currentDesignOptions.borderColor", ["loc", [null, [42, 12], [42, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValue", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [43, 42], [43, 51]]], 0, 0, 0, 0], "_border_color"], [], ["loc", [null, [43, 34], [43, 68]]], 0, 0]], [], ["loc", [null, [43, 15], [43, 69]]], 0, 0]], ["loc", [null, [39, 4], [44, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-background.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "component", ["v2/libraries/surveys/navbar/design/elements/components/box-shadow"], ["name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [49, 9], [49, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "styleName", ["subexpr", "@mut", [["get", "styleName", ["loc", [null, [50, 14], [50, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "designOptions", ["subexpr", "@mut", [["get", "designOptions", ["loc", [null, [51, 18], [51, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "updateDesignOptions", ["subexpr", "@mut", [["get", "updateDesignOptions", ["loc", [null, [52, 24], [52, 43]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [48, 2], [53, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-background.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "displayBackgroundColor", ["loc", [null, [1, 6], [1, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [11, 7]]]], ["block", "if", [["get", "displayBorderRadius", ["loc", [null, [12, 6], [12, 25]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [12, 0], [23, 7]]]], ["block", "if", [["get", "displayBorderWidth", ["loc", [null, [24, 6], [24, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [24, 0], [35, 7]]]], ["block", "if", [["get", "displayBorderColor", ["loc", [null, [36, 6], [36, 24]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [36, 0], [46, 7]]]], ["block", "if", [["get", "displayBoxShadow", ["loc", [null, [47, 6], [47, 22]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [47, 0], [54, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});