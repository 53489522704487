define('due-dashboard/controllers/companies/surveys/edit/publishing', ['exports', 'ember', 'due-dashboard/mixins/help-zone'], function (exports, _ember, _dueDashboardMixinsHelpZone) {
  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend(_dueDashboardMixinsHelpZone['default'], {

    publishingType: 'mailing-due',

    newMailing: computed('model.survey_revision.mailings.[]', function () {
      return this.store.peekAll('mailing').filterBy('isNew', true)[0] || null;
    }),

    backBtnLink: computed(function () {
      var v2 = false; // this.get('currentAccount.content.company.content.enable_new_survey_dashboard');

      return v2 ? 'companies.libraries.surveys' : 'companies.surveys';
    }),

    actions: {
      selectType: function selectType() {
        var type = this.get('publishingType');
        if (type) {
          this.send('nextStep', type);
        }
      }
    }
  });
});