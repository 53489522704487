define('due-dashboard/components/due-color-panel', ['exports', 'ember', 'ember-cli-keyboard-nav/mixins/keyboard-nav'], function (exports, _ember, _emberCliKeyboardNavMixinsKeyboardNav) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend(_emberCliKeyboardNavMixinsKeyboardNav['default'], {
    tagName: 'div',
    classNames: ['due-color-panel'],
    colors: ["#b0b0b0", "#e8384f", "#fc91ad", "#fd9a00", "#eec300", "#a4cf30", "#37c5ab", "#20aaea", "#7a6ff0", "#e362e3"],

    didInsertElement: function didInsertElement() {
      this.bindKeys(this.$('.due-color-panel'));
    },

    show_class: computed('showColorPanel', function () {
      if (this.get('showColorPanel')) {
        return 'show-class';
      }
      return 'hide-class';
    }),

    displayArrowClass: computed('showArrow', 'left', function () {
      var marginTop = this.get('showArrow') ? '20px' : '0';
      var style = htmlSafe('left:-' + this.get('left') + 'px;margin-top:' + marginTop + ';');
      this.set('panelStyle', style);
      return this.get('showArrow') ? 'show-class' : 'hide-class';
    }),

    arrowStyle: computed('left', function () {
      return htmlSafe('left:min(calc(10px + ' + this.get('left') + 'px), 210px);');
    }),

    onEscPress: function onEscPress() {
      this.set('showColorPanel', false);
      this.set('fromParent', false);
    },

    actions: {
      clickOutsideComponent: function clickOutsideComponent() {},

      clickInsideComponent: function clickInsideComponent() {}
    }
  });
});