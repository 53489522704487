define("due-dashboard/templates/components/v2/multi-dropdown-dynamic-options/settings/segments/create-segment-category", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/multi-dropdown-dynamic-options/settings/segments/create-segment-category.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ddm-i-option-container display-row create-category");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "icon-wrapper");
        var el3 = dom.createElement("span");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ddm-i-option-prefix-text");
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "new-category-name");
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createAttrMorph(element1, 'style');
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [3, 0]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [5, 0]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["get", "dynamicOptionProps.icon", ["loc", [null, [2, 43], [2, 66]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", [["subexpr", "if", [["get", "dynamicOptionProps.color", ["loc", [null, [2, 82], [2, 106]]], 0, 0, 0, 0], ["subexpr", "concat", ["color: ", ["get", "dynamicOptionProps.color", ["loc", [null, [2, 125], [2, 149]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 107], [2, 150]]], 0, 0]], [], ["loc", [null, [2, 77], [2, 152]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "dynamicOptionProps.label", ["loc", [null, [3, 46], [3, 74]]], 0, 0, 0, 0], ["content", "dynamicOptionProps.value", ["loc", [null, [4, 39], [4, 67]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});