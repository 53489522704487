define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/background-design', ['exports', 'ember', 'due-dashboard/helpers/color-formating'], function (exports, _ember, _dueDashboardHelpersColorFormating) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['setting-element-container'],
    name: 'background',
    DEFAULT_VALUE: 'none',
    DEFAULT_COLOR: '#ffffff',
    DEFAULT_GRADIENT: 'linear-gradient(0deg, #FFFFFF 0%, #000000 100%)',
    BG_ATTACHEMENT: [{ name: 'fixed', value: 'fixed' }, { name: 'local', value: 'local' }, { name: 'scroll', value: 'scroll' }],
    BG_POSITION: [{ name: 'center', value: 'center' }, { name: 'top', value: 'top' }, { name: 'bottom', value: 'bottom' }, { name: 'left', value: 'left' }, { name: 'right', value: 'right' }],
    BG_REPEAT: [{ name: 'repeat', value: 'repeat' }, { name: 'repeat_x', value: 'repeat-x' }, { name: 'repeat_y', value: 'repeat-y' }, { name: 'space', value: 'space' }, { name: 'round', value: 'round' }, { name: 'no_repeat', value: 'no-repeat' }],
    BG_SIZE: [{ name: 'cover', value: 'cover' }, { name: 'contain', value: 'contain' }],
    GRADIENT_PREFIX: 'linear-gradient',
    IMAGE_PREFIX: 'http',

    currentOptions: computed('isLoading', function () {
      var currentItem = 0;
      var desktopBackgroundSize = this.get('designOptions.desktop_background_size');
      var desktopBackgroundImage = this.get('designOptions.desktop_background_image');
      if (desktopBackgroundSize != '0' && desktopBackgroundImage === 'none') {
        currentItem = 1;
      } else if (desktopBackgroundSize != '0' && desktopBackgroundImage != 'none') {
        currentItem = 2;
      }
      return {
        currentItem: currentItem
      };
    }),

    currentItem: computed('currentOptions.currentItem', function () {
      return this.get('currentOptions.currentItem');
    }),

    currentDesignOptions: computed('currentItem', 'isLoading', 'designOptions', function () {
      var bgColor = this.get('designOptions.desktop_background_color') || this.get('DEFAULT_COLOR');
      var backgroundImage = this.get('surveyDraft.background_image') || this.get('DEFAULT_VALUE');
      var bgImage = backgroundImage.startsWith(this.get('IMAGE_PREFIX')) ? backgroundImage : undefined;
      var backgroundGradient = this.get('designOptions.background_image') || this.get('DEFAULT_VALUE');
      var bgGradient = backgroundGradient.startsWith(this.get('GRADIENT_PREFIX')) ? backgroundGradient : this.get('DEFAULT_GRADIENT');
      return {
        color: bgColor,
        image: bgImage,
        gradient: bgGradient,
        bgAttachement: this._extractOption('designOptions.desktop_background_attachment', 'BG_ATTACHEMENT'),
        bgPosition: this._extractOption('designOptions.desktop_background_position', 'BG_POSITION'),
        bgRepeat: this._extractOption('designOptions.desktop_background_repeat', 'BG_REPEAT'),
        bgSize: this._extractOption('designOptions.desktop_background_size', 'BG_SIZE')
      };
    }),

    _extractOption: function _extractOption(designOptionName, optionsName) {
      var options = this.get(optionsName);
      var designOptions = this.get(designOptionName);
      return options.find(function (obj) {
        return obj.value === designOptions;
      }) || options[0];
    },

    actions: {
      setColor: function setColor(value) {
        value = _dueDashboardHelpersColorFormating['default'].compute(value);
        this.sendAction('updateDesignOptions', { desktop_background_color: value });
      },

      setLogoImage: function setLogoImage(image) {
        this.set('currentDesignOptions.image', image);
        this.sendAction('updateSurveyDraft', 'background_image', image);
      },

      setCurrentItem: function setCurrentItem(currentItem) {
        if (currentItem === 0) {
          this.sendAction('updateDesignOptions', { desktop_background_size: '0', desktop_background_image: 'none' });
        } else if (currentItem === 1) {
          this.sendAction('updateDesignOptions', { desktop_background_size: 'none', desktop_background_image: 'none' });
        } else if (currentItem === 2) {
          var gradient = this.get('currentDesignOptions.gradient');
          this.sendAction('updateDesignOptions', { desktop_background_size: 'none', desktop_background_image: gradient, background_image: gradient });
        }
        this.set('currentOptions.currentItem', currentItem);
      },

      selectDropdownValue: function selectDropdownValue(designOption, frontElement, selectedElement) {
        this.set('currentDesignOptions.' + frontElement, selectedElement);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, selectedElement.value));
      },

      updateGradient: function updateGradient(value) {
        this.set('currentDesignOptions.gradient', value);
        this.sendAction('updateDesignOptions', { background_image: value, desktop_background_image: value });
      }
    }
  });
});