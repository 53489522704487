define('due-dashboard/serializers/survey', ['exports', 'due-dashboard/serializers/application'], function (exports, _dueDashboardSerializersApplication) {
  exports['default'] = _dueDashboardSerializersApplication['default'].extend({
    serialize: function serialize(snap) {
      var json = this._super.apply(this, arguments);
      delete json.data.relationships['last-revision'];
      if (snap.id) {
        delete json.data.attributes['duplicate-from'];
      }
      if (json.data.attributes['duplicate-from']) {
        delete json.data.relationships['active-revision'];
      }
      delete json.data.relationships['draft-revision'];
      return json;
    }
  });
});