define('due-dashboard/components/v2/libraries/surveys/navbar/translations/-badge', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    BADGE_ICONS: {
      'rating': 'star_outlined',
      'short_text_input': 'comment_text_outlined',
      'long_text_input': 'text_left',
      'single_select': 'checkmark_circle_outlined',
      'multiple_select': 'checkmark_square_outlined',
      'title': 'small_caps',
      'text': 'text',
      'prev_next_btns': 'swap_horizontal',
      'redirect_btn': 'link'
    },

    badgeIcon: computed(function () {
      return this.BADGE_ICONS[this.get('kind')];
    })

  });
});