define('due-dashboard/components/v2/radio-buttons/-radio-buttons', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['due-radio-buttons'],

    actions: {}
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-radio-buttons}} radio buttons with selected yield display
 */