define('due-dashboard/components/my-select', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // possible passed-in values with their defaults:
    content: null,
    prompt: null,
    optionValuePath: 'id',
    optionLabelPath: 'name',
    action: _ember['default'].K, // action to fire on change

    // shadow the passed-in `selection` to avoid
    // leaking changes to it via a 2-way binding
    _selection: _ember['default'].computed.reads('selection'),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }
    },

    actions: {
      change: function change() {
        var selectEl = this.$('select')[0];
        var selectedIndex = selectEl.selectedIndex;
        var content = this.get('content');

        // decrement index by 1 if we have a prompt
        var hasPrompt = !!this.get('prompt');
        var contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;

        var selection = content[contentIndex];

        // set the local, shadowed selection to avoid leaking
        // changes to `selection` out via 2-way binding
        this.set('_selection', selection);

        var changeCallback = this.get('action');
        changeCallback(selection);
      }
    }
  });
});
// app/components/my-select.js