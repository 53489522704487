define('due-dashboard/controllers/companies/surveys/index', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    session: _ember['default'].inject.service(),
    surveySortProperties: ['isNew:desc', 'is_favorite:desc', 'created_at:desc'],
    surveys: computed.alias('model.surveys'),
    searchedSurveys: null,

    /*
    ** Pagination
    */

    currentPage: 1,
    limit: 10,

    pagesCount: computed('model.surveys.meta', '_page_count', function () {
      var meta = this.get('model.surveys.meta');
      return meta ? meta['page_count'] : this.get('_page_count');
    }),

    // queryParams: ['filterStatus'],

    filterStatus: 'live',
    scenarioStatusFilters: ['live', 'archived', 'deleted'],
    sortedSurveys: computed.sort('surveys', 'surveySortProperties'),

    filterAndPageObserver: observer('filterStatus', 'currentPage', function (_, changed_pp) {
      if (changed_pp === 'filterStatus') {
        this.set('currentPage', 1);
      }
      run.debounce(this, this.launchQuery, 300);
    }),

    launchQuery: function launchQuery() {
      var _this = this;

      var survey_type = "diduenjoy";
      this.set('surveysAreLoading', true);
      this.store.query('survey', { include: 'active-revision', filter: { status: this.get('filterStatus'), survey_type: survey_type }, sort: '-is_favorite,-created_at', page: { number: this.get('currentPage'), size: this.get('limit') } }).then(function (queryResult) {
        _this.set('surveys', queryResult.toArray());
        _this.set('_page_count', queryResult.meta['page_count']);
        _this.set('surveysAreLoading', false);
      });
    },

    startSurveyQuery: function startSurveyQuery() {
      var _this2 = this;

      var survey_type = "diduenjoy";
      this.store.query('survey', { filter: { name: this.get('searchedSurveys'), status: this.get('filterStatus'), survey_type: survey_type }, sort: '-is_favorite,-created_at', page: { number: 1, size: this.get('limit') } }).then(function (surveys) {
        _this2.set('model.surveys', surveys);
      });
    },

    /*
    ** User rights
    */

    canCreateSurvey: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return this.get('currentAccount.rights').get('surveys_rights') === 'all';
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    canChangeSurveyStatus: computed.alias('canCreateSurvey'),

    canExportSurveyStatus: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return ['all', 'write', 'export', true].includes(this.get('currentAccount.rights').get('exports_rights'));
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    canModifySurvey: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return ['all', 'write'].includes(this.get('currentAccount.rights').get('surveys_rights'));
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    canDistributeSurvey: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return ['all', 'write', 'distribute'].includes(this.get('currentAccount.rights').get('surveys_rights'));
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    actions: {
      debouncedSearchSurveys: function debouncedSearchSurveys() {
        _ember['default'].run.debounce(this, this.startSurveyQuery, 500);
      },

      duplicateSurvey: function duplicateSurvey(survey) {
        if (window.confirm(this.get('i18n').t('surveys.confirm_duplicate', { survey: survey.get('name') }).string)) {
          var record = this.get('store').createRecord('survey', {
            duplicate_from: survey.get('id'),
            created_at: new Date(Date.parse(this.get('sortedSurveys.firstObject.created_at')) + 1000) });
          // to push new survey at the top of sortedSurveys
          this.set('dupPromise', record.save());
          this.set('dupIndex', 0);
          $('body > .ember-view').animate({ scrollTop: 0 });
          this.set('cloning_survey_name', survey.get('name'));
          var surveys = this.get('model.surveys').toArray();
          surveys.push(record);

          // pagination stuff
          surveys = surveys.sortBy('created_at').reverse();
          if (surveys.length > this.get('limit') && this.get('pagesCount') === 1) {
            if (this.get('model.surveys.meta')) {
              this.get('model.surveys.meta').set('page-count', 2);
              this.notifyPropertyChange('pagesCount');
            } else {
              this.set('_page_count', 2);
            }
          }
          this.set('model.surveys', surveys.slice(0, this.get('limit')));
        }
      },

      updateSurveyStatus: function updateSurveyStatus(survey, status) {
        var _this3 = this;

        survey.set('status', status);
        survey.save().then(function (s) {
          _this3.store.peekAll('feedback').filterBy('survey.id', s.get('id')).map(function (a) {
            return a.reload();
          });
          s.get('draft_revision').then(function () {
            if (s.get('creation_status') === 'new' && !s.get('draft_revision.activity_sector.id') && s.get('status') === 'deleted') {
              s.destroyRecord();
            }
          });
          _this3.launchQuery();
        });
      }

    }
  });
});