define('due-dashboard/components/digests/parts/one-kpi-part', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    kpis: [],
    kpi: null,
    searchList: false,
    filteredKpis: [],
    basicKpis: [],
    triggerClientX: 0,
    triggerClientY: 0,
    triggerHeight: 0,
    triggerWidth: 0,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('finalKpi')) {
        (function () {
          var id = _this.get('finalKpi');
          _this.set('kpi', _this.get('kpis').find(function (kpi) {
            return kpi.id == id;
          }));
        })();
      } else {
        this.set('finalKpi', null);
      }
      this._buildCategories();
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this._setTriggerClientX();
    },

    _setTriggerClientX: function _setTriggerClientX() {
      if (this.element) {
        var trigger = this.element.querySelector('.select-container');
        if (trigger) {
          this.set('triggerClientY', trigger.offsetTop);
          this.set('triggerClientX', trigger.getBoundingClientRect().x);
          this.set('triggerHeight', trigger.getBoundingClientRect().height);
          this.set('triggerWidth', trigger.getBoundingClientRect().width);
        }
      }
    },

    newKpiArray: computed('kpi', function () {
      if (this.get('kpi')) {
        return [this.get('kpi')];
      }
      return [];
    }),

    _searchKpi: function _searchKpi(term) {
      var _this2 = this;

      if (term.length) {
        var searchPromise = this.store.query('kpi', {
          filter: { label: term }
        });
        this.set('search_promise', searchPromise);
        searchPromise.then(function (kpis) {
          _this2._buildFlatKpis(kpis);
          _this2.set('searchList', true);
        });
      } else {
        this.set('searchList', false);
      }
    },

    _buildFlatKpis: function _buildFlatKpis(kpis) {
      var _this3 = this;

      var filteredKpis = kpis.map(function (kpi) {
        var category_name = kpi.get('category_label') || _this3.get('i18n').t('digest.component.no_category').string;
        var title = category_name + ' > ' + kpi.get('localisedLabel');
        return { title: title, name: kpi.get('localisedLabel'), id: kpi.get('id'), disabled: kpi == _this3.get('kpi') };
      });
      this.set('filteredKpis', filteredKpis);
    },

    _buildCategories: function _buildCategories() {
      var _this4 = this;

      var basicKpis = [];
      var category_labels = this.get('kpis').map(function (kpi) {
        return kpi.get('category_label');
      });
      category_labels = [].concat(_toConsumableArray(new Set(category_labels)));
      category_labels.sort().forEach(function (category_label) {
        var category_name = category_label || _this4.get('i18n').t('digest.component.no_category').string;
        var parentKpi = { title: category_name, name: category_name, type: 'category' };
        parentKpi.groupName = { title: category_name, name: category_name, type: 'category' };
        var releventKpis = _this4._buildReleventKpis(category_label);
        parentKpi.options = releventKpis;
        basicKpis.push(parentKpi);
      });
      this.set('basicKpis', basicKpis);
    },

    _buildReleventKpis: function _buildReleventKpis(category_label) {
      var _this5 = this;

      var kpis = this.get('kpis').filter(function (kpi) {
        return kpi.get('category_label') === category_label;
      });
      return kpis.map(function (kpi) {
        return { title: kpi.get('localisedLabel'), name: kpi.get('localisedLabel'), id: kpi.get('id'), type: 'kpi', disabled: kpi == _this5.get('kpi') };
      });
    },

    dueInputProps: computed('i18n.locale', 'current_company', function () {
      var i18n = this.get('i18n');
      return {
        'class': 'filter-label',
        label: i18n.t('settings.company_segment.kpi'),
        placeholder: i18n.t('settings.company_segment.create_or_choose_kpi')
      };
    }),

    displayedKpis: computed('searchList', 'filteredKpis', 'basicKpis', function () {
      return this.get('searchList') ? this.get('filteredKpis') : this.get('basicKpis');
    }),

    actions: {

      toggle: function toggle(onToggle) {
        onToggle();
      },

      addKpi: function addKpi(kpi) {
        this.set('kpi', this.get('kpis').find(function (kpi) {
          return kpi.id == kpi.id;
        }));
        if (kpi) {
          this.set('finalKpi', kpi.id);
        } else {
          this.set('finalKpi', null);
        }
        this.set('searchList', false);
        this.get('saveDigestDelay')();
      },

      onDeleteKpi: function onDeleteKpi() {
        this.set('kpi', null);
        this.set('finalKpi', null);
        this.set('searchList', false);
        this.get('saveDigestDelay')();
      },

      searchKpi: function searchKpi(term) {
        var _this6 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this6, _this6._searchKpi, term, resolve, reject, 600);
        });
      }
    }
  });
});