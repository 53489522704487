define("due-dashboard/templates/settings/integrations/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 52
              },
              "end": {
                "line": 7,
                "column": 138
              }
            },
            "moduleName": "due-dashboard/templates/settings/integrations/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["status-icon ", ["subexpr", "if", [["get", "item.isOn", ["loc", [null, [7, 107], [7, 116]]], 0, 0, 0, 0], "on", "off"], [], ["loc", [null, [7, 102], [7, 129]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 8
              },
              "end": {
                "line": 12,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/settings/integrations/index.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "badge");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "details");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element0, 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat", ["settings.integrations.", ["get", "i_type", ["loc", [null, [11, 67], [11, 73]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [11, 34], [11, 83]]], 0, 0]], [], ["loc", [null, [11, 30], [11, 85]]], 0, 0], ["inline", "t", [["subexpr", "concat", ["settings.integrations.", ["get", "i_type", ["loc", [null, [11, 144], [11, 150]]], 0, 0, 0, 0], ".description"], [], ["loc", [null, [11, 111], [11, 166]]], 0, 0]], [], ["loc", [null, [11, 107], [11, 168]]], 0, 0]],
          locals: ["i_type"],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 8
              },
              "end": {
                "line": 17,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/settings/integrations/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["settings.integrations.configure"], [], ["loc", [null, [16, 10], [16, 49]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 8
              },
              "end": {
                "line": 19,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/settings/integrations/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["settings.integrations.set_up"], [], ["loc", [null, [18, 10], [18, 46]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 22,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/index.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "integration-logo");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h3");
          dom.setAttribute(el2, "class", "integration-name");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "integration-desc");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "badge-list");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1, 1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(element2, [9]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createAttrMorph(element3, 'src');
          morphs[3] = dom.createMorphAt(element4, 0, 0);
          morphs[4] = dom.createMorphAt(element4, 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element2, [7]), 1, 1);
          morphs[7] = dom.createAttrMorph(element5, 'class');
          morphs[8] = dom.createMorphAt(element5, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["integration-block ", ["subexpr", "unless", [["get", "item.it.free_access", ["loc", [null, [3, 43], [3, 62]]], 0, 0, 0, 0], "no-access"], [], ["loc", [null, [3, 34], [3, 76]]], 0, 0], " ", ["subexpr", "if", [["get", "item.isConnected", ["loc", [null, [3, 82], [3, 98]]], 0, 0, 0, 0], "is-connected"], [], ["loc", [null, [3, 77], [3, 115]]], 0, 0], " ", ["subexpr", "if", [["get", "item.isOn", ["loc", [null, [3, 121], [3, 130]]], 0, 0, 0, 0], "is-on", "is-off"], [], ["loc", [null, [3, 116], [3, 149]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["redirectToIntegration", ["get", "item.it", ["loc", [null, [3, 184], [3, 191]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 151], [3, 193]]], 0, 0], ["attribute", "src", ["get", "item.it.logo", ["loc", [null, [5, 19], [5, 31]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "item.it.title", ["loc", [null, [7, 35], [7, 52]]], 0, 0, 0, 0], ["block", "if", [["get", "item.isConnected", ["loc", [null, [7, 58], [7, 74]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 52], [7, 145]]]], ["content", "item.it.shortDescription", ["loc", [null, [8, 34], [8, 62]]], 0, 0, 0, 0], ["block", "each", [["get", "item.it.integration_types", ["loc", [null, [10, 16], [10, 41]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [10, 8], [12, 17]]]], ["attribute", "class", ["concat", ["call-to-action ", ["get", "item.it.name", ["loc", [null, [14, 35], [14, 47]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "item.isConnected", ["loc", [null, [15, 14], [15, 30]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [15, 8], [19, 15]]]]],
        locals: ["item"],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "integration-list");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "integrations", ["loc", [null, [2, 10], [2, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [22, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});