define("due-dashboard/templates/components/digests/top-progression", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 8,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/top-progression.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "digests/parts/color-picker-part", [], ["defaultMax", 10, "defaultMin", -10, "options", ["subexpr", "@mut", [["get", "comp.options", ["loc", [null, [7, 75], [7, 87]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "current_period_colors", "text", "digest.color_label", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [7, 160], [7, 175]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 4], [7, 177]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/top-progression.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "digests/parts/color-picker-part", [], ["defaultMax", 200, "defaultMin", -200, "options", ["subexpr", "@mut", [["get", "comp.options", ["loc", [null, [9, 77], [9, 89]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "current_period_colors", "text", "digest.color_label", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [9, 162], [9, 177]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [9, 4], [9, 179]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/components/digests/top-progression.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content-child");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content-child score-type-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["inline", "digests/parts/one-segment-part", [], ["finalSegment", ["subexpr", "@mut", [["get", "comp.options.segment", ["loc", [null, [2, 48], [2, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "label", "digest.component.segment_to_monitor", "segments", ["subexpr", "@mut", [["get", "segments", ["loc", [null, [2, 122], [2, 130]]], 0, 0, 0, 0]], [], [], 0, 0], "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [2, 147], [2, 162]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 164]]], 0, 0], ["inline", "digests/parts/score-part", [], ["label", "digest.component.score_type", "comp", ["subexpr", "@mut", [["get", "comp", ["loc", [null, [5, 70], [5, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [5, 91], [5, 106]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [5, 2], [5, 108]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "comp.options.score_type", ["loc", [null, [6, 12], [6, 35]]], 0, 0, 0, 0], "avg"], [], ["loc", [null, [6, 8], [6, 42]]], 0, 0]], [], 0, 1, ["loc", [null, [6, 2], [10, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});