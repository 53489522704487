define('due-dashboard/routes/companies/libraries/fonts/new', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller._resetPageState();
    }

  });
});