define("due-dashboard/templates/settings/integrations/microsoft-dynamics/configure", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 8
            },
            "end": {
              "line": 13,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/microsoft-dynamics/configure.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("strong");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "version", ["loc", [null, [12, 18], [12, 29]]], 0, 0, 0, 0]],
        locals: ["version"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 4
            },
            "end": {
              "line": 22,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/microsoft-dynamics/configure.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [7]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [9]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["settings.integrations.microsoft-dynamics.instructions.1"], [], ["loc", [null, [17, 10], [17, 73]]], 0, 0], ["inline", "t", ["settings.integrations.microsoft-dynamics.instructions.2"], [], ["loc", [null, [18, 10], [18, 73]]], 0, 0], ["inline", "t", ["settings.integrations.microsoft-dynamics.instructions.3"], ["url", ["subexpr", "@mut", [["get", "parent.microsoft_dynamics_module_dl_link", ["loc", [null, [19, 76], [19, 116]]], 0, 0, 0, 0]], [], [], 0, 0], "managed_url", ["subexpr", "@mut", [["get", "parent.microsoft_dynamics_managed_module_dl_link", ["loc", [null, [19, 129], [19, 177]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 10], [19, 179]]], 0, 0], ["inline", "t", ["settings.integrations.microsoft-dynamics.instructions.4"], [], ["loc", [null, [20, 10], [20, 73]]], 0, 0], ["inline", "t", ["settings.integrations.microsoft-dynamics.instructions.5"], [], ["loc", [null, [21, 10], [21, 73]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 2
            },
            "end": {
              "line": 30,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/microsoft-dynamics/configure.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "inputs-container");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(element1, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["settings.integrations.microsoft-dynamics.your_domain_title"], [], ["loc", [null, [25, 8], [25, 75]]], 0, 0], ["inline", "due-input", [], ["prefixLabel", "https://", "value", ["subexpr", "@mut", [["get", "model.company_integration.hostname", ["loc", [null, [27, 47], [27, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["settings.integrations.microsoft-dynamics.your_domain"], [], ["loc", [null, [27, 94], [27, 152]]], 0, 0]], ["loc", [null, [27, 6], [27, 154]]], 0, 0], ["inline", "due-input", [], ["prefixLabel", ".", "suffixLabel", ".dynamics.com", "value", ["subexpr", "@mut", [["get", "model.company_integration.crm_version", ["loc", [null, [28, 68], [28, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "crm4"], ["loc", [null, [28, 6], [28, 126]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 0
            },
            "end": {
              "line": 34,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/microsoft-dynamics/configure.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [33, 18], [33, 30]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "button.text", ["loc", [null, [33, 33], [33, 48]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 15
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/microsoft-dynamics/configure.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-block instructions");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ol");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "input-wrapper");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element4, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element3, 3, 3);
        morphs[3] = dom.createMorphAt(element2, 3, 3);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["settings.integrations.microsoft-dynamics.api_version"], [], ["loc", [null, [4, 4], [4, 64]]], 0, 0], ["block", "power-select", [], ["options", ["subexpr", "array", ["v8", "v9"], [], ["loc", [null, [7, 18], [7, 35]]], 0, 0], "selected", ["subexpr", "@mut", [["get", "parent.api_version", ["loc", [null, [8, 19], [8, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "parent.api_version", ["loc", [null, [9, 32], [9, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 27], [9, 51]]], 0, 0]], [], ["loc", [null, [9, 19], [9, 52]]], 0, 0], "searchEnabled", false], 0, null, ["loc", [null, [6, 8], [13, 25]]]], ["block", "if", [["get", "parent.api_version", ["loc", [null, [16, 10], [16, 28]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 4], [22, 11]]]], ["block", "if", [["get", "parent.api_version", ["loc", [null, [24, 8], [24, 26]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [24, 2], [30, 9]]]], ["block", "due-button", [], ["type", "transition", "disabled", ["subexpr", "not", [["get", "parent.submitEnabled", ["loc", [null, [32, 46], [32, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 41], [32, 67]]], 0, 0], "text", ["subexpr", "t", ["settings.integrations.microsoft-dynamics.create_connection"], [], ["loc", [null, [32, 73], [32, 137]]], 0, 0], "href", ["subexpr", "@mut", [["get", "parent.microsoft_dynamics_auth", ["loc", [null, [32, 143], [32, 173]]], 0, 0, 0, 0]], [], [], 0, 0]], 3, null, ["loc", [null, [32, 0], [34, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});