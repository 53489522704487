define('due-dashboard/helpers/array', ['exports', 'ember-array-helper/helpers/array'], function (exports, _emberArrayHelperHelpersArray) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberArrayHelperHelpersArray['default'];
    }
  });
  Object.defineProperty(exports, 'array', {
    enumerable: true,
    get: function get() {
      return _emberArrayHelperHelpersArray.array;
    }
  });
});