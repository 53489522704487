define('due-dashboard/components/graphs/event-line', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'tr',
    eventText: null,
    updating: false,
    actions: {
      deleteEvent: function deleteEvent(event) {
        this.get('removeEvent')(event);
      },

      editEvent: function editEvent() {
        this.set('eventText', this.get('event.event.text'));
        this.set('updating', true);
      },

      cancelUpdate: function cancelUpdate() {
        this.set('updating', false);
      },

      saveEventUpdate: function saveEventUpdate() {
        this.set('event.event.text', this.get('eventText'));
        this.set('updating', false);
      }
    }
  });
});