define('due-dashboard/helpers/check-rights-on-title', ['exports', 'ember', 'due-dashboard/helpers/check-rights-on-route'], function (exports, _ember, _dueDashboardHelpersCheckRightsOnRoute) {
  exports['default'] = _ember['default'].Helper.helper(function (object) {
    var currentAccount = object[0];
    var title = object[1];
    switch (title) {
      case 'content':
        if (_dueDashboardHelpersCheckRightsOnRoute['default'].compute([currentAccount, 'companies.surveys']) || _dueDashboardHelpersCheckRightsOnRoute['default'].compute([currentAccount, 'companies.templates']) || _dueDashboardHelpersCheckRightsOnRoute['default'].compute([currentAccount, 'companies.scenarios'])) {
          return true;
        }
        return false;
      case 'help_desk':
        return _dueDashboardHelpersCheckRightsOnRoute['default'].compute([currentAccount, 'companies.chats']);
      default:
        return true;
    }
  });
});