define('due-dashboard/components/group-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var and = _Ember$computed.and;
  var or = _Ember$computed.or;
  var not = _Ember$computed.not;
  var notEmpty = _Ember$computed.notEmpty;
  var getOwner = _ember['default'].getOwner;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'tr',

    zero: 0,
    isEditableGroup: notEmpty('group.id'),
    relatedAccountNumber: or('group.account_count', 'zero'),
    hasNoAccount: not('relatedAccountNumber'),
    isDeletableGroup: and('hasNoAccount', 'isEditableGroup'),
    genericGroupNames: ['admin', 'manager', 'read_only'],

    name: computed('group.name', 'i18n.locale', function () {
      var name = this.get('group.name');
      if (this.genericGroupNames.includes(name)) {
        return this.get('i18n').t('settings.groups.' + name);
      }
      return name;
    }),

    actions: {
      editGroup: function editGroup() {
        getOwner(this).lookup('controller:application').transitionToRoute('settings.groups.edit', this.get('group.id'));
      }
    }

  });
});