define('due-dashboard/components/reports/-multi-kpi', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['multi-kpi'],
    classNameBindings: ['layout']
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{multi-kpi}} component iterating on array of {{single-kpi}}
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */