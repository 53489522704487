define('due-dashboard/models/scenario-stat', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({

    total_views: attr('number'),
    unique_views: attr('number'),
    total_clicks: attr('number'),
    feedbacks_with_click: attr('number'),
    clicks_from: attr()

  });
});