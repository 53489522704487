define('due-dashboard/serializers/due-digest', ['exports', 'due-dashboard/serializers/application'], function (exports, _dueDashboardSerializersApplication) {
  exports['default'] = _dueDashboardSerializersApplication['default'].extend({
    serialize: function serialize(snap) {
      var json = this._super.apply(this, arguments);
      if (snap.id) {
        delete json.data.attributes['duplicate-from'];
        delete json.data.attributes['duplicate-subscriber-list'];
      }
      return json;
    }
  });
});