define('due-dashboard/routes/settings/integrations/prestashop', ['exports', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({
    needApiKey: true,
    includeSurveys: 'post-confirmed-order-survey',

    actions: {

      completePrestashopIntegration: function completePrestashopIntegration() {
        var _this = this;

        this.currentModel.company_integration.set('is_active', true);
        this.currentModel.company_integration.set('completed', true);
        this.currentModel.company_integration.set('api_key', this.currentModel.api_key);
        this.controller.set('promise', this.currentModel.company_integration.save().then(function () {
          return _this.transitionTo('settings.integrations.prestashop.manage');
        }));
      }

    }
  });
});