define('due-dashboard/mixins/model-validation-methods', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Mixin.create({
    validateStrict: function validateStrict() {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var _validateSync = _this.validateSync();

        var model = _validateSync.model;
        var validations = _validateSync.validations;

        if (!validations.get('isValid')) {
          reject(new Error(validations.get('message')));
        }
        resolve(model);
      });
    },

    validateStrictSync: function validateStrictSync() {
      var _validateSync2 = this.validateSync();

      var model = _validateSync2.model;
      var validations = _validateSync2.validations;

      if (!validations.get('isValid')) {
        throw new Error(validations.get('message'));
      }
      return model;
    }
  });
});