define('due-dashboard/serializers/feedback', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.data.attributes = {
        status: json.data.attributes.status,
        resolved: json.data.attributes.resolved,
        is_public: json.data.attributes['is-public'],
        actions_taken: json.data.attributes['actions-taken']
      };
      json.data.relationships = {};
      return json;
    }
  });
});