define('due-dashboard/controllers/settings/company', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var observer = _ember['default'].observer;
  var service = _ember['default'].inject.service;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    errors: service(),
    languages: alias('model.languages'),

    setCurrentLanguage: observer('model', function () {
      this.set('current_language', this.get('model.company.language'));
    }),

    disabledModify: computed('model.company.hasDirtyAttributes', 'current_language', 'model.company.language', function () {
      return this.get('model.company.language') === this.get('current_language') && !this.get('model.company.hasDirtyAttributes');
    }),

    actions: {
      updateCompany: function updateCompany() {
        var _this = this;

        this.set('displayed', true);
        this.get('model.company').save().then(function () {
          _this.setCurrentLanguage();
          window.location.reload(true);
        });
      },

      companyHasChanged: function companyHasChanged() {
        this.set('displayed', true);
        this.send('companyChanged');
      },

      toggleSatisfactionIndexType: function toggleSatisfactionIndexType() {
        this.toggleProperty('model.company.satisfaction_index_100');
      },

      toggleNoteByDefault: function toggleNoteByDefault() {
        this.toggleProperty('model.company.note_by_default');
      },

      toggleQuestionCategorySort: function toggleQuestionCategorySort() {
        this.toggleProperty('model.company.sort_question_categories_by_position');
      },

      toggleVerbatimDefaultLanguage: function toggleVerbatimDefaultLanguage() {
        this.toggleProperty('model.company.verbatim_auto_translation');
      },

      toggleRootCauseEnable: function toggleRootCauseEnable() {
        this.toggleProperty('model.company.enabled_root_cause');
        if (!this.get('model.company.action_taken_enabled') && !this.get('model.company.enabled_root_cause')) {
          this.set('model.company.modal_resolution_enabled', false);
        }
      },

      toggleActionTakenEnable: function toggleActionTakenEnable() {
        this.toggleProperty('model.company.action_taken_enabled');
        if (!this.get('model.company.action_taken_enabled') && !this.get('model.company.enabled_root_cause')) {
          this.set('model.company.modal_resolution_enabled', false);
        }
      },

      toggleModalResolutionEnable: function toggleModalResolutionEnable() {
        this.toggleProperty('model.company.modal_resolution_enabled');
      },

      toggleModalResolutionForDetractor: function toggleModalResolutionForDetractor() {
        this.toggleProperty('model.company.resolution_popup_for_detractor');
      },

      toggleRootCauseMandatory: function toggleRootCauseMandatory() {
        this.toggleProperty('model.company.root_cause_mandatory');
      },

      toggleActionTakenMandatory: function toggleActionTakenMandatory() {
        this.toggleProperty('model.company.action_taken_mandatory');
      },

      toggleTimezoneDashboard: function toggleTimezoneDashboard() {
        this.toggleProperty('model.company.timezone_in_dashboard');
      },

      toggleTimezoneDigest: function toggleTimezoneDigest() {
        this.toggleProperty('model.company.timezone_in_digest');
      },

      toggleOpenFeedback: function toggleOpenFeedback() {
        this.toggleProperty('model.company.open_feedback_on_note_creation');
      },

      deleteLogo: function deleteLogo() {
        var message = this.get('i18n').t('settings.company.confirm_delete');
        if (window.confirm(message)) {
          this.set('model.company.logo_image', null);
        }
      },

      deleteBackground: function deleteBackground() {
        var message = this.get('i18n').t('settings.company.confirm_delete');
        if (window.confirm(message)) {
          this.set('model.company.background_image', null);
        }
      }
    }
  });
});