define('due-dashboard/controllers/settings/integrations/hubspot', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({
    hubspot_it: alias('model.company_integration'),

    disable_save: computed('hubspot_it.in_company_timeline', 'hubspot_it.in_contact_timeline', function () {
      return !(this.get('hubspot_it.in_company_timeline') || this.get('hubspot_it.in_contact_timeline'));
    }),

    login_url: computed('model.company.id', function () {
      return _dueDashboardConfigEnvironment['default'].EmberENV['HUBSPOT_AUTH_URL'] + '/' + this.get('model.company.id');
    })

  });
});