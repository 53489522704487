define('due-dashboard/utils/group-by', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;

  exports['default'] = function (groupBy, key) {
    var dependentKey = 'content.@each.' + groupBy;

    if (!key) {
      return _ember['default'].computed(dependentKey, function () {
        var result = [];

        this.get('content').forEach(function (item) {
          var hasGroup = !!result.findBy('group', get(item, groupBy));

          if (!hasGroup) {
            result.pushObject(_ember['default'].Object.create({
              group: get(item, groupBy),
              content: []
            }));
          }

          result.findBy('group', get(item, groupBy)).get('content').pushObject(item);
        });

        return result;
      });
    } else {
      var result = [];
      groupBy.forEach(function (item) {
        var hasGroup = !!result.findBy('group', _ember['default'].get(item, key));

        if (!hasGroup) {
          result.pushObject(_ember['default'].Object.create({
            group: _ember['default'].get(item, key),
            content: []
          }));
        }

        result.findBy('group', _ember['default'].get(item, key)).get('content').pushObject(item);
      });

      return result;
    }
  };
});