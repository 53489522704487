define('due-dashboard/components/delete-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'td',
    classNames: ['delete-button'],

    actions: {
      buttonPressed: function buttonPressed() {
        this.get('onDelete')();
      }
    }
  });
});