define('due-dashboard/routes/companies/exports/index', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var set = _ember['default'].set;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    filters: service(),
    limit: 20,

    model: function model() {
      var _this = this;

      var filters = this.get('filters.rawCookie');
      filters = filters ? { filter: filters } : this.get('filters').get('queryFilters');
      set(filters, 'page', { number: 1, size: this.get('limit') });
      set(filters, 'include', 'choices,company-segment-values,client');
      var sorting_params = this.controller ? this.controllerFor('companies/exports.index').get('_sortingParams').join(",") : "";
      sorting_params = sorting_params === "" ? '-sorting-date,id' : sorting_params;
      set(filters, 'sort', sorting_params);
      return {
        feedbacks: this.store.query('feedback', filters).then(function (fbs) {
          _this.controllerFor('companies.exports.index').set('_pagination', {
            total_pages: fbs.meta['page_count'],
            number: 1,
            size: _this.get('limit')
          });
          _this.controllerFor('companies.exports.index').set('paginationLoading', false);
          return fbs;
        }),
        templates: this.store.query('export-template', { filter: {}, page: { number: 1, size: 60 } })
      };
    },

    resetController: function resetController() {
      this.controller.set('queryParams', []);
      this.controller.set('selectedSurvey', null);
      this._super.apply(this, arguments);
    },

    afterModel: function afterModel() {
      if (this.controller) {
        this.controller.init();
      } else if (this.controllerFor('companies.exports.index')) {
        this.controllerFor('companies.exports.index').init();
      }
    }

  });
});