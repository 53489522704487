define('due-dashboard/components/dynamic-surveys-dropdown', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    tagName: '',

    searchField: 'name',
    status: ['live', 'archived'],
    survey_type: ['diduenjoy'],

    init: function init() {
      var _this = this;

      this._searchSurvey('', function (surveys) {
        return _this.set('options', surveys);
      }, null);
      this._super.apply(this, arguments);
    },

    _searchSurvey: function _searchSurvey(term, resolve, reject) {
      this.store.query('survey', {
        filter: {
          name: term,
          status: 'live,archived',
          survey_type: 'diduenjoy'
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (surveys) {
        return resolve(surveys);
      }, reject);
    },

    actions: {

      searchSurveys: function searchSurveys(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this2, _this2._searchSurvey, term, resolve, reject, 600);
        });
      }

    }

  });
});