define('due-dashboard/serializers/search', ['exports', 'ember-data'], function (exports, _emberData) {

  /* global _ */

  var NAME_MAP = {
    profiles: 'profile',
    surveys: 'survey',
    tags: 'tag',
    languages: 'language',
    sentiments: 'sentiment',
    segments: 'segment'
  };

  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    serialize: function serialize(snapshot, options) {
      var json = _.cloneDeep(this._super.apply(this, arguments)),
          attrs = json.data.attributes;

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = attrs.filters[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var filter = _step.value;

          var type = NAME_MAP[filter.filter_type] || filter.filter_type;

          delete filter.filter_type;
          filter.type = type;
          switch (type) {
            case 'segment':
              filter.segmentId = filter.value.id;
              filter.value = filter.value.values;
              break;
            case 'tag':
              filter.valueTag = Object.keys(filter.value.tags_sentiments);
              filter.with_sentiment = true;
              filter.tags_sentiments = filter.value.tags_sentiments;
              filter.value = filter.value.included;
              break;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      attrs.filters = { data: attrs.filters };
      return json;
    },

    normalize: function normalize(typeClass, hash) {
      var attrs = hash.attributes;

      attrs.filters = attrs.filters.data;
      delete attrs.filters.data;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        var _loop = function () {
          var filter = _step2.value;

          var type = _.findKey(NAME_MAP, function (k) {
            return k === filter.type;
          }) || filter.type;

          delete filter.type;
          filter.filter_type = type;
          switch (type) {
            case 'segments':
              filter.value = { id: filter.segmentId, values: filter.value };
              delete filter.segmentId;
              break;
            case 'tags':
              filter.value = { included: filter.value, tags_sentiments: filter.tags_sentiments };
              delete filter.valueTag;
              delete filter.with_sentiment;
              delete filter.tags_sentiments;
              break;
          }
        };

        for (var _iterator2 = attrs.filters[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2['return']) {
            _iterator2['return']();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return this._super.apply(this, arguments);
    }
  });
});