define('due-dashboard/components/tags/breakdown-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var sort = computed.sort;
  exports['default'] = _ember['default'].Component.extend({
    filters: service(),
    tagName: '',
    tagSort: ['displayName'],
    hasSubTags: computed.notEmpty('tag.subTags'),
    sortedTags: sort('tag.subTags', 'tagSort'),

    graphbar_width: computed('tag.percent', function () {
      // min/max bar width: 33% - 100%
      return Math.round(this.get('tag.percent') * 0.66) + 33;
    }),

    displayedTitle: computed('tag', 'link', 'prefix', function () {
      if (this.get('link') !== undefined) {
        return this.get('title');
      } else if (this.get('prefix') !== undefined || this.get('tag.displayname') !== undefined) {
        return this.get('tag.displayName');
      } else {
        return this.get('title');
      }
    }),

    best_count: computed('tag', function () {
      return this.get('tag.feedbacks_count_incl_subtags') !== undefined ? this.get('tag.feedbacks_count_incl_subtags') : this.get('tag.count');
    }),

    best_nps: computed('best_count', 'best_promoters', 'best_detractors', function () {
      return Math.round((this.get('best_promoters') - this.get('best_detractors')) * 100 / this.get('best_count'));
    }),

    avg_feeling: computed('tag.positive_incl_subtags', 'tag.negative_incl_subtags', 'tag.neutral_incl_subtags', function () {
      var total = this.get('tag.negative_incl_subtags') + this.get('tag.positive_incl_subtags') + this.get('tag.neutral_incl_subtags');
      if (total === 0) return null;
      return ((-1 * this.get('tag.negative_incl_subtags') + this.get('tag.positive_incl_subtags')) / total).toPrecision(1);
    }),

    best_promoters: computed('tag', function () {
      return this.get('tag.promoters_incl_subtags') !== undefined ? this.get('tag.promoters_incl_subtags') : this.get('tag.promoter');
    }),

    best_passives: computed('tag', function () {
      return this.get('tag.passives_incl_subtags') !== undefined ? this.get('tag.passives_incl_subtags') : this.get('tag.passive');
    }),

    best_detractors: computed('tag', function () {
      return this.get('tag.detractors_incl_subtags') !== undefined ? this.get('tag.detractors_incl_subtags') : this.get('tag.detractor');
    }),

    promoter_percent: computed('best_count', 'best_promoters', function () {
      return Math.round(this.get('best_promoters') * 100 / this.get('best_count'));
    }),

    passive_percent: computed('best_count', 'best_passives', function () {
      return Math.round(this.get('best_passives') * 100 / this.get('best_count'));
    }),

    detractor_percent: computed('best_count', 'best_detractors', function () {
      return Math.round(this.get('best_detractors') * 100 / this.get('best_count'));
    }),

    computedPadding: computed('tag.displayed_depth', function () {
      return _ember['default'].String.htmlSafe('padding-left:' + this.get('tag.displayed_depth') * 25 + 'px');
    }),

    createdAt: computed('tag.created_at', 'i18n.locale', function () {
      var created_at = this.get('tag.created_at');
      return created_at ? created_at.toLocaleDateString(this.get('i18n.locale'), { day: 'numeric', month: 'long', year: 'numeric' }) : '...';
    }),

    tagColor: computed('tag.color', function () {
      return _ember['default'].String.htmlSafe('color: ' + this.get('tag.color'));
    }),

    isParent: computed('tag.depth', function () {
      if (this.get('tag.depth') == 0) {
        return true;
      }
      return false;
    })
  });
});