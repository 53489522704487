define('due-dashboard/components/v2/tooltips/-tag-badge-tooltip', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    attributeBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      var text = this.get('text');
      $(this.$().parent()).tooltipster({
        theme: ['tooltipster-noir', 'tooltipster-noir-customized', 'tooltipster-noir-customized-centered'],
        content: '\n        <div style="">\n          <span style="font-size: 12px;">' + text + '</span>\n        </div>\n      ',
        contentAsHTML: true,
        animation: 'fade',
        delay: 1,
        speed: 100,
        hideOnClick: false,
        trigger: 'hover',
        autoClose: true,
        functionReady: function functionReady() {
          $('.tag-badge-v2').on("click", function () {
            $(".tooltipster-noir-customized-centered").tooltipster('show');
          });
          $('.tag-badge-v2').on("click", function () {
            $(".tooltipster-noir-customized-centered").tooltipster('show');
          });
        },
        arrow: false
      });
    }
  });
});
/* global $ */