define('due-dashboard/routes/settings/web-snippets', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    actions: {

      saveSnippet: function saveSnippet(snippet) {
        var _this = this;

        snippet.save().then(function () {
          return _this.transitionTo('settings.web-snippets.index');
        });
      },

      createWebSnippet: function createWebSnippet() {
        var new_snippet = this.get('store').createRecord('web-snippet', {
          snippet_type: 'global_rating',
          lightness: 'light',
          display_comment: false,
          display_date: false
        });
        this.transitionTo('settings.web-snippets.edit', new_snippet);
      },

      deleteSnippet: function deleteSnippet(snippet) {
        var message = this.get('i18n').t('settings.web_snippets.confirm_delete');
        if (window.confirm(message)) {
          snippet.destroyRecord();
        }
      },

      editSnippet: function editSnippet(snippet) {
        this.transitionTo('settings.web-snippets.edit', snippet.get('id'));
      },

      rollback: function rollback(snippet) {
        if (snippet.get('id')) {
          snippet.rollbackAttributes();
          snippet.reload(true);
        }
        this.transitionTo('settings.web-snippets.index');
      }

    }
  });
});