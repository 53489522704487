define('due-dashboard/components/scenario-condition-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    errors: inject.service(),
    classNameBindings: ['hasErrors', 'minError'],
    tagName: 'tr',
    classNames: ['filter'],

    didRender: function didRender() {
      var _this = this;
      var func = function func() {
        _this.set('minError', true);
      };
      this.$()[0].onclick = func;
    },

    error_key: computed('condition.position', function () {
      return this.get('condition.type') + '-' + this.get('condition.position');
    }),

    minifyError: observer('minError', function () {
      if (this.get('minError')) {
        var error_node = document.getElementById('error-' + this.$()[0].id);
        if (error_node) {
          error_node.className = 'error-message';
        }
      }
    }),

    errorObserver: observer('try_step', function () {
      var error = this.get('errors').updateErrorByKey(this.get('error_key'), this.get('condition.validations.error'));
      var dom = this.$() ? this.$()[0] : null;
      if (dom) {
        var error_node = document.getElementById('error-' + this.$()[0].id);
        if (error) {
          this.set('minError', false);
          this.set('hasErrors', true);
          error_node = error_node || document.createElement('tr');
          error_node.id = 'error-' + this.$()[0].id;
          error_node.className = 'error-message has-error';
          error_node.appendChild(document.createElement('td'));
          error_node.firstChild.textContent = error.error.message;
          if (!error_node.parentNode) {
            dom.insertAdjacentElement('afterend', error_node);
          }
        } else if (error_node) {
          error_node.remove();
        }
      }
    }),

    willDestroy: function willDestroy() {
      this.get('errors').destroyErrorByKey(this.get('error_key'));
    }

  });
});