define('due-dashboard/components/due-button', ['exports', 'ember'], function (exports, _ember) {
  /* globals $ */

  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var and = _Ember$computed.and;
  var not = _Ember$computed.not;
  var or = _Ember$computed.or;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    /*
    ** Component Init
    */

    all_types: ['action', 'action.icon', 'action.delete', 'action.delete.hard', 'action.toggle', 'cancel', 'save', 'transition'],
    classNames: ['due-button'],
    classNameBindings: ['customClass', 'hasIcon', 'iconClass', 'textStyle'],
    scroll_to_error: true,
    timer: 2000,
    icon: null,
    bubblesUp: true,

    init: function init() {
      var type = this.get('type');
      if (!type) {
        throw new Error("due-button: You can't use this component without a given type attr");
      } else if (this.get('all_types').indexOf(type) < 0) {
        throw new Error('due-button: unknown button type \'' + type + '\'');
      }
      if (type === 'toggle' && this.get('on') === undefined) {
        throw new Error('due-button: type toogle need \'on\' state');
      }

      // init state labels
      var i18n = this.get('i18n');
      this.set('_labels', {
        disabled: this.get('labels.disabled') || i18n.t('due_button.default.disabled'),
        error: this.get('labels.error') || i18n.t('due_button.default.error'),
        loading: this.get('labels.loading') || i18n.t('due_button.default.loading'),
        on: this.get('labels.on') || i18n.t('due_button.default.on'),
        off: this.get('labels.off') || i18n.t('due_button.default.off'),
        success: this.get('labels.success') || i18n.t('due_button.default.success')
      });
      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      var toggleClick = function toggleClick() {
        this.clicked = this.clicked ? false : true;
      };
      var button = this.$('button')[0] || this.$('a')[0];
      button.toggleClick = toggleClick;
      button.onclick = button.toggleClick;
      this._super.apply(this, arguments);
    },

    /*
    ** Observers (on model.isSaving || event.loading || promise)
    */

    loadingObserver: on('init', observer('isLoading', 'clicked_once', 'isNotValid', function () {
      if (this.get('clicked_once') && (this.get('model') || this.get('events'))) {
        var loading_last_value = this.get('loading_last_value');
        var new_loading = this.get('isLoading') || false;
        this.set('loading_last_value', new_loading);
        if (loading_last_value !== new_loading || this.get('isNotValid')) {
          this.loadingChanged(this.get('loading_last_value'));
          this.propertyWillChange('labelToDisplay');
        }
      }
    })),

    promiseObserver: on('init', observer('promise', 'clicked_once', function () {
      var _this = this;

      var promise = this.get('promise');
      if (promise && (this.get('clicked_once') || this.get('needConfirm')) && (promise.isSettled && !promise.get('isSettled') || !promise.isSettled) && this.get('_promise') !== promise) {
        this.set('_promise', promise);
        this.set('_disable', 'disabled');
        this.set('try_saved', true);
        if (this.get('needConfirm')) {
          this.loadingChanged(true);
        }
        promise.then(function () {
          _this.display('success');
        })['catch'](function () {
          _this.display('error');
        });
      }
    })),

    /*
    ** Core Properties
    */

    isLoading: or('model.isSaving', 'events.loading'),
    isNotValid: computed('model.isError', 'model.isValid', 'events.error', function () {
      if (this.get('model')) {
        return this.get('model.isError') || !this.get('model.isValid') || this.get('events.error');
      }
      return this.get('events.error');
    }),
    isValid: not('isNotValid'),
    isSaved: and('isValid', 'clicked_once'),
    isError: and('isNotValid', 'clicked_once'),
    labelToDisplay: computed('temporaryLabel', 'text', 'on', function () {
      var label = this.get('temporaryLabel');
      if (this.get('type') === 'action.toggle') {
        label = label ? label : this.get('on') ? this.get('_labels.on') : this.get('_labels.off');
      }
      return label || this.get('text');
    }),

    classToDisplay: computed('temporaryClass', 'disabled', '_disable', 'on', function () {
      var state = this.get('temporaryClass') || (this.get('_disable') ? 'loading' : this.get('disabled') ? 'disabled' : null);
      if (this.get('type') === 'action.toggle' && !this.get('temporaryClass')) {
        state += this.get('on') ? ' on' : ' off';
      }
      if (this.get('textStyle')) {
        state += ' text-style ';
      }
      return state + ' ' + this.get('type').replace(/\./g, '-');
    }),

    hasIcon: computed.notEmpty('icon'),

    iconClass: computed('icon', function () {
      var icon = this.get('icon');
      if (icon) {
        return 'icon-' + icon;
      }
    }),

    /*
    ** Core functions
    */

    _bubblesUp: function _bubblesUp(event) {
      if (!this.get('bubblesUp')) {
        event.stopImmediatePropagation();
        event.stopPropagation();
      }
    },

    click: function click(event) {
      var button = this.$('button')[0] || this.$('a')[0];
      if (!button.clicked) {
        return this._bubblesUp(event);
      }
      button.toggleClick();
      if (this.get('disabled')) {
        return this._bubblesUp(event);
      }
      if (this.$()[0].firstElementChild.type === 'submit' && this.get('form_id')) {
        if (!$('#' + this.get('form_id'))[0].checkValidity()) {
          this.display('error');
          this._bubblesUp(event);
        }
      }
      var type = this.get('type');
      if (type === 'cancel') {
        this.set('clicked_once', this.get('clicked_once') || !this.get('disabled') ? true : false);
        this.set('_disable', 'disabled');
        this.set('temporaryLabel', this.get('_labels.loading'));
      } else if (type === 'action.delete.hard') {
        if (window.confirm(this.get('confirm'))) {
          this.set('_disable', 'disabled');
          this.set('temporaryLabel', this.get('_labels.loading'));
          this.get('deleteAction')();
        }
      } else if (this.get('needConfirm')) {
        return this._bubblesUp(event);
      } else if (type === 'transition' && this.get('href')) {
        this.set('clicked_once', this.get('clicked_once') || !this.get('disabled') ? true : false);
        this.loadingChanged(true);
        window.location.replace(this.get('href'));
      } else if (type.split('.')[0] === 'action' && !this.get('promise') && !this.get('model')) {
        return this._bubblesUp(event);
      } else {
        this.set('clicked_once', this.get('clicked_once') || !this.get('disabled') ? true : false);
        this.loadingChanged(true);
      }
      return this._bubblesUp(event);
    },

    loadingChanged: function loadingChanged(loading_state) {
      if (loading_state) {
        this.set('_disable', 'disabled');
        this.set('temporaryLabel', this.get('_labels.loading'));
      } else if (this.get('isSaved') || this.get('events.success')) {
        this.display('success');
      } else if (this.get('isError') || this.get('events.error')) {
        this.display('error');
      }
    },

    display: function display(state) {
      this.set('_disable', null);
      var type = this.get('type');
      if (state === 'error') {
        run.later(this, function () {
          if (!this.isDestroyed && this.get('scroll_to_error')) {
            $('html, body').animate({
              scrollTop: $('.error').first().offset().top - 100
            }, 500);
          }
        }, 200);
      }
      if (type === 'action.toggle' && state === 'success') {
        this.set('temporaryLabel', null);
        this.set('temporaryClass', null);
        this.set('clicked_once', false);
        return;
      }
      if (state !== 'success' || type !== 'transition') {
        this.set('temporaryClass', state);
        this.set('temporaryLabel', this.get('_labels.' + state));
      } else {
        this.set('temporaryClass', 'loading');
        this.set('temporaryLabel', this.get('_labels.loading'));
      }
      run.later(this, function () {
        if (!this.isDestroyed) {
          this.set('clicked_once', false);
          if (state !== 'success' || type !== 'transition') {
            this.set('temporaryLabel', null);
            this.set('temporaryClass', null);
          }
        }
      }, this.get('timer'));
    }

  });
});