define('due-dashboard/services/translations-fetcher', ['exports', 'ember', 'moment', 'due-dashboard/translations/fr', 'due-dashboard/translations/en', 'due-dashboard/translations/es', 'due-dashboard/translations/nl', 'due-dashboard/translations/th', 'due-dashboard/translations/ko', 'due-dashboard/translations/ja', 'due-dashboard/translations/zh', 'due-dashboard/translations/zht'], function (exports, _ember, _moment, _dueDashboardTranslationsFr, _dueDashboardTranslationsEn, _dueDashboardTranslationsEs, _dueDashboardTranslationsNl, _dueDashboardTranslationsTh, _dueDashboardTranslationsKo, _dueDashboardTranslationsJa, _dueDashboardTranslationsZh, _dueDashboardTranslationsZht) {
  var Service = _ember['default'].Service;
  var inject = _ember['default'].inject;
  exports['default'] = Service.extend({
    i18n: inject.service(),

    fetch: function fetch() {
      return this._addTranslations();
    },

    _addTranslations: function _addTranslations() {
      var locales = {
        'fr': _dueDashboardTranslationsFr['default'],
        'en': _dueDashboardTranslationsEn['default'],
        'es': _dueDashboardTranslationsEs['default'],
        'nl': _dueDashboardTranslationsNl['default'],
        'th': _dueDashboardTranslationsTh['default'],
        'ko': _dueDashboardTranslationsKo['default'],
        'ja': _dueDashboardTranslationsJa['default'],
        'zh': _dueDashboardTranslationsZh['default'],
        'zht': _dueDashboardTranslationsZht['default']
      };
      var i18n = this.get('i18n');
      Object.keys(locales).forEach(function (locale) {
        var translations = locales[locale];
        translations = translations[locale];
        var moment_translations = _moment['default'].localeData(locale);
        Object.keys(moment_translations).forEach(function (tkey) {
          translations[tkey.replace(/^_/, '')] = moment_translations[tkey].toString();
        });
        i18n.addTranslations(locale, translations);
      });
    }
  });
});