define('due-dashboard/components/infinite-scroll', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      if (this.get('resetScrollSteps')) {
        this.get('resetScrollSteps')();
      }
      var lastScrollTop = 0;
      var _scope = this;
      var dpMaxHeight = this.get('maxHeight');
      _ember['default'].$(this.get('parentScroll')).scroll(function () {
        var st = _ember['default'].$(this).scrollTop(),
            scrolled = _ember['default'].$(this)[0].scrollHeight - st;

        // We use a Fuzzy equal, because on a zoomed page, values may be something like:
        // scrolled = 149.88123983 and this_height = 150.3333333333
        if ((!_scope.get('fromDropdown') && st > lastScrollTop && scrolled <= _ember['default'].$(this).height() + 1 && scrolled > _ember['default'].$(this).height() - 1 || _scope.get('fromDropdown') && st > lastScrollTop && scrolled <= dpMaxHeight + 1 && scrolled > dpMaxHeight - 1) && !_scope.get('loading')) {
          _scope.get('loadMore')();
        }
        lastScrollTop = st;
      });
    }
  });
});