define('due-dashboard/components/question-detail-item', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'tr',
    displayOnHover: true,
    classNames: ['question-detail-item'],

    barStyle: computed('question.score', function () {
      var detractors = _dueDashboardConfigEnvironment['default'].EmberENV.colors[0];
      var passives = _dueDashboardConfigEnvironment['default'].EmberENV.colors[3];
      var promoters = _dueDashboardConfigEnvironment['default'].EmberENV.colors[4];
      var style = 'background-color: ';
      if (this.get('question.score').toFixed() <= 60) {
        style += detractors;
      } else if (this.get('question.score').toFixed() <= 80) {
        style += passives;
      } else {
        style += promoters;
      }
      return style + ';width: ' + this.get('question.score').toFixed() + '%';
    }),

    mouseMove: function mouseMove(e) {
      $('.c3-tooltip-container.question-detail-hover').offset({ left: e.pageX + 5, top: e.pageY + 5 });
    },

    values: computed('question.{score,number_of_choices,promoters_count,passives_count,detractors_count}', function () {
      var number_of_choices = this.get('question.number_of_choices'),
          detractors = _dueDashboardConfigEnvironment['default'].EmberENV.colors[0],
          passives = _dueDashboardConfigEnvironment['default'].EmberENV.colors[3],
          promoters = _dueDashboardConfigEnvironment['default'].EmberENV.colors[4],
          promoters_count = this.get('question.promoters_count'),
          passives_count = this.get('question.passives_count'),
          detractors_count = this.get('question.detractors_count');
      return {
        promoter: {
          value: promoters_count,
          percent: (promoters_count / number_of_choices * 100).toFixed(),
          barStyle: 'background-color: ' + promoters + '; width: ' + (promoters_count / number_of_choices * 100).toFixed() + '%',
          percentInlineStyle: (promoters_count / number_of_choices * 100).toFixed() <= 6 ? "display: none" : "display: initial"
        },
        passive: {
          value: passives_count,
          percent: (passives_count / number_of_choices * 100).toFixed(),
          barStyle: 'background-color: ' + passives + '; width: ' + (passives_count / number_of_choices * 100).toFixed() + '%',
          percentInlineStyle: (passives_count / number_of_choices * 100).toFixed() <= 6 ? "display: none" : "display: initial"
        },
        detractor: {
          value: detractors_count,
          percent: (detractors_count / number_of_choices * 100).toFixed(),
          barStyle: 'background-color: ' + detractors + '; width: ' + (detractors_count / number_of_choices * 100).toFixed() + '%',
          percentInlineStyle: (detractors_count / number_of_choices * 100).toFixed() <= 6 ? "display: none" : "display: initial"
        }
      };
    }),

    precisionValue: computed('question.score', function () {
      return (this.get('question.score') / 10 || 0).toPrecision(2);
    })
  });
});