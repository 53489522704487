define('due-dashboard/components/due-sticky-block', ['exports', 'due-dashboard/components/sticky-block', 'ember'], function (exports, _dueDashboardComponentsStickyBlock, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardComponentsStickyBlock['default'].extend({
    offset: computed('windowSize', function () {
      if (this.get('stick_windowSize.width') < 992) {
        return 101;
      } else {
        return 70;
      }
    })
  });
});