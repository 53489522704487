define('due-dashboard/controllers/sign-in', ['exports', 'ember', 'due-dashboard/helpers/email-validator', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardHelpersEmailValidator, _dueDashboardConfigEnvironment) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    currentAccount: _ember['default'].inject.service(),

    password: '',
    identification: '',
    minError: false,
    isUnauthenticated: false,

    displayEmailValidation: false,
    displayPasswordValidation: false,
    lastError: null,
    validEmail: false,
    validPassword: false,
    session: _ember['default'].inject.service(),

    init: function init() {
      this.set('currentAccount.newCompany', false);
      this._super.apply(this, arguments);

      var loginType = localStorage.getItem('loginType');
      if (loginType === null) {
        loginType = 'password';
        localStorage.setItem('loginType', loginType);
      }
      if (loginType == 'sso') {
        this.transitionToRoute('sso-login');
      }
    },

    unauthenticated: computed('isUnauthenticated', 'lastError.errors', function () {
      return this.get('lastError.errors') === undefined && this.get('isUnauthenticated') === true;
    }),

    errorMessage: computed('lastError.errors', 'i18n.locale', function () {
      var error = this.get('lastError.errors');

      if (error !== undefined && error[0].detail === 'failed_login') {
        return this.get('i18n').t('errors.' + error[0].detail);
      }
      return error ? this.get('i18n').t('errors.' + error[0].detail) : null;
    }),

    disableButton: computed('identification', 'password', function () {
      return !_dueDashboardHelpersEmailValidator['default'].compute(this.get('identification'));
    }),

    actions: {
      minifyError: function minifyError() {
        this.set('minError', true);
      },

      emailFocusedOut: function emailFocusedOut() {
        this.set('displayEmailValidation', true);
      },

      passwordFocusedOut: function passwordFocusedOut() {
        this.set('displayPasswordValidation', true);
      },

      transitionToSSO: function transitionToSSO() {
        localStorage.setItem('loginType', 'sso');
        this.transitionToRoute('sso-login');
      },

      authenticate: function authenticate() {
        var _this = this;

        var _getProperties = this.getProperties('identification', 'password');

        var identification = _getProperties.identification;
        var password = _getProperties.password;

        var promise = this.get('session').authenticate('authenticator:oauth2', identification, password);

        this.set('promise', promise);

        return promise['catch'](function (e) {
          if (e.errors && e.errors.length > 0 && e.errors[0].zone) {
            var zone_url = 'DASHBOARD_URL_' + e.errors[0].zone.toUpperCase();
            window.location.replace('' + _dueDashboardConfigEnvironment['default'].EmberENV[zone_url]);
          } else {
            _this.set('isUnauthenticated', !_this.get('session.isAuthenticated'));
            _this.set('minError', false);
            _this.set('lastError', e);
          }
        });
      }
    }
  });
});