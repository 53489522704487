define("due-dashboard/templates/components/animated-options", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 11,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/animated-options.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "ember-power-select-option");
            dom.setAttribute(el1, "role", "option");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'aria-selected');
            morphs[1] = dom.createAttrMorph(element0, 'aria-disabled');
            morphs[2] = dom.createAttrMorph(element0, 'aria-current');
            morphs[3] = dom.createAttrMorph(element0, 'data-option-index');
            morphs[4] = dom.createMorphAt(element0, 1, 1);
            return morphs;
          },
          statements: [["attribute", "aria-selected", ["concat", [["subexpr", "ember-power-select-is-selected", [["get", "opt", ["loc", [null, [4, 54], [4, 57]]], 0, 0, 0, 0], ["get", "select.selected", ["loc", [null, [4, 58], [4, 73]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 21], [4, 75]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-disabled", ["subexpr", "ember-power-select-true-string-if-present", [["get", "opt.disabled", ["loc", [null, [5, 64], [5, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [5, 78]]], 0, 0], 0, 0, 0, 0], ["attribute", "aria-current", ["concat", [["subexpr", "eq", [["get", "opt", ["loc", [null, [6, 25], [6, 28]]], 0, 0, 0, 0], ["get", "select.highlighted", ["loc", [null, [6, 29], [6, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 20], [6, 49]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-option-index", ["concat", [["get", "groupIndex", ["loc", [null, [7, 27], [7, 37]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [7, 41], [7, 46]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "yield", [["get", "opt", ["loc", [null, [9, 14], [9, 17]]], 0, 0, 0, 0], ["get", "select", ["loc", [null, [9, 18], [9, 24]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 6], [9, 26]]], 0, 0]],
          locals: ["opt", "index"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 2
              },
              "end": {
                "line": 13,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/animated-options.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "ember-power-select-option ember-power-select-option--loading-message");
            dom.setAttribute(el1, "role", "option");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "loadingMessage", ["loc", [null, [12, 99], [12, 117]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/animated-options.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "currentOptions", ["loc", [null, [2, 10], [2, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [13, 11]]]]],
        locals: ["currentOptions"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 16
          }
        },
        "moduleName": "due-dashboard/templates/components/animated-options.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "liquid-bind", [["get", "options", ["loc", [null, [1, 15], [1, 22]]], 0, 0, 0, 0]], ["rules", ["subexpr", "@mut", [["get", "animationRules", ["loc", [null, [1, 29], [1, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "enableGrowth", ["subexpr", "@mut", [["get", "enableGrowth", ["loc", [null, [1, 57], [1, 69]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [14, 16]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});