define('due-dashboard/components/loading-skeleton/due-tag-skeleton', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;

  /**
   * @author Antoine YVROUD <antoine@diduenjoy.com>
   * @summary {{due-tag-skeleton}} Tag skeleton component
   *
   * @module
   * @argument {string} [size] - 3 differents template size ("small" || "medium" || "large")
   * @argument {string} [width] - specific width for SVG
   * @argument {string} [height] - specific height for SVG
   * @argument {string} [color] - specific color for SVG
   * @argument {string} [customStyle] - specific style added to  the component initial style
   * @example <caption>Full usage example of {{due-tag-skeleton}} component</caption>
   * Usage :
   * ```hbs
   * {{loading-skeleton/due-tag-skeleton
   *   size="medium"
   *   color="#F6F8FB"
   *   customStyle="margin: XXpx;"
   * }}
   * or
   * {{loading-skeleton/due-tag-skeleton
   *   width="150"
   *   height="30"
   *   color="#F6F8FB"
   *   customStyle="margin: XXpx;"
   * }}
   * ```
   */

  exports['default'] = Component.extend({
    tagName: 'li',
    classNames: ['due-tag-skeleton'],
    classNamesBindings: ['size'],

    color: '#F6F8FB',
    widthFromSize: { small: 101, medium: 175, large: 227 },
    rendered: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('animationUID', 'linear-gradient-' + guidFor(this));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('rendered', true);
      this.set('xOffset', this.element.querySelector('svg').getBoundingClientRect().x);
      this.set('windowWidth', $('body > .ember-view').width());
    },

    computedAnimationUID: computed('emptyState', function () {
      return this.get('emptyState') ? '#F6F8FB' : 'url(#' + this.get('animationUID') + ')';
    }),

    containerWidth: computed('width', 'size', function () {
      return this.get('width') || this.get('widthFromSize')[this.get('size')] || 101;
    }),
    containerHeight: computed('height', 'size', function () {
      return this.get('height') || 29;
    }),

    borderRectWidth: computed('containerWidth', function () {
      return this.get('containerWidth') - 1;
    }),
    borderRectHeight: computed('containerHeight', function () {
      return this.get('containerHeight') - 1;
    }),

    insideRectWidth: computed('containerWidth', function () {
      return this.get('containerWidth') * 0.8;
    }),
    insideRectHeight: computed('containerHeight', function () {
      return this.get('containerHeight') * 0.33;
    }),

    xMargin: computed('containerWidth', function () {
      return this.get('containerWidth') * 0.1;
    }),
    yMargin: computed('containerHeight', function () {
      return this.get('containerHeight') * 0.33;
    })
  });
});