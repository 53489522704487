define('due-dashboard/controllers/settings/web-snippets/index', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var alias = _ember['default'].computed.alias;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({
    currentCompany: alias('currentAccount.content.company'),

    isShowingModal: false,

    actions: {

      openCodePreviewModal: function openCodePreviewModal(web_snippet) {
        this.set('selectedSnippet', web_snippet);
        this.send('toggleModal');
        return false;
      },

      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },

      tooglePublicDefault: function tooglePublicDefault() {
        this.toggleProperty('currentCompany.public_feedback_default');
        this.get('currentCompany.content').save().then(function () {
          $('iframe.preview').each(function (_, elem) {
            return elem.src += '';
          });
        });
      }
    }

  });
});