define('due-dashboard/services/feedback-search/global', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set', 'moment'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet, _moment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /* global _ */

  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Service.extend({

    current: computed.readOnly('_current'),

    ALLOWED_FILTER_TYPES: computed('_company.has_nps_surveys', function () {
      var types = [].concat(_toConsumableArray(_dueDashboardObjectsFeedbackSearchFilterSet['default'].PERSISTENT_FILTER_TYPES));

      _.pull(types, 'segments');
      if (!this.get('_company.has_nps_surveys')) {
        _.pull(types, 'profiles');
      }
      return types;
    }).readOnly().volatile(),

    DEFAULT_FILTERS: computed('ALLOWED_FILTER_TYPES.[]', function () {
      var props = _.pick({
        comment: 'both',
        status: 'both',
        profiles: [],
        surveys: []
      }, this.get('ALLOWED_FILTER_TYPES'));

      return _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWith(this.container, props);
    }).readOnly().volatile(),

    updateCurrent: function updateCurrent(filter_set) {
      this.set('_current', filter_set);
    },

    // private:

    init: function init() {
      var defaults = this.get('DEFAULT_FILTERS');

      defaults.set('completed_at', {
        start: (0, _moment['default'])().subtract(30, 'days').startOf('day').toDate(),
        end: (0, _moment['default'])().endOf('day').toDate()
      });
      this.set('_current', defaults);
    },

    _i18n: service('i18n'),
    _current: null,
    _currentAccount: service('current-account').readOnly(),
    _company: computed.readOnly('_currentAccount.content.company.content')
  });
});