define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/components/-gradient', ['exports', 'ember', 'due-dashboard/helpers/color-formating'], function (exports, _ember, _dueDashboardHelpersColorFormating) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['setting-element-container', 'element-component-container', 'gradient'],
    MAX_COLORS: 3,
    DEGREE: 'deg',
    STOP_PERCENT: '%',
    DEFAULT_DEG: '0',
    DEFAULT_COLOR: '#FFFFFF',
    DEFAULT_STOP: '0',
    DEG_REGEX: /\d*deg/g,
    COLOR_REGEX: /(#[0-9a-fA-F]{3}|[0-9a-fA-F]{6}|rgba\((\d+,\s?){3}\d*\.\d*\))/g,
    STOP_REGEX: /\d*%/g,

    currentDesignOptions: computed('linearGradient', 'designOptions', function () {
      var gradient = this.get('linearGradient');
      return {
        deg: this._extractDeg(gradient),
        colors: this._extractColors(gradient)
      };
    }),

    canAddColor: computed('currentDesignOptions.colors', function () {
      return this.get('currentDesignOptions.colors').length < this.get('MAX_COLORS');
    }),

    _extractDeg: function _extractDeg(gradient) {
      if (gradient == undefined) {
        return this.get('DEFAULT_DEG');
      }
      var match = gradient.match(this.get('DEG_REGEX'));
      return match ? match[0].match(/\d*/)[0] : this.get('DEFAULT_DEG');
    },

    _extractColors: function _extractColors(gradient) {
      if (gradient == undefined) {
        return [{ value: this.get('DEFAULT_COLOR'), stop: this.get('DEFAULT_STOP') }];
      }
      var colors = gradient.match(this.get('COLOR_REGEX'));
      var stops = gradient.match(this.get('STOP_REGEX'));
      if (colors == null || stops == null || colors.length != stops.length) {
        return [{ value: this.get('DEFAULT_COLOR'), stop: this.get('DEFAULT_STOP') }];
      }
      var gradientColors = [];
      colors.map(function (color, i) {
        gradientColors.push({ value: color, stop: stops[i].match(/\d*/)[0] });
      });
      return gradientColors;
    },

    _addColor: function _addColor() {
      var colors = this.get('currentDesignOptions.colors');
      colors.push({ value: this.get('DEFAULT_COLOR'), stop: this.get('DEFAULT_STOP') });
      this.set('currentDesignOptions.colors', colors);
    },

    _removeColor: function _removeColor(idx) {
      var colors = this.get('currentDesignOptions.colors');
      colors.splice(idx, 1);
      this.set('currentDesignOptions.colors', colors);
    },

    _setColor: function _setColor(idx, value) {
      var colors = this.get('currentDesignOptions.colors');
      if (value.match(this.get('COLOR_REGEX')) == null) {
        value = this.get('DEFAULT_COLOR');
      }
      set(colors[idx], 'value', value);
      this.set('currentDesignOptions.colors', colors);
    },

    _setStop: function _setStop(idx, value) {
      var colors = this.get('currentDesignOptions.colors');
      set(colors[idx], 'stop', value);
      this.set('currentDesignOptions.colors', colors);
    },

    _setDeg: function _setDeg(value) {
      this.set('currentDesignOptions.deg', value);
    },

    _generateLinearGradient: function _generateLinearGradient() {
      var gradient = this.get('currentDesignOptions');
      var gradientDeg = gradient.deg;
      var gradientColors = [];
      gradient.colors.forEach(function (color) {
        gradientColors.push(color.value + ' ' + color.stop + '%');
      });
      return 'linear-gradient(' + gradientDeg + 'deg, ' + gradientColors.join(', ') + ')';
    },

    _updateLinearGradient: function _updateLinearGradient() {
      var linearGradient = this._generateLinearGradient();
      this.sendAction('updateGradient', linearGradient);
    },

    actions: {
      addColor: function addColor() {
        this._addColor();
        this._updateLinearGradient();
      },

      removeColor: function removeColor(idx) {
        this._removeColor(idx);
        this._updateLinearGradient();
      },

      setColor: function setColor(idx, value) {
        value = _dueDashboardHelpersColorFormating['default'].compute(value);
        this._setColor(idx, value);
        this._updateLinearGradient();
      },

      setStop: function setStop(idx, value) {
        this._setStop(idx, value);
        this._updateLinearGradient();
      },

      setDeg: function setDeg(value) {
        this._setDeg(value);
        this._updateLinearGradient();
      }
    }
  });
});