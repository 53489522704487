define('due-dashboard/models/bulk-dispatch', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    sending_type: attr('string'),
    csv_body: attr(),
    csv_headers: attr(),
    active: attr('boolean'),
    survey_revision: belongsTo('survey-revision'),
    survey: belongsTo('survey', { readOnly: true }),
    template: belongsTo('template')
  });
});