define('due-dashboard/components/tags/merge-tag', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    exports['default'] = Component.extend({
        tagName: 'span',
        modalIsOpened: false,
        tags: computed(function () {
            //this.get('store').findAll('tags');
        }),
        tagsList: computed.filter('tags', function (item) {
            return item !== this.get('tag');
        }),
        selectedTag: computed.oneWay('tagsList.firstObject'),

        closeModal: function closeModal() {
            this.set('selectedTag', computed.oneWay('tagsList.firstObject'));
            this.set('modalIsOpened', false);
        },

        actions: {
            openModal: function openModal() {
                this.set('modalIsOpened', true);
            },

            setDestinationTag: function setDestinationTag(tag) {
                this.set('selectedTag', tag);
            },

            saveTag: function saveTag() {
                this.closeModal();
            },

            closeModal: function closeModal() {
                this.closeModal();
            }
        }
    });
});