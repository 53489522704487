define('due-dashboard/utils/has-many-through', ['exports', 'ember', 'ember-inflector'], function (exports, _ember, _emberInflector) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /* global _ */

  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;

  var updateMethodFor = function updateMethodFor(options) {
    return function (updated_objects) {
      var _this = this;

      this.get(options.throughKey).then(function (throughObjects) {
        RSVP.all(throughObjects.mapBy(options.sourceKey)).then(function (sourceObjects) {
          var toAdd = _.difference(updated_objects, sourceObjects);
          var toRemove = _.difference(sourceObjects, updated_objects);
          var throughObjectsArray = throughObjects.toArray();
          toRemove.forEach(function (removed_source_object) {
            var i = sourceObjects.indexOf(removed_source_object);
            var removedThroughObject = throughObjectsArray[i];
            throughObjects.removeObject(removedThroughObject);
            removedThroughObject.deleteRecord();
          });
          toAdd.forEach(function (added_source_object) {
            var _store$createRecord;

            var object = _this.store.createRecord(options.throughType, (_store$createRecord = {}, _defineProperty(_store$createRecord, options.foreignKey, _this), _defineProperty(_store$createRecord, options.sourceKey, added_source_object), _store$createRecord));
            throughObjects.addObject(object);
          });
        });
      });
    };
  };

  var parseOptions = function parseOptions(klass, throughKey, _options) {
    var relationship = _.chain(get(klass, 'relationshipsByName')._values).values().flatten().find({ key: throughKey }).value();
    if (relationship.kind !== 'hasMany') {
      throw 'Invalid relationship type for key ' + throughKey;
    }
    var options = _.extend(_options, {
      throughType: relationship.type,
      throughKey: throughKey
    });

    var _options$throughKey$split = options.throughKey.split('_');

    var _options$throughKey$split2 = _slicedToArray(_options$throughKey$split, 2);

    var _foreignKey = _options$throughKey$split2[0];
    var _pluralSourceKey = _options$throughKey$split2[1];

    if (!options.foreignKey) {
      options.foreignKey = _foreignKey;
    }
    if (!options.sourceKey) {
      options.sourceKey = _ember['default'].String.singularize(_pluralSourceKey);
    }
    if (!options.hasManyKey) {
      options.hasManyKey = _pluralSourceKey;
    }
    if (!options.updateMethod) {
      options.updateMethod = _ember['default'].String.camelize((0, _emberInflector.pluralize)('update_' + options.sourceKey));
    }
    return options;
  };

  exports['default'] = {
    define: function define(klass, throughKey) {
      var _klass$reopen;

      var _options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var options = parseOptions(klass, throughKey, _options);
      var dependentKey = options.throughKey + '.@each.' + options.sourceKey;
      klass.reopen((_klass$reopen = {}, _defineProperty(_klass$reopen, options.hasManyKey, computed(dependentKey, function () {
        return _ember['default'].ArrayProxy.create({
          content: this.get(options.throughKey).map(function (throughObject) {
            var sourceObject = throughObject.get(options.sourceKey);
            return sourceObject.content || sourceObject;
          })
        });
      }).readOnly()), _defineProperty(_klass$reopen, options.updateMethod, updateMethodFor(options)), _klass$reopen));
    }
  };
});

// [options.hasManyKey]: computed.mapBy(options.throughKey, options.sourceKey).meta(meta).readOnly(),