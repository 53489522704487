define('due-dashboard/models/match-surveys-scenario-condition.1', ['exports', 'ember-data', 'due-dashboard/utils/has-many-through', 'due-dashboard/models/scenario-condition', 'ember-cp-validations'], function (exports, _emberData, _dueDashboardUtilsHasManyThrough, _dueDashboardModelsScenarioCondition, _emberCpValidations) {
  var hasMany = _emberData['default'].hasMany;

  var Validations = (0, _emberCpValidations.buildValidations)({
    condition_surveys: (0, _emberCpValidations.validator)('presence', true)
  });

  var M = _dueDashboardModelsScenarioCondition['default'].extend(Validations, {
    match_surveys_scenario_conditions_surveys: hasMany('match-surveys-scenario-conditions-survey', { async: true }),

    defineSavepoint: function defineSavepoint() {
      var _this = this;

      return this.get('match_surveys_scenario_conditions_surveys').then(function (condition_surveys) {
        _this.set('_savepoint_condition_surveys', condition_surveys.toArray());
        return _this;
      });
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_condition_surveys');
      if (savepoint) {
        this.set('match_surveys_scenario_conditions_surveys', savepoint);
        savepoint.map(function (a) {
          return a.rollbackAttributes();
        });
      }
    }
  });

  _dueDashboardUtilsHasManyThrough['default'].define(M, 'match_surveys_scenario_conditions_surveys', {
    foreignKey: 'condition',
    hasManyKey: 'condition_surveys',
    sourceKey: 'survey'
  });

  exports['default'] = M;
});