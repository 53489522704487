define('due-dashboard/components/due-translation-column', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({
    tagName: '',
    hasError: false,

    notTranslatable: computed('line.due_exclusive', 'dueExclusiveEdit', function () {
      return this.get('line.due_exclusive') && !this.get('dueExclusiveEdit');
    }),

    displayValue: computed('line', 'line.translations', 'language.tag', function () {
      var currentLang = this.get('language.tag').toLowerCase(),
          tagLang = this.get('line.language.tag').toLowerCase(),
          currentTrans = this.get('line.translations')[currentLang];
      if (currentTrans) {
        return currentTrans['text'].length > 0 ? currentTrans['text'].split('>')[this.get('line.depth')].trim() : '';
      } else if (currentLang === tagLang) {
        return this.get('line.displayName');
      }
      return '';
    }),

    placeholderValue: computed('line', 'line.name', 'line.depth', function () {
      return this.get('line.name').split('>')[this.get('line.depth')].trim();
    }),

    inputIconClass: computed('line', 'line.translations', 'language.tag', function () {
      if (this.get('line.translations')[this.get('language.tag').toLowerCase()]) {
        if (this.get('line.translations')[this.get('language.tag').toLowerCase()]['auto']) {
          return 'auto';
        }
      }
      return '';
    }),

    updateTranslationValue: function updateTranslationValue() {
      var _this = this;

      var currentLang = this.get('language.tag').toLowerCase();
      var containsUnallowed = this.get('forbiddenChars').filter(function (char) {
        return _this.get('displayValue').includes(char);
      });
      if (containsUnallowed.length) {
        this.set('hasError', true);
      } else {
        this.set('hasError', false);
        this.get('updateTranslation')(this.get('line'), currentLang, this.get('displayValue'));
      }
    },

    actions: {
      debounceUpdateValue: function debounceUpdateValue() {
        run.debounce(this, this.updateTranslationValue, 500);
      }
    }
  });
});