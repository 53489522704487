define('due-dashboard/components/management-question-category/category-item', ['exports', 'ember', 'ember-sortable/mixins/sortable-item'], function (exports, _ember, _emberSortableMixinsSortableItem) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend(_emberSortableMixinsSortableItem['default'], {
    tagName: 'li',
    classNames: ['qcm-item'],
    deletePromise: null,

    name: computed('category.title', function () {
      return this.get('category.title');
    }),

    position: computed('category.position', function () {
      return this.get('category.position') !== null ? this.get('category.position') + 1 : 0;
    }),

    questionLabel: computed('category.questions', function () {
      return this.get('i18n').t('settings.categories.questions', { count: this.get('category.questions.length') });
    }),

    delete_confirmation: computed('category.title', function () {
      return this.get('i18n').t("settings.categories.confirm_delete", { category_name: htmlSafe(this.get("category.title")) });
    }),

    actions: {
      updateCategory: function updateCategory() {
        this.get('edit')(this.get('category.id'));
      },

      deleteCategory: function deleteCategory() {
        if (window.confirm(this.get('delete_confirmation'))) {
          this.set('deletePromise', this.get('delete')(this.get('category')));
        }
      }
    }
  });
});