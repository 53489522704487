define('due-dashboard/components/graphs/line-filter', ['exports', 'ember', 'due-dashboard/models/segment', 'ember-sortable/mixins/sortable-item'], function (exports, _ember, _dueDashboardModelsSegment, _emberSortableMixinsSortableItem) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_emberSortableMixinsSortableItem['default'], {
    tagName: 'tbody',

    init: function init() {
      var _this = this;

      this.store.query('segment', {
        filter: { all: false, display: 'filter' },
        page: { number: 1, size: 20 }
      }).then(function (segments) {
        return _this.set('defaultSegments', segments.toArray());
      });
      this.store.findAll('question-category').then(function (qc) {
        _this.set('questionCategories', qc.toArray());
      });
      this._super.apply(this, arguments);
    },

    availableSegments: computed.reads('defaultSegments'),

    chartTypes: ['line', 'spline', 'step', 'area', 'area-spline', 'area-step', 'bar'],

    hideLineFilter: computed('model.filters', function () {
      return this.get('model.filters').toArray().length === 0;
    }),

    titledCategoryTypes: computed('questionCategories', function () {
      var _this2 = this;

      var qcs = this.get('questionCategories') || [];

      return qcs.map(function (qc) {
        return {
          title: _this2._titleFromCategory(qc),
          type: 'question_category:' + qc.get('id')
        };
      });
    }),

    titledAveragePosCategoryTypes: computed('questionCategories', function () {
      var _this3 = this;

      var qcs = this.get('questionCategories') || [];

      return qcs.map(function (qc) {
        return {
          title: _this3._titleFromCategory(qc),
          type: 'question_category_average_pos:' + qc.get('id')
        };
      });
    }),

    lineTypes: computed('titledCategoryTypes.[]', function () {
      var i18n = this.get('i18n');
      return [{
        groupName: { title: i18n.t('graphs.edit_view.filters.indicators') },
        must_be_last: true,
        options: [{ title: 'NPS', type: 'nps' }]
      }, {
        groupName: { title: i18n.t('graphs.edit_view.filters.average') },
        must_be_last: true,
        options: [{ title: i18n.t('graphs.edit_view.filters.main'), type: 'average' }, {
          groupName: { title: i18n.t('graphs.edit_view.filters.questions.score_avg_pos') },
          must_be_last: true,
          options: this.get('titledAveragePosCategoryTypes')
        }, {
          groupName: { title: i18n.t('graphs.edit_view.filters.questions.score_avg') },
          must_be_last: true,
          options: this.get('titledCategoryTypes')
        }]
      }, {
        groupName: { title: i18n.t('graphs.edit_view.filters.counters') },
        must_be_last: true,
        options: [
        //{ title: i18n.t('graphs.edit_view.filters.total'),                 type: 'count_total'     },
        { title: i18n.t('graphs.edit_view.filters.feedbacks.completed'), type: 'count_completed' }, { title: i18n.t('graphs.edit_view.filters.feedbacks.sent'), type: 'count_sent' }, {
          groupName: { title: i18n.t('graphs.edit_view.filters.profiles.title') },
          must_be_last: true,
          options: [{ title: i18n.t('graphs.edit_view.filters.profiles.promoter'), type: 'count_promoter' }, { title: i18n.t('graphs.edit_view.filters.profiles.passive'), type: 'count_passive' }, { title: i18n.t('graphs.edit_view.filters.profiles.detractor'), type: 'count_detractor' }]
        }]
      }, {
        groupName: { title: i18n.t('graphs.edit_view.filters.percentages') },
        must_be_last: true,
        options: [{ title: i18n.t('surveys.stats.open.title'), type: 'open_rate' }, { title: i18n.t('surveys.stats.clicked.title'), type: 'click_rate' }, { title: i18n.t('surveys.stats.complete.title'), type: 'completion_rate' }, { title: i18n.t('surveys.stats.feedback.title'), type: 'feedback_rate' }, { title: i18n.t('graphs.edit_view.filters.rates.promoter'), type: 'promoter_rate' }, { title: i18n.t('graphs.edit_view.filters.rates.passive'), type: 'passive_rate' }, { title: i18n.t('graphs.edit_view.filters.rates.detractor'), type: 'detractor_rate' }, { title: i18n.t('graphs.edit_view.filters.rates.verbatims'), type: 'verbatim_rate' }, { title: i18n.t('graphs.edit_view.filters.rates.delivery'), type: 'delivery_rate' }]
      }];
    }),

    lineColor: computed('model.color', function () {
      return this.get('model.color');
    }),

    DISABLED_SEGMENT_FILTERS: ['MC_CID', 'MC_EID', 'SPM_KEY', 'UTM_SOURCE', 'UTM_CAMPAIGN', 'UTM_CONTENT', 'UTM_MEDIUM', 'UTM_TERM', 'SCHEDULED_AT'],

    enabledSegments: computed('model.filters.[]', 'availableSegments', function () {
      var _this4 = this;

      var available = this.get('availableSegments') || [];
      var filters = this.get('model.filters');

      return available.filter(function (s) {
        var name = s.get('name');

        return !(_this4.DISABLED_SEGMENT_FILTERS.includes(name.toUpperCase()) || filters.find(function (f) {
          return f.get('segment.name') === name;
        }));
      });
    }),

    sortedEnabledSegments: computed.sort('enabledSegments.[]', _dueDashboardModelsSegment['default'].sort),

    selectedType: computed('model.type', 'lineTypes.[]', function () {
      var type = this.get('model.type'),
          line_types = this.get('lineTypes') || [],
          find_line = function find_line(ll, t) {
        var title = null;
        ll.forEach(function (element) {
          if (element.type === t) {
            title = element;
          } else if (typeof element.options !== "undefined") {
            element.options.forEach(function (subelem) {
              if (subelem.type === t) {
                title = subelem;
              } else if (typeof subelem.options !== "undefined") {
                subelem.options.forEach(function (deepElem) {
                  if (deepElem.type === t) {
                    title = deepElem;
                  }
                });
              }
            });
          }
        });
        return title;
      },
          line = find_line(line_types, type),
          title = line != null ? line.title : '';

      return {
        title: title
      };
    }),

    delete_line_confirmation: computed('model.name', function () {
      return this.get('i18n').t('graphs.messages.confirm_delete_line', { stat_name: this.get('model.name') });
    }),

    _titleFromCategory: function _titleFromCategory(qc) {
      // TODO: Get current language
      return qc.get('title');
    },

    _searchSegments: function _searchSegments(term, resolve, reject) {
      var excludedSegments = [];
      this.get('model.filters').toArray().forEach(function (filter) {
        return excludedSegments.push(filter.get('segment.name'));
      });
      this.store.query('segment', {
        filter: {
          all: false,
          name: term,
          display: 'filter',
          excluded_names: excludedSegments.join('#$#')
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (seg) {
        return resolve(seg);
      }, reject);
    },

    _updateLineColor: function _updateLineColor() {
      this.set('model.color', this.get('lineColor'));
    },

    theColor: observer('lineColor', function () {
      run.debounce(this, this._updateLineColor, 800);
    }),

    actions: {
      saveLine: function saveLine() {
        this.onSave();
      },

      addFilter: function addFilter(segment) {
        this.get('model.filters').createFragment({
          segment: this.store.createFragment('segment-fragment', {
            oid: segment.get('id'),
            name: segment.get('name')
          })
        });
        this.notifyPropertyChange('model.filters');
      },

      removeFilter: function removeFilter(filter) {
        this.get('model.filters').removeObject(filter);
        this.get('model').save();
      },

      setType: function setType(type) {
        this.set('model.type', type.type);
      },

      changeSegments: function changeSegments(filter, segment, values) {
        filter.set('values', values);
      },

      searchSegments: function searchSegments(term) {
        var _this5 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this5, _this5._searchSegments, term, resolve, reject, 600);
        });
      },

      setAddFilterController: function setAddFilterController(remoteController) {
        this.set('addFilterController', remoteController);
      },

      showFilterList: function showFilterList() {
        this.get('addFilterController').actions.open();
      }
    }
  });
});