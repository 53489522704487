define("due-dashboard/templates/components/my-checkbox", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 116
          }
        },
        "moduleName": "due-dashboard/templates/components/my-checkbox.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["my-checkbox ", ["subexpr", "if", [["get", "checked", ["loc", [null, [1, 29], [1, 36]]], 0, 0, 0, 0], "checked", "not-checked"], [], ["loc", [null, [1, 24], [1, 62]]], 0, 0], " ", ["subexpr", "if", [["get", "disabled", ["loc", [null, [1, 68], [1, 76]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [1, 63], [1, 89]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["click"], [], ["loc", [null, [1, 91], [1, 109]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});