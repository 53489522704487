define('due-dashboard/components/v2/-black-tooltip', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['due-black-tooltip'],
    classNamesBindings: ['hidden', 'data-content'],
    hidden: false,

    didInsertElement: function didInsertElement() {
      var text = this.get('text');
      $(this.$().parent()).tooltipster({
        theme: ['tooltipster-noir', 'tooltipster-noir-customized'],
        content: '<span style="font-weight: 600;">' + text.toString() + '</span>',
        contentAsHTML: true,
        hideOnClick: true,
        position: 'right',
        animation: 'fade',
        delay: 150,
        arrow: false
      });
    },
    willDestroyElement: function willDestroyElement() {
      $(this.$().parent()).tooltipster('destroy');
    }
  });
});