define("due-dashboard/serializers/api-key", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].JSONAPISerializer.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.data.attributes["is-active"];

      return json;
    }
  });
});