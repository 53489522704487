define('due-dashboard/routes/companies/questions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    filters: _ember['default'].inject.service(),

    model: function model() {
      var filters = this.get('filters.rawCookie');
      filters = filters ? { filter: filters } : this.get('filters').get('queryFilters');
      _ember['default'].set(filters.filter, 'page_number', 1);
      var model = this.store.query('questions-benchmark', filters);
      return _ember['default'].RSVP.hash({
        questions: model.then(function (a) {
          return a.get('firstObject.questions');
        }),
        pages_count: model.then(function (a) {
          return a.meta['page_count'];
        }),
        main_question: model.then(function (a) {
          return a.get('firstObject.main_question');
        })
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.set('hideMainQuestion', false);
        this.controller.set('searchedQuestion', null);
        return true;
      }
    }

  });
});