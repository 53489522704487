define('due-dashboard/initializers/jsonapi-serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = {
    name: 'jsonapi-serializer-readonly-attr',
    initialize: function initialize() {
      _emberData['default'].JSONAPISerializer.reopen({
        serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
          // do not serialize the attribute if readOnly!
          if (attribute.options && attribute.options.readOnly) {
            return;
          }
          this._super.apply(this, arguments);
        }
      });
    }
  };
});