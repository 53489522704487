define('due-dashboard/components/custom-stat-nps-tag-tooltip', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var Component = _ember['default'].Component;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    tagName: 'span',
    classNames: ['nps-tooltip', 'tio-info_outined'],
    attributeBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      $(this.$().parent()).tooltipster({
        theme: ['tooltipster-noir', 'tooltipster-noir-customized', 'tooltipster-noir-customized-centered'],
        content: htmlSafe('<div class="text">' + this.get('tag') + '</div>').toString(),
        contentAsHTML: true,
        hideOnClick: true,
        position: 'top',
        animation: 'grow',
        arrow: false,
        delay: 150
      });
    },

    willDestroyElement: function willDestroyElement() {
      $(this.$().parent()).tooltipster('destroy');
    }
  });
});