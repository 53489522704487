define('due-dashboard/controllers/companies/scenarios/edit', ['exports', 'ember'], function (exports, _ember) {
  /* globals _, $ */
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var alias = computed.alias;
  var mapBy = computed.mapBy;
  var sort = computed.sort;
  var dasherize = _ember['default'].String.dasherize;
  exports['default'] = _ember['default'].Controller.extend({

    selectedActionRecipients: [],
    scenario: alias('model'),
    scenario_conditions: alias('scenario.scenario_conditions'),
    sortingPositionAsc: ['position'],
    sortedScenarioConditions: sort('scenario_conditions', 'sortingPositionAsc'),
    sortedScenarioActions: sort('scenario.scenario_actions', 'sortingPositionAsc'),
    displayErrors: false,
    errors: service(),
    currentAccount: service(),

    buildingStep: null,

    categoryStep: computed.equal('buildingStep', 0),
    conditionStep: computed.equal('buildingStep', 1),
    actionStep: computed.equal('buildingStep', 2),
    summaryStep: computed.equal('buildingStep', 3),

    actionCategoryHookKey: null,

    scenarioStore: computed(function () {
      return this.store;
    }),

    actionCategories: computed('i18n.locale', function () {
      var i18n = this.get('i18n');
      var _t = function _t(l) {
        return i18n.t('settings.scenarios.edit.' + l);
      };
      var categories = [{
        hookKey: 'company-new-feedback',
        label: _t('category_company_new_feedback_label'),
        description: _t('category_company_new_feedback_description'),
        icon: 'commenting-o'
      }, {
        hookKey: 'survey-send',
        label: _t('category_company_send_survey_label'),
        description: _t('category_company_send_survey_description'),
        icon: 'envelope-o'
      }];
      if (!this.get('currentAccount.content.company.enable_new_survey_dashboard')) {
        categories.push({
          hookKey: 'survey-end',
          label: _t('category_survey_end_label'),
          description: _t('category_survey_end_description'),
          icon: 'random'
        });
      }
      return categories;
    }),

    conditionTypes: computed('i18n.locale', 'actionCategoryHookKey', function () {
      var i18n = this.get('i18n'),
          v2Flag = this.get('currentAccount.content.company.content.enable_new_survey_dashboard');
      var t_label = function t_label(l) {
        return i18n.t('settings.scenarios.edit.condition_labels.' + l);
      };
      var conditions = [{ typeKey: 'unconditional-scenario-condition', label: t_label('unconditional') }, { typeKey: 'match-surveys-scenario-condition', label: t_label('match_surveys') }, { typeKey: 'match-segment-value-scenario-condition', label: t_label('match_segment_value_eq'), attrs: { operator: 'eq' } }, { typeKey: 'match-segment-value-scenario-condition', label: t_label('match_segment_value_neq'), attrs: { operator: 'neq' } }, { typeKey: 'has-segment-scenario-condition', label: t_label('has_segment_eq'), attrs: { operator: 'eq' } }, { typeKey: 'has-segment-scenario-condition', label: t_label('has_segment_neq'), attrs: { operator: 'neq' } }];
      if (this.get('actionCategoryHookKey') !== 'survey-send') {
        conditions.push({ typeKey: 'match-question-and-ratings-scenario-condition', label: t_label('match_question_and_ratings') }, { typeKey: 'match-devices-scenario-condition', label: t_label('match_devices') }, { typeKey: 'has-comment-scenario-condition', label: t_label('with_comment'), attrs: { with_comment: true } }, { typeKey: 'has-comment-scenario-condition', label: t_label('without_comment'), attrs: { with_comment: false } }, { typeKey: 'match-nps-score-scenario-condition', label: t_label('match_nps_score') });
        if (!v2Flag) {
          conditions.push({ typeKey: 'match-profiles-scenario-condition', label: t_label('match_profiles') });
        }
      } else {
        conditions.push({ typeKey: 'check-feedback-delayed-scenario-condition', label: t_label('check-feedback-delayed') });
      }
      return conditions;
    }),

    actionTypes: computed('i18n.locale', 'actionCategoryHookKey', function () {
      var i18n = this.get('i18n');
      var t_label = function t_label(l) {
        return i18n.t('settings.scenarios.edit.action_labels.' + l);
      };
      return [{ typeKey: 'auto-resolve-scenario-action', hookKey: 'company-new-feedback', label: t_label('auto_resolve') }, { typeKey: 'auto-reply-scenario-action', hookKey: 'company-new-feedback', label: t_label('auto_reply') }, { typeKey: 'preset-reply-scenario-action', hookKey: 'company-new-feedback', label: t_label('preset_reply') }, { typeKey: 'email-alert-scenario-action', hookKey: 'company-new-feedback', label: t_label('email_alert') }, { typeKey: 'redirect-scenario-action', hookKey: 'survey-end', label: t_label('redirect') }, { typeKey: 'generic-js-scenario-action', hookKey: 'survey-end', label: t_label('generic_js_action') }, { typeKey: 'load-js-scenario-action', hookKey: 'survey-end', label: t_label('load_js_action') }, { typeKey: 'change-end-text-scenario-action', hookKey: 'survey-end', label: t_label('change_end_text') }, { typeKey: 'change-end-link-scenario-action', hookKey: 'survey-end', label: t_label('change_end_link') }, { typeKey: 'change-end-social-networks-scenario-action', hookKey: 'survey-end', label: t_label('social_networks') }, { typeKey: 'change-end-fb-like-scenario-action', hookKey: 'survey-end', label: t_label('fb_like') }, { typeKey: 'change-end-fb-share-scenario-action', hookKey: 'survey-end', label: t_label('fb_share') }, { typeKey: 'change-end-tw-follow-scenario-action', hookKey: 'survey-end', label: t_label('tw_follow') }, { typeKey: 'change-end-tw-tweet-scenario-action', hookKey: 'survey-end', label: t_label('tw_tweet') }, { typeKey: 'change-end-image-scenario-action', hookKey: 'survey-end', label: t_label('image') }, { typeKey: 'change-end-trustpilot-scenario-action', hookKey: 'survey-end', label: t_label('trustpilot') }, { typeKey: 'text-field-to-segment-scenario-action', hookKey: 'survey-end', label: t_label('text_field_to_segment') }, { typeKey: 'send-survey-scenario-action', hookKey: 'survey-send', label: t_label('send_survey') }].filterBy('hookKey', this.get('actionCategoryHookKey'));
    }),

    addedActionTypes: mapBy('scenario.scenario_actions', 'type'),
    remainingActionTypes: computed('actionTypes.[]', 'addedActionTypes.[]', function () {
      var allTypes = this.get('actionTypes').toArray();
      var addedTypes = this.get('addedActionTypes').toArray();
      var isReplyAction = function isReplyAction(t) {
        return ['auto-reply-scenario-action', 'preset-reply-scenario-action'].includes(t);
      };
      var hasReplyAction = addedTypes.any(isReplyAction);
      var isResolveAction = function isResolveAction(t) {
        return ['auto-resolve-scenario-action'].includes(t);
      };
      var hasResolveAction = addedTypes.any(isResolveAction);
      var isRedirAction = function isRedirAction(t) {
        return ['redirect-scenario-action'].includes(t);
      };
      var hasRedirAction = addedTypes.any(isRedirAction);
      var isGenericJsAction = function isGenericJsAction(t) {
        return ['generic-js-scenario-action'].includes(t);
      };
      var hasGenericJsAction = addedTypes.any(isGenericJsAction);
      var isLoadJsAction = function isLoadJsAction(t) {
        return ['load-js-scenario-action'].includes(t);
      };
      var hasLoadJsAction = addedTypes.any(isLoadJsAction);
      var isChangeEndAction = function isChangeEndAction(t) {
        return ['change-end-text-scenario-action', 'change-end-link-scenario-action', 'change-end-social-networks-scenario-action', 'text-field-to-segment-scenario-action'].includes(t);
      };
      var hasChangeEndAction = addedTypes.any(isChangeEndAction);
      var uniqueTypes = ['redirect-scenario-action', 'generic-js-scenario-action', 'load-js-scenario-action', 'email-alert-scenario-action', 'change-end-text-scenario-action', 'change-end-social-networks-scenario-action', 'change-end-fb-like-scenario-action', 'change-end-fb-share-scenario-action', 'change-end-tw-follow-scenario-action', 'change-end-tw-tweet-scenario-action', 'change-end-trustpilot-scenario-action'];
      return allTypes.reject(function (type) {
        return addedTypes.includes(type.typeKey) && uniqueTypes.includes(type.typeKey) || hasReplyAction && isReplyAction(type.typeKey) || hasResolveAction && isResolveAction(type.typeKey) || hasRedirAction && isChangeEndAction(type.typeKey) || hasChangeEndAction && isRedirAction(type.typeKey) || hasGenericJsAction && isGenericJsAction(type.typeKey) || hasLoadJsAction && isLoadJsAction(type.typeKey);
      });
    }),

    addedConditionTypes: mapBy('scenario_conditions', 'type'),
    remainingConditionTypes: computed('conditionTypes', 'addedConditionTypes', function () {
      var allTypes = this.get('conditionTypes').toArray(),
          addedTypes = this.get('addedConditionTypes').toArray(),
          v2Flag = this.get('currentAccount.content.company.content.enable_new_survey_dashboard'),
          uniqueTypes = ['unconditional-scenario-condition', 'match-surveys-scenario-condition', 'match-devices-scenario-condition', 'has-comment-scenario-condition'];

      if (!v2Flag) {
        uniqueTypes.push('match-profiles-scenario-condition');
      }
      return allTypes.reject(function (type) {
        return addedTypes.includes(type.typeKey) && uniqueTypes.includes(type.typeKey) || type.typeKey === 'unconditional-scenario-condition';
      });
    }),

    addCondition: function addCondition(typeKey, attrs) {
      var scenario = this.get('scenario');
      attrs = _.extend({}, attrs, {
        type: typeKey,
        position: (this.get('sortedScenarioConditions.lastObject.position') || 0) + 1
      });
      var modelName = dasherize(typeKey);
      var condition = this.store.createRecord(modelName, attrs);
      scenario.get('scenario_conditions').then(function (conditions) {
        var defaultCondition = conditions.findBy('type', 'unconditional-scenario-condition');
        if (defaultCondition) {
          conditions.removeObject(defaultCondition);
        }
        conditions.addObject(condition);
      });
    },

    addAction: function addAction(typeKey, attrs) {
      var scenario = this.get('scenario');
      attrs = _.extend({}, attrs, {
        type: typeKey,
        position: (this.get('sortedScenarioActions.lastObject.position') || 0) + 1
      });
      var modelName = dasherize(typeKey);
      var action = this.store.createRecord(modelName, attrs);
      scenario.get('scenario_actions').then(function (actions) {
        actions.addObject(action);
      });
    },

    returnToIndex: function returnToIndex() {
      this.set('noActionError', false);
      this.set('displayed', false);
      this.transitionToRoute('companies.scenarios');
    },

    actions: {

      sendNextStep: function sendNextStep() {
        switch (this.get('buildingStep')) {
          case 0:
            return;
          case 3:
            $('#sc-form-button button').click();
            break;
          default:
            this.send('nextStep');
            break;
        }
      },

      sendPreviousStep: function sendPreviousStep() {
        switch (this.get('buildingStep')) {
          case 0:
            this.transitionToRoute('companies.scenarios');
            break;
          case 1:
            // bad but usefull to preserve actual behavior
            return;
          default:
            this.send('prevStep');
            break;
        }
      },

      setActionCategory: function setActionCategory(actionCategory) {
        this.set('actionCategoryHookKey', actionCategory.hookKey);
        this.send('nextStep');
      },

      nextStep: function nextStep() {
        var _this = this;

        var step = this.get('buildingStep'),
            nextStep = step + 1,
            scenario = this.get('scenario');
        if (nextStep > 3) {
          return;
        }
        var validationPromise = RSVP.resolve(null);
        if (step === 1) {
          validationPromise = scenario.validate({ on: ['scenario_conditions'] });
        } else if (step === 2) {
          if (!this.get('scenario.scenario_actions.length')) {
            return this.set('noActionError', true) && this.set('promise', RSVP.reject(null)) && null;
          }
          this.set('noActionError', false);
          validationPromise = scenario.validate({ on: ['scenario_actions'] });
        }
        this.set('promise', validationPromise);
        validationPromise.then(function (v) {
          if (v === null || v.validations === undefined || v.validations.get('isValid')) {
            _this.get('errors').resetErrors();
            _this.set('displayErrors', false);
            _this.set('buildingStep', nextStep);
          } else {
            _this.set('promise', RSVP.reject(null));
            _this.get('errors').signalChanges();
            if (v.promise) {
              _this.get('errors').resetErrors();
              v.promise[0][0].map(function (e) {
                _this.get('errors').updateErrorByKey(e.model.type + '-' + e.model.get('position'), e.model.get('validations.error') || null);
              });
            }
            _this.set('displayErrors', true);
          }
        });
      },

      prevStep: function prevStep() {
        var step = this.get('buildingStep');
        if (step > 0) {
          this.set('buildingStep', step - 1);
        }
      },

      saveScenario: function saveScenario() {
        var _this2 = this;

        var promise = this.get('scenario').save();
        this.set('savingPromise', promise);
        promise.then(function (scenario) {
          _this2.send('scenarioAdded', scenario);
          _this2.set('displayed', false);
          _this2.set('displayErrors', false);
          _this2.returnToIndex();
        });
        promise['catch'](function () {
          _this2.set('displayed', true);
        });
      },

      cancelScenarioEdit: function cancelScenarioEdit() {
        var _this3 = this;

        var scenario = this.get('scenario');
        this.set('displayed', false);
        scenario.rollbackToSavepoint().then(function () {
          return _this3.returnToIndex();
        });
      },

      addCondition: function addCondition(conditionType) {
        this.addCondition(conditionType.typeKey, conditionType.attrs);
      },

      addAction: function addAction(actionType) {
        this.addAction(actionType.typeKey, actionType.attrs);
      },

      deleteCondition: function deleteCondition(condition) {
        var conditions = this.get('scenario_conditions');
        conditions.removeObject(condition);
        condition.deleteRecord();
        this.get('errors').updateErrorByKey(condition.type + '-' + condition.get('position'), null);
        if (conditions.get('length') === 0) {
          var defaultCondition = this.store.createRecord('unconditional-scenario-condition');
          conditions.addObject(defaultCondition);
        }
      },

      deleteAction: function deleteAction(action) {
        this.get('scenario.scenario_actions').removeObject(action);
        this.get('errors').updateErrorByKey(action.type + '-' + action.get('position'), null);
        action.deleteRecord();
      }
    }
  });
});