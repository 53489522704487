define('due-dashboard/components/segment-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({

    classNames: ['filter', 'segment'],
    selectedValues: [],
    segmentValues: computed.mapBy('segment.company_segment_values', 'value'),
    uniqueValues: computed.uniq('segmentValues'),
    must_match: true,
    canCreateValues: false,
    displayDelegate: false,
    filters: service(),

    initValues: (function () {
      var values = this.get('values');
      if (typeof values === "string") {
        values = values.split(',');
      }
      if (values) {
        this.set('selectedValues', values);
      }
      if (this.get('op')) {
        this.set('operator', this.get('op'));
      }
      if (this.get('delegate_to_user')) {
        this.set('delegate', this.get('delegate_to_user'));
      }
    }).on('didReceiveAttrs'),

    operator: computed('must_match', 'withOperator', {
      get: function get() {
        return this.get('withOperator') ? this.get('must_match') ? 'eq' : 'neq' : null;
      }, set: function set(_, value) {
        this.set('must_match', value === 'eq' ? true : false);
        return value;
      }
    }),

    operatorObserver: observer('operator', function () {
      this.send('selectFilter', this.get('selectedValues'));
    }),

    actions: {

      searchValuesInit: function searchValuesInit() {
        var _this = this;

        var filters = this.get('filters');
        var query = this.store.queryRecord('segment-value', {
          filter: {
            segment_id: this.get('segment.id'),
            contains: '',
            start_date: filters.get('startDate'),
            end_date: filters.get('endDate')
          }
        });
        if (!this.get('initialOptions')) {
          this.set('initialOptions', query.then(function (arr) {
            return arr.get('values');
          }));
        } else {
          query.then(function (arr) {
            return _this.set('initialOptions', arr.get('values'));
          });
        }
      },

      searchValues: function searchValues(value) {
        var filters = this.get('filters');
        return this.store.queryRecord('segment-value', {
          filter: {
            segment_id: this.get('segment.id'),
            contains: value,
            start_date: filters.get('startDate'),
            end_date: filters.get('endDate')
          }
        }).then(function (arr) {
          var ret = arr.get('values');
          ret.addObject(value);
          return ret.sortBy('');
        });
      },

      selectFilter: function selectFilter(values) {
        this.set('selectedValues', values);
        this.attrs.action(this.get('segment'), values || null, this.get('operator'), this.get('delegate'));
      },

      toggleDelegate: function toggleDelegate() {
        this.toggleProperty('delegate');
        this.attrs.action(this.get('segment'), this.get('selectedValues'), this.get('operator'), this.get('delegate'));
      }
    }
  });
});