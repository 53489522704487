define("due-dashboard/templates/components/icons/-translation", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/icons/-translation.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "width", "15");
        dom.setAttribute(el1, "height", "16");
        dom.setAttribute(el1, "viewBox", "0 0 15 16");
        dom.setAttribute(el1, "fill", "none");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2, "clip-path", "url(#clip0_3584_410673)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M8.04375 9.91875L6.45625 8.35L6.475 8.33125C7.5625 7.11875 8.3375 5.725 8.79375 4.25H10.625V3H6.25V1.75H5V3H0.625V4.24375H7.60625C7.1875 5.45 6.525 6.59375 5.625 7.59375C5.04375 6.95 4.5625 6.24375 4.18125 5.5H2.93125C3.3875 6.51875 4.0125 7.48125 4.79375 8.35L1.6125 11.4875L2.5 12.375L5.625 9.25L7.56875 11.1938L8.04375 9.91875ZM11.5625 6.75H10.3125L7.5 14.25H8.75L9.45 12.375H12.4188L13.125 14.25H14.375L11.5625 6.75ZM9.925 11.125L10.9375 8.41875L11.95 11.125H9.925Z");
        dom.setAttribute(el3, "fill", "#71899C");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("defs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("clipPath");
        dom.setAttribute(el3, "id", "clip0_3584_410673");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("rect");
        dom.setAttribute(el4, "width", "15");
        dom.setAttribute(el4, "height", "15");
        dom.setAttribute(el4, "fill", "white");
        dom.setAttribute(el4, "transform", "translate(0 0.5)");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});