define('due-dashboard/components/template-item', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  /* global _ */

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    store: _ember['default'].inject.service(),

    classNames: ['templates-item'],

    languages: computed(function () {
      return this.store.peekAll('language');
    }),

    requiredLanguages: computed('survey_languages.isFulfilled', 'template.text_contents.[].language', 'bulk_dispatch.csv_body', function () {
      var languages = [];
      if (this.get('bulk_dispatch.csv_body')) {
        languages = this.get('bulk_dispatch.csv_body').map(function (b) {
          return b.language;
        }).filter(function (a) {
          return a;
        }).uniq();
      }
      if (this.get('survey_languages.isFulfilled') && !languages.length) {
        languages = this.get('survey_languages').mapBy('language.tag');
      }
      return _.difference(languages, this.get('modelLanguages'));
    }),

    modelLanguages: computed('languages.[]', 'model.text_contents.[].languages', 'model.html_contents.[].languages', function () {
      return this.get('model.text_contents').mapBy('language.tag');
    }),

    templateDate: computed('model.sent_at', function () {
      return (0, _moment['default'])(this.get('model.sent_at')).format('L');
    }).readOnly(),

    selectedLanguage: computed('templateLanguages', 'clickedLanguage', function () {
      var language = undefined;
      var contents = this.get('model.text_contents');
      var html_contents = this.get('model.html_contents');
      [this.get('clickedLanguage'), this.get('i18n.locale').toUpperCase(), this.get('currentAccount.content.language.tag'), this.get('currentAccount.content.company.language.tag')].forEach(function (tag) {
        language = language || contents.findBy('language.tag', tag);
      });
      language = (language || contents.get('firstObject')).get('language');
      this.set('selectedTag', language.get('tag'));
      return {
        content: contents.findBy('language.tag', language.get('tag')).get('content'),
        html_content: html_contents.findBy('language.tag', language.get('tag')) ? html_contents.findBy('language.tag', language.get('tag')).get('content') : null,
        language: language,
        sending_object: _ember['default'].get(this.get('model.dynamic_attributes.sending_objects').findBy('language.tag', language.get('tag')) || {}, 'content')
      };
    }),

    templateActions: computed('i18n.locale', 'template', 'selectTemplate', 'model.is_favorite', 'currentAccount.canEditTemplates', function () {
      var template_actions = [];
      if (this.get('selectTemplate')) {
        template_actions.push({ icon: 'send', action: 'select', textKey: 'words.distribute' });
      }
      if (this.get('currentAccount.canEditTemplates')) {
        template_actions.push({ icon: 'gears', action: 'editTemplate', textKey: 'words.edit' });
        template_actions.push({ icon: 'star', action: 'makeFavorite', textKey: 'templates.edit.' + (this.get('model.is_favorite') ? 'un' : '') + 'make_favorite' });
        template_actions.push({ icon: 'copy', action: 'duplicateTemplate', textKey: 'words.duplicate' });
      }
      template_actions.push({ icon: 'clipboard', action: 'copyTemplateId', textKey: 'templates.edit.copy_id' });
      if (this.get('currentAccount.canEditTemplates')) {
        template_actions.push({ icon: 'trash', action: 'deleteTemplate', textKey: 'words.delete', disabled: this.get('model.is_favorite') });
      }
      return template_actions;
    }),

    idToCopy: computed('model.id', function () {
      return 'template-id-to-copy-' + this.get('model.id');
    }),

    actions: {

      editTemplate: function editTemplate() {
        this.get('onEdit')(this.get('model'));
      },

      makeFavorite: function makeFavorite() {
        this.toggleProperty('model.is_favorite');
        this.get('model').save();
      },

      duplicateTemplate: function duplicateTemplate() {
        if (window.confirm(this.get('i18n').t('templates.confirm_duplicate'))) {
          this.get('duplicateTemplate')();
        }
      },

      copyTemplateId: function copyTemplateId() {
        var $copyText = document.getElementById(this.get('idToCopy'));
        $copyText.select();
        document.execCommand("Copy");
      },

      deleteTemplate: function deleteTemplate() {
        if (window.confirm(this.get('i18n').t('templates.confirm_delete'))) {
          this.get('deleteTemplate')();
        }
      },

      select: function select() {
        this.get('selectTemplate')();
      },

      triggerAction: function triggerAction(action) {
        this.send(action);
      },

      clickLanguage: function clickLanguage(tag) {
        this.set('clickedLanguage', tag);
      }
    }

  });
});