define('due-dashboard/components/reports/-pie-chart', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set', 'due-dashboard/helpers/current-timezone'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet, _dueDashboardHelpersCurrentTimezone) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['pie-chart'],
    classNameBindings: ['layout', 'v_layout', 'pieSize'],

    /* ### Services props ### */
    globalFilters: service('feedback-search/global'),
    store: service(),

    /* ### Private props ### */
    isLoading: true,
    isEmpty: false,

    /* ### Title props ### */
    title: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].title;
    }),

    /* ### Information popup props ### */
    informationTitle: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].information.title;
    }),

    informationText: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].information.text;
    }),

    legend: computed('content.component.config.pie', function () {
      var translations = this.get('content.component.config.translations')[this.get('currentLanguage')],
          colors = this.get('content.component.config.pie');

      return translations.pie.map(function (pieTrad, index) {
        return { title: pieTrad.title, style: 'background-color: ' + colors[index].color + ';' };
      });
    }),

    pieChart: computed('content_data', function () {
      var pie = this.get('pie'),
          conf = this.get('content.component.config.pie'),
          pieChart = [];

      if (pie && conf) {
        pie.forEach(function (value, index) {
          pieChart.push({ value: value, color: conf[index].color });
        });
      }
      return pieChart;
    }),

    computedBreakdown: computed('content_data', function () {
      var _this = this;

      var distribution = this.get('content_data');
      var content = this.get('content');
      var computedBreakdown = [];
      var total = 0;

      if (!distribution) {
        return [];
      }
      distribution.forEach(function (sectionTotal) {
        total += sectionTotal;
      });
      this.set('total', total);
      if (total === 0) {
        this.set('isEmpty', true);
        return [];
      }

      computedBreakdown = distribution.map(function (sectionTotal, index) {
        var sectionPercentage = sectionTotal / total * 100;
        var color = content.component.config.pie[index].color;
        var title = content.component.config.translations[_this.get('currentLanguage')].pie[index].title;

        return {
          color: color,
          percentage: Math.round(sectionPercentage),
          style: 'background-color: ' + color + ';',
          title: title,
          total: sectionTotal
        };
      });

      return computedBreakdown;
    }),

    init: function init() {
      this._super();
      this._queryData();
    },

    reloadObserver: observer('reload', function () {
      this._queryData();
    }),

    _queryData: function _queryData() {
      var _this2 = this;

      this.set('isLoading', true);

      // Get all filters and data from component & get global filters
      var global_filters = this.get('globalFilters.current'),
          report_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('reportFilters')),
          component_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('content.component.filters')),
          dataQueryFilters = global_filters.merge(report_filters, component_filters).toDataQueryFormat(),
          dataQuery = this.get('store').createRecord('data-query'),
          dateRange = {
        from: '',
        to: ''
      };

      // Set date_range if present in filters
      if (dataQueryFilters.start_date && dataQueryFilters.end_date) {
        dateRange.from = moment(dataQueryFilters.start_date[0][0]).format().split('T')[0];
        dateRange.to = moment(dataQueryFilters.end_date[0][0]).format().split('T')[0];
      } else {
        // Set past 30 days if not in fitlers
        var date = new Date();
        dateRange.to = moment(Date.now()).format().split('T')[0];
        date.setDate(date.getDate() - 30);
        dateRange.from = moment(date).format().split('T')[0];
      }

      // Remove date range from filters
      dataQuery.set('date_range', dateRange);
      delete dataQueryFilters.start_date;
      delete dataQueryFilters.end_date;

      dataQuery.set('filters', dataQueryFilters);
      dataQuery.set('properties', this.get('content.component.properties'));
      dataQuery.set('object', 'single-kpi');
      dataQuery.set('tz', _dueDashboardHelpersCurrentTimezone['default'].compute());

      if (this.get('canView')) {
        dataQuery.save().then(function (response) {
          _this2.set('content_data', Object.values(response.get('content_data')[0].attrs.breakdown));
          _this2.set('isLoading', false);
          _this2.set('isEmpty', false);
        })['catch'](function (error) {
          _this2.set('isLoading', false);
          _this2.set('isEmpty', true);
        });
      } else {
        this.set('isEmpty', true);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{single-kpi}} component displaying a main value w/ title + trend indicator + evolution graph
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */