define('due-dashboard/components/api-key-field', ['exports', 'ember'], function (exports, _ember) {

  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({
    classNames: ['api-key-field'],
    readonly: true,
    clicked: false,
    max_input_width: 50,
    timer: 1000,

    afterRender: function afterRender() {
      var _this = this;

      this.set('max_input_width', $(window).width() * this.get('coef')($(window).width()));
      _ember['default'].$(window).on('resize', function () {
        var width = $(window).width();
        _this.set('max_input_width', width * _this.get('coef')(width));
      });
    },

    coef: function coef(w) {
      if (w > 1000) {
        return 0.1;
      } else if (w > 500) {
        return 0.07;
      } else if (w > 300) {
        return 0.06;
      }
      return 0.05;
    },

    lenKey: computed('max_input_width', 'apiKey', function () {
      // this return the size of the input. Tricky function but works like a charm
      var str = this.get('apiKey'),
          l1 = str ? str.length + 0.4 * str.replace(/[^A-Z,m,\_]/g, "").length + 2 : 1,
          l2 = this.get('max_input_width');
      return l1 > l2 ? l2 : l1;
    }),

    actions: {
      clickedAction: function clickedAction() {
        var _this2 = this;

        this.set('clicked', true);
        this.$('.copy-btn')[0].style.backgroundColor = '#2699da';
        run.later(function () {
          _this2.$('.copy-btn')[0].style.backgroundColor = '';
          _this2.set('clicked', false);
        }, this.get('timer'));
      }
    }
  });
});