define('due-dashboard/services/errors', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  var _Ember$computed = _ember['default'].computed;
  var notEmpty = _Ember$computed.notEmpty;
  var not = _Ember$computed.not;
  var computed = _ember['default'].computed;
  exports['default'] = Service.extend({

    errors: [],
    hasErrors: notEmpty('errors'),
    hasNoErrors: not('hasErrors'),
    default_limit: 10,
    updateHook: 0,

    errorKeys: computed('errors.[]', function () {
      return this.get('errors').map(function (error) {
        return error.key;
      });
    }),

    allErrorMessages: computed('errors.[]', function () {
      if (this.get('hasNoErrors')) {
        return null;
      }
      return this.get('errors').slice(0, this.get('default_limit')).map(function (error) {
        return error.error.message;
      }).join("\n");
    }),

    signalChanges: function signalChanges() {
      this.set('updateHook', this.get('updateHook') + 1);
    },

    updateClientErrors: function updateClientErrors() {
      var _this = this;

      this.get('errors').filterBy('side', 'client').map(function (error) {
        if (error.error.get('content.firstObject.model.validations.isValid') || error.error.get('content.firstObject.model.isDeleted')) {
          _this.signalChanges();
          _this.updateErrorByKey(error.key, null);
        }
      });
    },

    resetErrors: function resetErrors() {
      this.set('updateHook', 0);
      this.set('errors', []);
    },

    findErrorByKey: function findErrorByKey(key) {
      return this.get('errors').findBy('key', key) || null;
    },

    findOrCreateErrorByKey: function findOrCreateErrorByKey(key) {
      return this.findErrorByKey(key) || (this.signalChanges() || true) && this.get('errors').addObject({
        key: key,
        error: null,
        side: 'client',
        meta: null
      }).findBy('key', key);
    },

    // return the destroyed error
    destroyErrorByKey: function destroyErrorByKey(key) {
      var error = this.findErrorByKey(key);
      if (!error) {
        return null;
      } else {
        this.get('errors').removeObject(error);
        this.signalChanges();
        return error;
      }
    },

    updateErrorByKey: function updateErrorByKey(key, error, meta) {
      if (error === null || error === undefined) {
        return this.destroyErrorByKey(key);
      } else {
        var elem = this.findOrCreateErrorByKey(key);
        _ember['default'].set(elem, 'error', error);
        _ember['default'].set(elem, 'side', error.content ? 'client' : 'server');
        _ember['default'].set(elem, 'meta', meta);
        this.propertyDidChange('allErrorMessages');
        this.signalChanges();
        return elem;
      }
    },

    getMessageByKey: function getMessageByKey(key) {
      var error = this.findErrorByKey(key);
      if (!error) {
        return null;
      } else {
        if (error.error) {
          if (error.error.message.isDescriptor) {
            return error.error.get('message');
          }
          return error.error.message;
        }
        return 'undefined error';
      }
    }

  });
});