define('due-dashboard/models/segment', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    name: attr('string'),
    position: attr('number'),
    company_segment_values: hasMany('CompanySegmentValue', { async: true }),
    email_alert_scenario_actions_segments: hasMany('emailAlertScenarioActionsSegment')
  }).reopenClass({
    sort: function sort(a, b) {
      if (a.get('position') === b.get('position')) {
        return a.get('name') < b.get('name');
      } else {
        return a.get('position') < b.get('position');
      }
    }
  });
});