define('due-dashboard/components/due-information-popup', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-information-popup'],

    popupStyle: null,

    didRender: function didRender() {
      this._updatePopupPosition();
    },

    /**
     * Update popup postion on the current page
     */
    _updatePopupPosition: function _updatePopupPosition() {
      if (this.element && this.get('title') && !this.get('popupStyle')) {
        var popupProps = this.element.querySelector('.informations-container').getBoundingClientRect(),
            _top = popupProps.height,
            position = this.get('position');
        var popupStyle = 'display: none;';

        if (_top) {
          popupStyle += ' top: -' + _top + 'px;';
        }
        if (position === 'left') {
          popupStyle += ' left: -' + (popupProps.width + 8) + 'px;'; // + 8 for margin-right
        }

        this.set('popupStyle', popupStyle);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{due-information-popup}} popup triggered by hovering element
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */

/**
 * @module due-information-popup
 * @argument {String} title (Optional) Popup title
 * @argument {String} text (Option) Popup text
 */