define('due-dashboard/components/combo-button', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['combo-button'],
    openSurveyOptions: false,
    bubbleUp: true,

    mainButton: computed.alias('buttonActions.firstObject'),

    // prevent drag/drop from parent
    click: function click() {
      return this.get('bubbleUp');
    },

    actions: {
      openSurveyOptions: function openSurveyOptions() {
        this.toggleProperty('openSurveyOptions');
      },

      closeSurveyOptions: function closeSurveyOptions() {
        this.set('openSurveyOptions', false);
      },

      triggerAndClose: function triggerAndClose(triggerAction, actionName) {
        this.send('closeSurveyOptions');
        triggerAction(actionName);
      }
    }
  });
});