define('due-dashboard/components/due-translation-row', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var sort = computed.sort;
  exports['default'] = Component.extend({
    tagName: '',
    tagSort: ['displayName'],
    sortedTags: sort('line.subLines', 'tagSort'),
    computePadding: computed('line.depth', function () {
      return _ember['default'].String.htmlSafe('padding-left:' + this.get('line.depth') * 15 + 'px');
    }),

    lineColor: computed('line.color', function () {
      return _ember['default'].String.htmlSafe('color: ' + this.get('line.color')) || '';
    })
  });
});