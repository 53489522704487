define("due-dashboard/templates/components/segments/single-dropdown", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/segments/single-dropdown.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "segment.name", ["loc", [null, [12, 2], [12, 18]]], 0, 0, 0, 0]],
        locals: ["segment"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/segments/single-dropdown.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "power-select", [], ["options", ["subexpr", "@mut", [["get", "sortedEnabledSegments", ["loc", [null, [2, 10], [2, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selectedSegment", ["loc", [null, [3, 11], [3, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "selectedSegment", ["loc", [null, [4, 24], [4, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 19], [4, 40]]], 0, 0]], [], ["loc", [null, [4, 11], [4, 41]]], 0, 0], "dropdownClass", "survey-dropdown", "allowClear", ["subexpr", "@mut", [["get", "allowClear", ["loc", [null, [6, 13], [6, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "loadingMessage", ["subexpr", "t", ["global.search_segment"], [], ["loc", [null, [7, 17], [7, 44]]], 0, 0], "noMatchesMessage", ["subexpr", "t", ["global.no_segments_matching"], [], ["loc", [null, [8, 19], [8, 52]]], 0, 0], "placeholder", ["subexpr", "t", ["global.choose_segment"], [], ["loc", [null, [9, 14], [9, 41]]], 0, 0], "search", ["subexpr", "action", ["searchSegments"], [], ["loc", [null, [10, 9], [10, 34]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [13, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});