define('due-dashboard/initializers/fragment-serializer', ['exports', 'due-dashboard/serializers/fragment'], function (exports, _dueDashboardSerializersFragment) {
  exports.initialize = initialize;

  function initialize(application) {
    application.register('serializer:date-range', _dueDashboardSerializersFragment['default']);
    application.register('serializer:survey-size', _dueDashboardSerializersFragment['default']);
    application.register('serializer:extremas', _dueDashboardSerializersFragment['default']);
    application.register('serializer:question-score', _dueDashboardSerializersFragment['default']);
    application.register('serializer:satisfaction-score', _dueDashboardSerializersFragment['default']);
    application.register('serializer:recommend-count', _dueDashboardSerializersFragment['default']);
    application.register('serializer:note-count', _dueDashboardSerializersFragment['default']);
    application.register('serializer:satisfaction-history', _dueDashboardSerializersFragment['default']);
    application.register('serializer:satisfaction-point', _dueDashboardSerializersFragment['default']);
    application.register('serializer:comments-history', _dueDashboardSerializersFragment['default']);
    application.register('serializer:stat-comment', _dueDashboardSerializersFragment['default']);
  }

  exports['default'] = {
    name: 'fragment-serializer',
    initialize: initialize
  };
});