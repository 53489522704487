define('due-dashboard/controllers/companies/surveys/edit/layout', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({

    subtitleTranslationKey: computed('previewDevice', function () {
      return 'survey.layout.preview_subtitle_' + this.get('previewDevice');
    }),

    survey_draft: computed.alias('model.survey_draft'),

    logoLoading: false,
    backgroundLoading: false,
    logoError: false,
    clientLogoError: false,
    _logoError: _ember['default'].computed.or('logoError', 'clientLogoError'),
    backgroundError: false,
    clientBackgroundError: false,
    _backgroundError: _ember['default'].computed.or('backgroundError', 'clientBackgroundError'),
    displayBackgroundValidation: false,
    displayLogoValidation: false,
    previewDevice: 'desktop',

    survey_color: computed('survey_draft.survey_color', {
      get: function get() {
        return this.get('survey_draft.survey_color');
      }, set: function set(_, value) {
        this.set('survey_draft.survey_color', value);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDraft'), 200));
        return value;
      }
    }),

    custom_text_color: computed('survey_draft.custom_text_color', {
      get: function get() {
        return this.get('survey_draft.custom_text_color');
      }, set: function set(_, value) {
        this.set('survey_draft.custom_text_color', value);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDraft'), 200));
        return value;
      }
    }),

    custom_box_bg_color: computed('survey_draft.custom_box_bg_color', {
      get: function get() {
        return this.get('survey_draft.custom_box_bg_color');
      }, set: function set(_, value) {
        this.set('survey_draft.custom_box_bg_color', value);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDraft'), 200));
        return value;
      }
    }),

    saveDraft: function saveDraft() {
      if (!this.get('survey_draft')) return;
      this.get('survey_draft').save();
    },

    /* Rating Scales : BEGIN */
    // Builtin rating scales
    builtinScalesSorting: ['scale'],
    builtinScales: computed.sort('model.builtin_scales', 'builtinScalesSorting'),

    // Closed questions rating scale
    initialClosedQuestionsRatingScaleA: computed('model.closed_questions_rating_scale', function () {
      return [this.get('model.closed_questions_rating_scale')];
    }),
    closedQuestionsRatingScales: computed.union('builtinScales', 'initialClosedQuestionsRatingScaleA'),
    selectedClosedQuestionsRatingScale: computed('closedQuestionsRatingScales', 'survey_draft.closed_questions_rating_scale_id', function () {
      return this.get('closedQuestionsRatingScales').findBy('id', this.get('survey_draft.closed_questions_rating_scale_id'));
    }),

    // Would you recommend rating scale
    initialRatingScaleA: computed('model.rating_scale', function () {
      return [this.get('model.rating_scale')];
    }),
    ratingScales: computed.union('builtinScales', 'initialRatingScaleA'),
    selectedRatingScale: computed('ratingScales', 'survey_draft.rating_scale_id', function () {
      return this.get('ratingScales').findBy('id', this.get('survey_draft.rating_scale_id'));
    }),
    /* Rating Scales : END */

    setupPromiseQueue: (function () {
      this.promiseQueue = [];
    }).on('init'),

    updateImage: function updateImage(image_type) {
      this.set('#{image_type}Loading', true);
      this.set('#{image_type}Error', false);
      if (image_type === 'logo') {
        this.set('displayLogoValidation', false);
      } else if (image_type === 'background') {
        this.set('displayBackgroundValidation', false);
      }
      var _this = this;
      this.get('survey_draft').save()['catch'](function () {
        _this.set('#{image_type}Error', true);
      })['finally'](function () {
        _this.set('#{image_type}Loading', false);
        if (image_type === 'logo') {
          _this.set('displayLogoValidation', true);
        } else if (image_type === 'background') {
          _this.set('displayBackgroundValidation', true);
        }
      });
    },

    actions: {

      onImageUpdate: function onImageUpdate(image_type) {
        this.updateImage(image_type);
      },

      onColorUpdate: function onColorUpdate() {
        this.get('survey_draft').save();
      },

      setClosedQuestionsRatingScale: function setClosedQuestionsRatingScale(rating_scale) {
        this.set('survey_draft.closed_questions_rating_scale_id', rating_scale.id);
        this.get('survey_draft').save();
      },

      setRatingScale: function setRatingScale(rating_scale) {
        this.set('survey_draft.rating_scale_id', rating_scale.id);
        this.get('survey_draft').save();
      }

    }
  });
});