define('due-dashboard/routes/settings/users/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var account = this.get('currentAccount.content');
      return account.get('company').then(function (company) {
        return _ember['default'].RSVP.hash({
          existing_groups: _this.store.findAll('account_custom_profile_right'),
          existings_accounts: _this.store.findAll('account'),
          emails: [],
          profile: null,
          company: company,
          languages: _this.store.query('language', { filter: { account_id: _this.currentAccount.content.id } }),
          segments: {},
          available_segments: _this.store.findAll('segment')
        });
      });
    }
  });
});