define("due-dashboard/templates/components/digests/sat-score-resume", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/sat-score-resume.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "score-type-wrapper content-child");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content-child");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content-child");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
          return morphs;
        },
        statements: [["inline", "digests/parts/score-part", [], ["comp", ["subexpr", "@mut", [["get", "comp", ["loc", [null, [3, 34], [3, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "label", "digest.component.score_type", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [3, 91], [3, 106]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 2], [3, 108]]], 0, 0], ["inline", "digests/parts/color-picker-part", [], ["defaultMax", 10, "options", ["subexpr", "@mut", [["get", "comp.options", ["loc", [null, [6, 58], [6, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "current_period_colors", "text", "digest.color_label", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [6, 143], [6, 158]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 2], [6, 160]]], 0, 0], ["inline", "digests/parts/periods-part", [], ["defaultMax", 10, "analysingYear", ["subexpr", "@mut", [["get", "analysingYear", ["loc", [null, [9, 59], [9, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "period", ["subexpr", "@mut", [["get", "period", ["loc", [null, [9, 80], [9, 86]]], 0, 0, 0, 0]], [], [], 0, 0], "comp", ["subexpr", "@mut", [["get", "comp", ["loc", [null, [9, 92], [9, 96]]], 0, 0, 0, 0]], [], [], 0, 0], "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [9, 113], [9, 128]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [9, 2], [9, 130]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 0
            },
            "end": {
              "line": 21,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/sat-score-resume.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "score-type-wrapper content-child");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content-child");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content-child");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
          return morphs;
        },
        statements: [["inline", "digests/parts/score-part", [], ["comp", ["subexpr", "@mut", [["get", "comp", ["loc", [null, [13, 34], [13, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "label", "digest.component.score_type", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [13, 91], [13, 106]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 2], [13, 108]]], 0, 0], ["inline", "digests/parts/color-picker-part", [], ["defaultMax", 100, "options", ["subexpr", "@mut", [["get", "comp.options", ["loc", [null, [16, 59], [16, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "current_period_colors", "text", "digest.color_label", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [16, 144], [16, 159]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 2], [16, 161]]], 0, 0], ["inline", "digests/parts/periods-part", [], ["comp", ["subexpr", "@mut", [["get", "comp", ["loc", [null, [19, 36], [19, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "defaultMax", 100, "analysingYear", ["subexpr", "@mut", [["get", "analysingYear", ["loc", [null, [19, 70], [19, 83]]], 0, 0, 0, 0]], [], [], 0, 0], "period", ["subexpr", "@mut", [["get", "period", ["loc", [null, [19, 91], [19, 97]]], 0, 0, 0, 0]], [], [], 0, 0], "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [19, 114], [19, 129]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 2], [19, 131]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/digests/sat-score-resume.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "eq", [["get", "comp.options.score_type", ["loc", [null, [1, 10], [1, 33]]], 0, 0, 0, 0], "avg"], [], ["loc", [null, [1, 6], [1, 40]]], 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [21, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});