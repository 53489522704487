define('due-dashboard/components/basic-tooltip', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    attributeBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      var message = this.get('message') || "";
      $(this.$().parent()).tooltipster({
        theme: ['tooltipster-basic'],
        content: message.toString(),
        contentAsHTML: true,
        animation: 'grow',
        delay: 150
      });
    },

    languageUpdate: _ember['default'].observer('message', function () {
      $(this.$().parent()).tooltipster('content', this.get('message').toString());
      $(this.$().parent()).tooltipster('reposition');
    })

  });
});
/* global $ */