define('due-dashboard/controllers/settings/groups/edit', ['exports', 'ember'], function (exports, _ember) {
  var alias = _ember['default'].computed.alias;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    profile: alias('model'),
    showWarning: alias('currentAccount.content.company.replicate_to_china'),

    backToIndex: function backToIndex() {
      this.transitionToRoute('settings.groups.index');
    },

    actions: {

      rollback: function rollback() {
        this.model.rollbackToSavepoint();
        this.get('profileApi').reloadSurvey();
        this.backToIndex();
      },

      registerApi: function registerApi(api) {
        this.set('profileApi', api);
      },

      saveGroup: function saveGroup() {
        var _this = this;

        this.get('profile').save().then(function () {
          _this.backToIndex();
        });
      }

    }

  });
});