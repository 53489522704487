define('due-dashboard/components/digests/filters/status-filter', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    classNames: ['time-parent'],

    init: function init() {
      if (!this.get('filter.value')) {
        this.set('filter.value', 'resolved');
      }
      this._super.apply(this, arguments);
    },

    statusOptions: ['resolved', 'unresolved'],

    actions: {

      changeStatus: function changeStatus(status) {
        this.set('filter.value', status);
        this.get('saveDigestDelay')();
      },

      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});