define('due-dashboard/models/activity-sector', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    name: attr('string'),

    translatedName: computed('i18n.locale', 'name', function () {
      return this.get('i18n').t('survey.main.activity_sectors.' + this.get('name')).toString();
    })
  });
});