define('due-dashboard/components/v2/libraries/surveys/navbar/design/-settings', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-design-settings'],

    designOptions: computed('surveyDraft.design_options', function () {
      return this.get('surveyDraft.design_options');
    }),

    actions: {
      cleanCurrentElement: function cleanCurrentElement() {
        this.sendAction('selectCurrentElement', null);
      }

    }
  });
});