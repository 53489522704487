define('due-dashboard/components/satisfaction-donut', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    percentRepartition: computed('anim_note_step', 'stats.recommend_counts', function () {
      var counts = this.get('stats.recommend_counts');
      var data = [];
      var anim_note_step = this.get('anim_note_step');
      function final_data(data) {
        if (anim_note_step === 0) {
          data[0][1] = 0;
          data[1][1] = 0;
        } else if (anim_note_step === 1) {
          var tmp = data[0][1];
          data[0][1] = 0;
          data[2][1] += tmp;
        }
        return data;
      }

      var o = counts ? counts.findBy('profile', 'promoter') : null;
      if (o) {
        data.push([o.get('profile'), o ? o.get('count') : 0]);
      }
      o = counts ? counts.findBy('profile', 'passive') : null;
      if (o) {
        data.push([o.get('profile'), o ? o.get('count') : 0]);
      }
      o = counts ? counts.findBy('profile', 'detractor') : null;
      if (o) {
        data.push([o.get('profile'), o ? o.get('count') : 0]);
      }
      return data.length ? final_data(data) : data;
    }),

    satisfactionDonut: {
      expand: false,
      label: {
        show: false
      },
      width: 8
    },

    tooltip: computed('i18n.locale', function () {
      var _this = this;
      return {
        show: true,
        format: {
          name: function name(value, ratio, id) {
            return _this.get('i18n').t('overview.chart.legend.' + id + 's');
          },
          value: function value(_value, ratio) {
            var percentage = Math.round(ratio * 100);
            return percentage + ' %';
          }
        }
      };
    }),

    formatedSatIndex: computed('totalScoreAnimator.fixedCurrentValue', 'satisfaction_index_100', function () {
      if (this.get('satisfaction_index_100')) {
        return this.get('totalScoreAnimator.fixedCurrentValue').toFixed(0);
      } else {
        return this.get('totalScoreAnimator.fixedCurrentValue');
      }
    }),

    satisfactionData: computed('percentRepartition.[]', function () {
      return {
        type: 'donut',
        columns: this.get('percentRepartition'),
        order: null,
        sort: false,
        colors: {
          detractor: _dueDashboardConfigEnvironment['default'].EmberENV.colors[0],
          passive: _dueDashboardConfigEnvironment['default'].EmberENV.colors[3],
          promoter: _dueDashboardConfigEnvironment['default'].EmberENV.colors[4]
        }
      };
    })
  });
});