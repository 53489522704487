define('due-dashboard/controllers/settings/integrations/facebook', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  /* global FB */

  var alias = _ember['default'].computed.alias;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({
    facebook_it: alias('model.company_integration'),
    hideSave: true,

    actions: {
      auth: function auth() {
        var _this = this;

        FB.login(function (response_1) {
          if (response_1.authResponse) {
            var token = response_1.authResponse.accessToken;
            _this.get('parent').set('isLoading', true);
            location.replace('' + _dueDashboardConfigEnvironment['default'].EmberENV.FACEBOOK_AUTH_URL + _this.get('currentAccount.content.company.id') + '?code=' + token);
          }
        }, { scope: _dueDashboardConfigEnvironment['default'].EmberENV.FACEBOOK_INTEGRATION_SCOPE });
      }
    }

  });
});