define('due-dashboard/models/change-end-trustpilot-scenario-actions-language', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;

  var Validations = (0, _emberCpValidations.buildValidations)({
    text: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = Model.extend(Validations, {
    action: belongsTo('change-end-trustpilot-scenario-action', { async: true }),
    language: belongsTo('language', { async: true }),
    text: attr('string')
  });
});