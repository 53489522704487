define('due-dashboard/routes/settings/integrations/integration-parent', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    templateName: 'settings/integrations/integration-parent',
    includeSurveys: false,
    needApiKey: false,

    model: function model(_, transition) {
      var _this = this;

      var type = transition.targetName.split('.')[2];
      var model = this.modelFor('settings.integrations');
      var includes = this.get('includeSurveys') ? { include: this.get('includeSurveys') } : {};
      var api_key = this.get('needApiKey') ? this.store.findAll('api-key', { reload: true }).then(function (api_keys) {
        return api_keys.findBy('is_active', true) || _this.createApiKey();
      }) : null;
      return _ember['default'].RSVP.hash({
        integration: model.integrations.findBy('name', type),
        account: model.account,
        company: model.company,
        surveys: model.surveys,
        company_integration: this.store.peekAll(type + '-company-integration').toArray()[0] || this.store.query(type + '-company-integration', includes).then(function (a) {
          return a.get('firstObject') || _this.store.createRecord(type + '-company-integration', { company_integration_type: type + '_company_integration' });
        }),
        api_key: api_key
      });
    },

    afterModel: function afterModel(model) {
      if (!model.integration.get('free_access')) {
        this.transitionTo("settings.integrations.index");
      } else {
        this.properPageRedirection(model);
      }
    },

    properPageRedirection: function properPageRedirection(model) {
      if (model.company_integration.get('completed') && model.company_integration.get('type') != 'trustpilot_company_integration') {
        this.transitionTo(this.routeName + '.manage');
      } else {
        this.transitionTo(this.routeName + '.configure');
      }
    },

    createApiKey: function createApiKey() {
      return this.store.createRecord('ApiKey', {
        company: this.modelFor('settings.integrations').company
      }).save();
    },

    actions: {

      didTransition: function didTransition() {
        var configure_controller = this.controllerFor('settings.integrations.' + this.currentModel.integration.get('name') + '.configure', true),
            manage_controller = this.controllerFor('settings.integrations.' + this.currentModel.integration.get('name') + '.manage', true);
        if (configure_controller && !configure_controller.get('parent')) {
          configure_controller.set('parent', _ember['default'].inject.controller('settings.integrations.' + this.currentModel.integration.get('name')));
        }
        if (manage_controller && !manage_controller.get('parent')) {
          manage_controller.set('parent', _ember['default'].inject.controller('settings.integrations.' + this.currentModel.integration.get('name')));
        }
        return true;
      }

    }
  });
});