define('due-dashboard/components/qr-code', ['exports', 'ember'], function (exports, _ember) {

  /* global QRCode */

  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({

    setImage: function setImage(e) {
      this.set('imageData', e.target.src);
    },

    didInsertElement: function didInsertElement() {
      var el = this.$().get(0);
      this._qrcode = new QRCode(el, {
        text: this.get('value'),
        width: 256,
        height: 256
      });
      this.$('img').on('load', _ember['default'].run.bind(this, this.setImage));
    },

    updateCode: observer('value', function () {
      var val = this.get('value');
      this._qrcode.makeCode(val);
    })

  });
});