define('due-dashboard/components/v2/-alert', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['due-alert'],
    isSaving: false,

    iconMap: {
      danger: 'tio-warning_outlined',
      info: 'tio-info_outined',
      success: 'tio-checkmark_circle_outlined',
      locked: 'tio-lock_outlined',
      'delete': 'tio-delete'
    },

    mappedIcon: computed('icon', function () {
      var map = this.get('iconMap');
      var icon = this.get('icon');

      return map[icon];
    }),

    actions: {
      cancelAlert: function cancelAlert(fromCross) {
        var cancelFunc = this.get('cancelCallback');
        var validateFunc = this.get('validationCallback');
        var useValidateFunc = this.get('validationActionOnCloseCross');

        if (fromCross !== true && cancelFunc) cancelFunc();else if (useValidateFunc && validateFunc) validateFunc();else if (!useValidateFunc && cancelFunc) cancelFunc();
      },

      optionalAlert: function optionalAlert() {
        var optionalFunc = this.get('optionalCallback');

        if (optionalFunc) {
          optionalFunc();
        }
      },

      validateAlert: function validateAlert() {
        var validateFunc = this.get('validationCallback');

        if (validateFunc) {
          validateFunc();
          this.set('isSaving', true);
        }
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-alert}} alert component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */