define('due-dashboard/components/answer-modal', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    classNames: ['satisfaction-modal'],
    stepPosition: ['step_relative_position', 'element_relative_position'],
    sortedAnswers: computed.sort('feedback.answers', 'stepPosition'),

    answerList: computed('sortedAnswers', function () {
      var answerList = {};
      this.get('sortedAnswers').forEach(function (answer) {
        if (!answerList[answer.get('step_relative_position')]) {
          answerList[answer.get('step_relative_position')] = [];
        }
        answerList[answer.get('step_relative_position')].push(answer);
      });
      var keys = Object.keys(answerList);
      var values = keys.map(function (v) {
        return { index: v, elements: answerList[v] };
      });
      return values;
    }),

    actions: {
      closeModal: function closeModal() {
        this.get('closeModal')();
      }
    }

  });
});