define('due-dashboard/controllers/companies/custom-stats/show', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  /* global $ */

  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var observer = _ember['default'].observer;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    custom_stats: alias('model.statsPromise.stats'),
    folded: false,

    filters: service(),
    session: service(),

    hasAtLeastOneRow: computed('custom_stats.stats[]', function () {
      if (!this.get('custom_stats.isPending')) {
        return this.get("custom_stats.stats.length") > 0;
      } else {
        return false;
      }
    }),

    segments: computed('baseInfos.segments.[]', 'i18n.locale', function () {
      var _this = this;

      var last_segment_index = this.get('baseInfos.segments.length') - 1;
      var i18n = this.get('i18n');
      var segments = (this.get('baseInfos.segments') || []).map(function (segment, index) {
        if (last_segment_index === index) {
          return {
            key: segment,
            legend: _this.get('i18n').t('custom_stats.level_word') + ' ' + (index + 1) + ' - ' + segment + ' ' + i18n.t('custom_stats.show_all'),
            lvl: index
          };
        } else {
          return {
            key: segment,
            legend: _this.get('i18n').t('custom_stats.level_word') + ' ' + (index + 1) + ' - ' + segment,
            lvl: index
          };
        }
      });
      this.set('segmentLevel', segments[this.get('segment_index')]);
      return segments;
    }),

    segmentIndexSetter: observer('segmentLevel.lvl', function () {
      this.set('segment_index', this.get('segmentLevel.lvl'));
    }),

    baseInfos: computed('model.cusomStatId', function () {
      this.set('segment_index', null);
      return this.store.peekRecord('custom-stat', this.get('model.cusomStatId'));
    }),

    customStatSize: computed(function () {
      return this.store.peekAll('custom-stat').get('length');
    }),

    query_custom_stats: function query_custom_stats() {
      var _this2 = this;

      var filters = this.get('filters').get('queryFilters');
      filters.filter.id = this.get('custom_stats.id');
      var query = this.store.queryRecord('custom-stat', {
        sort: (this.get('custom_stats.sort') || []).join(','),
        filter: filters.filter
      });
      query.then(function () {
        _this2.notifyPropertyChange('model');
        _this2.notifyPropertyChange('xlsxUrl');
      });
      this.set('model.statsPromise.stats', query);
      return query;
    },

    xlsxUrl: computed('baseInfos', function () {
      var filters = this.get('filters.queryFilters.filter');
      var url = _dueDashboardConfigEnvironment['default'].EmberENV.DEFAULT_SCHEME + '://' + _dueDashboardConfigEnvironment['default'].EmberENV.API_HOST + '/api/v3/custom-stats/' + this.get('baseInfos.id') + '.xlsx?';
      url += 'api_key=' + this.get('session.session.authenticated.access_token') + '&';
      url += Object.keys(filters).map(function (key) {
        return 'filter[' + encodeURIComponent(key) + ']=' + encodeURIComponent(filters[key]);
      }).join("&");
      if (this.get('custom_stats.sort') !== undefined) {
        url += '&sort=' + this.get('custom_stats.sort');
      }
      return url;
    }),

    actions: {

      filtersUpdated: function filtersUpdated() {
        return this.query_custom_stats();
      },

      sortAndSearch: function sortAndSearch(key, direction) {
        var current_sort = this.get('custom_stats.sort');
        var old_sort = current_sort.join(',');
        var index = current_sort.indexOf('-' + key);
        index = index === -1 ? current_sort.indexOf('' + key) : index;
        if (index >= 0) {
          current_sort.splice(index, 1);
        }
        current_sort.unshift('' + (direction === 'asc' ? '' : '-') + key);
        if (current_sort.join(',') !== old_sort) {
          this.query_custom_stats();
        }
      },

      exportData: function exportData(url) {
        window.open(url, '_blank');
      },

      lightExportData: function lightExportData(url) {
        window.open(url + '&light=true', '_blank');
      },

      toggleFolding: function toggleFolding() {
        var child_class_matching = '';
        for (var i = 1; i <= 10; i++) {
          child_class_matching += '.custom-stats-table .lvl-' + i;
          if (i !== 10) {
            child_class_matching += ',';
          }
        }
        this.toggleProperty('folded');
        if (this.get('folded')) {
          $(child_class_matching).hide();
        } else {
          $(child_class_matching).show();
        }
      }

    }
  });
});