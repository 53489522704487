define('due-dashboard/components/keyword-badge', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'span',
    classNames: ['keyword-badge'],
    classNameBindings: ['rankClass', 'selected'],

    selected: computed('selectedKeywords.[]', 'keyword', function () {
      return this.get('selectedKeywords').map(function (keyword) {
        return keyword.id;
      }).indexOf(this.get('keyword').id) !== -1;
    }),

    rankClass: computed('keyword.rank', function () {
      return 'rank-' + this.get('keyword.rank');
    }),

    click: function click() {
      if (this.get('action')) {
        this.get('action')(this.get('keyword'));
      }
    }
  });
});