define('due-dashboard/mixins/click-zone', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  // Refactor with click zone view
  exports['default'] = _ember['default'].Mixin.create({
    _isInTarget: function _isInTarget(target, el) {
      var t = el;
      while (t) {
        if (t === target || t.parentNode === null && t.nodeType !== 9 // deleted node (parent is null and node isnt #document) @warning bug when outside clicked is deleted before this call
        ) {
            return true;
          }
        t = t.parentNode;
      }
      return false;
    },

    _handler: function _handler() {
      var targetRoot = this.$().parent().get(0);
      var clickTarget = $(event.target).get(0);
      if (this._isInTarget(targetRoot, clickTarget)) {
        this.send('itemClicked');
      } else {
        this.send('outsideClicked');
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._boundHandler = this._handler.bind(this);
      $(document).on('click', this._boundHandler);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      $(document).off('click', this._boundHandler);
    }

  });
});