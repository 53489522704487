define('due-dashboard/routes/settings/groups', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var account = this.get('currentAccount.content');
      return account.get('company').then(function (company) {
        return _ember['default'].RSVP.hash({
          account_custom_profiles: _this.store.query('account_custom_profile_right', { page: { number: 1, size: 50 } }),
          basic_account_info: _this.store.find('basic_account_info', company.id),
          company: company
        });
      });
    }

  });
});