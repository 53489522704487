define("due-dashboard/helpers/short-number", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Helper.helper(function (number) {
    number = number[0];
    if (number < 1000) {
      return number;
    } else if (number < 100000) {
      return Math.round(number / 100) / 10 + "k";
    } else if (number < 100000000) {
      return Math.round(number / 100000) / 10 + "M";
    } else if (number < 100000000000) {
      return Math.round(number / 100000000) / 10 + "MM";
    } else {
      return 'way too much';
    }
  });
});