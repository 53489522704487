define('due-dashboard/components/feedback-rate-details', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['feedback-rate-detail'],
    classNameBindings: ['hidden'],
    hasMultipleLegends: false,

    hidden: computed.equal('totalCount', 0),

    defaultSelectedA: computed('filteredData', 'selectedKey', function () {
      var _this = this;

      return this.get('filteredData').filter(function (stat) {
        return stat.key === _this.get('selectedKey');
      });
    }),

    selected: computed.oneWay('defaultSelectedA.firstObject'),

    filteredData: computed('data.[]', function () {
      return this.get('data').filter(function (stat) {
        var keep = stat.get('name') !== 'total';
        return keep;
      }).map(function (stat) {
        return {
          count: stat.get('count'),
          key: stat.get('name')
        };
      });
    }),

    totalA: computed.filterBy('data', 'name', 'total'),
    totalCount: computed.alias('totalA.firstObject.count'),

    percentage: computed('selected.count', 'totalCount', function () {
      return (this.get('selected.count') / this.get('totalCount') * 100).toFixed(0);
    })
  });
});