define("due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-gradient", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 4
              },
              "end": {
                "line": 38,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-gradient.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "gradient-element-delete");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "tio-clear");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element1, 'onclick');
            return morphs;
          },
          statements: [["attribute", "onclick", ["subexpr", "action", ["removeColor", ["get", "index", ["loc", [null, [35, 74], [35, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [35, 81]]], 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 0
            },
            "end": {
              "line": 40,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-gradient.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "gradient-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "gradient-element-color");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "gradient-element-stop");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(element2, 5, 5);
          return morphs;
        },
        statements: [["inline", "due-input", [], ["type", "color", "placeholder", "", "value", ["subexpr", "@mut", [["get", "color.value", ["loc", [null, [19, 14], [19, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setColor", ["get", "index", ["loc", [null, [20, 36], [20, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 17], [20, 42]]], 0, 0], "triggerOnChangeOnFocusOut", true], ["loc", [null, [16, 6], [22, 8]]], 0, 0], ["inline", "due-input", [], ["type", "number", "placeholder", "", "value", ["subexpr", "@mut", [["get", "color.stop", ["loc", [null, [28, 14], [28, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "suffixLabel", ["subexpr", "@mut", [["get", "STOP_PERCENT", ["loc", [null, [29, 20], [29, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setStop", ["get", "index", ["loc", [null, [30, 35], [30, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [30, 17], [30, 41]]], 0, 0], "triggerOnChangeOnFocusOut", true], ["loc", [null, [25, 6], [32, 8]]], 0, 0], ["block", "if", [["get", "index", ["loc", [null, [34, 10], [34, 15]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 4], [38, 11]]]]],
        locals: ["color", "index"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 0
            },
            "end": {
              "line": 47,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-gradient.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "gradient-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "gradient-element-add");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("+ ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'onclick');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "onclick", ["subexpr", "action", ["addColor"], [], ["loc", [null, [null, null], [43, 67]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["words.add_color"], [], ["loc", [null, [44, 11], [44, 34]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-gradient.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "due-typo-h6 subdued radio-button-item-title");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "gradient-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "gradient-element-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["words.direction"], [], ["loc", [null, [1, 58], [1, 81]]], 0, 0], ["inline", "component", ["v2/-range-slider"], ["min", 0, "max", 100, "value", ["subexpr", "@mut", [["get", "currentDesignOptions.deg", ["loc", [null, [7, 12], [7, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setDeg"], [], ["loc", [null, [8, 15], [8, 32]]], 0, 0], "suffixLabel", ["subexpr", "@mut", [["get", "DEGREE", ["loc", [null, [9, 18], [9, 24]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 4], [10, 6]]], 0, 0], ["block", "each", [["get", "currentDesignOptions.colors", ["loc", [null, [13, 8], [13, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 0], [40, 9]]]], ["block", "if", [["get", "canAddColor", ["loc", [null, [41, 6], [41, 17]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [41, 0], [47, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});