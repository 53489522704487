define('due-dashboard/components/language-select', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'select',
    classNames: ['language-select'],
    current: computed.readOnly('i18n.locale'),

    available_locales: ['fr', 'en', 'es', 'nl', 'th', 'ko', 'ja', 'zh', 'zht'],

    locales: computed('available_locales', 'i18n', 'current', function () {
      var _this = this;
      return this.get('available_locales').map(function (loc) {
        return { id: loc, text: _this.get('i18n').t('language-select.language.' + loc), selected: loc === _this.get('current') };
      });
    }),

    // It would be nice to do this with `{{action "setLocale" on="change"}}`
    // in the template, but the template doesn't include the component's own
    // tag yet. See https://github.com/emberjs/rfcs/pull/60
    change: function change() {
      this.sendAction('action', this.$().val());
    }
  });
});