define('due-dashboard/models/woocommerce-company-integration', ['exports', 'due-dashboard/models/company-integration', 'ember-data'], function (exports, _dueDashboardModelsCompanyIntegration, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _dueDashboardModelsCompanyIntegration['default'].extend({
    name: 'woocommerce',
    post_confirmed_order_delay_days: attr('number', { defaultValue: 7 }),
    post_confirmed_order_survey: belongsTo('Survey', { async: true }),
    post_confirmed_order_template: belongsTo('template', { async: true }),
    consumer_key: attr(),
    consumer_secret: attr(),
    shop_url: attr()
  });
});