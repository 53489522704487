define('due-dashboard/components/sortable-list-actions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    modalOn: false,

    classNames: ['sortable-list-actions'],
    classNameBindings: ['direction'],
    direction: 'bottom',

    // prevent drag/drop from parent
    mouseDown: function mouseDown() {
      return false;
    },

    actions: {
      open: function open() {
        var opened = this.toggleProperty('modalOn');
        if (opened) {
          if (this.$()[0].classList.contains('last') || window.$('body').width() - this.$().offset().left < 250) {
            this.$('ul').css({ right: 0 });
          }
        } else {
          this.$('ul').css({ right: '' });
        }
      },

      cancel: function cancel() {
        if (!this.isDestroyed) {
          this.set('modalOn', false);
          this.$('ul').css({ right: '' });
        }
      },

      sort: function sort(asc_or_desc) {
        this.send('cancel');
        this.get('sort')(this.get('on'), asc_or_desc);
      },

      'delete': function _delete() {
        this.send('cancel');
        this.get('delete')(this.get('on'));
      }
    }
  });
});