define('due-dashboard/components/click-zone', ['exports', 'ember', 'due-dashboard/mixins/click-zone'], function (exports, _ember, _dueDashboardMixinsClickZone) {
  exports['default'] = _ember['default'].Component.extend(_dueDashboardMixinsClickZone['default'], {
    actions: {
      itemClicked: function itemClicked() {
        var act = this.get('click-inside');
        if (act) {
          act();
        }
      },
      outsideClicked: function outsideClicked() {
        var act = this.get('click-outside');
        if (act) {
          act();
        }
      }
    }
  });
});