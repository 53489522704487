define('due-dashboard/components/due-translation', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var sort = computed.sort;
  exports['default'] = Component.extend({
    tagSort: ['displayName'],
    companyLanguageTag: computed.alias('currentAccount.content.company.language.tag'),
    remainingLanguages: computed.setDiff('languages', 'selectedLanguages'),
    showAddLanguage: computed.notEmpty('remainingLanguages'),
    sortedTags: sort('data', 'tagSort'),

    actions: {
      addLanguage: function addLanguage(language) {
        this.get('addLanguage')(language);
      },

      removeLanguage: function removeLanguage(language) {
        this.get('removeLanguage')(language);
      },

      cancelAction: function cancelAction() {
        this.get('cancelAction')();
      },

      saveTranslation: function saveTranslation() {
        this.get('saveTranslation')();
      }
    }

  });
});