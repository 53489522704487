define('due-dashboard/mixins/survey', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create({
    prefix: 'companies.surveys.edit', //??

    currentStep: null,
    stepGroups: [{
      name: 'timeline.survey',
      steps: ['main', 'layout', 'summary']
    }, {
      name: 'timeline.distribution',
      steps: [['publishing'], ['mailing-external', 'mailing-qrcode', 'bulk-dispatch']]
    }],

    activate: function activate() {
      this.controllerFor('application').addObserver('currentPath', this, this.currentPathChanged);
    },

    currentPathChanged: function currentPathChanged(ctrl, key) {
      var route = ctrl.get(key);
      var needle = this.routeName + '.';
      if (route.indexOf(needle) === 0) {
        var step = route.replace(needle, '');
        step = step.split('.').get('firstObject');
        if (step === 'index') {
          step = this.get('stepGroups.firstObject.steps.firstObject');
        }
        this.set('currentStep', step);
        _ember['default'].set(this.currentModel, 'currentStep', this.get('currentStep'));
        _ember['default'].set(this.currentModel, 'stepGroups', this.get('computedStepGroups'));
      }
    },

    deactivate: function deactivate() {
      this.controllerFor('application').removeObserver('currentPath', this, this.currentPathChanged);
    },

    stepIndex: function stepIndex(step) {
      var stepGroups = this.get('stepGroups');
      var i = 0;
      for (var gi = 0; gi < stepGroups.length; ++gi) {
        var group = stepGroups[gi];
        var si = group.steps.indexOf(step);
        if (si !== -1) {
          return i + si;
        } else {
          for (var _i = 0; _i < group.steps.length; ++_i) {
            var gsteps = group.steps[_i];
            if (Array.isArray(gsteps) && gsteps.indexOf(step) !== -1) {
              return i + _i;
            }
          }
          i += group.steps.length;
        }
      }
      throw "Step not found: " + step;
    },

    computedStepGroups: computed('stepGroups', 'currentStep', function () {
      // Move this in controller with currentStep
      var self = this;
      var stepGroups = this.get('stepGroups');
      var currentStep = this.get('currentStep');
      if (!currentStep) {
        return [];
      }
      var currentStepIndex = this.stepIndex(currentStep);
      return stepGroups.map(function (group) {
        return {
          name: group.name,
          isCurrentGroup: group.steps.indexOf(currentStep) !== -1,
          isNotLastGroup: group !== stepGroups.get('lastObject'),
          steps: group.steps.map(function (step) {
            var stepIndex = self.stepIndex(step);
            return {
              name: step,
              isCurrent: currentStepIndex === stepIndex,
              isPrevious: currentStepIndex > stepIndex,
              isFuture: currentStepIndex < stepIndex
            };
          })
        };
      });
    })

  });
});