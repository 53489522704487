define('due-dashboard/components/daterange-picker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    classNames: ['due-daterange-picker'],
    classNameBindings: ['startDateState'],

    // minDate: null,
    // maxDate: null,

    // internals
    startDateState: true,
    internalStartDate: computed.oneWay('startDate'),
    internalEndDate: computed.oneWay('endDate'),

    pikadayMinDate: computed('minDate', 'startDate', function () {
      return (0, _moment['default'])(this.get('minDate')).max(this.get('startDate')).toDate();
    }),

    pikadayMaxDate: computed('maxDate', 'endDate', function () {
      return (0, _moment['default'])(this.get('maxDate')).min(this.get('endDate')).toDate();
    }),

    pikaday: null,

    datesChanged: observer('startDate', 'endDate', function () {
      this.set('internalStartDate', this.get('startDate'));
      this.set('internalEndDate', this.get('endDate'));
      this.redrawPikaday();
    }),

    cleanStartDate: computed('internalStartDate', function () {
      return (0, _moment['default'])(this.get('internalStartDate')).startOf('day').utcOffset((0, _moment['default'])().utcOffset(), true).toDate();
    }),
    cleanEndDate: computed('internalEndDate', function () {
      return (0, _moment['default'])(this.get('internalEndDate')).startOf('day').utcOffset((0, _moment['default'])().utcOffset(), true).toDate();
    }),

    redrawPikaday: function redrawPikaday() {
      if (this.get('pikaday')) {
        var finalOffsetStart = (0, _moment['default'])().utcOffset() + this.get('cleanStartDate').getTimezoneOffset();
        var displayDateStart = new Date(this.get('cleanStartDate').getTime() + finalOffsetStart * 60 * 1000);
        if (this.get('internalStartDate')) {
          this.get('pikaday').setStartRange(displayDateStart);
          if (this.get('internalEndDate')) {
            var finalOffsetEnd = (0, _moment['default'])().utcOffset() + this.get('cleanEndDate').getTimezoneOffset();
            var displayDateEnd = new Date(this.get('cleanEndDate').getTime() + finalOffsetEnd * 60 * 1000);
            this.get('pikaday').setEndRange(displayDateEnd);
            this.get('pikaday').gotoDate(displayDateEnd);
          } else {
            this.get('pikaday').setMinDate(displayDateStart);
            this.get('pikaday').gotoDate(displayDateStart);
          }
        }
      }
    },

    actions: {
      pickStartDate: function pickStartDate(pickedDate) {
        this.set('internalStartDate', pickedDate);
        this.set('internalEndDate', null);
        this.toggleProperty('startDateState');
      },

      pickEndDate: function pickEndDate(pickedDate) {
        this.set('internalEndDate', pickedDate);
        this.toggleProperty('startDateState');
        if (this.get('rangePicked')) {
          this.get('rangePicked')(this.get('internalStartDate'), (0, _moment['default'])(this.get('internalEndDate')).endOf('day').toDate());
        }
      },

      registerPikaday: function registerPikaday(pikaday) {
        this.set('pikaday', pikaday);
        this.redrawPikaday();
      }
    }
  });
});