define("due-dashboard/templates/companies/scenarios/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 6
                },
                "end": {
                  "line": 8,
                  "column": 6
                }
              },
              "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element9, 'class');
              morphs[1] = dom.createElementMorph(element9);
              morphs[2] = dom.createMorphAt(element9, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [5, 24], [5, 36]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["addScenario"], [], ["loc", [null, [5, 39], [5, 63]]], 0, 0], ["content", "button.text", ["loc", [null, [6, 10], [6, 25]]], 0, 0, 0, 0]],
            locals: ["button"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 9,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "due-button", [], ["type", "transition", "icon", "plus", "text", ["subexpr", "t", ["settings.scenarios.create_scenario_button"], [], ["loc", [null, [4, 55], [4, 102]]], 0, 0]], 0, null, ["loc", [null, [4, 6], [8, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "actions");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["get", "canCreateScenario", ["loc", [null, [3, 15], [3, 32]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "currentAccount.isFromChina", ["loc", [null, [3, 38], [3, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 33], [3, 65]]], 0, 0]], [], ["loc", [null, [3, 10], [3, 66]]], 0, 0]], [], 0, null, ["loc", [null, [3, 4], [9, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 6
            },
            "end": {
              "line": 18,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", [["subexpr", "concat", ["settings.scenarios.filters.", ["get", "option.key", ["loc", [null, [17, 50], [17, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 12], [17, 61]]], 0, 0]], [], ["loc", [null, [17, 8], [17, 63]]], 0, 0]],
        locals: ["option"],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 24,
                      "column": 14
                    },
                    "end": {
                      "line": 32,
                      "column": 14
                    }
                  },
                  "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "on-mobile m-handle");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createElement("i");
                  dom.setAttribute(el3, "class", "fa fa-sort-asc");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createElement("i");
                  dom.setAttribute(el3, "class", "fa fa-sort-desc");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "handle");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("..");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("br");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("..");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("br");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("..");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("br");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("..");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("br");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("..");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("br");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("..");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var element3 = dom.childAt(element2, [1]);
                  var element4 = dom.childAt(element2, [3]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element3);
                  morphs[1] = dom.createElementMorph(element4);
                  return morphs;
                },
                statements: [["element", "action", ["switchPosition", ["get", "scenario", ["loc", [null, [26, 50], [26, 58]]], 0, 0, 0, 0], -1], [], ["loc", [null, [26, 24], [26, 63]]], 0, 0], ["element", "action", ["switchPosition", ["get", "scenario", ["loc", [null, [27, 50], [27, 58]]], 0, 0, 0, 0], 1], [], ["loc", [null, [27, 24], [27, 62]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 37,
                      "column": 16
                    },
                    "end": {
                      "line": 41,
                      "column": 16
                    }
                  },
                  "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "stats");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "scenario-stats", [], ["scenario_id", ["subexpr", "@mut", [["get", "scenario.id", ["loc", [null, [39, 49], [39, 60]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [39, 20], [39, 62]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 20
                    },
                    "end": {
                      "line": 46,
                      "column": 20
                    }
                  },
                  "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1, "class", "text");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element1);
                  morphs[1] = dom.createMorphAt(element1, 0, 0);
                  return morphs;
                },
                statements: [["element", "action", ["editScenario", ["get", "scenario", ["loc", [null, [45, 54], [45, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 30], [45, 64]]], 0, 0], ["inline", "t", ["settings.scenarios.edit_scenario_button"], [], ["loc", [null, [45, 78], [45, 125]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child3 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 49,
                      "column": 20
                    },
                    "end": {
                      "line": 53,
                      "column": 20
                    }
                  },
                  "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1, "class", "delete");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("i");
                  dom.setAttribute(el2, "class", "fa fa-trash");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element0);
                  return morphs;
                },
                statements: [["element", "action", ["deleteScenario", ["get", "scenario", ["loc", [null, [50, 56], [50, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [50, 30], [50, 66]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 12
                  },
                  "end": {
                    "line": 57,
                    "column": 12
                  }
                },
                "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "info");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("h3");
                var el4 = dom.createElement("i");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "actions");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "left");
                var el4 = dom.createTextNode("\n");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("                  ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "right");
                var el4 = dom.createTextNode("\n");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("                  ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [2]);
                var element6 = dom.childAt(element5, [1, 1]);
                var element7 = dom.childAt(element6, [0]);
                var element8 = dom.childAt(element5, [5]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createAttrMorph(element5, 'class');
                morphs[2] = dom.createAttrMorph(element7, 'class');
                morphs[3] = dom.createMorphAt(element6, 2, 2);
                morphs[4] = dom.createMorphAt(element5, 3, 3);
                morphs[5] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
                morphs[6] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "and", [["get", "canEditScenario", ["loc", [null, [24, 25], [24, 40]]], 0, 0, 0, 0], ["get", "canSwitchPosition", ["loc", [null, [24, 41], [24, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 20], [24, 59]]], 0, 0]], [], 0, null, ["loc", [null, [24, 14], [32, 21]]]], ["attribute", "class", ["concat", ["details ", ["subexpr", "unless", [["subexpr", "and", [["get", "canEditScenario", ["loc", [null, [33, 48], [33, 63]]], 0, 0, 0, 0], ["get", "canSwitchPosition", ["loc", [null, [33, 64], [33, 81]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 43], [33, 82]]], 0, 0], "no-switch"], [], ["loc", [null, [33, 34], [33, 96]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["fa fa-", ["get", "scenario.categoryIcon", ["loc", [null, [35, 40], [35, 61]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "scenario.name", ["loc", [null, [35, 70], [35, 87]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "scenario.category_hook_key", ["loc", [null, [37, 26], [37, 52]]], 0, 0, 0, 0], "survey_end"], [], ["loc", [null, [37, 22], [37, 66]]], 0, 0]], [], 1, null, ["loc", [null, [37, 16], [41, 23]]]], ["block", "if", [["subexpr", "and", [["get", "canEditScenario", ["loc", [null, [44, 31], [44, 46]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "currentAccount.isFromChina", ["loc", [null, [44, 52], [44, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 47], [44, 79]]], 0, 0]], [], ["loc", [null, [44, 26], [44, 80]]], 0, 0]], [], 2, null, ["loc", [null, [44, 20], [46, 27]]]], ["block", "if", [["subexpr", "and", [["get", "canDeleteScenario", ["loc", [null, [49, 31], [49, 48]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "currentAccount.isFromChina", ["loc", [null, [49, 54], [49, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [49, 49], [49, 81]]], 0, 0]], [], ["loc", [null, [49, 26], [49, 82]]], 0, 0]], [], 3, null, ["loc", [null, [49, 20], [53, 27]]]]],
              locals: [],
              templates: [child0, child1, child2, child3]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 10
                },
                "end": {
                  "line": 58,
                  "column": 10
                }
              },
              "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "sortable-item", [], ["tagName", "li", "model", ["subexpr", "@mut", [["get", "scenario", ["loc", [null, [23, 48], [23, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "group", ["subexpr", "@mut", [["get", "group", ["loc", [null, [23, 63], [23, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "handle", ".handle", "class", ["subexpr", "@mut", [["get", "scenario.dasherizedHookKey", ["loc", [null, [23, 92], [23, 118]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [23, 12], [57, 30]]]]],
            locals: ["scenario"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 6
              },
              "end": {
                "line": 59,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "filteredScenarios", ["loc", [null, [22, 18], [22, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 10], [58, 19]]]]],
          locals: ["group"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 4
            },
            "end": {
              "line": 60,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "sortable-group", [], ["class", "scenario-list", "tagName", "ol", "model", ["subexpr", "@mut", [["get", "model.company", ["loc", [null, [21, 65], [21, 78]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", "reorderScenarios"], 0, null, ["loc", [null, [21, 6], [59, 25]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 4
            },
            "end": {
              "line": 64,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["settings.scenarios.no_scenarios"], [], ["loc", [null, [62, 8], [62, 47]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 66,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/companies/scenarios/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "scenarios-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "scenarios");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "intro");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "filter");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [1, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(element10, 5, 5);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "dashboard-title", [], [], 0, null, ["loc", [null, [1, 0], [11, 20]]]], ["inline", "t", ["settings.scenarios.subtitle"], [], ["loc", [null, [14, 21], [14, 56]]], 0, 0], ["block", "button-group", [], ["options", ["subexpr", "@mut", [["get", "filterOption", ["loc", [null, [16, 30], [16, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "scenarioFilter", ["loc", [null, [16, 52], [16, 66]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [16, 6], [18, 23]]]], ["block", "if", [["get", "filteredScenarios", ["loc", [null, [20, 10], [20, 27]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [20, 4], [64, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});