define("due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/-group", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/-group.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "element-config-container group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "labeled-input-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "due-typo-h6");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "labeled-switch");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "due-typo-default");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [5]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["words.elements_displayed"], [], ["loc", [null, [3, 30], [3, 62]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "object.content.threshold", ["loc", [null, [5, 12], [5, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "number", "onChange", ["subexpr", "action", ["updateContentField", "threshold"], [], ["loc", [null, [7, 15], [7, 56]]], 0, 0], "focused", true, "placeholder", ["subexpr", "t", ["words.limit"], [], ["loc", [null, [9, 18], [9, 35]]], 0, 0]], ["loc", [null, [4, 4], [10, 6]]], 0, 0], ["inline", "component", ["v2/-switch"], ["on", ["subexpr", "@mut", [["get", "object.content.randomize_elements", ["loc", [null, [12, 34], [12, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "onSwitch", ["subexpr", "action", ["toggleContentField", "randomize_elements"], [], ["loc", [null, [12, 77], [12, 127]]], 0, 0]], ["loc", [null, [12, 6], [12, 129]]], 0, 0], ["inline", "t", ["words.randomize_order"], [], ["loc", [null, [13, 37], [13, 66]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});