define('due-dashboard/controllers/settings', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Controller.extend({
    displayMenu: false,
    navbarDisplayed: true,
    sidebar: service(),

    init: function init() {
      this.set('sidebar.dashboardSection', false);
      this.set('sidebar.settingsSection', true);
    },

    version: computed('currentAccount.content.company.enable_new_survey_dashboard', function () {
      return this.get('currentAccount.content.company.enable_new_survey_dashboard') ? 'v2' : '';
    }),

    actions: {
      toggleMenu: function toggleMenu() {
        this.toggleProperty('displayMenu');
      }
    }
  });
});