define("due-dashboard/components/country-picker", ["exports", "ember", "ember-countries"], function (exports, _ember, _emberCountries) {
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Component.extend({
    availableCountries: computed(function () {
      return _emberCountries.COUNTRIES_LIST;
    }),

    selectedCountries: computed("availableCountries", "countries", function () {
      var countries = this.get("countries") || [];

      return this.get("availableCountries").filter(function (country) {
        return countries.indexOf(country.iso2) > -1;
      });
    }),

    selectedCountry: computed("availableCountries", "countries", function () {
      var selected_country = this.get("country") || {};

      return this.get("availableCountries".find(function (country) {
        return country.iso2 === selected_country;
      }));
    }),

    actions: {
      updateCountry: function updateCountry(country) {
        this.set("country", country.mapBy("iso2"));
      },

      updateCountries: function updateCountries(countries) {
        this.set("countries", countries.mapBy("iso2"));
      }
    }
  });
});