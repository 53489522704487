define('due-dashboard/serializers/data-query', ['exports', 'due-dashboard/serializers/application'], function (exports, _dueDashboardSerializersApplication) {
  exports['default'] = _dueDashboardSerializersApplication['default'].extend({

    keyForAttribute: function keyForAttribute(key) {
      return key;
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      return json;

      // Not needed anymore
      // const filters = json.data.attributes.filters;

      // if (!filters) { return json; }

      // const tag_sentiments = filters.tags_sentiments || [];

      // for (const ts_set of tag_sentiments) {
      //   for (const [ts_index, ts] of ts_set.entries()) {
      //     if (ts.indexOf('_') !== -1) { continue; }

      //     ts_set[ts_index] = `${ts}_allfeedback`;
      //   }
      // }
    }
  });
});