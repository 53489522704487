define('due-dashboard/models/generic-js-scenario-action', ['exports', 'due-dashboard/models/scenario-action', 'ember-data', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioAction, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    js_method: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports['default'] = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    js_method: attr('string')
  });
});