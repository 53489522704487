define('due-dashboard/components/breakdown-table', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var sort = computed.sort;
  exports['default'] = Component.extend({
    tags: [],
    root: undefined,
    tagSort: ['feedbacks_count_incl_subtags:desc'],
    tagId: undefined,

    realTags: computed('tags', 'tagId', function () {
      var tags = this.get('tags');
      if (tags && tags.length) {
        // tree-ify tags + add depths + clean name
        var tags_tree = this._listToTree(tags);
        // compute displayed_depths and totals with subtags included
        return tags_tree;
      }
      return [];
    }),

    sortedTags: sort('realTags', 'tagSort'),

    _listToTree: function _listToTree(list) {
      var map = {},
          tree = [],
          i = undefined;
      // find depth, parentName, displayname, init subtags
      var initialDepth = this.get('tagId') !== undefined ? list[0].name.split(' > ').length - 1 : 0;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].name] = i;
        var splitName = list[i].name.split(' > ');
        list[i].depth = splitName.length - 1;
        list[i].displayed_depth = list[i].depth - initialDepth;
        list[i].percent = Math.round(list[i].count_percent * 100);
        list[i].parentName = splitName.slice(0, -1).join(' > ');
        list[i].subTags = [];
        list[i].displayName = splitName[list[i].depth];
      }
      // assign subtags
      for (i = 0; i < list.length; i += 1) {
        if (map[list[i].parentName] !== undefined) {
          list[map[list[i].parentName]].subTags.push(list[i]);
        }
      }
      // add roots to tree
      for (i = 0; i < list.length; i += 1) {
        if (map[list[i].parentName] === undefined) {
          tree.push(list[i]);
        }
      }
      return tree;
    },

    actions: {}
  });
});