define('due-dashboard/components/change-end-trustpilot-scenario-action', ['exports', 'ember', 'due-dashboard/components/scenario-action-item'], function (exports, _ember, _dueDashboardComponentsScenarioActionItem) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var mapBy = computed.mapBy;
  var setDiff = computed.setDiff;
  var gt = computed.gt;
  var sort = computed.sort;
  exports['default'] = _dueDashboardComponentsScenarioActionItem['default'].extend({
    segments: [],
    review_types: ['Service', 'Product', 'Location'],

    _searchSegments: function _searchSegments(term, resolve, reject) {
      this.store.query('segment', this.query_filter(term)).then(function (segments) {
        return resolve(segments);
      }, reject);
    },

    isProductReview: computed('action.review_type', function () {
      return this.get("action.review_type") == 'Product' ? true : false;
    }),

    isLocationReview: computed('action.review_type', function () {
      return this.get("action.review_type") == 'Location' ? true : false;
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this._searchSegments('', function (segments) {
        _this.set('segments', segments);
      });
      this.store.findAll('language', { reload: true }).then(function (languages) {
        _this.set('languages', languages);
        if (_this.get('selectedLanguages').length === 0) {
          _this.addLanguage(languages.findBy('tag', _this.get('i18n.locale').toUpperCase()));
        } else {
          _this.get('selectedLanguages').forEach(function (language) {
            _this.get('text_error_key').set(language.get('tag'), 'change_end_link_text-' + language.get('tag') + '-text');
          });
        }
      });
    },

    /*
    ** Error handling
    */

    text_error_key: new _ember['default'].Object(),
    has_many_language: true,
    language_tab_error_regexp: /change_end_link_text-[A-Z]+-[a-z]+/,

    errorObserverVars: {
      array_key: 'action.actions_languages',
      error_prefix: 'change_end_link_text',
      error_suffix: 'language.tag',
      single_error_target: 'action.validations.attrs.message.error',
      error_target: {
        'text': 'validations.attrs.link_text.error'
      }
    },

    error_keys: computed(function () {
      return ['change-end-link'].concat(this.get('errors.errorKeys').filter(function (a) {
        return !!a.match(/change_end_link_text-[A-Z]/);
      }));
    }).volatile(),

    customize_color: computed('action.button_color', function () {
      return this.get('action.button_color') !== null && this.get('action.button_color') !== undefined;
    }),

    errorObserver: observer('try_step', function () {
      var _this2 = this;

      var _get = this.get('errorObserverVars');

      var error_prefix = _get.error_prefix;
      var error_suffix = _get.error_suffix;
      var error_target = _get.error_target;
      var array_key = _get.array_key;

      this.get(array_key).map(function (elem) {
        elem.validate().then(function () {
          ['text', 'link'].forEach(function (attr) {
            _this2.get('errors').updateErrorByKey(error_prefix + '-' + elem.get(error_suffix) + '-' + attr, elem.get(error_target[attr]), elem.get(error_suffix));
          });
        });
      });
    }),

    actLanguageSort: ['language.tag'],
    languageSort: ['tag'],
    orderedActionLanguages: sort('action.actions_languages', 'actLanguageSort'),
    _selectedLanguages: mapBy('action.actions_languages', 'language.content'),
    _remainingLanguages: setDiff('languages', 'selectedLanguages'),
    selectedLanguages: sort('_selectedLanguages', 'languageSort'),
    remainingLanguages: sort('_remainingLanguages', 'languageSort'),
    canDeleteLanguage: gt('action.actions_languages.length', 1),

    addLanguage: function addLanguage(language) {
      var action = this.get('action');
      var at = this.store.createRecord('change-end-trustpilot-scenario-actions-language', {
        action: action,
        language: language,
        button_color: this.get('action.actions_languages.firstObject.button_color')
      });
      this.get('text_error_key').set(language.get('tag'), 'change_end_link_text-' + language.get('tag') + '-text');
      action.get('actions_languages').addObject(at);
      this.set('selectedLanguage', at);
    },

    removeLanguage: function removeLanguage(language) {
      this.get('errors').updateErrorByKey('change_end_link_text-' + language.get('tag') + '-text', null);
      var index = this.get('orderedActionLanguages').indexOf(language);
      this.get('action.actions_languages').removeObject(language);
      if (this.get('selectedLanguage.language.tag') === language.get('language.tag')) {
        this.set('selectedLanguage', this.get('orderedActionLanguages').toArray()[index] || this.get('orderedActionLanguages').toArray()[index - 1]);
      }
      language.deleteRecord();
    },

    query_filter: function query_filter() {
      var term = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

      return { filter: { name: term, all: true, display: 'filter' } };
    },

    actions: {
      openLanguageList: function openLanguageList() {
        this.set('displayLanguageList', true);
      },

      closeLanguageList: function closeLanguageList() {
        this.set('displayLanguageList', false);
      },

      addLanguage: function addLanguage(language) {
        this.set('displayLanguageList', false);
        this.addLanguage(language);
      },

      removeLanguage: function removeLanguage(language) {
        this.removeLanguage(language);
      },

      toggleColorCustomize: function toggleColorCustomize() {
        if (this.get('customize_color') && this.get('action.button_color')) {
          this.set('action.button_color', null);
        } else {
          this.toggleProperty('customize_color');
        }
      },

      searchSegments: function searchSegments(term) {
        var _this3 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this3, _this3._searchSegments, term, resolve, reject, 600);
        });
      }
    }

  });
});