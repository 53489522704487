define('due-dashboard/components/help-zone', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    errors: inject.service(),

    // classNames: ['help-zone'],
    defaultKey: null,

    text: computed('key.name', 'defaultKey', function () {
      var key = undefined;
      if (typeof this.get('key') === "string") {
        key = this.get('key');
      } else {
        key = this.get('key.name');
      }
      if (_ember['default'].isEmpty(key)) {
        key = this.get('defaultKey');
      }
      return _ember['default'].isEmpty(key) ? '' : key;
    })

  });
});