define('due-dashboard/routes/companies/surveys/edit/main', ['exports', 'ember', 'due-dashboard/mixins/survey-step'], function (exports, _ember, _dueDashboardMixinsSurveyStep) {
  exports['default'] = _ember['default'].Route.extend(_dueDashboardMixinsSurveyStep['default'], {

    stepConfig: {
      layout: 'preview'
    },

    model: function model() {
      return _ember['default'].RSVP.hash({
        survey: this.modelFor('companies.surveys.edit').survey,
        surveyDraft: this.modelFor('companies.surveys.edit').surveyDraft,
        languages: this.store.findAll('language'),
        activity_sectors: this.store.findAll('activity-sector'),
        selectedLanguage: null,
        isSaving: false
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('controller.model.isSaving')) {
          if (!confirm(this.get('i18n').t('survey.is_saving'))) {
            transition.abort();
            return false;
          }
        }
        return true;
      }
    },

    draft: _ember['default'].computed.alias('currentModel.survey.draft_revision')

  });
});