define('due-dashboard/controllers/notification-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      itemClicked: function itemClicked() {
        this.toggleProperty('opened');
      },

      outsideClicked: function outsideClicked() {
        this.set('opened', false);
      }
    }
  });
});