define("due-dashboard/translations/pt", ["exports"], function (exports) {
  exports["default"] = {
    "pt": {
      "survey_preview": {
        "social": {
          "title": "Obrigado!"
        }
      }
    }
  };
});