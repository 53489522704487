define('due-dashboard/components/web-snippet', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['web-snippet'],

    iframeSrc: _ember['default'].computed('selectedLanguage', function () {
      return _dueDashboardConfigEnvironment['default'].EmberENV.SNIPPET_URL + '/' + this.get('model.id') + '?locale=' + this.get('selectedLanguage.tag');
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.store.findAll('language').then(function (languages) {
        _this.set('languages', languages);
        _this.set('selectedLanguage', languages.content[0]._data);
      });
    },

    actions: {
      updateLanguage: function updateLanguage(language) {
        this.set('selectedLanguage', language);
      }
    }

  });
});