define('due-dashboard/models/tag-analysis', ['exports', 'ember-data'], function (exports, _emberData) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var _Ember = Ember;
  var computed = _Ember.computed;
  exports['default'] = Model.extend({
    all: attr(),
    with_tags: attr(),
    without_tags: attr(),
    without_tags_with_comment: attr(),
    without_tags_without_comment: attr(),
    tags: attr(),

    // require to add key and 'all' to watched properties
    percent: function percent(key) {
      return Math.round(this.get(key).count * 100 / this.get('all').count);
    },

    // require to add key and 'all' to watched properties
    repartition: function repartition(key) {
      return _extends({}, this.get(key), { percent: this.percent(key) });
    },

    repartition_all: computed('all', function () {
      return _extends({}, this.get('all'), { percent: 100 });
    }),

    repartition_with_tags: computed('all', 'with_tags', function () {
      return this.repartition('with_tags');
    }),

    repartition_without_tags: computed('all', 'without_tags', function () {
      return this.repartition('without_tags');
    }),

    repartition_without_tags_with_comment: computed('all', 'without_tags_with_comment', function () {
      return this.repartition('without_tags_with_comment');
    }),

    repartition_without_tags_without_comment: computed('all', 'without_tags_without_comment', function () {
      return this.repartition('without_tags_without_comment');
    })
  });
});