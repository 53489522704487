define('due-dashboard/routes/companies/scenarios', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var account = this.get('currentAccount.content');
      return account.get('company').then(function (company) {
        return _ember['default'].RSVP.hash({
          account: account,
          company: company,
          scenarios: _this.store.findAll('scenario').then(function (a) {
            return a.sortBy('position');
          })
        });
      });
    },

    actions: {

      scenarioAdded: function scenarioAdded(scenario) {
        this.currentModel.scenarios.addObject(scenario);
      },

      deleteScenario: function deleteScenario(scenario) {
        var _this2 = this;

        var message = this.get('i18n').t('settings.scenarios.confirm_delete');
        if (window.confirm(message)) {
          scenario.destroyRecord().then(function () {
            _this2.currentModel.scenarios.removeObject(scenario);
          });
        }
      },

      addScenario: function addScenario() {
        this.transitionTo('companies.scenarios.new');
      },

      editScenario: function editScenario(scenario) {
        this.transitionTo('companies.scenarios.edit', scenario.get('id'));
      },

      reorderScenarios: function reorderScenarios(company, reorderedScenarios, movedScenario) {
        var _this3 = this;

        this.currentModel.scenarios.setObjects(reorderedScenarios);
        var arrayPosition = reorderedScenarios.indexOf(movedScenario);
        var newPosition = null;
        if (arrayPosition === 0) {
          newPosition = 0;
        } else {
          newPosition = reorderedScenarios[arrayPosition - 1].get('position') + 1;
        }
        movedScenario.set('position', newPosition);
        movedScenario.save({ adapterOptions: { reorder: true } }).then(function () {
          return company.get('scenarios');
        }).then(function (scenarios) {
          return scenarios.reload();
        }).then(function () /* reloadedScenarios*/{
          _this3.currentModel.scenarios.setObjects(reorderedScenarios);
        });
      }
    }

  });
});