define('due-dashboard/models/chat', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    company: belongsTo('Company', { async: true }),
    answer_set: belongsTo('AnswerSet', { async: true }),
    messages: hasMany('Message', { async: true }),
    tags: hasMany('Tag', { async: true }),
    // TODO: clean/remove unused code unread
    unread: attr('boolean'),
    resolved: attr('boolean'),
    chat_name: attr('string'),
    chat_title: attr('string'),
    last_message: attr('string'),
    preset_message: attr('string'),
    updated_at: attr('date'),

    last_message_date: attr('date'),

    total_pages: attr('number'),

    sDesc: ['created_at:asc'],
    messageToDisplay: computed.filterBy('messages', 'isNew', false),
    messagesOrderedByDate: computed.sort('messageToDisplay', 'sDesc'),

    title: computed('answer_set.client.email', function () {
      if (this.get('answer_set.client.email')) {
        return this.get('answer_set.client.email');
      } else {
        return 'Anonyme';
      }
    })

  });
});