define('due-dashboard/components/dashboard-filters/surveys', ['exports', 'ember', 'due-dashboard/utils/promise-array'], function (exports, _ember, _dueDashboardUtilsPromiseArray) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var RSVP = _ember['default'].RSVP;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    defaultSurveys: [],
    baseSurveys: [],
    selectedSurveys: computed.map('filter.value', function (id) {
      return this.get('store').peekOrFind('survey', id);
    }).readOnly(),
    addableSurveys: computed.setDiff('baseSurveys', 'selectedSurveys'),

    init: function init() {
      this._super.apply(this, arguments);

      var live = this._queryLive(),
          selected = this.get('selectedSurveys'),
          merged = RSVP.all([live, selected]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var liveSurveys = _ref2[0];
        var selectedSurveys = _ref2[1];

        return liveSurveys.toArray().concat(selectedSurveys.toArray()).uniq();
      }),
          wrapped = (0, _dueDashboardUtilsPromiseArray['default'])(merged);

      this.set('defaultSurveys', wrapped);
      this.set('baseSurveys', wrapped);
    },

    dueInputProps: computed('i18n.locale', 'current_company', function () {
      return {
        'class': 'filter-label',
        label: this.get('current_company.custom_surveys_legend') || this.get('i18n').t('sidebar.filters.surveys.title'),
        placeholder: this.get('i18n').t('global.choose_surveys')
      };
    }),

    // private:

    _searchSurvey: function _searchSurvey(term) {
      var promise = undefined;

      if (term) {
        promise = this._queryByName(term);
      } else {
        promise = this.get('defaultSurveys');
      }
      this.set('baseSurveys', promise);
    },

    /**
     * Search surveys in the list from its name
     * @param {String} term Survey name to search
     */
    _queryByName: function _queryByName(name) {
      return this.store.query('survey', { filter: { status: 'live', name: name }, page: { number: 1, size: 20 } });
    },

    _queryLive: function _queryLive() {
      return this.store.query('survey', { filter: { status: 'live' }, page: { number: 1, size: 60 } });
    },

    actions: {
      /**
       * Delete the language fitler from dashboard filters
       */
      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      },

      /**
       * Search surveys in the list from its name
       * @param {String} term Survey name to search
       */
      searchSurveys: function searchSurveys(term) {
        run.debounce(this, this._searchSurvey, term, 600);
      },

      /**
       * Add the selected survey to the filter list
       * @param {Model<Suvey>} survey Survey selected from dropdown
       */
      onSelectSurveyFilter: function onSelectSurveyFilter(survey) {
        var ids = this.get('filter.value').copy();

        ids.addObject(survey.get('id'));
        this.set('filter.value', ids);
      },

      /**
       * Delete a survey from the filters
       * @param {Model<Survey>} survey Survey to delete
       */
      onDeleteSurveyFilter: function onDeleteSurveyFilter(survey) {
        var ids = this.get('filter.value').copy();

        ids.removeObject(survey.get('id'));
        this.set('filter.value', ids);
      }
    }

  });
});