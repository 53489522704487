define('due-dashboard/controllers/settings/tags/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;
  var sort = computed.sort;
  exports['default'] = Controller.extend({
    /** Query hidden params */
    queryParams: ['due_exclusive_edit'],
    due_exclusive_edit: null,

    tagManager: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    /* Static */
    TAB: {
      tag: { pos: 0, kind: 'tag', propsName: 'tags' },
      root_cause: { pos: 1, kind: 'root_cause', propsName: 'rootCauses' },
      action_taken: { pos: 2, kind: 'action_taken', propsName: 'actionsTaken' }
    },
    SKELETON_REPETITION: 3,
    ICON: { tag: 'label', rootCause: 'flare', actionTaken: 'support' },

    /* Private Props */
    loading: true,
    tags: [],
    rootCauses: [],
    actionsTaken: [],
    selectedTab: 0,
    searchInput: '',
    isShowingModal: true,
    addTagModalIsOpened: false,
    tagSort: ['displayName'],

    init: function init() {
      var _this = this;

      this.get('tagManager').getAll().then(function (tags) {
        _this.set('tags', tags);
        _this.get('tagManager').getAll({}, 'root_cause').then(function (rootCauses) {
          _this.set('rootCauses', rootCauses);
          _this.set('loading', false);
        });
        _this.get('tagManager').getAll({}, 'action_taken').then(function (actionsTaken) {
          _this.set('actionsTaken', actionsTaken);
          _this.set('loading', false);
        });
      });
    },

    /** Display redirection button */
    displayTraductionRedirection: computed('model.languages', function () {
      return this.get('model.languages.content') && this.get('model.languages.content').length > 1;
    }),

    /** Display tab with Tag/Root cause */
    displayTab: computed('tags', 'rootCauses', 'selectedTab', 'actionsTaken', function () {
      var selectedTab = this.get('selectedTab'),
          tab = this.get('TAB');
      return this.get('loading') || selectedTab === tab.tag.pos && this.get('tags').length > 0 || selectedTab === tab.root_cause.pos && this.get('rootCauses').length > 0 || selectedTab === tab.action_taken.pos && this.get('actionsTaken').length > 0;
    }),

    /**
     * Return the traduction for the current tab
     * 0 -> Tag
     * 1 -> Root Cause
     */
    currentTab: computed('selectedTab', function () {
      var i18n = this.get('i18n'),
          icon = this.get('ICON');
      var traductions = [{
        kind: 'tag',
        addBtn: i18n.t('settings.tags.add_tag_button'),
        searchPlaceholder: i18n.t('settings.tags.search', { label: i18n.t('settings.tags.tag') }),
        tableTheadName: i18n.t('settings.tags.tag_name'),
        emptyTableIcon: icon.tag,
        emptyTableTitle: i18n.t('settings.tags.empty_table.tag.title'),
        emptyTableSubTitle: i18n.t('settings.tags.empty_table.tag.subtitle'),
        addForm: this.get('formTag')
      }, {
        kind: 'root_cause',
        addBtn: i18n.t('settings.tags.add_root_cause'),
        searchPlaceholder: i18n.t('settings.tags.search', { label: i18n.t('settings.tags.root_cause') }),
        tableTheadName: i18n.t('settings.tags.root_cause_name'),
        emptyTableIcon: icon.rootCause,
        emptyTableTitle: i18n.t('settings.tags.empty_table.root_cause.title'),
        emptyTableSubTitle: i18n.t('settings.tags.empty_table.root_cause.subtitle'),
        addForm: this.get('formRootCause')
      }, {
        kind: 'action_taken',
        addBtn: i18n.t('settings.tags.add_root_cause'),
        searchPlaceholder: i18n.t('settings.tags.search', { label: i18n.t('settings.tags.action_taken') }),
        tableTheadName: i18n.t('settings.tags.action_taken_name'),
        emptyTableIcon: icon.actionTaken,
        emptyTableTitle: i18n.t('settings.tags.empty_table.action_taken.title'),
        emptyTableSubTitle: i18n.t('settings.tags.empty_table.action_taken.subtitle'),
        addForm: this.get('formActionTaken')
      }];
      return traductions[this.get('selectedTab')];
    }),

    /** Tab navigation configuration */
    tabs: computed('tags', 'rootCauses', 'actionsTaken', function () {
      var icon = this.get('ICON');
      return [{
        icon: icon.tag,
        label: this.get('i18n').t('settings.tags.tags_count').string,
        suffix: this.get('tags') ? this._countAssignable(this.get('tags')) : ''
      }, {
        icon: icon.rootCause,
        label: this.get('i18n').t('settings.tags.root_causes_count').string,
        suffix: this.get('rootCauses') ? this._countAssignable(this.get('rootCauses')) : ''
      }, {
        icon: icon.actionTaken,
        label: this.get('i18n').t('settings.tags.actions_taken_count').string,
        suffix: this.get('actionsTaken') ? this._countAssignable(this.get('actionsTaken')) : ''
      }];
    }),

    /** Table column configuration */
    tablesColumns: computed('selectedTab', function () {
      var i18n = this.get('i18n');
      return [{ colspan: '2', size: 'medium', label: this.get('currentTab').tableTheadName }, { colspan: '2', size: 'small', label: i18n.t('settings.tags.surveys') }, { colspan: '1', size: 'small', label: i18n.t('settings.tags.created') }, { colspan: '1', size: 'small', label: i18n.t('settings.tags.created_by') }];
    }),

    /** Sort tag computeted */
    sortedTags: sort('computedTags', 'tagSort'),

    /** Build Tag/Root Cause with child */
    computedTags: computed('tags', 'rootCauses', 'selectedTab', 'actionsTaken', function () {
      var _this2 = this;

      var tags = [this.get('tags'), this.get('rootCauses'), this.get('actionsTaken')][this.get('selectedTab')];
      return tags.map(function (tag, _, array) {
        return _this2._computeTags(array, tag);
      });
    }),

    /**
     * Buidl child tag
     * @param {Array<Tag>} tags Tag list
     * @param {*} tag Current tag
     * @returns Child list of tags
     */
    _computeTags: function _computeTags(tags, tag) {
      var _this3 = this;

      var splitName = tag.get('name').split(' > ');
      tag.set('depth', splitName.length - 1);
      tag.set('displayName', splitName[tag.get('depth')]);
      var subTagRegExp = "^" + tag.get('name').replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + " > [^>]*$";
      var subTags = tags.filter(function (item) {
        return item.get('name').match(subTagRegExp);
      });
      subTags = subTags.map(function (subTag) {
        subTag = _this3._computeTags(tags, subTag);
        subTag.set('parentTag', tag);
        return subTag;
      });
      tag.set('subTags', subTags);
      return tag;
    },

    /**
     * Delete tag and its children
     * @param {Tag} tag Tag to delete
     */
    _deleteTagAndSubTags: function _deleteTagAndSubTags(tag) {
      var _this4 = this;

      if (tag.get('subTags').length > 0) {
        for (var i = 0; i < tag.get('subTags').length; i++) {
          this._deleteTagAndSubTags(tag.get('subTags')[i]);
        }
      }
      tag.destroyRecord().then(function () {
        var tab = [_this4.get('tags'), _this4.get('rootCauses'), _this4.get('actionsTaken')][_this4.get('selectedTab')];
        tab.removeObject(tag);
        _this4.notifyPropertyChange('tags');
      });
    },

    _updateTagAndSubTags: function _updateTagAndSubTags(tag, updatedAttributes, language, depth) {
      if (tag.get('subTags').length > 0) {
        for (var i = 0; i < tag.get('subTags').length; i++) {
          this._updateTagAndSubTags(tag.get('subTags')[i], updatedAttributes, language, depth);
        }
      }
      if (updatedAttributes['name']) {
        var nameSplited = tag.get('name').split(' > ').map(function (subname) {
          return subname.trim();
        });
        nameSplited[depth] = updatedAttributes['name'][1].split(' > ')[depth].trim();
        tag.set('name', nameSplited.join(' > '));
      }
      if (updatedAttributes['due_exclusive']) {
        tag.set('due_exclusive', updatedAttributes['due_exclusive'][1]);
      }
      tag.set('language', language);
      tag.save();
    },

    _countAssignable: function _countAssignable(items) {
      return items.filter(function (item) {
        return !item.get('not_assignable');
      }).length;
    },

    actions: {
      /** Trigger when user change tab */
      onChangeTab: function onChangeTab(index) {
        this.set('selectedTab', index);
      },

      /** Search tag or root cause */
      search: function search() {
        var _this5 = this;

        var searchInput = this.get('searchInput');
        var filters = searchInput && searchInput.length > 0 ? { name: searchInput, with_parents: true } : {};
        var tab = this.get('TAB')[this.get('currentTab').kind];
        this.get('tagManager').getAll(filters, tab.kind).then(function (result) {
          return _this5.set(tab.propsName, result);
        });
      },

      /** Force open model with add btn */
      openAddTagModal: function openAddTagModal() {
        this.set('addTagModalIsOpened', true);
      },

      /** Add tag create in current view */
      notifyTagCreation: function notifyTagCreation(tag) {
        var tab = [this.get('tags'), this.get('rootCauses'), this.get('actionsTaken')][this.get('selectedTab')];
        tab.pushObject(tag);
        this.notifyPropertyChange('tags');
      },

      editTag: function editTag(tag) {
        this._updateTagAndSubTags(tag, tag.changedAttributes(), tag.get('language'), tag.get('depth'));
      },

      deleteTag: function deleteTag(tag) {
        this._deleteTagAndSubTags(tag);
      },

      redirectToTranslations: function redirectToTranslations() {
        var kinds = ['tag', 'root_cause', 'action_taken'];
        this.transitionToRoute('settings.tags.translations', { queryParams: { kind: kinds[this.get('selectedTab')] } });
      }
    }
  });
});