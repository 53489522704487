define('due-dashboard/models/feedback-tag', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var belongsTo = _emberData['default'].belongsTo;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    from_due: attr('boolean', { readOnly: true }),
    deleted: attr('boolean'),
    feeling: attr('string'),
    hard_delete: attr('boolean'),
    feedback: belongsTo('feedback'),
    tag: belongsTo('tag', { async: false })
  });
});