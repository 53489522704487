define('due-dashboard/components/v2/libraries/surveys/navbar/elements/common/closed-questions', ['exports'], function (exports) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _getQuestionSource(condition) {
    var questionSource, questions;
    return regeneratorRuntime.async(function _getQuestionSource$(context$1$0) {
      var _this = this;

      while (1) switch (context$1$0.prev = context$1$0.next) {
        case 0:
          questionSource = [];
          questions = this.get('group.questionsList');

          if (!(condition.kind === 'answer_in')) {
            context$1$0.next = 5;
            break;
          }

          context$1$0.next = 5;
          return regeneratorRuntime.awrap((function callee$1$0() {
            var step, element, question, rating_scale;
            return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
              while (1) switch (context$2$0.prev = context$2$0.next) {
                case 0:
                  step = condition.data.answer_step;
                  element = condition.data.answer_element;
                  question = questions.filter(function (question) {
                    return question.step === step && question.element === element;
                  })[0];
                  context$2$0.t0 = question.kind;
                  context$2$0.next = context$2$0.t0 === 'single_select' ? 6 : context$2$0.t0 === 'multiple_select' ? 6 : context$2$0.t0 === 'rating' ? 8 : 13;
                  break;

                case 6:
                  questionSource.push.apply(questionSource, _toConsumableArray(question.content.options.map(function (r) {
                    return { key: r['key'], text: r['value'] };
                  })));
                  return context$2$0.abrupt('break', 14);

                case 8:
                  context$2$0.next = 10;
                  return regeneratorRuntime.awrap(this.get('store').findRecord('rating_scale', question.content.rating_scale_id));

                case 10:
                  rating_scale = context$2$0.sent;

                  questionSource.push.apply(questionSource, _toConsumableArray(rating_scale.get('ratings').map(function (r) {
                    return { key: r.get('score').toString(), text: r.get('score').toString() };
                  })));
                  return context$2$0.abrupt('break', 14);

                case 13:
                  questionSource.clear();

                case 14:
                case 'end':
                  return context$2$0.stop();
              }
            }, null, _this);
          })());

        case 5:
          return context$1$0.abrupt('return', questionSource);

        case 6:
        case 'end':
          return context$1$0.stop();
      }
    }, null, this);
  }

  exports._getQuestionSource = _getQuestionSource;
});