define("due-dashboard/helpers/set-segment-value", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Helper.helper(function () {
    var elem = arguments[0][0];
    var promise = elem;
    var obj = arguments[0][1];
    var keys = obj.dep_keys;
    var func = obj.func;
    var set_path = arguments[0][2];
    keys.map(function (key) {
      if (promise.then) {
        promise = promise.then(function () {
          return promise.get(key);
        });
      } else {
        promise = promise.get(key);
      }
    });
    if (promise.then) {
      elem.set(set_path, "Loading ...");
      promise.then(function () {
        return elem.set(set_path, func(elem));
      });
    } else {
      elem.set(set_path, func(elem));
    }
  });
});