define('due-dashboard/components/match-segment-value-scenario-condition', ['exports', 'ember', 'due-dashboard/components/scenario-condition-item'], function (exports, _ember, _dueDashboardComponentsScenarioConditionItem) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardComponentsScenarioConditionItem['default'].extend({
    segments: [],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.attrs.scenarioStore.value.query('segment', { filter: { all: true, display: 'filter' }, page: { number: 1, size: 20 } }).then(function (segments) {
        _this.set('segments', segments);
      });
    },

    _searchSegments: function _searchSegments(term, resolve, reject) {
      this.store.query('segment', {
        filter: {
          name: term,
          all: true,
          display: 'filter'
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (segments) {
        return resolve(segments);
      }, reject);
    },

    operatorLabel: computed('condition.operator', function () {
      var operator = this.get('condition.operator');
      return this.get('i18n').t('components.match-segment-value-scenario-condition.operator_' + operator + '_label');
    }),

    actions: {
      searchSegments: function searchSegments(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this2, _this2._searchSegments, term, resolve, reject, 600);
        });
      }
    }

  });
});