define('due-dashboard/components/tags/delete-tag', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    tagName: 'span',
    modalIsOpened: false,

    delete_confirmation: computed('tag', function () {
      return this.get('i18n').t('settings.tags.confirm_delete.' + this.get('kind'), { tag_name: htmlSafe(this.get('tag.displayName')) });
    }),

    closeModal: function closeModal() {
      this.set('modalIsOpened', false);
    },

    actions: {
      openModal: function openModal() {
        if (this.get('isTagEditable')) {
          this.set('modalIsOpened', true);
        }
      },

      closeModal: function closeModal() {
        this.closeModal();
      },

      deleteTag: function deleteTag() {
        var tag = this.get('tag');
        this.get('deleteAction')(tag);
        this.closeModal();
      }
    }
  });
});