define('due-dashboard/components/due-table/due-table-dynamic', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var copy = _ember['default'].copy;
  exports['default'] = Component.extend({

    headers: computed('data.headers', function () {
      var headers = this.get('data.headers');
      var leftSideHeader = [],
          rightSideHeader = [];
      headers.forEach(function (header) {
        return header.main_column ? leftSideHeader.push(header) : rightSideHeader.push(header);
      });
      return {
        leftSideHeader: leftSideHeader,
        rightSideHeader: rightSideHeader
      };
    }),

    page: computed('data.rows', function () {
      var rows = this.get('data.rows');

      var _processRows2 = this._processRows(rows, 0);

      var leftSideContent = _processRows2.leftSideContent;
      var rightSideContent = _processRows2.rightSideContent;

      return {
        leftSideContent: leftSideContent,
        rightSideContent: rightSideContent
      };
    }),

    showLineComputed: computed('showLineMapping', function () {
      return this.showLineMapping;
    }),

    _processRows: function _processRows(rows, depth) {
      var _this = this;

      var leftContent = [],
          rightContent = [];

      var _loop = function (i, len) {
        var row = rows[i],
            id = crypto.randomUUID();
        if (row.id == undefined) {
          row.id = id;
        }
        row.depth = depth;
        var left = _extends({}, row);
        var right = _extends({}, row);
        if (row.subLines) {
          var subLinesContent = _this._processRows(row.subLines, depth + 1);
          left.subLines = subLinesContent.leftSideContent;
          right.subLines = subLinesContent.rightSideContent;
        }
        var lContent = [],
            rContent = [];
        row.data.forEach(function (r) {
          return r.main_column ? lContent.push(r) : rContent.push(r);
        });
        left.data = lContent;
        right.data = rContent;
        leftContent = leftContent.concat(left);
        rightContent = rightContent.concat(right);
      };

      for (var i = 0, len = rows.length; i < len; i++) {
        _loop(i, len);
      }
      return {
        leftSideContent: leftContent,
        rightSideContent: rightContent
      };
    },

    actions: {
      toggleLine: function toggleLine(id) {
        this.showLineMapping[id] = !this.showLineMapping[id];
        this.notifyPropertyChange('showLineMapping');
      }
    }
  });
});