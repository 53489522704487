define('due-dashboard/lib/cookie', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    setCookie: function setCookie(key, value, options) {
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        try {
          _ember['default'].$.cookie(key, value, options);
          _ember['default'].run(null, resolve);
        } catch (e) {
          _ember['default'].run(null, reject, e);
        }
      });
    },

    getCookie: function getCookie(key) {
      return _ember['default'].$.cookie(key);
    },

    removeCookie: function removeCookie(key, options) {
      return _ember['default'].$.removeCookie(key, options);
    }
  });
});