define('due-dashboard/models/verbatim-display-option', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    keywords: attr('boolean'),
    segments: attr('boolean'),
    tags: attr('boolean'),
    status: attr('boolean'),
    messaging: attr('boolean'),

    account: belongsTo('Account')
  });
});