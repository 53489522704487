define("due-dashboard/templates/components/v2/array/-footer", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/array/-footer.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("tfoot");
        dom.setAttribute(el1, "class", "due-array-footer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tr");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("td");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "footer-container");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'colspan');
        morphs[1] = dom.createAttrMorph(element1, 'colspan');
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "colspan", ["get", "colspan", ["loc", [null, [1, 17], [1, 24]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "colspan", ["get", "colspan", ["loc", [null, [3, 18], [3, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [5, 8], [5, 17]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});