define('due-dashboard/components/templates/type-picker', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['type-picker'],

    typesOptions: {
      'nps': {
        icon: 'clone'
      },
      'basic': {
        icon: 'inbox'
      },
      'sms': {
        icon: 'mobile'
      },
      'html': {
        icon: 'code'
      },
      'email': {
        icon: 'inbox'
      }
    },

    emailTypes: computed('includeNps', function () {
      return this.get('includeNps') ? ['basic', 'nps', 'html'] : ['basic', 'html'];
    }),

    setTypeToEmail: computed(function () {
      this.set('mainType', 'email');
    }),

    bulkDispatch: computed('bulk_dispatch_id', function () {
      var _this = this;

      var bdid = this.get('bulk_dispatch_id');
      if (!bdid || bdid === "null") {
        return null;
      }
      this.store.findRecord('bulk-dispatch', bdid).then(function (bd) {
        return _this.set('mainType', bd.get('sending_type'));
      });
      return true;
    }),

    mainType: computed('model.sending_type', {
      get: function get() {
        var sp = this.get('model.sending_type');
        return sp === 'sms' ? 'sms' : !sp ? null : 'email';
      }, set: function set(_, value) {
        if (value === 'sms') {
          this.set('model.sending_type', 'sms');
          this.send('changeType', 'sms');
          return 'sms';
        } else if (this.get('model.sending_type') === 'sms') {
          this.set('model.sending_type', null);
        }
        return value;
      }
    }),

    _searchSurveys: function _searchSurveys(resolve, reject) {
      this.store.query('survey', { filter: { has_embedded_nps: true, status: 'live' } }).then(function (surveys) {
        return resolve(surveys);
      }, reject);
    },

    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      this._searchSurveys(function (surveys) {
        _this2.set('includeNps', surveys.content.length);
      });
    },

    actions: {

      changeType: function changeType(type) {
        this.set('model.sending_type', type);
        var change = this.get('onChange');
        if (change) {
          change(this.get('model.sending_type'));
        }
      }

    }

  });
});