define('due-dashboard/components/resolution-modal', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    tagManager: service(),

    rootCauseTitle: computed('resolveCallback', function () {
      return this.get('i18n').t('settings.company.root_cause').string.toUpperCase();
    }),

    actionTakenTitle: computed('resolveCallback', function () {
      return this.get('i18n').t('settings.company.action_taken').string.toUpperCase();
    }),

    resolutionDisabled: computed('feedbackActionsTaken.@each', 'feedbackRootCauses.@each', function () {
      var disabled = false;
      if (this.get('currentCompany.enabled_root_cause') && this.get('currentCompany.root_cause_mandatory') && this.get('feedbackRootCauses').length === 0) {
        disabled = true;
      }
      if (this.get('currentCompany.action_taken_enabled') && this.get('currentCompany.action_taken_mandatory') && (this.get('feedbackActionsTaken') || []).length === 0) {
        disabled = true;
      }
      return disabled;
    }),

    actions: {
      closeModal: function closeModal() {
        this.get('cancelCallback')();
      },

      resolveModal: function resolveModal() {
        this.get('resolveCallback')();
      },

      actionTakenUpdated: function actionTakenUpdated(obj) {
        this.get('actionTakenUpdated')('action_taken', obj);
      },

      actionTakenRemoved: function actionTakenRemoved(obj) {
        this.get('actionTakenRemoved')(obj);
      },

      searchRootCause: function searchRootCause(term) {
        this.get('rootCauseSearch')('root_cause', term);
      },

      searchActionTaken: function searchActionTaken(term) {
        this.get('actionTakenSearch')('action_taken', term);
      },

      rootCauseUpdated: function rootCauseUpdated(obj) {
        this.get('rootCauseUpdated')('root_cause', obj);
      },

      closeRootCause: function closeRootCause() {
        this.get('rootCauseClose')('root_cause');
      },

      closeActionTaken: function closeActionTaken() {
        this.get('actionTakenClose')('action_taken');
      },

      rootCauseRemove: function rootCauseRemove(obj) {
        this.get('rootCauseRemove')(obj);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{resolution-modal}} modal with root-cause & action taken
 * @figma https://www.figma.com/file/25WAxtLf7gFC9H60vxpW8j/Close-the-loop?node-id=354%3A4793&t=Bj1kTOmgCmoqVUJQ-0
 */