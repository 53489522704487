define('due-dashboard/components/due-label', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var guidFor = _ember['default'].guidFor;

  /*
    {{due-input}}
      label for input component
      author: Romain Gay
  
    full usage:
      {{due-label
        label=[label to display on top of the input]
        helper=[helper to display at the bottom of the input]
      }}
  
  */

  exports['default'] = Component.extend({
    classNames: ['due-label'],
    uid: null,

    didInsertElement: function didInsertElement() {
      this.set('uid', guidFor(this));
    },

    actions: {}
  });
});