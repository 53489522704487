define('due-dashboard/components/scenario-stats', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    classNames: ['scenario-stats'],

    setScenario: on('init', observer('scenario_id', function () {
      this.set('scenario_stats', this.store.find('scenario-stat', this.get('scenario_id')));
    })),

    uniqClicksTotalPercent: computed('scenario_stats.{unique_views,feedbacks_with_click}', function () {
      if (this.get('scenario_stats.unique_views') !== 0) {
        return '(' + Math.round(this.get('scenario_stats.feedbacks_with_click') / this.get('scenario_stats.unique_views') * 100 * 10) / 10 + '%)';
      }
    })

  });
});