define('due-dashboard/routes/companies/templates/edit', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({

    beforeModel: function beforeModel() {
      if (!this.get('currentAccount.canEditTemplates')) {
        return this.transitionTo('companies.templates.index');
      }
      return this._super.apply(this, arguments);
    },

    model: function model(params) {
      if (!params.template_id || params.template_id === "null") {
        return this.store.createRecord('template');
      } else {
        return this.store.findRecord('template', params.template_id);
      }
    },

    transitionToNext: function transitionToNext() {
      var _this = this;

      var bulk_dispatch_id = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      if (bulk_dispatch_id && bulk_dispatch_id !== "null") {
        this.controller.set('bulk_dispatch_id', null);
        this.store.findRecord('bulk-dispatch', bulk_dispatch_id).then(function (bd) {
          bd.get('survey').then(function (sr) {
            _this.transitionTo('companies.surveys.edit.bulk-dispatch.template', sr.get('id'), bd.get('id'));
          });
        });
      } else {
        this.transitionTo('companies.templates.index');
      }
    },

    deactivate: function deactivate() {
      this._super();
      var model = this.controller.get('model');
      if (model.get('isNew')) {
        this.controller.get('model').deleteRecord();
      } else if (model.get('hasDirtyAttributes')) {
        model.rollbackAttributes();
      }
    },

    actions: {

      previous: function previous(template, bulk_dispatch_id) {
        if (template.get('isNew')) {
          template.deleteRecord();
          template = null;
        } else {
          template.rollbackAttributes();
        }
        this.transitionToNext(bulk_dispatch_id);
      },

      saveTemplate: function saveTemplate(template, bulk_dispatch_id) {
        var _this2 = this;

        this.controller.set('saveTemplatePromise', template.commitDefaults().save().then(function () {
          _this2.transitionToNext(bulk_dispatch_id);
        }));
      }
    }

  });
});