define('due-dashboard/controllers/settings/groups/new', ['exports', 'ember'], function (exports, _ember) {
  var _Ember$computed = _ember['default'].computed;
  var oneWay = _Ember$computed.oneWay;
  var alias = _Ember$computed.alias;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    profile: oneWay('model.profile'),
    showWarning: alias('currentAccount.content.company.replicate_to_china'),

    actions: {

      backToIndex: function backToIndex() {
        this.transitionToRoute('settings.groups.index');
      },

      createGroup: function createGroup() {
        var _this = this;

        this.get('profile').save().then(function () {
          _this.set('profile', _this.store.createRecord('account-custom-profile-right'));
          _this.send('backToIndex');
        });
      }

    }

  });
});