define('due-dashboard/serializers/template-dynamic-attribute', ['exports', 'ember-data/serializers/json'], function (exports, _emberDataSerializersJson) {
  /* global _ */

  exports['default'] = _emberDataSerializersJson['default'].extend({

    serialize: function serialize() /*snapshot, options*/{
      var res = this._super.apply(this, arguments);

      Object.keys(res.sending_objects).forEach(function (key) {
        return res[key] = { sending_object: res.sending_objects[key] };
      });
      delete res.sending_objects;
      return res;
    },

    normalize: function normalize(typeClass, hash) {
      _.forEach(hash, function (value, key) {
        hash[key.underscore()] = value;
        if (key.underscore() !== key) {
          delete hash[key];
        }
      });

      hash.sending_objects = {};
      this.store.peekAll('language').forEach(function (language) {
        var tag = language.get('tag').toLowerCase();
        if (hash[tag]) {
          hash.sending_objects[tag] = hash[tag]['sending-object'];
          delete hash[tag];
        }
      });

      return this._super.apply(this, arguments);
    }

  });
});