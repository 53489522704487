define('due-dashboard/routes/account', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'due-dashboard/mixins/survey'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _dueDashboardMixinsSurvey) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], _dueDashboardMixinsSurvey['default'], {

    model: function model() {
      //params
      return _ember['default'].Object.create({
        stepGroups: this.get('computedStepGroups'),
        account: this.store.createRecord('account', {})
      });
    }

  });
});