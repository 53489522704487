define('due-dashboard/components/zendesk/one-trigger', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['zendesk-trigger'],

    actions: {
      save: function save() {
        this.set('model.conditions.any', this.get('any_conditions'));
        this.set('model.conditions.all', this.get('all_conditions'));
        this.get('saveTrigger')();
      }
    }
  });
});