define('due-dashboard/routes/companies/verbatim', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    filters: service(),
    model: function model() {
      var filters = this.get('filters').get('queryFilters');
      return {
        company: this.get('currentAccount.content.company'),
        keywords: this.store.query('keyword', { filter: filters['filter'], sort: '-counter', page: { number: 1, size: 5 } }),
        verbatims: this.store.query('verbatim', this.get('filters').get('queryFilters')),
        segmentsToDisplay: this.store.query('segment', { filter: { display: 'verbatim' } }),
        displaySettings: this.store.query('verbatim-display-option', { filter: { account_id: this.currentAccount.content.id } }),
        languages: this.get('currentAccount.companyLanguages')
      };
    },

    actions: {
      didTransition: function didTransition() {
        this.controllerFor('companies.verbatim').checkVerbatimLanguage();
      }
    }
  });
});