define('due-dashboard/controllers/companies/libraries/rating-scales/index', ['exports', 'ember', 'due-dashboard/helpers/date-format-array'], function (exports, _ember, _dueDashboardHelpersDateFormatArray) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var debounce = _ember['default'].run.debounce;
  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = Controller.extend({
    filters: service(),
    searchValue: '',
    isLoading: true,
    isLoadingFonts: true,

    STATUS_ENUM: [{ key: 'all', text: 'words.all_maj' }, { key: 'live', text: 'words.live' }, { key: 'archived', text: 'words.archived' }],

    statusInfoMapping: {
      'live': { icon: 'tio-archive', key: 'words.archive' },
      'draft': { icon: 'tio-archive', key: 'words.archive' },
      'archived': { icon: 'tio-restore', key: 'words.restore' }
    },

    ratingScaleComboActions: computed(function () {
      return [{ type: 'action', icon: 'tio-archive', action: 'toggleStatus', text: this.get('i18n').t('words.archive').string }];
    }),

    selectedStatusFilter: { key: 'live', text: 'words.live' },

    availablePaginations: computed(function () {
      return [{ key: 25, text: '25' }, { key: 50, text: '50' }, { key: 100, text: '100' }];
    }),

    selectedPagination: { key: 25, text: '25' },

    paginationMeta: computed('model.meta', function () {
      var model = this.get('model');

      return {
        page_count: model ? model.get('meta').page_count : 0,
        current_page: model ? model.get('meta').current_page : 0
      };
    }),

    canSeeButtons: computed(function () {
      var account = this.get('currentAccount.content');
      return account.get('zone') == 'Europe' && this.get('currentAccount.canEditRating_scales');
    }),

    computedFooterSelector: computed('computedRatingScales', function () {
      var rss = this.get('computedRatingScales');

      return rss && rss.length > 8 ? '.footer-container' : '';
    }),

    computedRatingScales: computed('model', function () {
      var _this = this;

      var rss = this.get('model');

      if (!rss) {
        return;
      }

      var computedRatingScales = rss.map(function (rs) {
        var ratings = rs.get('ratings');
        if (ratings.toArray().slice(-1)[0].get('score') == null) {
          ratings.popObject();
        }

        ratings.pushObject(_this.store.createRecord('rating', {
          position: ratings.get('length'),
          display_scale_value: 'N/A',
          style: rs.get('na_design') != null ? rs.get('na_design')['style'] : null,
          score: null
        }));

        return {
          id: rs.get('id'),
          name: rs.get('name'),
          kind: rs.get('kind'),
          status: rs.get('status'),
          overview: rs.get('overview'),
          last_updated_at: _dueDashboardHelpersDateFormatArray['default'].compute(rs.get('updated_at')),
          created_at: _dueDashboardHelpersDateFormatArray['default'].compute(rs.get('created_at')),
          status_btn_icon: _this.get('statusInfoMapping')[rs.get('status')] ? _this.get('statusInfoMapping')[rs.get('status')].icon : '🤷‍♂️',
          status_btn_key: _this.get('statusInfoMapping')[rs.get('status')] ? _this.get('statusInfoMapping')[rs.get('status')].key : '🤷‍♂️',
          ratings: ratings
        };
      });

      setTimeout(function () {
        _this.send('refreshPreview');
      }, 0);

      return computedRatingScales;
    }),

    isEmpty: computed('model', function () {
      var model = this.get('model');

      return model ? model.toArray().length === 0 : false;
    }),

    init: function init() {
      if (!this.get('customFonts')) {
        this._queryCustomFonts();
      }
    },

    _queryRatingScales: function _queryRatingScales() {
      var _this2 = this;

      var statusFilter = this.get('selectedStatusFilter');
      var pagination = this.get('selectedPagination');
      var nameFilter = this.get('searchValue');
      var actualPage = this.get('paginationMeta');
      var filters = { filter: { kind: 'number,image', v2_builtin: false }, page: { size: pagination.key, number: actualPage.current_page }, include: 'ratings', sort: '-created_at' };

      if (statusFilter.key != 'all') {
        filters.filter['status'] = statusFilter.key;
      }

      if (nameFilter.length > 0) {
        filters.filter['name'] = nameFilter;
      }

      this.set('isLoading', true);
      this.notifyPropertyChange('isLoading');

      this.store.query('rating_scale', filters).then(function (filteredRatingScales) {
        _this2.set('model', filteredRatingScales);
        _this2.set('isLoading', false);

        set(pagination, 'current_page', filteredRatingScales.get('meta').current_page);
        set(pagination, 'page_count', filteredRatingScales.get('meta').page_count);
        _this2.set('paginationMeta', pagination);

        _this2.send('refreshPreview');
      });
    },

    _patchRatingScaleStatus: function _patchRatingScaleStatus(index, status) {
      var _this3 = this;

      var rss = this.get('model').toArray();

      rss[index].set('status', status);
      rss[index].save().then(function () {
        _this3._queryRatingScales();
      });
    },

    _queryCustomFonts: function _queryCustomFonts() {
      var _this4 = this;

      this.set('isLoadingFonts', true);
      this.store.query('font', { sort: '-created_at' }).then(function (fonts) {
        fonts = _this4._formatFonts(fonts);
        _this4.set('customFonts', fonts.sort(function (a, b) {
          return a['name'].localeCompare(b['name']);
        }));
        _this4.set('isLoadingFonts', false);
      });
    },

    _initFontFace: function _initFontFace(fonts) {
      var fontFaces = fonts.map(function (font) {
        return new FontFace(font.get('name') + '-' + font.get('id'), 'url(' + font.get('font') + ')');
      });

      fontFaces.forEach(function (fontFace) {
        fontFace.load();
        document.fonts.add(fontFace);
      });
    },

    _formatFonts: function _formatFonts(fonts) {
      this._initFontFace(fonts);
      return fonts.map(function (font) {
        return {
          name: font.get('name'),
          value: font.get('name') + '-' + font.get('id')
        };
      });
    },

    _getCustomFont: function _getCustomFont(font_family) {
      return this.get('customFonts').find(function (f) {
        return f.value.startsWith(font_family);
      }) || { name: font_family, value: font_family };
    },

    actions: {
      dispatchAction: function dispatchAction(arg, action) {
        this.send(action, arg);
      },

      changeStatusFilter: function changeStatusFilter(selectedStatusFilter) {
        var _this5 = this;

        this.set('selectedStatusFilter', selectedStatusFilter);
        this.set('isLoading', true);
        this.set('paginationMeta.current_page', 1);

        return new Promise(function (resolve, reject) {
          debounce(_this5, _this5._queryRatingScales, resolve, reject, 600);
        });
      },

      changePagination: function changePagination(selectedPagination) {
        var _this6 = this;

        var meta = this.get('paginationMeta');

        if (!this.get('isEmpty')) {
          this.set('selectedPagination', selectedPagination);
          set(meta, 'current_page', 1);
          this.set('paginationMeta', meta);

          return new Promise(function (resolve, reject) {
            debounce(_this6, _this6._queryRatingScales, resolve, reject, 600);
          });
        }
      },

      changeNameFilter: function changeNameFilter() {
        var _this7 = this;

        return new Promise(function (resolve, reject) {
          debounce(_this7, _this7._queryRatingScales, resolve, reject, 600);
        });
      },

      createRS: function createRS() {
        this.transitionToRoute('companies.libraries.rating-scales.new');
      },

      toggleStatus: function toggleStatus(index) {
        var rss = this.get('model').toArray();
        this._patchRatingScaleStatus(index, ({
          "live": "archived",
          "archived": "live"
        })[rss[index].get('status')]);
      },

      previousPage: function previousPage() {
        var _this8 = this;

        if (!this.get('isEmpty')) {
          var meta = this.get('paginationMeta');

          set(meta, 'current_page', meta['current_page'] - 1);
          this.set('paginationMeta', meta);

          return new Promise(function (resolve, reject) {
            debounce(_this8, _this8._queryRatingScales, resolve, reject, 600);
          });
        }
      },

      nextPage: function nextPage() {
        var _this9 = this;

        if (!this.get('isEmpty')) {
          var meta = this.get('paginationMeta');

          set(meta, 'current_page', meta['current_page'] + 1);
          this.set('paginationMeta', meta);

          return new Promise(function (resolve, reject) {
            debounce(_this9, _this9._queryRatingScales, resolve, reject, 600);
          });
        }
      },

      editRatingScale: function editRatingScale(ratingScale) {
        ratingScale.ratings.popObject();
        this.transitionToRoute('companies.libraries.rating-scales.edit', ratingScale.id);
      },

      setModel: function setModel() {
        this._queryRatingScales();
      },

      refreshPreview: function refreshPreview() {
        var _this10 = this;

        var ratingScales = this.get('model');

        if (ratingScales) {
          ratingScales = ratingScales.toArray();
        } else {
          return;
        }

        this.set('isLoading', false);

        // each rating scales

        var _loop = function (i) {
          if (!ratingScales[i].get('ratings') || ratingScales[i].get('kind') === 'image') {
            return 'continue';
          }

          // each rating
          ratingScales[i].get('ratings').forEach(function (rating, index) {
            var ratingElement = document.getElementsByClassName('rating-number-preview-container-' + i + '-' + index)[0];
            var ratingElementMobile = document.getElementsByClassName('rating-number-preview-container-' + i + '-' + index)[1];
            if (!ratingElement && !ratingElementMobile || !rating.style) {
              return;
            }

            // mobile & desktop
            var ratingElementText = ratingElement.children[0];
            var ratingElementMobileText = ratingElementMobile.children[0];
            var ratingStyle = rating.get('style');

            ratingStyle['default'].text['font-family'] = _this10._getCustomFont(ratingStyle['default'].text['font-family']).value;

            ratingElement.style.backgroundColor = ratingStyle['default'].background['background-color'];
            ratingElement.style.borderRadius = ratingStyle['default'].background['border-radius'];
            ratingElement.style.borderWidth = ratingStyle['default'].background['border-width'];
            ratingElement.style.borderColor = ratingStyle['default'].background['border-color'];
            ratingElement.style.boxShadow = ratingStyle['default'].background['box-shadow'];

            ratingElementMobile.style.backgroundColor = ratingStyle['default'].background['background-color'];
            ratingElementMobile.style.borderRadius = ratingStyle['default'].background['border-radius'];
            ratingElementMobile.style.borderWidth = ratingStyle['default'].background['border-width'];
            ratingElementMobile.style.borderColor = ratingStyle['default'].background['border-color'];
            ratingElementMobile.style.boxShadow = ratingStyle['default'].background['box-shadow'];

            ratingElementText.style.fontFamily = ratingStyle['default'].text['font-family'];
            ratingElementText.style.color = ratingStyle['default'].text['color'];
            ratingElementText.style.fontWeight = ratingStyle['default'].text['font-weight'];
            ratingElementText.style.fontSize = ratingStyle['default'].text['font-size'];
            ratingElementText.style.lineHeight = ratingStyle['default'].text['line-height'];
            ratingElementText.style.textDecoration = ratingStyle['default'].text['text-decoration'];

            ratingElementMobileText.style.fontFamily = ratingStyle['default'].text['font-family'];
            ratingElementMobileText.style.color = ratingStyle['default'].text['color'];
            ratingElementMobileText.style.fontWeight = ratingStyle['default'].text['font-weight'];
            ratingElementMobileText.style.fontSize = ratingStyle['default'].text['font-size'];
            ratingElementMobileText.style.lineHeight = ratingStyle['default'].text['line-height'];
            ratingElementMobileText.style.textDecoration = ratingStyle['default'].text['text-decoration'];
          });
        };

        for (var i = 0; i < ratingScales.length; i++) {
          var _ret = _loop(i);

          if (_ret === 'continue') continue;
        }
      }
    }
  });
});