define('due-dashboard/components/change-end-tw-follow-scenario-action', ['exports', 'due-dashboard/components/scenario-action-item', 'ember'], function (exports, _dueDashboardComponentsScenarioActionItem, _ember) {
  exports['default'] = _dueDashboardComponentsScenarioActionItem['default'].extend({
    action: null,

    error_keys: _ember['default'].computed(function () {
      return ['name_error_key'];
    }),

    errorObserverVars: {
      single_error_key: 'name_error_key',
      single_error_target: 'action.validations.attrs.account_name.error'
    }

  });
});