define('due-dashboard/models/template-dynamic-attribute', ['exports', 'ember-data-model-fragments', 'ember-data', 'ember'], function (exports, _emberDataModelFragments, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberDataModelFragments['default'].Fragment.extend({
    banner_color: attr(),
    button_color: attr(),
    sending_objects: _emberDataModelFragments['default'].fragmentArray('template-content'),

    isValid: _ember['default'].computed('sending_objects.@each.content', 'fragmentOwner.isSms', function () {
      return !this.get('sending_objects').filter(function (so) {
        return !so.get('isValid');
      }).length;
    }).volatile()

  });
});