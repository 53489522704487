define('due-dashboard/models/social-network', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    name: attr('string'),
    tag: attr('string'),

    imageURL: computed('tag', 'isLoaded', function () {
      if (this.get('isLoaded') === true) {
        return this.asset_mapper.asset_url('images/social/' + this.get('tag') + '.png');
      } else {
        return "";
      }
    })
  });
});