define('due-dashboard/components/companies-segment-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'tr',
    modalIsOpened: false,
    dataTypes: ['personal_data', 'sensitive_personal_data', 'not_personal_data'],
    dataSubjects: ['consultant', 'contractor', 'customer', 'employee', 'other', 'prospect', 'suppliers', 'trainee', 'visitor'],
    feedbackCount: 1,
    monitoredFocus: false,
    filteredCategories: [],
    displayDynamicOption: false,

    description: computed('companiesSegment.description', function () {
      return this.truncateString(this.get('companiesSegment.description'), 20);
    }),

    disabledSave: computed('companiesSegment.name', function () {
      return !this.get('companiesSegment.name');
    }),

    status: computed('companiesSegment.data_type', 'companiesSegment.data_subject', function () {
      if (this.get('companiesSegment.is_system')) {
        return 'system';
      }
      if (this.get('companiesSegment.data_type') === 'to_be_defined' || this.get('companiesSegment.data_subject') === 'to_be_defined') {
        return 'auto_qualified';
      }
      if (this.get('companiesSegment.data_type') === null) {
        return 'unqualified';
      }
      return 'qualified';
    }),

    disabled: computed('feedbackCount', 'countLoading', function () {
      if (this.get('feedbackCount') == 0 && !this.get('countLoading')) {
        return false;
      }
      return true;
    }),

    editCategoryArray: computed('editCategory', function () {
      if (this.get('editCategory')) {
        return [this.get('editCategory')];
      }
      return [];
    }),

    displayedCategories: computed('filteredCategories', function () {
      var categories = this.get('filteredCategories');
      var index = categories.indexOf(this.get('editCategory'));
      if (index >= 0) {
        categories.splice(index, 1);
      }
      return categories;
    }),

    dueInputProps: computed('i18n.locale', 'current_company', function () {
      return {
        'class': 'filter-label',
        label: this.get('i18n').t('settings.company_segment.category'),
        placeholder: this.get('i18n').t('settings.company_segment.create_or_choose_category')
      };
    }),

    multiDropdownDynamicOptionProps: computed('i18n.locale', 'current_company', 'newCategoryName', function () {
      return {
        label: this.get('i18n').t('settings.company_segment.create_category'),
        icon: 'tio-add',
        color: '#23afcb',
        component: 'v2/multi-dropdown-dynamic-options/settings/segments/create-segment-category',
        onClick: 'createCategory',
        value: this.get('newCategoryName')
      };
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },

    closeModal: function closeModal() {
      this.set('modalIsOpened', false);
    },

    closeDeleteModal: function closeDeleteModal() {
      this.set('deleteModalIsOpened', false);
    },

    truncateString: function truncateString(str, num) {
      if (!str || str.length <= num) {
        return str;
      }
      return str.slice(0, num) + '...';
    },

    _searchCategory: function _searchCategory(term) {
      var _this = this;

      var searchPromise = this.store.query('companies-segment', {
        filter: { category: term }
      });
      this.set('search_promise', searchPromise);
      searchPromise.then(function (segments) {
        var categories = segments.map(function (segment) {
          return segment.get('category');
        });
        var existingCategory = categories.find(function (element) {
          return element == term;
        });
        if (term && !existingCategory && term != _this.get('editCategory')) {
          _this.set('displayDynamicOption', true);
        }
        _this.set('filteredCategories', categories);
      });
    },

    verifyCategoryName: function verifyCategoryName() {
      var _this2 = this;

      this.set('showSegmentError', false);
      this.set('showCharacterError', false);
      if (this.get('editCategory') === "") {
        this.set('editCategory', null);
      }
      if (this.get('editCategory')) {
        var forbiddenCategoryNames = ['segments', 'segment', 'no category'];
        var found = forbiddenCategoryNames.find(function (element) {
          return element == _this2.get('editCategory').toLowerCase();
        });
        if (found) {
          this.set('showSegmentError', true);
          return false;
        }
        var format = /[!-\/:-@[-`{-~]/;
        if (format.test(this.get('editCategory'))) {
          this.set('showCharacterError', true);
          return false;
        }
      }
      return true;
    },

    actions: {

      searchCategory: function searchCategory(onToggle, term) {
        this.set('displayDynamicOption', false);
        this.set('monitoredFocus', true);
        this.set('newCategoryName', term);
        onToggle('open');
        this._searchCategory(term);
      },

      addCategory: function addCategory(category) {
        this.set('editCategory', null);
        this.set('editCategory', category);
        this.set('displayDynamicOption', false);
        this.set('newCategoryName', null);
        this._searchCategory('');
      },

      createCategory: function createCategory() {
        this.set('editCategory', this.get('newCategoryName'));
        this.set('displayDynamicOption', false);
        this.set('newCategoryName', null);
        this._searchCategory('');
      },

      dispatchActions: function dispatchActions(action) {
        this.send(action);
      },

      onDeleteCategory: function onDeleteCategory() {
        this.set('editCategory', null);
        this.set('displayDynamicOption', false);
        this._searchCategory(this.get('newCategoryName'));
      },

      openModal: function openModal() {
        this.set('editName', this.get('companiesSegment.name'));
        this.set('editDescription', this.get('companiesSegment.description'));
        this.set('editType', this.get('companiesSegment.data_type'));
        this.set('editSubject', this.get('companiesSegment.data_subject'));
        this.set('editDelay', this.get('companiesSegment.anonymization_delay'));
        this.set('editCategory', this.get('companiesSegment.category'));
        this.set('newCategoryName', null);
        this.set('displayDynamicOption', false);
        this.set('showDelayError', false);
        this.set('showSegmentError', false);
        this.set('showCharacterError', false);
        this.set('modalIsOpened', true);
        this._searchCategory('');
      },

      clearDelay: function clearDelay() {
        this.set('editDelay', null);
      },

      openDeleteModal: function openDeleteModal() {
        var _this3 = this;

        this.set('countLoading', true);
        this.set('deleteModalIsOpened', true);
        this.get('store').find('companies_segment_count', this.get('companiesSegment.id')).then(function (companiesSegmentCount) {
          _this3.set('feedbackCount', companiesSegmentCount.get('feedback_count'));
          _this3.set('countLoading', false);
          companiesSegmentCount.unloadRecord();
        });
      },

      closeModal: function closeModal() {
        this.closeModal();
      },

      closeDeleteModal: function closeDeleteModal() {
        this.closeDeleteModal();
      },

      editSegment: function editSegment() {
        var _this4 = this;

        if (!this.verifyCategoryName()) {
          return;
        }
        this.set('showDelayError', this.get('editDelay') && this.get('editDelay') < 1);
        if (this.get('showDelayError')) {
          return;
        }
        var editCategory = this.get('editCategory') ? this.get('editCategory').toLowerCase() : null;
        this.set('companiesSegment.name', this.get('editName'));
        this.set('companiesSegment.category', editCategory);
        this.set('companiesSegment.description', this.get('editDescription'));
        this.set('companiesSegment.data_type', this.get('editType'));
        this.set('companiesSegment.data_subject', this.get('editSubject'));
        this.set('companiesSegment.anonymization_delay', this.get('editDelay'));
        this.get('companiesSegment').save().then(function () {
          _this4.closeModal();
        });
      },

      deleteCompaniesSegment: function deleteCompaniesSegment() {
        var _this5 = this;

        var companiesSegment = this.get('companiesSegment');
        this.store.peekRecord('segment', companiesSegment.get('segment.id')).unloadRecord();
        companiesSegment.destroyRecord().then(function () {
          _this5.get('reloadSegments')();
          _this5.closeDeleteModal();
        });
      }
    }

  });
});