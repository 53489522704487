define('due-dashboard/adapters/segment-value', ['exports', 'due-dashboard/adapters/application'], function (exports, _dueDashboardAdaptersApplication) {
  exports['default'] = _dueDashboardAdaptersApplication['default'].extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      var res = this._super.apply(this, arguments);
      if (query.filter.segment_id) {
        res += '/' + query.filter.segment_id;delete query.filter.segment_id;
      }
      return res;
    }
  });
});