define('due-dashboard/routes/settings/integrations/intercom', ['exports', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({
    includeSurveys: 'survey',
    model: function model(_, transition) {
      var _this = this;

      return this._super(null, transition).then(function (parentModel) {
        return _this.store.query('client', { filter: { with_email: 'true' }, page: { number: '1', size: '1' } }).then(function (result) {
          parentModel.total_clients_count = result.get('meta.total-count');
          return parentModel;
        });
      });
    }
  });
});