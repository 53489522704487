define('due-dashboard/controllers/settings/digests/index', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    maxDigestsLimit: computed.alias('currentAccount.content.company.max_digests_limit'),

    currentDigestDropdown: null,
    digestSortProperties: ['updated_at:desc'],
    sortedDigests: computed.sort('model.digests', 'digestSortProperties'),

    limitedModal: {
      trigger: {
        textKey: 'digest.create',
        disabled: false,
        buttonIcon: 'tio-add',
        buttonClass: 'limited',
        buttonIntent: 'primary'
      },
      title: { textKey: 'digest.limitation_modal.title', 'class': '' },
      content: { textKey: 'digest.limitation_modal.text', 'class': '' },
      cancelBtn: { textKey: 'words.cancel', intent: 'secondary', 'class': '' },
      buttons: [{ action: 'mailSupport', textKey: 'digest.limitation_modal.contact_action', customClass: '', intent: 'primary' }]
    },

    canCreateNewDigest: computed('model.digests', 'model.digests.[]', 'maxDigestsLimit', function () {
      return this.get('model.digests.length') < this.get('maxDigestsLimit');
    }),

    actions: {
      triggerAction: function triggerAction(action) {
        this.send(action);
      },

      editDigest: function editDigest(digest) {
        this.transitionToRoute('settings.digests.edit', digest.id);
      },

      duplicateDigest: function duplicateDigest(original_digest, duplicate_subscriber_list) {
        var _this = this;

        this.store.createRecord('due-digest', {
          duplicate_from: original_digest.id,
          duplicate_subscriber_list: duplicate_subscriber_list
        }).save().then(function (digest) {
          return _this.transitionToRoute('settings.digests.edit', digest.id);
        });
      },

      deleteDigest: function deleteDigest(digest) {
        if (window.confirm(this.get('i18n').t('digest.confirm_delete'))) {
          digest.destroyRecord();
        }
      },

      pauseDigest: function pauseDigest(digest) {
        digest.live = false;
        digest.save();
      },

      mailSupport: function mailSupport() {
        window.open("mailto:support@diduenjoy.com");
      },

      newDigest: function newDigest() {
        var _this2 = this;

        this.set('newDigestPromise', this.store.createRecord('due-digest', {
          company: this.get('currentAccount.content.company')
        }).save().then(function (digest) {
          return _this2.transitionToRoute('settings.digests.edit', digest.id);
        }));
      },

      setCurrentDigestDropdown: function setCurrentDigestDropdown(idx) {
        this.set('currentDigestDropdown', idx);
      }
    }
  });
});