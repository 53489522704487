define('due-dashboard/components/v2/libraries/surveys/navbar/elements/-single-select/-key-value-option', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: [''],
    isOpen: false,

    actions: {
      onToggle: function onToggle() {
        this.toggleProperty('isOpen');
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 */