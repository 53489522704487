define("due-dashboard/templates/settings/integrations/trustpilot/configure", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/trustpilot/configure.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["settings.integrations.trustpilot.review.activated"], [], ["loc", [null, [5, 10], [5, 67]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 4
              },
              "end": {
                "line": 18,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/settings/integrations/trustpilot/configure.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element5, 'class');
            morphs[1] = dom.createElementMorph(element5);
            morphs[2] = dom.createMorphAt(element5, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [17, 55], [17, 67]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "parent.actions.connect", ["loc", [null, [17, 23], [17, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 14], [17, 47]]], 0, 0], ["content", "button.text", ["loc", [null, [17, 70], [17, 85]]], 0, 0, 0, 0]],
          locals: ["button"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/trustpilot/configure.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "class", "marged-li");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "new-trustpilot-url");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element7, 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element7, [3]), 1, 1);
          morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["settings.integrations.trustpilot.review.description"], [], ["loc", [null, [9, 10], [9, 69]]], 0, 0], ["inline", "t", ["settings.integrations.trustpilot.your_domain"], [], ["loc", [null, [10, 28], [10, 80]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "model.company_integration.domain", ["loc", [null, [12, 28], [12, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["settings.integrations.trustpilot.your_domain"], [], ["loc", [null, [12, 73], [12, 123]]], 0, 0]], ["loc", [null, [12, 10], [12, 125]]], 0, 0], ["block", "due-button", [], ["type", "transition", "text", ["subexpr", "t", ["settings.integrations.trustpilot.review.connect"], [], ["loc", [null, [16, 41], [16, 94]]], 0, 0], "class", "marged-li", "disabled", ["subexpr", "@mut", [["get", "parent.cannotConnect", ["loc", [null, [16, 122], [16, 142]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [16, 4], [18, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 2
            },
            "end": {
              "line": 28,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/trustpilot/configure.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["settings.integrations.trustpilot.bgl.activated"], [], ["loc", [null, [26, 10], [26, 64]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 4
              },
              "end": {
                "line": 49,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/settings/integrations/trustpilot/configure.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [48, 56], [48, 68]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "parent.actions.saveKeys", ["loc", [null, [48, 23], [48, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 14], [48, 48]]], 0, 0], ["content", "button.text", ["loc", [null, [48, 71], [48, 86]]], 0, 0, 0, 0]],
          locals: ["button"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 2
            },
            "end": {
              "line": 50,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/trustpilot/configure.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "class", "marged-li");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "new-trustpilot-url");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "class", "marged-li");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "new-trustpilot-url");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "class", "marged-li");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "new-trustpilot-url");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(element1, [5]);
          var element4 = dom.childAt(element1, [7]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element2, 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
          morphs[3] = dom.createMorphAt(element3, 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
          morphs[5] = dom.createMorphAt(element4, 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
          morphs[7] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["settings.integrations.trustpilot.bgl.description"], [], ["loc", [null, [30, 10], [30, 66]]], 0, 0], ["inline", "t", ["settings.integrations.trustpilot.your_domain"], [], ["loc", [null, [31, 28], [31, 80]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "model.company_integration.domain", ["loc", [null, [33, 28], [33, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["settings.integrations.trustpilot.your_domain"], [], ["loc", [null, [33, 73], [33, 123]]], 0, 0]], ["loc", [null, [33, 10], [33, 125]]], 0, 0], ["inline", "t", ["settings.integrations.trustpilot.bgl.encription_key"], [], ["loc", [null, [36, 28], [36, 87]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "model.company_integration.encription_key", ["loc", [null, [38, 28], [38, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["settings.integrations.trustpilot.bgl.encription_key"], [], ["loc", [null, [38, 81], [38, 138]]], 0, 0]], ["loc", [null, [38, 10], [38, 140]]], 0, 0], ["inline", "t", ["settings.integrations.trustpilot.bgl.authentication_key"], [], ["loc", [null, [41, 28], [41, 91]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "model.company_integration.auth_key", ["loc", [null, [43, 28], [43, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["settings.integrations.trustpilot.bgl.authentication_key"], [], ["loc", [null, [43, 75], [43, 136]]], 0, 0]], ["loc", [null, [43, 10], [43, 138]]], 0, 0], ["block", "due-button", [], ["type", "save", "text", ["subexpr", "t", ["settings.integrations.trustpilot.bgl.save"], [], ["loc", [null, [47, 35], [47, 82]]], 0, 0], "class", "marged-li"], 0, null, ["loc", [null, [47, 4], [49, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 53,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/trustpilot/configure.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-block config");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-block config");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var element9 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element8, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element9, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["settings.integrations.trustpilot.review.title"], [], ["loc", [null, [2, 6], [2, 59]]], 0, 0], ["block", "if", [["get", "model.company_integration.activate_retrieve", ["loc", [null, [3, 8], [3, 51]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 2], [19, 9]]]], ["inline", "t", ["settings.integrations.trustpilot.bgl.title"], [], ["loc", [null, [23, 6], [23, 56]]], 0, 0], ["block", "if", [["get", "model.company_integration.activate_scenario", ["loc", [null, [24, 8], [24, 51]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [24, 2], [50, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});