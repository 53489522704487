define('due-dashboard/components/dashboard-filters/sentiments', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var A = _ember['default'].A;
  exports['default'] = Component.extend({

    sentimentOptions: A([{ name: 'positive', value: 2, icon: 'thumb_up_outlined', color: '#00B548' }, { name: 'neutral', value: 1, icon: 'thumb_left_outlined', color: '#FAB005' }, { name: 'negative', value: 0, icon: 'thumb_down_outlined', color: '#FA5252' }, { name: 'without', value: -1, icon: 'label', color: '#979797' }]),

    selectedSentiments: computed.map('filter.value', function (value) {
      return this.get('sentimentOptions').findBy('value', value);
    }),

    actions: {
      sentimentToggled: function sentimentToggled(sentiment) {
        var selected = this.get('filter.value'),
            toggled = sentiment.value;

        if (selected.includes(toggled)) {
          selected.removeObject(toggled);
        } else {
          selected.pushObject(toggled);
        }
      },

      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});