define('due-dashboard/components/verbatims/display-settings', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: '',
    disabledSave: true,

    verbatimDisplay: computed('model', function () {
      if (!this.get('model')) {
        return this.store.createRecord('verbatim-display-option', {
          keywords: true,
          segments: true,
          tags: true,
          status: true,
          messaging: true
        });
      }
      return this.get('model');
    }),

    displayOptions: computed('verbatimDisplay', function () {
      var displayOpts = this.get('verbatimDisplay'),
          options = [];
      displayOpts.eachAttribute(function (name) {
        options.push({ key: name, translateKey: 'verbatims.displays.' + name, isActive: displayOpts.get(name) });
      });
      return options;
    }),

    closeModal: function closeModal() {
      this.set('modalIsOpened', false);
    },

    actions: {
      openModal: function openModal() {
        this.set('modalIsOpened', true);
      },

      closeModal: function closeModal() {
        this.get('verbatimDisplay').rollbackAttributes();
        this.notifyPropertyChange('verbatimDisplay');
        this.closeModal();
      },

      updateOption: function updateOption(option) {
        this.set('disabledSave', false);
        this.toggleProperty('verbatimDisplay.' + option);
      },

      saveDisplaySettings: function saveDisplaySettings() {
        var _this = this;

        this.get('verbatimDisplay').save().then(function (displayOptions) {
          _this.get('saveAction')(displayOptions);
          _this.set('disabledSave', true);
          _this.closeModal();
        });
      }
    }
  });
});