define('due-dashboard/components/reports/due-report-table/due-report-table-dynamic', ['exports', 'ember', 'due-dashboard/helpers/current-timezone', 'due-dashboard/objects/reports/due-report-table-params', 'due-dashboard/objects/feedback-search/filter-set', 'due-dashboard/objects/reports/data-converter/table-data-converter-factory'], function (exports, _ember, _dueDashboardHelpersCurrentTimezone, _dueDashboardObjectsReportsDueReportTableParams, _dueDashboardObjectsFeedbackSearchFilterSet, _dueDashboardObjectsReportsDataConverterTableDataConverterFactory) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-table-dynamic'],
    classNameBindings: ['layout'],

    allColumns: ['index', 'icon', 'main-label', 'number-trend', 'breakdown', 'positive-impact', 'negative-impact', 'label-with-sub'],
    globalFilters: service('feedback-search/global'),
    dataQuery: service('requests/data-query'),

    rootDefaultLimit: 10,
    nestedDefaultLimit: 25,
    tableParams: null,
    dataConverter: null,
    displayViewBy: false,
    isLoading: true,
    isEmpty: false,
    dataLoaded: false,

    exportModalIsOpened: false,
    progressModalIsOpened: false,
    emptyModalIsOpened: false,

    // Popups automatically triggered by array view_type field
    questionsListPopupToggle: false,
    tagsListPopupToggle: false,

    popups: {
      'questions-breakdown': 'questionsListPopupToggle',
      'tags-breakdown': 'tagsListPopupToggle'
    },

    reloadObserver: observer('reload', function () {
      this.setTableParams();
      this.initializeVariables();
      this.setViewBySection({ reload: true });
      this.fetchData();
    }),

    querySegments: computed(function () {
      if (!this.tableParams.segments) {
        return null;
      }

      var segments = this.tableParams.segments,
          querySegments = {};

      Object.entries(segments).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var segment = _ref2[0];
        var value = _ref2[1];

        querySegments[segment.toLowerCase()] = value;
      });
      return querySegments;
    }),

    popupEnabled: computed('', function () {
      return Object.keys(this.get('popups')).includes(this.tableParams.view_type);
    }),

    showLineMapping: computed('showLines', function () {
      return {
        lines: this.get('showLines')
      };
    }),

    availableExportFormats: computed('tableParams.type', function () {
      var type = this.get('tableParams.type');

      switch (type) {
        case 'tag-breakdown':
          return ['xlsx'];
        case 'segment-breakdown':
          return ['csv', 'xlsx'];
      }
    }),

    customExportOptions: computed('tableParams.translation', function () {
      var tag = this.get('currentLanguage');

      var opts = this.tableParams.translations[tag].headers.map(function (opt) {
        return { label: opt };
      });
      opts.shift();
      return opts;
    }),

    displayDownload: computed('tableParams.view_type', 'tableParams.type', function () {
      return ['segment-breakdown', 'tag-breakdown'].includes(this.tableParams.type);
    }),

    init: function init() {
      this._super();
      this.setTableParams();
      this.initializeVariables();
      this.setViewBySection();
      this.setTopSection();
      this.setDataConverter();
      this.fetchData();
    },

    setTopSection: function setTopSection() {
      this.set('displayTopSection', this.get('displayViewBy') || this.get('viewTypeSegments') || this.get('displayDownload') || this.tableParams.hasTitle);
    },

    setViewBySection: function setViewBySection() {
      var reload = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      if (!this.tableParams.view_by) {
        return;
      }

      var querySegments = this.get('querySegments');
      var viewByOpts = Object.keys(querySegments).map(function (s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
      });

      var opt = viewByOpts[0];
      var defaultViewBy = this.tableParams.default_view_by;
      if (defaultViewBy && Object.keys(querySegments).includes(defaultViewBy)) {
        opt = defaultViewBy.charAt(0).toUpperCase() + defaultViewBy.slice(1);
      }

      this.set('viewByOpts', viewByOpts);
      this.set('selectedViewBy', opt);
      if (opt !== undefined) {
        this.tableParams.object_id = querySegments[opt.toLowerCase()];
      }
      this.set('displayViewBy', viewByOpts.length > 1);
      this.set('viewTypeSegmentBreakdown', this.tableParams.view_type == 'segment-breakdown');
      this.set('resetViewBy', reload);
    },

    setDataConverter: function setDataConverter() {
      var dataConverter = _dueDashboardObjectsReportsDataConverterTableDataConverterFactory['default'].create({ type: this.tableParams.get('type') });
      if (dataConverter) {
        this.dataConverter = dataConverter.getConverter();
      }
    },

    getFilters: function getFilters() {
      var global_filters = this.get('globalFilters.current'),
          report_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('reportFilters')),
          component_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('tableParams.componentFilters'));
      return global_filters.merge(report_filters, component_filters);
    },

    setTableParams: function setTableParams() {
      if (this.tableParams == null) {
        this.tableParams = _dueDashboardObjectsReportsDueReportTableParams['default'].create(this.get('content'));
      }

      var profile = this.get('currentAccount.content.profile');
      var reportsHierarchy = this.get('currentAccount.content.account_custom_profile_right.content.reports_hierarchy') || [];
      this.tableParams.filterSegments(profile, reportsHierarchy);

      var merged = this.getFilters();

      this.tableParams.setFilters(merged.toDataQueryFormat());
      this.tableParams.setDateRange(merged.completed_at.start, merged.completed_at.end);
      this.tableParams.setQuerySegments(this.get('querySegments'));
      this.tableParams.setTimezone(_dueDashboardHelpersCurrentTimezone['default'].compute());
      this.tableParams.setTranslations(this.get('currentLanguage'));
      return this.tableParams;
    },

    data: computed('content_data', function () {
      var content_data = this.get('content_data');
      var formattedContentData = this.dataConverter.formatData({
        tableParams: this.tableParams,
        data: content_data,
        selectedViewBy: this.get('selectedViewBy'),
        currentLanguage: this.get('currentLanguage'),
        valuesHierarchy: this.get('values_hierarchy'),
        i18n: this.get('i18n'),
        remainingLinesNumber: this.get('totalCount') - content_data.length,
        loadingState: this.get('isLoading')
      });
      this.dataConverter.addLevel(this.get('table_data.rows'), formattedContentData.rows, this.get('values_hierarchy'));
      return {
        headers: formattedContentData.headers,
        rows: this.get('table_data.rows')
      };
    }),

    fetchData: function fetchData() {
      var _this = this;

      var noLimit = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      if (this.tableParams == undefined) {
        this.set('isEmpty', true);
        return;
      }
      this.tableParams.set('values_hierarchy', this.get('values_hierarchy'));
      var levelLimit = this._getLevelLimit(this.tableParams);
      this.tableParams.set('page_size', noLimit ? undefined : levelLimit);
      this.set('isLoading', true);
      this.set('isEmpty', false);
      this.set('content_data', [[{ type: 'loading' }]]);
      this.get('dataQuery').fetchData(this.tableParams).then(function (response) {
        var content_data = response.get('content_data') || [];
        _this.set('isEmpty', content_data.length === 0);
        _this.set('pageCount', response.get('page_count') || 1);
        _this.set('totalCount', response.get('total_count') || 0);
        if (levelLimit && noLimit) {
          content_data = content_data.slice(levelLimit);
          _this.set('totalCount', 0);
        }
        _this.set('content_data', content_data);
        _this.set('isLoading', false);
        _this.set('dataLoaded', !_this.get('isEmpty'));
      })['catch'](function (error) {
        // Empty state when catched errors
        _this.set('isLoading', false);
        _this.set('isEmpty', true);
      });
    },

    _getLevelLimit: function _getLevelLimit(tableParams) {
      var segment_limits = tableParams.segment_limits || {};
      var lvl = tableParams.values_hierarchy.length;
      var defaultValue = lvl == 0 ? this.rootDefaultLimit : this.nestedDefaultLimit;
      return segment_limits[Object.keys(tableParams.segments)[lvl]] || defaultValue;
    },

    _closeExportModal: function _closeExportModal() {
      this.set('emptyModalIsOpened', false);
      this.set('exportModalIsOpened', false);
    },

    _closeProgressModal: function _closeProgressModal() {
      this.set('progressModalIsOpened', false);
    },

    initializeVariables: function initializeVariables() {
      this.set('dataLoaded', false);
      this.set('maxDepth', this.tableParams.segment_ids.length);
      this.set('showLines', {});
      this.set('values_hierarchy', []);
      this.set('content_data', []);
      this.set('table_data', {
        rows: []
      });
    },

    _formatTableParamsToExport: function _formatTableParamsToExport(exportConfig) {
      var tableParams = _.cloneDeep(this.tableParams);
      tableParams.properties = this.tableParams.properties.map(function (property, index) {
        if (exportConfig.custom_columns_list[index].selected || exportConfig.content_option === 'all') return property;
      }).filter(function (x) {
        return x;
      });
      tableParams.filters = this.getFilters().toDataQueryFormat();
      return tableParams;
    },

    actions: {

      changeViewBy: function changeViewBy(selectedViewBy) {
        this.tableParams.object_id = this.get('querySegments')[selectedViewBy.toLowerCase()];
        var segment_ids = Object.values(this.tableParams.view_by.segments);
        var idx = segment_ids.indexOf(this.tableParams.object_id);
        this.tableParams.set('segment_ids', segment_ids.slice(idx));
        this.initializeVariables();
        this.set('selectedViewBy', selectedViewBy);
        this.fetchData();
        if (!this.get('resetViewBy')) {
          this.element.scrollIntoView({ behavior: "smooth" });
        }
        this.set('resetViewBy', false);
      },

      openExportModal: function openExportModal() {
        if (!this.get('isEmpty') && !this.get('isLoading')) {
          this.set('exportModalIsOpened', true);
        } else {
          this.set('emptyModalIsOpened', true);
        }
      },

      closeNameModal: function closeNameModal() {
        this._closeExportModal();
      },

      'export': function _export(exportConfig) {
        var _this2 = this;

        var formattedContent = this._formatTableParamsToExport(exportConfig);
        var content = JSON.stringify(formattedContent.toJson());
        var export_type = exportConfig.export_format;
        var content_type = exportConfig.content_type;
        var file_file_name = exportConfig.file_name;
        var timezone = _dueDashboardHelpersCurrentTimezone['default'].compute();

        var exportModel = ({
          'segment-breakdown': 'segment-table-export-request',
          'tag-breakdown': 'tag-breakdown-export-request'
        })[this.tableParams.type];

        var promise = this.get('store').createRecord(exportModel, {
          content: content,
          export_type: export_type,
          content_type: content_type,
          file_file_name: file_file_name,
          timezone: timezone
        }).save();

        this.set('export_promise', promise);
        promise.then(function () {
          _this2.send('closeExportModal');
          _this2.set('progressModalIsOpened', true);
        });
      },

      changeFormat: function changeFormat(format) {
        this.set('newFormat', format);
      },

      changeFileContent: function changeFileContent(fileContent) {
        this.set('fileContent', fileContent);
      },

      closeExportModal: function closeExportModal() {
        this._closeExportModal();
      },

      goToHistory: function goToHistory() {
        this.get('redirectAction')('companies.exports.history');
        this._closeProgressModal();
      },

      closeProgressModal: function closeProgressModal() {
        this._closeProgressModal();
      },

      collapseLine: function collapseLine(id, values_hierarchy) {
        if (this.get('isLoading')) {
          return;
        }
        var dataAlreadyLoaded = (id in this.get('showLines'));
        this.get('showLines')[id] = !this.get('showLines')[id];
        if (!this.get('showLines')[id] || dataAlreadyLoaded) {
          this.notifyPropertyChange('showLines');
          return;
        }
        this.set('values_hierarchy', values_hierarchy);
        this.notifyPropertyChange('showLines');
        run.later(this, this.fetchData, 200); // added 200 latency to let the css "chevron going down" animation being played
      },

      showMore: function showMore(values_hierarchy) {
        if (this.get('isLoading')) {
          return;
        }
        this.set('values_hierarchy', values_hierarchy);
        this.fetchData(true);
      },

      validateExport: function validateExport(exportConfig) {
        this.send('export', exportConfig);
      }
    }
  });
});