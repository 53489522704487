define('due-dashboard/models/subscription-plan', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    name: attr('string'),
    duty_free_amount: attr('number'),
    custom: attr('boolean'),
    limit: attr('number'),
    duty_free_additional_charge: attr('number'),
    amount: attr('number'),
    is_unicorn: attr('boolean'),

    humanized_duty_free_amount: computed('duty_free_amount', function () {
      return (this.get('duty_free_amount') / 100).toFixed(2);
    }),

    humanized_duty_free_additional_charge: computed('duty_free_additional_charge', function () {
      return (this.get('duty_free_additional_charge') / 100).toFixed(2);
    }),

    planClass: computed('name', 'is_unicorn', function () {
      if (this.get('name') === 'entrepreneur') {
        return 'entrepreneur';
      } else if (this.get('name') === 'salesforce') {
        return 'salesforce';
      } else if (this.get('is_unicorn')) {
        return 'unicorn';
      } else {
        return 'secret';
      }
    })

  });
});