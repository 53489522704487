define('due-dashboard/components/typed-text-field', ['exports', 'ember'], function (exports, _ember) {
  var TextField = _ember['default'].TextField;
  var observer = _ember['default'].observer;
  exports['default'] = TextField.extend({
    attributeBindings: ['type'],

    updateValidity: observer('value', function () {
      var el = this.$()[0];
      this.set('valid', el.validity.valid);
    })

  });
});