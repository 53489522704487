define('due-dashboard/components/debounced-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextField.extend({
    debounceWait: 500,
    fireAtStart: false,
    onChange: null,

    _elementValueDidChange: function _elementValueDidChange() {
      _ember['default'].run.debounce(this, this._setValue, this.debounceWait, this.fireAtStart);
    },

    _setValue: function _setValue() {
      if (this.$()) {
        this.set('value', this.$().val());
        var onChange = this.get('onChange');
        if (onChange) onChange();
      }
    }
  });
});
//
// To be used like this:
//
// {{debounced-input
//   placeholder="1000000"
//   value=propertyName
//   debounceWait=300 <-- debounce wait value
//   fireAtStart=false <-- corresponds to Ember.run.debounce’s 4th param, if false, will run at the end of wait period
//   class="form-control" <-- all regular text input attributes work
//   name="price"
// }}