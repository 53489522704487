define('due-dashboard/components/previous-button', ['exports', 'due-dashboard/components/due-button', 'ember'], function (exports, _dueDashboardComponentsDueButton, _ember) {
  exports['default'] = _dueDashboardComponentsDueButton['default'].extend({

    type: 'cancel',
    CustomClass: 'inline-block',

    text: _ember['default'].computed('i18n.locale', function () {
      return this.get('i18n').t('prev_step');
    })

  });
});