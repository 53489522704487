define('due-dashboard/models/chat-repartition', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    all_count: _emberData['default'].attr(),
    new_count: _emberData['default'].attr(),
    resolved_count: _emberData['default'].attr(),
    ongoing_count: _emberData['default'].attr(),
    deleted_count: _emberData['default'].attr(),

    update_counters: function update_counters(new_status, old_status) {
      this.increase(new_status);
      switch (new_status) {
        case 'new':
          if (old_status === 'deleted') {
            this.increase('ongoing');
            this.increase('all');
            this.decrease('deleted');
          } else if (old_status === 'resolved') {
            this.increase('ongoing');
            this.decrease('resolved');
          }
          break;
        case 'ongoing':
          if (old_status === 'deleted') {
            this.increase('all');
            this.decrease('deleted');
          } else if (old_status === 'new') {
            this.decrease('new');
          } else if (old_status === 'resolved') {
            this.decrease('resolved');
          }
          break;
        case 'resolved':
          if (old_status === 'deleted') {
            this.increase('all');
            this.decrease('deleted');
          } else if (old_status === 'ongoing') {
            this.decrease('ongoing');
          } else if (old_status === 'new') {
            this.decrease('ongoing');
            this.decrease('new');
          }
          break;
        case 'deleted':
          this.decrease('all');
          if (old_status === 'new') {
            this.decrease('ongoing');
            this.decrease('new');
          } else if (old_status === 'ongoing') {
            this.decrease('ongoing');
          } else if (old_status === 'resolved') {
            this.decrease('resolved');
          }
          break;
        default:
          break;
      }
    },

    increase: function increase(status) {
      var key_name = status + '_count';
      this.set(key_name, this.get(key_name) + 1);
    },

    decrease: function decrease(status) {
      var key_name = status + '_count';
      this.set(key_name, this.get(key_name) - 1);
    }
  });
});