define('due-dashboard/initializers/setup-cookie', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'cookie', 'cookie:main');
    application.inject('controller', 'cookie', 'cookie:main');
    application.inject('component', 'cookie', 'cookie:main');
    application.inject('view', 'cookie', 'cookie:main');
  }

  exports['default'] = {
    after: ['cookie'],
    initialize: initialize
  };
});