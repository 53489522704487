define('due-dashboard/components/digests/digest-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'tr',
    modalIsOpened: false,
    currentItem: 0,
    openDropdown: computed('currentDigestDropdown', function () {
      return this.get('idx') == this.get('currentDigestDropdown');
    }),

    componentActions: computed('digest.live', {
      get: function get() {
        if (this.get('digest.live')) {
          return [{ icon: 'tio-copy', size: 'small', intent: 'secondary', action: 'openDuplicateDigestModal', text: this.get('i18n').t('words.duplicate_maj') }, { icon: 'tio-delete', size: 'small', intent: 'danger', action: 'deleteDigest', text: this.get('i18n').t('surveys.delete') }, { icon: 'tio-pause', size: 'small', intent: 'secondary', action: 'pauseComp', text: this.get('i18n').t('digest.pause') }];
        }
        return [{ icon: 'tio-copy', size: 'small', intent: 'secondary', action: 'openDuplicateDigestModal', text: this.get('i18n').t('words.duplicate_maj') }, { icon: 'tio-delete', size: 'small', intent: 'danger', action: 'deleteDigest', text: this.get('i18n').t('surveys.delete') }];
      }
    }),

    currentSubTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag') + '.components.' + this.get('comp.id') + '.subtitle');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag') + '.components.' + this.get('comp.id') + '.subtitle', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    duplicateModal: {
      trigger: {
        textKey: 'words.duplicate_maj',
        disabled: false,
        buttonIcon: 'tio-add',
        buttonClass: '',
        buttonIntent: 'primary'
      },
      title: { textKey: 'digest.duplicate_modal.title', 'class': '' },
      content: {
        withUser: {
          title: { textKey: 'digest.duplicate_modal.content.with_user.title' },
          text: { textKey: 'digest.duplicate_modal.content.with_user.text' }
        },
        withoutUser: {
          title: { textKey: 'digest.duplicate_modal.content.without_user.title' },
          text: { textKey: 'digest.duplicate_modal.content.without_user.text' }
        }
      },
      cancelBtn: { textKey: 'words.cancel', 'class': 'cancel-duplication', intent: 'tertiary' },
      buttons: [{ action: 'duplicateDigest', textKey: 'words.duplicate_maj', customClass: '', intent: 'primary' }]
    },

    actions: {
      triggerAction: function triggerAction(action) {
        this.send(action);
      },

      editDigest: function editDigest() {
        this.get('editDigest')(this.get('digest'));
      },

      triggerAndClose: function triggerAndClose() {
        this.get('duplicateDigest')(this.get('digest'), this.get('currentItem') == 0);
        this.set('modalIsOpened', false);
      },

      openDuplicateDigestModal: function openDuplicateDigestModal() {
        this.set('duplicateSubscribers', true);
        this.set('modalIsOpened', true);
      },

      closeDuplicateDigestModal: function closeDuplicateDigestModal() {
        this.set('modalIsOpened', false);
      },

      toggleDuplicateSubscribers: function toggleDuplicateSubscribers() {
        this.toggleProperty('duplicateSubscribers');
      },

      deleteDigest: function deleteDigest() {
        this.get('deleteDigest')(this.get('digest'));
      },

      pauseComp: function pauseComp() {
        this.get('pauseDigest')(this.get('digest'));
      },

      redirectMailSupport: function redirectMailSupport() {
        this.get('mailSupport')();
        this.set('modalIsOpened', false);
      },

      setCurrentItem: function setCurrentItem(currentItem) {
        this.set('currentItem', currentItem);
      },

      setCurrentDigestDropdown: function setCurrentDigestDropdown() {
        if (this.get('idx') == this.get('currentDigestDropdown')) {
          this.get('setCurrentDigestDropdown')(undefined);
        } else {
          this.get('setCurrentDigestDropdown')(this.get('idx'));
        }
      },

      resetCurrentDigestDropdown: function resetCurrentDigestDropdown() {
        this.get('setCurrentDigestDropdown')(undefined);
      }
    }
  });
});