define("due-dashboard/templates/settings/digests/translations", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/settings/digests/translations.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "actions");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(element2, 3, 3);
          return morphs;
        },
        statements: [["inline", "due-btn", [], ["action", ["subexpr", "action", ["cancelChanges"], [], ["loc", [null, [3, 21], [3, 45]]], 0, 0], "textKey", "sidebar.filters.cancel", "intent", "secondary", "size", "medium"], ["loc", [null, [3, 4], [3, 113]]], 0, 0], ["inline", "due-btn", [], ["action", ["subexpr", "action", ["saveChanges"], [], ["loc", [null, [4, 21], [4, 43]]], 0, 0], "promise", ["subexpr", "@mut", [["get", "save_promise", ["loc", [null, [4, 52], [4, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "routeTransition", true, "textKey", "digest.save", "intent", "primary", "size", "medium"], ["loc", [null, [4, 4], [4, 140]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 16
              },
              "end": {
                "line": 18,
                "column": 16
              }
            },
            "moduleName": "due-dashboard/templates/settings/digests/translations.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "remove-language-icon");
            var el2 = dom.createTextNode("x");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'onclick');
            return morphs;
          },
          statements: [["attribute", "onclick", ["subexpr", "action", ["removeLanguage", ["get", "language", ["loc", [null, [17, 87], [17, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [17, 97]]], 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 12
            },
            "end": {
              "line": 20,
              "column": 12
            }
          },
          "moduleName": "due-dashboard/templates/settings/digests/translations.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("th");
          dom.setAttribute(el1, "class", "language-title");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" (");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(")\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 0, 0);
          morphs[1] = dom.createMorphAt(element1, 2, 2);
          morphs[2] = dom.createMorphAt(element1, 4, 4);
          return morphs;
        },
        statements: [["content", "language.name", ["loc", [null, [15, 41], [15, 58]]], 0, 0, 0, 0], ["content", "language.tag", ["loc", [null, [15, 60], [15, 76]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "not", [["subexpr", "eq", [["get", "language.tag", ["loc", [null, [16, 31], [16, 43]]], 0, 0, 0, 0], ["get", "lowerCurrentTag", ["loc", [null, [16, 44], [16, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 27], [16, 60]]], 0, 0]], [], ["loc", [null, [16, 22], [16, 61]]], 0, 0]], [], 0, null, ["loc", [null, [16, 16], [18, 23]]]]],
        locals: ["language"],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 12
            },
            "end": {
              "line": 23,
              "column": 12
            }
          },
          "moduleName": "due-dashboard/templates/settings/digests/translations.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "tags/add-language", [], ["languages", ["subexpr", "@mut", [["get", "remainingLanguages", ["loc", [null, [22, 44], [22, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "addLanguageAction", ["subexpr", "action", ["addLanguage"], [], ["loc", [null, [22, 81], [22, 103]]], 0, 0]], ["loc", [null, [22, 14], [22, 105]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 10
            },
            "end": {
              "line": 36,
              "column": 10
            }
          },
          "moduleName": "due-dashboard/templates/settings/digests/translations.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "digests/parts/component-row-translation-part", [], ["comp", ["subexpr", "@mut", [["get", "line", ["loc", [null, [35, 64], [35, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "languages", ["subexpr", "@mut", [["get", "selectedLanguages", ["loc", [null, [35, 79], [35, 96]]], 0, 0, 0, 0]], [], [], 0, 0], "translations", ["subexpr", "@mut", [["get", "digest.draft_translations", ["loc", [null, [35, 110], [35, 135]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [35, 12], [35, 137]]], 0, 0]],
        locals: ["line"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 41,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/settings/digests/translations.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "parameter");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "translations-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "translations-content-wrapper");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("table");
        dom.setAttribute(el4, "class", "translations-table");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("thead");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "legend-column");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tbody");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        dom.setAttribute(el6, "class", "bloc-title");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        dom.setAttribute(el7, "class", "legend-column title");
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        dom.setAttribute(el6, "class", "bloc-title");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        dom.setAttribute(el7, "class", "legend-column title");
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [1, 1, 1, 1]);
        var element4 = dom.childAt(element3, [1, 1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element4, 3, 3);
        morphs[2] = dom.createMorphAt(element4, 4, 4);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [1, 1, 0]), 0, 0);
        morphs[4] = dom.createMorphAt(element5, 3, 3);
        morphs[5] = dom.createMorphAt(dom.childAt(element5, [5, 1, 0]), 0, 0);
        morphs[6] = dom.createMorphAt(element5, 7, 7);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "dashboard-title", [], [], 0, null, ["loc", [null, [1, 0], [6, 20]]]], ["block", "each", [["get", "selectedLanguages", ["loc", [null, [14, 20], [14, 37]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [14, 12], [20, 21]]]], ["block", "if", [["get", "showAddLanguage", ["loc", [null, [21, 18], [21, 33]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [21, 12], [23, 19]]]], ["inline", "t", ["digest.digest"], [], ["loc", [null, [28, 48], [28, 69]]], 0, 0], ["inline", "digests/parts/digest-row-translation-part", [], ["languages", ["subexpr", "@mut", [["get", "selectedLanguages", ["loc", [null, [30, 64], [30, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "translations", ["subexpr", "@mut", [["get", "digest.draft_translations", ["loc", [null, [30, 95], [30, 120]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [30, 10], [30, 122]]], 0, 0], ["inline", "t", ["digest.sections"], [], ["loc", [null, [32, 48], [32, 71]]], 0, 0], ["block", "each", [["get", "digest.draft_components.data", ["loc", [null, [34, 18], [34, 46]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [34, 10], [36, 19]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});