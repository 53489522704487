define('due-dashboard/controllers/settings/integrations/salesforce/manage', ['exports', 'ember', 'due-dashboard/controllers/settings/integrations/salesforce', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardControllersSettingsIntegrationsSalesforce, _dueDashboardConfigEnvironment) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsSalesforce['default'].extend({

    downloadLink: computed('parent.salesforce_it.login_domain', function () {
      return this.get('parent.salesforce_it.login_domain') + _dueDashboardConfigEnvironment['default'].EmberENV.SALESFORCE_PACKAGE_PATH;
    }),

    actions: {
      updateToken: function updateToken() {
        window.location.replace(this.get('parent.salesforce_it.oauth_url'));
      }
    }
  });
});