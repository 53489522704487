define('due-dashboard/controllers/companies/verbatim', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /* global $ */

  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Controller = _ember['default'].Controller;
  var A = _ember['default'].A;
  var RSVP = _ember['default'].RSVP;
  var observer = _ember['default'].observer;
  exports['default'] = Controller.extend({
    filters: service(),
    session: service(),

    displayKeywords: false,
    selectedKeywords: A(),
    searchedAlias: null,
    currentStep: 1,

    kwCurrentPage: 1,
    kwPageCount: 0,
    kwShowPrev: false,
    kwShowNext: false,

    isLoading: false,
    unavailable_locales: ['th', 'ko', 'ja', 'zht'],

    currentAccount: service(),
    verbatimTranslation: computed.alias('model.company.verbatim_translation'),
    currentLanguage: computed.alias('currentAccount.content.language.name'),
    sortedKeywordList: computed.alias('model.keywords'),
    verbatims: computed.alias('model.verbatims'),

    updateKwPaging: observer('sortedKeywordList', function () {
      var _this = this;

      Promise.resolve(this.get('sortedKeywordList')).then(function (keywords) {
        _this.set('kwPageCount', keywords.meta['page_count']);
        _this.set('kwShowPrev', _this.get('kwCurrentPage') > 1);
        _this.set('kwShowNext', _this.get('kwCurrentPage') < keywords.meta['page_count']);
      });
    }),

    downloadLink: computed('sortedKeywordList', 'selectedKeywords', 'selectedKeywords.length', function () {
      var params = this.get('filters').get('queryFilters');
      if (this.get('selectedKeywords').length > 0) {
        params.filter.keywords = this.get('selectedKeywords').map(function (keyword) {
          return keyword.get('text');
        });
      }
      if (this.get('unavailableLanguage')) {
        params.filter.language = this.get('selectedLanguage').get('tag');
      }
      var url = _dueDashboardConfigEnvironment['default'].EmberENV.DEFAULT_SCHEME + '://' + _dueDashboardConfigEnvironment['default'].EmberENV.API_HOST + '/exports/verbatims.pdf?';
      url += 'api_key=' + this.get('session.session.authenticated.access_token') + '&';
      return url + $.param(params);
    }),

    listStyle: computed(function () {
      var style = localStorage.getItem('verbatimListStyle');
      if (style === null) {
        style = 'blocks';
        localStorage.setItem('verbatimListStyle', style);
      }
      return style;
    }),

    unavailableLanguage: computed('i18n.locale', function () {
      return this.get('unavailable_locales').includes(this.get('i18n.locale'));
    }),

    unavailableLanguageTitle: computed('currentLanguage', function () {
      return this.get('i18n').t('verbatims.unavailable_language', { language: this.get('currentLanguage') });
    }),

    languages: computed('model.languages', function () {
      var _this2 = this;

      return this.get('model.languages').filter(function (language) {
        return !_this2.get('unavailable_locales').includes(language.get('tag').toLowerCase());
      });
    }),

    selectedLanguage: computed('languages', function () {
      var selected = this.get('languages.firstObject');
      this.get('languages').forEach(function (language) {
        if (language.get('tag').toLowerCase() == 'en') {
          selected = language;
        }
      });
      return selected;
    }),

    ranks: computed('model.keywords.@each.aliases', function () {
      var acc = {};
      this.get('model.keywords').forEach(function (keyword) {
        keyword.get('aliases').forEach(function (alias) {
          acc[alias] = keyword.get('rank');
        });
      });
      return acc;
    }),

    checkVerbatimLanguage: function checkVerbatimLanguage() {
      if (this.get('unavailableLanguage') && this.get('verbatimTranslation')) {
        this.changeVerbatimLanguage();
      }
    },

    updateKeywordsList: function updateKeywordsList() {
      var filters = this.get('filters').get('queryFilters');
      var searchedAlias = this.get('searchedAlias');
      if (this.get('unavailableLanguage')) {
        filters.filter.language = this.get('selectedLanguage').get('tag');
      }
      if (searchedAlias) {
        filters.filter.text = searchedAlias;
      }

      var keywords_query = this.store.query('keyword', {
        sort: '-counter', page: { number: this.get('kwCurrentPage'), size: 5 },
        filter: filters['filter']
      });
      this.set('model.keywords', keywords_query);
      return keywords_query;
    },

    updateScroll: function updateScroll() {
      var _this3 = this;

      this.set('currentStep', this.get('currentStep') + 1);
      var filters = this.get('filters').get('queryFilters');
      filters.page = { number: this.get('currentStep') };
      var keywordArray = this.get('selectedKeywords').toArray();
      if (keywordArray.length > 0) {
        filters.filter.keyword = keywordArray.map(function (keyword) {
          return keyword.get('text');
        }).join(',');
      }
      if (this.get('unavailableLanguage')) {
        filters.filter.language = this.get('selectedLanguage').get('tag');
      }
      var verbatims = this.get('model.verbatims').toArray();
      this.get('store').query('verbatim', filters).then(function (results) {
        verbatims = [].concat(_toConsumableArray(verbatims), _toConsumableArray(results.toArray()));
        _this3.set('model.verbatims', verbatims);
        _this3.set('isLoading', false);
      });
    },

    updateVerbatimsList: function updateVerbatimsList() {
      var filters = this.get('filters').get('queryFilters');
      var keywordArray = this.get('selectedKeywords').toArray();
      if (this.get('unavailableLanguage')) {
        filters.filter.language = this.get('selectedLanguage').get('tag');
      }
      if (keywordArray.length > 0) {
        filters.filter.keyword = keywordArray.map(function (keyword) {
          return keyword.get('text');
        }).join(',');
      }
      var verbatims_query = this.get('store').query('verbatim', filters);
      this.set('model.verbatims', verbatims_query);
      return verbatims_query;
    },

    changeVerbatimLanguage: function changeVerbatimLanguage() {
      this.updateKeywordsList();
      this.updateVerbatimsList();
    },

    resetPageAndUpdate: function resetPageAndUpdate() {
      this.set('kwCurrentPage', 1);
      this.updateKeywordsList();
    },

    actions: {
      debouncedSearchAlias: function debouncedSearchAlias() {
        _ember['default'].run.debounce(this, this.resetPageAndUpdate, 500);
      },

      changeKeywordsPage: function changeKeywordsPage(to) {
        this.set('kwShowNext', false);
        this.set('kwShowPrev', false);
        // go to previous page
        if (to === 'prev' && this.get('kwCurrentPage') > 1) {
          this.set('kwCurrentPage', this.get('kwCurrentPage') - 1);
          // go to next page
        } else if (this.get('kwCurrentPage') < this.get('kwPageCount')) {
            this.set('kwCurrentPage', this.get('kwCurrentPage') + 1);
          }
        this.updateKeywordsList();
      },

      searchKeyword: function searchKeyword(keyword) {
        if (this.get('selectedKeywords').indexOf(keyword) !== -1) {
          this.get('selectedKeywords').removeObject(keyword);
        } else {
          this.get('selectedKeywords').addObject(keyword);
        }
        this.get('selectedKeywords').arrayContentDidChange();
        this.updateVerbatimsList();
      },

      clearAllSelectedKeywords: function clearAllSelectedKeywords() {
        this.get('selectedKeywords').clear();
        this.get('selectedKeywords').arrayContentDidChange();
        this.updateVerbatimsList();
      },

      filtersUpdated: function filtersUpdated() {
        return RSVP.all([this.updateKeywordsList(), this.updateVerbatimsList()]);
      },

      toggleDisplayKeywords: function toggleDisplayKeywords() {
        this.toggleProperty('displayKeywords');
      },

      reloadVerbatim: function reloadVerbatim() {
        this.set('isLoading', true);
        _ember['default'].run.debounce(this, this.updateScroll, 200);
      },

      resetScrollSteps: function resetScrollSteps() {
        this.set('currentStep', 1);
      },

      changeVerbatimLanguage: function changeVerbatimLanguage(language) {
        this.set('selectedLanguage', language);
        this.changeVerbatimLanguage();
      },

      setBlocks: function setBlocks() {
        localStorage.setItem('verbatimListStyle', 'blocks');
        this.set('listStyle', 'blocks');
      },

      setList: function setList() {
        localStorage.setItem('verbatimListStyle', 'list');
        this.set('listStyle', 'list');
      },

      saveDisplaySettings: function saveDisplaySettings(displayOptions) {
        this.set('model.displaySettings', [displayOptions]);
        this.notifyPropertyChange('model.displaySettings');
      }
    }
  });
});