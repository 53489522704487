define('due-dashboard/models/question-category', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    text: attr({ read_only: true }),
    title: attr({ read_only: true }),
    questions_count: attr('number', { read_only: true }),
    position: attr('number'),

    detail: computed('title_or_first_question', 'questions_count', function () {
      return this.get('title_or_first_question') + ' (' + this.get('questions_count') + ')';
    }),

    title_or_first_question: computed.or('title', 'text')

  });
});