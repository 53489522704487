define("due-dashboard/templates/components/v2/-range-slider", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/-range-slider.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "label-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "due-typo-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bottom-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "slider-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("input");
        dom.setAttribute(el3, "type", "range");
        dom.setAttribute(el3, "class", "slider");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "filled-part-container");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "value-input-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 0, 0);
        morphs[1] = dom.createAttrMorph(element2, 'min');
        morphs[2] = dom.createAttrMorph(element2, 'max');
        morphs[3] = dom.createAttrMorph(element2, 'value');
        morphs[4] = dom.createAttrMorph(element2, 'oninput');
        morphs[5] = dom.createAttrMorph(element3, 'id');
        morphs[6] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        return morphs;
      },
      statements: [["content", "label", ["loc", [null, [2, 33], [2, 42]]], 0, 0, 0, 0], ["attribute", "min", ["get", "min", ["loc", [null, [6, 30], [6, 33]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "max", ["get", "max", ["loc", [null, [6, 42], [6, 45]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "compValue", ["loc", [null, [6, 56], [6, 65]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", ["onChange"], [], ["loc", [null, [null, null], [6, 112]]], 0, 0], 0, 0, 0, 0], ["attribute", "id", ["get", "elementGUID", ["loc", [null, [7, 14], [7, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "due-input", [], ["inputId", ["subexpr", "concat", ["input-", ["get", "elementGUID", ["loc", [null, [11, 31], [11, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 14], [11, 43]]], 0, 0], "type", "number", "placeholder", "", "value", ["subexpr", "@mut", [["get", "compValue", ["loc", [null, [14, 12], [14, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "suffixLabel", ["subexpr", "@mut", [["get", "suffixLabel", ["loc", [null, [15, 18], [15, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "suffixOptions", ["subexpr", "@mut", [["get", "suffixOptions", ["loc", [null, [16, 20], [16, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "onSelectSuffixOption", ["subexpr", "action", ["onSelectSuffixOption"], [], ["loc", [null, [17, 27], [17, 58]]], 0, 0], "onChange", ["subexpr", "@mut", [["get", "onChange", ["loc", [null, [18, 15], [18, 23]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 4], [19, 6]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});