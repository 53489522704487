define('due-dashboard/components/answer-set', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    hasComment: computed.empty('answer_set.comment'),

    hasCommentText: computed('hasComment', function () {
      return this.get('hasComment') ? 'oui' : 'non';
    }),

    ellipsedComment: computed('answer_set.comment', function () {
      var comment = this.get('answer_set.comment');
      return comment.substr(0, 120) + '...';
    }),

    prettyCreatedAt: computed('answer_set.created_at', function () {
      return (0, _moment['default'])(this.get('answer_set.created_at')).format('LLL');
    })

  });
});