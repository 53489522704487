define('due-dashboard/controllers/digest-export/show', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var RSVP = _ember['default'].RSVP;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;

  var WS_SCHEME = _dueDashboardConfigEnvironment['default'].EmberENV.DEFAULT_SCHEME === 'https' ? 'wss' : 'ws',
      WS_HOST = _dueDashboardConfigEnvironment['default'].EmberENV.API_HOST,
      WS_URL = _dueDashboardConfigEnvironment['default'].EmberENV.WS_BASE_URL + '/cable',
      WS_TIMEOUT_DELAY_MS = 3 * 60 * 1000,
      POLL_INTERVAL_MS = 4 * 1000;

  exports['default'] = _ember['default'].Controller.extend({
    // private:

    _session: service('session'),

    init: function init() {
      var _this = this;

      this._super();
      run.next(function () {
        _this._awaitExport().then(function (url) {
          _this._tryDownloadExportFile(url);
        });
      });
    },

    _awaitExport: function _awaitExport() {
      var _this2 = this;

      var model = this.get('model'),
          token = this.get('_session.session.authenticated.access_token'),
          auth_url = WS_URL + '?token=' + token;
      var ws_timeout = undefined,
          socket = undefined;

      try {
        socket = new WebSocket(auth_url);
      } catch (e) {
        console.error(e);
      }
      if (!socket || socket.readyState != WebSocket.OPEN) {
        return this._pollModel();
      }

      return new RSVP.Promise(function (resolve, reject) {
        ws_timeout = run.later(_this2, function () {
          console.log('Websocket update timed out after ' + WS_TIMEOUT_DELAY_MS + ' ms, reverting to polling.');
          socket.close();
          resolve(this._pollModel());
        }, WS_TIMEOUT_DELAY_MS);

        socket.onerror = function (error) {
          console.log('Websocket update failed, reverting to polling.', error);
          resolve(_this2._pollModel());
        };

        socket.onopen = function (event) {
          var msg = {
            command: 'subscribe',
            identifier: JSON.stringify({
              export_request_id: model.get('id'),
              channel: 'SegmentTableExportRequestChannel'
            })
          };
          socket.send(JSON.stringify(msg));
        };

        socket.onmessage = function (event) {
          var msg = JSON.parse(event.data);

          switch (msg.type) {
            case 'confirm_subscription':
              // Changes could have happened before we were subscribed, so we reload now and check.
              model.reload().then(function (model) {
                switch (model.get('status')) {
                  case 'ready_to_download':
                    resolve(model.get('url'));
                    break;
                  case 'error':
                    reject();
                    break;
                }
              });
              break;
            default:
              if (!msg.message || !msg.message.status) {
                break;
              }

              switch (msg.message.status) {
                case 'ready_to_download':
                  resolve(msg.message.url);
                  break;
                case 'error':
                  reject();
                  break;
              }
          }
        };
      })['finally'](function () {
        run.cancel(ws_timeout);
        socket.close();
        model.reload();
      });
    },

    _tryDownloadExportFile: function _tryDownloadExportFile(url) {
      window.open(url);
    },

    _pollModel: function _pollModel() {
      var _this3 = this;

      var model = this.get('model');

      return model.reload().then(function () {
        var status = model.get('status');

        switch (status) {
          case 'ready_to_download':
            return model.get('url');
          case 'error':
            throw new Error('Error while processing export.');
          default:
            return new RSVP.Promise(function (resolve, reject) {
              run.later(function () {
                return resolve(_this3._pollModel());
              }, POLL_INTERVAL_MS);
            });
        }
      });
    },

    actions: {
      downloadFile: function downloadFile() {
        this._tryDownloadExportFile(this.get('model.url'));
      }
    }
  });
});