define('due-dashboard/components/scenario-action-readonly', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var dasherize = _ember['default'].String.dasherize;
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['readonlyComponentName'],

    action: null,

    readonlyComponentName: computed('action.type', function () {
      var type = this.get('action.type');
      return dasherize(type) + '-readonly';
    })
  });
});