define('due-dashboard/initializers/feedback-search', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet) {
  exports.initialize = initialize;

  function initialize(container, application) {
    application.register('object:feedback-search/filter-set', _dueDashboardObjectsFeedbackSearchFilterSet['default'], { singleton: false });
  }

  exports['default'] = {
    name: 'feedback-search',
    initialize: initialize
  };
});