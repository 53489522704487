define('due-dashboard/components/color-badge', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['color-badge'],
    attributeBindings: ['style'],

    style: computed('color', function () {
      if (this.get('color')) {
        return 'background-color: ' + this.get('hexToRGBA')(this.get('color')) + ';color: ' + this.get('color');
      }
    }),

    isSelected: computed('color', 'selectedColor', function () {
      return this.get('color') === this.get('selectedColor');
    }),

    hexToRGBA: function hexToRGBA(h) {
      var r = 0,
          g = 0,
          b = 0,
          a = 0.2;
      // 3 digits
      if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];
        // 6 digits
      } else if (h.length == 7) {
          r = "0x" + h[1] + h[2];
          g = "0x" + h[3] + h[4];
          b = "0x" + h[5] + h[6];
        }
      return "rgb(" + +r + "," + +g + "," + +b + "," + a + ")";
    },

    click: function click() {
      this.sendAction('selectColorAction', this.get('color'));
    }
  });
});