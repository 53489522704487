define('due-dashboard/components/survey-draft', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({

    currentAccount: _ember['default'].inject.service(),
    errors: service(),

    selectedLanguage: computed.alias('model.selectedLanguage'),

    displayLanguageList: false,

    displayAll: false,
    deletedLanguages: {},

    activitySectorsSorting: ['translatedName'],
    activitySectors: computed.sort('model.activity_sectors', 'activitySectorsSorting'),
    selectedActivitySector: computed('model.surveyDraft.activity_sector_id', function () {
      var _this2 = this;

      var activities = this.get('activitySectors');
      return activities.find(function (activity) {
        return activity.id == _this2.get('model.surveyDraft.activity_sector_id');
      });
    }),

    focusErrorTab: observer('edit.failSaveHook', function () {
      var keys = this.get('errors.errorKeys').join().match(/question-[0-9]*-[A-Z]{2}/);
      if (keys) {
        var to_go = this.get('tags').findBy('language.tag', keys[0].split('-')[2]);
        if (to_go) {
          this.set('selectedLanguage', to_go);
        }
      }
    }),

    questions: computed('model.surveyDraft.questions', function () {
      return JSON.parse(this.get('model.surveyDraft.questions'));
    }),

    questionsBasic: computed('model.surveyDraft.questions_basic', function () {
      return JSON.parse(this.get('model.surveyDraft.questions_basic'));
    }),

    status: computed('model.isSaving', function () {
      return this.get('model.isSaving') ? 'survey.saving' : 'survey.saved';
    }),

    tags: computed('questions', function () {
      var questions = this.get('questions');
      this.set('displayAll', true);
      return Object.keys(questions);
    }),

    canSave: computed('questions', 'tags', function () {
      var questions = this.get('questions');
      var everythingIsFilled = true;
      this.get('tags').forEach(function (tag) {
        if (questions[tag].main.length < 1) return everythingIsFilled = false;
        questions[tag].closed.forEach(function (question) {
          if (question.text.length < 1) return everythingIsFilled = false;
        });
      });
      return everythingIsFilled ? '' : 'disabled';
    }),

    displayQuestions: computed('model.surveyDraft.activity_sector_id', 'tags', function () {
      return this.get('model.surveyDraft.activity_sector_id') && this.get('tags').length >= 1 ? true : false;
    }),

    allLanguages: computed.mapBy('model.languages', 'tag'),
    remainingLanguages: computed.setDiff('allLanguages', 'tags'),
    atLeastOneLanguage: computed.gt('tags.length', 1),

    draftIsSaving: false,

    saveDraft: function saveDraft() {
      var _this3 = this;

      if (!this.get('model') || !this.get('model.surveyDraft') || !this.get('model.surveyDraft.questions')) return;
      this.set('model.surveyDraft.questions', JSON.stringify(this.get('questions')));
      this.get('model.surveyDraft').save().then(function () {
        return _this3.set('model.isSaving', false);
      });
    },

    recoverLostLanguage: function recoverLostLanguage(_this, language) {
      var deletedLanguages = _this.get('deletedLanguages');
      if (!deletedLanguages[language]) return false;
      var questions = _this.get('questions');
      _ember['default'].set(questions, language, deletedLanguages[language]);
      _this.set('questions', questions);
      _this.set('model.selectedLanguage', language);
      _this.notifyPropertyChange('questions');
      _this.set('model.isSaving', true);
      _this.set('debounceTimer', _ember['default'].run.debounce(_this, _this.get('saveDraft'), 2500));
      return true;
    },

    actions: {

      saveAndNext: function saveAndNext() {
        var _this4 = this;

        _ember['default'].run.cancel(this.get('debounceTimer'));
        this.set('model.surveyDraft.questions', JSON.stringify(this.get('questions')));
        this.get('model.surveyDraft').save().then(function () {
          _this4.set('model.isSaving', false);
          _this4.sendAction('validateAndNext');
        });
      },

      fieldTextChanged: function fieldTextChanged(value) {
        var context = arguments.length <= 1 || arguments[1] === undefined ? this : arguments[1];

        context.set('model.isSaving', true);
        context.set('debounceTimer', _ember['default'].run.debounce(context, context.get('saveDraft'), 2500));
      },

      questionTextChanged: function questionTextChanged() {
        this.notifyPropertyChange('questions');
        this.get('actions.fieldTextChanged')('', this);
      },

      updateActivitySector: function updateActivitySector(activity_sector) {
        var _this5 = this;

        var questions = this.get('questions');
        this.store.query('questionTemplate', {
          filter: {
            activity_sector_id: activity_sector.get('id')
          }
        }).then(function (response) {
          var templates = response.content;
          _this5.get('tags').forEach(function (tag) {
            if (questions[tag].closed.length > 0) questions[tag].closed.removeAt(0, questions[tag].closed.length);
            templates.forEach(function (template) {
              if (template._data.language.tag == tag) {
                questions[tag].closed.pushObject({ position: template._data.position, template_position: template._data.position, template: template.id, text: template._data.text });
              }
            });
          });
          _this5.set('questions', questions);
          _this5.set('deletedLanguages', {});
          _this5.notifyPropertyChange('questions');
          _this5.set('model.surveyDraft.activity_sector_id', activity_sector.id);
          _this5.set('model.isSaving', true);
          _this5.set('debounceTimer', _ember['default'].run.debounce(_this5, _this5.get('saveDraft'), 2500));
        });
      },

      deleteQuestion: function deleteQuestion(question_to_delete) {
        var questions = this.get('questions');
        this.get('tags').forEach(function (tag) {
          if (questions[tag].closed.length < question_to_delete.position) return;
          questions[tag].closed.removeAt(question_to_delete.position - 1, 1);
          questions[tag].closed.forEach(function (question) {
            _ember['default'].set(question, 'position', questions[tag].closed.indexOf(question) + 1);
          });
        });
        this.set('questions', questions);
        this.set('deletedLanguages', {});
        this.notifyPropertyChange('questions');
        this.set('model.isSaving', true);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDraft'), 2500));
      },

      listClickedOutside: function listClickedOutside() {
        this.set('displayLanguageList', false);
      },

      openLanguageList: function openLanguageList() {
        this.set('displayLanguageList', true);
      },

      addQuestion: function addQuestion() {
        var questions = this.get('questions');
        this.get('tags').forEach(function (tag) {
          questions[tag].closed.pushObject({ position: questions[tag].closed.length + 1, template: false, text: "" });
        });
        this.set('questions', questions);
        this.set('deletedLanguages', {});
        this.notifyPropertyChange('questions');
        this.set('model.isSaving', true);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDraft'), 2500));
      },

      addLanguage: function addLanguage(language) {
        var _this6 = this;

        this.set('displayLanguageList', false);
        if (this.get('recoverLostLanguage')(this, language)) return;
        var questions = this.get('questions');
        var newQuestion = {};
        newQuestion.main = this.get('questionsBasic')[language].main;
        newQuestion.open = this.get('questionsBasic')[language].open;
        newQuestion.closed = [];
        this.store.query('questionTemplate', {
          filter: {
            activity_sector_id: this.get('model.surveyDraft.activity_sector_id')
          }
        }).then(function (response) {
          var templates = response.content;
          questions[_this6.get('tags')[0]].closed.forEach(function (question) {
            var templated = templates.find(function (template) {
              return template._data.position == question.template_position && template._data.language.tag == language;
            });
            if (templated && question.template) newQuestion.closed.pushObject({ position: question.position, template: templated.id, template_position: templated._data.position, text: templated._data.text });else newQuestion.closed.pushObject({ position: question.position, template: question.template, template_position: question.template_position, text: "" });
          });
          _ember['default'].set(questions, language, newQuestion);
          _this6.set('questions', questions);
          _this6.set('model.selectedLanguage', language);
          _this6.notifyPropertyChange('questions');
          _this6.set('model.isSaving', true);
          _this6.set('debounceTimer', _ember['default'].run.debounce(_this6, _this6.get('saveDraft'), 2500));
        });
      },

      removeLanguage: function removeLanguage(survey_language) {
        var deletedLanguages = this.get('deletedLanguages');
        var questions = this.get('questions');
        _ember['default'].set(deletedLanguages, survey_language, questions[survey_language]);
        delete questions[survey_language];
        this.set('questions', questions);
        this.set('deletedLanguages', deletedLanguages);
        this.notifyPropertyChange('questions');
        if (this.get('model.selectedLanguage') == survey_language) this.set('model.selectedLanguage', this.get('tags').get('lastObject'));
        this.set('model.isSaving', true);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDraft'), 2500));
      },

      reorderQuestions: function reorderQuestions(orderedQuestions) {
        if (!orderedQuestions.length) return;
        var questions = this.get('questions');
        var positions = orderedQuestions.mapBy('position');
        this.get('tags').forEach(function (tag) {
          if (questions[tag].closed.length < positions.length) return;
          questions[tag].closed.forEach(function (question) {
            _ember['default'].set(question, 'position', positions.indexOf(question.position) + 1);
          });
          questions[tag].closed.sort(function (a, b) {
            return a.position > b.position ? 1 : b.position > a.position ? -1 : 0;
          });
          questions[tag].closed.arrayContentDidChange(0, questions[tag].closed.length, questions[tag].closed.length);
        });
        this.set('questions', questions);
        this.set('deletedLanguages', {});
        this.notifyPropertyChange('questions');
        this.set('model.isSaving', true);
        this.set('debounceTimer', _ember['default'].run.debounce(this, this.get('saveDraft'), 2500));
      }
    }
  });
});