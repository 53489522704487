define('due-dashboard/adapters/feedback', ['exports', 'ember-data', 'due-dashboard/mixins/jsonapi-adapter'], function (exports, _emberData, _dueDashboardMixinsJsonapiAdapter) {
  exports['default'] = _emberData['default'].JSONAPIAdapter.extend(_dueDashboardMixinsJsonapiAdapter['default'], {
    urlForQuery: function urlForQuery() {
      var url = this._super.apply(this, arguments);
      if (arguments[0].id === "all") {
        delete arguments[0]["id"];
        url += "/ids";
      }
      return url;
    }
  });
});