define("due-dashboard/serializers/mailing", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].JSONAPISerializer.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.data.attributes["from-email-domain"];
      delete json.data.attributes["from-email-local"];
      delete json.data.attributes["updated-at"];

      return json;
    }
  });
});