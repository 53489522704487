define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/components/box-shadow', ['exports', 'ember', 'due-dashboard/helpers/color-formating'], function (exports, _ember, _dueDashboardHelpersColorFormating) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Component.extend({
    classNames: ['setting-element-container', 'setting-element-with-padding', 'box-shadow'],

    DEFAULT_VALUE: 0,
    DEFAULT_COLOR: '#FFFFFF',
    DEFAULT_BOX_SHADOW: ['0px', '0px', '0px', '0px', '#FFFFFF'],
    UNITIES: ['px', 'em', 'ch', '%'],
    DEFAULT_UNITY: 'px',

    suffixOptions: computed(function () {
      return this.UNITIES.reduce(function (a, v) {
        a.push({ value: v });return a;
      }, []);
    }),

    boxShadow: computed('styleName', 'designOptions', function () {
      return this._extractShadow('designOptions.' + this.get('styleName') + '_shadow') || this.DEFAULT_BOX_SHADOW;
    }),

    currentBoxShadow: computed('styleName', 'designOptions', function () {
      var shadow = this.get('boxShadow');

      return {
        boxShadowX: this._extractValue(shadow[0]),
        boxShadowXUnity: this._extractSuffix(shadow[0]),
        boxShadowY: this._extractValue(shadow[1]),
        boxShadowYUnity: this._extractSuffix(shadow[1]),
        boxShadowBlur: this._extractValue(shadow[2]),
        boxShadowBlurUnity: this._extractSuffix(shadow[2]),
        boxShadowSpread: this._extractValue(shadow[3]),
        boxShadowSpreadUnity: this._extractSuffix(shadow[3]),
        boxShadowColor: shadow[4] || this.DEFAULT_COLOR
      };
    }),

    _extractValue: function _extractValue(value) {
      var defaultValue = arguments.length <= 1 || arguments[1] === undefined ? '0' : arguments[1];

      if (value == undefined) return defaultValue;
      var valueRegexp = /[0-9\.]/g;
      var number_value = value.match(valueRegexp);
      return number_value ? number_value.join('') : defaultValue;
    },

    _extractSuffix: function _extractSuffix(value) {
      var defaultValue = arguments.length <= 1 || arguments[1] === undefined ? 'px' : arguments[1];

      if (value == undefined) return defaultValue;
      var unityRegexp = /[a-z\%]/g;
      var unityDetect = value.match(unityRegexp);
      if (unityDetect == undefined) return defaultValue;
      var unity = unityDetect.join('');
      return this.UNITIES.includes(unity) ? unity : defaultValue;
    },

    _extractShadow: function _extractShadow(designOptionName) {
      var shadow_box = this.get(designOptionName);
      if (shadow_box == undefined) return;
      var shadow_box_list = shadow_box.split(' ');
      return shadow_box_list.length == 5 ? shadow_box_list : undefined;
    },

    _updateBoxShadow: function _updateBoxShadow() {
      var currentBoxShadow = this.get('currentBoxShadow');
      var shadowColor = _dueDashboardHelpersColorFormating['default'].compute(currentBoxShadow.boxShadowColor);

      var boxShadow = [currentBoxShadow.boxShadowX + currentBoxShadow.boxShadowXUnity, currentBoxShadow.boxShadowY + currentBoxShadow.boxShadowYUnity, currentBoxShadow.boxShadowBlur + currentBoxShadow.boxShadowBlurUnity, currentBoxShadow.boxShadowSpread + currentBoxShadow.boxShadowSpreadUnity, shadowColor];
      this.sendAction('updateDesignOptions', _defineProperty({}, this.get('styleName') + '_shadow', boxShadow.join(' ')));
    },

    actions: {
      setUnity: function setUnity(frontElement, unity) {
        this.set('currentBoxShadow.' + frontElement, unity.value);
        this._updateBoxShadow();
      },

      setValueWithUnity: function setValueWithUnity(frontElement, value) {
        this.set('currentBoxShadow.' + frontElement, value);
        debounce(this, this._updateBoxShadow, 500);
      },

      setValue: function setValue(frontElement, value) {
        this.set('currentBoxShadow.' + frontElement, value);
        this._updateBoxShadow();
      }
    }
  });
});