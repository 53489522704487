define('due-dashboard/models/change-end-text-scenario-action', ['exports', 'due-dashboard/models/scenario-action', 'ember-data', 'ember-cp-validations', 'ember'], function (exports, _dueDashboardModelsScenarioAction, _emberData, _emberCpValidations, _ember) {
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    change_end_text_scenario_actions_languages: (0, _emberCpValidations.validator)('presence', true),
    validText: (0, _emberCpValidations.validator)('number', {
      integer: true,
      is: 0,
      message: 'Empty messages are invalid'
    })
  });

  exports['default'] = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    change_end_text_scenario_actions_languages: hasMany('change-end-text-scenario-actions-language', { async: true }),
    actions_languages: computed.alias('change_end_text_scenario_actions_languages'),

    validText: computed('change_end_text_scenario_actions_languages.[]', function () {
      var _this = this;

      var actions = this.get('change_end_text_scenario_actions_languages');
      actions.map(function (a) {
        if (a.observersForKey('text').length === 0) {
          a.addObserver('text', function () {
            return _this.notifyPropertyChange('validText');
          });
        }
      });
      return actions.map(function (a) {
        return a.get('text') === undefined || a.get('text') === '';
      }).filter(function (a) {
        return a === true;
      }).length;
    }),

    defineSavepoint: function defineSavepoint() {
      var _this2 = this;

      this.get('actions_languages').then(function (al) {
        _this2.set('_savepoint_actions_languages', al.toArray());
      });
      return this;
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_actions_languages');
      if (savepoint) {
        this.set('change_end_text_scenario_actions_languages', savepoint);
        savepoint.map(function (a) {
          return a.rollbackAttributes();
        });
      }
    }
  });
});