define('due-dashboard/initializers/cookies', ['exports'], function (exports) {
  exports['default'] = {
    name: 'cookies-initializer',
    after: ['cookie'],

    initialize: function initialize(app) {
      app.inject('object', 'cookie', 'cookie:main');
      app.inject('controller', 'cookie', 'cookie:main');
      app.inject('route', 'cookie', 'cookie:main');
    }
  };
});