define('due-dashboard/serializers/account', ['exports', 'due-dashboard/serializers/application', 'ember'], function (exports, _dueDashboardSerializersApplication, _ember) {
  /* global _ */

  var keys = _ember['default'].keys;
  exports['default'] = _dueDashboardSerializersApplication['default'].extend({

    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        if (["password", "current_password", "password_confirmation"].includes(key) && snapshot.changedAttributes()[key] && snapshot.changedAttributes()[key][1] === "") {
          return;
        }
      }
      this._super(snapshot, json, key, attributes);
    },

    serialize: function serialize(snapshot) {
      if (snapshot.record.get('profile') !== 'custom') {
        snapshot.record.set('account_custom_profile_right', null);
      }
      var json = this._super.apply(this, arguments);
      var pass_attrs = ["password", "current_password", "password_confirmation"];
      if (_.intersection(pass_attrs, keys(snapshot.changedAttributes())).length === 0) {
        pass_attrs.forEach(function (key) {
          return delete json.data.attributes[key.replace(/\_/g, '-')];
        });
      }
      return json;
    }
  });
});