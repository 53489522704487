define('due-dashboard/components/due-selected-item', ['exports', 'ember', 'due-dashboard/helpers/get-rgba-from-hex'], function (exports, _ember, _dueDashboardHelpersGetRgbaFromHex) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;

  /**
   * @module {{due-selected-item}}
   * @param prefix {Any} (Optional) Display a prefix object
   * @param text {String} (Required) Text to display
   * @param color {Color} (Optional) Color use for display item
   * @param onDelete {Function} (Optional) Callback for delete item
   * @param disabled {Boolean} (Optional) Disable item
   */
  exports['default'] = Component.extend({
    classNames: ['due-selected-item'],
    classNameBindings: ['disabled'],
    attributeBindings: ['style'],

    /* Optional props */
    disabled: false,

    style: computed('color', function () {
      var color = this.get('color');
      var noStyle = this.get('noStyle');
      return !color || noStyle ? '' : htmlSafe('\n      background-color: ' + _dueDashboardHelpersGetRgbaFromHex['default'].compute(color, 0.2) + ';\n      color: ' + color + ';\n      border-color: ' + color + ';');
    }),

    actions: {
      onDelete: function onDelete() {
        var onDelete = this.get('onDelete');
        if (onDelete && !this.get('disabled')) onDelete();
      }
    }
  });
});
/**
 * @author Eliott Martin <eliott@diduenjoy.com> (14/02/2022)
 * @summary {{due-selected-item}} Display an item with border and arrow to delete
 */