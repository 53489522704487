define('due-dashboard/controllers/companies/analysis/tags', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /* global $ */

  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;
  var service = _ember['default'].inject.service;
  var A = _ember['default'].A;
  var RSVP = _ember['default'].RSVP;
  var observer = _ember['default'].observer;
  exports['default'] = Controller.extend({
    /** TAG-DEEP-ANALYSIS PART */
    filters: service(),

    tagDeepAnalysis: computed.alias('model.tag_deep_analysis.firstObject'),
    graph: computed.alias('tagDeepAnalysis.profiles_distribution_history'),
    total: computed.alias('tagDeepAnalysis.total_profiles_distribution'),
    tag_id: computed.alias('model.tag_id'),

    tableBreakdownLoading: computed('repartition_tags', function () {
      if (!this.get('repartition_tags')) {
        return true;
      } else {
        return false;
      }
    }),

    profileHistogramLoading: computed('tagDeepAnalysis', function () {
      if (!this.get('graph')) {
        return true;
      } else {
        return false;
      }
    }),

    /** TOP-KEYWORDS + VERBATIMS PART */
    session: service(),

    displayKeywords: false,
    selectedKeywords: A(),
    searchedAlias: null,
    currentStep: 1,

    kwCurrentPage: 1,
    kwPageCount: 0,
    kwShowPrev: false,
    kwShowNext: false,

    isLoading: false,
    unavailable_locales: ['th', 'ko', 'ja', 'zh', 'zht'],

    currentAccount: service(),
    verbatimTranslation: computed.alias('model.company.verbatim_translation'),
    currentLanguage: computed.alias('currentAccount.content.language.name'),
    sortedKeywordList: computed.alias('model.keywords'),
    verbatims: computed.alias('model.verbatims'),

    tagAnalysis: computed.alias('model.tag_analyses.firstObject'),
    repartition_tags: computed.alias('tagAnalysis.tags'),
    with_tags: computed.alias('tagAnalysis.repartition_with_tags'),

    updateKwPaging: observer('sortedKeywordList', function () {
      var _this = this;

      Promise.resolve(this.get('sortedKeywordList')).then(function (keywords) {
        _this.set('kwPageCount', keywords.meta['page_count']);
        _this.set('kwShowPrev', _this.get('kwCurrentPage') > 1);
        _this.set('kwShowNext', _this.get('kwCurrentPage') < keywords.meta['page_count']);
      });
    }),

    downloadLink: computed('sortedKeywordList', 'selectedKeywords', 'selectedKeywords.length', function () {
      var params = this.get('filters').get('queryFilters');
      params.filter.tagged = 'tagged';
      params.filter.tags = this.get('model.tag_id');
      if (this.get('selectedKeywords').length > 0) {
        params.filter.keywords = this.get('selectedKeywords').map(function (keyword) {
          return keyword.get('text');
        });
      }
      if (this.get('unavailableLanguage')) {
        params.filter.language = this.get('selectedLanguage').get('tag');
      }
      var url = _dueDashboardConfigEnvironment['default'].EmberENV.DEFAULT_SCHEME + '://' + _dueDashboardConfigEnvironment['default'].EmberENV.API_HOST + '/exports/verbatims.pdf?';
      url += 'api_key=' + this.get('session.session.authenticated.access_token') + '&';
      return url + $.param(params);
    }),

    tagAnalysisDownloadLink: computed('tagAnalysis', function () {
      var params = this.get('filters').get('queryFilters');
      var url = _dueDashboardConfigEnvironment['default'].EmberENV.DEFAULT_SCHEME + '://' + _dueDashboardConfigEnvironment['default'].EmberENV.API_HOST + '/api/v3/tag-analyses.csv?';
      url += 'api_key=' + this.get('session.session.authenticated.access_token') + '&';
      return url + $.param(params);
    }),

    listStyle: computed(function () {
      var style = localStorage.getItem('verbatimListStyle');
      if (style === null) {
        style = 'blocks';
        localStorage.setItem('verbatimListStyle', style);
      }
      return style;
    }),

    downloadLinkVerbatims: computed('downloadLink', function () {
      return this.get('downloadLink') + '&type=verbatims';
    }),

    downloadLinkKeywords: computed('downloadLink', function () {
      return this.get('downloadLink') + '&type=keywords';
    }),

    listStyle: computed(function () {
      var style = localStorage.getItem('verbatimListStyle');
      if (style === null) {
        style = 'blocks';
        localStorage.setItem('verbatimListStyle', style);
      }
      return style;
    }),

    unavailableLanguage: computed('i18n.locale', function () {
      return this.get('unavailable_locales').includes(this.get('i18n.locale'));
    }),

    unavailableLanguageTitle: computed('currentLanguage', function () {
      return this.get('i18n').t('verbatims.unavailable_language', { language: this.get('currentLanguage') });
    }),

    languages: computed('model.languages', function () {
      var _this2 = this;

      return this.get('model.languages').filter(function (language) {
        return !_this2.get('unavailable_locales').includes(language.get('tag').toLowerCase());
      });
    }),

    selectedLanguage: computed('languages', function () {
      var selected = this.get('languages.firstObject');
      this.get('languages').forEach(function (language) {
        if (language.get('tag').toLowerCase() == 'en') {
          selected = language;
        }
      });
      return selected;
    }),

    ranks: computed('model.keywords.@each.aliases', function () {
      var acc = {};
      this.get('model.keywords').forEach(function (keyword) {
        keyword.get('aliases').forEach(function (alias) {
          acc[alias] = keyword.get('rank');
        });
      });
      return acc;
    }),

    /** ## Due-histogram START ## */
    histogramColors: {
      'red': { bgColor: 'rgba(250, 82, 82, 0.1)', color: '#C62121' },
      'orange': { bgColor: 'rgba(250, 176, 5, 0.1)', color: '#F08C00' },
      'green': { bgColor: 'rgba(0, 181, 72, 0.1)', color: '#00953B' },
      'grey': { bgColor: 'rgba(113, 137, 156, 0.1)', color: '#71899C' }
    },

    histogramFeelingCount: {
      keys: ['words.positive', 'words.neutral', 'words.negative', 'tag_analysis.due_histogram.feeling.without_feeling'],
      data: [{ color: 'green', icon: { name: 'thumb_up_outlined', color: '#00B548' }, pos: 3 }, { color: 'orange', icon: { name: 'thumb_left_outlined', color: '#F08C00' }, pos: 5 }, { color: 'red', icon: { name: 'thumb_down_outlined', color: '#E03131' }, pos: 4 }, { color: 'grey', icon: { name: 'blocked', color: '#71899C' }, pos: 6 }]
    },

    histogramProfileCount: {
      keys: ['words.promoters', 'words.passives', 'words.detractors'],
      data: [{ color: 'green', icon: { name: 'slightly_smilling', color: '#00B548' }, pos: 2 }, { color: 'orange', icon: { name: 'expressionless', color: '#F08C00' }, pos: 1 }, { color: 'red', icon: { name: 'sad', color: '#E03131' }, pos: 0 }]
    },

    histogramFeelingLegend: [{ key: 'words.positive', color: 'green' }, { key: 'words.neutral', color: 'orange' }, { key: 'words.negative', color: 'red' }, { key: 'tag_analysis.due_histogram.feeling.without_feeling', color: 'grey' }],

    histogramProfileLegend: [{ key: 'words.promoters', color: 'green' }, { key: 'words.passives', color: 'orange' }, { key: 'words.detractors', color: 'red' }],

    histogramProfileGraph: [{ value: 'promoters', trad: 'words.promoters', color: '#00B548' }, { value: 'passives', trad: 'words.passives', color: '#FAB005' }, { value: 'detractors', trad: 'words.detractors', color: '#FA5252' }],

    histogramFeelingGraph: [{ value: 'positive', trad: 'words.positive', color: '#00B548' }, { value: 'neutral', trad: 'words.neutral', color: '#FAB005' }, { value: 'negative', trad: 'words.negative', color: '#FA5252' }, { value: 'nothing', trad: 'tag_analysis.due_histogram.feeling.without_feeling', color: '#9EB0BE' }],

    /**
     * Build right legend of histogram
     * @param {Array<String>} keysTrad Build legend label from traduction
     * @param {Array<Object>} data Display options & values
     *    - color: {String} Color to use from histogramColors
     *    - icon: {Object} Icon to display
     *      + name: {String} Icon name
     *      + color: {Color} Icon color
     *      + pos: {Number} Position in total array
     * @returns {Object} Top legend and data to display
     */
    _buildCount: function _buildCount(keysTrad, data) {
      var total = this.get('total') ? this.get('total')[0] : null;
      var histogramColors = this.get('histogramColors');
      var i18n = this.get('i18n');
      var legend = '';
      var values = [];
      keysTrad.forEach(function (key, index) {
        legend += i18n.t(key) + (index != keysTrad.length - 1 ? '-' : '');
      });
      data.forEach(function (value) {
        values.push(_extends({}, histogramColors[value.color], { icon: value.icon, total: total ? total[value.pos] : '-' }));
      });
      return { legend: legend, values: values, values: values };
    },

    /**
     * Build left legend for histogram graph
     * @param {Array<Object>} keysColors Keys & Colors for left legend
     *    - key: {String} Key trad for legend label
     *    - color: {Color} Color for rectangle legend
     * @returns {Array<Object>} Legend to display
     */
    _buildLegend: function _buildLegend(keysColors) {
      var i18n = this.get('i18n');
      var histogramColors = this.get('histogramColors');
      return keysColors.map(function (keyColor) {
        return { title: i18n.t(keyColor.key), color: histogramColors[keyColor.color].color };
      });
    },

    /**
     * Build sticks data histogram graph
     * @param {Array<Object>} keys Keys to build graph
     *    - value: {String} Value name to select
     *    - trad: {String} Key traduction to display
     *    - color: {Color} Color to display on stick
     * @returns {Object} Data for histogram graph
     */
    _buildGraph: function _buildGraph(keys) {
      var graph = this.get('graph');
      var i18n = this.get('i18n');

      if (!graph) return null;

      var data = [];
      var empty = true;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        var _loop = function () {
          var pdh = _step.value;

          if (pdh.step) empty = false;
          data.push({
            values: keys.map(function (key) {
              return {
                nbr: pdh[key.value] || 0, label: i18n.t(key.trad), color: key.color };
            }),
            step: pdh.step, timeserieStep: pdh.timeserie_step
          });
        };

        for (var _iterator = graph[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return { stepType: this.get('tagDeepAnalysis.step_type'), data: data, empty: empty };
    },

    histogramRange: computed('tagDeepAnalysis.date_range', 'tagDeepAnalysis.step_type', 'tagDeepAnalysis.profiles_distribution_history', function () {
      var time = this.get('tagDeepAnalysis.profiles_distribution_history') ? this.get('tagDeepAnalysis.profiles_distribution_history').length : 0;
      return {
        start: this.get('tagDeepAnalysis.date_range.start-date'),
        end: this.get('tagDeepAnalysis.date_range.end-date'),
        stepType: this.get('tagDeepAnalysis.step_type'),
        time: time
      };
    }),

    profileTitle: computed('repartition_tags', function () {
      var rt = this.get('repartition_tags');
      return rt && rt.length === 1 ? 'title_tag' : 'title_category';
    }),

    histogramCount: computed('total', function () {
      var profile = this.get('histogramProfileCount');
      var feeling = this.get('histogramFeelingCount');
      return {
        profile: this._buildCount(profile.keys, profile.data),
        feeling: this._buildCount(feeling.keys, feeling.data)
      };
    }),

    histogramLegend: computed(function () {
      return {
        profile: this._buildLegend(this.get('histogramProfileLegend')),
        feeling: this._buildLegend(this.get('histogramFeelingLegend'))
      };
    }),

    histogramGraph: computed('graph', function () {
      return {
        profile: this._buildGraph(this.get('histogramProfileGraph')),
        feeling: this._buildGraph(this.get('histogramFeelingGraph'))
      };
    }),

    /** ## Due-histogram END ## */

    checkVerbatimLanguage: function checkVerbatimLanguage() {
      if (this.get('unavailableLanguage') && this.get('verbatimTranslation')) {
        this.changeVerbatimLanguage();
      }
    },

    updateKeywordsList: function updateKeywordsList() {
      var filters = this.get('filters').get('queryFilters');
      var searchedAlias = this.get('searchedAlias');
      if (this.get('unavailableLanguage')) {
        filters.filter.language = this.get('selectedLanguage').get('tag');
      }
      if (searchedAlias) {
        filters.filter.text = searchedAlias;
      }
      var keywords_query = this.store.query('keyword', {
        sort: '-counter', page: { number: this.get('kwCurrentPage'), size: 5 },
        filter: filters['filter']
      });
      this.set('model.keywords', keywords_query);
      return keywords_query;
    },

    updateScroll: function updateScroll() {
      var _this3 = this;

      this.set('currentStep', this.get('currentStep') + 1);
      var filters = this.get('filters').get('queryFilters');
      filters.filter.mandatory_tag = this.get('tag_id');
      filters.page = { number: this.get('currentStep') };
      var keywordArray = this.get('selectedKeywords').toArray();
      if (keywordArray.length > 0) {
        filters.filter.keyword = keywordArray.map(function (keyword) {
          return keyword.get('text');
        }).join(',');
      }
      if (this.get('unavailableLanguage')) {
        filters.filter.language = this.get('selectedLanguage').get('tag');
      }
      var verbatims = this.get('model.verbatims').toArray();
      this.get('store').query('verbatim', filters).then(function (results) {
        verbatims = [].concat(_toConsumableArray(verbatims), _toConsumableArray(results.toArray()));
        _this3.set('model.verbatims', verbatims);
        _this3.set('isLoading', false);
      });
    },

    updateVerbatimsList: function updateVerbatimsList() {
      var filters = this.get('filters').get('queryFilters');
      filters.filter.mandatory_tag = this.get('tag_id');
      var keywordArray = this.get('selectedKeywords').toArray();
      if (this.get('unavailableLanguage')) {
        filters.filter.language = this.get('selectedLanguage').get('tag');
      }
      if (keywordArray.length > 0) {
        filters.filter.keyword = keywordArray.map(function (keyword) {
          return keyword.get('text');
        }).join(',');
      }
      var verbatims_query = this.get('store').query('verbatim', filters);
      this.set('model.verbatims', verbatims_query);
      return verbatims_query;
    },

    updateBreakdownTable: function updateBreakdownTable() {
      var filters = this.get('filters').get('queryFilters');
      var q = this.store.query('tag-analysis', _extends({ id: this.get('tagDeepAnalysis.id') }, filters));
      this.set('model.tag_analyses', q);
      return q;
    },

    updateProfileHistogram: function updateProfileHistogram() {
      var filters = this.get('filters').get('queryFilters');
      filters.filter.tag_id = this.get('tagDeepAnalysis.id');
      var tda_query = this.store.query('tag-deep-analysis', filters);
      this.set('model.tag_deep_analysis', tda_query);
      return tda_query;
    },

    changeVerbatimLanguage: function changeVerbatimLanguage() {
      this.updateKeywordsList();
      this.updateVerbatimsList();
    },

    resetPageAndUpdate: function resetPageAndUpdate() {
      this.set('kwCurrentPage', 1);
      this.updateKeywordsList();
    },

    actions: {

      prevStep: function prevStep() {
        this.set('verbatims', null);
        this.transitionTo(history.back());
      },

      /** TAG-DEEP ANALYSIS PART */

      filtersUpdated: function filtersUpdated() {
        return RSVP.all([this.updateBreakdownTable(), this.updateProfileHistogram(), this.updateKeywordsList(), this.updateVerbatimsList()]);
      },

      /** TOP-KEYWORDS + VERBATIMS PART */

      debouncedSearchAlias: function debouncedSearchAlias() {
        _ember['default'].run.debounce(this, this.resetPageAndUpdate, 500);
      },

      changeKeywordsPage: function changeKeywordsPage(to) {
        this.set('kwShowNext', false);
        this.set('kwShowPrev', false);
        // go to previous page
        if (to === 'prev' && this.get('kwCurrentPage') > 1) {
          this.set('kwCurrentPage', this.get('kwCurrentPage') - 1);
          // go to next page
        } else if (this.get('kwCurrentPage') < this.get('kwPageCount')) {
            this.set('kwCurrentPage', this.get('kwCurrentPage') + 1);
          }
        this.updateKeywordsList();
      },

      searchKeyword: function searchKeyword(keyword) {
        if (this.get('selectedKeywords').indexOf(keyword) !== -1) {
          this.get('selectedKeywords').removeObject(keyword);
        } else {
          this.get('selectedKeywords').addObject(keyword);
        }
        this.get('selectedKeywords').arrayContentDidChange();
        this.updateVerbatimsList();
      },

      clearAllSelectedKeywords: function clearAllSelectedKeywords() {
        this.get('selectedKeywords').clear();
        this.get('selectedKeywords').arrayContentDidChange();
        this.updateVerbatimsList();
      },

      toggleDisplayKeywords: function toggleDisplayKeywords() {
        this.toggleProperty('displayKeywords');
      },

      reloadVerbatim: function reloadVerbatim() {
        this.set('isLoading', true);
        _ember['default'].run.debounce(this, this.updateScroll, 200);
      },

      resetScrollSteps: function resetScrollSteps() {
        this.set('currentStep', 1);
      },

      changeVerbatimLanguage: function changeVerbatimLanguage(language) {
        this.set('selectedLanguage', language);
        this.changeVerbatimLanguage();
      },

      setBlocks: function setBlocks() {
        localStorage.setItem('verbatimListStyle', 'blocks');
        this.set('listStyle', 'blocks');
      },

      setList: function setList() {
        localStorage.setItem('verbatimListStyle', 'list');
        this.set('listStyle', 'list');
      },

      saveDisplaySettings: function saveDisplaySettings(displayOptions) {
        this.set('model.displaySettings', [displayOptions]);
        this.notifyPropertyChange('model.displaySettings');
      }

    }
  });
});