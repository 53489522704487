define('due-dashboard/components/satisfaction-chart', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {

  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({

    tagName: 'div',

    classNames: ['gauge'],

    // Remove ?
    attributeBindings: ['min', 'max'],

    note: 0,
    currentNote: 0,

    displayedNote: computed('currentNote', function () {
      var note = this.get('currentNote');
      return Number(Math.round(note / 10 + 'e' + 1) + 'e-' + 1);
    }),

    updateChart: observer('note', function () {
      var chart = this.$().data('easyPieChart');
      var note = this.get('note');
      if (note === undefined) {
        note = 0;
      }
      chart.update(note * 10);
    }),

    didInsertElement: function didInsertElement() {
      var cb = (function (from, to, currentValue) {
        this.set('currentNote', currentValue);
      }).bind(this);
      // Factor this somewhere ?!
      var color_theme = {
        blue: _dueDashboardConfigEnvironment['default'].EmberENV.colors[4],
        red: _dueDashboardConfigEnvironment['default'].EmberENV.colors[0]
      };
      this.$().easyPieChart({
        size: 84,
        barColor: color_theme.blue,
        trackColor: color_theme.red,
        scaleColor: false,
        lineCap: 'butt',
        rotate: 0,
        lineWidth: 7,
        animate: 1000,
        onStep: cb
      });
      this.updateChart();
    },

    willDestroyElement: function willDestroyElement() {
      var chart = this.$().data('easyPieChart');
      chart.options.onStep = $.noop;
    }

  });
});