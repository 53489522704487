define('due-dashboard/components/loading-skeleton/due-number-skeleton', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  /**
   * @author Youba SALMI <youba@diduenjoy.com>
   * @summary {{due-number-skeleton}} Simple skeleton component for numbers
   *
   * @module
   * @argument {string} [size] - size of component
   * @argument {string} [width] - width of the component
   * @argument {string} [height] - height of the component
   * @argument {string} [style] - css style to customise the component
   * @argument {string} [color] - custom color
   * @argument {string} [customClass] - classes to customise the component
   * @example <caption>Full usage example of {{due-numbers-skeleton}} component</caption>
   * Usage :
   * ```hbs
   * {{loading-skeleton/due-number-skeleton
   *   customClass='my-custom-class'
   *   size='medium'
   *   width=100
   *   height=15
   *   color='my-custom-color'
   *   style='my-custom-style'
   * }}
   * ```
   */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-number-skeleton'],
    classNameBindings: ['customClass', 'size'],
    attributeBindings: ['style'],

    color: '#F6F8FB',
    heightFromSize: { small: 20, medium: 40, large: 60 },
    widthFromSize: { small: 46, medium: 88, large: 100 },
    rendered: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('animationUID', 'linear-gradient-' + guidFor(this));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('rendered', true);
      this.set('xOffset', this.element.querySelector('svg').getBoundingClientRect().x);
      this.set('windowWidth', $('body > .ember-view').width());
    },

    computedAnimationUID: computed('emptyState', function () {
      return this.get('emptyState') ? '#F6F8FB' : 'url(#' + this.get('animationUID') + ')';
    }),

    recWidth: computed('width', 'size', function () {
      return this.get('width') || this.get('widthFromSize')[this.get('size')] || 46;
    }),

    recHeight: computed('height', 'size', function () {
      return this.get('height') || this.get('heightFromSize')[this.get('size')] || 20;
    })
  });
});