define('due-dashboard/components/digests/parts/one-segment-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    segments: [],
    segment: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('finalSegment')) {
        var id = this.get('finalSegment');
        this.set('segment', this.get('segments').find(function (segment) {
          return segment.id == id;
        }));
      } else {
        this.set('finalSegment', null);
      }
    },

    _searchSegment: function _searchSegment(term) {
      var _this = this;

      var searchPromise = this.store.query('segment', {
        filter: { name: term }
      });
      this.set('search_promise', searchPromise);
      searchPromise.then(function (segments) {
        return _this.set('filteredSegments', segments.filter(function (s) {
          return _this.get('finalSegment') !== s.id;
        }));
      });
    },

    dropdownSegments: computed('segments', 'filteredSegments', 'searchList', function () {
      return this.get('searchList') ? this.get('filteredSegments') : this.get('segments');
    }),

    actions: {

      searchSegment: function searchSegment(term) {
        this.set('searchList', term && term !== '');
        this._searchSegment(term);
      },

      addSegment: function addSegment(segment) {
        this.set('segment', segment);
        if (segment) {
          this.set('finalSegment', segment.id);
        } else {
          this.set('finalSegment', null);
        }
        this.get('saveDigestDelay')();
      }
    }
  });
});