define('due-dashboard/objects/number-animator', ['exports', 'ember'], function (exports, _ember) {
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Object.extend({
    targetObject: null,
    targetPath: null,
    initialValue: 0,
    currentValue: 0,
    currentTarget: 0,
    transitionTime: 1000, // 1s
    tick: 20, // 100 ms
    inputValue: null,
    decimalDigits: 0,

    init: function init() {
      this._super.apply(this, arguments);
      var tobj = this.get('targetObject');
      var tpath = this.get('targetPath');
      tobj.addObserver(tpath, this, this.updateInput);
      this.updateInput();
    },

    updateInput: function updateInput() {
      var tpath = 'targetObject.' + this.get('targetPath');
      var input = this.get(tpath);
      this.set('inputValue', input);
      this.inputChanged();
    },

    inputChanged: observer('inputValue', function () {
      this.set('currentValue', this.get('initialValue'));
      var input = parseFloat(this.get('inputValue'), 10);
      if (isNaN(input)) {
        return;
      }
      this.set('currentTarget', input);
      var tickCount = this.get('transitionTime') / this.get('tick');
      this.set('increment', Math.abs(input / tickCount));
      this.update();
      //TODO: Cancel current run.later if exists
    }),

    update: function update() {
      var current = this.get('currentValue');
      var nextValue = current + this.get('increment');
      if (nextValue > this.get('currentTarget')) {
        nextValue = this.get('currentTarget');
      }
      if (nextValue < this.get('currentTarget')) {
        _ember['default'].run.later(this, this.update, this.get('tick'));
      }
      this.set('currentValue', nextValue);
    },

    fixedCurrentValue: computed('currentValue', 'decimalDigits', function () {
      return parseFloat(this.get('currentValue').toFixed(this.get('decimalDigits')));
    })

  });
});