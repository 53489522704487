define('due-dashboard/components/digests/tag-breakdown', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    includeOptions: ['included', 'excluded'],
    mentionOptions: ['volume', 'volume_mention', 'share_tag', 'share_mention'],
    respondantOptions: ['volume', 'percent'],
    scoreOptions: ['nps', 'average', 'avg_pos'],
    useDropdownMultilevel: true,
    valueAttribute: 'key',

    orderOptions: computed(function () {
      return [{ key: 'score', name: this.translateValue('score') }, { key: 'change', name: this.translateValue('change') }, { key: 'count', name: this.translateValue('count') }, { key: 'volume_of_tags', name: this.translateValue('volume_of_tags') }, {
        key: 'sentiment', name: this.translateValue('sentiment'),
        options: [{ key: 'positive_volume', name: this.translateValue('positive_volume') }, { key: 'neutral_volume', name: this.translateValue('neutral_volume') }, { key: 'negative_volume', name: this.translateValue('negative_volume') }]
      }];
    }),

    categoryOptions: computed(function () {
      return ["All", 'Main', 'Sub', 'Sub-sub', 'Sub-sub-sub'];
    }),

    selectedIncludeOption: computed('includeOptions', 'comp.options', 'comp.options.tag_include', function () {
      return this.get('comp.options.tag_include') ? this.get('comp.options.tag_include') : this.get('includeOptions')[0];
    }),

    selectedRespondantOption: computed('respondantOptions', 'comp.options', 'comp.options.respondant_mode', function () {
      return this.get('comp.options.respondant_mode') ? this.get('comp.options.respondant_mode') : this.get('respondantOptions')[0];
    }),

    selectedFeelingOption: computed('respondantOptions', 'comp.options', 'comp.options.feeling_mode', function () {
      return this.get('comp.options.feeling_mode') ? this.get('comp.options.feeling_mode') : this.get('respondantOptions')[0];
    }),

    selectedScoreOption: computed('scoreOptions', 'comp.options', 'comp.options.score_mode', function () {
      return this.get('comp.options.score_mode') ? this.get('comp.options.score_mode') : this.get('scoreOptions')[0];
    }),

    feedbackVolumeTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.feedback_volume_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.feedback_volume_name', value);
        return value;
      }
    }),

    volumeVariationTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.volume_variation_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.volume_variation_name', value);
        return value;
      }
    }),

    respondantsTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.respondants_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.respondants_name', value);
        return value;
      }
    }),

    feelingTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.feelings_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.feelings_name', value);
        return value;
      }
    }),

    tagsTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.tags_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.tags_name', value);
        return value;
      }
    }),

    scoreTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.score_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.score_name', value);
        return value;
      }
    }),

    scoreVariationTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.score_variation_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.score_variation_name', value);
        return value;
      }
    }),

    translateValue: function translateValue(option) {
      return String(this.get('i18n').t('digest.component.order_by_' + option));;
    },

    actions: {
      changeInclude: function changeInclude(opt) {
        this.set('comp.options.tag_include', opt);
        this.get('saveDigestDelay')();
      },

      changeRespondant: function changeRespondant(opt) {
        this.set('comp.options.respondant_mode', opt);
        this.get('saveDigestDelay')();
      },

      changeFeeling: function changeFeeling(opt) {
        this.set('comp.options.feeling_mode', opt);
        this.get('saveDigestDelay')();
      },

      changeOrder: function changeOrder(opt) {
        this.set('comp.options.order_mode', opt);
        this.get('saveDigestDelay')();
      },

      changeScore: function changeScore(opt) {
        this.set('comp.options.score_mode', opt);
        this.get('saveDigestDelay')();
      }
    }
  });
});