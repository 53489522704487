define("due-dashboard/templates/components/reports/array/-breakdown", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/reports/array/-breakdown.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "breakdowns/due-distribution-breakdown", [], ["distribution", ["subexpr", "@mut", [["get", "compValue.distribution", ["loc", [null, [2, 15], [2, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "title", ["loc", [null, [3, 8], [3, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [4, 10], [4, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "colIndex", ["subexpr", "@mut", [["get", "colIndex", ["loc", [null, [5, 11], [5, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [6, 18], [6, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "source", ["subexpr", "@mut", [["get", "source", ["loc", [null, [7, 9], [7, 15]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [8, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});