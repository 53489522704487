define("due-dashboard/templates/components/feedback-rate-details", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 59
          }
        },
        "moduleName": "due-dashboard/templates/components/feedback-rate-details.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h4");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        dom.setAttribute(el1, "class", "title");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("%");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        dom.setAttribute(el1, "class", "legend");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" / ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [4]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        morphs[2] = dom.createMorphAt(element0, 0, 0);
        morphs[3] = dom.createMorphAt(element0, 2, 2);
        return morphs;
      },
      statements: [["inline", "t", [["subexpr", "concat", ["surveys.stats.", ["get", "selected.key", ["loc", [null, [1, 33], [1, 45]]], 0, 0, 0, 0], ".title"], [], ["loc", [null, [1, 8], [1, 55]]], 0, 0]], [], ["loc", [null, [1, 4], [1, 57]]], 0, 0], ["content", "percentage", ["loc", [null, [2, 18], [2, 32]]], 0, 0, 0, 0], ["content", "selected.count", ["loc", [null, [3, 19], [3, 37]]], 0, 0, 0, 0], ["content", "totalCount", ["loc", [null, [3, 40], [3, 54]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});