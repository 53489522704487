define("due-dashboard/templates/components/due-input-autocomplete", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 8
                },
                "end": {
                  "line": 8,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/due-input-autocomplete.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "due-autocomplete", [], ["highlightedOption", ["subexpr", "@mut", [["get", "highlightedOption", ["loc", [null, [7, 47], [7, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "option", ["subexpr", "@mut", [["get", "option", ["loc", [null, [7, 72], [7, 78]]], 0, 0, 0, 0]], [], [], 0, 0], "selectOption", ["subexpr", "action", ["selectOption"], [], ["loc", [null, [7, 92], [7, 115]]], 0, 0]], ["loc", [null, [7, 10], [7, 117]]], 0, 0]],
            locals: ["option"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 10,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/due-input-autocomplete.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "autocomplete-options");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "options", ["loc", [null, [6, 16], [6, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 8], [8, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 4
              },
              "end": {
                "line": 16,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/due-input-autocomplete.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createMorphAt(element0, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["error-desc ", ["subexpr", "unless", [["get", "true_display", ["loc", [null, [13, 38], [13, 50]]], 0, 0, 0, 0], "min-error"], [], ["loc", [null, [13, 29], [13, 64]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "error", ["loc", [null, [14, 8], [14, 17]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/due-input-autocomplete.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(element1, 3, 3);
          morphs[3] = dom.createMorphAt(element1, 5, 5);
          morphs[4] = dom.createMorphAt(element1, 7, 7);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "error", ["loc", [null, [2, 19], [2, 24]]], 0, 0, 0, 0], "input-field-error"], [], ["loc", [null, [2, 14], [2, 46]]], 0, 0], " ", ["subexpr", "if", [["get", "in-form", ["loc", [null, [2, 52], [2, 59]]], 0, 0, 0, 0], "input-block"], [], ["loc", [null, [2, 47], [2, 75]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input", [], ["id", ["subexpr", "@mut", [["get", "name", ["loc", [null, [3, 15], [3, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [3, 25], [3, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "text", "value", ["subexpr", "@mut", [["get", "inputValue", ["loc", [null, [3, 48], [3, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "autocomplete", "off", "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [3, 90], [3, 101]]], 0, 0, 0, 0]], [], [], 0, 0], "key-up", ["subexpr", "action", ["debounceSearchQuestion"], [], ["loc", [null, [3, 109], [3, 142]]], 0, 0]], ["loc", [null, [3, 4], [3, 144]]], 0, 0], ["block", "if", [["get", "hasResults", ["loc", [null, [4, 10], [4, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 4], [10, 11]]]], ["inline", "field-validation-display", [], ["field", ["subexpr", "@mut", [["get", "value", ["loc", [null, [11, 37], [11, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [11, 49], [11, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "meta", ["subexpr", "@mut", [["get", "meta", ["loc", [null, [11, 60], [11, 64]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 4], [11, 66]]], 0, 0], ["block", "if", [["get", "error", ["loc", [null, [12, 10], [12, 15]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [12, 4], [16, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 15
          }
        },
        "moduleName": "due-dashboard/templates/components/due-input-autocomplete.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "click-zone", [], ["click-outside", ["subexpr", "action", ["clickOutsideComponent"], [], ["loc", [null, [1, 28], [1, 60]]], 0, 0], "click-inside", ["subexpr", "action", ["clickInsideComponent"], [], ["loc", [null, [1, 74], [1, 105]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [18, 15]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});