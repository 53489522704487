define('due-dashboard/models/due-digest', ['exports', 'ember', 'moment', 'ember-data'], function (exports, _ember, _moment, _emberData) {
  var computed = _ember['default'].computed;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    company: belongsTo('Company'),
    draft_language: belongsTo('Language'),
    live_language: belongsTo('Language'),

    name: attr('string'),
    live_timeframe: attr('string'),
    draft_timeframe: attr('string'),
    live_send_period: attr('string'),
    draft_send_period: attr('string'),
    live_scheduled_day: attr('string'),
    draft_scheduled_day: attr('string'),
    status: attr('string', { readOnly: true }),

    created_at: attr(),
    updated_at: attr(),
    draft_components: attr(),
    draft_segment_id: attr(),
    draft_translations: attr(),
    draft_subscriptions: attr(),
    live_components: attr(),
    live_segment_id: attr(),
    live_translations: attr(),
    live_subscriptions: attr(),

    live_scheduled_at: attr('utc'),
    draft_scheduled_at: attr('utc'),
    last_sent_at: attr('utc', { readOnly: true }),

    live: attr('boolean'),
    unpublished_change: attr('boolean'),

    duplicate_from: attr('string'),
    duplicate_subscriber_list: attr('boolean'),

    sent_count: attr({ readOnly: true }),
    opened_rate: attr({ readOnly: true }),
    delivered_rate: attr({ readOnly: true }),
    live_subscriptions_count: attr({ readOnly: true }),
    cancel_email_if_no_data: attr('boolean'),

    subscriptionsFromJson: computed('draft_subscriptions', function () {
      return JSON.parse(this.get('draft_subscriptions'));
    }),

    liveSubscriptionsFromJson: computed('live_subscriptions', function () {
      return JSON.parse(this.get('live_subscriptions'));
    }),

    formatedUpdatedAt: computed('updated_at', function () {
      return (0, _moment['default'])(this.get('updated_at')).fromNow();
    }),

    formatedLastSentAt: computed('last_sent_at', function () {
      if (this.get('last_sent_at')) {
        return (0, _moment['default'])(this.get('last_sent_at')).fromNow();
      }
      return '-';
    }),

    formatedStatus: computed('i18n', 'status', function () {
      return this.get('i18n').t('digest.status.' + this.get('status'));
    })
  });
});