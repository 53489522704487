define('due-dashboard/components/reports/array/-breakdown', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-breakdown'],

    init: function init() {
      this._super();
      var content = this.get('content'),
          colIndex = this.get('colIndex'),
          index = content.component.view_type === 'segments' && !content.component.config.index ? colIndex : colIndex - 1,
          headerIndex = content.component.type === 'tag-breakdown' ? colIndex + 1 : content.component.view_type === 'tags' ? colIndex - 1 : colIndex;

      this.set('contentConfig', content.component.properties[index].distribution);
      this.set('title', content.component.config.translations[this.get('currentLanguage') || 'EN'].headers[headerIndex]);
    },

    compValue: computed('value', function () {
      var value = this.get('value'),
          content = this.get('content');

      if (content.component.config.index) {
        this.set('colIndex', this.get('colIndex') - 1);
      }

      if (value.breakdown) {
        value.distribution = value.breakdown;
      }

      var index = content.component.view_type !== 'tags' ? this.get('colIndex') : this.get('colIndex') - 1;
      if (value.breakdown && (content.component.properties[index].kpi === 'question_breakdown' || content.component.view_type === 'questions')) {
        (function () {
          var filteredBrkdn = {};
          Object.keys(value.breakdown).forEach(function (text) {
            if (!text.includes('_') || text === '_total_multi') {
              filteredBrkdn[text] = value.breakdown[text];
            }
          });
          value.distribution = filteredBrkdn;
        })();
      }

      return value;
    })
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{reports/array/-breakdown}} breakdown column used by {{reports/-array}}
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */