define('due-dashboard/components/loading-skeleton/due-animate-skeleton', ['exports', 'ember'], function (exports, _ember) {

  /**
   * @author Youba SALMI <youba@diduenjoy.com>
   * @summary {{due-animate-skeleton}} SVG linear gradient ainamtion component
   *
   * @module
   * @argument {number} [width] - width of the element on which the animatin will be applied
   * @argument {number} [xOffset] - x position of the element calling the animation
   * @argument {number} windowWidth - windown width
   * @argument {string} animationUID - Unique identifier
   * @argument {string} [baseColor] - base color of the component
   * @argument {string} [animateColor] - animation color
   * @example <caption>Full usage example of {{due-animate-skeleton}} component</caption>
   * Usage :
   * ```hbs
   * {{loading-skeleton/due-animate-skeleton
   *   width=recWidth
   *   xOffset=xOffset
   *   windowWidth=windowWidth
   *   animationUID=animationUID
   * }}
   * ```
   */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;
  exports['default'] = Component.extend({
    tagName: '',
    baseColor: '#F6F8FB',
    middleColor: '#D1DBEA',
    animateColor: '#DDE4EF',
    waveTime: 800,

    didInsertElement: function didInsertElement() {
      this.set('uid', "animate-" + guidFor(this));
    },

    duration: computed('width', function () {
      var width = this.get('width') || 100;
      return 200 + Math.floor(width - 100) / 2;
    }),

    begin: computed('xOffset', 'windowWidth', function () {
      var xOffset = this.get('xOffset') || 0,
          fullWidth = this.get('windowWidth');
      return Math.round(xOffset * this.get('waveTime') / fullWidth);
    }),

    delay: computed('windowWidth', function () {
      return 2 * 800 + 200;
    })
  });
});