define('due-dashboard/components/tab-component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tab-component',
    // tablist: null,

    active: false,

    willInsertElement: function willInsertElement() {
      // this.sendAction('registerAction', this);
      var action = this.get('tablist');
      if (action) {
        action(this);
      }
    },

    click: function click() {}

  });
});