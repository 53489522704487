define('due-dashboard/utils/cookie_encode', ['exports'], function (exports) {
  exports['default'] = {
    btoa: (function (_btoa) {
      function btoa(_x) {
        return _btoa.apply(this, arguments);
      }

      btoa.toString = function () {
        return _btoa.toString();
      };

      return btoa;
    })(function (string) {
      return btoa(string).replace(/\=/g, '*');
    }),
    atob: (function (_atob) {
      function atob(_x2) {
        return _atob.apply(this, arguments);
      }

      atob.toString = function () {
        return _atob.toString();
      };

      return atob;
    })(function (string) {
      return atob(string.replace(/\*/g, '='));
    })
  };
});