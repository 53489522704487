define('due-dashboard/models/tag', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    name: attr('string'),
    color: attr('string'),
    created_at: attr('date'),
    survey_limited: attr('boolean'),
    not_assignable: attr('boolean'),
    translations: attr(),
    company: belongsTo('company'),
    account: belongsTo('Account'),
    language: belongsTo('language'),
    surveys: hasMany('surveys'),
    due_exclusive: attr('boolean'),
    feeling_enabled: attr('boolean'),
    kind: attr()
  });
});