define('due-dashboard/routes/companies/overview', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    filters: service(),

    model: function model() {
      var filters = this.get('filters').get('queryFilters');
      // let verbatimsParams = filters;
      // verbatimsParams.page = {
      //   number: 1,
      //   size: 5
      // };
      return {
        company: this.get('currentAccount.content.company'),
        stats: this.store.queryRecord('stat', filters),
        graph: this.get('currentAccount.content.favorite_graph')
      };
    }

  });
});
// segmentsToDisplay: this.store.query('segment', {filter: {display: 'chat'}}),
// verbatimDisplaySettings: this.store.query('verbatim-display-option', {filter: { account_id: this.currentAccount.content.id }}),
// lastVerbatims: (verbatimsParams['filter']['comment'] === 'without') ? [] : this.store.query('verbatim', verbatimsParams)