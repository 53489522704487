define('due-dashboard/components/sticky-block', ['exports', 'ember'], function (exports, _ember) {
  /*global $*/

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    offset: 0,
    stick_scrollTop: 0,
    windowSize: null,

    stickToNode: computed('stickTo', function () {
      return $(this.get('stickTo'));
    }),

    stickyNode: computed(function () {
      return this.$();
    }),

    stickToOffsetTop: computed('stickToNode', function () {
      return this.get('stickToNode').offset().top;
    }),

    stickToHeight: computed('stickToNode', function () {
      return this.get('stickToNode').outerHeight();
    }),

    stickStart: computed('stickToOffsetTop', 'offset', function () {
      return this.get('stickToOffsetTop') - this.get('offset');
    }),

    effectiveWindowHeight: computed('windowSize', 'offset', function () {
      return this.get('windowSize').height - this.get('offset');
    }),

    totalMissingDistance: computed('stickToHeight', 'stickyNode', function () {
      // TODO : Handle stickyNode height > stickToHeigjt
      var missing_distance = this.get('stickToHeight') - this.get('stickyNode').outerHeight();
      return missing_distance;
    }),

    missingDistance: computed('stick_scrollTop', 'stickStart', function () {
      var missing_distance = this.get('stick_scrollTop') - this.get('stickStart');
      if (missing_distance < 0) {
        missing_distance = 0;
      }
      return missing_distance;
    }),

    stickEnd: computed('stickStart', 'totalMissingDistance', function () {
      return this.get('stickStart') + this.get('totalMissingDistance');
    }),

    stickDistance: computed('stick_scrollTop', 'stickStart', 'stickEnd', 'missingDistance', 'totalMissingDistance', function () {
      if (this.get('stick_scrollTop') < this.get('stickStart')) {
        return 0;
      } else if (this.get('stick_scrollTop') < this.get('stickEnd')) {
        return this.get('missingDistance');
      } else {
        return this.get('totalMissingDistance');
      }
    }),

    reloadDomProperties: function reloadDomProperties() {
      this.propertyDidChange('stickToNode', 'stickyNode');
    },

    stickScrollEvent: function stickScrollEvent() {
      this.reloadDomProperties();
      this.set('stick_scrollTop', $(window).scrollTop());
      var screenTop = this.get('stickDistance');
      if (screenTop < 0) {
        screenTop = 0;
      }
      this.$().css('margin-top', screenTop + 'px');
    },

    stickResizeEvent: function stickResizeEvent() {
      this.reloadDomProperties();
      this.set('windowSize', {
        width: $(window).width(),
        height: $(window).height()
      });
      this.stickScrollEvent();
    },

    didInsertElement: function didInsertElement() {
      $(window).on('load', _ember['default'].run.bind(this, this.stickScrollEvent));
      $(window).on('resize', _ember['default'].run.bind(this, this.stickResizeEvent));
      $('body > div.ember-view').on('scroll', _ember['default'].run.bind(this, this.stickScrollEvent));
      this._super.apply(this, arguments);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      $(window).unbind('resize');
      $('body > div.ember-view').unbind('scroll');
    }
  });
});