define('due-dashboard/components/v2/radio-buttons/-radio-button-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['due-radio-button-item'],
    classNameBindings: ['selected:selected'],

    displayChild: false,

    selected: computed('currentItem', function () {
      return this.get('index') === this.get('currentItem');
    }),

    actions: {
      selectedOption: function selectedOption() {
        var callback = this.get('onSelect');
        var selected = this.get('selected');
        var itemIndex = this.get('index');

        if (callback && !selected) {
          callback(itemIndex);
        }
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-radio-buttons}} radio button child item
 */