define('due-dashboard/components/billing-item', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    tagName: 'tr',
    classNames: ['billing-item'],

    billingUrl: computed('billing', function () {
      return _dueDashboardConfigEnvironment['default'].EmberENV.API_URL + '/billings_pdfs/' + this.get('billing.id') + '.pdf';
    }),

    datePayed: computed('billing', function () {
      var ret = this.get('billing.paid_at');
      if (ret) {
        ret = ret.toLocaleDateString(this.get('i18n.locale'), { day: 'numeric', month: 'long', year: 'numeric' });
      } else {
        ret = '-';
      }
      return ret;
    }),

    billingTitle: computed('billing', function () {
      return this.get('billing.created_at').toLocaleDateString(this.get('i18n.locale'), { month: 'long', year: 'numeric' });
    }),

    billingAmmount: computed('billing', function () {
      return (this.get('billing.amount') / (1 + this.get('billing.taxes_rate') / 100)).toFixed(2);
    })

  });
});