define('due-dashboard/components/digests/filters/profile-filter', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    init: function init() {
      if (!this.get('filter.value')) {
        this.set('filter.value', []);
      }
      this._super.apply(this, arguments);
    },

    availableProfiles: ['detractor', 'passive', 'promoter'],

    computedValue: computed('filter.value', {
      get: function get() {
        return this.get('filter.value');
      }, set: function set(_, value) {
        this.set('filter.value', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    actions: {

      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});