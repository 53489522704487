define('due-dashboard/routes/settings', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    actions: {

      willTransition: function willTransition() {
        this.controller.set('displayMenu', false);
        this.currentAccount.content.get('company.content').rollbackAttributes();
        this.currentAccount.content.rollbackAttributes();
        return true;
      },

      companyChanged: function companyChanged() {
        this.get('currentAccount.content.company').then(function (company) {
          company.save();
        });
      },

      accountChanged: function accountChanged() {
        this.get('currentAccount.content').save();
      }

    }

  });
});