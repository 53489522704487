define("due-dashboard/templates/settings/integrations/zendesk/manage", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 8,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/zendesk/manage.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "button-label");
          var el2 = dom.createTextNode("off");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "button-label");
          var el2 = dom.createTextNode("on");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [6, 69], [6, 81]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "parent.actions.toggleTicketOnFeedback", ["loc", [null, [6, 21], [6, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 12], [6, 60]]], 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/zendesk/manage.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1, "class", "inline-block domain");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(".zendesk.com");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "category");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        dom.setAttribute(el2, "class", "inline-block");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "category triggers");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        return morphs;
      },
      statements: [["content", "model.company_integration.company_zendesk_url", ["loc", [null, [1, 32], [1, 81]]], 0, 0, 0, 0], ["inline", "t", ["settings.integrations.zendesk.create_ticket.title"], [], ["loc", [null, [3, 27], [3, 84]]], 0, 0], ["block", "due-button", [], ["type", "action.toggle", "needConfirm", true, "on", ["subexpr", "@mut", [["get", "model.company_integration.ticket_on_feedback", ["loc", [null, [4, 57], [4, 101]]], 0, 0, 0, 0]], [], [], 0, 0], "labels", ["subexpr", "hash", [], ["off", "turn-on", "on", "turn-off"], ["loc", [null, [4, 109], [4, 143]]], 0, 0], "model", ["subexpr", "@mut", [["get", "model.company_integration", ["loc", [null, [4, 150], [4, 175]]], 0, 0, 0, 0]], [], [], 0, 0], "customClass", "inline-block"], 0, null, ["loc", [null, [4, 2], [8, 17]]]], ["inline", "t", ["settings.integrations.zendesk.create_feedback.title"], [], ["loc", [null, [11, 6], [11, 65]]], 0, 0], ["inline", "zendesk/all-triggers", [], ["company_integration", ["subexpr", "@mut", [["get", "model.company_integration", ["loc", [null, [12, 45], [12, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "api_key", ["subexpr", "@mut", [["get", "model.api_key", ["loc", [null, [12, 79], [12, 92]]], 0, 0, 0, 0]], [], [], 0, 0], "setCallback", ["subexpr", "action", ["setCallback"], [], ["loc", [null, [12, 105], [12, 127]]], 0, 0]], ["loc", [null, [12, 2], [12, 129]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});