define('due-dashboard/components/print-div', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',
    divId: null,
    title: null,

    actions: {
      print: function print() {
        var element = document.getElementById(this.get('divId'));
        var title = this.get('title');
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + title + '</title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write('<h1>' + title + '</h1>');
        mywindow.document.write(element.innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
      }
    }

  });
});