define('due-dashboard/components/management-question-category/category-infinite-scroll', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      this.get('resetScrollSteps')();
      var lastScrollTop = 0;
      var _scope = this;
      _ember['default'].$('body > .ember-view').scroll(function () {
        var st = _ember['default'].$(this).scrollTop(),
            scrolled = _ember['default'].$(this)[0].scrollHeight - st;

        if (st > lastScrollTop && scrolled === _ember['default'].$(this).height()) {
          _scope.get('loadMore')();
        }
        lastScrollTop = st;
      });
    },

    actions: {
      reorderCategories: function reorderCategories(_, orderedCategories) {
        orderedCategories.forEach(function (category, index) {
          category.set('position', index);
        });
        this.set('categories', orderedCategories);
        this.get('updateCategoriesList')(orderedCategories);
      }
    }
  });
});