define("due-dashboard/templates/components/graphs/due-graph", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 8,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "edit-graph-name");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "due-field-validation", [], ["model", ["subexpr", "@mut", [["get", "graph", ["loc", [null, [4, 35], [4, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "name", "placeholder", "graph name"], ["loc", [null, [4, 6], [6, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 8
                },
                "end": {
                  "line": 12,
                  "column": 57
                }
              },
              "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "fa fa-star");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 6
              },
              "end": {
                "line": 13,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "isFavoriteGraph", ["loc", [null, [12, 14], [12, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 8], [12, 64]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "graph-name");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["content", "graph.name", ["loc", [null, [10, 10], [10, 24]]], 0, 0, 0, 0], ["block", "if", [["get", "listingMode", ["loc", [null, [11, 12], [11, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 6], [13, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 27,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "scaleType.name", ["loc", [null, [26, 6], [26, 24]]], 0, 0, 0, 0]],
        locals: ["scaleType"],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 4
              },
              "end": {
                "line": 34,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "loading-skeleton/due-bar-charts-skeleton", ["loc", [null, [33, 6], [33, 50]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 2
            },
            "end": {
              "line": 35,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "increment-for", [], ["times", 6], 0, null, ["loc", [null, [32, 4], [34, 22]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 2
            },
            "end": {
              "line": 50,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "c3-chart", [], ["data", ["subexpr", "@mut", [["get", "data", ["loc", [null, [37, 11], [37, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "axis", ["subexpr", "@mut", [["get", "axis", ["loc", [null, [38, 11], [38, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "line", ["subexpr", "@mut", [["get", "line", ["loc", [null, [39, 11], [39, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "legend", ["subexpr", "@mut", [["get", "legend", ["loc", [null, [40, 13], [40, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "spline", ["subexpr", "@mut", [["get", "spline", ["loc", [null, [41, 13], [41, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "padding", ["subexpr", "@mut", [["get", "padding", ["loc", [null, [42, 14], [42, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "transition", ["subexpr", "@mut", [["get", "transition", ["loc", [null, [43, 17], [43, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "grid", ["subexpr", "@mut", [["get", "grid", ["loc", [null, [44, 11], [44, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "tooltip", ["subexpr", "@mut", [["get", "tooltip", ["loc", [null, [45, 14], [45, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "point", ["subexpr", "@mut", [["get", "point", ["loc", [null, [46, 12], [46, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "oninit", ["subexpr", "action", ["chartInit"], [], ["loc", [null, [47, 13], [47, 33]]], 0, 0], "onresized", ["subexpr", "action", ["chartResized"], [], ["loc", [null, [48, 16], [48, 39]]], 0, 0]], ["loc", [null, [36, 4], [49, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 4
              },
              "end": {
                "line": 58,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "loading-period-labels");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "loading-skeleton/due-text-skeleton", [], ["size", "small"], ["loc", [null, [56, 8], [56, 59]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 0
            },
            "end": {
              "line": 60,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "loading-bars-ctn");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "increment-for", [], ["times", 6], 0, null, ["loc", [null, [54, 4], [58, 22]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 4
              },
              "end": {
                "line": 71,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "graphs/event-item", [], ["event", ["subexpr", "@mut", [["get", "event.event", ["loc", [null, [64, 14], [64, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "offset", ["subexpr", "@mut", [["get", "event.offset", ["loc", [null, [65, 15], [65, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "display", ["subexpr", "@mut", [["get", "event.display", ["loc", [null, [66, 16], [66, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "current", ["subexpr", "@mut", [["get", "selectedEvent", ["loc", [null, [67, 16], [67, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", "eventSelected", "loading", ["subexpr", "or", [["get", "graph.isPending", ["loc", [null, [69, 20], [69, 35]]], 0, 0, 0, 0], ["get", "rawStats.isPending", ["loc", [null, [69, 36], [69, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [69, 16], [69, 55]]], 0, 0]], ["loc", [null, [63, 6], [70, 8]]], 0, 0]],
          locals: ["event"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 0
            },
            "end": {
              "line": 74,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "position: relative; display: inline");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "each", [["get", "displayedEvents", ["loc", [null, [62, 12], [62, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [62, 4], [71, 13]]]], ["inline", "graphs/event-table", [], ["events", ["subexpr", "@mut", [["get", "eventsDetails", ["loc", [null, [73, 30], [73, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "deleteEvent", "deleteEvent", "editMode", ["subexpr", "@mut", [["get", "editMode", ["loc", [null, [73, 79], [73, 87]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [73, 2], [73, 89]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 75,
              "column": 0
            },
            "end": {
              "line": 84,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "graphs/timeline-table", [], ["stepType", ["subexpr", "@mut", [["get", "selectedTimeScale", ["loc", [null, [77, 13], [77, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedIndex", ["subexpr", "@mut", [["get", "selectedDateIndex", ["loc", [null, [78, 18], [78, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "graph", ["subexpr", "@mut", [["get", "graph", ["loc", [null, [79, 10], [79, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "data", ["subexpr", "@mut", [["get", "rawStats", ["loc", [null, [80, 9], [80, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "dateFormat", ["subexpr", "@mut", [["get", "xTickFormatter", ["loc", [null, [81, 15], [81, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "loading", ["subexpr", "or", [["get", "graph.isPending", ["loc", [null, [82, 16], [82, 31]]], 0, 0, 0, 0], ["get", "rawStats.isPending", ["loc", [null, [82, 32], [82, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [82, 12], [82, 51]]], 0, 0]], ["loc", [null, [76, 2], [83, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 85,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/graphs/due-graph.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "graph-top-actions");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "time-scale-select");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(fragment, [2]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
        morphs[2] = dom.createAttrMorph(element2, 'class');
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "editMode", ["loc", [null, [2, 8], [2, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [15, 9]]]], ["block", "power-select", [], ["selected", ["subexpr", "@mut", [["get", "selectedTimeScale", ["loc", [null, [18, 15], [18, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", ["changeTimeScale"], [], ["loc", [null, [19, 15], [19, 41]]], 0, 0], "options", ["subexpr", "@mut", [["get", "timeScales", ["loc", [null, [20, 14], [20, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "time-scale-dropdown", "closeOnSelect", true, "searchEnabled", false, "matchTriggerWidth", true], 2, null, ["loc", [null, [17, 4], [27, 21]]]], ["attribute", "class", ["subexpr", "if", [["subexpr", "or", [["get", "graph.isPending", ["loc", [null, [30, 20], [30, 35]]], 0, 0, 0, 0], ["get", "rawStats.isPending", ["loc", [null, [30, 36], [30, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [30, 16], [30, 55]]], 0, 0], "loading-bars-ctn"], [], ["loc", [null, [null, null], [30, 76]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "or", [["get", "graph.isPending", ["loc", [null, [31, 12], [31, 27]]], 0, 0, 0, 0], ["get", "rawStats.isPending", ["loc", [null, [31, 28], [31, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 8], [31, 47]]], 0, 0]], [], 3, 4, ["loc", [null, [31, 2], [50, 9]]]], ["block", "if", [["subexpr", "or", [["get", "graph.isPending", ["loc", [null, [52, 10], [52, 25]]], 0, 0, 0, 0], ["get", "rawStats.isPending", ["loc", [null, [52, 26], [52, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 6], [52, 45]]], 0, 0]], [], 5, 6, ["loc", [null, [52, 0], [74, 7]]]], ["block", "if", [["subexpr", "not", [["get", "editMode", ["loc", [null, [75, 11], [75, 19]]], 0, 0, 0, 0]], [], ["loc", [null, [75, 6], [75, 20]]], 0, 0]], [], 7, null, ["loc", [null, [75, 0], [84, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  })());
});