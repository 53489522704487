define('due-dashboard/services/notifications', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    session: service(),
    store: service(),

    loadNotifications: function loadNotifications() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.get('store').findAll('company-notification').then(function (notifications) {
          _this.set('content', notifications);
        });
      }
    }
  });
});