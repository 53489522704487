define('due-dashboard/components/has-segment-scenario-condition', ['exports', 'ember', 'due-dashboard/components/scenario-condition-item'], function (exports, _ember, _dueDashboardComponentsScenarioConditionItem) {
  exports['default'] = _dueDashboardComponentsScenarioConditionItem['default'].extend({
    segments: [],

    _searchSegments: function _searchSegments(term, resolve, reject) {
      this.store.query('segment', this.query_filter(term)).then(function (segments) {
        return resolve(segments);
      }, reject);
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this._searchSegments('', function (segments) {
        _this.set('segments', segments);
      });
    },

    query_filter: function query_filter() {
      var term = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

      return { filter: { name: term, all: true, display: 'filter' } };
    },

    actions: {
      searchSegments: function searchSegments(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this2, _this2._searchSegments, term, resolve, reject, 600);
        });
      }
    }

  });
});