define('due-dashboard/services/requests/data-query', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    store: service(),

    fetchData: function fetchData(params) {
      var dataQuery = this.get('store').createRecord('data-query');
      dataQuery.setParameters(params);
      return dataQuery.save();
    }
  });
});