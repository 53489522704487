define('due-dashboard/models/search', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-cp-validations', 'due-dashboard/mixins/model-validation-methods'], function (exports, _emberData, _emberDataModelFragments, _emberCpValidations, _dueDashboardMixinsModelValidationMethods) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var fragmentArray = _emberDataModelFragments['default'].fragmentArray;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 3, max: 200 })],
    shared: (0, _emberCpValidations.validator)('inclusion', { 'in': [true, false] })
  });

  exports['default'] = Model.extend(Validations, _dueDashboardMixinsModelValidationMethods['default'], {
    account_id: attr('string'),
    account_email: attr('string'),
    name: attr('string'),
    shared: attr('boolean', { defaultValue: false }),
    filters: attr({ defaultValue: [] })
  });
});