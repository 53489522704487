define('due-dashboard/models/company-notification', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    // ReadOnly attrs
    notification_type: attr('string'),
    text: attr(),
    not_limited_to_admin: attr('boolean'),
    expires_at: attr('date'),

    //RelationShips
    company: belongsTo('company')
  });
});