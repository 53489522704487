define('due-dashboard/models/question', ['exports', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _emberData, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    text: (0, _emberCpValidations.validator)('presence', true)
  });

  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend(Validations, {
    like: attr('string'),
    categories: attr(),
    survey_language: belongsTo('SurveyLanguage', { async: true }),
    survey_revision_question: belongsTo('SurveyRevisionQuestion', { async: true }),
    // question_template: belongsTo('QuestionTemplate', {async: true}),
    from_template: attr('boolean'),
    position: attr('number'),
    text: attr('string'),

    custom: computed('from_template', function () {
      return this.get('from_template') === false;
    }),

    survey_languages: computed('survey_language.survey_revision.survey_languages.[]', function () {
      return this.get('survey_language.survey_revision.survey_languages');
    }),

    siblings: computed('survey_revision_question.questions.[]', function () {
      var questions = this.get('survey_revision_question.questions');
      return questions && questions.rejectBy('id', this.id) || [];
    }),

    survey: belongsTo('survey')
  });
});