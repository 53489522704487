define('due-dashboard/components/custom-stat-lvl', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  /* globals $ */

  $.fn.redraw = function () {
    this.css('display', 'none');
    this.css('display', '');
  };

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'tr',
    classNameBindings: ['pathClass', 'lvlClass', 'npsColor', 'isLoading'],
    parentClass: '',
    folded: false,

    name: computed('lvl.lvl', 'lvl.name', 'i18n.locale', function () {
      if (this.get('lvl.lvl') === -1) {
        return 'total';
      } else {
        return this.get('lvl.name');
      }
    }),

    isFoldable: computed('lvl.is_leaf', 'lvl.lvl', function () {
      if (this.get('lvl.is_leaf') || this.get('lvl.lvl') === -1) {
        return false;
      } else {
        return true;
      }
    }),

    pathClass: computed('lvl.path', function () {
      return 'path-' + this.get('lvl.path');
    }),

    lvlClass: computed('lvl.lvl', function () {
      return 'lvl-' + this.get('lvl.lvl');
    }),

    npsColor: computed('lvl.color', function () {
      return 'color-' + this.get('lvl.color');
    }),

    nextLevel: computed('lvl.lvl', function () {
      return this.get('lvl.lvl') + 1;
    }),

    computedColumns: computed('columns', function () {
      var _this = this;

      return this.get('columns').map(function (col) {
        return !col.color ? col : _extends({}, col, { tag_color: _this.get('lvl.' + col.color) });
      });
    }),

    foldIt: observer('lvlToShow.lvl', function () {
      var lvl = this.get('lvlToShow.lvl');
      if (lvl === undefined) {
        return;
      }
      if (lvl <= this.get('lvl.lvl')) {
        this.foldLine();
      } else {
        this.expandLine();
      }
    }),

    foldLine: function foldLine() {
      var child_class_matching = '.custom-stats-table [class^="' + this.get('pathClass') + '-"], .custom-stats-table [class*=" ' + this.get('pathClass') + '-"]';
      $(child_class_matching).addClass('hidden').addClass('folded');
      this.$().removeClass('expanded').addClass('folded');
    },

    expandLine: function expandLine() {
      var child_class_matching = '.custom-stats-table .lvl-' + this.get('nextLevel') + '[class^="' + this.get('pathClass') + '-"], .custom-stats-table .lvl-' + this.get('nextLevel') + '[class*=" ' + this.get('pathClass') + '-"]';
      $(child_class_matching).removeClass('hidden');
      this.$().removeClass('folded').addClass('expanded');
    },

    actions: {
      toggleChilds: function toggleChilds() {
        var lvlToShow = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        if (lvlToShow) {
          this.set('lvlToShow', null);
        }
        var status = this.$().hasClass('folded') ? 'folded' : 'expanded';
        if (status === 'expanded') {
          this.foldLine();
        } else {
          this.expandLine();
        }
      }
    }

  });
});