define('due-dashboard/components/change-end-fb-share-scenario-action', ['exports', 'due-dashboard/components/scenario-action-item', 'ember'], function (exports, _dueDashboardComponentsScenarioActionItem, _ember) {
  exports['default'] = _dueDashboardComponentsScenarioActionItem['default'].extend({
    action: null,

    error_keys: _ember['default'].computed(function () {
      return ['url_error_key'];
    }),

    errorObserverVars: {
      single_error_key: 'url_error_key',
      single_error_target: 'action.validations.attrs.page_url.error'
    }

  });
});