define('due-dashboard/helpers/match', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function matchHelper(params) {
    var var1 = _ember['default'].isArray(params[0]) ? params[0] : params[1];
    var var2 = _ember['default'].isArray(params[0]) ? params[1] : params[0];
    if (!_ember['default'].isArray(var1) || _ember['default'].isArray(var2)) {
      return var1 === var2;
    } else {
      return var1.indexOf(var2) !== -1;
    }
  });
});