define('due-dashboard/routes/settings/tags/translations', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        languages: this.store.query('language', { filter: { account_id: this.currentAccount.content.id } }),
        company: this.get('currentAccount.content.company')
      });
    }
  });
});