define('due-dashboard/routes/companies/templates', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {},

    actions: {

      newTemplate: function newTemplate() {
        var bulk_dispatch_id = null;
        this.transitionTo('companies.templates.edit', this.store.createRecord('template'), { queryParams: { bulk_dispatch_id: bulk_dispatch_id } });
      },

      modifyTemplate: function modifyTemplate(template) {
        this.transitionTo('companies.templates.edit', this.store.findRecord('template', template.get('id')));
      },

      test: function test() {}
    }

  });
});