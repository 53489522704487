define('due-dashboard/components/v2/array/-paginator', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['due-array-paginator'],

    leftButtonDisabled: computed('pageIndex', 'countIndex', function () {
      return this.get('pageIndex') <= 1;
    }),

    rightButtonDisabled: computed('pageIndex', 'countIndex', function () {
      return this.get('pageIndex') >= this.get('countIndex');
    }),

    actions: {
      changePagination: function changePagination(selectedPagination) {
        var func = this.get('paginationSelectionCallback');

        if (func) {
          func(selectedPagination);
        }
      },

      previous: function previous() {
        var func = this.get('navigationLeftButtonCallback');

        if (func) {
          func();
        }
      },

      next: function next() {
        var func = this.get('navigationRightButtonCallback');

        if (func) {
          func();
        }
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/array/-paginator}} array footer pagination component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */