define('due-dashboard/components/v2/libraries/surveys/navbar/elements/element-content', ['exports', 'ember', 'ember-data', 'due-dashboard/components/v2/libraries/surveys/navbar/elements/common/closed-questions'], function (exports, _ember, _emberData, _dueDashboardComponentsV2LibrariesSurveysNavbarElementsCommonClosedQuestions) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content'],
    elementName: null,

    conditionSourceFilterInput: '',

    CONDITION_KINDS: ['contains', 'does_not_contains', 'is_any_of', 'is_not_one_of'],

    CONDITIONABLE_QUESTION_TYPES: ['rating', 'short_text_input', 'long_text_input', 'single_select', 'multiple_select'],
    EXECUTABLE_ELEMENT_KINDS: ['group', 'assign_segment', 'submit_step', 'soft_complete'],

    executableElement: computed('', function () {
      var execElements = this.get('EXECUTABLE_ELEMENT_KINDS');
      var object = this.get('object');

      return execElements.includes(object.kind);
    }),

    dasherizedKind: computed('object.kind', function () {
      var kind = this.get('object.kind');

      return kind.replace('_', '-');
    }),

    dropdownConditionActions: computed(function () {
      return [{ color: ' ', icon: 'tio-copy', action: 'duplicateCondition', text: this.get('i18n').t('words.duplicate_maj') }, { color: ' ', icon: 'tio-delete', action: 'deleteCondition', text: this.get('i18n').t('words.delete_maj') }];
    }),

    conditionSourceOptions: computed('group.segmentsList', 'group.questionsList', 'conditionSourceFilterInput', function () {
      var _this = this;

      var conditionableQuestionsTypes = this.get('CONDITIONABLE_QUESTION_TYPES');
      var filter = this.get('conditionSourceFilterInput');
      var filterParentKey = this.get('conditionSourceFilterKey');
      var questions = (filter.length ? this.get('group.questionsList').filter(function (question) {
        return question.text.toLowerCase().includes(filter.toLowerCase()) && conditionableQuestionsTypes.includes(question.kind);
      }) : this.get('group.questionsList').filter(function (question) {
        return conditionableQuestionsTypes.includes(question.kind);
      })).filter(function (question) {
        return question.step <= _this.get('selectedStepIndex');
      });
      var segments = filter.length ? this.get('group.segmentsList').filter(function (segment) {
        return segment.text.toLowerCase().includes(filter.toLowerCase());
      }) : this.get('group.segmentsList');

      if (filter.length > 0) {
        return filterParentKey === 'segments' ? segments : questions.map(function (question) {
          return { text: question.step + 1 + '.' + (question.element + 1) + ' ' + question.text, type: 'question', step: question.step, element: question.element };
        });
      } else {
        return [{ text: this.get('i18n').t('words.segments').string, options: segments, key: 'segments' }, { text: this.get('i18n').t('words.questions').string, key: 'questions', options: questions.map(function (question) {
            return { text: question.step + 1 + '.' + (question.element + 1) + ' ' + question.text, type: 'question', step: question.step, element: question.element, content: question.content };
          }) }];
      }
    }),

    tabOptions: computed('object.conditions', 'object', function () {
      var object = this.get('object');
      var conditions = object.conditions.filter(function (condition) {
        if (condition.data[condition.kind] && condition.data[condition.kind].length > 0 && (condition.data.answer_element != null || condition.data.segment_name != null)) {
          return true;
        }
      });
      var tabName = ({
        title: 'title',
        text: 'text',
        short_text_input: 'text',
        long_text_input: 'text',
        single_select: 'question',
        multiple_select: 'question',
        rating: 'question',
        prev_next_btns: 'buttons',
        redirect_btn: 'button',
        soft_complete: 'checkpoint',
        assign_segment: 'segment',
        submit_step: 'jump_to'
      })[object.kind];
      return [{ label: this.get('i18n').t('words.' + tabName) }, { label: this.get('i18n').t('words.conditions'), badge: conditions.length }];
    }),

    conditionsDataMapping: {
      'answer_in': {
        answer_step: 0,
        answer_element: 0,
        answer_in: []
      },
      'segment_value_in': {
        segment_name: '',
        segment_value_in: []
      },
      'segment_value_not_in': {
        segment_name: '',
        segment_value_not_in: []
      },
      'segment_value_contains': {
        segment_name: '',
        segment_value_contains: []
      },
      'segment_value_not_contains': {
        segment_name: '',
        segment_value_not_contains: []
      }
    },

    cmpElementIndex: computed('selectedElementIndex', function () {
      var _this2 = this;

      var elemIndex = this.get('selectedElementIndex');
      var object = this.get('object');

      if (object.kind === 'group') {
        var subElement = object.child_indexes_info.filter(function (childUuid) {
          return childUuid === _this2.get('object.uuid');
        })[0];
        if (subElement) {
          elemIndex = subElement.idx;
        }
      }

      return elemIndex;
    }),

    computedElementConditions: computed('object.conditions', function () {
      var _this3 = this;

      var elemIdx = Math.max(0, this.get('selectedElementIndex'));
      var steps = this.get('steps');
      var stepIdx = this.get('selectedStepIndex');
      var groupNav = this.get('groupNavigationIdx');
      var path = groupNav.map(function (groupIdx) {
        return '.' + groupIdx + '.content.children';
      }).join('');

      var promises = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions')).map(function callee$1$0(condition) {
        var questionList;
        return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
          while (1) switch (context$2$0.prev = context$2$0.next) {
            case 0:
              context$2$0.next = 2;
              return regeneratorRuntime.awrap(_dueDashboardComponentsV2LibrariesSurveysNavbarElementsCommonClosedQuestions['default']._getQuestionSource.bind(this)(condition));

            case 2:
              questionList = context$2$0.sent;
              return context$2$0.abrupt('return', {
                kind: condition.kind,
                cmpType: this._getCmpType(condition),
                cmpSource: this._getCmpSource(condition),
                data: condition.data,
                questionSource: questionList,
                questionSourceRemaining: [].concat(_toConsumableArray(questionList.filter(function (q) {
                  return !(condition.data.answer_in || []).some(function (c) {
                    return q.key == c;
                  });
                })))
              });

            case 4:
            case 'end':
              return context$2$0.stop();
          }
        }, null, _this3);
      });

      return _emberData['default'].PromiseArray.create({
        promise: new _ember['default'].RSVP.Promise(function (resolve) {
          _ember['default'].RSVP.allSettled(promises).then(function (responses) {
            resolve(responses.map(function (r) {
              return r.value;
            }));
          });
        })
      });
    }),

    _computeConditionKind: function _computeConditionKind(kind, condition) {
      if (condition.data.segment_name) {
        switch (kind) {
          case 'is_any_of':
            return 'segment_value_in';
          case 'is_not_one_of':
            return 'segment_value_not_in';
          case 'contains':
            return 'segment_value_contains';
          case 'does_not_contains':
            return 'segment_value_not_contains';
        }
      } else {
        return 'contains';
      }
    },

    _getCmpType: function _getCmpType(condition) {
      switch (condition.kind) {
        case 'answer_in':
          return 'is_any_of';
        case 'segment_value_in':
          return 'is_any_of';
        case 'segment_value_not_in':
          return 'is_not_one_of';
        case 'segment_value_contains':
          return 'contains';
        case 'segment_value_not_contains':
          return 'does_not_contains';
      }
    },

    _getCmpSource: function _getCmpSource(condition) {
      var questions = this.get('group.questionsList');

      if (condition.kind.includes('segment_value_')) {
        return { text: condition.data.segment_name };
      } else if (condition.kind === 'answer_in') {
        var _ret = (function () {
          var step = condition.data.answer_step;
          var element = condition.data.answer_element;
          var question = questions.filter(function (question) {
            return question.step === step && question.element === element;
          })[0].text;

          return {
            v: { text: step + 1 + '.' + (element + 1) + ' ' + question }
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    },

    actions: {
      dispatchAction: function dispatchAction(action, arg) {
        var dispatcher = this.get('actionsDispatcher');

        if (dispatcher) {
          dispatcher(action, arg);
        }
      },

      dispatchActionLocally: function dispatchActionLocally(arg, action, callbackArgs) {
        if (action) {
          this.send(action, arg, callbackArgs);
        }
      },

      addCondition: function addCondition() {
        var elemIdx = this.get('selectedElementIndex');
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var groupNav = this.get('groupNavigationIdx');
        var path = groupNav.map(function (groupIdx) {
          return '.' + groupIdx + '.content.children';
        }).join('');
        var newCondition = { uuid: crypto.randomUUID(), kind: '', data: {} };
        var childConditions = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions'));

        childConditions.push(newCondition);
        set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions'), childConditions);
        this.set('unsavedChanges', true);
        this.send('dispatchAction', 'refreshSteps');
      },

      backToElements: function backToElements() {
        var stateToggleCallback = this.get('onStateChange');

        stateToggleCallback(null, -1, true);
      },

      setConditionKind: function setConditionKind(index, kind) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elemIdx = this.get('selectedElementIndex');
        var groupNav = this.get('groupNavigationIdx');
        var path = groupNav.map(function (groupIdx) {
          return '.' + groupIdx + '.content.children';
        }).join('');
        var condition = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index));
        var values = condition.data[condition.kind];
        var newKind = this._computeConditionKind(kind, condition);

        set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.kind'), newKind);

        set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data.' + newKind), values);
        this.set('unsavedChanges', true);
        this.send('dispatchAction', 'refreshSteps');
      },

      setConditionSource: function setConditionSource(index, source) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elemIdx = this.get('selectedElementIndex');
        var conditionsDataMapping = this.get('conditionsDataMapping');
        var groupNav = this.get('groupNavigationIdx');
        var path = groupNav.map(function (groupIdx) {
          return '.' + groupIdx + '.content.children';
        }).join('');

        if (source.type === 'question') {
          var conditionStepIdx = source.step;
          var conditionElemIdx = source.element;
          var condition = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index));
          var values = condition.data[condition.kind] || [];
          values.clear();

          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index), { kind: '', data: {} });
          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.kind'), 'answer_in');
          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data'), copy(conditionsDataMapping['answer_in'], true));
          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data.answer_step'), parseInt(conditionStepIdx));
          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data.answer_element'), parseInt(conditionElemIdx));
          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data.answer_in'), values);
        } else {
          var condition = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index));
          var values = condition.data[condition.kind] || [];
          var newKind = condition.kind.includes('answer') ? 'segment_value_in' : source.kind || 'segment_value_in';

          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index), { kind: '', data: {} });
          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.kind'), newKind);
          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data'), copy(conditionsDataMapping[newKind], true));
          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data.segment_name'), source.text);
          set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data.' + newKind), values);
        }
        this.set('unsavedChanges', true);
        this.send('dispatchAction', 'refreshSteps');
      },

      setQuestionSource: function setQuestionSource(options, index, question) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elemIdx = this.get('selectedElementIndex');
        var groupNav = this.get('groupNavigationIdx');
        var path = groupNav.map(function (groupIdx) {
          return '.' + groupIdx + '.content.children';
        }).join('');
        var condition = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index));

        var values = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data.' + condition.kind)) || [];
        if (values.findIndex(function (v) {
          return v == question.key;
        }) == -1) {
          values.push(question.key);
          set(options, 'questionSourceRemaining', [].concat(_toConsumableArray(options.questionSourceRemaining.toSpliced(options.questionSourceRemaining.findIndex(function (o) {
            return o.key == question.key;
          }), 1))));
          this.send('addConditionValue', null, index, values);
        }
      },

      toggleDropdown: function toggleDropdown(onToggle) {
        onToggle();
      },

      duplicateCondition: function duplicateCondition(index) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elemIdx = this.get('selectedElementIndex');
        var groupNav = this.get('groupNavigationIdx');
        var path = groupNav.map(function (groupIdx) {
          return '.' + groupIdx + '.content.children';
        }).join('');
        var duplicateCondition = copy(get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index)), true);

        var conditions = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions'));
        conditions.splice(index + 1, 0, duplicateCondition);
        set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions'), conditions);
        this.set('unsavedChanges', true);
        this.send('dispatchAction', 'refreshSteps');
      },

      deleteCondition: function deleteCondition(index) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elemIdx = this.get('selectedElementIndex');
        var groupNav = this.get('groupNavigationIdx');
        var path = groupNav.map(function (groupIdx) {
          return '.' + groupIdx + '.content.children';
        }).join('');

        var conditions = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions'));
        conditions.splice(index, 1);
        set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions'), conditions);
        this.send('dispatchAction', 'refreshSteps');
      },

      setFilteredSources: function setFilteredSources(value, parentKey) {
        this.set('conditionSourceFilterInput', value);
        this.set('conditionSourceFilterKey', parentKey);
      },

      addConditionValue: function addConditionValue(options, index, values) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elemIdx = this.get('selectedElementIndex');
        var groupNav = this.get('groupNavigationIdx');
        var path = groupNav.map(function (groupIdx) {
          return '.' + groupIdx + '.content.children';
        }).join('');
        var condition = get(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index));

        set(steps[stepIdx].survey_elements_attributes, path + ('.' + elemIdx + '.conditions.' + index + '.data.' + condition.kind), values.map(function (v) {
          return v['key'] ? v['key'] : v;
        }));
        if (options) set(options, 'questionSourceRemaining', [].concat(_toConsumableArray(options.questionSource.filter(function (q) {
          return !values.some(function (v) {
            return q.key == v.key;
          });
        }))));
        this.set('unsavedChanges', true);
      },

      editElementName: function editElementName(value) {
        var elementName = this.get('elementName');
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elementIdx = this.get('selectedElementIndex');
        var element = this.get('object');

        if (value !== -1) {
          this.set('elementName', value);
          this.set('object.name', value);
        } else if (value === -1 && elementName) {
          set(steps[stepIdx].survey_elements_attributes[elementIdx], 'name', element.name);
          this.set('elementName', null);
        }
        this.set('unsavedChanges', true);
      },

      updateElementContent: function updateElementContent(content, kind) {
        var object = this.get('object');
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elementIdx = this.get('selectedElementIndex');
        var unsavedChangesMap = this.get('unsavedChangesMap');

        set(object, 'content', content);
        set(steps[stepIdx].survey_elements_attributes[elementIdx], 'content', content);
        if (kind) set(steps[stepIdx].survey_elements_attributes[elementIdx], 'kind', kind);
        this.set('unsavedChanges', true);
        unsavedChangesMap.push({ step: stepIdx, element: elementIdx });
        this.set('unsavedChangesMap', unsavedChangesMap);
        this.get('triggerUnsavedChangesMap')(unsavedChangesMap, steps);
      },

      setSelectedTab: function setSelectedTab(tabIndex) {
        this.set('selectedTabIndex', tabIndex);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/surveys-library/surveys/navbar/elements/elements-content}} alert component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */