define("due-dashboard/helpers/color-formating", ["exports", "ember"], function (exports, _ember) {
  exports.colorFormating = colorFormating;

  function colorFormating(color) {
    var colorRegex = "([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
    var startWithHashtagRegex = "^#";

    if (color.match(colorRegex) && !color.match(startWithHashtagRegex)) color = '#' + color;
    return color;
  }

  exports["default"] = _ember["default"].Helper.helper(colorFormating);
});