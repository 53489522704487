define('due-dashboard/models/change-end-fb-like-scenario-actions-language', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;

  var Validations = (0, _emberCpValidations.buildValidations)({
    page_url: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'url' })]
  });

  exports['default'] = Model.extend(Validations, {

    action: belongsTo('change-end-fb-like-scenario-action', { async: true }),
    language: belongsTo('language', { async: true }),
    page_url: attr('string', { defaultValue: 'https://' })

  });
});