define('due-dashboard/components/due-table/elements/-load-more', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    remainingLinesNumber: computed('value', function () {
      return this.get('value');
    }),

    actions: {
      loadMore: function loadMore() {
        var triggerAction = this.get('triggerAction');
        if (triggerAction) {
          triggerAction();
        }
      }
    }
  });
});