define('due-dashboard/components/month-picker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    classNames: ['due-month-picker'],

    minDate: null,
    maxDate: null,

    pickingMode: 'main',

    months: computed('minDate', 'maxDate', 'selectedYear', 'date', function () {
      var _this = this;

      return _moment['default'].months().map(function (month_name) {
        var month_date = (0, _moment['default'])(_this.get('selectedYear') + '/' + month_name, 'YYYY/MMMM');
        return {
          name: month_date.format('MMM'),
          date: month_date.startOf('month').toDate(),
          is_picked: month_date.isSame(_this.get('date')),
          disabled: month_date.endOf('month').diff(_this.get('minDate')) < 0 || month_date.startOf('month').diff(_this.get('maxDate')) > 0
        };
      });
    }),

    defaultSelectedYear: computed('date', function () {
      if (this.get('date')) {
        return (0, _moment['default'])(this.get('date')).format('YYYY');
      } else {
        return (0, _moment['default'])().format('YYYY');
      }
    }),

    selectedYear: computed.oneWay('defaultSelectedYear'),

    dateChanged: observer('defaultSelectedYear', function () {
      this.set('selectedYear', this.get('defaultSelectedYear'));
    }),

    actions: {
      yearView: function yearView() {
        this.set('pickingMode', 'year');
      },

      backToMainView: function backToMainView() {
        this.set('pickingMode', 'main');
      },

      pickMonth: function pickMonth(month) {
        if (this.get('monthPicked')) {
          this.get('monthPicked')(month);
        }
      },

      yearPicked: function yearPicked(year) {
        this.set('selectedYear', (0, _moment['default'])(year).format('YYYY'));
        this.send('backToMainView');
      }
    }
  });
});