define('due-dashboard/components/icons/thumb-up-outlined', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['thumb-up-outlined-icon'],
    classNameBindings: ['feeling'],

    hexColor: computed(function () {
      return ({
        'positive': '#00B548',
        'neutral': '#FAB005',
        'negative': '#FA5252'
      })[this.get('feeling')];
    })
  });
});