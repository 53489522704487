define('due-dashboard/components/letter-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tag: '',

    didReceiveAttrs: function didReceiveAttrs() {
      this.formatValue(this.get('value') || "");
      this._super.apply(this, arguments);
    },

    formatValue: function formatValue(value) {
      value = value.replace(/[^a-zA-Z ]/g, '');
      value = value.substring(0, 11);
      this.set('value', value);
    },

    actions: {

      normalizeValue: function normalizeValue(value) {
        this.formatValue(value);
        this.$("#alias").val(this.get('value'));
      }
    }
  });
});