define('due-dashboard/components/folding-button', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    // tagName: 'span',
    // classNameBindings: ['pathClass', 'lvlClass', 'npsColor'],
    // parentClass: '',
    classNames: ['left'],
    // folded: false,

    isFoldable: computed('lvl.is_leaf', 'lvl.lvl', function () {
      if (this.get('lvl.is_leaf') || this.get('lvl.lvl') === -1) {
        return false;
      } else {
        return true;
      }
    }),

    pathClass: computed('lvl.path', function () {
      return 'path-' + this.get('lvl.path');
    }),

    lvlClass: computed('lvl.lvl', function () {
      return 'lvl-' + this.get('lvl.lvl');
    }),

    npsColor: computed('lvl.color', function () {
      return 'color-' + this.get('lvl.color');
    }),

    nextLevel: computed('lvl', function () {
      return this.get('lvl') + 1;
    })

  });
});