define('due-dashboard/models/stat-comment', ['exports', 'ember-data-model-fragments', 'ember-data', 'ember'], function (exports, _emberDataModelFragments, _emberData, _ember) {
  var computed = _ember['default'].computed;
  var attr = _emberData['default'].attr;
  exports['default'] = _emberDataModelFragments['default'].Fragment.extend({
    feedback_id: attr(),
    effective_comment: attr('string'),
    created_at: attr('date'),
    rating_picture: attr('string'),
    translation: attr('string'),
    source: attr('string'),

    rating: computed('rating_picture', function () {
      return {
        picture: this.get('rating_picture')
      };
    }),

    comment: computed.alias('effective_comment')
  });
});