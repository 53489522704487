define('due-dashboard/components/integration-action-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    confirmation: _ember['default'].computed('model', function () {
      var integration = this.get('model');
      if (integration) {
        return this.get('i18n').t('settings.integrations.confirm_delete', { integration: integration.get('name') });
      }
    })

  });
});