define('due-dashboard/components/display-segments', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    store: _ember['default'].inject.service(),
    optionDisplay: 'hidden',
    arrowClass: 'arrow-right',
    open: 'close',
    allSegments: [],

    actions: {
      toggleList: function toggleList() {
        this.toggleProperty('isWhitelist');
        this.set("needSaving", true);
      },

      toggleTextOptionsDisplay: function toggleTextOptionsDisplay() {
        this.get('optionDisplay') == 'hidden' ? this.set('optionDisplay', 'visible') : this.set('optionDisplay', 'hidden');
        this.get('arrowClass') == 'arrow-right' ? this.set('arrowClass', 'arrow-down') : this.set('arrowClass', 'arrow-right');
        this.get('open') == 'open' ? this.set('open', 'close') : this.set('open', 'open');
      }
    }
  });
});