define('due-dashboard/components/aircall-tags-dp', ['exports', 'ember'], function (exports, _ember) {
  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    updateSelected: observer('tags', function () {
      var _this = this;

      if (this.get('tags.firstObject.name')) {
        (function () {
          var target_ids = (_this.get('target') || []).mapBy('id');
          _this.set('target', _this.get('tags').filter(function (elem) {
            return target_ids.indexOf(elem.id) !== -1;
          }));
        })();
      }
    })
  });
});