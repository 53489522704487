define('due-dashboard/models/company-segment-value', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  /* global _ */

  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    feedback: _emberData['default'].belongsTo('Feedback', { async: true }),
    value: _emberData['default'].attr('string'),
    category: _emberData['default'].attr('string'),
    //companies_segment: DS.belongsTo('CompaniesSegment', {async: true}),
    segment: _emberData['default'].belongsTo('Segment', { async: true }),

    isHidden: computed('segment.name', function () {
      if (this.get('segment.name')) {
        return _.startsWith(this.get('segment.name'), '.');
      }
    }),

    position: computed.alias('segment.position')
  });
});