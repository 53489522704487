define('due-dashboard/controllers/settings/integrations/microsoft-dynamics', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({
    submitEnabled: computed.notEmpty('model.company_integration.hostname'),
    hideSave: true,

    microsoft_dynamics_module_dl_link: computed('api_version', function () {
      return _dueDashboardConfigEnvironment['default'].EmberENV['DYNAMICS_SOLUTION_URL_' + this.get('api_version').toUpperCase()];
    }),

    microsoft_dynamics_managed_module_dl_link: computed('api_version', function () {
      return _dueDashboardConfigEnvironment['default'].EmberENV['DYNAMICS_MANAGED_SOLUTION_URL_' + this.get('api_version').toUpperCase()];
    }),

    microsoft_dynamics_auth: computed('model.company.id', 'model.company_integration.hostname', 'model.company_integration.crm_version', function () {
      var hostname = this.get('model.company_integration.hostname');
      var crm_version = this.get('model.company_integration.crm_version');
      if (hostname && hostname !== '' && crm_version && crm_version !== '') {
        return _dueDashboardConfigEnvironment['default'].EmberENV.MICROSOFT_DYNAMICS_CONNECT_URL + '/' + this.get('model.company.id') + '?hostname=' + hostname + '&crm_version=' + crm_version;
      } else {
        return "";
      }
    })

  });
});