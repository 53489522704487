define('due-dashboard/models/change-end-image-scenario-action', ['exports', 'due-dashboard/models/scenario-action', 'ember-data', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioAction, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    image: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    image: attr(),

    defineSavepoint: function defineSavepoint() {
      return this;
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
    }
  });
});