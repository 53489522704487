define('due-dashboard/routes/companies/surveys/edit/bulk-dispatch/send-survey', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var template = this.store.findRecord('template', params.template_id);
      var bulk_dispatch = this.store.findRecord('bulk-dispatch', params.bulk_dispatch_id);
      return _rsvp['default'].hash({ template: template, bulk_dispatch: bulk_dispatch });
    },

    actions: {
      afterSave: function afterSave() {
        var v2 = false; // this.get('currentAccount.content.company.content.enable_new_survey_dashboard');
        var route = v2 ? 'companies.libraries.surveys' : 'companies.surveys';

        this.transitionTo(route);
      },

      previousStep: function previousStep() {
        this.transitionTo('companies.surveys.edit.bulk-dispatch.template', this.currentModel.bulk_dispatch.get('survey.id'), this.currentModel.bulk_dispatch.get('id'));
      }
    }

  });
});