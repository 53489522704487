define('due-dashboard/routes/settings/api', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      var _this = this;

      return this.get('currentAccount.content.company').then(function (company) {
        return _ember['default'].RSVP.hash({
          company: company,
          api_keys: _this.store.findAll('api_key')
        });
      });
    },

    deactivate: function deactivate() {
      this.currentModel.company.rollbackAttributes();
    }

  });
});