define('due-dashboard/routes/settings/integrations/aircall', ['exports', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({
    needApiKey: true,

    properPageRedirection: function properPageRedirection(model) {
      var company_integration = model.company_integration;
      if (company_integration.get('completed')) {
        if (company_integration.get('aircall_filters.length') === 0) {
          company_integration.set('aircall_filters', [this.get('store').createRecord('aircall-filter', {
            aircall_company_integration: company_integration
          })]);
        }
      }
      this._super.apply(this, arguments);
    }

  });
});