define('due-dashboard/routes/settings/company', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return _ember['default'].RSVP.hash({
        company: this.get('currentAccount.content.company'),
        languages: this.store.query('language', { filter: { account_id: this.currentAccount.content.id } })
      });
    }

  });
});