define('due-dashboard/components/feedback-rate-chart', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['feedback-rate-chart'],

    colors: {
      feedback: _dueDashboardConfigEnvironment['default'].EmberENV.colors[3],
      not_feedback: '#adadad'
    },

    data: computed.alias('model.feedback_rates.content'),
    defaultSelectedKey: 'feedback'
  });
});