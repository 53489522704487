define('due-dashboard/controllers/application', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var Controller = _ember['default'].Controller;
  var observer = _ember['default'].observer;
  exports['default'] = Controller.extend({

    classNames: ['application-wrapper'],
    notifications: _ember['default'].inject.service(),
    notificationsContent: _ember['default'].computed('notifications.content', function () {
      return this.get('notifications.content');
    }),

    init: function init() {
      if (_dueDashboardConfigEnvironment['default'].EmberENV.ENABLE_SKALIN) {
        ska(function (skalin) {
          skalin.clientId(_dueDashboardConfigEnvironment['default'].EmberENV.SKALIN_CLIENT_ID);
        });
      }
    },

    currentPathChange: observer('currentPath', function () {
      var _this = this;

      _ember['default'].$('body').attr('data-view-path', this.get('currentPath'));
      if (_dueDashboardConfigEnvironment['default'].EmberENV.ENABLE_SKALIN) {
        (function () {
          var page = _this.get('currentPath');
          ska(function (skalin) {
            skalin.feature({ name: page });
          });
        })();
      }
    })

  });
});