define('due-dashboard/components/digests/parts/order-by-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    classNames: ['score-part'],

    init: function init() {
      if (!this.get('comp.options.order_by')) {
        var defaultOption = this.get('orderOptions')[0];
        this.set('comp.options.order_by', typeof defaultOption == 'object' ? defaultOption.key : defaultOption);
      }
      this._super.apply(this, arguments);
    },

    actions: {

      changeOrder: function changeOrder(order) {
        this.set('comp.options.order_by', order);
        this.get('saveDigestDelay')();
      }
    }
  });
});