define('due-dashboard/components/profile-chooser', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var filterBy = _Ember$computed.filterBy;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var later = _ember['default'].run.later;
  var set = _ember['default'].set;
  var Component = _ember['default'].Component;

  var get_root = function get_root(_x) {
    var _again = true;

    _function: while (_again) {
      var options = _x;
      _again = false;

      var opt = options.findBy('parentLevel');
      if (opt) {
        opt = opt.parentLevel.options;
      }
      if (opt) {
        _x = opt;
        _again = true;
        opt = undefined;
        continue _function;
      }
      return options;
    }
  };

  exports['default'] = Component.extend({

    classNames: ['profile-chooser'],
    defaultProfile: 'manager',
    searchInput: '',
    promise: null,
    filteredProfiles: [],
    store: service(),
    reloadDp: true,
    _customProfiles: filterBy('profiles.name', 'custom'),
    customProfiles: alias('_customProfiles.firstObject.options'),
    firstInit: true,

    getUserRoleCreation: function getUserRoleCreation() {
      return {
        type: 'new_profile',
        name: this.get('i18n').t('settings.users.profiles.new_custom_profile').string,
        title: this.get('i18n').t('settings.users.profiles.new_custom_profile').string,
        icon: 'fa-plus'
      };
    },

    getDefaultRoles: computed('currentAccount.isAdmin', function () {
      if (this.get('account.zone') === 'China' || !this.get('currentAccount.isAdmin')) {
        return [];
      }
      return [{ name: this.get('i18n').t('settings.groups.admin').string, title: 'admin' }, { name: this.get('i18n').t('settings.groups.manager').string, title: 'manager' }, { name: this.get('i18n').t('settings.groups.read_only').string, title: 'read_only' }];
    }),

    selectedProfile: computed('account.profile', 'existing_groups', function () {
      var _this = this;

      var profile = this.get('account.profile');
      if (profile && this.get('existing_groups')) {
        return profile === 'custom' ? this.get('existing_groups').find(function (g) {
          return g.id === _this.get('account.account_custom_profile_right.id');
        }) : this.get('profiles').find(function (p) {
          return p.title === _this.get('account.profile');
        });
      } else {
        return this.get('profiles').find(function (p) {
          return p.title === 'manager';
        });
      }
    }),

    setExistingProfiles: (function () {
      var _this2 = this;

      this.set('_profiles_not_set', true);
      this.set('existing_groups', null);
      // size 10000 is a hack to get all account_custom_profile_right
      var promise = this.get('store').query('account_custom_profile_right', { filter: { without_own_custom_role: 'true' }, page: { number: 1, size: 10000 } });
      this.set('promise', promise);
      promise.then(function (profiles) {
        _this2.set('existing_groups', profiles);
        _this2.set('firstInit', false);
      });
    }).on('didInsertElement'),

    _searchProfiles: function _searchProfiles() {
      var _this3 = this;

      var ret = this.get('getDefaultRoles');
      var searchInput = this.get('searchInput').toLowerCase();
      var userRoleCreation = this.getUserRoleCreation();
      var promise = this.get('store').query('account_custom_profile_right', { filter: { name: searchInput, without_own_custom_role: 'true' } });
      this.set('promise', promise);
      promise.then(function (acpr) {
        acpr.toArray().map(function (a) {
          return set(a, 'title', a.get('name'));
        });
        ret = ret.filter(function (p) {
          return typeof p.name === 'string' ? p.name.toLowerCase().includes(searchInput) : p.get('name').toLowerCase().includes(searchInput);
        });
        ret = ret.concat(acpr.toArray());

        if (_this3.get('onCreate') && _this3.get('currentAccount.isAdmin') && !ret.find(function (r) {
          return r.type === 'new_profile';
        })) {
          ret.push(userRoleCreation);
        }
        _this3.set('filteredProfiles', ret);
      });
    },

    computedProfiles: computed('profiles', 'filteredProfiles', function () {
      return this.get('searchInput.length') === 0 ? this.get('profiles') : this.get('filteredProfiles');
    }),

    profiles: computed('currentAccount.content', 'existing_groups.[]', 'i18n.locale', 'selectedProfile', 'searchInput', 'account.zone', function () {
      var _this4 = this;

      var options = (this.get('existing_groups') || []).sortBy('name');
      options.map(function (a) {
        return set(a, 'title', a.get('name'));
      });
      var searchInput = this.get('searchInput').toLowerCase();
      var userRoleCreation = this.getUserRoleCreation();
      var ret = this.get('getDefaultRoles');

      if (this.get('onCreate') && this.get('currentAccount.isAdmin')) {
        options = options.concat(userRoleCreation);
      }

      if (searchInput.length === 0) {
        this.set('filteredProfiles', []);

        if (ret.find(function (r) {
          return r.type === 'group';
        })) {
          ret.find(function (r) {
            return r.type === 'group';
          }).options = options;
        } else {
          ret.push({
            type: 'group',
            name: this.get('i18n').t('settings.groups.custom').string,
            title: 'custom',
            options: options
          });
        }
      }
      later(function () {
        return _this4.notifyPropertyChange('customProfiles');
      });
      return ret;
    }),

    profilesObserver: observer('profiles.[]', function () {
      this.toggleProperty('reloadDp');
    }),

    _set_profile: observer('dpApi', 'customProfiles', function () {
      var api = this.get('dpApi');
      if (api && api.options.length && !this.get('selectedProfile')) {
        var profile = this.get('account.profile');
        if (profile === 'custom') {
          var to_find = get_root(api.options).findBy('levelName.title', 'custom').options.findBy('name', this.get('account.account_custom_profile_right.name'));
          if (to_find) {
            api.actions.choose(to_find);
          }
        } else if (profile) {
          api.actions.choose(get_root(api.options).findBy('title', profile));
        } else {
          var opt = get_root(api.options).findBy('title', this.get('defaultProfile'));
          if (opt) {
            api.actions.choose(opt);
          }
        }
      }
    }),

    actions: {
      setSelectedProfile: function setSelectedProfile(selected) {
        if (selected.type === 'new_profile') {
          this.get('onCreate')();
        } else if (this.get('account')) {
          if (selected.constructor.name === "Object") {
            this.set('account.profile', selected.title);
          } else {
            this.set('account.profile', 'custom');
            this.set('account.account_custom_profile_right', selected);
          }
        }
        this.set('selectedProfile', selected);
        var act = this.get('onChange');
        if (act) {
          act(selected);
        }
      },

      onSearch: function onSearch(term) {
        this.set('searchInput', term);
        this._searchProfiles();
      },

      toggleIcon: function toggleIcon(onToggle) {
        onToggle();
      },

      registerApi: function registerApi(api) {
        this.set('dpApi', api);
      }
    }
  });
});