define("due-dashboard/templates/components/integrations/salesforce-form", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 8
              },
              "end": {
                "line": 15,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/components/integrations/salesforce-form.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat", ["settings.integrations.salesforce.", ["get", "option", ["loc", [null, [14, 58], [14, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 14], [14, 65]]], 0, 0]], [], ["loc", [null, [14, 10], [14, 67]]], 0, 0]],
          locals: ["option"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 8
              },
              "end": {
                "line": 21,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/components/integrations/salesforce-form.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "/assets/images/integrations/salesforce-custom-field-screen.jpg");
            dom.setAttribute(el1, "class", "sizer");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createUnsafeMorphAt(dom.childAt(fragment, [5]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["settings.integrations.salesforce.account_field_title"], [], ["loc", [null, [17, 13], [17, 75]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "model.company_integration.custom_account_email", ["loc", [null, [18, 28], [18, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "Email__c"], ["loc", [null, [18, 10], [18, 99]]], 0, 0], ["inline", "t", ["settings.integrations.salesforce.account_warning"], [], ["loc", [null, [19, 13], [19, 71]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 6
            },
            "end": {
              "line": 22,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/components/integrations/salesforce-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element2, 0, 0);
          morphs[1] = dom.createMorphAt(element2, 3, 3);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["settings.integrations.salesforce.email_title0"], [], ["loc", [null, [11, 11], [11, 64]]], 0, 0], ["inline", "t", ["settings.integrations.salesforce.email_title1"], [], ["loc", [null, [12, 8], [12, 61]]], 0, 0], ["block", "button-group", [], ["options", ["subexpr", "array", ["Contact", "Account", "None"], [], ["loc", [null, [13, 32], [13, 66]]], 0, 0], "selected", ["subexpr", "@mut", [["get", "accountObject", ["loc", [null, [13, 76], [13, 89]]], 0, 0, 0, 0]], [], [], 0, 0], "onSelection", ["subexpr", "action", [["subexpr", "mut", [["get", "accountObject", ["loc", [null, [13, 115], [13, 128]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 110], [13, 129]]], 0, 0]], [], ["loc", [null, [13, 102], [13, 130]]], 0, 0]], 0, null, ["loc", [null, [13, 8], [15, 25]]]], ["block", "if", [["subexpr", "eq", [["get", "accountObject", ["loc", [null, [16, 18], [16, 31]]], 0, 0, 0, 0], "Account"], [], ["loc", [null, [16, 14], [16, 42]]], 0, 0]], [], 1, null, ["loc", [null, [16, 8], [21, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 8
              },
              "end": {
                "line": 24,
                "column": 112
              }
            },
            "moduleName": "due-dashboard/templates/components/integrations/salesforce-form.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["settings.integrations.salesforce.subscribe"], [], ["loc", [null, [24, 62], [24, 112]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 6
            },
            "end": {
              "line": 25,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/components/integrations/salesforce-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["settings.integrations.salesforce.prevent_live"], [], ["loc", [null, [23, 11], [23, 64]]], 0, 0], ["block", "link-to", ["settings.subscription"], ["class", "subscribe"], 0, null, ["loc", [null, [24, 8], [24, 124]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 2
              },
              "end": {
                "line": 32,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/integrations/salesforce-form.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createElementMorph(element1);
            morphs[2] = dom.createMorphAt(element1, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [31, 20], [31, 32]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["updateSalesforceIntegration"], [], ["loc", [null, [31, 35], [31, 76]]], 0, 0], ["content", "button.text", ["loc", [null, [31, 77], [31, 92]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 2
              },
              "end": {
                "line": 35,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/integrations/salesforce-form.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [34, 20], [34, 32]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["completeSalesforceIntegration"], [], ["loc", [null, [34, 35], [34, 78]]], 0, 0], ["content", "button.text", ["loc", [null, [34, 79], [34, 94]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 0
            },
            "end": {
              "line": 36,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/integrations/salesforce-form.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "status", ["loc", [null, [30, 12], [30, 18]]], 0, 0, 0, 0], "manage"], [], ["loc", [null, [30, 8], [30, 28]]], 0, 0]], [], 0, null, ["loc", [null, [30, 2], [32, 9]]]], ["block", "if", [["subexpr", "eq", [["get", "status", ["loc", [null, [33, 12], [33, 18]]], 0, 0, 0, 0], "configure"], [], ["loc", [null, [33, 8], [33, 31]]], 0, 0]], [], 1, null, ["loc", [null, [33, 2], [35, 9]]]]],
        locals: ["button"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 15
          }
        },
        "moduleName": "due-dashboard/templates/components/integrations/salesforce-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-block instructions");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element4, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element5, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["settings.integrations.zapier.instructions.title"], [], ["loc", [null, [2, 6], [2, 61]]], 0, 0], ["inline", "t", [["subexpr", "concat", ["settings.integrations.salesforce.sandbox_mode_title.", ["get", "status", ["loc", [null, [5, 76], [5, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 13], [5, 83]]], 0, 0]], [], ["loc", [null, [5, 9], [5, 85]]], 0, 0], ["inline", "due-input", [], ["style", "login-domain", "value", ["subexpr", "@mut", [["get", "model.company_integration.login_domain", ["loc", [null, [6, 45], [6, 83]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "eq", [["get", "status", ["loc", [null, [6, 97], [6, 103]]], 0, 0, 0, 0], "manage"], [], ["loc", [null, [6, 93], [6, 113]]], 0, 0]], ["loc", [null, [6, 6], [6, 115]]], 0, 0], ["block", "if", [["get", "savable", ["loc", [null, [10, 12], [10, 19]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [10, 6], [25, 13]]]], ["block", "due-button", [], ["type", "save", "promise", ["subexpr", "@mut", [["get", "promise", ["loc", [null, [29, 34], [29, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "t", [["subexpr", "concat", ["settings.integrations.salesforce.", ["get", "status", ["loc", [null, [29, 94], [29, 100]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 50], [29, 101]]], 0, 0]], [], ["loc", [null, [29, 47], [29, 102]]], 0, 0], "disabled", ["subexpr", "not", [["subexpr", "and", [["get", "savable", ["loc", [null, [29, 122], [29, 129]]], 0, 0, 0, 0], ["get", "validAccount", ["loc", [null, [29, 130], [29, 142]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 117], [29, 143]]], 0, 0]], [], ["loc", [null, [29, 112], [29, 144]]], 0, 0]], 2, null, ["loc", [null, [29, 0], [36, 15]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});