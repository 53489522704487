define('due-dashboard/components/verbatims/display-option', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',

    actions: {
      toggleDisplay: function toggleDisplay() {
        this.toggleProperty('display.isActive');
        this.get('updateOption')(this.get('display.key'));
      }
    }
  });
});