define('due-dashboard/components/would-recommend-item', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'li',

    localizedSurveyLanguage: computed('i18n.locale', 'survey_language', function () {
      return _emberData['default'].PromiseObject.create({
        promise: this.get('store').query('survey_language', { filter: {
            optimum_locale: this.get('i18n.locale'),
            answered_survey_language: this.get('survey_language.id')
          } })
      });
    })

  });
});