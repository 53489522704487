define('due-dashboard/models/change-end-social-networks-scenario-action', ['exports', 'due-dashboard/models/scenario-action', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioAction, _emberData, _ember, _emberCpValidations) {
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;

  var url_regexp = /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  var Validations = (0, _emberCpValidations.buildValidations)({
    change_end_social_networks_scenario_actions_social_networks: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('has-many')],
    validLink: (0, _emberCpValidations.validator)('number', {
      integer: true,
      is: 0,
      message: 'Empty links are invalid'
    })
  });

  exports['default'] = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    change_end_social_networks_scenario_actions_social_networks: hasMany('change-end-social-networks-scenario-actions-social-network', { async: true }),
    social_networks: computed.alias('change_end_social_networks_scenario_actions_social_networks'),

    validLink: computed('social_networks.[]', function () {
      var _this = this;

      var actions = this.get('social_networks');
      actions.map(function (a) {
        if (a.observersForKey('link').length === 0) {
          a.addObserver('link', function () {
            return _this.notifyPropertyChange('validLink');
          });
        }
      });
      return actions.map(function (a) {
        return url_regexp.test(a.get('link'));
      }).filter(function (a) {
        return a === false;
      }).length;
    }),

    defineSavepoint: function defineSavepoint() {
      var _this2 = this;

      this.get('social_networks').then(function (sn) {
        _this2.set('_savepoint_action_social_networks', sn.toArray());
      });
      return this;
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_action_social_networks');
      if (savepoint) {
        this.set('social_networks', savepoint);
        savepoint.map(function (a) {
          return a.rollbackAttributes();
        });
      }
    }
  });
});