define('due-dashboard/models/slack-company-integration', ['exports', 'due-dashboard/models/company-integration', 'ember-data', 'ember'], function (exports, _dueDashboardModelsCompanyIntegration, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  exports['default'] = _dueDashboardModelsCompanyIntegration['default'].extend({
    name: 'slack',
    slack_channel_id: attr('string'),

    slack_channel: _ember['default'].computed({
      get: function get() {
        var _this = this;

        return this.store.findAll('slack-company-channel').then(function (channels) {
          return channels.findBy('id', _this.get('slack_channel_id'));
        });
      },
      set: function set(_, channel) {
        this.set('slack_channel_id', channel.get('id'));
        return channel;
      }
    })
  });
});