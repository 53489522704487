define('due-dashboard/mixins/help-zone', ['exports', 'ember'], function (exports, _ember) {
  var alias = _ember['default'].computed.alias;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({

    selectedHelp: null,
    singleHelp: alias('selectedHelp.firstObject'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedHelp', []);
    }

  });
});