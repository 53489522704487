define("due-dashboard/components/notification-banner", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  exports["default"] = Component.extend({
    notificationIcons: [{ type: "warning", icon: "warning" }],

    isVisible: _ember["default"].computed('profile', 'notification', function () {
      var isAdmin = this.get('profile') === 'admin';
      return this.get('notification.not_limited_to_admin') || isAdmin;
    }),

    language_tag: _ember["default"].computed('language', function () {
      return this.get('language.tag').toLowerCase();
    }),

    notificationIcon: _ember["default"].computed('notification', 'notificationIcons', function () {
      var notificationIcons = this.get('notificationIcons').findBy("type", this.get('notification.notification_type'));
      if (notificationIcons) {
        return notificationIcons.icon;
      }
    }),

    closeBanner: function closeBanner() {
      this.set('isVisible', false);
    },

    actions: {
      closeBanner: function closeBanner() {
        this.closeBanner();
      }
    }
  });
});