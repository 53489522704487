define("due-dashboard/templates/companies/fonts/new", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 10
              },
              "end": {
                "line": 72,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/companies/fonts/new.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "dropdown-text");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "fallback", ["loc", [null, [71, 40], [71, 52]]], 0, 0, 0, 0]],
          locals: ["fallback"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 4
            },
            "end": {
              "line": 75,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/companies/fonts/new.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "part-container");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "due-typo-default bold");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "part-container");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "due-typo-default bold");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "details-container");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "due-typo-small-g");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "font-upload-container");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "part-container");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "due-typo-default bold");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "details-container");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "due-typo-small-g");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "template-option-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(fragment, [5]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [3, 1]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element2, [3, 1]), 0, 0);
          morphs[7] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["fonts.array.headers.custom_font_name"], [], ["loc", [null, [29, 44], [29, 88]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "newFontName", ["loc", [null, [31, 16], [31, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "text", "onChange", ["subexpr", "action", ["onChangeFontName"], [], ["loc", [null, [33, 19], [33, 46]]], 0, 0], "blockDisplay", true, "error", ["subexpr", "@mut", [["get", "nameError", ["loc", [null, [35, 16], [35, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "errorMsgKey", "settings.company_segment.name_error", "focused", true], ["loc", [null, [30, 8], [38, 10]]], 0, 0], ["inline", "t", ["fonts.new.upload_file"], [], ["loc", [null, [41, 44], [41, 73]]], 0, 0], ["inline", "t", ["fonts.new.upload_file_informations"], [], ["loc", [null, [43, 41], [43, 83]]], 0, 0], ["inline", "due-input", [], ["accept", ".ttf,.otf,.woff", "inputId", "font-upload-btn", "type", "file", "onChange", ["subexpr", "action", ["setUploadedFont"], [], ["loc", [null, [50, 21], [50, 47]]], 0, 0], "blockDisplay", true, "uploadIcon", "upload_outlined", "button", true, "buttonIntent", "secondary"], ["loc", [null, [46, 10], [55, 12]]], 0, 0], ["inline", "t", ["fonts.new.fallback"], [], ["loc", [null, [59, 44], [59, 70]]], 0, 0], ["inline", "t", ["fonts.new.fallback_informations"], [], ["loc", [null, [61, 41], [61, 80]]], 0, 0], ["block", "due-select", [], ["value", ["subexpr", "@mut", [["get", "selectedFallback", ["loc", [null, [65, 18], [65, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "FALLBACKS", ["loc", [null, [66, 20], [66, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["changeFallback"], [], ["loc", [null, [67, 21], [67, 46]]], 0, 0], "containerSelector", "#right-frame"], 0, null, ["loc", [null, [64, 10], [72, 25]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 92,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/companies/fonts/new.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "font-page-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "title-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "navigation-container");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "due-typo-h3");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "new-font-button-container");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "font-card-container");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(element5, 1, 1);
        morphs[2] = dom.createMorphAt(element5, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "t", ["fonts.new.page_title"], [], ["loc", [null, [4, 32], [4, 60]]], 0, 0], ["inline", "due-btn", [], ["icon", "tio-clear", "size", "medium", "intent", "secondary", "textKey", "words.cancel", "action", ["subexpr", "action", ["toggleModalIsOpen", "cancel"], [], ["loc", [null, [12, 15], [12, 52]]], 0, 0]], ["loc", [null, [7, 6], [13, 8]]], 0, 0], ["inline", "due-btn", [], ["icon", "tio-done", "size", "medium", "intent", "primary", "textKey", "words.save", "disabled", ["subexpr", "@mut", [["get", "saveDisabled", ["loc", [null, [19, 17], [19, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", ["save"], [], ["loc", [null, [20, 15], [20, 30]]], 0, 0], "promise", ["subexpr", "@mut", [["get", "savePromise", ["loc", [null, [21, 16], [21, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 6], [22, 8]]], 0, 0], ["block", "component", ["v2/-card"], [], 0, null, ["loc", [null, [27, 4], [75, 18]]]], ["inline", "component", ["v2/-alert"], ["isOpen", ["subexpr", "@mut", [["get", "cancelAlertIsOpen", ["loc", [null, [81, 9], [81, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "validationIntent", "danger", "validationTextKey", "words.discard_changes", "validationCallback", ["subexpr", "action", ["cancel"], [], ["loc", [null, [84, 21], [84, 38]]], 0, 0], "cancelIntent", "secondary", "cancelCallback", ["subexpr", "action", ["toggleModalIsOpen", "cancel"], [], ["loc", [null, [86, 17], [86, 54]]], 0, 0], "cancelTextKey", "words.cancel", "title", ["subexpr", "t", ["words.discard_changes"], [], ["loc", [null, [88, 8], [88, 35]]], 0, 0], "text", ["subexpr", "t", ["fonts.new.cancel_modal_text"], [], ["loc", [null, [89, 7], [89, 40]]], 0, 0], "icon", "danger"], ["loc", [null, [80, 0], [91, 2]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});