define('due-dashboard/models/salesforce-company-integration', ['exports', 'ember', 'ember-data', 'due-dashboard/config/environment', 'due-dashboard/models/company-integration'], function (exports, _ember, _emberData, _dueDashboardConfigEnvironment, _dueDashboardModelsCompanyIntegration) {
  var computed = _ember['default'].computed;
  var attr = _emberData['default'].attr;
  exports['default'] = _dueDashboardModelsCompanyIntegration['default'].extend({
    name: 'salesforce',
    sandbox_mode: attr('boolean', { defaultValue: true }),
    custom_account_email: attr('string'),
    login_domain: attr('string', { defaultValue: 'https://login.salesforce.com' }),
    no_object: attr('boolean', { defaultValue: false }),

    oauth_site: computed.alias('login_domain'),

    oauth_url: computed('oauth_site', function () {
      return this.get('oauth_site') + _dueDashboardConfigEnvironment['default'].EmberENV.SALESFORCE_AUTHORIZE_PATH + '&state=' + this.get('id');
    })
  });
});