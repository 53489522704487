define('due-dashboard/components/zendesk/trigger-conditions', ['exports', 'ember', 'due-dashboard/utils/zendesk/client-api'], function (exports, _ember, _dueDashboardUtilsZendeskClientApi) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({

    classNames: ['zendesk-trigger-conditions'],

    conditions: [],

    conditions_dropdown: computed('i18n.locale', function () {
      return this.get('conditions_ref').mapBy('name');
    }),

    fetch_zendesk_data: function fetch_zendesk_data(resource, mapping) {
      var _this = this;

      var params = arguments.length <= 2 || arguments[2] === undefined ? "" : arguments[2];
      var zendesk_client = arguments.length <= 3 || arguments[3] === undefined ? this.get('ZendeskClient') : arguments[3];

      var ret = null;
      return zendesk_client.get(resource + '.json' + params).then(function (res) {
        ret = mapping(res);
        if (res.next_page) {
          return _this.fetch_zendesk_data(resource, mapping, res.next_page.split("json")[1], zendesk_client).then(function (value) {
            ret.addObjects(value);
            return ret;
          });
        } else {
          return ret;
        }
      });
    },

    get_group_id_values: function get_group_id_values() {
      var ret = [{ name: "-" }];
      return this.fetch_zendesk_data('groups', function (res) {
        return res.groups;
      }).then(function (res) {
        return ret.addObjects(res);
      });
      // "" (no group assigned to the ticket) or the numeric ID of the group assigned to the ticket.
    },

    get_assignee_id_values: function get_assignee_id_values() {
      var ret = [{ name: '-' }, { name: 'requester_id', id: 'requester_id' }, { name: 'current_user', id: 'current_user' }];
      return this.fetch_zendesk_data('users', function (res) {
        return res.users;
      }, "?role[]=admin&role[]=agent&verified=true").then(function (res) {
        return ret.addObjects(res);
      });
      //"" (nobody assigned to the ticket), current_user, all_agents, or the numeric ID of the agent assigned to the ticket.
    },

    get_requester_id_values: function get_requester_id_values() {
      var ret = [{ name: '-' }, { name: 'assignee_id', id: 'assignee_id' }, { name: 'current_user', id: 'current_user' }];
      return this.fetch_zendesk_data('users', function (res) {
        return res.users;
      }, "?role[]=admin&role[]=agent&verified=true").then(function (res) {
        return ret.addObjects(res);
      });
      //"" (no requester specified), current_user or the numeric ID of the requester or assignee.
    },

    get_organization_id_values: function get_organization_id_values() {
      var ret = [{ name: '-' }];
      return this.fetch_zendesk_data('organizations', function (res) {
        return res.organizations;
      }).then(function (res) {
        return ret.addObjects(res);
      });
      // ft //"" (no organization added to the ticket) or the numeric ID of the organization added to the ticket.
    },

    get_recipient_values: function get_recipient_values() {
      var ret = [];
      return this.fetch_zendesk_data('recipient_addresses', function (res) {
        return res.recipient_addresses;
      }).then(function (res) {
        return ret.addObjects(res.map(function (one) {
          return { email: one.email, id: one.email };
        }));
      });
    },

    get_brand_id_values: function get_brand_id_values() {
      var ret = [];
      return this.fetch_zendesk_data('brands', function (res) {
        return res.brands;
      }).then(function (res) {
        return ret.addObjects(res);
      });
    },

    get_ticket_form_id_values: function get_ticket_form_id_values() {
      return this.get('ZendeskClient').get('ticket_forms.json').then(function (res) {
        return res.ticket_forms;
      });
    },

    get_locale_id_values: function get_locale_id_values() {
      //The numeric ID of the locale of the person who submitted the ticket. See List locales to list the available locale IDs for the account.
      return this.get('ZendeskClient').get('locales.json').then(function (res) {
        return res.locales;
      });
    },

    get_role_values: function get_role_values() {
      var ret = [{ name: 'agent' }, { name: 'end_user' }];
      return this.fetch_zendesk_data('users', function (res) {
        return res.users;
      }, "?role[]=admin&role[]=agent&verified=true").then(function (res) {
        return ret.addObjects(res);
      });
    },

    get_requester_role_values: function get_requester_role_values() {
      return this.get('ZendeskClient').get('custom_roles.json').then(function () {
        return [{
          name: 'agent',
          id: 4
        }, {
          name: 'end_user',
          id: '0'
        }, {
          name: 'admin',
          id: 2
        }];
      });
    },

    didInsertElement: function didInsertElement() {
      this.init_conditions_ref();
      this.load_remote_conditions();
      this._super.apply(this, arguments);
    },

    _set_values: function _set_values(condition, values) {
      condition.value = values.find(function (c) {
        return (c['id'] || c.name || c[condition.key] || c).toString() === condition.value.toString();
      }) || condition.value;
    },

    load_remote_conditions: function load_remote_conditions() {
      var _this2 = this;

      this.get('conditions_ref').forEach(function (condition) {
        if (condition.values) {
          if (condition.values.then) {
            condition.values.then(function (res) {
              condition.set('values', res);
            });
          } else {
            condition.set('values', condition.values);
          }
        }
      });
      var conditions_ref = this.get('conditions_ref');
      this.set('conditions', this.getWithDefault('conditions', []));
      this.get('input_conditions').forEach(function (condition) {
        var condition_ref = conditions_ref.findBy('name', condition.field);
        condition.type = condition_ref;
        var values = condition.type.get('values');
        if (values) {
          if (values.then) {
            values.then(function (res) {
              condition.type.set('values', res);
              _this2._set_values(condition, res);
            });
          } else {
            condition.type.set('values', values);
            _this2._set_values(condition, values);
          }
        }
        _this2.get('conditions').addObject(condition);
      });
    },

    init_conditions_ref: function init_conditions_ref() {
      var _this3 = this;

      var conditions_ref = [];

      _dueDashboardUtilsZendeskClientApi['default'].CONDITIONS_REFS.forEach(function (condition) {
        var obj = new _ember['default'].Object(condition);
        if (!(obj.values || obj.is_number || obj.is_string || obj.multi_input)) {
          obj.values = _this3['get_' + obj.name + '_values']();
          obj.client = _this3.get('ZendeskClient');
        }
        conditions_ref.addObject(obj);
      });
      this.set('conditions_ref', conditions_ref);
    },

    actions: {
      newCondition: function newCondition() {
        this.get('conditions').addObject({});
      },

      removeCondition: function removeCondition(condition) {
        this.get('conditions').removeObject(condition);
      },

      changeType: function changeType(condition, type) {
        set(condition, 'type', type);
        set(condition, 'operator', undefined);
        set(condition, 'value', undefined);
      },

      changeOperator: function changeOperator(condition, operator) {
        set(condition, 'operator', operator);
        set(condition, 'value', undefined);
      }
    }
  });
});