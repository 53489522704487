define('due-dashboard/routes/settings/integrations/stripe', ['exports', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({

    includeSurveys: 'post-charge-survey',

    actions: {

      completeStripeIntegration: function completeStripeIntegration(href) {
        this.currentModel.company_integration.set('completed', false);
        this.controller.set('promise', this.currentModel.company_integration.save().then(function () {
          window.location.replace(href);
        }));
      }

    }

  });
});