define('due-dashboard/objects/reports/data-converter/tag-table-converter', ['exports', 'due-dashboard/objects/reports/data-converter/table-converter'], function (exports, _dueDashboardObjectsReportsDataConverterTableConverter) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _dueDashboardObjectsReportsDataConverterTableConverter['default'].extend({

    getHeaders: function getHeaders(tableParams, selectedViewBy, currentLanguage) {
      var headerTxt = tableParams.translations[currentLanguage].headers;
      var headerTooltip = tableParams.translations[currentLanguage].header_tooltip;
      if (tableParams.view_by) {
        headerTxt.splice(0, 1, selectedViewBy);
      }
      headerTxt = headerTxt.map(function (txt) {
        return txt.length > 70 ? txt.slice(0, 70) + '...' : txt;
      });

      var headers = [];
      var mainColumnNb = headerTxt.length - tableParams.properties.length;
      if (mainColumnNb < 1 || mainColumnNb == undefined) {
        return [];
      }
      headerTxt.forEach(function (txt, index) {
        var header = undefined;
        if (index < mainColumnNb) {
          header = {
            type: 'label-with-sub',
            label: txt,
            main_column: true
          };
        } else {
          header = {
            type: tableParams.properties[index - mainColumnNb].type,
            label: txt,
            main_column: false
          };
        }
        if (headerTooltip != null && headerTooltip[txt]) {
          header.tooltip = headerTooltip[txt];
        }
        headers.push(header);
      });
      return headers;
    },

    getRows: function getRows(data, tableParams, currentLanguage, i18n) {
      var rows = [];
      var content = this._getTagsContent(data, tableParams, currentLanguage, i18n);
      content.forEach(function (line, index) {
        return rows.push({ data: line });
      });
      return rows;
    },

    _formatBreakdown: function _formatBreakdown(elem, properties, tableParams, currentLanguage, title) {
      var value = _extends({}, elem.value);

      if (value.breakdown) {
        value.distribution = value.breakdown;
      }

      var distribution = value.distribution;
      var computedBreakdown = {};
      var total = 0;

      if (!distribution) {
        value.distribution = [];
        return _extends({}, elem, { value: value });
      }

      if (distribution['_total_multi'] !== undefined) {
        total = distribution['_total_multi'];
        delete distribution['_total_multi'];
      } else {
        Object.values(distribution).forEach(function (sectionTotal) {
          total += sectionTotal;
        });
      }
      value.total = total;

      if (total == 0) {
        value.distribution = [];
        return _extends({}, elem, { value: value });
      }

      computedBreakdown = Object.keys(distribution).map(function (sectionKey, idx) {
        var sectionTotal = distribution[sectionKey];
        var sectionPercentage = sectionTotal / total * 100;
        var color = '';
        var title = '';

        if (properties.kpi && properties.kpi.includes('sentiment')) {
          title = tableParams.translations[currentLanguage].sentiments[sectionKey].title;
        } else if (properties.kpi && properties.kpi.includes('breakdown')) {
          title = sectionKey;
        } else if (properties.kpi && !properties.kpi.includes('sentiment') && properties.segment_id) {
          title = tableParams.translations[currentLanguage]['distribution_' + properties.segment_id][idx].title;
        } else {
          title = tableParams.translations[currentLanguage].distribution[idx].title;
        }

        var color_e = null;
        if (tableParams.view_type !== 'segments' && properties.kpi && properties.kpi.includes('sentiment')) {
          color_e = properties.distribution[sectionKey];
        } else if (properties.segment_id && tableParams['distribution_' + properties.segment_id]) {
          color_e = tableParams['distribution_' + properties.segment_id][idx % tableParams['distribution_' + properties.segment_id].length];
        } else {
          color_e = properties.distribution[idx % properties.distribution.length];
        }
        color = color_e ? color_e.color : '#e6e6e6'; // default color for missing distribution entries

        return {
          color: color,
          percentage: Math.round(sectionPercentage),
          style: 'width: ' + sectionPercentage + '%; background-color: ' + color + ';',
          title: title,
          total: sectionTotal || 0
        };
      });
      value.distribution = computedBreakdown;
      value.title = title;
      return _extends({}, elem, { value: value });
    },

    _getTagsContent: function _getTagsContent(content, tableParams, currentLanguage, i18n) {
      var _this = this;

      var serializedTags = [];
      // Each line, fill tags name column (config)
      content.forEach(function (line, lineIdx) {
        var idxColumn = { type: 'index', main_column: true, value: lineIdx };

        serializedTags.push([]);
        serializedTags[lineIdx].push(idxColumn);
        line.forEach(function (elem, idx) {
          var properties = tableParams.properties[idx - 1];

          if (idx === 0) {
            serializedTags[lineIdx].push(_extends({}, elem, { main_column: true }));
          } else {
            if (elem.type == 'breakdown') {
              var title = tableParams.translations[currentLanguage].headers[idx];
              serializedTags[lineIdx].push(_this._formatBreakdown(elem, properties, tableParams, currentLanguage, title));
            } else if (elem.type == 'number-trend' || elem.type.startsWith('variation-percent')) {
              serializedTags[lineIdx].push(_this._formatNumberTrend(elem, properties, tableParams, i18n));
            } else if (['positive-impact', 'negative-impact'].includes(elem.type)) {
              serializedTags[lineIdx].push(_extends({}, elem, { positiveImpactHideValue: tableParams.positiveImpactHideValue, negativeImpactHideValue: tableParams.negativeImpactHideValue }));
            } else if (['positive-impact-level', 'negative-impact-level'].includes(elem.type)) {
              serializedTags[lineIdx].push(_extends({}, elem, { lowThreshold: tableParams.lowThreshold, moderateThreshold: tableParams.moderateThreshold, strongThreshold: tableParams.strongThreshold }));
            } else {
              serializedTags[lineIdx].push(_extends({}, elem, { kpi: properties.kpi, attrs: properties.attrs }));
            }
          }
        });
      });
      return serializedTags;
    },

    formatData: function formatData(params) {
      var tableParams = params.tableParams;
      var data = params.data;
      var selectedViewBy = params.selectedViewBy;
      var currentLanguage = params.currentLanguage;
      var i18n = params.i18n;

      return {
        headers: this.getHeaders(tableParams, selectedViewBy, currentLanguage || 'EN'),
        rows: this.getRows(data, tableParams, currentLanguage || 'EN', i18n),
        navigation_button: tableParams.component.config.navigation_button
      };
    }
  });
});