define('due-dashboard/components/delivery-rate-chart', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['delivery-rate-chart'],

    colors: {
      delivered: _dueDashboardConfigEnvironment['default'].EmberENV.colors[3],
      scheduled: _dueDashboardConfigEnvironment['default'].EmberENV.colors[4],
      antispamming: _dueDashboardConfigEnvironment['default'].EmberENV.colors[1],
      bounce: _dueDashboardConfigEnvironment['default'].EmberENV.colors[0],
      error: _dueDashboardConfigEnvironment['default'].EmberENV.colors[0],
      injection: '#adadad'
    },

    defaultSelectedKey: 'delivered'
  });
});