define('due-dashboard/controllers/settings/digests/translations', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    digest: computed.alias('model.digest'),
    languages: computed.alias('model.languages'),

    remainingLanguages: computed.setDiff('languages', 'selectedLanguages'),
    showAddLanguage: computed.notEmpty('remainingLanguages'),

    tags: computed('digest.draft_translations', function () {
      return Object.keys(this.get('digest.draft_translations'));
    }),

    currentTag: computed('digest.draft_language', function () {
      return this.get('digest.draft_language.tag').toLowerCase();
    }),

    realLanguages: computed('languages', function () {
      return this.get('languages').content;
    }),

    lowerCurrentTag: computed('currentTag', function () {
      return this.get('currentTag').toUpperCase();
    }),

    selectedLanguages: computed('digest.draft_translations', 'languages', function () {
      var selectedLanguages = Object.keys(this.get('digest.draft_translations'));
      var languages = this.get('languages');
      return languages.filter(function (a) {
        return selectedLanguages.includes(a.get('tag').toLowerCase());
      });
    }),

    actions: {

      cancelChanges: function cancelChanges() {
        this.transitionToRoute('settings.digests.edit', this.get('digest.id'));
      },

      saveChanges: function saveChanges() {
        var _this = this;

        this.set('digest.unpublished_change', true);
        this.set('save_promise', this.get('digest').save().then(function () {
          return _this.transitionToRoute('settings.digests.edit', _this.get('digest.id'));
        }));
      },

      removeLanguage: function removeLanguage(language) {
        var translations = this.get('digest.draft_translations');
        var tag = language.get('tag').toLowerCase();
        delete translations[tag];
        this.set('digest.draft_translations', translations);
        this.notifyPropertyChange('digest.draft_translations');
      },

      addLanguage: function addLanguage(language) {
        var translations = this.get('digest.draft_translations');
        var tag = language.get('tag').toLowerCase();
        if (!translations[tag]) {
          translations[tag] = JSON.parse(JSON.stringify(translations[this.get('currentTag').toLowerCase()]));
          this.set('digest.draft_translations', translations);
          this.notifyPropertyChange('digest.draft_translations');
        }
      }
    }
  });
});