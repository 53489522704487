define('due-dashboard/routes/companies/graphs/edit', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Route.extend({
    /*deactivate: function() {
       const graph = this.controller.get('model');
        if (graph.get('isnew')) {
         graph.destroyRecord();
       } else {
         graph.rollbackAttributes();
       }
     },*/

    graph: function graph(graph_id) {
      var _this = this;

      if (graph_id === 'new') {
        return new RSVP.Promise(function (resolve) {
          resolve(_this.store.createRecord('graph'));
        });
      } else {
        return this.store.findRecord('graph', graph_id, { reload: true });
      }
    },

    model: function model(_ref) {
      var graph_id = _ref.graph_id;

      return RSVP.hash({
        graph: this.graph(graph_id)
      });
    },

    actions: {
      willTransition: function willTransition() {
        if (this.currentModel.graph.isNew) {
          this.currentModel.graph.unloadRecord();
        } else if (this.currentModel.graph.hasDirtyAttributes) {
          this.currentModel.graph.rollbackAttributes();
        }
      }
    }

  });
});