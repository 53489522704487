define('due-dashboard/components/survey-language-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    init: function init() {
      this._super.apply(this, arguments);
      if (!Object.keys(this.get('deletedLanguages')).length) this.get('reorderQuestions')(this.get('questionsFromLanguage'));
    },

    currentAccount: _ember['default'].inject.service(),
    questionsPosition: ['position'],

    sortedQuestions: computed.sort('questionsFromLanguage', 'questionsPosition'),

    language: computed('questions', function () {
      var questions = this.get('questions');
      return questions[this.get('survey_language')];
    }),

    questionsFromLanguage: computed('language', function () {
      var language = this.get('language');
      return language.closed;
    }),

    actions: {

      switchQuestion: function switchQuestion(question, direction) {
        var reorderedQuestions = this.get('sortedQuestions').toArray();
        var q_position = reorderedQuestions.indexOf(question);
        if (q_position + direction < 0 || q_position + direction === reorderedQuestions.length) {
          return;
        }
        var tmp = reorderedQuestions[q_position];
        reorderedQuestions[q_position] = reorderedQuestions[q_position + direction];
        reorderedQuestions[q_position + direction] = tmp;
      }
    }
  });
});