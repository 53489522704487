define('due-dashboard/components/digests/segment-breakdown', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var A = _ember['default'].A;
  exports['default'] = Component.extend({

    includeOptions: ['excluded', 'included'],
    tagOrderOptions: ['volume', 'tag_volume', 'score', 'positive_volume', 'negative_volume'],
    respondantOptions: ['volume', 'percent'],
    useDropdownMultilevel: true,
    valueAttribute: 'key',

    init: function init() {
      if (!this.get('comp.options.hide_sentiment')) {
        this.set('comp.options.hide_sentiment', []);
      }
      this._super.apply(this, arguments);
    },

    orderOptions: computed(function () {
      return [{ key: 'score', name: this.translateValue('score') }, { key: 'change', name: this.translateValue('change') }, { key: 'count', name: this.translateValue('count') }, { key: 'volume_of_tags', name: this.translateValue('volume_of_tags') }, {
        key: 'sentiment', name: this.translateValue('sentiment'),
        options: [{ key: 'positive_volume', name: this.translateValue('positive_volume') }, { key: 'neutral_volume', name: this.translateValue('neutral_volume') }, { key: 'negative_volume', name: this.translateValue('negative_volume') }]
      }];
    }),

    sentimentOptions: A([{ name: 'positive', value: 'positive', icon: 'thumb_up_outlined', color: '#00B548' }, { name: 'neutral', value: 'neutral', icon: 'thumb_left_outlined', color: '#FAB005' }, { name: 'negative', value: 'negative', icon: 'thumb_down_outlined', color: '#FA5252' }]),

    sentimentHider: computed('comp.options.hide_sentiment', {
      get: function get() {
        var _this = this;

        return this.get('sentimentOptions').filter(function (sentiment) {
          return _this.get('comp.options.hide_sentiment').includes(sentiment.value);
        });
      }, set: function set(_, filter) {
        this.set('comp.options.hide_sentiment', filter.mapBy('value'));
        this.get('saveDigestDelay')();
        return filter;
      }
    }),

    categoryOptions: computed(function () {
      return ["All", 'Main', 'Sub', 'Sub-sub', 'Sub-sub-sub'];
    }),

    selectedRespondantOption: computed('respondantOptions', 'comp.options', 'comp.options.respondant_mode', function () {
      return this.get('comp.options.respondant_mode') || this.get('respondantOptions')[0];
    }),

    selectedIncludeOption: computed('includeOptions', 'comp.options', 'comp.options.tag_include', function () {
      return this.get('comp.options.tag_include') || this.get('includeOptions')[0];
    }),

    feelingTagOption: computed('includeOptions', 'comp.options', 'comp.options.feeling_tag_include', function () {
      return this.get('comp.options.feeling_tag_include') || this.get('includeOptions')[0];
    }),

    selectedTagOrderOption: computed('tagOrderOptions', 'comp.options', 'comp.options.tag_order_mode', function () {
      return this.get('comp.options.tag_order_mode') || this.get('tagOrderOptions')[0];
    }),

    selectedFeelingOption: computed('respondantOptions', 'comp.options', 'comp.options.feeling_mode', function () {
      return this.get('comp.options.feeling_mode') ? this.get('comp.options.feeling_mode') : this.get('respondantOptions')[0];
    }),

    currentSegmentTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.segment_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.segment_column_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    feelingTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.feelings_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.feelings_name', value);
        return value;
      }
    }),

    currentScoreTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.score_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.score_column_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    currentChangeTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.change_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.change_column_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    currentCounterTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.counter_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.counter_column_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    respondantsTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.respondants_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.respondants_name', value);
        return value;
      }
    }),

    tagThresholdLabel: computed('comp.options.tag_order_mode', function () {
      var tradTagOrderOpt = this.get('i18n').t('digest.component.tag_order_' + this.get('comp.options.tag_order_mode'));
      return this.get('i18n').t('digest.component.threshold_only') + ' (' + tradTagOrderOpt + ')';
    }),

    translateValue: function translateValue(option) {
      return String(this.get('i18n').t('digest.component.order_by_' + option));;
    },

    actions: {
      changeInclude: function changeInclude(opt) {
        this.set('comp.options.tag_include', opt);
        this.get('saveDigestDelay')();
      },

      changeIncludeSentiment: function changeIncludeSentiment(opt) {
        this.set('comp.options.feeling_tag_include', opt);
        this.get('saveDigestDelay')();
      },

      changeOrder: function changeOrder(opt) {
        this.set('comp.options.order_mode', opt);
        this.get('saveDigestDelay')();
      },

      changeTagOrder: function changeTagOrder(opt) {
        this.set('comp.options.tag_order_mode', opt);
        this.get('saveDigestDelay')();
      },

      changeFeeling: function changeFeeling(opt) {
        this.set('comp.options.feeling_mode', opt);
        this.get('saveDigestDelay')();
      },

      changeRespondant: function changeRespondant(opt) {
        this.set('comp.options.respondant_mode', opt);
        this.get('saveDigestDelay')();
      }
    }
  });
});