define('due-dashboard/components/digests/parts/dl-report-part', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    saveDigestDelay: null,
    digestComponent: null,
    queries: [],

    // private:

    _selectedQuery: computed('queries.[]', 'digestComponent.options.exportable_report_query_id', function () {
      var _this = this;

      return this.get('queries').find(function (q) {
        return q.get('id') == _this.get('digestComponent.options.exportable_report_query_id');
      });
    }),
    _candidateQueries: computed.setDiff('queries', '_selectedQuery'),

    actions: {
      selectQuery: function selectQuery(query) {
        this.set('digestComponent.options.exportable_report_query_id', query.get('id'));
        this.get('on-save')();
      }
    }

  });
});