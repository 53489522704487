define('due-dashboard/components/match-question-and-ratings-scenario-condition', ['exports', 'ember', 'due-dashboard/components/scenario-condition-item', 'due-dashboard/utils/group-by'], function (exports, _ember, _dueDashboardComponentsScenarioConditionItem, _dueDashboardUtilsGroupBy) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var later = _ember['default'].run.later;
  exports['default'] = _dueDashboardComponentsScenarioConditionItem['default'].extend({
    questions: [],
    ratings: [],
    reloadDp: false,
    options: [],
    selectedQuestionRatings: computed.alias('condition.question.survey_language.survey_revision.rating_scale'),
    remainingQuestionRatings: computed.setDiff('selectedQuestionRatings', 'condition.ratings'),

    uniqBy: function uniqBy(list, dependentKey) {
      var uniq = [];
      var seen = {};
      list.forEach(function (item) {
        var guid = item.get(dependentKey);
        if (!(guid in seen)) {
          seen[guid] = true;
          uniq.push(item);
        }
      });
      return uniq;
    },

    loadQuestions: (function () {
      var _this = this;

      this.set('options', this.get('store').query('question', { include: 'survey', filter: { uniq_by_like: this.get('i18n.locale') } }).then(function (qs) {
        _this.set('_questions', qs.toArray());
        _this.set('options', computed.oneWay('questionsForNavigable'));
        _this.questionIsLoaded();
      }));
    }).on('init'),

    questionIsLoaded: observer('condition.isLoaded', function () {
      var _this2 = this;

      if (!this.get('condition.isLoaded')) {
        return;
      }
      this.get('condition.question').then(function () {
        if (!_this2.set('selectedQuestion') && _this2.get('condition.question.survey.id') && !_this2.isDestroyed) {
          _this2.set('selectedQuestion', _this2.get('options').findBy('id', _this2.get('condition.question.survey.id')).options.findBy('question.like', _this2.get('condition.question.like')));
        }
      });
    }),

    _serializeOneQuestionForDropDown: function _serializeOneQuestionForDropDown(question) {
      return {
        question: question,
        name: get(question, 'text'),
        text: get(question, 'text'),
        title: get(question, 'text'),
        position: get(question, 'position')
      };
    },

    localeObserver: observer('i18n.locale', function () {
      this.loadQuestions();
    }),

    questionsForNavigable: computed('_questions.[]', function () {
      var _this3 = this;

      var questions = this.get('_questions');
      if (!questions || !questions.length) {
        return [];
      }
      var questions_gb_survey = (0, _dueDashboardUtilsGroupBy['default'])(questions, 'survey.id');
      later(function () {
        return _this3.toggleProperty('reloadDp');
      });
      return questions_gb_survey.map(function (q_group) {
        var survey = _this3.get('store').peekRecord('survey', q_group.group);
        return {
          groupName: { title: _this3.get('i18n').t('feedback_list.head.titles.questions_of_survey') + ' : ' + survey.get('name') },
          title: get(survey, 'name'),
          name: get(survey, 'name'),
          id: get(survey, 'id'),
          options: q_group.content.map(function (q) {
            return _this3._serializeOneQuestionForDropDown(q);
          })
        };
      });
    }),

    questionRatingsObserver: on('init', observer('selectedQuestion.question.id', '_questions.[]', function () {
      var _this4 = this;

      var question = this.get('selectedQuestion.question');
      if (question && this.get('_questions')) {
        this.get('store').query('rating', { filter: { question_like: question.get('id') } }).then(function (ratings) {
          _this4.set('ratings', ratings.toArray().sortBy('score'));
        });
      }
    })),

    actions: {

      selectQuestion: function selectQuestion(question) {
        this.set('selectedQuestion', question);
        if (this.get('condition.question')) {
          this.get('condition').updateRatings([]);
        }
        this.set('condition.question', question.question);
      },

      selectRatings: function selectRatings(ratings) {
        this.get('condition').updateRatings(ratings);
      }
    }
  });
});