define('due-dashboard/components/dashboard-filters/profiles', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var A = _ember['default'].A;
  exports['default'] = Component.extend({

    profileOptions: A([{ name: 'detractor', icon: '/assets/images/icons/detractor.png' }, { name: 'passive', icon: '/assets/images/icons/passive.png' }, { name: 'promoter', icon: '/assets/images/icons/promoter.png' }]),

    selectedOptions: computed.map('filter.value', function (name) {
      return this.get('profileOptions').findBy('name', name);
    }).readOnly(),

    actions: {
      optionSelected: function optionSelected(option) {
        var value_copy = [].concat(_toConsumableArray(this.get('filter.value'))),
            name = option.name;

        if (value_copy.contains(name)) {
          value_copy.removeObject(name);
        } else {
          value_copy.pushObject(name);
        }
        this.set('filter.value', value_copy);
      },

      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});