define('due-dashboard/models/change-end-trustpilot-scenario-action', ['exports', 'due-dashboard/models/scenario-action', 'ember-data', 'ember-cp-validations', 'ember'], function (exports, _dueDashboardModelsScenarioAction, _emberData, _emberCpValidations, _ember) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    segment_for_name: (0, _emberCpValidations.validator)('presence', true),
    segment_for_ref: (0, _emberCpValidations.validator)('presence', true),
    segment_for_sku: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: computed.not('requiredForProduct')
    }),
    open_question: (0, _emberCpValidations.validator)('length', {
      min: 1,
      disabled: computed.not('requiredForProduct')
    }),
    change_end_trustpilot_scenario_actions_languages: (0, _emberCpValidations.validator)('presence', true),
    validLinkText: (0, _emberCpValidations.validator)('number', {
      integer: true,
      is: 0,
      message: 'Empty messages are invalid'
    })
  });

  exports['default'] = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    change_end_trustpilot_scenario_actions_languages: hasMany('change-end-trustpilot-scenario-actions-language', { async: true }),
    actions_languages: computed.alias('change_end_trustpilot_scenario_actions_languages'),
    segment_for_name: belongsTo('Segment', { async: true }),
    segment_for_ref: belongsTo('Segment', { async: true }),
    segment_for_sku: belongsTo('Segment', { async: true }),
    segment_for_location: belongsTo('Segment', { async: true }),

    separator: attr('string', { defaultValue: ',' }),
    button_color: attr('string', { defaultValue: '#51B27F' }),
    review_type: attr('string', { defaultValue: 'Service' }),

    requiredForProduct: computed('review_type', function () {
      this.get('review_type') == 'Product' ? true : false;
    }),

    validLinkText: computed('change_end_trustpilot_scenario_actions_languages.[]', function () {
      var _this = this;

      var actions = this.get('change_end_trustpilot_scenario_actions_languages');
      actions.map(function (a) {
        if (a.observersForKey('text').length === 0) {
          a.addObserver('text', function () {
            return _this.notifyPropertyChange('validLinkText');
          });
        }
      });
      return actions.map(function (a) {
        return a.get('text') === undefined || a.get('text') === '';
      }).filter(function (a) {
        return a === true;
      }).length;
    }),

    defineSavepoint: function defineSavepoint() {
      var _this2 = this;

      this.get('actions_languages').then(function (al) {
        _this2.set('_savepoint_actions_languages', al.toArray());
      });
      return this;
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_actions_languages');
      if (savepoint) {
        this.set('change_end_trustpilot_scenario_actions_languages', savepoint);
        savepoint.map(function (a) {
          return a.rollbackAttributes();
        });
      }
    }
  });
});