define('due-dashboard/components/scenario-action-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    errors: service(),

    action: null,
    has_many_language: false,

    error_key: computed('action.position', 'action.type', function () {
      return this.get('action.type') + '-' + this.get('action.position');
    }),

    // default pp, need to be overwrite most of the time
    error_keys: computed(function () {
      return [this.get('error_key')];
    }),

    goToTab: observer('errors.updateHook', function () {
      var _this = this;

      if (this.get('has_many_language')) {
        var error_language_tag = this.get('errors.errors').filter(function (a) {
          return !!a.key.match(_this.get('language_tab_error_regexp'));
        })[0];
        if (error_language_tag) {
          var to_select = this.get('orderedActionLanguages').findBy('language.tag', error_language_tag.meta);
          if (to_select) {
            this.set('selectedLanguage', to_select);
          }
        }
      }
    }),

    errorObserver: observer('try_step', function () {
      var _this2 = this;

      var _get = this.get('errorObserverVars');

      var single_error_key = _get.single_error_key;
      var single_error_target = _get.single_error_target;
      var error_prefix = _get.error_prefix;
      var error_suffix = _get.error_suffix;
      var error_target = _get.error_target;
      var array_key = _get.array_key;

      if (single_error_key && single_error_target) {
        this.get('errors').updateErrorByKey(single_error_key, this.get(single_error_target));
      }
      if (array_key) {
        this.get(array_key).map(function (elem) {
          elem.validate().then(function () {
            return _this2.get('errors').updateErrorByKey(error_prefix + '-' + elem.get(error_suffix), elem.get(error_target), elem.get(error_suffix));
          });
        });
      }
    }),

    willDestroy: function willDestroy() {
      var errors = this.get('errors');
      this.get('error_keys').map(function (key) {
        return errors.destroyErrorByKey(key);
      });
    }

  });
});