define("due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/footer-design", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 2
            },
            "end": {
              "line": 18,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/footer-design.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "dropdown-text");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", [["subexpr", "concat", ["words.", ["get", "logoColor.value", ["loc", [null, [17, 53], [17, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 36], [17, 69]]], 0, 0]], [], ["loc", [null, [17, 32], [17, 71]]], 0, 0]],
        locals: ["logoColor"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/footer-design.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "element-header-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "design-element-title-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "due-typo-h5 bold");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "setting-element-container margin-top-24");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "due-typo-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "setting-element-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "due-typo-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "setting-element-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "due-typo-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3]);
        var element1 = dom.childAt(fragment, [5]);
        var element2 = dom.childAt(fragment, [7]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(element1, 3, 3);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[6] = dom.createMorphAt(element2, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", [["subexpr", "concat", ["words.", ["get", "currentElement.name", ["loc", [null, [4, 56], [4, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 39], [4, 76]]], 0, 0]], [], ["loc", [null, [4, 35], [4, 78]]], 0, 0], ["inline", "t", ["words.logo_color"], [], ["loc", [null, [8, 33], [8, 57]]], 0, 0], ["block", "due-select", [], ["value", ["subexpr", "@mut", [["get", "currentDesignOptions.logoColor", ["loc", [null, [10, 10], [10, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "LOGO_COLORS", ["loc", [null, [11, 12], [11, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["selectDropdownValue"], [], ["loc", [null, [12, 13], [12, 43]]], 0, 0], "containerSelector", ".secondary-panel", "rightAlign", true], 0, null, ["loc", [null, [9, 2], [18, 17]]]], ["inline", "t", ["words.text_color"], [], ["loc", [null, [21, 33], [21, 57]]], 0, 0], ["inline", "due-input", [], ["type", "color", "placeholder", "", "value", ["subexpr", "@mut", [["get", "currentDesignOptions.textColor", ["loc", [null, [25, 10], [25, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValue", "textColor", "footer_text_color"], [], ["loc", [null, [26, 13], [26, 64]]], 0, 0]], ["loc", [null, [22, 2], [27, 4]]], 0, 0], ["inline", "t", ["words.language_selector_background"], [], ["loc", [null, [30, 33], [30, 75]]], 0, 0], ["inline", "due-input", [], ["type", "color", "placeholder", "", "value", ["subexpr", "@mut", [["get", "currentDesignOptions.languageSelectorBgColor", ["loc", [null, [34, 10], [34, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValue", "languageSelectorBgColor", "footer_language_selector_bg_color"], [], ["loc", [null, [35, 13], [35, 94]]], 0, 0]], ["loc", [null, [31, 2], [36, 4]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});