define('due-dashboard/controllers/settings/integrations/trustpilot', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({
    submitEnabled: computed.notEmpty('model.company_integration.company_zendesk_url'),
    hideSave: true,

    cannotConnect: computed('model.company_integration.domain', function () {
      if (this.get("model.company_integration.domain")) {
        return this.get("model.company_integration.domain").length > 3 ? false : true;
      }
      return true;
    }),

    actions: {

      connect: function connect() {
        var domain = btoa(this.get("model.company_integration.domain"));
        var id = this.get('model.company.id');
        window.location.replace(_dueDashboardConfigEnvironment['default'].EmberENV.TRUSTPILOT_AUTH_URL + '?client_id=' + _dueDashboardConfigEnvironment['default'].EmberENV.TRUSTPILOT_API_KEY + '&redirect_uri=' + _dueDashboardConfigEnvironment['default'].EmberENV.TRUSTPILOT_REDIRECT_URI + '/' + domain + '/' + id + '&response_type=code');
      },

      saveKeys: function saveKeys() {
        this.set('model.company_integration.activate_scenario', true);
        this.set('model.company_integration.completed', true);
        this.get('model.company_integration').save();
      },

      toggleActivateRetrieve: function toggleActivateRetrieve() {
        this.toggleProperty('model.company_integration.activate_retrieve');
      }

    }

  });
});