define("due-dashboard/templates/components/feedback-chat-title", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 7,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/feedback-chat-title.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "title-container");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "bold");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(":");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" · ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "bold");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(":");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var element6 = dom.childAt(element5, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element6, [0]), 0, 0);
            morphs[2] = dom.createMorphAt(element6, 2, 2);
            morphs[3] = dom.createMorphAt(dom.childAt(element6, [4]), 0, 0);
            morphs[4] = dom.createMorphAt(element6, 6, 6);
            return morphs;
          },
          statements: [["content", "feedback.chat_title", ["loc", [null, [4, 12], [4, 35]]], 0, 0, 0, 0], ["inline", "t", ["chats.response_at"], [], ["loc", [null, [5, 31], [5, 56]]], 0, 0], ["content", "feedback.chatCompletedAtTime", ["loc", [null, [5, 65], [5, 97]]], 0, 0, 0, 0], ["inline", "t", ["chats.last_action_at"], [], ["loc", [null, [5, 119], [5, 147]]], 0, 0], ["content", "feedback.chatUpdatedDateTime", ["loc", [null, [5, 156], [5, 188]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 12,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/feedback-chat-title.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "title-container");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" · ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "bold");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(":");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" · ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "bold");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(":");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [3]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(element3, 0, 0);
            morphs[1] = dom.createMorphAt(element3, 2, 2);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [0]), 0, 0);
            morphs[3] = dom.createMorphAt(element4, 2, 2);
            morphs[4] = dom.createMorphAt(dom.childAt(element4, [4]), 0, 0);
            morphs[5] = dom.createMorphAt(element4, 6, 6);
            return morphs;
          },
          statements: [["content", "feedback.chat_title", ["loc", [null, [9, 12], [9, 35]]], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat", ["settings.users.", ["get", "feedback.source_zone", ["loc", [null, [9, 69], [9, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 43], [9, 90]]], 0, 0]], [], ["loc", [null, [9, 38], [9, 92]]], 0, 0], ["inline", "t", ["chats.response_at"], [], ["loc", [null, [10, 33], [10, 58]]], 0, 0], ["content", "feedback.chatCompletedAtTime", ["loc", [null, [10, 67], [10, 99]]], 0, 0, 0, 0], ["inline", "t", ["chats.last_action_at"], [], ["loc", [null, [10, 121], [10, 149]]], 0, 0], ["content", "feedback.chatUpdatedDateTime", ["loc", [null, [10, 158], [10, 190]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/feedback-chat-title.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "feedback.source_zone", ["loc", [null, [2, 16], [2, 36]]], 0, 0, 0, 0], null], [], ["loc", [null, [2, 12], [2, 42]]], 0, 0], ["subexpr", "eq", [["get", "feedback.source_zone", ["loc", [null, [2, 47], [2, 67]]], 0, 0, 0, 0], "worldwide"], [], ["loc", [null, [2, 43], [2, 80]]], 0, 0]], [], ["loc", [null, [2, 8], [2, 81]]], 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [12, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 2
              },
              "end": {
                "line": 16,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/feedback-chat-title.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" · ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(": ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element1, 0, 0);
            morphs[1] = dom.createMorphAt(element1, 2, 2);
            morphs[2] = dom.createMorphAt(element1, 4, 4);
            return morphs;
          },
          statements: [["content", "feedback.chat_title", ["loc", [null, [15, 10], [15, 33]]], 0, 0, 0, 0], ["inline", "t", ["chats.last_action_at"], [], ["loc", [null, [15, 36], [15, 64]]], 0, 0], ["content", "feedback.chatUpdatedDateTime", ["loc", [null, [15, 66], [15, 98]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 2
              },
              "end": {
                "line": 18,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/feedback-chat-title.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" · ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" · ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(": ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element0, 0, 0);
            morphs[1] = dom.createMorphAt(element0, 2, 2);
            morphs[2] = dom.createMorphAt(element0, 4, 4);
            morphs[3] = dom.createMorphAt(element0, 6, 6);
            return morphs;
          },
          statements: [["content", "feedback.chat_title", ["loc", [null, [17, 10], [17, 33]]], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat", ["settings.users.", ["get", "feedback.source_zone", ["loc", [null, [17, 67], [17, 87]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 41], [17, 88]]], 0, 0]], [], ["loc", [null, [17, 36], [17, 90]]], 0, 0], ["inline", "t", ["chats.last_action_at"], [], ["loc", [null, [17, 93], [17, 121]]], 0, 0], ["content", "feedback.chatUpdatedDateTime", ["loc", [null, [17, 123], [17, 155]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/feedback-chat-title.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "feedback.source_zone", ["loc", [null, [14, 16], [14, 36]]], 0, 0, 0, 0], null], [], ["loc", [null, [14, 12], [14, 42]]], 0, 0], ["subexpr", "eq", [["get", "feedback.source_zone", ["loc", [null, [14, 47], [14, 67]]], 0, 0, 0, 0], "worldwide"], [], ["loc", [null, [14, 43], [14, 80]]], 0, 0]], [], ["loc", [null, [14, 8], [14, 81]]], 0, 0]], [], 0, 1, ["loc", [null, [14, 2], [18, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/feedback-chat-title.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "selectedFb", ["loc", [null, [1, 6], [1, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [19, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});