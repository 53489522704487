define('due-dashboard/components/due-dropdown-item', ['exports', 'ember', 'ember-cli-keyboard-nav/mixins/keyboard-nav'], function (exports, _ember, _emberCliKeyboardNavMixinsKeyboardNav) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  var htmlSafe = _ember['default'].String.htmlSafe;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_emberCliKeyboardNavMixinsKeyboardNav['default'], {
    tagManager: service(),
    tagName: 'div',
    classNames: ['due-dropdown-item'],
    filterValue: '',
    fromSelf: false,
    tagSortProperties: ['name', 'created_at:asc'],
    remainingTags: computed.setDiff('allTags', 'selectedTags'),
    sortedTags: computed.sort('remainingTags', 'tagSortProperties'),
    attributeBindings: ['style'],
    showLoadingLine: '',
    posFixedTo: null,
    surveyFilter: [],
    dropDownWidth: 0,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var that = this;
      this.bindKeys(this.$('.due-tag-dropdown-menu'));
      this.$(function () {
        return $('.tags-list li').on('mouseover', function () {
          return that.set('highlightedOptionIndex', $('.tags-list li').index(_this));
        });
      });

      if (this.get('posFixedTo')) {
        var rect = $(this.get('posFixedTo'))[0].getBoundingClientRect();
        this.set('dropDownStyle', htmlSafe('width:' + rect.width + 'px; position: absolute; z-index: 9100; margin-top: 5px;'));
      } else if (this.get('relativeToParent')) {
        this.set('dropDownStyle', htmlSafe('width: 100%; margin-top: 5px;'));
      }
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      var rightFrameWidth = _ember['default'].$('#right-frame').width() - 2; // set maximum width to right frame width usefull for small screens and -2 to see the borders
      var searchInput = this.element.querySelector('.tag-input');
      if (this.element.querySelector('.due-tag-dropdown-menu')) {
        var width = Math.min(this.element.querySelector('.due-tag-dropdown-menu').getBoundingClientRect().width, rightFrameWidth);
        if (this.get('dropDownWidth') !== width && this.get('showDropDown') === true && this.get('showArrow')) {
          this.set('dropDownWidth', width);
          this._updateDropDownPosition();
        }
        this.set('dropDownWidth', width);
      }
      if (searchInput) {
        searchInput.focus();
      }
    },

    _updateDropDownPosition: function _updateDropDownPosition() {
      var pageWidth = _ember['default'].$('body > .ember-view').width(),
          spaceLeft = pageWidth - this.get('triggerClientX'),
          width = this.get('dropDownWidth');
      var right = 0;
      if (spaceLeft < width) {
        right = width - spaceLeft;
      }
      this.set('arrowLeftProperty', htmlSafe('left: min(calc(10px + ' + right + 'px), ' + width + 'px);'));
      this.set('dropDownStyle', htmlSafe('width: ' + width + 'px;left: -' + right + 'px; margin-top: 12px;'));
    },

    canEditTags: computed('enableCreation', 'filterValue', function () {
      return this.get('enableCreation') && this.get('currentAccount.canEditTags') && !this.get('filterValue').includes('>') && this.get('filterValue').length > 1;
    }),

    canUseTags: computed('currentAccount.canUseTags', function () {
      return this.get('currentAccount.canUseTags');
    }),

    arrowDisplayClass: computed('showArrow', function () {
      return this.get('showArrow') ? 'show-class' : 'hide-class';
    }),

    highlightedOptionIndex: computed('filterValue', function () {
      if (this.get('filterValue')) {
        return 0;
      }
      return -1;
    }),

    show_class: computed('showDropDown', function () {
      this.set('fromSelf', false);
      if (this.get('showDropDown')) {
        return 'show-class';
      }
      return 'hide-class';
    }),

    _searchTags: function _searchTags(term) {
      var _this2 = this;

      this.set('showLoadingLine', 'show-loading-li');
      var filters = {
        name: term,
        dropdown_list_restrictions: JSON.stringify(['hidden', 'none'])
      };
      if (this.get('surveyFilterId')) {
        filters.survey = this.get('surveyFilterId');
      }
      this.get('tagManager').getAll(filters).then(function (tags) {
        if (!_this2.get('isDestroyed')) {
          _this2.set('searchTags', tags);
          _this2.set('showLoadingLine', '');
        }
      });
    },

    onEscPress: function onEscPress() {
      this.set('highlightedOptionIndex', -1);
      this.set('showDropDown', false);
      this.set('filterValue', '');
      this._searchTags('');
      if (this.get('closeDropDownAction')) {
        this.get('closeDropDownAction')();
      }
    },

    onDownPress: function onDownPress() {
      var index = this.get("highlightedOptionIndex");
      if (index === this.get("remainingTags.length") - 1) {
        index = 0;
      } else {
        index++;
      }
      this.set("highlightedOptionIndex", index);
    },

    onUpPress: function onUpPress() {
      var lastItem = this.get("remainingTags.length") - 1,
          index = lastItem;
      if (this.get("highlightedOptionIndex") >= 0) {
        index = this.get("highlightedOptionIndex") - 1;
      }
      if (index < 0) {
        index = lastItem;
      }
      this.set("highlightedOptionIndex", index);
    },

    onEnterPress: function onEnterPress() {
      var tag = this.get("remainingTags").objectAt(this.get("highlightedOptionIndex"));
      if (tag) {
        this.get('selectTagAction')(tag);
        this.onEscPress();
      }
    },

    highlightedOption: computed('remainingTags.[]', 'highlightedOptionIndex', function () {
      return this.get('remainingTags').objectAt(this.get('highlightedOptionIndex'));
    }),

    actions: {
      clickOutsideComponent: function clickOutsideComponent() {
        if (this.get('fromSelf')) {
          this.set('fromSelf', false);
          this.onEscPress();
        }
      },

      clickInsideComponent: function clickInsideComponent() {
        this.set('fromSelf', true);
      },

      debounceSearchOptions: function debounceSearchOptions(_v, e) {
        if (![13, 27, 37, 38, 39, 40].includes(e.keyCode)) {
          this.set("highlightedOptionIndex", 0);
          run.debounce(this, this._searchTags, this.get('filterValue'), 500);
        }
      },

      selectOption: function selectOption(tag) {
        this.get('selectTagAction')(tag);
        this.onEscPress();
      },

      createTag: function createTag() {
        var _this3 = this;

        var name = this.get('filterValue');
        this.store.createRecord('tag', { name: name, color: '#b0b0b0' }).save().then(function (tag) {
          return _this3.get('selectTagAction')(tag, true);
        });
        this.onEscPress();
      },

      triggerTagDropDown: function triggerTagDropDown(e) {
        this.set('triggerClientX', e.clientX);
        this.set('dropDownWidth', 0);
        this.toggleProperty('showDropDown');
      }
    }
  });
});