define('due-dashboard/mixins/company-integrations-serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.data.attributes.type;
      delete json.data.attributes.free_access;
      if (json.data.relationships) {
        delete json.data.relationships.company;
      }

      return json;
    }
  });
});