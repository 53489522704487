define('due-dashboard/helpers/check-rights-on-route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function (object) {
    var currentAccount = object[0];
    var profile = currentAccount.get('content.profile');
    var company = currentAccount.get('content.company');
    if (!profile) {
      return true;
    }
    var route = object[1].split('.');
    var subdomain = route[0];
    var target = route[1];
    if (target === 'libraries') {
      target = route[2];
    }
    if (subdomain === 'companies' && target === 'graphs' && !currentAccount.get('canAccessGraphs')) {
      return false;
    }
    if (subdomain === 'companies' && target === 'questions' && !company.get('show_answer_details')) {
      return false;
    }
    if (subdomain === 'companies' && target === 'custom-stats' && (!company.get('show_custom_stats') || !currentAccount.get('canAccessCustomReports'))) {
      return false;
    }
    if (subdomain === 'companies' && target === 'verbatim' && (!company.get('show_verbatim') || !currentAccount.get('canUseVerbatims'))) {
      return false;
    }
    if (subdomain === 'companies' && target === 'analysis' && (!company.get('show_analysis') || !currentAccount.get('canUseAnalysis'))) {
      return false;
    }
    switch (profile) {
      case 'admin':
        return true;
      case 'manager':
      case 'read_only':
        if (subdomain === 'settings') {
          return target === 'account' ? true : false;
        }
        return profile === 'manager' || target !== 'scenarios';
      case 'custom':
        if (subdomain === 'settings') {
          if (target === 'account') {
            return true;
          }
          if (target === 'users' && currentAccount.get('canManageUsers')) {
            return true;
          }
          return false;
        } else {
          var rights = object[0].rights;
          var forbiddable_routes = ['scenarios', 'surveys', 'questions', 'chats', 'exports', 'templates', 'fonts', 'rating-scales'];
          if (forbiddable_routes.includes(target)) {
            if (target.includes('-')) {
              target = target.replace('-', '_');
            }
            var ret = rights.get(target + '_rights') && rights.get(target + '_rights') !== 'none';
            return ret;
          } else {
            return true;
          }
        }
      default:
        return false;
    }
  });
});