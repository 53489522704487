define('due-dashboard/components/message-item', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    originalTextDisplay: true,
    showTranslation: false,
    translationKey: 'translate',
    autoTranslate: computed.alias('currentAccount.content.company.verbatim_auto_translation'),
    verbatimTranslation: computed.alias('currentAccount.content.company.verbatim_translation'),

    messageFromAuthor: computed('message.author', function () {
      var author = this.get('message.author');
      if (author) {
        var match = author.match(/^;scenario;(.*)/);
        var ret = this.set('from_scenario', !!match);
        return ret ? match[1] : author;
      }
      this.set('from_scenario', false);
      return null;
    }),

    hasTranslation: computed('message.translation', 'verbatimTranslation', function () {
      return this.get('message.translation') && this.get('verbatimTranslation');
    }),

    messageText: computed('message.text', 'message.translation', 'i18n.locale', 'autoTranslate', function () {
      if (this.get('message.translation') && this.get('autoTranslate')) {
        this.set('originalTextDisplay', false);
        this.set('translationKey', 'original_text');
        return htmlSafe(this.get('message.translation'));
      }
      return this.get('message.text');
    }),

    messageTranslation: computed('message.text', 'message.translation', 'autoTranslate', function () {
      if (!this.get('autoTranslate') && this.get('message.translation').length) {
        return htmlSafe(this.get('message.translation'));
      }
      return this.get('message.text');
    }),

    updatedAtDate: computed('message.updated_at', function () {
      return (0, _moment['default'])(this.get('message.updated_at')).format('LL');
    }),

    updatedAtTime: computed('message.updated_at', function () {
      return (0, _moment['default'])(this.get('message.updated_at')).format('LT');
    }),

    actions: {
      toggleDisplayText: function toggleDisplayText() {
        if (!this.get('showTranslation')) {
          if (this.get('originalTextDisplay')) {
            this.set('translationKey', 'hide_translation');
          } else {
            this.set('translationKey', 'hide_original');
          }
        } else if (this.get('originalTextDisplay')) {
          this.set('translationKey', 'translate');
        } else {
          this.set('translationKey', 'original_text');
        }
        this.toggleProperty('showTranslation');
      }
    }
  });
});