define('due-dashboard/helpers/asset-url', ['exports', 'ember'], function (exports, _ember) {
    exports.assetResolve = assetResolve;

    function assetResolve(asset) {
        var fn = _ember['default'].container.lookup('asset-mapper:main').asset_url;
        var res = asset;
        if (fn) {
            var a = fn(asset);
            if (a) {
                res = a;
            }
        }
        return res;
    }

    exports['default'] = _ember['default'].Helper.helper(assetResolve);
});