define('due-dashboard/components/digests/filters/survey-filter', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    allSurveys: [],
    remainingSurveys: computed.setDiff('allSurveys', 'surveyFilter'),

    init: function init() {
      var _this = this;

      if (!this.get('filter.value')) {
        this.set('filter.value', []);
      }
      this.store.query('survey', { filter: { status: 'live' }, page: { number: 1, size: 60 } }).then(function (surveys) {
        _this.set('allSurveys', surveys);
        _this._querySavedSurveys(surveys);
      });
      this._super.apply(this, arguments);
    },

    surveyFilter: computed('filter.value', 'allSurveys', {
      get: function get() {
        var surveys = this.get('filter.value');
        return this.get('allSurveys').toArray().filter(function (a) {
          return surveys.includes(a.id);
        });
      }, set: function set(_, filter) {
        this.set('filter.value', filter.mapBy('id'));
        this.get('saveDigestDelay')();
        return filter;
      }
    }),

    dropdownSurveys: computed('remainingSurveys', 'filteredSurveys', 'searchList', function () {
      return this.get('searchList') ? this.get('filteredSurveys') : this.get('remainingSurveys');
    }),

    /**
     * Add missing surveys from saved filter
     * @param {Array<Model<Survey>>} Surveys to add if they are missing
     */
    _querySavedSurveys: function _querySavedSurveys(surveys) {
      var _this2 = this;

      var missingSurveys = this.get('filter.value').filter(function (id) {
        return surveys.find(function (s) {
          return s.get('id') === id;
        }) === undefined;
      });

      if (missingSurveys.length > 0) {
        this.store.query('survey', { filter: { status: 'live', id: missingSurveys } }).then(function (surveys) {
          _this2.set('allSurveys', _this2.get('allSurveys').toArray().concat(surveys.toArray()));
        });
      }
    },

    /**
     * Search surveys in the list from its name
     * @param {String} term Survey name to search
     */
    _searchSurvey: function _searchSurvey(term) {
      var _this3 = this;

      var search_promise = this.store.query('survey', {
        filter: { name: term },
        page: { number: 1, size: 20 }
      });
      this.set('search_promise', search_promise);
      search_promise.then(function (surveys) {
        return _this3.set('filteredSurveys', surveys.filter(function (s) {
          return !_this3.get('filter.value').includes(s.id);
        }));
      });
    },

    actions: {
      /**
       * Search surveys in the list from its name
       * @param {String} term Survey name to search
       */
      searchSurveys: function searchSurveys(term) {
        this.set('searchList', term && term !== '');
        run.debounce(this, this._searchSurvey, term, 600);
      },

      /**
       * Add the selected survey to the filter list
       * @param {Model<Suvey>} survey Survey selected from dropdown
       */
      addSurvey: function addSurvey(survey) {
        var allSurveys = this.get('allSurveys').toArray();
        if (allSurveys.find(function (s) {
          return s.get('id') === survey.get('id');
        }) === undefined) {
          allSurveys.push(survey);
          this.set('allSurveys', allSurveys);
        }

        var surveys = this.get('filter.value');
        surveys.push(survey.id);
        this.set('filter.value', surveys);
        this.notifyPropertyChange('filter.value');
        this.get('saveDigestDelay')();
      },

      /**
       * Delete a survey from the filters
       * @param {Model<Survey>} survey Survey to delete
       */
      removeSurvey: function removeSurvey(survey) {
        var surveys = this.get('filter.value');
        surveys.splice(surveys.indexOf(survey), 1);
        this.set('filter.value', surveys);
        this.notifyPropertyChange('filter.value');
        this.get('saveDigestDelay')();
      },

      /**
       * Remove survey filter
       */
      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});