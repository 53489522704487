define('due-dashboard/controllers/settings/keyword-adjustments', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  exports['default'] = Controller.extend({
    searchAdjustment: null,

    currentPage: 1,
    limit: 10,
    keywordAdjustments: computed.alias('model.keywordAdjustments'),

    pagesCount: computed('model.keywordAdjustments.content.meta', 'totalCount', function () {
      var meta = this.get('model.keywordAdjustments.content.meta');
      return this.get('totalCount') ? Math.ceil(this.get('totalCount') / this.get('limit')) : meta ? meta['page_count'] : 0;
    }),

    totalCount: computed('model.keywordAdjustments.content.meta', function () {
      var meta = this.get('model.keywordAdjustments.content.meta');
      return meta ? meta['total_count'] : 0;
    }),

    pageChanged: observer('currentPage', function () {
      if (this.get('doNotQuery')) {
        this.set('doNotQuery', false);
      } else {
        this.updateAdjustmentsList();
      }
    }),

    updateAdjustmentsList: function updateAdjustmentsList() {
      var filters = { filter: {} },
          searchAdjustment = this.get('searchAdjustment');
      filters.page = { number: this.get('currentPage') };
      if (searchAdjustment) {
        filters.filter.text = searchAdjustment;
      }
      var adjustment_query = this.get('store').query('keyword-adjustment', filters);
      this.set('model.keywordAdjustments', adjustment_query);
    },

    actions: {
      debouncedSearchAdjustement: function debouncedSearchAdjustement() {
        if (this.get('currentPage') !== 1) {
          this.set('doNotQuery', true);
          this.set('currentPage', 1);
        }
        run.debounce(this, this.updateAdjustmentsList, 500);
      },

      deleteAdjustment: function deleteAdjustment(adjustment) {
        var _this = this;

        var currentPage = this.get('currentPage'),
            dataLength = this.get('model.keywordAdjustments.length');
        adjustment.destroyRecord().then(function () {
          if (currentPage === _this.get('pagesCount') && currentPage > 1 && dataLength === 1) {
            _this.decrementProperty('currentPage');
          } else if (dataLength === 1) {
            _this.updateAdjustmentsList();
          } else {
            var keywordAdjustments = _this.get('model.keywordAdjustments').removeObject(adjustment);
            _this.set('model.keywordAdjustments', keywordAdjustments);
          }
          _this.set('totalCount', _this.get('totalCount') - 1);
        });
      },

      forceUpdateListAfterAdd: function forceUpdateListAfterAdd(addedAdjustment) {
        var totalCount = this.get('totalCount');
        if (this.get('model.keywordAdjustments.length') < this.get('limit')) {
          var keywordAdjustments = this.get('model.keywordAdjustments').toArray();
          keywordAdjustments.push(addedAdjustment);
          this.set('model.keywordAdjustments', keywordAdjustments);
        }
        this.set('totalCount', totalCount + 1);
      }
    }
  });
});