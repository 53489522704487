define('due-dashboard/models/scenario', ['exports', 'ember', 'ember-data', 'ember-cp-validations'], function (exports, _ember, _emberData, _emberCpValidations) {
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;

  var Validations = (0, _emberCpValidations.buildValidations)({
    scenario_conditions: (0, _emberCpValidations.validator)('has-many'),
    scenario_actions: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('presence', true)]
  });

  exports['default'] = Model.extend(Validations, {
    company: belongsTo('company', { async: true }),

    scenario_conditions: hasMany('scenario-condition', { polymorphic: true }),
    scenario_actions: hasMany('scenario-action', { polymorphic: true }),

    name: attr('string'),
    position: attr('number'),
    category_hook_key: attr('string'),

    dasherizedHookKey: _ember['default'].computed('category_hook_key', function () {
      return this.get('category_hook_key').dasherize();
    }),

    categoryIcon: computed('category_hook_key', function () {
      var icons = {
        survey_end: 'random',
        company_new_feedback: 'commenting-o',
        survey_send: 'envelope-o'
      };
      return icons[this.get('category_hook_key')];
    }),

    save: function save(options) {
      var _this = this;

      // Workaround for https://github.com/emberjs/data/issues/1829
      var unloadLocalGhosts = function unloadLocalGhosts(records) {
        if (records) {
          records.filterBy('isNew').forEach(function (r) {
            records.removeObject(r);
            r.deleteRecord();
            _this.store.unloadRecord(r);
          });
        }
      };
      return this._super(options).then(function () {
        if (!_this.get('isDeleted')) {
          return RSVP.all([_this.get('scenario_conditions').reload(), _this.get('scenario_actions').reload()]).then(function () {
            unloadLocalGhosts(_this.get('scenario_conditions'));
            unloadLocalGhosts(_this.get('scenario_actions'));
            return _this;
          });
        }
        return _this;
      });
    },

    defineSavepoint: function defineSavepoint() {
      var _this2 = this;

      var saveHasMany = function saveHasMany(array) {
        return RSVP.all(array.map(function (c) {
          return c.defineSavepoint();
        }));
      };
      RSVP.hash({
        scenario_conditions: this.get('scenario_conditions'),
        scenario_actions: this.get('scenario_actions')
      }).then(function (h) {
        return RSVP.hash({
          scenario_conditions: saveHasMany(h.scenario_conditions),
          scenario_actions: saveHasMany(h.scenario_actions)
        });
      }).then(function (h) {
        _this2.set('_savepoint_conditions', h.scenario_conditions);
        _this2.set('_savepoint_actions', h.scenario_actions);
        return _this2;
      });
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var conditions = this.get('_savepoint_conditions');
      var actions = this.get('_savepoint_actions');
      if (conditions) {
        conditions.forEach(function (c) {
          return c.rollbackToSavepoint();
        });
        this.set('scenario_conditions', conditions);
      }

      if (actions) {
        actions.forEach(function (a) {
          return a.rollbackToSavepoint();
        });
        this.set('scenario_actions', actions);
      }
      return RSVP.resolve(this);
    }
  });
});