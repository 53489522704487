define('due-dashboard/routes/settings/integrations/zapier', ['exports', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({

    needApiKey: true,

    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      model.company_integration.setProperties({ api_key: model.api_key, completed: true, is_active: true });
      if (model.company_integration.get('isNew') && !model.company_integration.get('isSaving')) {
        model.company_integration.save();
      }
    }

  });
});