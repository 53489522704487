define('due-dashboard/components/digests/parts/language-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    actions: {
      changeLanguage: function changeLanguage(language) {
        var translations = this.get('translations');
        var tag = language.get('tag').toLowerCase();
        if (!translations[tag]) {
          translations[tag] = JSON.parse(JSON.stringify(translations[this.get('language.tag').toLowerCase()]));
          this.set('translations', translations);
        }
        this.set('language', language);
        this.get('saveDigestDelay')();
      },

      goToTranslation: function goToTranslation() {
        this.get('goToTranslation')();
      }
    }
  });
});