define('due-dashboard/components/due-table/elements/-positive-impact', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-positive-impact'],

    hideValue: computed('elem', function () {
      return this.get('elem.positiveImpactHideValue');
    }),

    compValue: computed('value', function () {
      var value = this.get('value');

      if (value.total == null) {
        value.total = '-';
      }

      return value;
    })
  });
});