define('due-dashboard/components/v2/libraries/surveys/navbar/elements/-redirect-btn', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content-element-prev-next-btns'],
    badUrlFormatError: false,
    cachedValues: {},

    init: function init() {
      var _this = this;

      var content = this.get('object.content');
      Object.keys(content).map(function (key) {
        return _this.cachedValues[key] = content[key];
      });
      this._super.apply(this, arguments);
    },

    actions: {
      onChangeContentField: function onChangeContentField() {
        var saveCallback = this.get('contentUpdateCallback');
        var content = this.get('object.content');

        if (saveCallback) {
          saveCallback(content);
        }
      },

      updateContentField: function updateContentField(field, value) {
        var content = this.get('object.content');
        var focus = this.get('focus');

        if (field === 'url' && value !== -1) {
          if (!value.includes('http://') && !value.includes('https://')) {
            this.set('badUrlFormatError', true);
            return;
          } else if (this.get('badUrlFormatError')) {
            this.set('badUrlFormatError', false);
          }
        }

        if (!focus && value !== -1) this.set('focus', true);
        if (value !== -1) {
          var valueChanged = this.cachedValues[field] && !(this.cachedValues[field] === value);
          this.cachedValues[field] = value;
          set(content, field, value);
          if (valueChanged) this.send('onChangeContentField');
        }
        if (value === -1 && this.get('focus')) this.set('focus', false);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/libraries/surveys/navbar/elements/-redirect-btn}} redirection button element config component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */