define('due-dashboard/components/digests/parts/one-title-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    actions: {
      saveDigestDelay: function saveDigestDelay() {
        this.get('saveDigestDelay')();
      }
    }
  });
});