define("due-dashboard/templates/components/aircall-tags-dp", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 91
              },
              "end": {
                "line": 9,
                "column": 188
              }
            },
            "moduleName": "due-dashboard/templates/components/aircall-tags-dp.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("   ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "aircall-tag-desc");
            var el2 = dom.createTextNode("(");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(")");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "tag.description", ["loc", [null, [9, 160], [9, 179]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/aircall-tags-dp.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "aircall-tag-tag");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'style');
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          return morphs;
        },
        statements: [["attribute", "style", ["concat", ["background-color: ", ["get", "tag.color", ["loc", [null, [9, 59], [9, 68]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "tag.name", ["loc", [null, [9, 72], [9, 84]]], 0, 0, 0, 0], ["block", "if", [["get", "tag.description", ["loc", [null, [9, 97], [9, 112]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 91], [9, 195]]]]],
        locals: ["tag"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 26
          }
        },
        "moduleName": "due-dashboard/templates/components/aircall-tags-dp.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "power-select-multiple", [], ["dropdownClass", "filter-dropdown", "options", ["subexpr", "@mut", [["get", "tags", ["loc", [null, [3, 10], [3, 14]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "target", ["loc", [null, [4, 11], [4, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "searchField", "name", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "target", ["loc", [null, [6, 24], [6, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 19], [6, 31]]], 0, 0]], [], ["loc", [null, [6, 11], [6, 32]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [10, 26]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});