define('due-dashboard/components/subscription-plan-card', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    isUnicorn: computed('plan.name', function () {
      return this.get('plan.name') === 'unicorn';
    }),

    isCustom: computed('plan.name', function () {
      if (['entrepreneur', 'unicorn', 'salesforce'].includes(this.get('plan.name'))) {
        return false;
      } else {
        return true;
      }
    }),

    ctaKey: computed('subscribed', function () {
      if (this.get('subscribed')) {
        return 'update';
      } else {
        return 'cta';
      }
    }),

    title: computed('i18n.locale', 'translationSection', 'plan.name', 'isCustom', 'company_name', function () {
      if (this.get('isCustom') || this.get('plan.name') === 'unicorn') {
        return this.get('company_name');
      } else {
        return this.get('i18n').t('subscription.' + this.get('translationSection') + '.title');
      }
    }),

    translationSection: computed('isCustom', 'plan.name', function () {
      if (this.get('isCustom')) {
        return 'custom';
      } else {
        return this.get('plan.name');
      }
    }),

    link: computed('isUnicorn', function () {
      return this.get('isUnicorn') ? 'mailto:contact@diduenjoy.com' : '';
    }),

    target: computed('isUnicorn', function () {
      return this.get('isUnicorn') ? '_blank' : '';
    }),

    pictureUrl: computed('plan.name', 'isCustom', function () {
      var name = '';
      if (this.get('isCustom')) {
        name = 'secret';
      } else {
        name = this.get('plan.name');
      }
      if (this.asset_mapper) {
        return this.asset_mapper.asset_url('images/plan/pricing-' + name + '.png');
      }
    }),

    limit: computed('plan.limit', function () {
      return this.get('plan.limit') || 5000;
    })
  });
});