define('due-dashboard/components/feedback-chat-list', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet) {
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  var Component = _ember['default'].Component;

  // TODO: Ask if this is used anywhere, wtf ?
  exports['default'] = Component.extend({

    _global: service('feedback-search/global'),
    classNames: ['list-wrapper'],
    elementId: 'feedbacks-list-wrapper',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!this.get('selectedFeedback') && this.get('indexFeedback')) {
        this.get('scrollBack')();
      }
    },

    searchfieldChanged: observer('feedbackSearchText', function () {
      var service = this.get('_global'),
          filter_set = service.get('current').copy();

      filter_set.set('search_keyword', this.get('feedbackSearchText'));
      service.updateCurrent(filter_set);
      if (this.get('currentPage') !== 1) {
        this.set('doNotQuery', true);
        this.set('currentPage', 1);
      }
      run.debounce(this, this.launchQuery, 300);
    }),

    actions: {
      clickFeedback: function clickFeedback(feedback) {
        this.get("clickFeedback")(feedback);
      }
    }
  });
});