define('due-dashboard/components/each-languages', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var oneWay = _Ember$computed.oneWay;
  var setDiff = _Ember$computed.setDiff;
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({

    displayLanguageList: false,

    remainingLanguages: setDiff('existingLanguages', 'selectedLanguages'),
    selectedLanguage: oneWay('selectedLanguages.firstObject'),

    // selectedLanguages: mapBy('targets', 'language') not working
    selectedLanguages: computed('targets.[]', function () {
      return this.get('targets').mapBy('language');
    }),

    selectedTargetObserver: observer('targets.[]', 'selectedLanguage.id', function () {
      var target = this.get('targets').findBy('language.id', this.get('selectedLanguage.id'));
      this.set('selectedTarget', target);
    }),

    existingLanguages: computed(function () {
      return this.store.peekAll('language');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('target')) {
        this.setTargets();
      }
    },

    setTargets: function setTargets() {
      var _this = this;

      var resume = function resume(promise, cb) {
        if (promise.then) {
          promise.then(function (obj) {
            return cb(obj);
          });
        } else {
          cb(promise);
        }
      };
      var load = function load(paths, target, cb) {
        var promise = get(target, paths[0]);
        if (paths.length > 1) {
          resume(promise, function (target) {
            return load(paths.slice(1), target, cb);
          });
        } else {
          resume(promise, function (target) {
            return cb(target);
          });
        }
      };

      load(this.get('target').split('.'), this.get('model'), function (objs) {
        _this.set('targets', objs.toArray());
      });
    },

    actions: {
      removeLanguage: function removeLanguage(language) {
        if (this.get('removeLanguage')) {
          if (!this.get('removeLanguage')(language, this.get('targets'))) {
            return;
          }
        }
        var targets = this.get('targets');
        targets.removeObject(targets.findBy('language.id', language.get('id')));
      },

      addLanguage: function addLanguage(language) {
        if (this.get('addLanguage')) {
          if (!this.get('addLanguage')(language, this.get('targets'))) {
            return;
          }
        }
        var target = this.get('target').split('.').reverse();
        var target_type = target[0].slice(0, -1);
        var parent = target[1];
        var creationParams = { language: language };
        creationParams[parent] = this.get('model.' + target.slice(1).reverse().join('.'));
        var new_ressource = this.store.createRecord(target_type, creationParams);
        this.get('targets').addObject(new_ressource);
        if (!this.get('remainingLanguages.length')) {
          this.set('displayLanguageList', false);
        }
      },

      toggleLanguageList: function toggleLanguageList() {
        this.toggleProperty('displayLanguageList');
      }

    }

  });
});