define('due-dashboard/routes/settings/integrations/zendesk', ['exports', 'due-dashboard/config/environment', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardConfigEnvironment, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({
    queryParams: {
      code: {
        replace: true
      }
    },

    needApiKey: true,

    beforeModel: function beforeModel() {
      if (!this.get('_code')) {
        this._decodeUrl();
        if (this.get('_code')) {
          window.location.replace(_dueDashboardConfigEnvironment['default'].EmberENV.ZENDESK_CONNECT_URL + '/' + ('' + this.modelFor('settings.integrations').company.id) + ('?zendesk_url=' + this.get('_domain')) + ('&code=' + this.get('_code')));
        }
      }
      this._super.apply(this, arguments);
    },

    _decodeUrl: function _decodeUrl() {
      var code = this.router.location.location.search.match(/code=([^&$]+)/),
          domain = this.router.location.location.search.match(/state=([^&$]+)/);
      this.set('_code', code && code[1] ? code[1] : null);
      this.set('_domain', domain && domain[1] ? domain[1] : null);
    }
  });
});