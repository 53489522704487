define('due-dashboard/routes/companies/custom-stats', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Route.extend({

    model: function model() {
      return RSVP.hash({
        custom_stats: this.store.findAll('custom-stat')
      });
    }

  });
});