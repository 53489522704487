define('due-dashboard/routes/settings/integrations', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var ex_it = this.store.findAll('integration');
      var account = this.get('currentAccount.content');
      return account.get('company').then(function (company) {
        return _ember['default'].RSVP.hash({
          integrations: ex_it,
          account: account,
          company: company,
          company_integrations: _this.store.query('company_integration', {}),
          surveys: _this.store.query('survey', { filter: { status: 'live' } })
        });
      });
    },

    actions: {

      willTransition: function willTransition(transition) {
        if (transition.targetName === "settings.integrations.index") {
          this.refresh();
        }
        return true;
      },

      createApiKey: function createApiKey() {
        return this.store.createRecord('ApiKey', {
          company: this.modelFor('settings.integrations').company
        }).save();
      },

      redirectToIntegration: function redirectToIntegration(integration) {
        if (integration.get('free_access')) {
          this.transitionTo(this.get('routeName') + '.' + integration.get('name'));
        }
      }
    }
  });
});