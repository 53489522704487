define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/-accessibility', ['exports', 'ember', 'due-dashboard/helpers/color-formating'], function (exports, _ember, _dueDashboardHelpersColorFormating) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    DEFAULT_COLOR: '#000000',
    COLOR_REGEX: /(#[0-9a-fA-F]*|rgba\((\d+,\s?){3}\d*\.\d*\))/g,

    currentDesignOptions: computed('isLoading', function () {
      return {
        borderColor: this._extractColors(this.get('designOptions.accessibility_focus_border_color'), this.get('DEFAULT_COLOR'))
      };
    }),

    _extractColors: function _extractColors(color, defaultValue) {
      if (color == undefined) {
        return defaultValue;
      }
      var match = color.match(this.get('COLOR_REGEX'));
      if (match == undefined) {
        return defaultValue;
      }
      return match[0];
    },

    _extractOption: function _extractOption(designOptionName, optionsName) {
      var options = this.get(optionsName);
      var designOptions = this.get(designOptionName);
      return options.find(function (obj) {
        return obj.value === designOptions;
      }) || options[0];
    },

    actions: {
      setValue: function setValue(frontElement, designOption, value) {
        if (frontElement.toLowerCase().includes('color')) value = _dueDashboardHelpersColorFormating['default'].compute(value);
        this.set('currentDesignOptions.' + frontElement, value);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, value));
      }
    }
  });
});