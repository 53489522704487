define('due-dashboard/components/question-category-dropdown', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    allowClear: false,

    init: function init() {
      var _this = this;

      var survey_ids = this.get('surveyFilters') || [];
      survey_ids = survey_ids[0] && typeof survey_ids[0] === "string" ? survey_ids : survey_ids.mapBy('id');
      this.store.query('question-category', { filter: { survey_ids: survey_ids }, page: { number: 1, size: 20 } }).then(function (allQuestionCats) {
        _this.set('allQuestionCats', allQuestionCats.toArray().filterBy('questions_count'));
      });
      this._super.apply(this, arguments);
    },

    _searchQuestionCats: function _searchQuestionCats(term, resolve, reject) {
      this.store.query('question-category', {
        filter: {
          contains: term,
          survey_ids: this.get('survey_ids')
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (qcc) {
        return resolve(qcc.filterBy('questions_count'));
      }, reject);
    },

    actions: {

      searchQuestionCats: function searchQuestionCats(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this2, _this2._searchQuestionCats, term, resolve, reject, 600);
        });
      }

    }

  });
});