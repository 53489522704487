define('due-dashboard/components/digests/parts/color-picker-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var copy = _ember['default'].copy;
  exports['default'] = Component.extend({

    classNames: ['color-picker-wrapper'],
    classNameBindings: ['withSelector'],
    validColor: [],

    init: function init() {
      var valuName = 'options.' + this.get('value');
      this.set('valueName', valuName);
      this._super.apply(this, arguments);
    },

    getTempDefault: function getTempDefault(displayType) {
      // TODO improve this function to handle all possible cases
      if (displayType === 'volume') {
        return { defaultMin: 0, defaultMax: 999999 };
      }
      return { defaultMin: 0, defaultMax: 100 };
    },

    actions: {

      openModal: function openModal() {
        var valuName = 'options.' + this.get('value');
        var typeKey = this.get('value').replace(/_colors([^_colors]*)$/, '_type' + '$1'),
            typeValue = this.get('options.' + typeKey);
        if (!this.get(valuName)) {
          var min = this.get('defaultMin') || this.getTempDefault(typeValue)['defaultMin'] || 0;
          var max = this.get('defaultMax') || this.getTempDefault(typeValue)['defaultMax'] || 1;
          var color = this.get('defaultValue') ? this.get('defaultValue') : "#000000";
          this.set(valuName, [{ min: min, max: max, color: color }]);
        }
        this.set('tempValues', copy(this.get(valuName), true));
        this.set('validColor', []);
        this.set('modalIsOpened', true);
      },

      closeModal: function closeModal() {
        this.set('modalIsOpened', false);
        this.set('validColor', []);
      },

      handleEv: function handleEv(value, event) {
        var thisObj = event.currentTarget;
        if (value.indexOf("#") != 0) {
          event.preventDefault();
          thisObj.value = "#" + value;
        }
        thisObj.value = thisObj.value.replace("##", "#");
      },

      addValue: function addValue() {
        var valuName = 'options.' + this.get('value');
        var lastValue = this.get(valuName)[this.get(valuName).length - 1];
        var newMin = lastValue ? Number(lastValue.max) + 1 : 0;
        this.get('tempValues').addObject({ min: newMin, max: Number(newMin) + 1, color: "#000000" });
      },

      removeValue: function removeValue(value) {
        this.get('tempValues').removeObject(value);
        this.set('validColor', []);
      },

      saveValues: function saveValues() {
        var validColor = [];
        var hasError = false;
        this.get('tempValues').forEach(function (value) {
          if (!/^#([0-9A-F]{3}){1,2}$/i.test(value.color)) {
            hasError = true;
          }
          validColor.push(!/^#([0-9A-F]{3}){1,2}$/i.test(value.color));
        });
        this.set('validColor', validColor);
        if (!hasError) {
          var valuName = 'options.' + this.get('value');
          this.set(valuName, this.get('tempValues'));
          this.set('modalIsOpened', false);
          this.get('saveDigestDelay')();
        }
      }
    }
  });
});