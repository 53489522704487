define("due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 13,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "dropdown-text");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "font.name", ["loc", [null, [12, 34], [12, 47]]], 0, 0, 0, 0]],
          locals: ["font"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "due-typo-default");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element3, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["words.font_family"], [], ["loc", [null, [3, 35], [3, 60]]], 0, 0], ["block", "due-select", [], ["value", ["subexpr", "@mut", [["get", "currentDesignOptions.fontFamily", ["loc", [null, [5, 12], [5, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "customFonts", ["loc", [null, [6, 14], [6, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["selectDropdownValue", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [7, 53], [7, 62]]], 0, 0, 0, 0], "_font_family"], [], ["loc", [null, [7, 45], [7, 78]]], 0, 0], "fontFamily"], [], ["loc", [null, [7, 15], [7, 92]]], 0, 0], "containerSelector", ".secondary-panel", "rightAlign", true], 0, null, ["loc", [null, [4, 4], [13, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "due-typo-default");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element2, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["words.color"], [], ["loc", [null, [18, 35], [18, 54]]], 0, 0], ["inline", "due-input", [], ["type", "color", "placeholder", "", "value", ["subexpr", "@mut", [["get", "currentDesignOptions.color", ["loc", [null, [22, 12], [22, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValue", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [23, 42], [23, 51]]], 0, 0, 0, 0], "_color"], [], ["loc", [null, [23, 34], [23, 61]]], 0, 0]], [], ["loc", [null, [23, 15], [23, 62]]], 0, 0]], ["loc", [null, [19, 4], [24, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 38,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "component", ["v2/-range-slider"], ["label", ["subexpr", "t", ["words.font_size"], [], ["loc", [null, [30, 12], [30, 33]]], 0, 0], "min", 0, "max", 100, "value", ["subexpr", "@mut", [["get", "currentDesignOptions.fontSize", ["loc", [null, [33, 12], [33, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValueWithUnity", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [34, 51], [34, 60]]], 0, 0, 0, 0], "_font_size"], [], ["loc", [null, [34, 43], [34, 74]]], 0, 0], "fontSize"], [], ["loc", [null, [34, 15], [34, 86]]], 0, 0], "suffixLabel", ["subexpr", "@mut", [["get", "currentDesignOptions.fontSizeSuffix", ["loc", [null, [35, 18], [35, 53]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [29, 4], [36, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 4
              },
              "end": {
                "line": 51,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "dropdown-text");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", [["get", "fontWeight.text", ["loc", [null, [50, 38], [50, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [50, 34], [50, 55]]], 0, 0]],
          locals: ["fontWeight"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "due-typo-default");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element1, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["words.font_weight"], [], ["loc", [null, [41, 35], [41, 60]]], 0, 0], ["block", "due-select", [], ["value", ["subexpr", "@mut", [["get", "currentDesignOptions.fontWeight", ["loc", [null, [43, 12], [43, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "FONT_WEIGHTS", ["loc", [null, [44, 14], [44, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["selectDropdownValue", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [45, 53], [45, 62]]], 0, 0, 0, 0], "_font_weight"], [], ["loc", [null, [45, 45], [45, 78]]], 0, 0], "fontWeight"], [], ["loc", [null, [45, 15], [45, 92]]], 0, 0], "containerSelector", ".secondary-panel", "rightAlign", true], 0, null, ["loc", [null, [42, 4], [51, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 0
            },
            "end": {
              "line": 65,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "component", ["v2/-range-slider"], ["label", ["subexpr", "t", ["words.letter_spacing"], [], ["loc", [null, [57, 12], [57, 38]]], 0, 0], "min", 0, "max", 50, "value", ["subexpr", "@mut", [["get", "currentDesignOptions.letterSpacing", ["loc", [null, [60, 12], [60, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValueWithUnity", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [61, 51], [61, 60]]], 0, 0, 0, 0], "_letter_spacing"], [], ["loc", [null, [61, 43], [61, 79]]], 0, 0], "letterSpacing"], [], ["loc", [null, [61, 15], [61, 96]]], 0, 0], "suffixLabel", ["subexpr", "@mut", [["get", "currentDesignOptions.letterSpacingSuffix", ["loc", [null, [62, 18], [62, 58]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [56, 4], [63, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 66,
              "column": 0
            },
            "end": {
              "line": 77,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "component", ["v2/-range-slider"], ["label", ["subexpr", "t", ["words.line_height"], [], ["loc", [null, [69, 12], [69, 35]]], 0, 0], "min", 0, "max", 50, "value", ["subexpr", "@mut", [["get", "currentDesignOptions.lineHeight", ["loc", [null, [72, 12], [72, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setValueWithUnity", ["subexpr", "concat", [["get", "styleName", ["loc", [null, [73, 51], [73, 60]]], 0, 0, 0, 0], "_line_height"], [], ["loc", [null, [73, 43], [73, 76]]], 0, 0], "lineHeight"], [], ["loc", [null, [73, 15], [73, 90]]], 0, 0], "suffixLabel", ["subexpr", "@mut", [["get", "currentDesignOptions.lineHeightSuffix", ["loc", [null, [74, 18], [74, 55]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [68, 4], [75, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 81,
                "column": 4
              },
              "end": {
                "line": 89,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "dropdown-text");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat", ["words.", ["get", "textDecoration.text", ["loc", [null, [88, 55], [88, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [88, 38], [88, 75]]], 0, 0]], [], ["loc", [null, [88, 34], [88, 77]]], 0, 0]],
          locals: ["textDecoration"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 78,
              "column": 0
            },
            "end": {
              "line": 91,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "setting-element-container setting-element-with-padding");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "due-typo-default");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["words.text_decoration"], [], ["loc", [null, [80, 35], [80, 64]]], 0, 0], ["block", "due-select", [], ["value", ["subexpr", "@mut", [["get", "currentDesignOptions.textDecoration", ["loc", [null, [82, 12], [82, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "TEXT_DECORATIONS", ["loc", [null, [83, 14], [83, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "containerSelector", ".secondary-panel", "rightAlign", true], 0, null, ["loc", [null, [81, 4], [89, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 92,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/components/-text.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "displayFontFamily", ["loc", [null, [1, 6], [1, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [15, 7]]]], ["block", "if", [["get", "displayColor", ["loc", [null, [16, 6], [16, 18]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 0], [26, 7]]]], ["block", "if", [["get", "displayFontSize", ["loc", [null, [27, 6], [27, 21]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [27, 0], [38, 7]]]], ["block", "if", [["get", "displayFontWeight", ["loc", [null, [39, 6], [39, 23]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [39, 0], [53, 7]]]], ["block", "if", [["get", "displayLetterSpacing", ["loc", [null, [54, 6], [54, 26]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [54, 0], [65, 7]]]], ["block", "if", [["get", "displayLineHeight", ["loc", [null, [66, 6], [66, 23]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [66, 0], [77, 7]]]], ["block", "if", [["get", "displayTextDecoration", ["loc", [null, [78, 6], [78, 27]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [78, 0], [91, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});