define('due-dashboard/controllers/settings/integrations/shopify', ['exports', 'ember', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var _Ember$computed = _ember['default'].computed;
  var and = _Ember$computed.and;
  var notEmpty = _Ember$computed.notEmpty;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({

    _notEmptyShopifyUrl: notEmpty('model.company_integration.company_shopify_url'),
    _notEmptysurveyConfig: notEmpty('model.company_integration.post_order_survey.id'),
    submitEnabled: and('_notEmptyShopifyUrl', '_notEmptysurveyConfig')

  });
});