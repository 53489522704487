define("due-dashboard/helpers/simple-format", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Helper.helper(function (string) {
    string = _ember["default"].Handlebars.Utils.escapeExpression(string);
    string = string.replace(/\r\n?/g, "\n").trim();
    if (string.length > 0) {
      string = string.replace(/\n\n+/g, '</p><p>');
      string = string.replace(/\n/g, '<br />');
    }
    return new _ember["default"].Handlebars.SafeString("<p>" + string + "</p>");
  });
});