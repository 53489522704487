define('due-dashboard/models/survey-stat', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  var Model = _emberData['default'].Model;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    survey: belongsTo('Survey', { async: true }),

    delivery_rates: _emberDataModelFragments['default'].fragmentArray('action-rate'),
    open_rates: _emberDataModelFragments['default'].fragmentArray('action-rate'),
    click_rates: _emberDataModelFragments['default'].fragmentArray('action-rate'),
    completion_rates: _emberDataModelFragments['default'].fragmentArray('action-rate'),
    feedback_rates: _emberDataModelFragments['default'].fragmentArray('action-rate')
  });
});