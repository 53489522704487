define('due-dashboard/routes/companies/analysis/index', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var service = _ember['default'].inject.service;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    filters: service(),
    model: function model() {
      var filters = this.get('filters').get('queryFilters');
      return {
        tag_analyses: this.store.query('tag-analysis', _extends({ id: 'all' }, filters))
      };
    },

    actions: {}
  });
});