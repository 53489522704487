define('due-dashboard/components/graphs/event-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['graph-event-item'],
    attributeBindings: ['offsetStyle:style'],

    isSelected: computed('current', 'offset', function () {
      return this.get('current') === this.get('offset');
    }),

    offsetStyle: (function () {
      return "left: " + this.get('offset') + 'px';
    }).property('offset'),

    click: function click() {
      this.sendAction('selected', this.get('offset'));
      return false;
    }
  });
});