define('due-dashboard/models/answer', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    value: attr('string'),
    label: attr('string'),
    localised_label: attr('string'),
    localised_value: attr('string'),
    kind: attr('string'),
    max_value: attr('string'),
    new_question_id: attr('string'),
    image_url: attr('string'),
    step_relative_position: attr('number'),
    element_relative_position: attr('number'),

    feedback: belongsTo('Feedback', { async: true })
  });
});