define('due-dashboard/controllers/companies/analysis/index', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Controller.extend({
    filters: service(),
    tagAnalysis: computed.alias('model.tag_analyses.firstObject'),
    session: service(),

    loading: computed('tagAnalysis', function () {
      return !!!this.get('repartition_tags');
    }),

    repartition_tags: computed.alias('tagAnalysis.tags'),
    repartition_all: computed.alias('tagAnalysis.repartition_all'),
    with_tags: computed.alias('tagAnalysis.repartition_with_tags'),
    without_tags: computed.alias('tagAnalysis.repartition_without_tags'),
    without_tags_with_comment: computed.alias('tagAnalysis.repartition_without_tags_with_comment'),
    without_tags_without_comment: computed.alias('tagAnalysis.repartition_without_tags_without_comment'),

    tagAnalysisDownloadLink: computed('tagAnalysis', function () {
      var params = this.get('filters').get('queryFilters');
      var url = _dueDashboardConfigEnvironment['default'].EmberENV.DEFAULT_SCHEME + '://' + _dueDashboardConfigEnvironment['default'].EmberENV.API_HOST + '/api/v3/tag-analyses.csv?';
      url += 'api_key=' + this.get('session.session.authenticated.access_token') + '&';
      return url + $.param(params);
    }),

    actions: {
      prevStep: function prevStep() {
        this.transitionTo(history.back());
      },

      filtersUpdated: function filtersUpdated() {
        var filters = this.get('filters').get('queryFilters');
        this.set('model.tag_analyses', this.store.query('tag-analysis', _extends({ id: 'all' }, filters)));
      }
    }
  });
});