define('due-dashboard/routes/companies/analysis/tags', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var service = _ember['default'].inject.service;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    filters: service(),

    model: function model(params) {
      var tag_id = params.tag_id;
      var filters = this.get('filters').get('queryFilters');
      var deep_filters = this.get('filters').get('queryFilters');
      deep_filters.filter.tag_id = tag_id;
      var vb_filters = this.get('filters').get('queryFilters');
      vb_filters.filter.mandatory_tag = tag_id;

      return {
        // tag-analysis
        tag_id: tag_id,
        tag_analyses: this.store.query('tag-analysis', _extends({ id: tag_id }, filters)),
        // tag-deep-analysis
        tag_deep_analysis: this.store.query('tag-deep-analysis', deep_filters),
        // top keywords + verbatims
        company: this.get('currentAccount.content.company'),
        keywords: this.store.query('keyword', { filter: vb_filters.filter, sort: '-counter', page: { number: 1, size: 5 } }),
        verbatims: this.store.query('verbatim', vb_filters),
        segmentsToDisplay: this.store.query('segment', { filter: { display: 'chat' } }),
        displaySettings: this.store.query('verbatim-display-option', { filter: { account_id: this.currentAccount.content.id } }),
        languages: this.store.query('language', { filter: { account_id: this.currentAccount.content.id } })
      };
    },

    actions: {
      /** TOP-KEYWORDS + VERBATIMS PART */
      didTransition: function didTransition() {
        this.controllerFor('companies.verbatim').checkVerbatimLanguage();
      }
    }
  });
});