define("due-dashboard/initializers/nullable-boolean", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = {

    initialize: function initialize() {
      _emberData["default"].BooleanTransform.reopen({
        // wait for 3.x
        // https://github.com/emberjs/data/blob/v3.1.1/addon/transforms/boolean.js
        deserialize: function deserialize(serialized, options) {
          if (serialized === null && options.allowNull === true) {
            return null;
          }

          var type = typeof serialized;
          if (type === "boolean") {
            return serialized;
          } else if (type === "string") {
            return (/^(true|t|1)$/i.test(serialized)
            );
          } else if (type === "number") {
            return serialized === 1;
          } else {
            return false;
          }
        },

        serialize: function serialize(deserialized, options) {
          if (deserialized === null && options.allowNull === true) {
            return null;
          }

          return Boolean(deserialized);
        }

      });
    }
  };
});