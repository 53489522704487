define('due-dashboard/components/digests/component-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    componentActions: computed('comp', {
      get: function get() {
        return [{ icon: 'tio-copy', action: 'duplicateComp', text: this.get('i18n').t('digest.component.duplicate') }, { icon: 'tio-arrow_upward', action: 'moveCompUp', text: this.get('i18n').t('digest.component.move_up') }, { icon: 'tio-arrow_downward', action: 'moveCompDown', text: this.get('i18n').t('digest.component.move_down') }, { icon: 'tio-delete', action: 'deleteComp', text: this.get('i18n').t('surveys.delete') }];
      }
    }),

    currentTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag') + '.components.' + this.get('comp.id') + '.title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag') + '.components.' + this.get('comp.id') + '.title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    currentSubTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag') + '.components.' + this.get('comp.id') + '.subtitle');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag') + '.components.' + this.get('comp.id') + '.subtitle', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    isNotAButton: computed('comp.type', function () {
      if (this.get('comp.type') == 'redirection_button') {
        return false;
      }
      return true;
    }),

    isNotATopFlop: computed('comp.type', function () {
      return this.get('comp.type') != 'tag_top_flop';
    }),

    analysingYear: computed('period', function () {
      if (this.get('period') && this.get('period').includes('year')) {
        return true;
      }
      return false;
    }),

    actions: {
      triggerAction: function triggerAction(action) {
        this.send(action);
      },

      moveCompUp: function moveCompUp() {
        this.get('moveCompUp')(this.get('comp'));
      },

      moveCompDown: function moveCompDown() {
        this.get('moveCompDown')(this.get('comp'));
      },

      deleteComp: function deleteComp() {
        this.get('deleteComp')(this.get('comp'));
      },

      duplicateComp: function duplicateComp() {
        this.get('duplicateComp')(this.get('comp'));
      }
    }
  });
});