define('due-dashboard/controllers/settings/billing', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    errors: service(),

    Billingfields: ['company_name', 'address', 'zip_code', 'city', 'country', 'recipient'],

    billingSort: ['created_at:desc'],
    sortedBillings: computed.sort('model.billings', 'billingSort'),

    disableButton: computed('model.billing_profile.{company_name,address,zip_code,city,country,recipient,isNew}', function () {
      var _this = this;

      if (this.get('model.billing_profile.isNew')) {
        return !this.get('Billingfields').map(function (a) {
          return _this.get('model.billing_profile.' + a + '.length') || 0;
        }).reduce(function (pv, cv) {
          return pv + cv;
        }, 0);
      }
      return !this.get('model.billing_profile.hasDirtyAttributes');
    }),

    actions: {
      saveBillingProfile: function saveBillingProfile() {
        this.get('model.billing_profile').save();
      }
    }

  });
});