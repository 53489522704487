define('due-dashboard/models/kpi', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    label: attr('string'),
    category_label: attr('string'),
    description: attr('string'),
    translations: attr(),

    localisedLabel: computed('i18n.locale', 'translations', 'label', function () {
      return this.get('translations.label.' + this.get('i18n').locale.toUpperCase()) || this.get('label');
    })
  });
});