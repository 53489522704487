define('due-dashboard/components/answer-icon', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    option: computed('kind', function () {
      var options = {
        'rating': { color: '#FF6B6B', icon: 'star' },
        'single_select': { color: '#339AF0', icon: 'arrow_drop_down_circle' },
        'multiple_select': { color: '#339AF0', icon: 'arrow_drop_down_circle' },
        'long_text_input': { color: '#22B8CF', icon: 'comment_text' },
        'short_text_input': { color: '#22B8CF', icon: 'comment_text' }
      };
      return options[this.get('kind')];
    })

  });
});