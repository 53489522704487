define('due-dashboard/components/reports/-redirect-button', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['redirect-button'],

    currentAccount: service(),

    canSeeButton: true,

    init: function init() {
      this._super();
      this._checkUserRights();
      var content = this.get('content');

      this.set('text', content.component.config.translations[this.get('currentLanguage')].text);
    },

    _checkUserRights: function _checkUserRights() {
      var profile = this.get('currentAccount.content.profile'),
          rights = this.get('currentAccount.rights'),
          props = this.get('content.component.properties'),
          hasReportCustomRight = this.get('currentAccount.hasReportCustomRight'),
          reportRightsList = this.get('currentAccount.reportRightsList');

      if (profile !== 'custom' || props.external_url) {
        return;
      }

      // Native pages rights
      var page = this.get('content.component.properties.route').split('.')[1];
      var param = this.get('content.component.properties.param');
      var right = rights.get(page + '_rights');
      if (right === 'none') {
        this.set('canSeeButton', false);
      }

      // Report pages rights
      if (page === 'report' && hasReportCustomRight && !reportRightsList.includes(param)) {
        this.set('canSeeButton', false);
      }
    },

    target: computed('content', function () {
      var content = this.get('content');
      var target = '';

      if (content.component.properties.open_in_new_tab) {
        target = '_blank';
      }

      return target;
    }),

    redirectUrl: computed('content', function () {
      var content = this.get('content');

      return content.component.properties.url;
    }),

    actions: {}
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{reports/-redirect-button}} redirect button with text + url
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */