define('due-dashboard/components/tags/add-language', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: '',
    modalIsOpened: false,
    selectedLanguage: null,
    disabled: computed.empty('selectedLanguage'),

    closeModal: function closeModal() {
      this.set('selectedLanguage', null);
      this.set('modalIsOpened', false);
    },

    actions: {
      openModal: function openModal() {
        this.set('modalIsOpened', true);
      },

      setLanguage: function setLanguage(language) {
        this.set('selectedLanguage', language);
      },

      addLanguage: function addLanguage() {
        this.get('addLanguageAction')(this.get('selectedLanguage'));
        this.closeModal();
      },

      closeModal: function closeModal() {
        this.closeModal();
      }
    }
  });
});