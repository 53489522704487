define('due-dashboard/routes/settings/management-question-category/index', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    model: function model() {
      return this.store.findAll('management-question-category');
    },

    actions: {
      willTransition: function willTransition() {
        this.controllerFor('settings.management-question-category.index').send('saveCategories');
        return true;
      },

      newCategory: function newCategory() {
        var category_id = null;
        this.transitionTo('settings.management-question-category.edit', this.store.createRecord('management-question-category'), { queryParams: { category_id: category_id } });
      },

      modifyCategory: function modifyCategory(category) {
        this.transitionTo('settings.management-question-category.edit', this.store.findRecord('management-question-category', category));
      }
    }
  });
});