define('due-dashboard/components/field-validation-display-base', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    layoutName: 'components/field-validation-display',

    qsqHasErrors: computed.alias('question.question.errors.questions_text.length'),
    notNew: computed.not('model.isNew'),
    saved: computed.oneWay('notNew'),
    displayed: computed.oneWay('saved'),
    valid: computed.not('error'),
    question: null,

    watchState: observer('model.currentState.stateName', function () {
      var _this = this;
      var state = this.get('model.currentState.stateName');
      // Maybe we could replace this by observing becameInvalid, didCreate and didUpdate
      if (state && state.match(/^root\.loaded\.(?:(?:created|updated)\.invalid|saved)$/)) {
        var field = this.get('field');
        var relationship = this.get('relationship');
        var fieldKey = 'model.errors.' + relationship + '/' + field;
        run.next(function () {
          if (_this.isDestroyed) {
            return;
          }
          var e = _this.get(fieldKey);
          _this.set('error', e);
          _this.set('saved', true);
        });
      }
    })

  });
});