define('due-dashboard/components/keyword-adjustments/new-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    adjustmentsModalIsOpened: false,
    keyword: null,
    aliasWord: null,
    keywordBan: true,
    keywordExists: false,
    adjustmentOptions: [{ key: 'ban', value: 'ban' }, { key: 'alias', value: 'alias' }],

    adjustmentOption: computed('adjustmentOption', {
      get: function get() {
        return this.get('adjustmentOptions.firstObject');
      }, set: function set(_, value) {
        this.set('keywordBan', value.key === 'ban');
        return value;
      }
    }),

    disabledSave: computed('keyword', 'aliasWord', 'keywordBan', function () {
      return !this.get('keywordBan') && (!this.get('keyword') || !this.get('aliasWord')) || this.get('keywordBan') && !this.get('keyword');
    }),

    closeAdjustmentModal: function closeAdjustmentModal() {
      this.set('keyword', null);
      this.set('aliasWord', null);
      this.set('keywordBan', true);
      this.set('keywordExists', false);
      this.set('adjustmentOption', this.get('adjustmentOptions.firstObject'));
      this.set('adjustmentsModalIsOpened', false);
    },

    actions: {
      openAdjustmentsModal: function openAdjustmentsModal() {
        this.set('adjustmentsModalIsOpened', true);
        this.set('keywordExists', false);
      },

      closeAdjustmentsModal: function closeAdjustmentsModal() {
        this.closeAdjustmentModal();
      },

      saveAdjustment: function saveAdjustment() {
        var _this = this;

        var keyword = this.get('keyword');
        var to_keyword = this.get('aliasWord');
        this.get('store').findAll('keyword-adjustment').then(function (adjustment) {
          adjustment.forEach(function (a) {
            if (a.get('keyword') === keyword) {
              _this.set('keywordExists', true);
              return;
            }
          });
          // create adjustement pair if it does not exist
          if (!_this.get('keywordExists')) {
            _this.get('store').createRecord('keyword-adjustment', {
              keyword: keyword,
              to_keyword: to_keyword
            }).save().then(function (addedAdjustment) {
              _this.get('updateAdjustmentsListView')(addedAdjustment);
              _this.closeAdjustmentModal();
            });
          }
        });
      }
    }
  });
});