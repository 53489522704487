define('due-dashboard/components/v2/libraries/surveys/navbar/design/-overview', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['surveys-library-navbar-design-overview'],

    elements: [{ name: 'logo', icon: 'panorama_image', component: 'logo-design' }, { name: 'background', icon: 'paint_outlined', component: 'background-design' }, { name: 'card', icon: 'layers_outlined', component: 'card-design' }, { name: 'title', icon: 'small_caps', component: 'title-design' }, { name: 'text', icon: 'text', component: 'text-design' }, { name: 'label', icon: 'text', component: 'label-design' }, { name: 'legend', icon: 'text', component: 'legend-design' }, { name: 'single_choice', icon: 'checkmark_circle_outlined', component: 'single-select-design' }, { name: 'multiple_choice', icon: 'checkmark_square_outlined', component: 'multiple-select-design' }, { name: 'short_text', icon: 'comment_text_outlined', component: 'short-text-design' }, { name: 'long_text', icon: 'comment_text', component: 'long-text-design' }, { name: 'previous_button', icon: 'swap_horizontal', component: 'previous-button-design' }, { name: 'next_button', icon: 'swap_horizontal', component: 'next-button-design' }, { name: 'primary_redirect_button', icon: 'link', component: 'primary-redirect-button-design' }, { name: 'secondary_redirect_button', icon: 'link', component: 'secondary-redirect-button-design' }, { name: 'footer', icon: 'text', component: 'footer-design' }, { name: 'accessibility', icon: 'globe', component: '-accessibility' }],

    actions: {
      onClickBackButton: function onClickBackButton() {
        var backToIndex = this.get('backToIndexCallback');
        if (backToIndex) {
          backToIndex();
        }
      },

      selectCurrentElement: function selectCurrentElement(currentElement) {
        this.sendAction('selectCurrentElement', currentElement);
      }
    }
  });
});