define('due-dashboard/components/send-survey', ['exports', 'ember', 'npm:@ngouy/easy-template'], function (exports, _ember, _npmNgouyEasyTemplate) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    store: _ember['default'].inject.service(),

    survey: "",
    type: "",
    selectedLine: _ember['default'].computed.oneWay('model.bulk_dispatch.csv_body.firstObject'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.send('setContent', this.get('model.bulk_dispatch.csv_body.firstObject'));
    },

    actions: {

      setContent: function setContent(line) {
        var language = undefined;
        var contents = this.get('model.template.text_contents');
        var html_contents = this.get('model.template.html_contents');
        [(line.language || '').toUpperCase(), this.get('i18n.locale').toUpperCase(), this.get('currentAccount.content.language.tag'), this.get('currentAccount.content.company.language.tag')].forEach(function (tag) {
          language = language || contents.findBy('language.tag', tag);
        });
        language = (language || contents.get('firstObject')).get('language');
        var so = this.get('model.template.dynamic_attributes.sending_objects').findBy('language.tag', language.get('tag'));
        var _line = {};
        Object.entries(line).forEach(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];
          return _line['segment:' + key] = value;
        });
        var selectedContent = {
          content: _npmNgouyEasyTemplate['default'].process(contents.findBy('language.tag', language.get('tag')).get('content'), _line),
          language: language,
          sending_object: so && _npmNgouyEasyTemplate['default'].process(so.get('content'), _line) || ""
        };
        if (html_contents.get('length') > 0 && html_contents.findBy('language.tag', language.get('tag'))) {
          selectedContent.html_content = _npmNgouyEasyTemplate['default'].process(html_contents.findBy('language.tag', language.get('tag')).get('content'), _line);
        }
        this.set('selectedContent', selectedContent);
        this.set('selectedLine', line);
      },

      sendSurvey: function sendSurvey() {
        this.sendAction('sendSurvey');
      }
    }

  });
});