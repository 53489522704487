define('due-dashboard/components/year-picker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['due-year-picker'],

    minDate: null,
    maxDate: null,

    date: null,

    pickingMode: 'year',

    initialDecade: computed('date', function () {
      var date = null;
      if (this.get('date')) {
        date = (0, _moment['default'])(this.get('date'));
      } else {
        date = (0, _moment['default'])();
      }
      date = date.format('YYYY');
      return date.slice(0, -1) + '0';
    }),

    selectedDecade: computed('initialDecade', 'newDecade', function () {
      return this.get('newDecade') ? this.get('newDecade') : this.get('initialDecade');
    }),

    endOfSelectedDecade: computed('selectedDecade', function () {
      return this.get('selectedDecade').slice(0, -1) + '9';
    }),

    years: computed('minDate', 'maxDate', 'selectedDecade', 'selectedYear', function () {
      var years = [];
      var year_base = Number(this.get('selectedDecade').slice(0, -1) + '0') - 1;
      for (var current_year = year_base; current_year < year_base + 12; current_year++) {
        var year_name = current_year.toString();
        var year_date = (0, _moment['default'])(year_name, 'YYYY');
        years.push({
          date: year_date.startOf('year'),
          name: year_name,
          selected: year_name === this.get('selectedYear'),
          disabled: year_date.endOf('year').diff(this.get('minDate')) < 0 || year_date.startOf('year').diff(this.get('maxDate')) > 0
        });
      }
      return years;
    }),

    selectedYear: computed('date', function () {
      if (this.get('date')) {
        return (0, _moment['default'])(this.get('date')).format('YYYY');
      }
    }),

    yearsRange: computed('minYear', 'maxYear', function () {
      var start_year = this.get('minYear');
      var years = [];
      while (start_year.diff(this.get('maxYear')) < 0) {
        years.push(start_year.format('YYYY'));
        start_year.add(1, 'year');
      }
      return years;
    }),

    actions: {
      pickYear: function pickYear(year) {
        var picked_year = year.startOf('year').toDate();
        if (this.get('yearPicked')) {
          this.get('yearPicked')(picked_year);
        }
      },

      decadePicked: function decadePicked(newDecadeStartDate) {
        this.set('newDecade', '' + newDecadeStartDate);
        this.set('pickingMode', 'year');
      },

      decadeView: function decadeView() {
        this.set('pickingMode', 'decade');
      }
    }
  });
});