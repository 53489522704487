define("due-dashboard/templates/components/due-table/due-table-empty", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 8
            },
            "end": {
              "line": 9,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "header-text");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["header-column ", ["get", "header.type", ["loc", [null, [6, 38], [6, 49]]], 0, 0, 0, 0], "-column"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "component", [["subexpr", "concat", [["subexpr", "concat", ["loading-skeleton/due-", ["get", "header.component", ["loc", [null, [7, 90], [7, 106]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 58], [7, 107]]], 0, 0], "-skeleton"], [], ["loc", [null, [7, 50], [7, 120]]], 0, 0]], ["size", "small", "width", ["subexpr", "@mut", [["get", "header.width", ["loc", [null, [7, 140], [7, 152]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [7, 164], [7, 171]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 38], [7, 173]]], 0, 0]],
        locals: ["header"],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 16,
                    "column": 16
                  },
                  "end": {
                    "line": 20,
                    "column": 16
                  }
                },
                "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element4, 'class');
                morphs[1] = dom.createMorphAt(element4, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["element-container ", ["get", "elem.type", ["loc", [null, [17, 50], [17, 59]]], 0, 0, 0, 0], "-column _", ["get", "elem.index", ["loc", [null, [17, 72], [17, 82]]], 0, 0, 0, 0], " left"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "component", [["subexpr", "concat", [["subexpr", "concat", ["loading-skeleton/due-", ["get", "elem.component", ["loc", [null, [18, 72], [18, 86]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 40], [18, 87]]], 0, 0], "-skeleton"], [], ["loc", [null, [18, 32], [18, 100]]], 0, 0]], ["size", "small", "width", ["subexpr", "@mut", [["get", "elem.width", ["loc", [null, [18, 120], [18, 130]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [18, 142], [18, 149]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [18, 20], [18, 151]]], 0, 0]],
              locals: ["elem"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 12
                },
                "end": {
                  "line": 22,
                  "column": 12
                }
              },
              "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "line-container");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "lineElems", ["loc", [null, [16, 24], [16, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [16, 16], [20, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 10
              },
              "end": {
                "line": 23,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["subexpr", "and", [["subexpr", "or", [["subexpr", "eq", [["get", "layout", ["loc", [null, [14, 35], [14, 41]]], 0, 0, 0, 0], "layout_2-3_2_2"], [], ["loc", [null, [14, 31], [14, 59]]], 0, 0], ["subexpr", "eq", [["get", "layout", ["loc", [null, [14, 64], [14, 70]]], 0, 0, 0, 0], "layout_1-3_1_1"], [], ["loc", [null, [14, 60], [14, 88]]], 0, 0]], [], ["loc", [null, [14, 27], [14, 89]]], 0, 0], ["subexpr", "gt", [["get", "index", ["loc", [null, [14, 94], [14, 99]]], 0, 0, 0, 0], 2], [], ["loc", [null, [14, 90], [14, 102]]], 0, 0]], [], ["loc", [null, [14, 22], [14, 103]]], 0, 0]], [], 0, null, ["loc", [null, [14, 12], [22, 23]]]]],
          locals: ["lineElems", "index"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 8
            },
            "end": {
              "line": 24,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "emptyPage.leftSideContent", ["loc", [null, [13, 18], [13, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 10], [23, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 8
            },
            "end": {
              "line": 29,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "empty-line");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "empty-text");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "empty-subtext");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["components.reports.array.empty.text"], [], ["loc", [null, [26, 34], [26, 77]]], 0, 0], ["inline", "t", ["components.reports.array.empty.subtext"], [], ["loc", [null, [27, 37], [27, 83]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 8
            },
            "end": {
              "line": 39,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "header-text");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["header-column ", ["get", "header.type", ["loc", [null, [36, 38], [36, 49]]], 0, 0, 0, 0], "-column"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "component", [["subexpr", "concat", [["subexpr", "concat", ["loading-skeleton/due-", ["get", "header.component", ["loc", [null, [37, 90], [37, 106]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 58], [37, 107]]], 0, 0], "-skeleton"], [], ["loc", [null, [37, 50], [37, 120]]], 0, 0]], ["size", "small", "width", ["subexpr", "@mut", [["get", "header.width", ["loc", [null, [37, 140], [37, 152]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [37, 164], [37, 171]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [37, 38], [37, 173]]], 0, 0]],
        locals: ["header"],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 14
                },
                "end": {
                  "line": 49,
                  "column": 14
                }
              },
              "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createMorphAt(element0, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["element-container ", ["get", "elem.type", ["loc", [null, [46, 48], [46, 57]]], 0, 0, 0, 0], "-column _", ["get", "elem.index", ["loc", [null, [46, 70], [46, 80]]], 0, 0, 0, 0], " right"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "component", [["subexpr", "concat", [["subexpr", "concat", ["loading-skeleton/due-", ["get", "elem.component", ["loc", [null, [47, 70], [47, 84]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 38], [47, 85]]], 0, 0], "-skeleton"], [], ["loc", [null, [47, 30], [47, 98]]], 0, 0]], ["size", "small", "width", ["subexpr", "@mut", [["get", "elem.width", ["loc", [null, [47, 118], [47, 128]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [47, 140], [47, 147]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [47, 18], [47, 149]]], 0, 0]],
            locals: ["elem"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 10
              },
              "end": {
                "line": 51,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(element1, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["line-container ", ["subexpr", "if", [["get", "isEmpty", ["loc", [null, [44, 44], [44, 51]]], 0, 0, 0, 0], "is-empty"], [], ["loc", [null, [44, 39], [44, 64]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "lineElems", ["loc", [null, [45, 22], [45, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [45, 14], [49, 23]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 8
            },
            "end": {
              "line": 52,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["subexpr", "and", [["subexpr", "or", [["subexpr", "eq", [["get", "layout", ["loc", [null, [43, 33], [43, 39]]], 0, 0, 0, 0], "layout_2-3_2_2"], [], ["loc", [null, [43, 29], [43, 57]]], 0, 0], ["subexpr", "eq", [["get", "layout", ["loc", [null, [43, 62], [43, 68]]], 0, 0, 0, 0], "layout_1-3_1_1"], [], ["loc", [null, [43, 58], [43, 86]]], 0, 0]], [], ["loc", [null, [43, 25], [43, 87]]], 0, 0], ["subexpr", "gt", [["get", "index", ["loc", [null, [43, 92], [43, 97]]], 0, 0, 0, 0], 2], [], ["loc", [null, [43, 88], [43, 100]]], 0, 0]], [], ["loc", [null, [43, 20], [43, 101]]], 0, 0]], [], 0, null, ["loc", [null, [43, 10], [51, 21]]]]],
        locals: ["lineElems", "index"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 57,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/due-table/due-table-empty.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "array-footer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "array-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "left-side-container");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "header-container");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "right-side-container");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "header-container");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "content-container");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0, 1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [3]);
        var element9 = dom.childAt(element6, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 1, 1);
        morphs[1] = dom.createAttrMorph(element8, 'class');
        morphs[2] = dom.createMorphAt(element8, 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "emptyHeader.leftSideHeader", ["loc", [null, [5, 16], [5, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 8], [9, 17]]]], ["attribute", "class", ["concat", ["content-container ", ["subexpr", "if", [["get", "isEmpty", ["loc", [null, [11, 41], [11, 48]]], 0, 0, 0, 0], "is-empty"], [], ["loc", [null, [11, 36], [11, 61]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "isLoading", ["loc", [null, [12, 14], [12, 23]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [12, 8], [29, 15]]]], ["block", "each", [["get", "emptyHeader.rightSideHeader", ["loc", [null, [35, 16], [35, 43]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [35, 8], [39, 17]]]], ["block", "each", [["get", "emptyPage.rightSideContent", ["loc", [null, [42, 16], [42, 42]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [42, 8], [52, 17]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});