define('due-dashboard/components/match-profiles-scenario-condition', ['exports', 'ember', 'due-dashboard/components/scenario-condition-item'], function (exports, _ember, _dueDashboardComponentsScenarioConditionItem) {
  var computed = _ember['default'].computed;
  var setDiff = computed.setDiff;
  exports['default'] = _dueDashboardComponentsScenarioConditionItem['default'].extend({
    profiles: ['detractor', 'passive', 'promoter'],

    profilesLabel: {
      detractor: "components.match-profiles-scenario-condition.detractor",
      passive: "components.match-profiles-scenario-condition.passive",
      promoter: "components.match-profiles-scenario-condition.promoter"
    },

    remainingProfiles: setDiff('profiles', 'condition.profiles'),

    actions: {
      selectProfiles: function selectProfiles(profiles) {
        this.get('condition').updateProfiles(profiles);
      }
    }
  });
});