define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/logo-design', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['setting-element-container'],
    name: 'logo',
    image: null,

    currentDesignOptions: computed('isLoading', 'designOptions', function () {
      var noLogo = this.get('designOptions.no_logo');
      return {
        image: this.get('surveyDraft.logo_image'),
        displayLogo: noLogo !== undefined ? !noLogo : true
      };
    }),

    actions: {
      setLogoImage: function setLogoImage(image) {
        this.set('currentDesignOptions.image', image);
        this.sendAction('updateSurveyDraft', 'logo_image', image);
      },

      toggleDisplayLogo: function toggleDisplayLogo() {
        this.toggleProperty('currentDesignOptions.displayLogo');
        this.sendAction('updateDesignOptions', { no_logo: !this.get('currentDesignOptions.displayLogo') });
      }
    }
  });
});