define('due-dashboard/components/digests/closed-question', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    orderOptions: ['best', 'worst', 'count', 'position'],
    scoreOptions: ['avg', 'avg_pos'],

    currentQuestionTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.question_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.question_column_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    currentScoreTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.score_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.score_column_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    currentChangeTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.change_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.change_column_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    currentCounterTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.counter_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.counter_column_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    })

  });
});