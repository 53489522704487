define('due-dashboard/routes/companies/surveys/edit/summary', ['exports', 'ember', 'due-dashboard/mixins/survey-step'], function (exports, _ember, _dueDashboardMixinsSurveyStep) {
  exports['default'] = _ember['default'].Route.extend(_dueDashboardMixinsSurveyStep['default'], {
    model: function model() {
      return _ember['default'].RSVP.hash({
        survey_draft: this.modelFor('companies.surveys.edit').surveyDraft
      });
    },
    actions: {
      finalizeRevision: function finalizeRevision() {
        var _this = this;

        if (!this.currentModel.survey_draft.get('isSaving')) {
          this.currentModel.survey_draft.finalize().then(function (survey_draft) {
            _this.store.find('survey', survey_draft.id).then(function (survey) {
              survey.reload().then(function () {
                return _this.transitionTo('companies.surveys');
              });
            });
          });
        }
      }
    }
  });
});