define('due-dashboard/components/change-end-image-scenario-action', ['exports', 'due-dashboard/components/scenario-action-item', 'ember'], function (exports, _dueDashboardComponentsScenarioActionItem, _ember) {
  exports['default'] = _dueDashboardComponentsScenarioActionItem['default'].extend({
    action: null,
    clientBackgroundError: false,

    error_keys: _ember['default'].computed(function () {
      return ['image_error_key'];
    }),

    errorObserverVars: {
      single_error_key: 'image_error_key',
      single_error_target: 'action.validations.attrs.image.error'
    },

    actions: {
      handleLoad: function handleLoad() {
        var to_remove = this.$('.error-desc')[0];
        var noks = this.$('.nok');
        if (to_remove && to_remove.remove) {
          to_remove.remove();
        }
        noks.each(function (_, nok) {
          nok.classList.remove('nok');
        });
        this.set('error', false);
        this.get('errors').updateErrorByKey(this.get('errorObserverVars').single_error_key, null);
      }
    }

  });
});