define('due-dashboard/components/choice-item', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'li',

    localizedQuestion: computed('i18n.locale', 'question', function () {
      return this.get('question.id') && _emberData['default'].PromiseObject.create({
        promise: this.get('store').query('question', { filter: {
            optimum_locale: this.get('i18n.locale'),
            answered_question: this.get('question.id')
          } })
      });
    })

  });
});