define("due-dashboard/templates/components/due-table/navigation-button", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/due-table/navigation-button.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "due-btn", [], ["size", "small", "intent", "secondary", "action", ["subexpr", "action", ["transitionToTarget"], [], ["loc", [null, [4, 9], [4, 38]]], 0, 0], "text", ["subexpr", "@mut", [["get", "text", ["loc", [null, [5, 7], [5, 11]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [6, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});