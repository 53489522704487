define('due-dashboard/components/v2/libraries/surveys/navbar/elements/-rating', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content-element-rating'],
    cachedValues: {},

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var content = this.get('object.content');
      Object.keys(content).map(function (key) {
        return _this.cachedValues[key] = content[key];
      });
      this.store.findRecord('rating_scale', this.get('object.content').rating_scale_id, { reload: true }).then(function (rating_scale) {
        _this.set('kind', rating_scale.get('kind'));
        _this.set('na_design', rating_scale.get('na_design') != null);
      });
      this.updateAttrs();
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.updateAttrs();
    },

    updateAttrs: function updateAttrs() {
      var ratingScaleId = this.get('object.content.rating_scale_id');
      var ratingScales = this.get('group.ratingScalesList');
      var ratingScale = ratingScales.filter(function (rs) {
        return rs.id === ratingScaleId;
      })[0];
      this.set('ratingScale', ratingScale);
    },

    hasDefaultValueObj: computed('object.content.default_value', function () {
      var content = this.get('object.content');

      return content.default_value != null;
    }),

    ratingScales: computed('object.content', 'group.ratingScalesList', function () {
      var _this2 = this;

      var ratingScales = this.get('group.ratingScalesList');
      if (null != this.get('object.new_question_id')) {
        (function () {
          var compRatingScales = [];
          var scale = _this2.get('ratingScale').get('ratings').map(function (r) {
            return r.get('score');
          }).toString();
          ratingScales.forEach(function (r) {
            return r.get('ratings').map(function (r) {
              return r.get('score');
            }).toString() == scale && compRatingScales.push(r);
          });
          ratingScales = compRatingScales;
        })();
      }

      var index = ratingScales.findIndex(function (r) {
        return r.get('default_rating_scale');
      });
      var default_rating_scale = index >= 0 ? ratingScales.splice(index, 1)[0] : null;
      ratingScales.sort(function (a, b) {
        return a.get('name').localeCompare(b.get('name'));
      });
      if (default_rating_scale) ratingScales.splice(0, 0, default_rating_scale);
      return ratingScales;
    }),

    actions: {
      onChangeContentField: function onChangeContentField() {
        var saveCallback = this.get('contentUpdateCallback');
        var content = this.get('object.content');

        if (saveCallback) {
          saveCallback(content);
        }
      },

      toggleContentField: function toggleContentField(field) {
        var content = this.get('object.content');

        set(content, field, !content[field]);
        debounce(this, this.send, 'onChangeContentField', 600);
      },

      updateContentField: function updateContentField(field, value) {
        var content = this.get('object.content');
        var focus = this.get('focus');

        if (!focus && value !== -1) this.set('focus', true);
        if (value !== -1) {
          var valueChanged = this.cachedValues[field] && !(this.cachedValues[field] === value);
          this.cachedValues[field] = value;
          set(content, field, value);
          if (valueChanged) this.send('onChangeContentField');
        }
        if (value === -1 && this.get('focus')) this.set('focus', false);
      },

      updateDefaultValue: function updateDefaultValue() {
        var content = this.get('object.content');
        var hasDefaultValueObj = this.get('hasDefaultValueObj');
        var defaultValue = { from: 'url_param', url_param: '' };

        if (hasDefaultValueObj) {
          set(content, 'default_value', null);
        } else {
          set(content, 'default_value', defaultValue);
        }

        this.send('onChangeContentField');
      },

      changeRatingScale: function changeRatingScale(ratingScale) {
        var content = this.get('object.content');
        set(content, 'rating_scale_id', ratingScale.id);
        this.send('onChangeContentField');
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/libraries/surveys/navbar/elements/-rating}} rating element config component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */