define('due-dashboard/components/graphs/edit-view', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['graph', 'edit-view'],

    defaultLineColors: ['#f48fb1', '#90caf9', '#a5d6a7', '#ffe082', '#ce93d8', '#fff59d', '#ffab91', '#ef9a9a', '#ffc080', '#b0bec5'],

    usedColors: computed.mapBy('graph.lines', 'color').volatile(),
    availableColors: computed.setDiff('defaultLineColors', 'usedColors').volatile(),

    didReceiveAttrs: function didReceiveAttrs() {
      var graph = this.get('graph'),
          lines = graph.get('lines');

      if (graph.get('isNew') && lines.get('length') === 0) {
        this.addLine();
      }
      return this._super.apply(this, arguments);
    },

    addLine: function addLine() {
      var lines = this.get('graph.lines'),
          i18n = this.get('i18n'),
          prefix = i18n.t('graphs.lines.default_name_prefix');

      lines.createFragment({
        type: 'nps',
        name: prefix + ' ' + (lines.get('length') + 1),
        color: this.get('availableColors.firstObject') || '#000000'
      });
    },

    actions: {
      deleteLine: function deleteLine(line) {
        this.get('graph.lines').removeObject(line);
      },

      newLine: function newLine() {
        this.addLine();
      },

      reorderLines: function reorderLines(oldLines, orderedLines) {
        if (!oldLines.length) return;
        this.set('graph.lines', orderedLines.toArray());
      }
    }

  });
});