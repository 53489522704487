define('due-dashboard/components/digests/parts/dl-custom-stats-part', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    allStats: [],
    remainingStats: computed.setDiff('allStats', 'currentStat'),

    init: function init() {
      var _this = this;

      this.store.query('custom-stat', { page: { number: 1, size: 60 } }).then(function (stats) {
        _this.set('allStats', stats);
        if (!_this.get('statId').length && stats.content.length > 0) {
          _this.set('statId', stats.content[0].id);
        }
      });
      this._super.apply(this, arguments);
    },

    currentStat: computed('statId', 'allStats', {
      get: function get() {
        var stat = this.get('statId');
        if (!stat) {
          return null;
        }
        return this.get('allStats').toArray().filter(function (a) {
          return stat == a.id;
        })[0];
      }, set: function set(_, stat) {
        this.set('statId', stat.id);
        this.get('saveDigestDelay')();
        return stat;
      }
    }),

    _searchStats: function _searchStats(term, resolve, reject) {
      this.store.query('custom-stat', {
        filter: {
          name: term
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (stats) {
        return resolve(stats);
      }, reject);
    },

    actions: {
      mutStat: function mutStat(stat) {
        this.set('currentStat', stat);
      },

      searchStats: function searchStats(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          run.debounce(_this2, _this2._searchStats, term, resolve, reject, 600);
        });
      }
    }

  });
});