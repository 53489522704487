define('due-dashboard/helpers/default-survey-v2-template', ['exports', 'ember'], function (exports, _ember) {
  exports.defaultSurveyV2Template = defaultSurveyV2Template;

  function defaultSurveyV2Template(defaultRatingScaleId, translations, tag) {
    var template = defaultTemplate;

    template.forEach(function (step, stepIdx) {
      step.uuid = crypto.randomUUID();
      step.conditions.forEach(function (condition) {
        condition.uuid = crypto.randomUUID();
      });
      step.survey_elements_attributes.forEach(function (element, elementIdx) {
        element.uuid = crypto.randomUUID();

        if (element.kind === 'rating') element.content.rating_scale_id = defaultRatingScaleId;

        element.conditions.forEach(function (condition) {
          condition.uuid = crypto.randomUUID();
        });

        var elementTranslations = translations[tag.toLowerCase()].elements[stepIdx][elementIdx];
        if (elementTranslations) {
          var translationKeys = Object.keys(elementTranslations);
          translationKeys.forEach(function (translationElementKey) {
            element.content[translationElementKey] = elementTranslations[translationElementKey];
          });
        }
      });
    });

    return { survey_steps_attributes: template };
  }

  exports['default'] = _ember['default'].Helper.helper(defaultSurveyV2Template);

  var defaultTemplate = [{
    "kind": "content",
    "name": "Recommandation",
    "uuid": "",
    "position": 0,
    "conditions": [],
    "survey_elements_attributes": [
    // 0
    {
      "kind": "title",
      "name": "Title",
      "uuid": "",
      "content": {
        "text": "It has been a pleasure to welcome you!"
      },
      "position": 0,
      "conditions": []
    },
    // 1
    {
      "kind": "text",
      "name": "Text",
      "uuid": "",
      "content": {
        "text": "Following your recent visit, we would like to learn more about your experience."
      },
      "position": 1,
      "conditions": []
    },
    // 2
    {
      "kind": "rating",
      "name": "Rating - NPS Question",
      "uuid": "",
      "content": {
        "na_label": "N/A",
        "label": "Would you recommend {company_name} to your friends and family?",
        "style": {
          "rating_legend": "rating-legend-inline",
          "rating_legend_top": "rating-hidden"
        },
        "legend": {
          "low_score": "0 - Absolutely not",
          "high_score": "10 - Yes, absolutely"
        },
        "mandatory": true,
        "main_rating": true,
        "rating_scale_id": ""
      },
      "position": 2,
      "conditions": []
    },
    // 3
    {
      "kind": "long_text_input",
      "name": "Long text input",
      "uuid": "",
      "content": {
        "label": "Share your experience with us",
        "placeholder": "Write here",
        "main_comment": true
      },
      "position": 4,
      "conditions": []
    },
    // 4
    {
      "kind": "prev_next_btns",
      "name": "Navigation button",
      "uuid": "",
      "content": {
        "next_button_text": "Next"
      },
      "position": 5,
      "conditions": []
    }, {
      "kind": "soft_complete",
      "name": "Checkpoint",
      "uuid": "",
      "content": {},
      "position": 3,
      "conditions": [{
        "data": {
          "answer_in": ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          "answer_step": 0,
          "answer_element": 2
        },
        "kind": "answer_in",
        "uuid": "",
        "has_been_cmptd": false
      }]
    }]
  }, {
    "kind": "content",
    "name": "Closed Questions",
    "uuid": "",
    "position": 1,
    "conditions": [],
    "survey_elements_attributes": [
    // 0
    {
      "kind": "title",
      "name": "Title",
      "uuid": "",
      "content": {
        "text": "More about your most recent experience"
      },
      "position": 0,
      "conditions": []
    },
    // 1
    {
      "kind": "text",
      "name": "Text",
      "uuid": "",
      "content": {
        "text": "Please rate the following items"
      },
      "position": 1,
      "conditions": []
    },
    // 2
    {
      "kind": "rating",
      "name": "Client Advisor",
      "uuid": "",
      "content": {
        "na_label": "N/A",
        "label": "The client advisor was knowledgeable",
        "style": {
          "rating_legend": "rating-legend-inline",
          "rating_legend_top": "rating-hidden"
        },
        "legend": {
          "low_score": "0 - Strongly disagree",
          "high_score": "10 - Strongly agree"
        },
        "skipable": true,
        "rating_scale_id": ""
      },
      "position": 2,
      "conditions": []
    },
    // 3
    {
      "kind": "rating",
      "name": "Store Experience",
      "uuid": "",
      "content": {
        "na_label": "N/A",
        "label": "I had a unique luxury store experience",
        "style": {
          "rating_legend": "rating-legend-inline",
          "rating_legend_top": "rating-hidden"
        },
        "legend": {
          "low_score": "0 - Strongly disagree",
          "high_score": "10 - Strongly agree"
        },
        "skipable": true,
        "rating_scale_id": ""
      },
      "position": 3,
      "conditions": []
    },
    // 4
    {
      "kind": "prev_next_btns",
      "name": "Navigation",
      "uuid": "",
      "content": {
        "next_button_text": "Submit",
        "previous_button_text": "Previous"
      },
      "position": 4,
      "conditions": []
    }]
  }, {
    "kind": "hard_complete",
    "name": "Hard complete",
    "uuid": "",
    "position": 2,
    "conditions": [],
    "survey_elements_attributes": []
  }, {
    "kind": "content",
    "name": "Thank you page",
    "uuid": "",
    "position": 3,
    "conditions": [],
    "survey_elements_attributes": [{
      "kind": "title",
      "name": "Title",
      "uuid": "",
      "content": {
        "text": "Thank you we appreciate your feedback"
      },
      "position": 0,
      "conditions": []
    }, {
      "kind": "text",
      "name": "Text",
      "uuid": "",
      "content": {
        "text": "We look forward to see you again in our stores!"
      },
      "position": 1,
      "conditions": []
    }]
  }];
});