define('due-dashboard/services/filters', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Service.extend({

    startDate: computed.readOnly('_current.completed_at.start'),
    endDate: computed.readOnly('_current.completed_at.end'),

    queryFilters: computed('_current', function () {
      return { filter: this.get('_current').toAPIFormat() };
    }).readOnly().volatile(),

    // private:

    _global: service('feedback-search/global'),
    _current: computed.readOnly('_global.current')
  });
});