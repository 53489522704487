define('due-dashboard/components/due-select', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  var guidFor = _ember['default'].guidFor;

  /**
   * @author Romain GAY <romain@diduenjoy.com>
   * @summary {{due-select}} select component
   *
   * @module
   * @argument {array} options - array of objects/strings to display in the select
   * @argument {string} value - value attribute to change
   * @argument {string} icon - icon to diplay on the select
   * @argument {boolena} [isOptional] - boolean to specify if the property may be empty or not
   * @argument {string} displayAttribute - name of the property to display
   * @argument {string} valueAttribute - name of the property to use as value
   * @argument {string} placeholder - placeholder to display when select empty
   * @argument {function} onChange - Action handler on value change
   * @example <caption>Full usage example of {{due-select}} component</caption>
   * Usage example :
   * ```hbs
   * {{#due-select
   *   options=(array 'day' 'week' 'month' 'quarter' 'semester' 'year')
   *   value=model.day
   *   onChange=(action "mutPeriod") as |option|
   * }}
   *   {{t (concat 'model.day.' option)}}
   * {{/due-select}}
   * ```
   */

  exports['default'] = Component.extend({
    classNames: ['due-select'],
    classNameBindings: ['focused', 'disabled', 'error', 'valid'],
    disabled: false,
    focused: false,
    icon: null,
    name: null,
    // placeholder: 'Select',
    value: null,
    displayValue: null,
    enableSearch: false,
    displayAttribute: null,
    valueAttribute: null,
    triggerClientX: 0,
    triggerClientY: 0,
    triggerHeight: 0,
    triggerWidth: 0,
    selected: [],
    error: false,
    valid: false,
    options: [],
    onChange: null,
    openDropdown: false,
    uid: null,
    useDropdownMultilevel: false,
    isDropdownMultilevelClosed: true,

    didInsertElement: function didInsertElement() {
      this.set('uid', guidFor(this));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this._setTriggerClientX();
    },

    valueChanged: observer('value', function () {
      if (this.get('onChange') !== null) {
        this.get('onChange')(this.get('value'));
      }
    }),

    displayedValue: computed('placeholder', 'displayValue', 'value', function () {
      return this.get('displayValue') || this.get('value') || this.get('placeholder');
    }),

    _setTriggerClientX: function _setTriggerClientX() {
      if (this.element) {
        var trigger = this.element.querySelector('.select-container');
        if (trigger) {
          this.set('triggerClientY', trigger.offsetTop);
          this.set('triggerClientX', trigger.getBoundingClientRect().x);
          this.set('triggerHeight', trigger.getBoundingClientRect().height);
          this.set('triggerWidth', trigger.getBoundingClientRect().width);
        }
      }
    },

    actions: {

      select: function select(opt) {
        if (this.get('valueAttribute')) {
          this.set('value', opt[this.get('valueAttribute')]);
        } else {
          this.set('value', opt);
        }
        if (this.get('canReselect')) {
          this.notifyPropertyChange('value');
        }
        this.send('focus', false);
      },

      clearSelection: function clearSelection() {
        this.set('value', null);
        this.send('toggle');
      },

      focus: function focus(focused) {
        if (this.get('disabled')) {
          return;
        }
        this.set('focused', focused);
        this.set('openDropdown', focused);
      },

      toggle: function toggle() {
        if (this.get('disabled')) {
          return;
        }
        if (this.get('useDropdownMultilevel')) {
          this.toggleProperty('isDropdownMultilevelClosed');
        } else {
          var _status = this.get('focused');
          this.set('focused', !_status);
          this.set('openDropdown', !_status);
        }
      }

    }
  });
});