define('due-dashboard/components/v2/-navbar', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['due-navbar'],

    leftPanelClosed: false,
    rightPanelClosed: false,

    actions: {
      collapsePanel: function collapsePanel(side) {
        this.toggleProperty(side + 'PanelClosed');
      },

      selectCurrentGroup: function selectCurrentGroup(selectedGroup) {
        var callback = this.get('selectCurrentGroup');
        var isLoading = this.get('group.isLoading');

        if (callback && !isLoading) {
          callback(selectedGroup);
        }
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-navbar}} alert component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */