define('due-dashboard/components/reports/verbatims-list/-filters-dropdown', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['report-verbatims-list-filters-dropdown'],

    didRender: function didRender() {
      this._initDropdownPosition();
    },

    _initDropdownPosition: function _initDropdownPosition() {
      if (this.get('isOpen')) {
        var _parent = this.element.parentElement,
            parentWidth = _parent.clientWidth,
            dropdownWidth = this.element.querySelector('.dropdown-container').clientWidth,
            left = parentWidth - dropdownWidth + 2;

        this.set('dropdownLeftStyle', left + 'px;');
      }
    },

    actions: {
      onSelectOption: function onSelectOption(option, optionIdx, group, groupIdx) {
        var onClick = this.get('onClick');

        if (onClick) onClick(_extends({}, option, { index: optionIdx, groupKey: group.key, groupIndex: groupIdx }));
      },

      closeDropdown: function closeDropdown() {
        this.set('isOpen', false);
      },

      openExportModal: function openExportModal() {
        var callback = this.get('onClickExportList');
        if (callback) callback();
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{reports/verbatims-list/-filters-dropdown}} Dropdown with group of options, selectable with radio buttons
 * @figma https://www.figma.com/design/IBuL3CUEFmxH2t83O7DC8o/Search-in-verbatim-list?node-id=0-2260&t=nHHJGG9jB5pqTBcT-0
 */