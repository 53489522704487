define('due-dashboard/components/scenario-action', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var dasherize = _ember['default'].String.dasherize;
  exports['default'] = _ember['default'].Component.extend({
    action: null,
    tagName: 'td',
    classNames: ['action'],
    errorsNotEmpty: _ember['default'].computed.notEmpty('action.validations.errors'),
    hasErrors: _ember['default'].computed.and('displayErrors', 'errorsNotEmpty'),

    componentName: computed('action.type', function () {
      var type = this.get('action.type');
      return dasherize(type);
    })
  });
});