define('due-dashboard/models/email-alert-scenario-action', ['exports', 'due-dashboard/utils/has-many-through', 'due-dashboard/models/scenario-action', 'ember', 'ember-data', 'ember-cp-validations'], function (exports, _dueDashboardUtilsHasManyThrough, _dueDashboardModelsScenarioAction, _ember, _emberData, _emberCpValidations) {
  var hasMany = _emberData['default'].hasMany;

  var Validations = (0, _emberCpValidations.buildValidations)({
    validAccounts: (0, _emberCpValidations.validator)('presence', true)
  });

  var M = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    email_alert_scenario_actions_accounts: hasMany('email-alert-scenario-actions-account', { async: true }),
    email_alert_scenario_actions_segments: hasMany('email-alert-scenario-actions-segment', { async: true }),

    validAccounts: _ember['default'].computed('email_alert_scenario_actions_accounts.[]', 'email_alert_scenario_actions_segments.[]', function () {
      return this.get('email_alert_scenario_actions_accounts.length') !== 0 || this.get('email_alert_scenario_actions_segments.length') !== 0 || null;
    }),

    defineSavepoint: function defineSavepoint() {
      var _this = this;

      return this.get('email_alert_scenario_actions_accounts').then(function (accounts) {
        _this.set('_savepoint_action_recipients', accounts.toArray());
        return _this;
      });
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_action_recipients');
      if (savepoint) {
        this.set('email_alert_scenario_actions_accounts', savepoint);
      }
    }
  });

  _dueDashboardUtilsHasManyThrough['default'].define(M, 'email_alert_scenario_actions_accounts', {
    foreignKey: 'action',
    hasManyKey: 'accounts',
    sourceKey: 'account'
  });

  _dueDashboardUtilsHasManyThrough['default'].define(M, 'email_alert_scenario_actions_segments', {
    foreignKey: 'action',
    hasManyKey: 'segments',
    sourceKey: 'segment'
  });

  exports['default'] = M;
});