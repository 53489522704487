define('due-dashboard/models/satisfaction-point', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberDataModelFragments['default'].Fragment.extend({
    score: attr('number'),
    answer_count: attr('number'),
    from_date: attr('date'),
    to_date: attr('date'),
    detractors_count: attr('number'),
    passives_count: attr('number'),
    promoters_count: attr('number')
  });
});