define('due-dashboard/models/integration', ['exports', 'ember-data', 'ember', 'ember-data-model-fragments'], function (exports, _emberData, _ember, _emberDataModelFragments) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    name: attr('string'),
    logo: attr('string'),
    is_public: attr('boolean'),
    integration_types: _emberDataModelFragments['default'].array(),
    free_access: attr('boolean'),

    title: _ember['default'].computed('i18n.locale', 'name', function () {
      return this.get('i18n').t('settings.integrations.' + this.get('name') + '.title');
    }),

    shortDescription: _ember['default'].computed('i18n.locale', 'name', function () {
      return this.get('i18n').t('settings.integrations.' + this.get('name') + '.short_description');
    })
  });
});