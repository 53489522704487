define("due-dashboard/templates/components/graphs/edit-view", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 8
            },
            "end": {
              "line": 11,
              "column": 8
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/edit-view.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [10, 47], [10, 59]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["newLine"], [], ["loc", [null, [10, 18], [10, 38]]], 0, 0], ["content", "button.text", ["loc", [null, [10, 62], [10, 77]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 8
              },
              "end": {
                "line": 29,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/components/graphs/edit-view.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "graphs/line-filter", [], ["class", "graph-line", "model", ["subexpr", "@mut", [["get", "line", ["loc", [null, [28, 56], [28, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "onSave", ["subexpr", "@mut", [["get", "saveAction", ["loc", [null, [28, 68], [28, 78]]], 0, 0, 0, 0]], [], [], 0, 0], "group", ["subexpr", "@mut", [["get", "group", ["loc", [null, [28, 85], [28, 90]]], 0, 0, 0, 0]], [], [], 0, 0], "handle", ".drag-handle", "deleteLine", ["subexpr", "action", ["deleteLine", ["get", "line", ["loc", [null, [28, 145], [28, 149]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 124], [28, 150]]], 0, 0]], ["loc", [null, [28, 10], [28, 152]]], 0, 0]],
          locals: ["line"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 4
            },
            "end": {
              "line": 30,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/graphs/edit-view.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("thead");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          dom.setAttribute(el2, "class", "lines-legends");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "class", "line-color-picker");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "class", "line-color-picker");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "class", "line-name");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "class", "line-type");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "class", "line-chart-type");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "class", "add-filter-block");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "class", "filler");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "class", "delete-line");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [15]), 0, 0);
          morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "capitalize", [["subexpr", "t", ["graphs.edit_view.titles.name"], [], ["loc", [null, [19, 45], [19, 79]]], 0, 0]], [], ["loc", [null, [19, 32], [19, 81]]], 0, 0], ["inline", "capitalize", [["subexpr", "t", ["graphs.edit_view.titles.index"], [], ["loc", [null, [20, 45], [20, 80]]], 0, 0]], [], ["loc", [null, [20, 32], [20, 82]]], 0, 0], ["inline", "capitalize", [["subexpr", "t", ["graphs.edit_view.titles.view"], [], ["loc", [null, [21, 51], [21, 85]]], 0, 0]], [], ["loc", [null, [21, 38], [21, 87]]], 0, 0], ["inline", "capitalize", [["subexpr", "t", ["graphs.edit_view.titles.actions"], [], ["loc", [null, [24, 47], [24, 84]]], 0, 0]], [], ["loc", [null, [24, 34], [24, 86]]], 0, 0], ["block", "each", [["get", "graph.lines", ["loc", [null, [27, 16], [27, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [27, 8], [29, 17]]]]],
        locals: ["group"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/graphs/edit-view.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "graph-list-item-wrapper edit-block");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "stats-edit-block");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "combo-title");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "section-actions");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        return morphs;
      },
      statements: [["inline", "graphs/due-graph", [], ["graph", ["subexpr", "@mut", [["get", "graph", ["loc", [null, [3, 29], [3, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "filters", ["subexpr", "@mut", [["get", "filters", ["loc", [null, [3, 43], [3, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "editMode", true], ["loc", [null, [3, 4], [3, 66]]], 0, 0], ["inline", "t", ["graphs.edit_view.titles.statistics"], [], ["loc", [null, [7, 10], [7, 52]]], 0, 0], ["block", "due-button", [], ["type", "action", "icon", "plus", "text", ["subexpr", "t", ["graphs.edit_view.button.add_stat"], [], ["loc", [null, [9, 53], [9, 91]]], 0, 0]], 0, null, ["loc", [null, [9, 8], [11, 23]]]], ["block", "sortable-group", [], ["tagName", "table", "class", "graph-lines", "model", ["subexpr", "@mut", [["get", "graph.lines", ["loc", [null, [14, 64], [14, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["reorderLines"], [], ["loc", [null, [14, 85], [14, 108]]], 0, 0]], 1, null, ["loc", [null, [14, 4], [30, 23]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});