define('due-dashboard/components/input-display-item', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _ember['default'].Component.extend({
    /* Public props */
    onClick: null, // {Function (Enum: int|out)} Trigger click
    items: [], // {Array<T>} Items to display

    defaultInputProps: { // (Optional) Input props
      type: 'text',
      autocomplete: false,
      'class': '',
      placeholder: ''
    },

    /* Private props */
    inputValue: '', // Current value of input
    banishKeys: ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter', 'Escape'], // Key banned from handleKeyUp

    init: function init() {
      /* Init input properties */
      var inputProps = this.get('inputProps');
      this.set('inputProps', inputProps ? _extends({}, this.get('defaultInputProps'), inputProps) : this.get('defaultInputProps'));
      this._super.apply(this, arguments);
    },

    /* Trigger click inside the component */
    click: function click(event) {
      if (!event || event.pointerId === -1) return false;
      var onClick = this.get('onClick');
      if (onClick) onClick();
    },

    /* Check if items array empty or undefined */
    itemsNotEmpty: _ember['default'].computed('items.[]', function () {
      if (this.get('inputValue.length') > 0) this.set('inputValue', '');
      var items = this.get('items');
      return items && items.length > 0;
    }),

    actions: {
      /* Trigger key up from due-input &&  */
      handleKeyUp: function handleKeyUp(_, trigger) {
        var onInput = this.get('onInput'),
            onEnterKey = this.get('onEnterKey'),
            inputValue = this.get('inputValue');

        if (trigger && trigger.originalEvent && trigger.originalEvent.code === 'Enter' && this.get('allowEnterKey') && onEnterKey) {
          onEnterKey(inputValue);
        }
        if (trigger && trigger.originalEvent && this.banishKeys.includes(trigger.originalEvent.code)) return;
        if (onInput) onInput(inputValue);
        this.click();
      },

      /* Clear filter from due-flexible-input */
      handleClearFilter: function handleClearFilter() {
        var onInput = this.get('onInput');
        if (onInput) onInput('');
        var onClick = this.get('onClick');
        if (onClick) onClick('close');
      }
    }
  });
});
/**
 * @author Eliott Martin <eliott@diduenjoy.com> (12/01/2022)
 * @summary {{input-display-item}} Input with displayed items inside
 */