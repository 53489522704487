define("due-dashboard/controllers/settings/integrations/aircall", ["exports", "ember", "due-dashboard/config/environment", "due-dashboard/controllers/settings/integrations/integration-parent", "npm:aircall"], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent, _npmAircall) {
  var computed = _ember["default"].computed;
  var _Ember$computed = _ember["default"].computed;
  var and = _Ember$computed.and;
  var sort = _Ember$computed.sort;
  exports["default"] = _dueDashboardControllersSettingsIntegrationsIntegrationParent["default"].extend({
    submitEnabled: and("model.company_integration.api_id", "model.company_integration.api_token"),
    positionSorting: ["position:asc"],
    notDeletedFilters: computed.filterBy("model.company_integration.aircall_filters", "isDeleted", false),
    orderedFilters: sort("notDeletedFilters", "positionSorting"),

    aircallClient: computed("model.company_integration.{api_token,api_id,isNew,token}", function () {
      if (!this.get("model.company_integration.isNew")) {
        // new integrations (token retrieved through oauth)
        if (this.get("model.company_integration.token")) {
          return new _npmAircall["default"]({
            token: this.get("model.company_integration.token")
          });
        } else {
          // retrocompatibility, old integrations (api_id + api_token)
          return new _npmAircall["default"](this.get("model.company_integration.api_id"), this.get("model.company_integration.api_token"));
        }
      }
    }),

    getAircallRessource: function getAircallRessource(computed_name, ressource) {
      var _this = this;

      var aircallClient = this.get("aircallClient");
      if (aircallClient) {
        aircallClient[ressource].list(function (_, response) {
          return _this.set(computed_name, response[ressource]);
        }, { per_page: 10000 });
      }
    },

    tags: computed("aircallClient", function () {
      this.getAircallRessource("tags", "tags");
    }),

    aircall_users: computed("aircallClient", function () {
      this.getAircallRessource("aircall_users", "users");
    }),

    aircall_teams: computed("aircallClient", function () {
      this.getAircallRessource("aircall_teams", "teams");
    }),

    aircall_auth: computed("model.company.id", function () {
      return _dueDashboardConfigEnvironment["default"].EmberENV.AIRCALL_CONNECT_URL + "/" + this.get("model.company.id");
    }),

    actions: {
      createFilter: function createFilter() {
        this.model.company_integration.get("aircall_filters").addObject(this.get("store").createRecord("aircall-filter"));
      },

      reorderFilters: function reorderFilters(ordered_filters) {
        ordered_filters.map(function (elem, index) {
          elem.set("position", index + 1);
        });
      }
    }
  });
});