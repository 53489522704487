define('due-dashboard/models/keyword', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    text: attr('string'),
    detractors_counter: attr('number'),
    passives_counter: attr('number'),
    promoters_counter: attr('number'),
    nps: attr('number'),
    counter: attr('number'),
    rank: attr('number'),
    aliases: attr()
  });
});