define('due-dashboard/components/survey-stats', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['survey-stats'],

    deliveryTotal: computed.filterBy('survey_stats.delivery_rates', 'name', 'total'),

    showMailingRelatedStats: computed('deliveryTotal.[]', function () {
      return this.get('deliveryTotal.firstObject') && this.get('deliveryTotal.firstObject.count') !== 0;
    }),

    lonelyStat: computed.not('showMailingRelatedStats'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.store.queryRecord('survey-stat', { filter: { id: this.get('survey.id') } }).then(function (record) {
        _this.set('survey_stats', record);
      });
    }
  });
});