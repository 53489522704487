define('due-dashboard/controllers/companies/surveys/edit', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({
    trySave: false,
    didNotSave: false,
    didSave: false,

    resetSavingStates: function resetSavingStates() {
      this.setProperties({
        trySave: false,
        didNotSave: false,
        didSave: false
      });
    }
  });
});