define('due-dashboard/routes/settings/users/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    activate: function activate() {
      this.controllerFor('settings.users').set('currentIsIndex', true);
    },

    deactivate: function deactivate() {
      this.controllerFor('settings.users').set('currentIsIndex', false);
    }
  });
});