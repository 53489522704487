define('due-dashboard/controllers/companies/surveys/edit/mailing-qrcode', ['exports', 'ember', 'due-dashboard/mixins/help-zone'], function (exports, _ember, _dueDashboardMixinsHelpZone) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend(_dueDashboardMixinsHelpZone['default'], {
    surveyURL: _ember['default'].computed.alias('model.survey.surveyURL'),
    qrCodeImage: null,
    filename: computed('model.survey.name', function () {
      return 'QRCode_' + this.get('model.survey.name');
    })

  });
});