define('due-dashboard/serializers/change-end-tw-tweet-scenario-action', ['exports', 'ember-data', 'due-dashboard/mixins/embedded-jsonapi-records'], function (exports, _emberData, _dueDashboardMixinsEmbeddedJsonapiRecords) {
  var JSONAPISerializer = _emberData['default'].JSONAPISerializer;
  exports['default'] = JSONAPISerializer.extend(_dueDashboardMixinsEmbeddedJsonapiRecords['default'], {

    attrs: {
      change_end_tw_tweet_scenario_actions_languages: {
        serialize: 'records',
        deserialize: 'ids-and-type'
      }
    }

  });
});