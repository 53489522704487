define('due-dashboard/models/graph-line-stat', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    lines: _emberData['default'].attr(),
    filters: _emberData['default'].attr(),
    step_type: _emberData['default'].attr(),
    // TODO: Change this into a date array, cleaning some hand-parsing in due-graph.js
    timestamps: _emberData['default'].attr({ readOnly: true }),
    curves: _emberData['default'].attr({ readOnly: true }),
    tz: _emberData['default'].attr()
  });
});
//import MF from 'ember-data-model-fragments';