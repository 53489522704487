define('due-dashboard/components/breakdowns/due-inline-percentage', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-inline-percentage'],
    classNameBindings: ['color'],

    init: function init() {
      this._super();
      var colorWidth = (this.get('value') === '-' ? 0 : this.get('value')) * 100;
      this.set('colorWidth', colorWidth);
      this.set('grayWidth', 100 - colorWidth);
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{due-inline-percentage}} inline colored percentage ratio
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2755%3A334944&t=vWBqgIrAQLBSPNiX-0
 */

/**
 * @module due-distribution-breakdown
 * @argument {Array} distribution (Mandatory) List of distribution values
 * @argument {Object} content (Mandatory) Object of configuration's props
 */