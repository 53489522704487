define('due-dashboard/routes/companies/surveys', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    activate: function activate() {
      this.controllerFor('companies').set('displayFilters', false);
    },

    deactivate: function deactivate() {
      this.controllerFor('companies').set('displayFilters', true);
    },

    model: function model() {
      var account = this.get('currentAccount.content');
      return _ember['default'].RSVP.hash({
        account: account,
        company: account.get('company')
      });
    }
  });
});