define('due-dashboard/routes/companies/custom-stats/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    afterModel: function afterModel(model) {
      if (model.custom_stats.get('length') === 1) {
        this.transitionTo('companies.custom-stats.show', model.custom_stats.get('firstObject.id'));
      }
    }

  });
});