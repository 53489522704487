define('due-dashboard/components/v2/libraries/surveys/navbar/-translations', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-translations'],

    addLanguageAlertIsOpen: false,
    selectedDefaultLanguage: null,
    currentElement: 'all',

    confirmMainLanguageAlertIsOpen: computed('group.survey_draft.confirm_main_language', 'group.confirmMainLanguageAlertIsOpenFromPublish', function () {
      var mainLanguage = this.get('group.survey_draft.main_language.id');
      var confirmation = this.get('group.survey_draft.confirm_main_language');
      var currentLanguage = this.get('currentAccount.content.language');

      if (!mainLanguage) {
        this.set('group.survey_draft.main_language', currentLanguage);
        this.set('selectedDefaultLanguage', { id: currentLanguage.get('id'), value: currentLanguage.get('tag') });
        this._saveRevision();
      }
      return !confirmation;
    }),

    defaultLanguage: computed('group.survey_draft', 'group.survey_draft.main_language', function () {
      var mainLanguage = this.get('group.survey_draft.main_language');

      if (mainLanguage) {
        this.set('selectedDefaultLanguage', { value: mainLanguage.get('tag'), id: mainLanguage.get('id') });
      }
      return mainLanguage != undefined ? { value: mainLanguage.get('tag'), id: mainLanguage.get('id') } : undefined;
    }),

    languagesDisplayed: computed('group.survey_draft', function () {
      var mainLanguageTag = this.get('group.survey_draft.main_language.tag');
      var translations = this.get('group.survey_draft.translations') || {};
      return (translations['_languages'] || []).reduce(function (a, v) {
        if (v != mainLanguageTag) {
          a.push({ value: v.toUpperCase() });
        }return a;
      }, []);
    }),

    availableMainLanguages: computed('group.survey_draft', function () {
      var availableLanguages = this.get('survey_draft.available_languages') || [];
      return availableLanguages.reduce(function (a, v) {
        {
          a.push({ value: v.tag, id: v.id });
        }return a;
      }, []);
    }),

    languagesToAdd: computed('group.survey_draft', 'group.survey_draft.translations', function () {
      var mainLanguageTag = this.get('group.survey_draft.main_language.tag');
      var translations = this.get('survey_draft.translations') || {};
      var languages = translations['_languages'] || [];
      var availableLanguages = this.get('survey_draft.available_languages') || [];
      return availableLanguages.reduce(function (a, v) {
        if (!languages.includes(v.tag) && v.tag != mainLanguageTag) {
          a.push({ name: v.name, value: v.tag, id: v.id });
        }return a;
      }, []);
    }),

    init: function init() {
      this._selectCurrentElement('all');
      this._super.apply(this, arguments);
    },

    _refreshLanguageToDisplay: function _refreshLanguageToDisplay(lang) {
      var mainLanguageTag = this.get('group.survey_draft.main_language.tag');
      var translations = this.get('survey_draft.translations') || {};
      var surveyLanguages = translations._languages || [];
      var languagesToDisplay = [mainLanguageTag];
      if (lang == 'all') {
        var languages = translations['_languages'] || [];
        languages.forEach(function (language) {
          if (!languagesToDisplay.includes(language) && surveyLanguages.includes(language)) {
            languagesToDisplay.push(language);
          }
        });
      } else {
        languagesToDisplay.push(lang);
      }
      var saveCallback = this.get('group.selectTranslationCallback');
      if (saveCallback) {
        this.send('dispatchAction', saveCallback, languagesToDisplay, lang);
      }
    },

    _selectCurrentElement: function _selectCurrentElement(lang) {
      this.set('currentElement', lang);
      this._refreshLanguageToDisplay(lang);
    },

    _saveRevision: function _saveRevision() {
      var autoSaveRevisionCallback = this.get('group.autoSaveRevisionCallback');
      if (autoSaveRevisionCallback) {
        this.send('dispatchAction', autoSaveRevisionCallback);
      }
    },

    actions: {
      dispatchAction: function dispatchAction(action) {
        var dispatcher = this.get('actionsDispatcher');
        if (dispatcher) {
          for (var _len = arguments.length, arg = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            arg[_key - 1] = arguments[_key];
          }

          dispatcher.apply(undefined, [action].concat(arg));
        }
      },

      onClickBackButton: function onClickBackButton() {
        var backToIndex = this.get('backToIndexCallback');
        if (backToIndex) {
          backToIndex();
        }
      },

      selectCurrentElement: function selectCurrentElement(lang) {
        this._selectCurrentElement(lang);
      },

      openAddLanguageAlert: function openAddLanguageAlert() {
        this.set('newLanguage', null);
        this.toggleProperty('addLanguageAlertIsOpen');
      },

      closeAddLanguageAlert: function closeAddLanguageAlert() {
        this.set('newLanguage', null);
        this.set('addLanguageAlertIsOpen', false);
      },

      addLanguage: function addLanguage(language) {
        if (language != undefined) {
          var translations = this.get('group.survey_draft.translations') || {};
          if (translations['_languages'] == undefined) {
            translations['_languages'] = [];
          }
          translations['_languages'].push(language.value);
          this.set('group.survey_draft.translations', translations);
          this._saveRevision();
          if (this.get('currentElement') == 'all') {
            this._refreshLanguageToDisplay('all');
          }
        }
        this.set('addLanguageAlertIsOpen', false);
      },

      deleteLanguage: function deleteLanguage(language) {
        var translations = this.get('group.survey_draft.translations');
        var languages = translations['_languages'];
        var index = languages.indexOf(language);
        languages.splice(index, 1);
        translations['_languages'] = languages;
        if (this.get('currentElement') == language) {
          this._selectCurrentElement('all');
        } else if (this.get('currentElement') == 'all') {
          this._refreshLanguageToDisplay('all');
        }
        this.set('group.survey_draft.translations', translations);
        this._saveRevision();
      },

      selectDefaultLanguage: function selectDefaultLanguage(lang) {
        this.set('selectedDefaultLanguage', lang);
      },

      confirmMainLanguage: function confirmMainLanguage() {
        var _this = this;

        var selectedDefaultLanguage = this.get('selectedDefaultLanguage');
        if (selectedDefaultLanguage == undefined) {
          return;
        }

        var translations = this.get('group.survey_draft.translations') || {};
        if (translations['_languages'] == undefined) {
          translations['_languages'] = [];
        }
        translations['_languages'].push(selectedDefaultLanguage.value);
        translations['_languages'] = [].concat(_toConsumableArray(new Set(translations['_languages']))); // only unique values after push
        this.set('group.survey_draft.translations', translations);

        this.store.findRecord('language', selectedDefaultLanguage.id).then(function (language) {
          _this.set('group.survey_draft.main_language', language);
          _this.set('group.survey_draft.confirm_main_language', true);
          _this.set('group.confirmMainLanguageAlertIsOpenFromPublish', false);
          _this._saveRevision();
          _this._refreshLanguageToDisplay(_this.get('currentElement'));
        });
      },

      closeConfirmMainLanguageAlertIsOpen: function closeConfirmMainLanguageAlertIsOpen() {
        var updateCallback = this.get('group.resetConfirmMainLnaguageAlert');
        this.set('confirmMainLanguageAlertIsOpen', false);
        this.set('group.confirmMainLanguageAlertIsOpenFromPublish', false);
        this.set('selectedDefaultLanguage', null);
        if (updateCallback) {
          this.send('dispatchAction', updateCallback);
        }
      }
    }
  });
});