define('due-dashboard/components/custom-stat-nps-tooltip', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var Component = _ember['default'].Component;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    tagName: 'span',
    classNames: ['nps-tooltip', 'tio-info_outined'],
    attributeBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      var content = "";
      for (var i = 0; i < 5; i++) {
        content += '\n        <div class="help-line">\n          <div class="text">' + this.get('i18n').t('custom_stats.help.nps_tiers_' + (i + 1)) + '</div>\n          <div><div class="color-' + (4 - i) + ' badge-span"></div>\n          </div>\n        </div>';
      }
      $(this.$().parent()).tooltipster({
        theme: ['tooltipster-noir', 'tooltipster-noir-customized', 'tooltipster-noir-customized-centered'],
        content: htmlSafe(content).toString(),
        contentAsHTML: true,
        hideOnClick: true,
        position: 'right',
        animation: 'grow',
        delay: 150
      });
    },

    willDestroyElement: function willDestroyElement() {
      $(this.$().parent()).tooltipster('destroy');
    }
  });
});