define('due-dashboard/components/graphs/due-graph', ['exports', 'ember', 'ember-data', 'due-dashboard/helpers/current-timezone'], function (exports, _ember, _emberData, _dueDashboardHelpersCurrentTimezone) {
  var _slice = Array.prototype.slice;

  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /* global _, $, d3 */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;

  var statTypes = ['feedback_rate', 'completion_rate', 'open_rate', 'click_rate', 'promoter_rate', 'passive_rate', 'detractor_rate', 'verbatim_rate'];

  exports['default'] = Component.extend({

    classNames: ['graphs', 'due-graph'],

    graph: null,
    filters: null,
    selectedEvent: null,
    selectedDateIndex: 0,
    displyInput: false,
    eventName: null,

    displayedEvents: computed('graph.events.[]', 'rawStats.timestamps.[]', 'component', 'graphWidth', function () {
      var raw_timestamps = this.get('rawStats.timestamps') || [],
          timestamps = raw_timestamps.map(function (t) {
        return new Date(t);
      }),
          graph_width = this.get('graphWidth');
      if (graph_width == null || timestamps.length === 0) {
        return [];
      }

      var events = this.get('graph.events'),
          tmin = Math.min.apply(null, timestamps),
          tmax = Math.max.apply(null, timestamps),
          icon_width = 20;

      if (events) {
        var _ret = (function () {
          var offsetsArray = [];
          return {
            v: events.filter(function (ev) {
              var at = ev.get('occured-at');
              return at >= tmin && at <= tmax;
            }).map(function (ev, idx) {
              var at = ev.get('occured-at'),
                  offset_coef = (at - tmin) / (tmax - tmin),
                  border = 10,
                  offset = (graph_width - 2 * border) * offset_coef + border - icon_width / 2;
              offsetsArray.push(offset);
              var displayFlag = offsetsArray.indexOf(offset) === idx ? true : false;
              return {
                offset: offset,
                event: ev,
                display: displayFlag
              };
            })
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
      return [];
    }),

    eventsDetails: computed('selectedEvent', 'graph.events.[]', function () {
      var _this2 = this;

      return this.get('displayedEvents').filter(function (ev) {
        return ev.offset == _this2.get('selectedEvent');
      });
    }),

    rawStats: computed('filters', 'graph.lines.@each.{type,segmentFilters}', 'step_type', function () {
      var lines = this.get('graph.lines'),
          step_type = this.checkTimeStepBeforeFilter(this.get('filters'), this.get('step_type'));

      if (lines && lines.get('length') > 0) {
        var stats = this.get('store').createRecord('graph-line-stat', {
          filters: this.get('filters'),
          tz: _dueDashboardHelpersCurrentTimezone['default'].compute(),
          lines: lines.map(function (l) {
            return {
              type: l.get('type'),
              filters: l.get('segmentFilters')
            };
          }),
          step_type: step_type
        });

        return _emberData['default'].PromiseObject.create({ promise: stats.save() });
      }
    }),

    columnData: computed('rawStats.isFulfilled', function () {
      var timestamps = this.get('rawStats.timestamps');
      var curves = this.get('rawStats.curves');
      if (timestamps != null && curves != null) {
        return [['x'].concat(_toConsumableArray(timestamps.map(function (t) {
          return new Date(t);
        })))].concat(_toConsumableArray(curves.map(function (curve, i) {
          var values = curve.values;
          var min = undefined,
              max = undefined;
          if (curve.type.includes('count')) {
            return [curve.type + ' - ' + (i + 1)].concat(_toConsumableArray(values.map(function (v) {
              return v;
            })));
          } else if (curve.type === 'nps') {
            min = 100;
            max = 200;
          } else if (curve.type.includes('question_category_average_pos:')) {
            min = 0;
            max = 5;
          } else if (curve.type === 'average' || curve.type.includes('question_category:')) {
            min = 0;
            max = 10;
          } else if (curve.type.includes('rate')) {
            min = 0;
            max = 100;
          }
          return [curve.type + ' - ' + (i + 1)].concat(_toConsumableArray(values.map(function (v) {
            if (v == null) {
              return null;
            }
            return (v + min) / max * 100;
          })));
        })));
      }
    }),

    axesData: computed('rawStats.isFulfilled', function () {
      var curves = this.get('rawStats.curves');
      if (curves != null) {

        return curves.reduce(function (map, curve, i) {
          if (curve.type.includes('count')) {
            map[curve.type + ' - ' + (i + 1)] = 'y';
          } else {
            map[curve.type + ' - ' + (i + 1)] = 'y2';
          }
          return map;
        }, {});
      }
    }),

    dataTypes: computed('graph.lines.@each.{chart-type,type}', 'step', function () {
      var lines = this.get('graph.lines') || [];
      return lines.reduce(function (o, line, i) {
        var curve = line.get('type') + ' - ' + (i + 1);
        o[curve] = line.get('chart-type');
        return o;
      }, {});
    }),

    data: computed('columnData.[]', 'colors.[]', 'dataTypes', function () {
      var _that = this;
      return {
        x: 'x',
        types: this.get('dataTypes'),
        colors: this.get('colors'),
        columns: this.get('columnData') || [],
        axes: this.get('axesData') || [],
        onclick: function onclick(d) {
          _that.set('selectedDateIndex', d.index);
        }
      };
    }),

    colors: computed('graph.lines.@each.{color,type}', 'step', function () {
      var lines = this.get('graph.lines') || [];

      return lines.reduce(function (o, line, i) {
        var curve = line.get('type') + ' - ' + (i + 1);

        o[curve] = line.get('color');
        return o;
      }, {});
    }),

    step: computed('columnData.[]', function () {
      var cols = this.get('columnData.firstObject');

      if (cols !== undefined && cols.length >= 3) {
        var _get = this.get('columnData.firstObject');

        var _get2 = _slicedToArray(_get, 3);

        var t1 = _get2[1];
        var t2 = _get2[2];
        var delta = (t2 - t1) / (3600 * 1000); // ms to hours
        if (delta <= 1) {
          return 'hour';
        } else if (delta <= 24) {
          return 'day';
        } else if (delta <= 24 * 7 + 2) {
          return 'week';
        } else if (delta <= 24 * 31 + 1) {
          return 'month';
        } else {
          return 'year';
        }
      } else {
        return 'day';
      }
    }),

    timeScales: computed('filters', function () {
      return this.filteredTimeScaleStep(this.get('filters'));
    }),

    selectedTimeScale: computed('timeScales.@each.name', 'timeScales.@each.code', 'step_type', 'step', function () {
      if (this.get('timeScales') && this.get('step_type')) {
        var step_type = this.get('timeScales').findBy('code', this.get('step_type'));
        return step_type ? step_type : this.get('timeScales').findBy('code', this.get('step'));
      }
      return this.get('timeScales').findBy('code', this.get('step'));
    }),

    xTickFormatter: computed('i18n.locale', 'step', function () {
      var _this3 = this;

      var step = this.get('step'),
          locale = this.get('i18n.locale'),
          format_types = ['hour', 'day', 'week', 'month', 'year'],
          formats = _.chain(format_types).keyBy().mapValues(function (type) {
        return _this3.get('i18n').t('satisfaction_bar_chart.date_format_' + type).string;
      }).value();

      var localeForTime = d3.localesJson[locale] || d3.localesJson['en'];
      d3.timeFormatDefaultLocale(localeForTime);
      var formatters = _.mapValues(formats, function (format) {
        return d3.timeFormat(format);
      });

      return step === 'hour' ? d3.timeFormat("%Hh") : formatters[step] || d3.timeFormat('%x');
    }),

    // xAxisPadding: computed('step', function() { OMFG
    //   let base = 1688172;
    //   if (this.get('step') == 'day') {
    //     return base * 24;
    //   } else if (this.get('step') == 'week') {
    //     return base * 24 * 7;
    //   } else if (this.get('step') == 'month') {
    //     return base * 24 * 31;
    //   } else if (this.get('step') == 'year') {
    //     return base * 24 * 31 * 12;
    //   } else {
    //     return base;
    //   }
    // }),

    axis: computed('xTickFormatter', function () {
      return {
        x: {
          type: 'timeseries',
          tick: {
            format: this.get('xTickFormatter'),
            outer: true,
            centered: true,
            multiline: true,
            multilineMax: 2,
            culling: {
              max: 12
            }
          }
        },
        y: {
          show: false,
          label: false,
          min: 0,
          tick: { format: d3.format("d") }
        },
        y2: {
          show: false,
          min: 0,
          max: 100,
          tick: { format: d3.format("d") }
        }
      };
    }),

    spline: {
      interpolation: {
        type: 'monotone'
      }
    },

    tooltip: computed('rawStats.curves.firstObject.length', 'graph.lines.@each.{chart-type,type}', function () {
      var lines = this.get('graph.lines') || [];
      var containsBar = false;
      lines.map(function (line) {
        if (line.get('chart-type') === 'bar') {
          containsBar = true;
        }
      });

      var _that = this,
          rand_int = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
          attr_name = 'graph_hax_f_' + rand_int,
          data_len = this.get('rawStats.curves.firstObject.values.length');
      //chart_rect = this.element ? this.element.getClientRects()[0] : null;

      window[attr_name] = this.addGraphEvent.bind(this);
      return {
        grouped: true,
        contents: function contents() {
          return _that._renderTooltip.apply(_that, [this, attr_name].concat(_slice.call(arguments)));
        },
        format: {
          value: function value(_value, ratio, id, index) {
            // WARNING: This breaks if curve naming (with indexes) is changed
            var curves = _that.get('rawStats.curves'),
                curve_index = parseInt(id.match(/- ([0-9]+)$/)[1]) - 1;

            var trueValue = Number(curves[curve_index].values[index]);
            var fixedValue = parseFloat(Number(trueValue).toFixed(1));
            if (fixedValue === parseFloat(Number(trueValue).toFixed(0))) {
              return trueValue;
            } else {
              return fixedValue;
            }
          },

          //name: function (value, ratio, id, index) {
          name: function name(value, ratio, id) {
            var lines = _that.get('graph.lines').toArray(),
                curve_index = parseInt(id.match(/- ([0-9]+)$/)[1]) - 1;

            return lines[curve_index].get('name');
          }
        },
        position: function position(data, tip_width, tip_height, element) {
          var el_rect = element.getClientRects()[0],
              rect_width = el_rect.width - 40,
              index = data[0].index,
              data_len_d = containsBar ? data_len : data_len - 1,
              x_offset = 20,
              x = containsBar ? rect_width / data_len_d * index + x_offset + x_offset * (index / data_len_d) / 2 - tip_width / 2 + rect_width / data_len_d / 2 : rect_width / data_len_d * index + x_offset + x_offset * (index / data_len_d) / 2 - tip_width / 2;

          var top = d3.mouse(element)[1] + 15;
          // temp code lv demo
          return {
            top: top,
            left: Math.min(Math.max(0, x), el_rect.width - tip_width)
          };
          // return {
          //   top: Math.max(y, min_y),
          //   left: Math.min(Math.max(0, x), el_rect.width - tip_width)
          // };
        }
      };
    }),

    addGraphEvent: function addGraphEvent(date_string) {
      var _this4 = this;

      var parentDiv = document.getElementById('div-input-container'),
          add_event_btn = document.getElementById('add-event-btn');
      if (this.get('displyInput')) {
        var events = this.get('graph.events');
        var _name = this.get('eventName');

        events.createFragment({
          'occured-at': new Date(date_string),
          text: _name ? _name : 'Event ' + (events.get('length') + 1)
        });
        this.set('displyInput', false);
        while (parentDiv.firstChild) {
          parentDiv.removeChild(parentDiv.firstChild);
        }
      } else {
        (function () {
          _this4.set('displyInput', true);
          var _this = _this4,
              evt_input = document.createElement('input');
          evt_input.id = 'event-name-input-id';
          evt_input.className = 'graph-tooltip-input';
          evt_input.placeholder = _this4.get('i18n').t('graphs.button.input_placeholder');
          evt_input.addEventListener('input', function () {
            _this.set('eventName', this.value);
          });
          parentDiv.appendChild(evt_input);
          while (add_event_btn.firstChild) {
            add_event_btn.removeChild(add_event_btn.firstChild);
          }
          add_event_btn.innerHTML = '<span class="add-event-a">' + _this4.get('i18n').t('graphs.button.save_event') + '<i class="fa fa-save"></i></span>';
        })();
      }
    },

    _renderTooltip: function _renderTooltip(c3, func_name, d, titleFormat) {
      var _this5 = this;

      this.set('displyInput', false);
      var title = titleFormat(d[0].x);
      var graphLines = this.get('graph.lines').toArray();
      var curves = this.get('rawStats.curves').toArray();
      var colors = this.get('colors');
      var points = [];
      d.forEach(function (point, index) {
        points[index] = {
          name: graphLines[index].get('name'),
          value: parseInt(curves[index].values[d[0].index]),
          color: colors[point.name],
          unit: statTypes.indexOf(graphLines[index].get('type')) !== -1 && !isNaN(parseFloat(curves[index].values[d[0].index]).toFixed(2)) ? ' %' : ''
        };
      });
      var renderAddBtn = function renderAddBtn() {
        if (_this5.get('editMode')) {
          return '<div id="add-event-btn" class="graph-tooltip-button" onclick="window.' + func_name + '(\'' + d[0].x + '\')">\n                  <span class="add-event-a">' + _this5.get('i18n').t('graphs.button.add_event') + '<i class="fa fa-plus"></i></span>\n                </div>';
        }
        return '';
      };
      return '\n      <h4>' + title + '</h4>\n      ' + points.map(function (point) {
        return '<div><span style="float:left;"><i style="background-color:' + point.color + '" class="color-flag"></i><b>' + point.name + '</b>:</span><span style="float:right;">' + (isNaN(point.value) ? '- -' : point.value) + point.unit + '</span></div>';
      }).join("\n") + '\n      <div id="div-input-container"></div>\n    ' + renderAddBtn();
    },

    grid: {
      y: {
        show: true,
        lines: [
          // {value: 0.1},
          // {value: 0.3},
          // {value: 0.5},
          // {value: 0.7},
          // {value: 0.9},
          // {value: 1.1},
        ]
      }
    },

    point: computed('columnData.[]', 'graphWidth', function () {
      var data_len = this.get('columnData.firstObject.length'),
          width = this.get('graphWidth');
      var sensitivity = 7;

      if (width != null && data_len > 0) {
        sensitivity = width / data_len / 2;
      }
      return {
        r: 0,
        sensitivity: sensitivity,
        focus: {
          expand: {
            r: 7
          }
        }
      };
    }),

    line: {
      connectNull: true
    },

    legend: {
      hide: true
    },

    transition: {
      duration: 0
    },

    checkTimeStepBeforeFilter: function checkTimeStepBeforeFilter(filters, step) {
      if (step !== undefined) {
        var filteredStep = this.filteredTimeScaleStep(filters).findBy('code', step);
        return filteredStep ? filteredStep.code : filteredStep;
      }
      return step;
    },

    filteredTimeScaleStep: function filteredTimeScaleStep(filter) {
      var delta = 0;
      var steps = [{ name: this.get('i18n').t('words.year'), code: 'year' }];
      if (filter.start_date !== undefined && filter.end_date !== undefined) {
        var t1 = filter.start_date,
            t2 = filter.end_date;
        delta = (t2 - t1) / (3600 * 1000 * 24); //ms to days;
      }

      if (delta <= 7 && delta > 0) {
        steps.unshift({ name: this.get('i18n').t('words.hour'), code: 'hour' }, { name: this.get('i18n').t('words.day'), code: 'day' }, { name: this.get('i18n').t('words.week'), code: 'week' }, { name: this.get('i18n').t('words.month'), code: 'month' });
      } else if (delta <= 31 || delta == 0) {
        // month
        steps.unshift({ name: this.get('i18n').t('words.day'), code: 'day' }, { name: this.get('i18n').t('words.week'), code: 'week' }, { name: this.get('i18n').t('words.month'), code: 'month' });
      } else if (delta <= 31 * 12) {
        // year
        steps.unshift({ name: this.get('i18n').t('words.week'), code: 'week' }, { name: this.get('i18n').t('words.month'), code: 'month' });
      } else if (delta <= 31 * 12 * 2) {
        // 2 years
        steps.unshift({ name: this.get('i18n').t('words.month'), code: 'month' });
      }
      return steps;
    },

    updateGraphWidth: function updateGraphWidth() {
      if (this.element) {
        this.set('graphWidth', this.element.clientWidth);
      }
    },

    actions: {
      chartInit: function chartInit(component) {
        var c3_api = component.c3chart;
        //container = component.element.getElementsByClassName('c3-tooltip-container')[0];

        if (c3_api === undefined) {
          return;
        }

        this.updateGraphWidth();
        _ember['default'].run.once(function () {
          // Move to css
          $(".c3-tooltip-container").css("pointer-events", "auto");

          var doHide = function doHide() {
            $(".c3-tooltip-container").css("display", "none");
          };
          var doNothing = function doNothing() {};

          c3_api.internal.__proto__.hideTooltip = doNothing;
          component.element.onmouseleave = doHide;

          var tooltip_bar = $(component.element).find("g.c3-xgrid-focus > line")[0],
              common_selector = $(component.element).find('g.c3-chart > g.c3-chart-lines > g.c3-chart-line');
          if (common_selector[0]) {
            common_selector[0].insertBefore(tooltip_bar, common_selector.find('g:last-child')[0]);
          }
        });
      },

      eventSelected: function eventSelected(eventOffset) {
        var current = this.get('selectedEvent');
        var next = eventOffset === current ? null : eventOffset;

        this.set('selectedEvent', next);
      },

      chartResized: function chartResized() {
        this.updateGraphWidth();
      },

      deleteEvent: function deleteEvent(event) {
        this.get('graph.events').removeObject(event);
      },

      changeTimeScale: function changeTimeScale(scale) {
        this.set('step_type', scale.code);
      }
    }
  });
});