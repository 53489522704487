define('due-dashboard/controllers/companies/libraries/surveys/index', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/helpers/date-format-array'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardHelpersDateFormatArray) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var debounce = _ember['default'].run.debounce;
  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  var copy = _ember['default'].copy;
  exports['default'] = Controller.extend({
    filters: service(),
    searchValue: '',
    isLoading: true,
    deleteSurveyAlertIsOpen: false,
    deleteSurveyPromise: null,
    surveyToDelete: null,

    STATUS_ENUM: [{ key: 'all', text: 'words.all_maj' }, { key: 'active', text: 'words.active' }, { key: 'draft', text: 'words.draft' }, { key: 'live', text: 'words.live' }, { key: 'archived', text: 'words.archived' }],

    statusInfoMapping: {
      'live': { icon: 'tio-send', key: 'words.distribute', disabled: false, action: 'distributeSurvey' },
      'draft': { icon: 'tio-send', key: 'words.distribute', disabled: true, action: 'distributeSurvey' },
      'archived': { icon: 'tio-restore', key: 'words.restore', disabled: false, action: 'restoreSurvey' },
      'deleted': { icon: 'tio-restore', key: 'words.restore', disabled: false, action: 'restoreSurvey' }
    },

    editionInfoMapping: {
      'live': { disabled: false },
      'draft': { disabled: false },
      'archived': { disabled: true },
      'deleted': { disabled: true }
    },

    selectedStatusFilter: { key: 'live', text: 'words.live' },

    availablePaginations: computed(function () {
      return [{ key: 25, text: '25' }, { key: 50, text: '50' }, { key: 100, text: '100' }];
    }),

    selectedPagination: { key: 25, text: '25' },

    paginationMeta: computed('model.meta', function () {
      var model = this.get('model');

      return {
        page_count: model ? model.get('meta').page_count : 0,
        current_page: model ? model.get('meta').current_page : 0
      };
    }),

    canSeeButtons: computed(function () {
      var account = this.get('currentAccount.content');

      return account.get('zone') == 'Europe';
    }),

    computedFooterSelector: computed('computedSurveys', function () {
      var surveys = this.get('computedSurveys');

      return surveys && surveys.length > 8 ? '.footer-container' : '';
    }),

    computedSurveys: computed('model', function () {
      var _this = this;

      var surveys = this.get('model');
      var canDistributeSurvey = this.get('canDistributeSurvey');
      var canModifySurvey = this.get('canModifySurvey');

      if (!surveys) {
        return;
      }
      var computedSurveys = surveys.map(function (survey) {
        return {
          id: survey.get('id'),
          name: survey.get && survey.get('data') ? survey.get('data').name : survey.get('name'),
          status: survey.get('status'),
          is_favorite: survey.get('is_favorite'),
          is_ready: survey.get('is_ready'),
          last_updated_at: _dueDashboardHelpersDateFormatArray['default'].compute(survey.get('updated_at')),
          created_at: _dueDashboardHelpersDateFormatArray['default'].compute(survey.get('created_at')),
          status_btn_icon: _this.get('statusInfoMapping')[survey.get('status')].icon,
          status_btn_key: _this.get('statusInfoMapping')[survey.get('status')].key,
          status_btn_disabled: _this.get('statusInfoMapping')[survey.get('status')].disabled || !canDistributeSurvey || survey.get('status') === 'draft',
          status_btn_action: _this.get('statusInfoMapping')[survey.get('status')].action,
          edit_btn_disabled: !canModifySurvey,
          combo_btn_disabled: survey.get('status') === 'archived',
          combo_btn_class: survey.get('status') === 'archived' ? 'hide-opacity' : '',
          combo_button_actions: _this._getComboButtonActions(survey)
        };
      });

      return computedSurveys;
    }),

    comboButtonActions: computed(function () {
      return [{ type: 'action', icon: 'tio-files_labeled_outlined', action: 'copySurveyId', text: this.get('i18n').t('words.copy_survey_id').string }];
    }),

    isEmpty: computed('model', function () {
      var model = this.get('model');

      return model ? model.toArray().length === 0 : false;
    }),

    canCreateSurvey: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return this.get('currentAccount.rights').get('surveys_rights') === 'all';
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    canModifySurvey: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return ['all', 'write'].includes(this.get('currentAccount.rights').get('surveys_rights'));
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    canDistributeSurvey: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return ['all', 'write', 'distribute'].includes(this.get('currentAccount.rights').get('surveys_rights'));
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    isAdmin: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return this.get('currentAccount.rights').get('surveys_rights') === 'all';
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    _getComboButtonActions: function _getComboButtonActions(survey) {
      var comboButtonActions = this.get('comboButtonActions');
      var copyComboButtonActions = copy(comboButtonActions, true);
      var canModifySurvey = this.get('canModifySurvey');
      var isAdmin = this.get('isAdmin');
      var status = survey.get('status');

      var publishAction = { type: 'action', icon: 'tio-checkmark_circle_outlined', action: 'publishSurvey', text: this.get('i18n').t('words.publish').string };
      var distributeAction = { type: 'action', icon: 'tio-send', action: 'distributeSurvey', text: this.get('i18n').t('words.distribute').string };
      var copyUrlAction = { type: 'action', icon: 'tio-link', action: 'copySurveyUrl', text: this.get('i18n').t('words.copy_survey_url').string };
      var archiveAction = { type: 'action', icon: 'tio-archive', action: 'archiveSurvey', text: this.get('i18n').t('words.archive') };
      var deleteAction = { type: 'action', icon: 'tio-delete', action: 'openDeleteSurveyAlert', text: this.get('i18n').t('words.delete_maj') };
      var makeFavorite = { type: 'action', icon: 'tio-star', action: 'makeSurveyFavorite', text: this.get('i18n').t('words.favorite').string };
      var removeFavorite = { type: 'action', icon: 'tio-star_outlined', action: 'makeSurveyFavorite', text: this.get('i18n').t('words.not_favorite').string };
      var separator = { type: 'separator' };

      if (status === 'draft' && canModifySurvey) {
        copyComboButtonActions = [publishAction, separator].concat(copyComboButtonActions);
      }
      if (status === 'live' && canModifySurvey) {
        copyComboButtonActions = [distributeAction, separator].concat(copyComboButtonActions);
      }
      if (status === 'live') {
        copyComboButtonActions.push(copyUrlAction);
      }
      if (survey.get('is_favorite')) {
        copyComboButtonActions.push(removeFavorite);
      } else if (status !== 'archived') {
        copyComboButtonActions.push(makeFavorite);
      }
      if (status !== 'deleted') {
        copyComboButtonActions.push(separator);
      }
      if (status !== 'archived' && status !== 'deleted') {
        copyComboButtonActions.push(archiveAction);
      }
      if (status !== 'deleted' && isAdmin) {
        copyComboButtonActions.push(deleteAction);
      }

      return copyComboButtonActions;
    },

    _querySurveys: function _querySurveys() {
      var _this2 = this;

      var loader = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      var statusFilter = this.get('selectedStatusFilter');
      var pagination = this.get('selectedPagination');
      var nameFilter = this.get('searchValue');
      var actualPage = this.get('paginationMeta');
      var filters = { filter: { is_new_survey: true }, page: { size: pagination.key, number: actualPage.current_page }, include: 'active-revision', sort: '-is_favorite,-created_at' };

      if (statusFilter.key != 'all') {
        if (statusFilter.key === 'active') {
          filters.filter['status'] = 'draft,live';
        } else {
          filters.filter['status'] = statusFilter.key;
        }
      } else {
        filters.filter['exclude_status'] = 'deleted';
      }

      if (nameFilter.length > 0) {
        filters.filter['name'] = nameFilter;
      }

      if (loader) {
        this.set('isLoading', true);
        this.notifyPropertyChange('isLoading');
      }

      this.store.query('survey', filters).then(function (surveys) {
        _this2.set('model', surveys.toArray());
        if (loader) _this2.set('isLoading', false);

        set(pagination, 'current_page', surveys.get('meta').current_page);
        set(pagination, 'page_count', surveys.get('meta').page_count);
        _this2.set('paginationMeta', pagination);
      });
    },

    _patchSurveyStatus: function _patchSurveyStatus(index, status) {
      var _this3 = this;

      var surveys = this.get('model').toArray();

      surveys[index].set('status', status);
      surveys[index].save().then(function () {
        _this3._querySurveys(false);
      });
    },

    actions: {
      dispatchAction: function dispatchAction(survey, action) {
        this.send(action, survey);
      },

      createSurvey: function createSurvey() {
        this.transitionToRoute('companies.libraries.surveys.new');
      },

      changeStatusFilter: function changeStatusFilter(selectedStatusFilter) {
        var _this4 = this;

        this.set('selectedStatusFilter', selectedStatusFilter);
        this.set('isLoading', true);
        this.set('paginationMeta.current_page', 1);

        return new Promise(function (resolve, reject) {
          debounce(_this4, _this4._querySurveys, resolve, reject, 600);
        });
      },

      changeNameFilter: function changeNameFilter() {
        var _this5 = this;

        return new Promise(function (resolve, reject) {
          debounce(_this5, _this5._querySurveys, resolve, reject, 600);
        });
      },

      changePagination: function changePagination(selectedPagination) {
        var _this6 = this;

        var meta = this.get('paginationMeta');

        if (!this.get('isEmpty')) {
          this.set('selectedPagination', selectedPagination);
          set(meta, 'current_page', 1);
          this.set('paginationMeta', meta);

          return new Promise(function (resolve, reject) {
            debounce(_this6, _this6._querySurveys, resolve, reject, 600);
          });
        }
      },

      previousPage: function previousPage() {
        var _this7 = this;

        if (!this.get('isEmpty')) {
          var meta = this.get('paginationMeta');

          set(meta, 'current_page', meta['current_page'] - 1);
          this.set('paginationMeta', meta);

          return new Promise(function (resolve, reject) {
            debounce(_this7, _this7._querySurveys, resolve, reject, 600);
          });
        }
      },

      nextPage: function nextPage() {
        var _this8 = this;

        if (!this.get('isEmpty')) {
          var meta = this.get('paginationMeta');

          set(meta, 'current_page', meta['current_page'] + 1);
          this.set('paginationMeta', meta);

          return new Promise(function (resolve, reject) {
            debounce(_this8, _this8._querySurveys, resolve, reject, 600);
          });
        }
      },

      toggleStatus: function toggleStatus(index) {
        var surveys = this.get('model').toArray();

        if (['live', 'draft'].includes(surveys[index].get('status'))) {
          this._patchSurveyStatus(index, 'archived');
        } else if (surveys[index].get('status') === 'archived') {
          this._patchSurveyStatus(index, 'draft');
        }
      },

      setModel: function setModel() {
        this._querySurveys();
      },

      editSurvey: function editSurvey(survey) {
        this.transitionToRoute('companies.libraries.surveys.edit', survey.id);
      },

      duplicateSurvey: function duplicateSurvey(survey) {
        var _this9 = this;

        this.get('store').createRecord('survey', {
          duplicate_from: survey.id,
          status: 'draft',
          is_favorite: survey.is_favorite,
          is_new_survey: true
        }).save().then(function () {
          _this9._querySurveys(false);
        });
      },

      copySurveyId: function copySurveyId(survey) {
        navigator.clipboard.writeText(survey.id);
      },

      copySurveyUrl: function copySurveyUrl(survey) {
        var surveyUrl = _dueDashboardConfigEnvironment['default'].EmberENV['SURVEY_URL'] + '/' + survey.id + '?ref_link=dashboard';
        navigator.clipboard.writeText(surveyUrl);
      },

      makeSurveyFavorite: function makeSurveyFavorite(survey) {
        var _this10 = this;

        var surveys = this.get('model');
        var surveyObj = surveys.find(function (surveyObj) {
          return surveyObj.get('id') === survey.id;
        });
        surveyObj.set('is_favorite', !survey.is_favorite);
        surveyObj.save().then(function () {
          _this10._querySurveys(false);
        });
      },

      archiveSurvey: function archiveSurvey(survey) {
        var _this11 = this;

        var surveys = this.get('model');
        var surveyObj = surveys.find(function (surveyObj) {
          return surveyObj.get('id') === survey.id;
        });
        surveyObj.set('status', 'archived');
        surveyObj.save().then(function () {
          _this11._querySurveys(false);
        });
      },

      openDeleteSurveyAlert: function openDeleteSurveyAlert(survey) {
        this.set('surveyToDelete', survey);
        this.set('deleteSurveyAlertIsOpen', true);
      },

      closeDeleteSurveyAlert: function closeDeleteSurveyAlert() {
        this.set('deleteSurveyAlertIsOpen', false);
      },

      deleteSurvey: function deleteSurvey() {
        var _this12 = this;

        var survey = this.get('surveyToDelete');
        var surveys = this.get('model');
        var surveyObj = surveys.find(function (surveyObj) {
          return surveyObj.get('id') === survey.id;
        });

        surveyObj.set('status', 'deleted');
        var promise = surveyObj.save();
        this.set('deleteSurveyPromise', promise);
        promise.then(function () {
          _this12._querySurveys(false);
          _this12.set('surveyToDelete', null);
          _this12.set('deleteSurveyPromise', null);
          _this12.send('closeDeleteSurveyAlert');
        });
      },

      distributeSurvey: function distributeSurvey(survey) {
        this.transitionToRoute('companies.surveys.edit.publishing', survey.id);
      },

      restoreSurvey: function restoreSurvey(survey) {
        var _this13 = this;

        var surveys = this.get('model');
        var surveyObj = surveys.find(function (surveyObj) {
          return surveyObj.get('id') === survey.id;
        });
        surveyObj.set('status', 'draft');
        surveyObj.save().then(function () {
          _this13._querySurveys(false);
        });
      },

      publishSurvey: function publishSurvey(survey) {
        var _this14 = this;

        var surveys = this.get('model') || [];
        var index = surveys.findIndex(function (s) {
          return s.id === survey.id;
        });
        var surveyObj = surveys[index];

        surveyObj.set('status', 'live');
        surveyObj.save();

        surveyObj.get('draft_revision').then(function (draft) {
          draft.set('active', true);
          draft.save().then(function () {
            _this14._querySurveys(false);
          });
        });
        this.set('model', surveys);
      }
    }

  });
});