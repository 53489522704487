define('due-dashboard/models/scenario-condition', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var RSVP = _ember['default'].RSVP;
  var Model = _emberData['default'].Model;
  var belongsTo = _emberData['default'].belongsTo;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    scenario: belongsTo('scenario', { async: true }),
    position: attr('number'),

    type: _ember['default'].computed('isLoaded', function () {
      if (this.constructor) {
        return this.get('modelName');
      }
    }),

    defineSavepoint: function defineSavepoint() {
      return RSVP.resolve(this);
    },
    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
    }

  });
});