define('due-dashboard/routes/companies/surveys/edit/publishing', ['exports', 'ember', 'due-dashboard/mixins/survey-step'], function (exports, _ember, _dueDashboardMixinsSurveyStep) {
  exports['default'] = _ember['default'].Route.extend(_dueDashboardMixinsSurveyStep['default'], {

    model: function model() {
      return _ember['default'].RSVP.hash({
        survey_revision: this.modelFor('companies.surveys.edit').survey.get('active_revision')
      });
    },

    stepConfig: {
      layout: 'preview'
    },

    actions: {

      nextStep: function nextStep(type) {
        var _this = this;

        this.controller.set('isTransitioning', true);
        this.currentModel.survey_revision.reload().then(function () {
          var base = 'companies.surveys.edit';
          if (type === 'mailing-due' || type === 'sms-due') {
            _this.transitionTo(base + '.bulk-dispatch', {
              queryParams: {
                type: type === 'sms-due' ? 'sms' : 'email'
              }
            });
          } else {
            _this.transitionTo(base + '.' + type);
          }
        });
      }
    }
  });
});