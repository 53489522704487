define("due-dashboard/templates/components/due-translation-column", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/components/due-translation-column.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["translation-input ", ["get", "inputIconClass", ["loc", [null, [1, 32], [1, 46]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input", [], ["type", "text", "class", ["subexpr", "if", [["get", "hasError", ["loc", [null, [2, 32], [2, 40]]], 0, 0, 0, 0], "errorInput"], [], ["loc", [null, [2, 28], [2, 54]]], 0, 0], "value", ["subexpr", "@mut", [["get", "displayValue", ["loc", [null, [2, 61], [2, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholderValue", ["loc", [null, [2, 86], [2, 102]]], 0, 0, 0, 0]], [], [], 0, 0], "key-up", ["subexpr", "action", ["debounceUpdateValue"], [], ["loc", [null, [2, 110], [2, 140]]], 0, 0], "disabled", ["subexpr", "@mut", [["get", "notTranslatable", ["loc", [null, [2, 150], [2, 165]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 167]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});