define('due-dashboard/components/magento-custom-filter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['magento-custom-filter'],

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('company_integration.magento_survey_filters').then(function (msf) {
        if (msf.get('length') === 0) {
          _this.send('createSurveyFilter');
        }
      });
    },

    canAddNewFilter: true,

    actions: {

      createSurveyFilter: function createSurveyFilter() {
        this.store.createRecord('magento-survey-filter', {
          magento_company_integration: this.get('company_integration')
        });
      },

      deleteFilter: function deleteFilter(filter) {
        if (!filter.get('isNew')) {
          filter.destroyRecord();
        } else {
          filter.deleteRecord();
        }
      }
    }
  });
});