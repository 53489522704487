define('due-dashboard/models/management-question-category', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember'], function (exports, _emberData, _emberDataModelFragments, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    questions: _emberDataModelFragments['default'].fragmentArray('questions-category'),
    position: _emberData['default'].attr('number'),

    isValid: computed('title', function () {
      return this.get('title');
    })
  });
});