define('due-dashboard/models/new-question', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    kind: attr(),
    translations: attr(),
    properties: attr(),

    company: belongsTo('Company')
  });
});