define("due-dashboard/templates/components/due-table/elements/-variation-percent-up", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/due-table/elements/-variation-percent-up.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["left variation ", ["subexpr", "if", [["get", "displayGreen", ["loc", [null, [1, 32], [1, 44]]], 0, 0, 0, 0], "green"], [], ["loc", [null, [1, 27], [1, 54]]], 0, 0], "  ", ["subexpr", "if", [["get", "displayRed", ["loc", [null, [1, 61], [1, 71]]], 0, 0, 0, 0], "red"], [], ["loc", [null, [1, 56], [1, 79]]], 0, 0], " ", ["subexpr", "if", [["get", "inTable", ["loc", [null, [1, 85], [1, 92]]], 0, 0, 0, 0], "variation-table"], [], ["loc", [null, [1, 80], [1, 112]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "compValue", ["loc", [null, [2, 2], [2, 15]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});