define('due-dashboard/routes/settings/digests/translations', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return _ember['default'].RSVP.hash({
        digest: this.store.find('due_digest', params.digest_id),
        languages: this.store.query('language', { filter: { account_id: this.currentAccount.content.id } }),
        company: this.get('currentAccount.content.company')
      });
    }
  });
});