define('due-dashboard/components/chat-item', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    tagName: 'li',
    // TODO: clean/remove unused code unread
    classNameBindings: ['isUnread:chat-sidebar-list-unread-true:chat-sidebar-list-unread-false', 'isSelected'],

    updatedAtDate: computed('chat.last_message_date', function () {
      return (0, _moment['default'])(this.get('chat.last_message_date')).format('L');
    }),

    updatedAtTime: computed('chat.last_message_date', function () {
      return (0, _moment['default'])(this.get('chat.last_message_date')).format('LT');
    }),

    isSelected: computed('clickedChat.id', 'chat.id', function () {
      return this.get('clickedChat.id') === this.get('chat.id');
    }),

    preview: computed('chat.last_message', function () {
      if (this.get('chat.last_message')) {
        return this.get('chat.last_message');
      } else {
        return '-';
      }
    }),

    click: function click() {
      this.sendAction('action', this.get('chat'));
    }
  });
});