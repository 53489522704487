define("due-dashboard/templates/companies/surveys/edit/summary", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 10,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/companies/surveys/edit/summary.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-long-arrow-left");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 2, 2);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [9, 44], [9, 56]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["prevStep"], [], ["loc", [null, [9, 14], [9, 35]]], 0, 0], ["content", "button.text", ["loc", [null, [9, 97], [9, 112]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 13,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/companies/surveys/edit/summary.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "id", "save-survey-step");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [12, 74], [12, 86]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["finalizeRevision"], [], ["loc", [null, [12, 36], [12, 65]]], 0, 0], ["content", "button.text", ["loc", [null, [12, 89], [12, 104]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/companies/surveys/edit/summary.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "summary");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "survey-preview");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "summary-legend");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-block");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [7]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
        morphs[3] = dom.createMorphAt(element3, 1, 1);
        morphs[4] = dom.createMorphAt(element3, 2, 2);
        return morphs;
      },
      statements: [["inline", "t", ["survey.summary.title"], [], ["loc", [null, [2, 6], [2, 34]]], 0, 0], ["inline", "survey-preview", [], ["survey_draft", ["subexpr", "@mut", [["get", "model.survey_draft", ["loc", [null, [4, 34], [4, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "previewDevice", ["subexpr", "@mut", [["get", "previewDevice", ["loc", [null, [4, 67], [4, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "displayNavArrows", true], ["loc", [null, [4, 4], [4, 104]]], 0, 0], ["inline", "t", ["survey.summary.info"], [], ["loc", [null, [6, 28], [6, 55]]], 0, 0], ["block", "due-button", [], ["type", "cancel", "text", ["subexpr", "t", ["prev_step"], [], ["loc", [null, [8, 37], [8, 52]]], 0, 0], "customClass", "inline-block"], 0, null, ["loc", [null, [8, 4], [10, 19]]]], ["block", "due-button", [], ["type", "transition", "model", ["subexpr", "@mut", [["get", "model.survey_draft", ["loc", [null, [11, 43], [11, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "t", ["words.save"], [], ["loc", [null, [11, 67], [11, 83]]], 0, 0], "customClass", "inline-block", "icon", "save"], 1, null, ["loc", [null, [11, 4], [13, 19]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});