define('due-dashboard/controllers/companies/libraries/rating-scales/new', ['exports', 'ember', 'due-dashboard/helpers/color-formating'], function (exports, _ember, _dueDashboardHelpersColorFormating) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var copy = _ember['default'].copy;
  exports['default'] = Controller.extend({
    newRatingScaleName: '',
    newRatingScaleSize: 12,
    applyStyleToAllOpt: false,
    cancelAlertIsOpen: false,
    saveAlertIsOpen: false,
    editionMode: false,
    savePromise: null,
    customFonts: null,
    errorType: null,
    errorMsg: null,
    isLoading: true,
    setupAlertIsOpen: true,

    UNITIES: [{ value: 'px' }, { value: 'em' }, { value: 'ch' }],

    LINE_UNITIES: [{ value: '%' }, { value: 'px' }, { value: 'em' }, { value: 'ch' }],

    TEXT_DECORATIONS: [{ text: 'words.none', value: 'none' }, { text: 'words.underline', value: 'underline' }, { text: 'words.overline', value: 'overline' }, { text: 'words.line-through', value: 'line-through' }],
    selectedTextDecoration: computed('selectedRatingIndex', {
      get: function get() {
        var currentRatingStateStyle = this.get('currentRatingStateStyle');
        return { text: 'words.' + currentRatingStateStyle.text['text-decoration'], value: currentRatingStateStyle.text['text-decoration'] };
      },
      set: function set(propertyName, newValue) {
        var currentRatingStateStyle = this.get('currentRatingStateStyle');
        currentRatingStateStyle.text['text-decoration'] = newValue.value;
        return { text: 'words.' + currentRatingStateStyle.text['text-decoration'], value: currentRatingStateStyle.text['text-decoration'] };
      }
    }),
    FONT_WEIGHTS: [{ text: 'words.normal', value: 'normal' }, { text: 'words.300', value: '300' }, { text: 'words.400', value: '400' }, { text: 'words.500', value: '500' }, { text: 'words.600', value: '600' }, { text: 'words.700', value: '700' }, { text: 'words.800', value: '800' }],
    selectedFontWeight: computed('selectedRatingIndex', {
      get: function get() {
        var currentRatingStateStyle = this.get('currentRatingStateStyle');
        return { text: 'words.' + currentRatingStateStyle.text['font-weight'], value: currentRatingStateStyle.text['font-weight'] };
      },
      set: function set(propertyName, newValue) {
        var currentRatingStateStyle = this.get('currentRatingStateStyle');
        currentRatingStateStyle.text['font-weight'] = newValue.value;
        return { text: 'words.' + currentRatingStateStyle.text['font-weight'], value: currentRatingStateStyle.text['font-weight'] };
      }
    }),
    fallbackFonts: [{ name: 'Arial, sans-serif', value: 'Arial, sans-serif' }, { name: 'Inter, sans-serif', value: 'Inter, sans-serif' }, { name: 'Time New Roman, serif', value: 'Time New Roman, serif' }],
    selectedFontFamily: computed('selectedRatingIndex', {
      get: function get() {
        var currentRatingStateStyle = this.get('currentRatingStateStyle');
        var font = this._getCustomFont(currentRatingStateStyle.text['font-family']);
        return { name: font.name, value: font.value };
      },
      set: function set(propertyName, newValue) {
        var currentRatingStateStyle = this.get('currentRatingStateStyle');
        currentRatingStateStyle.text['font-family'] = newValue.value;
        return newValue;
      }
    }),

    defaultStyle: {
      "default": {
        "background": { "background-color": "#FFFFFF", "border-radius": "4px", "border-width": "1px", "border-color": "#CDD7DF", "box-shadow": "0px 0px 0px 0px #000000" },
        "text": { "font-family": "Inter, sans-serif", "color": "#1B3448", "font-weight": "600", "font-size": "14px", "text-decoration": "none" }
      },
      "hover": {
        "background": { "background-color": "#F6F6F6", "border-radius": "4px", "border-width": "1px", "border-color": "#CDD7DF", "box-shadow": "0px 0px 0px 0px #000000" },
        "text": { "font-family": "Inter, sans-serif", "color": "#1B3448", "font-weight": "600", "font-size": "14px", "text-decoration": "none" }
      },
      "focus": {
        "background": { "background-color": "#E1E8EE", "border-radius": "4px", "border-width": "1px", "border-color": "#71899C", "box-shadow": "0px 0px 0px 0px #000000" },
        "text": { "font-family": "Inter, sans-serif", "color": "#1B3448", "font-weight": "600", "font-size": "14px", "text-decoration": "none" }
      },
      "selected": {
        "background": { "background-color": "#FFFFFF", "border-radius": "4px", "border-width": "1px", "border-color": "#1FB5F4", "box-shadow": "0px 0px 0px 0px #000000" },
        "text": { "font-family": "Inter, sans-serif", "color": "#1B3448", "font-weight": "600", "font-size": "14px", "text-decoration": "none" }
      },
      "disabled": {
        "background": { "background-color": "#F6F8FB", "border-radius": "4px", "border-width": "1px", "border-color": "#CDD7DF", "box-shadow": "0px 0px 0px 0px #000000" },
        "text": { "font-family": "Inter, sans-serif", "color": "#1B3448", "font-weight": "600", "font-size": "14px", "text-decoration": "none" }
      }
    },

    style: computed(function () {
      var _this = this;

      return Array(this.get('newRatingScaleSize')).fill(0).map(function () {
        return copy(_this.get('defaultStyle'), true);
      });
    }),

    selectedRatingIndex: 0,

    RATING_SCALE_TYPES: [{ key: 'number', text: 'words.numbers' }, { key: 'image', text: 'words.images' }],
    selectedRatingScaleType: { key: 'number', text: 'words.numbers' },

    RATING_STATES: [{ key: 'default', text: 'words.default' }, { key: 'hover', text: 'words.hover' }, { key: 'focus', text: 'words.focus' }, { key: 'selected', text: 'words.selected' }, { key: 'disabled', text: 'words.disabled' }],

    selectedRatingState: { key: 'default', text: 'words.default' },

    scoreRatingValueValidation: computed('selectedRatingIndex', 'ratings', function () {
      var result = [];
      var ratings = this.get('ratings');

      var _loop = function (i) {
        var rating = ratings[i];
        result.push(rating.score != null && rating.score.toString().length > 0 && ratings.map(function (r) {
          return '' + r.score;
        }).filter(function (score, index) {
          return index != i && score != 'null';
        }).indexOf(rating.score) === -1);
      };

      for (var i = 0; i < this.get('newRatingScaleSize') - 1; i++) {
        _loop(i);
      }
      result.push(true);
      return result;
    }),

    displayedRatingValueValidation: computed('selectedRatingIndex', 'ratings', function () {
      var result = [];
      var ratings = this.get('ratings');

      var _loop2 = function (i) {
        var rating = ratings[i];
        result.push(rating.display_scale_value != null && rating.display_scale_value.toString().length > 0 && ratings.map(function (r) {
          return '' + r.display_scale_value;
        }).filter(function (display_scale_value, index) {
          return index != i && display_scale_value != 'N/A';
        }).indexOf(rating.display_scale_value) === -1);
      };

      for (var i = 0; i < this.get('newRatingScaleSize') - 1; i++) {
        _loop2(i);
      }
      result.push(true);
      return result;
    }),

    ratingsDidChange: _ember['default'].observer('ratings', function () {
      var ratings = this.get('ratings');
      ratings.slice(-1)[0].position = ratings.length - 1;
    }),

    saveDisabled: computed('newRatingScaleName', 'ratings.@each', 'selectedRatingScaleType', function () {
      var ratings = this.get('ratings');
      var ratingType = this.get('selectedRatingScaleType').key;
      var emptyRatings = ratings.map(function (rating, index) {
        return index < ratings.length - 1 ? rating.score != null && rating.score.toString().length > 0 && rating.display_scale_value != null && rating.display_scale_value.toString().length > 0 : true;
      }).includes(false);
      var emptyImages = ratings.map(function (rating, index) {
        return index < ratings.length - 1 ? rating.score != null && rating.score.toString().length > 0 && (rating.picture_raw_data != null && rating.picture_raw_data.length > 0 || rating.picture != null && rating.picture.length > 0) : true;
      }).includes(false);
      var enabled = this.get('newRatingScaleName').length > 0 && ratingType.length > 0 && (ratingType == 'number' && !emptyRatings && !this.get('scoreRatingValueValidation').includes(false) && !this.get('displayedRatingValueValidation').includes(false) || ratingType == 'image' && !emptyImages);
      return !enabled;
    }),

    decreaseRatingScaleSizeDisabled: computed('newRatingScaleSize', function () {
      var style = this.get('style');
      var newRatingScaleSize = this.get('newRatingScaleSize');
      var newSelectedRatingIndex = newRatingScaleSize - 1;
      var selectedRatingIndex = this.get('selectedRatingIndex');

      if (!style[selectedRatingIndex]) {
        this.set('selectedRatingIndex', newSelectedRatingIndex);
      }

      if (newRatingScaleSize < this.get('MIN_SIZE_LIMIT')) {
        this.set('newRatingScaleSize', this.get('MIN_SIZE_LIMIT'));
      }

      if (newRatingScaleSize > this.get('MAX_SIZE_LIMIT')) {
        this.set('newRatingScaleSize', this.get('MAX_SIZE_LIMIT'));
      }

      return this.get('newRatingScaleSize') <= this.get('MIN_SIZE_LIMIT');
    }),

    increaseRatingScaleSizeDisabled: computed('newRatingScaleSize', function () {
      this._applyStyleToPreview(this.get('newRatingScaleSize') - 1);
      return this.get('newRatingScaleSize') >= this.get('MAX_SIZE_LIMIT');
    }),

    newComputedRatingScaleSize: computed('newRatingScaleSize', {
      get: function get() {
        return this.get('newRatingScaleSize') - 1;
      },
      set: function set(propertyName, newValue) {
        this.set('newRatingScaleSize', parseInt(newValue) + 1);
        return newValue;
      }
    }),

    currentRatingStateStyle: computed('selectedRatingState', 'style', 'selectedRatingIndex', function () {
      var style = this.get('style');
      var selectedState = this.get('selectedRatingState');
      var newRatingScaleSize = this.get('newRatingScaleSize');
      var selectedRatingIndex = this.get('selectedRatingIndex');
      var currentStateStyle = style[selectedRatingIndex][selectedState.key];
      var unityRegexp = /[a-z\%]/g;
      var valueRegexp = /[0-9\.]/g;

      if (!style[selectedRatingIndex]) {
        this.set('selectedRatingIndex', newRatingScaleSize - 1);
        selectedRatingIndex = newRatingScaleSize - 1;
      }

      var elementsToParse = [{ parent: currentStateStyle.background, field: 'border-radius' }, { parent: currentStateStyle.background, field: 'border-width' }, { parent: currentStateStyle.text, field: 'font-size' }];

      // separate value & unity in != fields
      elementsToParse.forEach(function (element) {
        if (element.parent[element.field] && element.parent[element.field].match(unityRegexp)) {
          set(element.parent, element.field + '-unity', element.parent[element.field].match(unityRegexp).join(''));
          set(element.parent, element.field, element.parent[element.field].match(valueRegexp).join(''));
        }
      });

      var boxShadowValues = currentStateStyle.background['box-shadow'].split(' ');
      var boxShadowFields = ['box-shadow-x', 'box-shadow-y', 'box-shadow-blur', 'box-shadow-spread'];

      boxShadowFields.forEach(function (shadowField, index) {
        if (boxShadowValues[index] && boxShadowValues[index].match(valueRegexp)) {
          set(currentStateStyle.background, shadowField + '-unity', boxShadowValues[index].match(unityRegexp).join(''));
          set(currentStateStyle.background, shadowField, boxShadowValues[index].match(valueRegexp).join(''));
        }
      });

      set(currentStateStyle.background, 'box-shadow-color', boxShadowValues[boxShadowValues.length - 1]);

      return currentStateStyle;
    }),

    displayDesignPart: computed('selectedRatingScaleType', 'currentRatingStateStyle', function () {
      var display = this.get('selectedRatingScaleType').key === 'number';
      this._refreshPreviewState();
      return display;
    }),

    _setRatingScaleFieldValue: function _setRatingScaleFieldValue(index, term, field) {
      if (term == null || term == undefined || index == null || index == undefined) {
        return;
      }
      var ratings = this.get('ratings');

      set(ratings[index], field, term);
      this.set('ratings', ratings);
      this.notifyPropertyChange('ratings');
    },

    init: function init() {
      this._resetPageState();
      if (!this.get('customFonts')) {
        this._queryCustomFonts();
      }
    },

    _queryCustomFonts: function _queryCustomFonts() {
      var _this2 = this;

      this.set('isLoading', true);
      this.store.query('font', { sort: '-created_at' }).then(function (fonts) {
        var fallbackFonts = _this2.get('fallbackFonts');
        fonts = _this2._formatFonts(fonts);
        _this2.set('customFonts', fallbackFonts.concat(fonts).sort(function (a, b) {
          return a['name'].localeCompare(b['name']);
        }));
        _this2.set('isLoading', false);
      });
    },

    _getCustomFont: function _getCustomFont(font_family) {
      return this.get('customFonts').find(function (f) {
        return f.value.startsWith(font_family);
      }) || { name: font_family, value: font_family };
    },

    _initNewRatingObj: function _initNewRatingObj() {
      var ratings = this.get('ratings');
      var length = ratings.length - 1;
      return {
        position: length,
        score: length,
        custom_profile: 'passive',
        display_scale_value: length,
        picture_raw_data: ''
      };
    },

    _applyStyleToPreview: function _applyStyleToPreview(index) {
      if (!this.get('editionMode')) return;
      var style = this.get('style');
      var selectedRatingIndex = this.get('selectedRatingIndex');
      var selectedRatingState = this.get('selectedRatingState');
      var applyStyleToAllOpt = this.get('applyStyleToAllOpt');
      var ratingElement = style[index] ? document.getElementsByClassName('preview-rating-' + index)[0] : null;

      if (!ratingElement) {
        return;
      }
      if (!this.get('customFonts')) {
        this._queryCustomFonts();
      }

      var inputElement = document.getElementsByClassName('input-rating-' + index)[0].getElementsByClassName('input-container')[0];
      var ratingElementText = ratingElement.children[0];
      var inputElementText = inputElement.children[0];
      var defaultStateStyle = style[index]['default'];
      var currentRatingStateStyle = style[index][selectedRatingState.key];

      if (index == selectedRatingIndex || applyStyleToAllOpt) {
        currentRatingStateStyle = this.get('currentRatingStateStyle');
      }

      var _addPropertyUnity = function _addPropertyUnity(u) {
        return u == undefined ? "" : u;
      };

      defaultStateStyle.text['font-family'] = this._getCustomFont(defaultStateStyle.text['font-family']).value;

      // Score value - bottom line
      ratingElement.style.backgroundColor = currentRatingStateStyle.background['background-color'];
      ratingElement.style.borderColor = currentRatingStateStyle.background['border-color'];
      ratingElement.style.boxShadow = currentRatingStateStyle.background['box-shadow'];
      ratingElement.style.borderRadius = defaultStateStyle.background['border-radius'] + _addPropertyUnity(defaultStateStyle.background['border-radius-unity']);
      ratingElement.style.borderWidth = defaultStateStyle.background['border-width'] + _addPropertyUnity(defaultStateStyle.background['border-width-unity']);

      ratingElementText.style.color = currentRatingStateStyle.text['color'];
      ratingElementText.style.fontFamily = defaultStateStyle.text['font-family'];
      ratingElementText.style.fontWeight = defaultStateStyle.text['font-weight'];
      ratingElementText.style.fontSize = defaultStateStyle.text['font-size'] + _addPropertyUnity(currentRatingStateStyle.text['font-size-unity']);
      ratingElementText.style.textDecoration = defaultStateStyle.text['text-decoration'];

      // Displayed value - top line
      inputElement.style.backgroundColor = currentRatingStateStyle.background['background-color'];
      inputElement.style.borderColor = currentRatingStateStyle.background['border-color'];
      inputElement.style.boxShadow = currentRatingStateStyle.background['box-shadow'];
      inputElement.style.borderRadius = defaultStateStyle.background['border-radius'] + _addPropertyUnity(defaultStateStyle.background['border-radius-unity']);
      inputElement.style.borderWidth = defaultStateStyle.background['border-width'] + _addPropertyUnity(defaultStateStyle.background['border-width-unity']);

      inputElementText.style.color = currentRatingStateStyle.text['color'];
      inputElementText.style.fontFamily = defaultStateStyle.text['font-family'];
      inputElementText.style.fontWeight = defaultStateStyle.text['font-weight'];
      inputElementText.style.fontSize = defaultStateStyle.text['font-size'] + _addPropertyUnity(defaultStateStyle.text['font-size-unity']);
      inputElementText.style.textDecoration = defaultStateStyle.text['text-decoration'];
    },

    _initNewStyleObj: function _initNewStyleObj() {
      var style = this.get('style');
      var i = style.length - 2;

      return {
        'default': copy(style[i]['default'], true),
        focus: copy(style[i].focus, true),
        hover: copy(style[i].hover, true),
        selected: copy(style[i].selected, true),
        disabled: copy(style[i].disabled, true)
      };
    },

    _refreshPreviewState: function _refreshPreviewState() {
      for (var i = 0; i < this.get('newRatingScaleSize'); i++) {
        this._applyStyleToPreview(i);
      }
    },

    _applyStyleToAll: function _applyStyleToAll() {
      var selectedRatingIndex = this.get('selectedRatingIndex');
      var style = this.get('style');

      for (var i = 0; i < this.get('newRatingScaleSize'); i++) {
        if (i != selectedRatingIndex) {
          style[i]['default'] = copy(style[selectedRatingIndex]['default'], true);
          style[i].focus = copy(style[selectedRatingIndex].focus, true);
          style[i].hover = copy(style[selectedRatingIndex].hover, true);
          style[i].selected = copy(style[selectedRatingIndex].selected, true);
          style[i].disabled = copy(style[selectedRatingIndex].disabled, true);
        }
      }
      this.set('style', style);
      this._refreshPreviewState();
    },

    _computeRatingAttributes: function _computeRatingAttributes() {
      var _this3 = this;

      var ratings = this.get('ratings');
      var style = this.get('style');
      var selectedRatingScaleType = this.get('selectedRatingScaleType').key;
      var fallbackFonts = this.get('fallbackFonts').map(function (font) {
        return font.name;
      });
      var rating_attibutes = ratings.map(function (rating, index) {
        if (null != rating.score) {
          set(rating, 'score', isNaN(parseInt(rating.score)) ? parseInt(rating.get('score')) : parseInt(rating.score));
        }
        if (selectedRatingScaleType == 'number') {
          set(rating, 'style', _this3._computeStyleAttributes(style[index]));
          if (!fallbackFonts.includes(style[index]['default'].text['font-family'])) {
            style[index]['default'].text['font-family'] = _this3._getCustomFont(style[index]['default'].text['font-family']).name;
            set(rating, 'font_attributes', { name: style[index]['default'].text['font-family'] });
          }
          delete rating['picture_raw_data'];
        } else {
          delete rating['display_scale_value'];
        }

        return rating;
      });

      return rating_attibutes.slice(0, -1);
    },

    _computeStyleAttributes: function _computeStyleAttributes(style) {
      var ratingStates = this.get('RATING_STATES');

      ratingStates.forEach(function (state) {
        var elementsToParse = [{ parent: style[state.key].background, field: 'border-radius' }, { parent: style[state.key].background, field: 'border-width' }, { parent: style[state.key].text, field: 'font-size' }];

        elementsToParse.forEach(function (element) {
          if (element.parent[element.field + '-unity']) {
            set(element.parent, element.field, element.parent[element.field].toString() + element.parent[element.field + '-unity']);
          }
        });

        var fieldsToDelete = [{ parent: style[state.key].text, field: 'font-size-unity' }, { parent: style[state.key].background, field: 'border-radius-unity' }, { parent: style[state.key].background, field: 'border-width-unity' }, { parent: style[state.key].background, field: 'box-shadow-x' }, { parent: style[state.key].background, field: 'box-shadow-x-unity' }, { parent: style[state.key].background, field: 'box-shadow-y' }, { parent: style[state.key].background, field: 'box-shadow-y-unity' }, { parent: style[state.key].background, field: 'box-shadow-blur' }, { parent: style[state.key].background, field: 'box-shadow-blur-unity' }, { parent: style[state.key].background, field: 'box-shadow-spread' }, { parent: style[state.key].background, field: 'box-shadow-spread-unity' }, { parent: style[state.key].background, field: 'box-shadow-color' }];

        fieldsToDelete.forEach(function (field) {
          delete field.parent[field.field];
        });
      });
      return style;
    },

    _resetStyleObject: function _resetStyleObject() {
      var _this4 = this;

      return Array(this.get('newRatingScaleSize')).fill(0).map(function () {
        return copy(_this4.get('defaultStyle'), true);
      });
    },

    _formatRatings: function _formatRatings(ratings) {
      return ratings.map(function (rating) {
        return {
          position: rating.get('position'),
          custom_profile: rating.get('custom_profile'),
          score: rating.get('score'),
          display_scale_value: rating.get('display_scale_value'),
          picture: rating.get('picture')
        };
      });
    },

    _formatFonts: function _formatFonts(fonts) {
      this._initFontFace(fonts);
      return fonts.map(function (font) {
        return {
          name: font.get('name'),
          value: font.get('name') + '-' + font.get('id')
        };
      });
    },

    _initFontFace: function _initFontFace(fonts) {
      var fontFaces = fonts.map(function (font) {
        return new FontFace(font.get('name') + '-' + font.get('id'), 'url(' + font.get('font') + ')');
      });

      fontFaces.forEach(function (fontFace) {
        fontFace.load();
        document.fonts.add(fontFace);
      });
    },

    _updateStyleField: function _updateStyleField(field, selectedValue) {
      var style = this.get('style');
      var selectedRatingIndex = this.get('selectedRatingIndex');
      var selectedRatingState = this.get('selectedRatingState');
      var value = typeof selectedValue === 'string' ? selectedValue : selectedValue.value;

      if (field.includes('color')) {
        value = _dueDashboardHelpersColorFormating['default'].compute(value);
      }

      if (field.includes('background')) {
        set(style, selectedRatingIndex + '.' + selectedRatingState.key + '.' + field, value);
      } else {
        set(style, selectedRatingIndex + '.' + selectedRatingState.key + '.text.' + field, value);
      }
      this.set('style', style);
      this._applyStyleToPreview(this.get('selectedRatingIndex'));
    },

    _resetPageState: function _resetPageState() {
      this.set('newRatingScaleName', '');
      this.set('newRatingScaleSize', 12);
      this.set('DEFAULT_SIZE', 12);
      this.set('MIN_SIZE_LIMIT', 3);
      this.set('MAX_SIZE_LIMIT', 12);
      var ratings = [{ position: 0, score: 0, display_scale_value: 0, picture_raw_data: '', custom_profile: 'detractor' }, { position: 1, score: 1, display_scale_value: 1, picture_raw_data: '', custom_profile: 'detractor' }, { position: 2, score: 2, display_scale_value: 2, picture_raw_data: '', custom_profile: 'detractor' }, { position: 3, score: 3, display_scale_value: 3, picture_raw_data: '', custom_profile: 'detractor' }, { position: 4, score: 4, display_scale_value: 4, picture_raw_data: '', custom_profile: 'detractor' }, { position: 5, score: 5, display_scale_value: 5, picture_raw_data: '', custom_profile: 'detractor' }, { position: 6, score: 6, display_scale_value: 6, picture_raw_data: '', custom_profile: 'detractor' }, { position: 7, score: 7, display_scale_value: 7, picture_raw_data: '', custom_profile: 'passive' }, { position: 8, score: 8, display_scale_value: 8, picture_raw_data: '', custom_profile: 'passive' }, { position: 9, score: 9, display_scale_value: 9, picture_raw_data: '', custom_profile: 'promoter' }, { position: 10, score: 10, display_scale_value: 10, picture_raw_data: '', custom_profile: 'promoter' }, { position: 11, score: null, display_scale_value: 'N/A', picture_raw_data: '' }];
      this.set('defaultRatings', ratings);
      this.set('applyStyleToAllOpt', false);
      this.set('cancelAlertIsOpen', false);
      this.set('saveAlertIsOpen', false);
      this.set('selectedRatingIndex', 0);
      this.set('selectedRatingScaleType', { key: 'number', text: 'words.numbers' });
      this.set('selectedRatingState', { key: 'default', text: 'words.default' });
      this.set('ratings', copy(this.get('defaultRatings'), true));
      this.set('style', this._resetStyleObject());
      this.set('images', []);
      this.set('model', null);
      this.set('editionMode', false);
      this.set('savePromise', null);
      this.set('nameError', false);
      this.set('setupAlertIsOpen', true);
    },

    _resetError: function _resetError() {
      this.set('errorType', null);
      this.set('errorMsg', null);
    },

    actions: {
      cancel: function cancel() {
        this._resetPageState();
        this.transitionToRoute('companies.libraries.rating-scales');
      },

      save: function save() {
        var _this5 = this;

        var selectedRatingScaleType = this.get('selectedRatingScaleType').key;
        var newRatingScaleName = this.get('newRatingScaleName');
        var ratings_attributes = this._computeRatingAttributes();
        var newRatingScaleSize = ratings_attributes.length;
        var na_design = 'number' == selectedRatingScaleType ? this.get('ratings').slice(-1)[0] : null;
        var editionMode = this.get('editionMode');
        var model = null;
        var promise = null;

        if (editionMode) {
          model = this.get('model');
          model.set('scale', newRatingScaleSize);
          model.set('name', newRatingScaleName);
          model.set('kind', selectedRatingScaleType);
          model.set('ratings_attributes', ratings_attributes);
          model.set('na_design', na_design);
        } else {
          var properties = {
            rating_scale_type: 'custom',
            scale: newRatingScaleSize,
            name: newRatingScaleName,
            kind: selectedRatingScaleType,
            ratings_attributes: ratings_attributes,
            status: 'live',
            is_nps: false,
            has_custom_html: false,
            na_design: na_design
          };
          model = this.store.createRecord('rating-scale', properties);
        }

        promise = model.save();

        this.set('savePromise', promise);

        promise.then(function () {
          _this5._resetPageState();
          _this5.transitionToRoute('companies.libraries.rating-scales');
        })['catch'](function () {
          _this5.set('saveAlertIsOpen', false);
          _this5.set('nameError', true);
        });
      },

      onChangeRatingScaleName: function onChangeRatingScaleName(text) {
        if (this.get('nameError')) {
          this.set('nameError', false);
        }
        this.set('newRatingScaleName', text);
      },

      editNewScaleSize: function editNewScaleSize(formula) {
        var ratings = this.get('ratings');
        var style = this.get('style');
        var images = this.get('images');
        if (formula === 'remove') {
          this.set('newRatingScaleSize', parseInt(this.get('newRatingScaleSize')) - 1);
          ratings.removeAt(ratings.length - 2);
          images.pop();
          style.removeAt(style.length - 2);
        } else {
          this.set('newRatingScaleSize', parseInt(this.get('newRatingScaleSize')) + 1);
          ratings.insertAt(ratings.length - 1, this._initNewRatingObj());
          images.push('');
          style.insertAt(style.length - 1, this._initNewStyleObj());
        }

        this.set('ratings', ratings);
        this.set('style', style);
        this.set('images', images);
        this.notifyPropertyChange('ratings');
        this.notifyPropertyChange('style');
        this._applyStyleToPreview(this.get('selectedRatingIndex'));
      },

      checkRatingScaleSizeValue: function checkRatingScaleSizeValue() {
        if (parseInt(this.get('newRatingScaleSize')) < this.get('MIN_SIZE_LIMIT')) {
          this.set('newRatingScaleSize', this.get('MIN_SIZE_LIMIT'));
        } else if (parseInt(this.get('newRatingScaleSize')) > this.get('MAX_SIZE_LIMIT')) {
          this.set('newRatingScaleSize', this.get('MAX_SIZE_LIMIT'));
        }
        if (isNaN(parseInt(this.get('newRatingScaleSize')))) {
          this.set('newRatingScaleSize', this.get('DEFAULT_SIZE'));
        }
        this.notifyPropertyChange('newRatingScaleSize');
        this._applyStyleToPreview(this.get('selectedRatingIndex'));
      },

      setNewScaleSize: function setNewScaleSize() {
        var _this6 = this;

        var size = parseInt(this.get('newRatingScaleSize'));
        var ratings = this.get('ratings');
        var style = this.get('style');
        var images = this.get('images');
        var threshold = Math.abs(size - ratings.length);

        for (var i = 0; i < threshold; i++) {
          if (size > ratings.length) {
            ratings.insertAt(ratings.length - 1, this._initNewRatingObj());
            images.push('');
            style.insertAt(style.length - 1, this._initNewStyleObj());
          } else if (size < ratings.length) {
            ratings.removeAt(ratings.length - 2);
            images.pop();
            style.removeAt(style.length - 2);
          }
        }

        this.set('ratings', ratings);
        this.set('style', style);
        this.set('images', images);
        this.notifyPropertyChange('ratings');
        this.notifyPropertyChange('style');
        setTimeout(function () {
          _this6._refreshPreviewState();
        }, 0);
      },

      changeRatingScaleType: function changeRatingScaleType(selectedType) {
        this.set('selectedRatingScaleType', selectedType);
      },

      changeRatingState: function changeRatingState(selectedState) {
        this.set('selectedRatingState', selectedState);
      },

      setSelectRatingIndex: function setSelectRatingIndex(index) {
        this.set('selectedRatingIndex', index);
        if (this.get('applyStyleToAllOpt')) this.set('applyStyleToAllOpt', false);
      },

      editScoreRatingValue: function editScoreRatingValue(index, term) {
        this._setRatingScaleFieldValue(index, term, 'score');
        this._applyStyleToPreview(this.get('selectedRatingIndex'));
        this.notifyPropertyChange('scoreRatingValueValidation');
      },

      editDisplayedRatingValue: function editDisplayedRatingValue(index, term) {
        this._setRatingScaleFieldValue(index, term, 'display_scale_value');
        this.notifyPropertyChange('displayedRatingValueValidation');
      },

      refreshPreview: function refreshPreview(field, value) {
        var applyToAll = this.get('applyStyleToAllOpt');
        if (field && value) this._updateStyleField(field, value);
        if (applyToAll) {
          this._applyStyleToAll();
        } else {
          this._applyStyleToPreview(this.get('selectedRatingIndex'));
        }
        this.notifyPropertyChange('currentRatingStateStyle');
        this.notifyPropertyChange('ratings');
      },

      refreshShadowBox: function refreshShadowBox() {
        var backgroundStyle = this.get('currentRatingStateStyle').background;
        var style = this.get('style');
        var selectedRatingIndex = this.get('selectedRatingIndex');
        var selectedRatingState = this.get('selectedRatingState');
        var shadowColor = _dueDashboardHelpersColorFormating['default'].compute(backgroundStyle['box-shadow-color']);

        var shadowBox = [backgroundStyle['box-shadow-x'] + backgroundStyle['box-shadow-x-unity'], backgroundStyle['box-shadow-y'] + backgroundStyle['box-shadow-y-unity'], backgroundStyle['box-shadow-blur'] + backgroundStyle['box-shadow-blur-unity'], backgroundStyle['box-shadow-spread'] + backgroundStyle['box-shadow-spread-unity'], shadowColor].join(' ');

        set(style, selectedRatingIndex + '.' + selectedRatingState.key + '.background.box-shadow', shadowBox);
        this.set('style', style);
        this.notifyPropertyChange('style');
      },

      toggleApplyToAllOpt: function toggleApplyToAllOpt() {
        this.toggleProperty('applyStyleToAllOpt');

        var applyToAll = this.get('applyStyleToAllOpt');
        if (applyToAll) {
          this._applyStyleToAll();
        }
      },

      toggleModalIsOpen: function toggleModalIsOpen(modal) {
        this.toggleProperty(modal + 'AlertIsOpen');
      },

      setRatingImage: function setRatingImage(index, image) {
        this._resetError();
        this._setRatingScaleFieldValue(index, image, 'picture_raw_data');
      },

      setEditionValues: function setEditionValues() {
        var model, ratings, styles, na_design, na_style;
        return regeneratorRuntime.async(function setEditionValues$(context$1$0) {
          var _this7 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.set('setupAlertIsOpen', false);
              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.get('model'));

            case 3:
              model = context$1$0.sent;
              context$1$0.next = 6;
              return regeneratorRuntime.awrap(model.get('ratings'));

            case 6:
              ratings = context$1$0.sent;
              styles = ratings.map(function (rating) {
                return rating.get('style');
              });
              na_design = model.get('na_design');
              na_style = na_design ? na_design['style'] : copy(this.get('defaultStyle'), true);

              styles[styles.length] = na_style;
              ratings.pushObject(this.store.createRecord('rating', {
                position: ratings.get('length'),
                display_scale_value: 'N/A',
                style: na_style,
                score: null
              }));

              model.set('scale', ratings.length);
              this.set('newRatingScaleName', model.get('name'));
              this.set('newRatingScaleSize', model.get('scale'));
              this.set('selectedRatingScaleType', { key: model.get('kind'), text: 'words.' + model.get('kind') + 's' });
              this.set('style', styles);
              this.set('ratings', this._formatRatings(ratings.toArray()));
              this.set('editionMode', true);
              this.notifyPropertyChange('newRatingScaleName');
              setTimeout(function () {
                return _this7.set('applyStyleToAllOpt', true);
              }, 500);

            case 21:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      setFieldUnity: function setFieldUnity(object, attr, unity) {
        var style = this.get('style');
        var applyToAll = this.get('applyStyleToAllOpt');
        var selectedRatingState = this.get('selectedRatingState');
        var selectedRatingIndex = this.get('selectedRatingIndex');

        if (attr.includes('box-shadow')) {
          var backgroundStyle = this.get('currentRatingStateStyle').background;
          set(backgroundStyle, attr + '-unity', unity.value);

          var shadowBox = [backgroundStyle['box-shadow-x'] + backgroundStyle['box-shadow-x-unity'], backgroundStyle['box-shadow-y'] + backgroundStyle['box-shadow-y-unity'], backgroundStyle['box-shadow-blur'] + backgroundStyle['box-shadow-blur-unity'], backgroundStyle['box-shadow-spread'] + backgroundStyle['box-shadow-spread-unity'], backgroundStyle['box-shadow-color']].join(' ');
          set(style, selectedRatingIndex + '.' + selectedRatingState.key + '.background.box-shadow', shadowBox);
        } else {
          set(style, selectedRatingIndex + '.' + selectedRatingState.key + '.' + object + '.' + attr, style[selectedRatingIndex][selectedRatingState.key][object][attr].toString() + unity.value);
        }

        this.set('style', style);
        this.notifyPropertyChange('style');

        if (applyToAll) {
          this._applyStyleToAll();
        }
      },

      onError: function onError(type, msg) {
        this.set('errorType', type);
        this.set('errorMsg', msg);
      },

      validateSetup: function validateSetup() {
        var _this8 = this;

        var selectedRatingScaleType = this.get('selectedRatingScaleType').key;
        var newRatingScaleName = this.get('newRatingScaleName');
        var ratings_attributes = this._computeRatingAttributes();
        var newRatingScaleSize = ratings_attributes.length;
        var na_design = 'number' == selectedRatingScaleType ? this.get('ratings').slice(-1)[0] : null;
        var model = null;
        var promise = null;
        var properties = {
          rating_scale_type: 'custom',
          scale: newRatingScaleSize,
          name: newRatingScaleName,
          kind: selectedRatingScaleType,
          ratings_attributes: ratings_attributes,
          status: 'live',
          is_nps: false,
          has_custom_html: false,
          na_design: na_design
        };
        model = this.store.createRecord('rating-scale', properties);
        promise = model.save();

        this.set('savePromise', promise);

        promise.then(function (ratingScale) {
          _this8._resetPageState();
          _this8.transitionToRoute('companies.libraries.rating-scales.edit', ratingScale.get('id'));
        })['catch'](function () {
          _this8.set('saveAlertIsOpen', false);
          _this8.set('nameError', true);
        });
      },

      togglePromoterScore: function togglePromoterScore(index) {
        var ratings = this.get('ratings');
        var custom_profile = 'promoter';

        if (ratings[index].custom_profile === 'promoter') custom_profile = 'passive';
        set(ratings[index], 'custom_profile', custom_profile);
        this.set('ratings', ratings);
        this.notifyPropertyChange('ratings');
      },

      toggleDetractorScore: function toggleDetractorScore(index) {
        var ratings = this.get('ratings');
        var custom_profile = 'detractor';

        if (ratings[index].custom_profile === 'detractor') custom_profile = 'passive';
        set(ratings[index], 'custom_profile', custom_profile);
        this.set('ratings', ratings);
        this.notifyPropertyChange('ratings');
      },

      openSettingsAlert: function openSettingsAlert() {
        this.set('setupAlertIsOpen', true);
      },

      closeSettingsAlert: function closeSettingsAlert() {
        this.set('setupAlertIsOpen', false);
      }
    }
  });
});