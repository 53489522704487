define('due-dashboard/helpers/date-format-array', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports.dateFormatArray = dateFormatArray;

  function dateFormatArray(date) {
    var now = (0, _moment['default'])();
    var diff = now.diff(date, 'month');

    if (diff > 0) return (0, _moment['default'])(date).format('ll');else return (0, _moment['default'])(date).fromNow();
  }

  exports['default'] = _ember['default'].Helper.helper(dateFormatArray);
});