define('due-dashboard/routes/settings/management-question-category/edit', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    model: function model(params) {
      if (!params.category_id || params.category_id === "null") {
        return this.store.createRecord('management-question-category');
      } else {
        return this.store.findRecord('management-question-category', params.category_id);
      }
    },

    actions: {
      saveCategory: function saveCategory(category) {
        var _this = this;

        this.controller.set('saveCategoryPromise', category.save().then(function () {
          return _this.transitionTo('settings.management-question-category.index');
        }));
      },

      previous: function previous(category) {
        if (category.get('isNew')) {
          category.deleteRecord();
          category = null;
        } else {
          category.rollbackAttributes();
        }
        this.transitionTo('settings.management-question-category.index');
      },

      didTransition: function didTransition() {
        var _this2 = this;

        var term = this.controller.get('questions.content').map(function (el) {
          return el.get('text');
        }).join('#$#');
        this.store.query('question-category-suggestion', { filter: { text: term } }).then(function (questions) {
          _this2.controller.set('suggestions', questions);
        });
      }
    }
  });
});