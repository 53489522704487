define('due-dashboard/components/sortable-selectable-list', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    classNames: ['sortable-selectable-list'],

    allIsSelected: computed('selected_elements.ids', 'selected_elements_ids', 'elements.length', function () {
      var elements = this.get('elements');
      var elements_ids = elements.mapBy('id');
      var selected_items = this.get('selected_elements.ids').filter(function (a) {
        return elements_ids.includes(a);
      });
      return selected_items.length === elements.get('length');
    }),

    didRender: function didRender() {
      var isOverflowed = function isOverflowed(element) {
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
      };
      this.$('.wrapp-overflow').map(function (index, elem) {
        if (isOverflowed(elem)) {
          elem.classList.add('is-overflow');
        } else {
          elem.classList.remove('is-overflow');
        }
      });
    },

    isLoading: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Promise.resolve(this.get('elements')).then(function (elems) {
        if (!_this.isDestroyed) {
          _this.set('elements', elems);
          _this.set('isLoading', false);
        }
      });
    },

    columnsSorting: ['position'],
    orderedColumns: computed.sort('columns', 'columnsSorting'),

    selected_elements_ids: computed('elements.[]', 'selected_elements.ids', 'selected_elements', function () {
      var _this2 = this;

      var ret = {};
      this.get('elements').forEach(function (elem) {
        if (_this2.get('selected_elements.ids').indexOf(elem.get('id')) !== -1) {
          ret[elem.get('id')] = true;
        }
      });
      return ret;
    }),

    mouseMove: function mouseMove(e) {
      this.mouseEvent(e);
    },

    mouseLeave: function mouseLeave(e) {
      this.mouseEvent(e);
    },

    mouseEnter: function mouseEnter(e) {
      this.mouseEvent(e);
    },

    mouseEvent: function mouseEvent(e) {
      if (this.get('isDragging')) {
        var parentOffset = this.$().offset();
        var relX = e.pageX - parentOffset.left;
        var elemWidth = this.$().width();
        switch (true) {
          case relX <= elemWidth * 0.10:
            this.set('scrolling', true);
            this.scrollLeft();
            break;
          case relX >= elemWidth * 0.90:
            this.set('scrolling', true);
            this.scrollright();
            break;
          default:
            this.stopScroll();
            break;
        }
      }
    },

    scrollLeft: function scrollLeft() {
      var _this3 = this;

      var $toScroll = this.$();
      if (this.get('scrolling')) {
        _ember['default'].run.debounce(this, function () {
          if (_this3.get('scrolling')) {
            $toScroll.scrollLeft($toScroll.scrollLeft() - 2);
            _this3.scrollLeft();
          }
        }, 50);
      }
    },

    scrollright: function scrollright() {
      var _this4 = this;

      var $toScroll = this.$();
      if (this.get('scrolling')) {
        _ember['default'].run.debounce(this, function () {
          if (_this4.get('scrolling')) {
            $toScroll.scrollLeft($toScroll.scrollLeft() + 2);
            _this4.scrollright();
          }
        }, 50);
      }
    },

    stopScroll: function stopScroll() {
      if (this.get('scrolling')) {
        this.set('scrolling', false);
      }
    },

    actions: {

      chooseAll: function chooseAll(elems) {
        this.set('selected_elements.all', true);
        if (elems === 'all') {
          this.get('findAllIds')();
        } else if (elems === 'visible') {
          this.set('selected_elements.ids', this.get('selected_elements.ids').concat(this.get('elements').mapBy('id')).uniq());
        }
        this.send('closeModalTrueAll');
      },

      closeModalTrueAll: function closeModalTrueAll() {
        this.set('showTrueAll', false);
      },

      toggleSelectAllElements: function toggleSelectAllElements() {
        if (!this.get('allIsSelected')) {
          this.set('showTrueAll', true);
        } else {
          this.set('selected_elements.all', false);
          this.set('selected_elements.ids', []);
        }
      },

      dragStart: function dragStart() {
        this.set('isDragging', true);
      },

      dragStopped: function dragStopped() {
        this.set('isDragging', false);
        this.stopScroll();
      },

      reorderColumns: function reorderColumns(newOrder) {
        var position = this.get('columnsSorting')[0];
        var oldOrder = this.get('columns').mapBy(position);
        var columns = this.set('columns', newOrder);
        var _newOrder = newOrder.mapBy(position);
        columns.forEach(function (a, i) {
          set(a, position, i);
        });
        if (oldOrder !== _newOrder) {
          this.get('orderChanged')();
        }
        return true;
      },

      resetPosition: function resetPosition(columns) {
        var position = this.get('columnsSorting')[0];
        columns.sort(function (col1, col2) {
          return col1.list_position - col2.list_position;
        }).forEach(function (a, i) {
          set(a, position, i);
        });
        this.set('columns', columns);
        this.get('orderChanged')();
      },

      deleteColumn: function deleteColumn(column_name) {
        var deleteAction = this.get('delete_column_action');
        var columns = this.get('columns');
        if (deleteAction) {
          deleteAction(columns.findBy('name', column_name));
        } else {
          columns.removeObject(columns.findBy('name', column_name));
        }
        this.send('resetPosition', columns);
      },

      checkboxClickAction: function checkboxClickAction(element) {
        var ids = this.get('selected_elements.ids');
        var selected = ids.indexOf(element.get('id'));
        if (selected !== -1) {
          ids.splice(selected, 1);
        } else {
          ids.push(element.get('id'));
        }
        this.set('selected_elements', { 'ids': ids, all: false });
      }

    }
  });
});