define('due-dashboard/routes/settings/web-snippets/edit', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({

    beforeModel: function beforeModel(transition) {
      var model_id = transition.params['settings.web-snippets.edit'].web_snippet_id;
      if (model_id && model_id !== 'null' || (transition.intent.contexts || [])[0]) {
        return this._super.apply(this, arguments);
      } else {
        this.transitionTo('settings.web-snippets.index');
      }
    },

    deactivate: function deactivate() {
      if (!this.get('controller.model.id')) {
        this.get('controller.model').deleteRecord();
      }
      return this._super.apply(this, arguments);
    }

  });
});