define('due-dashboard/controllers/settings/integrations/salesforce', ['exports', 'ember', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({

    accountObject: 'Contact',
    salesforce_it: _ember['default'].computed.alias('model.company_integration'),
    hideSave: true,

    savable: _ember['default'].computed('currentAccount.content.company.subscription_status', 'salesforce_it.sandbox_mode', function () {
      if (this.get('currentAccount.content.company.subscription_status') === 'subscriber' || this.get('salesforce_it.sandbox_mode')) {
        return true;
      } else {
        return false;
      }
    }),

    validAccount: _ember['default'].computed('salesforce_it.custom_account_email', 'accountObject', function () {
      if (this.get('accountObject') === 'Account' && (this.get('salesforce_it.custom_account_email') === undefined || this.get('salesforce_it.custom_account_email') === '')) {
        return false;
      } else {
        return true;
      }
    }),

    actions: {}
  });
});