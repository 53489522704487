define('due-dashboard/models/check-feedback-delayed-scenario-condition', ['exports', 'ember', 'due-dashboard/models/scenario-condition', 'ember-data', 'ember-cp-validations'], function (exports, _ember, _dueDashboardModelsScenarioCondition, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;
  var computed = _ember['default'].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    validDelay: (0, _emberCpValidations.validator)('validate-hours')
  });

  exports['default'] = _dueDashboardModelsScenarioCondition['default'].extend(Validations, {
    days: attr('number'),
    hours: attr('number'),

    validDelay: computed('days', 'hours', function () {
      return this.get('days') > 0 || this.get('hours') > 0;
    })
  });
});