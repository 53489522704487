define('due-dashboard/routes/companies/surveys/edit/layout', ['exports', 'ember', 'due-dashboard/mixins/survey-step'], function (exports, _ember, _dueDashboardMixinsSurveyStep) {
  exports['default'] = _ember['default'].Route.extend(_dueDashboardMixinsSurveyStep['default'], {

    isSavingLayout: true,

    model: function model() {
      var survey = this.modelFor('companies.surveys.edit').survey;
      var survey_draft = this.modelFor('companies.surveys.edit').surveyDraft;
      survey_draft.set('custom_text_color', survey_draft.get('custom_text_color') || "#ffffff");
      return _ember['default'].RSVP.hash({
        survey: survey,
        survey_draft: survey_draft,
        closed_questions_rating_scale: survey_draft.get('closed_questions_rating_scale'),
        rating_scale: survey_draft.get('rating_scale'),
        builtin_scales: this.store.query('rating_scale', { filter: { builtin: 'true' } })
      });
    },

    stepConfig: {
      layout: 'preview'
    },

    actions: {
      saveLayout: function saveLayout() {
        var _this = this;
        var ctrl = this.controller;
        ctrl.set('isSavingLayout', false);
        _this.send('nextStep');
      }
    }

  });
});