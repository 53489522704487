define('due-dashboard/components/promise-loader', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    promise: null,

    promiseSettled: observer('promise.isSettled', function () {
      if (this.get('promise.isSettled') === true) {
        _ember['default'].run.later(this, function () {
          this.set('promise', null);
        }, 1000);
      }
    })

  });
});