define('due-dashboard/models/feedback', ['exports', 'ember-data', 'ember', 'moment'], function (exports, _emberData, _ember, _moment) {
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var filterBy = _Ember$computed.filterBy;
  var sort = _Ember$computed.sort;
  var notEmpty = _Ember$computed.notEmpty;
  var service = _ember['default'].inject.service;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    current_account: service(),

    dispatch_cancel_reason: attr('string'),
    dispatch_from_email: attr('string'),
    dispatch_from_name: attr('string'),
    chat_url: attr('string', { readOnly: true }),
    dispatch_scheduled_at: attr('date'),
    dispatch_sent_at: attr('date'),
    sparkpost_status: attr('string'),
    dispatch_subject: attr('string'),
    dispatch_text: attr('string'),
    dispatch_type: attr('string'),
    dispatch_opened: attr('boolean'),

    dispatch_clicks: attr('number'),
    dispatch_openings: attr('number'),
    dispatch_first_click: attr('date'),
    dispatch_first_open: attr('date'),

    scenario_first_click: attr('date'),
    scenario_last_click: attr('date'),
    scenario_clicks: attr('number'),

    comment: attr('string'),
    answer_completed_at: attr('date'),
    status: attr('string'),
    cache_survey_status: attr('string', { readOnly: true }),
    first_client_message: attr('string'),
    notes: attr('string'),
    source: attr('string'),
    is_answerable: attr('boolean'),
    is_from_new_survey: attr('boolean'),
    public_acknowledged: attr('boolean'),
    is_public: attr('boolean', { allowNull: true }),

    device_type: attr('string'),
    resolved: attr('boolean'),

    chat_name: computed('client.email', 'client.full_name', function () {
      return this.get('client.email') || this.get('client.full_name');
    }),
    chat_title: computed.alias('survey.name'),
    last_message: attr('string'),
    preset_message: attr('string'),
    source_zone: attr('string'),
    last_message_date: attr('date'),
    chat_updated_at: attr('date'),
    last_opening_date: attr('date'),
    first_resolution_date: attr('date'),
    last_resolution_date: attr('date'),
    resolution_time_cache: attr('number'),
    new_resolution_time_cache: attr('number'),
    resolution_time: attr('number'),
    full_resolution_time: attr('number'),
    auto_resolved: attr('boolean'),
    draft_answers: attr(''),
    sending_status_code: attr('number'),

    rating: belongsTo('Rating'),
    company: belongsTo('Company'),
    survey_language: belongsTo('SurveyLanguage'),
    survey: belongsTo('Survey'),
    client: belongsTo('Client'),
    chosen_language: belongsTo('Language'),
    bulk_dispatch: belongsTo('BulkDispatch'),
    template: belongsTo('template'),
    nlp_language: belongsTo('Language'),
    dispatch_language: belongsTo('Language'),
    resolver_account: belongsTo('Account'),

    company_segment_values: hasMany('CompanySegmentValues'),
    choices: hasMany('Choices'),
    answers: hasMany('Answers'),
    messages: hasMany('Messages'),
    tags: hasMany('Tags'),
    feedback_tags: hasMany('FeedbackTags'),
    translations: attr(),

    positionSorting: ['question.position'],
    sortedChoices: _ember['default'].computed.sort('choices', 'positionSorting'),

    sDesc: ['updated_at:asc'],
    messageToDisplay: filterBy('messages', 'isNew', false),
    messagesOrderedByDate: sort('messageToDisplay', 'sDesc'),
    completed: notEmpty('answer_completed_at'),

    from_diduenjoy: computed.equal('source', 'diduenjoy'),
    from_external: computed.not('from_diduenjoy'),
    auto_tagged: attr('boolean'),

    title: computed('client.email', 'client.full_name', 'dispatch_type', 'client.phone_number', 'i18n.locale', function () {
      var name = this.get('client.email') || this.get('client.full_name') || this.get('client.phone_number');
      if (name) {
        return name;
      } else {
        return this.get('dispatch_type') === "dashboard" ? this.get('i18n').t('chats.titles.from_dashboard') : this.get('i18n').t('chats.titles.anonyme');
      }
    }),

    optimal_language: computed('nlp_language', 'chosen_language', function () {
      if (this.get('nlp_language.id')) {
        return this.get('nlp_language');
      }
      return this.get('chosen_language');
    }),

    isPublic: computed('is_public', 'current_account.content.company.public_feedback_default', function () {
      var is_public = this.get('is_public');
      if (is_public == null) {
        is_public = this.get('current_account.content.company.public_feedback_default');
      }
      return is_public;
    }),

    is_deleted: computed.equal('status', 'deleted'),

    chat_status: computed('status', 'messages.length', 'resolved', function () {
      if (this.get('status') === 'deleted') {
        return 'deleted';
      } else if (this.get('resolved')) {
        return 'resolved';
      } else if (this.get('.messages').filterBy('from_client', false).length === 0) {
        return 'new';
      } else {
        return 'ongoing';
      }
    }),

    survey_revision: computed.alias('survey_language.survey_revision'),

    prettyCompletedAt: computed('answer_completed_at', function () {
      // return 'Le ' + moment(this.get('answer_completed_at')).format('LL') + ' à ' + moment(this.get('answer_completed_at')).format('LT');
      return (0, _moment['default'])(this.get('answer_completed_at')).format('LL');
    }).readOnly(),

    completedAtDate: computed('answer_completed_at', function () {
      return (0, _moment['default'])(this.get('answer_completed_at')).format('L');
    }).readOnly(),

    completedAtTime: computed('answer_completed_at', function () {
      return (0, _moment['default'])(this.get('answer_completed_at')).format('LT');
    }).readOnly(),

    chatUpdatedDateTime: computed('chat_updated_at', function () {
      var now = new Date(),
          lastUpdate = this.get('chat_updated_at');
      if (lastUpdate) {
        if (lastUpdate.toDateString() === now.toDateString()) {
          var msDiff = Math.abs(now - lastUpdate);
          var hours = Math.floor(msDiff / (1000 * 60 * 60));
          var minutes = Math.floor(msDiff / (1000 * 60));
          if (hours > 0) {
            return this.get('i18n').t('words.hours_ago', { count: hours });
          } else {
            return this.get('i18n').t('words.minutes_ago', { count: minutes });
          }
        }
        return (0, _moment['default'])(lastUpdate).format('lll');
      }
    }),

    chatCompletedAtTime: computed('answer_completed_at', function () {
      var now = new Date(),
          completedAt = this.get('answer_completed_at');
      if (completedAt) {
        if (completedAt.toDateString() === now.toDateString()) {
          var msDiff = Math.abs(now - completedAt);
          var hours = Math.floor(msDiff / (1000 * 60 * 60));
          var minutes = Math.floor(msDiff / (1000 * 60));
          if (hours > 0) {
            return this.get('i18n').t('words.hours_ago', { count: hours });
          } else {
            return this.get('i18n').t('words.minutes_ago', { count: minutes });
          }
        }
        return (0, _moment['default'])(completedAt).format('lll');
      }
    }),

    avatar: computed('chat_name', function () {
      return this.get('chat_name') ? this.get('chat_name').charAt(0) : '?';
    }),

    avatarClass: computed('avatar', function () {
      var avatar = this.get('avatar').toLowerCase();
      var knownAvatars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      if (knownAvatars.indexOf(avatar) !== -1) {
        return 'color-' + avatar;
      } else {
        return 'color-else';
      }
    }),

    profile: computed('rating.score', function () {
      switch (this.get('rating.score')) {
        case 10:
        case 9:
          return 'promoter';
        case 8:
        case 7:
          return 'passive';
        default:
          return 'detractor';
      }
    }),

    // token:                    attr('string'),
    // sparkpost_status_reason:  attr('string'),
    // bulk_dispatch_id:         attr('string'),
    // dispatch_phone_number:    attr('string'),
    // dispatch_to_email:        attr('string'),
    // transmission_id:          attr('string'),
    // dispatch_last_click:      attr('date'),
    // dispatch_last_open:       attr('date'),
    // ip:                       attr('string'),
    // browser_info:             attr('string'),
    // raw_browser_info:         attr('string'),
    // pseudo_status:            attr('string'),
    // updated_at:               attr('date'),
    // unread:                   attr('boolean'),
    // dispatch_survey_language: belongsTo('SurveyLanguage'),
    created_at: attr('date')

  });
});