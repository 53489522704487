define("due-dashboard/templates/companies", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/companies.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "blocked-access", ["loc", [null, [5, 6], [5, 24]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 9,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/companies.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "notifications-list", [], ["currentAccount", ["subexpr", "@mut", [["get", "currentAccount", ["loc", [null, [7, 42], [7, 56]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 6], [7, 58]]], 0, 0], ["content", "outlet", ["loc", [null, [8, 6], [8, 16]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/companies.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "right-frame");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["page-with-navbar ", ["subexpr", "if", [["get", "sidebar.opened", ["loc", [null, [1, 34], [1, 48]]], 0, 0, 0, 0], "sidebar-opened", "sidebar-minify"], [], ["loc", [null, [1, 29], [1, 84]]], 0, 0], " ", ["subexpr", "if", [["subexpr", "and", [["subexpr", "eq", [["get", "currentAccount.content.profile", ["loc", [null, [1, 99], [1, 129]]], 0, 0, 0, 0], "custom", ["subexpr", "eq", [["get", "version", ["loc", [null, [1, 143], [1, 150]]], 0, 0, 0, 0], "v2"], [], ["loc", [null, [1, 139], [1, 156]]], 0, 0]], [], ["loc", [null, [1, 95], [1, 157]]], 0, 0]], [], ["loc", [null, [1, 90], [1, 158]]], 0, 0], "mini-v2"], [], ["loc", [null, [1, 85], [1, 170]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "dashboard-navbar", [], ["dashboardSection", ["subexpr", "@mut", [["get", "sidebar.dashboardSection", ["loc", [null, [2, 38], [2, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "settingsSection", ["subexpr", "@mut", [["get", "sidebar.settingsSection", ["loc", [null, [2, 79], [2, 102]]], 0, 0, 0, 0]], [], [], 0, 0], "version", ["subexpr", "@mut", [["get", "version", ["loc", [null, [2, 111], [2, 118]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 121]]], 0, 0], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "sidebar.opened", ["loc", [null, [3, 36], [3, 50]]], 0, 0, 0, 0], "sidebar-opened", "sidebar-minify"], [], ["loc", [null, [3, 31], [3, 86]]], 0, 0], " ", ["get", "version", ["loc", [null, [3, 89], [3, 96]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "company.is_blocked", ["loc", [null, [4, 10], [4, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 4], [9, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});