define('due-dashboard/models/change-end-link-scenario-action', ['exports', 'due-dashboard/models/scenario-action', 'ember-data', 'ember-cp-validations', 'ember'], function (exports, _dueDashboardModelsScenarioAction, _emberData, _emberCpValidations, _ember) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;

  var url_regexp = /^((?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?|mailto\:.+|tel\:\+?[\d,\-]+|.*\{segment\:.+\}.*)$/i;
  var Validations = (0, _emberCpValidations.buildValidations)({
    change_end_link_scenario_actions_languages: (0, _emberCpValidations.validator)('presence', true),
    validLinkText: (0, _emberCpValidations.validator)('number', {
      integer: true,
      is: 0,
      message: 'Empty messages are invalid'
    })
  });

  exports['default'] = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    change_end_link_scenario_actions_languages: hasMany('change-end-link-scenario-actions-language', { async: true }),
    actions_languages: computed.alias('change_end_link_scenario_actions_languages'),
    button_color: attr('string'),

    validLinkText: computed('change_end_link_scenario_actions_languages.[]', function () {
      var _this = this;

      var actions = this.get('change_end_link_scenario_actions_languages');
      actions.map(function (a) {
        if (a.observersForKey('link_text').length === 0) {
          a.addObserver('link_text', function () {
            return _this.notifyPropertyChange('validLinkText');
          });
        }
        if (a.observersForKey('link').length === 0) {
          a.addObserver('link', function () {
            return _this.notifyPropertyChange('validLinkText');
          });
        }
      });
      return actions.map(function (a) {
        return a.get('link_text') === undefined || a.get('link_text') === '' || a.get('link').match(url_regexp) === null;
      }).filter(function (a) {
        return a === true;
      }).length;
    }),

    defineSavepoint: function defineSavepoint() {
      var _this2 = this;

      this.get('actions_languages').then(function (al) {
        _this2.set('_savepoint_actions_languages', al.toArray());
      });
      return this;
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_actions_languages');
      if (savepoint) {
        this.set('change_end_link_scenario_actions_languages', savepoint);
        savepoint.map(function (a) {
          return a.rollbackAttributes();
        });
      }
    }
  });
});