define('due-dashboard/models/report', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    permalink: attr(),
    filters: attr(),
    title: attr(),
    elements: attr(),
    company: belongsTo('company')
  });
});