define('due-dashboard/controllers/settings/integrations/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    rawIntegrations: _ember['default'].computed('model.integrations.[]', 'model.company_integrations.@each.is_active', 'model.company_integrations.@each.is_active', 'model.company_integrations.[]', function () {
      var company_its = this.get('model.company_integrations');
      var ex_it = this.get('model.integrations');
      var ret = ex_it.map(function (it) {
        var cp_it = company_its.findBy('company_integration_type', it.get('name').underscore() + '_company_integration');
        return {
          it: it,
          isConnected: !!cp_it,
          isOn: cp_it && cp_it.get('is_active') && cp_it.get('completed')
        };
      });
      return ret;
    }),

    integrations: _ember['default'].computed.filterBy('rawIntegrations', 'it.is_public', true)
  });
});