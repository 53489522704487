define('due-dashboard/components/surveys/single-dropdown', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tag: '',

    allSurveys: [],
    allowClear: false,
    remainingSurveys: computed.setDiff('allSurveys', 'remainingSurveyArray'),
    remainingSurveyArray: computed('remainingSurvey', function () {
      return [this.get('remainingSurvey')];
    }),

    init: function init() {
      var _this = this;

      this.store.query('survey', { filter: { status: 'live,archived' }, page: { number: 1, size: 20 } }).then(function (surveys) {
        return _this.set('allSurveys', surveys);
      });
      this._super.apply(this, arguments);
    },

    _searchSurvey: function _searchSurvey(term, resolve, reject) {
      this.store.query('survey', {
        filter: {
          name: term,
          status: 'live,archived'
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (surveys) {
        return resolve(surveys);
      }, reject);
    },

    actions: {

      searchSurveys: function searchSurveys(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this2, _this2._searchSurvey, term, resolve, reject, 600);
        });
      }

    }

  });
});