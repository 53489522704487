define("due-dashboard/templates/components/breakdowns/due-distribution-breakdown", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 5,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/breakdowns/due-distribution-breakdown.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "breakdown-section");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'style');
            return morphs;
          },
          statements: [["attribute", "style", ["concat", [["get", "breakdownSection.style", ["loc", [null, [4, 46], [4, 68]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/breakdowns/due-distribution-breakdown.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "not", [["subexpr", "eq", [["get", "breakdownSection.percentage", ["loc", [null, [3, 19], [3, 46]]], 0, 0, 0, 0], 0], [], ["loc", [null, [3, 15], [3, 49]]], 0, 0]], [], ["loc", [null, [3, 10], [3, 50]]], 0, 0]], [], 0, null, ["loc", [null, [3, 4], [5, 11]]]]],
        locals: ["breakdownSection"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/breakdowns/due-distribution-breakdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "due-distribution-breakdown-tooltip", [], ["sections", ["subexpr", "@mut", [["get", "computedBreakdown", ["loc", [null, [10, 13], [10, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "total", ["subexpr", "@mut", [["get", "total", ["loc", [null, [11, 10], [11, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "title", ["loc", [null, [12, 10], [12, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "width", ["subexpr", "@mut", [["get", "width", ["loc", [null, [13, 10], [13, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "container", ["subexpr", "@mut", [["get", "tooltipContainer", ["loc", [null, [14, 14], [14, 30]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [9, 2], [15, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/breakdowns/due-distribution-breakdown.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "breakdown-container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "computedBreakdown", ["loc", [null, [2, 10], [2, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [6, 11]]]], ["block", "if", [["subexpr", "and", [["get", "computedBreakdown", ["loc", [null, [8, 11], [8, 28]]], 0, 0, 0, 0], ["subexpr", "not", [["subexpr", "eq", [["get", "total", ["loc", [null, [8, 38], [8, 43]]], 0, 0, 0, 0], 0], [], ["loc", [null, [8, 34], [8, 46]]], 0, 0]], [], ["loc", [null, [8, 29], [8, 47]]], 0, 0]], [], ["loc", [null, [8, 6], [8, 48]]], 0, 0]], [], 1, null, ["loc", [null, [8, 0], [16, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});