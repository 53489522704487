define('due-dashboard/components/due-autocomplete', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    value: computed('option', function () {
      return this.get('option.text');
    }),

    highlightedValue: computed('highlightedOption', function () {
      return this.get('highlightedOption') ? this.get('highlightedOption.text') : '';
    }),

    isHighlighted: computed('value', 'highlightedValue', function () {
      return this.get('value') === this.get('highlightedValue');
    }),

    actions: {
      selectOption: function selectOption(option) {
        this.get('selectOption')(option);
      }
    }

  });
});