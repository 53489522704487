define('due-dashboard/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize the attribute!
      if (attribute.options && attribute.options.readOnly) {
        return;
      }
      this._super.apply(this, arguments);
    },

    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      // do not serialize the attribute!
      if (relationship.options && relationship.options.readOnly) {
        return;
      }
      this._super.apply(this, arguments);
    },

    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      // do not serialize the attribute!
      if (relationship.options && relationship.options.readOnly) {
        return;
      }
      this._super.apply(this, arguments);
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      if (json.data.attributes !== undefined) {
        delete json.data.attributes['created-at'];
        delete json.data.attributes['updated-at'];
      }
      return json;
    }

  });
});