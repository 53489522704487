define('due-dashboard/models/unconditional-scenario-condition', ['exports', 'due-dashboard/models/scenario-condition', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioCondition, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    dummy: (0, _emberCpValidations.validator)('always-valid')
  });

  exports['default'] = _dueDashboardModelsScenarioCondition['default'].extend(Validations, {
    dummy: 42,
    isDefaultCondition: true
  });
});