define('due-dashboard/routes/file-export', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    redirect: function redirect(params) {
      // Get file_export_request and redirect to file_url attribute
      this.store.findRecord('unioned-export-request', params.exportId).then(function (exportRequest) {
        var newWindow = window.open(exportRequest.get('url'));
        window.onblur = function () {
          window.close();
        };
        newWindow.focus();
        newWindow.onblur = function () {
          newWindow.close();
        };
      })['catch'](function () {
        window.close();
      });
    }
  });
});