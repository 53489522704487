define('due-dashboard/routes/companies/surveys/edit/bulk-dispatch', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    stepConfig: {
      layout: 'preview'
    },

    model: function model() {
      return {
        survey: this.modelFor('companies.surveys.edit').survey,
        csvError: null
      };
    },

    actions: {
      prevStep: function prevStep() {
        this.transitionTo('companies.surveys.edit.publishing');
      }
    }

  });
});