define("due-dashboard/components/boolean-radio", ["exports", "ember"], function (exports, _ember) {
  var computed = _ember["default"].computed;
  var Component = _ember["default"].Component;
  exports["default"] = Component.extend({
    classNames: ["boolean-radio"],

    availableValues: computed(function () {
      return [null, true, false];
    })
  });
});