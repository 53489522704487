define('due-dashboard/controllers/settings/integrations/intercom', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var and = _Ember$computed.and;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({
    intercom_it: alias('model.company_integration'),
    submitEnabled: and('intercom_it.survey.content', '_defined_create_intercom_user_if_not_exists'),
    _defined_create_intercom_user_if_not_exists: computed('intercom_it.create_intercom_user_if_not_exists', function () {
      return this.get('intercom_it.create_intercom_user_if_not_exists') !== undefined;
    }),

    login_url: computed('intercom_it.survey', 'intercom_it.create_intercom_user_if_not_exists', function () {
      if (this.get('intercom_it.survey.content')) {
        return _dueDashboardConfigEnvironment['default'].EmberENV.INTERCOM_CONNECT_URL + '/' + this.get('model.company.id') + '?' + ('survey=' + this.get('intercom_it.survey.id')) + ('&create_it_us=' + this.get('intercom_it.create_intercom_user_if_not_exists'));
      } else {
        return "";
      }
    })

  });
});