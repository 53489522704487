define('due-dashboard/models/survey-draft', ['exports', 'ember', 'ember-data', 'due-dashboard/mixins/filthy-model', 'ember-cp-validations'], function (exports, _ember, _emberData, _dueDashboardMixinsFilthyModel, _emberCpValidations) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    activity_sector: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = Model.extend(_dueDashboardMixinsFilthyModel['default'], Validations, {
    name: attr('string'),
    background_image: attr('string'),
    logo_image: attr('string'),
    survey_color: attr('string'),
    custom_box_bg_color: attr(),
    custom_text_color: attr(),
    consent_texts: attr(),
    activity_sector_id: attr(),
    rating_scale_id: attr('string'),
    closed_questions_rating_scale_id: attr('string'),
    active: attr('boolean', { defaultValue: false }),
    questions: attr(),
    questions_basic: attr(),
    survey_content: attr(),
    design_options: attr(),
    available_languages: attr(),
    translations: attr(),
    confirm_main_language: attr('boolean'),
    main_language: belongsTo('Language'),
    html_meta: attr(),

    finalize: function finalize() {
      this.set('active', true);
      return this.save();
    },

    languages: computed.mapBy('survey_languages', 'language'),

    currentLanguage: computed('tags.[]', 'questionsFromJson', 'i18n.locale', function () {
      return this.get('questionsFromJson')[this.get('i18n.locale').toUpperCase()];
    }),

    currentBasic: computed('tags.[]', 'i18n.locale', function () {
      return this.get('basicFromJson')[this.get('i18n.locale').toUpperCase()];
    }),

    FirstLanguage: computed('tags.[]', 'questionsFromJson', function () {
      return this.get('questionsFromJson')[this.get('tags.firstObject')];
    }),

    FirstBasic: computed('tags.[]', function () {
      return this.get('basicFromJson')[this.get('tags.firstObject')];
    }),

    questionsFromJson: computed('questions', function () {
      return JSON.parse(this.get('questions'));
    }),

    basicFromJson: computed('questions_basic', function () {
      return JSON.parse(this.get('questions_basic'));
    }),

    tags: computed('questionsFromJson', function () {
      var questions = this.get('questionsFromJson');
      return Object.keys(questions);
    }),

    closed_questions_rating_scale: computed('closed_questions_rating_scale_id', function () {
      var questions = this.store.find('rating-scale', this.get('closed_questions_rating_scale_id'));
      return questions;
    }),

    rating_scale: computed('rating_scale_id', function () {
      var questions = this.store.find('rating-scale', this.get('rating_scale_id'));
      return questions;
    }),

    currentLanguageOrFirst: computed.or('currentLanguage', 'FirstLanguage'),

    currentBasicOrFirst: computed.or('currentBasic', 'FirstBasic')

  });
});