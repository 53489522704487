define("due-dashboard/templates/settings/tags/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 8
              },
              "end": {
                "line": 10,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createElementMorph(element6);
            morphs[2] = dom.createMorphAt(element6, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["get", "button.class", ["loc", [null, [9, 63], [9, 75]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["redirectToTranslations"], [], ["loc", [null, [9, 18], [9, 53]]], 0, 0], ["content", "button.text", ["loc", [null, [9, 79], [9, 94]]], 0, 0, 0, 0]],
          locals: ["button"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 12,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "grey-button");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "due-button", [], ["type", "action", "icon", "globe", "text", ["subexpr", "t", ["settings.tags.translations_button"], [], ["loc", [null, [8, 54], [8, 93]]], 0, 0]], 0, null, ["loc", [null, [8, 8], [10, 23]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 6
            },
            "end": {
              "line": 34,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "searchInput", ["loc", [null, [33, 34], [33, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "currentTab.searchPlaceholder", ["loc", [null, [33, 58], [33, 86]]], 0, 0, 0, 0]], [], [], 0, 0], "key-up", ["subexpr", "action", ["search"], [], ["loc", [null, [33, 94], [33, 111]]], 0, 0]], ["loc", [null, [33, 8], [33, 113]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 12
              },
              "end": {
                "line": 45,
                "column": 12
              }
            },
            "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'colspan');
            morphs[1] = dom.createMorphAt(element4, 0, 0);
            return morphs;
          },
          statements: [["attribute", "colspan", ["get", "column.colspan", ["loc", [null, [44, 28], [44, 42]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "column.label", ["loc", [null, [44, 45], [44, 61]]], 0, 0, 0, 0]],
          locals: ["column"],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 16
                  },
                  "end": {
                    "line": 55,
                    "column": 16
                  }
                },
                "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element3, 'colspan');
                morphs[1] = dom.createMorphAt(element3, 0, 0);
                return morphs;
              },
              statements: [["attribute", "colspan", ["get", "column.colspan", ["loc", [null, [54, 32], [54, 46]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "loading-skeleton/due-text-skeleton", [], ["size", ["subexpr", "@mut", [["get", "column.size", ["loc", [null, [54, 91], [54, 102]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [54, 49], [54, 104]]], 0, 0]],
              locals: ["column"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 12
                },
                "end": {
                  "line": 57,
                  "column": 12
                }
              },
              "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "tablesColumns", ["loc", [null, [53, 24], [53, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [53, 16], [55, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 10
              },
              "end": {
                "line": 58,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment(" Loading skeleton ");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "increment-for", [], ["times", ["subexpr", "@mut", [["get", "SKELETON_REPETITION", ["loc", [null, [51, 35], [51, 54]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [51, 12], [57, 30]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 14
                  },
                  "end": {
                    "line": 63,
                    "column": 14
                  }
                },
                "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "tags/show-item", [], ["deleteAction", ["subexpr", "action", ["deleteTag"], [], ["loc", [null, [62, 46], [62, 66]]], 0, 0], "editAction", ["subexpr", "action", ["editTag"], [], ["loc", [null, [62, 78], [62, 96]]], 0, 0], "notifyTagCreation", ["subexpr", "action", ["notifyTagCreation"], [], ["loc", [null, [62, 115], [62, 143]]], 0, 0], "tag", ["subexpr", "@mut", [["get", "tag", ["loc", [null, [62, 148], [62, 151]]], 0, 0, 0, 0]], [], [], 0, 0], "languages", ["subexpr", "@mut", [["get", "model.languages", ["loc", [null, [62, 162], [62, 177]]], 0, 0, 0, 0]], [], [], 0, 0], "hasSeveralLanguages", ["subexpr", "@mut", [["get", "displayTraductionRedirection", ["loc", [null, [62, 198], [62, 226]]], 0, 0, 0, 0]], [], [], 0, 0], "dueExclusiveEdit", ["subexpr", "@mut", [["get", "due_exclusive_edit", ["loc", [null, [62, 244], [62, 262]]], 0, 0, 0, 0]], [], [], 0, 0], "kind", ["subexpr", "@mut", [["get", "currentTab.kind", ["loc", [null, [62, 268], [62, 283]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [62, 16], [62, 285]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 60,
                  "column": 12
                },
                "end": {
                  "line": 64,
                  "column": 12
                }
              },
              "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "tag.depth", ["loc", [null, [61, 24], [61, 33]]], 0, 0, 0, 0], 0], [], ["loc", [null, [61, 20], [61, 36]]], 0, 0]], [], 0, null, ["loc", [null, [61, 14], [63, 21]]]]],
            locals: ["tag"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 10
              },
              "end": {
                "line": 65,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment(" Tag/Root cause items ");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "sortedTags", ["loc", [null, [60, 20], [60, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [60, 12], [64, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 2
            },
            "end": {
              "line": 69,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" Table ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tags-tab");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("table");
          dom.setAttribute(el2, "class", "tags-tab-table");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("thead");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tr");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tbody");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "tablesColumns", ["loc", [null, [43, 20], [43, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [43, 12], [45, 21]]]], ["block", "if", [["get", "loading", ["loc", [null, [49, 16], [49, 23]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [49, 10], [65, 17]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 2
            },
            "end": {
              "line": 78,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" Display empty table ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tags-empty");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h1");
          dom.setAttribute(el2, "class", "tags-empty-title");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2, "class", "tags-empty-subtitle");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [5, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[2] = dom.createElementMorph(element2);
          morphs[3] = dom.createMorphAt(element2, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["tio-", ["get", "currentTab.emptyTableIcon", ["loc", [null, [72, 25], [72, 50]]], 0, 0, 0, 0], " tags-empty-icon"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "currentTab.emptyTableTitle", ["loc", [null, [73, 35], [73, 65]]], 0, 0, 0, 0], ["element", "action", ["openAddTagModal"], [], ["loc", [null, [75, 11], [75, 39]]], 0, 0], ["content", "currentTab.emptyTableSubTitle", ["loc", [null, [75, 40], [75, 73]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/settings/tags/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "tags-container");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tags-trad-form");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" Traduction Redirection ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" Form Tag/Root Causes ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "blue-button");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" Tab navigation ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" Search ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tags-search");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [2]);
        var element8 = dom.childAt(element7, [1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element8, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element8, [7]), 1, 1);
        morphs[3] = dom.createMorphAt(element7, 5, 5);
        morphs[4] = dom.createMorphAt(dom.childAt(element7, [9]), 1, 1);
        morphs[5] = dom.createMorphAt(element7, 11, 11);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "dashboard-title", ["loc", [null, [1, 0], [1, 19]]], 0, 0, 0, 0], ["block", "if", [["get", "displayTraductionRedirection", ["loc", [null, [6, 10], [6, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 4], [12, 11]]]], ["inline", "tags/add-tag", [], ["modalIsOpened", ["subexpr", "@mut", [["get", "addTagModalIsOpened", ["loc", [null, [17, 22], [17, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "notifyTagCreation", ["subexpr", "action", ["notifyTagCreation"], [], ["loc", [null, [18, 26], [18, 54]]], 0, 0], "languages", ["subexpr", "@mut", [["get", "model.languages", ["loc", [null, [19, 18], [19, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "hasSeveralLanguages", ["subexpr", "@mut", [["get", "displayTraductionRedirection", ["loc", [null, [20, 28], [20, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "dueExclusiveEdit", ["subexpr", "@mut", [["get", "due_exclusive_edit", ["loc", [null, [21, 25], [21, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "kind", ["subexpr", "@mut", [["get", "currentTab.kind", ["loc", [null, [22, 13], [22, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 6], [23, 8]]], 0, 0], ["inline", "due-tab-bar", [], ["tabs", ["subexpr", "@mut", [["get", "tabs", ["loc", [null, [28, 21], [28, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "onChangeTab", ["subexpr", "action", ["onChangeTab"], [], ["loc", [null, [28, 38], [28, 60]]], 0, 0], "selected", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [28, 70], [28, 81]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [28, 2], [28, 83]]], 0, 0], ["block", "due-search-input", [], [], 1, null, ["loc", [null, [32, 6], [34, 27]]]], ["block", "if", [["get", "displayTab", ["loc", [null, [37, 8], [37, 18]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [37, 2], [78, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});