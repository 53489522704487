define('due-dashboard/components/filter/saved-search-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    tagName: '',

    searches: [],
    'on-new-search': null,
    'on-search-selected': null,

    // private:

    _currentAccount: service('current-account').readOnly(),

    _savedSearches: computed.filterBy('searches', 'isNew', false),
    _accountSearches: computed.filter('_savedSearches', function (search) {
      return search.get('account_id') === this.get('currentAccount.content.id');
    }).readOnly(),
    _companySearches: computed.setDiff('_savedSearches', '_accountSearches').readOnly(),

    actions: {
      newSearch: function newSearch() {
        this.getWithDefault('on-new-search', _ember['default'].K)();
      },

      setSearch: function setSearch(search) {
        this.getWithDefault('on-search-selected', _ember['default'].K)(search);
      }
    }
  });
});