define('due-dashboard/routes/callbacks/new-survey-with-type', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var id = transition.state.handlerInfos.findBy('name', 'callbacks.new-survey-with-type').params.activity_sector_id;
      if (id) {
        this.store.find('activity-sector', id).then(function (act) {
          if (act.get('id')) {
            transition.send('newSurvey', act.get('id'));
          } else {
            _this.transitionTo('companies.overview');
          }
        });
      } else {
        this.transitionTo('companies.overview');
      }
    }
  });
});