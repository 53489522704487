define("due-dashboard/templates/components/dynamic-templates-dropdown", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/dynamic-templates-dropdown.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "yield", [["get", "template", ["loc", [null, [14, 12], [14, 20]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 4], [14, 22]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 2
              },
              "end": {
                "line": 17,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/dynamic-templates-dropdown.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("strong");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "get", [["get", "template", ["loc", [null, [16, 18], [16, 26]]], 0, 0, 0, 0], ["get", "searchField", ["loc", [null, [16, 27], [16, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 12], [16, 40]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/dynamic-templates-dropdown.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [13, 8], [13, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [13, 2], [17, 9]]]]],
        locals: ["template"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 17
          }
        },
        "moduleName": "due-dashboard/templates/components/dynamic-templates-dropdown.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "power-select", [], ["options", ["subexpr", "@mut", [["get", "options", ["loc", [null, [2, 10], [2, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selectedTemplate", ["loc", [null, [3, 11], [3, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "triggerClass", ["subexpr", "@mut", [["get", "triggerClass", ["loc", [null, [4, 15], [4, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "@mut", [["get", "actionOnChange", ["loc", [null, [5, 11], [5, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "searchField", ["subexpr", "@mut", [["get", "searchField", ["loc", [null, [6, 14], [6, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "or", [["get", "placeholder", ["loc", [null, [7, 18], [7, 29]]], 0, 0, 0, 0], ["subexpr", "t", ["global.choose_template"], [], ["loc", [null, [7, 30], [7, 58]]], 0, 0]], [], ["loc", [null, [7, 14], [7, 59]]], 0, 0], "searchPlaceholder", ["subexpr", "or", [["get", "searchField", ["loc", [null, [8, 24], [8, 35]]], 0, 0, 0, 0], ["subexpr", "t", ["global.type_template_name"], [], ["loc", [null, [8, 36], [8, 67]]], 0, 0]], [], ["loc", [null, [8, 20], [8, 68]]], 0, 0], "loadingMessage", ["subexpr", "t", ["global.search_template"], [], ["loc", [null, [9, 17], [9, 45]]], 0, 0], "noMatchesMessage", ["subexpr", "t", ["global.no_templates_matching"], [], ["loc", [null, [10, 19], [10, 53]]], 0, 0], "search", ["subexpr", "action", ["searchTemplates"], [], ["loc", [null, [11, 9], [11, 35]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [18, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});