define('due-dashboard/models/message', ['exports', 'ember-data', 'ember', 'moment'], function (exports, _emberData, _ember, _moment) {
  var computed = _ember['default'].computed;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    feedback: belongsTo('Feedback', { async: true }),

    text: attr('string'),
    from_client: attr('boolean'),
    // TODO: clean/remove unused code unread
    unread: attr('boolean'),
    is_note: attr('boolean'),
    created_at: attr('date'),
    updated_at: attr('date'),
    author: attr('string'),
    translation: attr('string'),

    prettyDate: computed('date', function () {
      return 'Le ' + (0, _moment['default'])(this.get('date')).format('LL') + ' à ' + (0, _moment['default'])(this.get('date')).format('LT');
    }).readOnly(),

    from: computed('from_client', 'author', 'feedback', function () {
      if (this.get('from_client')) {
        if (!this.get('feedback.chat_name') || this.get('feedback.chat_name').length === 0) {
          return 'Anonymous';
        } else {
          return this.get('feedback.chat_name');
        }
      } else {
        var author = this.get('author');
        if (author) {
          var match = author.match(/^;scenario;(.*)/);
          var ret = !!match;
          return ret ? match[1] : author;
        }
        return this.get('feedback.company.name');
      }
    }),

    from_scenario: computed('author', function () {
      if (!this.get('from_client')) {
        var author = this.get('author');
        if (author) {
          var match = author.match(/^;scenario;(.*)/);
          return !!match;
        }
      }
      return false;
    }),

    avatar: computed('from_client', 'author', 'feedback', function () {
      if (this.get('from_client')) {
        return this.get('feedback.avatar');
      } else {
        var author = this.get('author');
        if (author) {
          return author.charAt(0);
        } else {
          return '?';
        }
      }
    }),

    avatarClass: computed('avatar', function () {
      var avatar = this.get('avatar').toLowerCase();
      var knownAvatars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      if (knownAvatars.indexOf(avatar) !== -1) {
        return 'color-' + avatar;
      } else {
        return 'color-else';
      }
    })
  });
});