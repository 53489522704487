define('due-dashboard/components/v2/-dropdown', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-dropdown-v2'],

    formattedOption: computed('options', 'displayKey', function () {
      var opts = this.get('options');
      var key = this.get('displayKey');

      if (!opts || !key) return [];
      return opts.map(function (opt) {
        return _extends({}, opt, { _label: opt[key] });
      });
    }),

    actions: {
      onSelectOption: function onSelectOption(option) {
        var callback = this.get('onSelect');
        if (callback) callback(option);
      }
    }
  });
});