define('due-dashboard/components/click-tooltip', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  exports['default'] = _ember['default'].Component.extend({
    classNames: 'due-click-tooltip',
    classNamesBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      var msg = this.get('msg');
      $(this.$().parent()).tooltipster({
        theme: ['tooltipster-noir', 'tooltipster-noir-customized', 'tooltipster-noir-customized-centered'],
        side: 'right',
        content: msg.toString(),
        contentAsHTML: true,
        animation: 'fade',
        trigger: 'click',
        timer: 1000
      });
    },
    willDestroyElement: function willDestroyElement() {
      $(this.$().parent()).tooltipster('destroy');
    }
  });
});