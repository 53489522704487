define('due-dashboard/components/digests/parts/kpi-list-item-translation-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    kpiColumnTitle: computed('tag', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.kpi_list.' + this.get('index') + '.title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.kpi_list.' + this.get('index') + '.title', value);
        return value;
      }
    })

  });
});