define('due-dashboard/components/decade-picker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['due-decade-picker'],

    now: (0, _moment['default'])().format('YYYY'),

    decades: computed('selectedDecade', 'date', function () {
      var decade_base = 2000;
      var decades = [];
      var now = this.get('now');

      while (decade_base < now) {
        decades.push({
          startYear: decade_base,
          endYear: decade_base + 10,
          selected: decade_base < Number(this.get('selectedYear')) && Number(this.get('selectedYear')) < decade_base + 10
        });
        decade_base += 10;
      }
      return decades;
    }),

    actions: {
      pickDecade: function pickDecade(startYear) {
        this.get('decadePicked')(startYear);
      }
    }
  });
});