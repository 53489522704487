define('due-dashboard/components/complex-daterange-picker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    classNames: ['due-complex-daterange-picker'],
    classNameBindings: ['isOpen'],

    isOpen: false,

    selectedStartDate: computed.oneWay('startDate'),
    startDateChanged: observer('startDate', function () {
      this.get('selectedStartDate', this.get('startDate'));
    }),

    selectedEndDate: computed.oneWay('endDate'),
    endDateChanged: observer('endDate', function () {
      this.get('selectedEndDate', this.get('endDate'));
    }),

    printedStartDate: computed('selectedStartDate', function () {
      return this.get('selectedStartDate') ? (0, _moment['default'])(this.get('selectedStartDate')).format('L') : '';
    }),

    printedEndDate: computed('selectedEndDate', function () {
      return this.get('selectedEndDate') ? (0, _moment['default'])(this.get('selectedEndDate')).format('L') : '';
    }),

    pickedRangeButton: computed.or('pickedButton', 'customRangeButton'),
    selectedRangeButton: computed.oneWay('pickedRangeButton'),

    pickedButtonChanged: observer('pickedRangeButton', function () {
      this.set('selectedRangeButton', this.get('pickedRangeButton'));
    }),

    isRangeType: function isRangeType(type_of_range) {
      var momentStartDate = (0, _moment['default'])(this.get('selectedStartDate'));
      return momentStartDate.startOf(type_of_range).isSame(this.get('selectedStartDate')) && momentStartDate.endOf(type_of_range).isSame(this.get('selectedEndDate'));
    },

    pickedMonth: computed('selectedStartDate', 'selectedEndDate', function () {
      return this.isRangeType('month') ? this.get('selectedStartDate') : null;
    }),

    pickedYear: computed('selectedStartDate', 'selectedEndDate', function () {
      return this.isRangeType('year') ? this.get('selectedStartDate') : null;
    }),

    defaultRanges: _ember['default'].A([{ start: (0, _moment['default'])().startOf('day').toDate(), end: (0, _moment['default'])().endOf('day').toDate(), name: 'today' }, { start: (0, _moment['default'])().subtract(1, 'days').startOf('day').toDate(), end: (0, _moment['default'])().subtract(1, 'days').endOf('day').toDate(), name: 'yesterday' }, { start: (0, _moment['default'])().subtract(7, 'days').startOf('day').toDate(), end: (0, _moment['default'])().endOf('day').toDate(), name: 'last_7_days' }]),

    overallRange: computed('minDate', 'maxDate', function () {
      return {
        start: (0, _moment['default'])(this.get('minDate')).toDate(),
        end: (0, _moment['default'])().add(1, 'year').toDate(),
        name: 'all',
        action: 'instantPick'
      };
    }),

    selectedButtonRangeName: computed('i18n.locale', 'selectedRangeButton', function () {
      return this.get('i18n').t('sidebar.filters.date.' + this.get('selectedRangeButton').name);
    }),

    isSavedRange: computed('selectedRangeButton', 'savedRanges', function () {
      return this.get('savedRanges').includes(this.get('selectedRangeButton'));
    }),

    remarkableRangeName: computed('i18n.locale', 'selectedStartDate', 'selectedEndDate', function () {
      var selectedStartDate = this.get('selectedStartDate');
      if (this.isRangeType('day')) {
        return (0, _moment['default'])(selectedStartDate).format('dddd, LL');
      } else if (this.isRangeType('month')) {
        return (0, _moment['default'])(selectedStartDate).format('MMM YYYY');
      } else if (this.isRangeType('year')) {
        return (0, _moment['default'])(selectedStartDate).format('YYYY');
      } else {
        return null;
      }
    }),

    monthButton: { name: 'pick_a_month' },
    yearButton: { name: 'pick_a_year' },
    customRangeButton: { name: 'custom_range' },

    rangeButtons: computed('overallRange', 'defaultRanges', function () {
      return [this.get('overallRange')].concat(this.get('defaultRanges').map(function (range) {
        range.action = 'instantPick';
        return range;
      })).concat([this.get('monthButton'), this.get('yearButton'), this.get('customRangeButton')]);
    }),

    closePopup: function closePopup() {
      this.set('isOpen', false);
    },

    pickRange: function pickRange(startDate, endDate, button) {
      this.set('selectedStartDate', startDate);
      this.set('selectedEndDate', endDate);
      this.closePopup();
      if (this.get('rangePicked')) {
        this.get('rangePicked')(startDate, (0, _moment['default'])(endDate).endOf('day').toDate(), button);
      }
    },

    pickingMode: computed('selectedRangeButton', function () {
      var selectedButton = this.get('selectedRangeButton');
      if (selectedButton === this.get('monthButton')) {
        return 'month';
      } else if (selectedButton === this.get('yearButton')) {
        return 'year';
      } else {
        return 'main';
      }
    }),

    savedRanges: computed.oneWay('defaultRanges', 'savedRanges'),

    actions: {
      rangePickerClicked: function rangePickerClicked() {
        this.toggleProperty('isOpen');
      },

      rangeButtonClicked: function rangeButtonClicked(button) {
        this.set('selectedRangeButton', button);
        if (button.action) {
          this.send(button.action, button);
        }
      },

      instantPick: function instantPick(button) {
        this.send('rangePicked', button.start, button.end, button);
      },

      rangePicked: function rangePicked(selectedStartDate, selectedEndDate, button) {
        this.pickRange(selectedStartDate, selectedEndDate, button);
      },

      monthPicked: function monthPicked(month) {
        this.send('rangePicked', (0, _moment['default'])(month).startOf('month').toDate(), (0, _moment['default'])(month).endOf('month').toDate(), this.get('monthButton'));
      },

      yearPicked: function yearPicked(year) {
        this.send('rangePicked', (0, _moment['default'])(year).startOf('year').toDate(), (0, _moment['default'])(year).endOf('year').toDate(), this.get('yearButton'));
      },

      customRangePicked: function customRangePicked(selectedStartDate, selectedEndDate) {
        this.set('selectedRangeButton', this.get('customRangeButton'));
        this.send('rangePicked', selectedStartDate, selectedEndDate, this.get('customRangeButton'));
      },

      cancel: function cancel() {
        this.set('selectedStartDate', this.get('startDate'));
        this.set('selectedEndDate', this.get('endDate'));
        this.set('selectedRangeButton', this.get('pickedRangeButton'));
        this.closePopup();
      }
    }
  });
});