define('due-dashboard/components/digests/filters/sentiment-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var A = _ember['default'].A;
  exports['default'] = Component.extend({

    init: function init() {
      if (!this.get('filter.value')) {
        this.set('filter.value', []);
      }
      this._super.apply(this, arguments);
    },

    sentimentOptions: A([{ name: 'positive', value: 2, icon: 'thumb_up_outlined', color: '#00B548' }, { name: 'neutral', value: 1, icon: 'thumb_left_outlined', color: '#FAB005' }, { name: 'negative', value: 0, icon: 'thumb_down_outlined', color: '#FA5252' }, { name: 'without', value: -1, icon: 'label', color: '#979797' }]),

    sentimentFilter: computed('filter.value', {
      get: function get() {
        var _this = this;

        return this.get('sentimentOptions').filter(function (sentiment) {
          return _this.get('filter.value').includes(sentiment.value);
        });
      }, set: function set(_, filter) {
        this.set('filter.value', filter.mapBy('value'));
        this.get('saveDigestDelay')();
        return filter;
      }
    }),

    actions: {
      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});