define('due-dashboard/utils/zendesk/constants', ['exports', 'due-dashboard/config/environment'], function (exports, _dueDashboardConfigEnvironment) {

  var operators = {
    group_1: ['is', 'is_not', 'changed', 'value', 'value_previous', 'not_changed', 'not_value', 'not_value_previous'],
    group_2: ['is', 'is_not', 'less_than', 'greater_than', 'changed', 'value', 'value_previous', 'not_changed', 'not_value', 'not_value_previous'],
    group_3: ['is', 'is_not', 'changed', 'changed_to', 'changed_from', 'not_changed', 'not_changed_to', 'not_changed_from'],
    group_4: ['is', 'is_not'],
    number: ['less_than', 'greater_than', 'is']
  };

  var via_channel = [{ name: 'Web form', key: 'web_form', id: 0 }, { name: 'Email', key: 'mail', id: 4 }, { name: 'Chat', key: 'chat', id: 29 }, { name: 'Twitter', key: 'twitter', id: 30 }, { name: 'Twitter DM', key: 'twitter_dm', id: 26 }, { name: 'Twitter like', key: 'twitter_favorite', id: 23 }, { name: 'Voicemail', key: 'voicemail', id: 33 }, { name: 'Phone call (incoming)', key: 'phone_call_inbound', id: 34 }, { name: 'Phone call (outbound)', key: 'phone_call_outbound', id: 35 }, { name: 'CTI* voicemail', key: 'api_voicemail', id: 44 }, { name: 'CTI phone call (inbound)', key: 'api_phone_call_inbound', id: 45 }, { name: 'CTI phone call (outbound)', key: 'api_phone_call_outbound', id: 46 }, { name: 'SMS', key: 'sms', id: 57 }, { name: 'Get Satisfaction', key: 'get_satisfaction', id: 16 }, { name: 'Web Widget', key: 'web_widget', id: 48 }, { name: 'Mobile SDK', key: 'mobile_sdk', id: 49 }, { name: 'Mobile', key: 'mobile', id: 56 }, { name: 'Help Center post', key: 'helpcenter', id: 50 }, { name: 'Web service (API)', key: 'web_service', id: 5 }, { name: 'Trigger, automation', key: 'rule', id: 8 }, { name: 'Closed ticket', key: 'closed_ticket', id: 27 }, { name: 'Ticket sharing', key: 'ticket_sharing', id: 31 }, { name: 'Facebook post', key: 'facebook_post', id: 38 }, { name: 'Facebook private message', key: 'facebook_message', id: 41 }, { name: 'Satisfaction prediction', key: 'satisfaction_prediction', id: 54 }, { name: 'Channel framework', key: 'any_channel', id: 55 }];

  var DIDUENJOY_SEGMENTS = {
    ticket_title: "{{ticket.title}}", // Ticket's subject
    ticket_description: "{{ticket.description}}", // Ticket's description
    ticket_url: "{{ticket.url}}", // Ticket's URL
    ticket_id: "{{ticket.id}}", // Ticket's ID
    ticket_external_id: "{{ticket.external_id}}", // Ticket's external ID
    ticket_via: "{{ticket.via}}", // Ticket's source
    ticket_status: "{{ticket.status}}", // Ticket's status
    ticket_priority: "{{ticket.priority}}", // Ticket's priority
    ticket_ticket_type: "{{ticket.ticket_type}}", // Ticket's type
    ticket_group_name: "{{ticket.group.name}}", // Ticket's assigned group
    ticket_brand_name: "{{ticket.brand.name}}", // Ticket's brand
    ticket_due_date: "{{ticket.due_date}}", // Ticket's due date (relevant for tickets of type Task)
    ticket_account: "{{ticket.account}}", // This Zendesk Support's account name
    ticket_assignee_email: "{{ticket.assignee.email}}", // Ticket assignee email (if any)
    ticket_assignee_name: "{{ticket.assignee.name}}", // Assignee's full name
    ticket_assignee_first_name: "{{ticket.assignee.first_name}}", // Assignee's first name
    ticket_assignee_last_name: "{{ticket.assignee.last_name}}", // Assignee's last name
    ticket_requester_name: "{{ticket.requester.name}}", // Requester's full name
    ticket_requester_first_name: "{{ticket.requester.first_name}}", // Requester's first name
    ticket_requester_last_name: "{{ticket.requester.last_name}}", // Requester's last name
    ticket_requester_phone: "{{ticket.requester.phone}}", // Requester's phone number
    ticket_requester_external_id: "{{ticket.requester.external_id}}", // Requester's external ID
    ticket_requester_field: "{{ticket.requester_field}}", // Name or email
    ticket_requester_details: "{{ticket.requester.details}}", // Detailed information about the ticket's requester
    ticket_organization_name: "{{ticket.organization.name}}", // Requester's organization
    ticket_organization_external_id: "{{ticket.organization.external_id}}", // Ticket's organization external ID
    ticket_organization_details: "{{ticket.organization.details}}", // The details about the organization of the ticket's requester
    ticket_organization_notes: "{{ticket.organization.notes}}", // The notes about the organization of the ticket's requester
    ticket_ccs: "{{ticket.ccs}}", // Ticket's CCs
    ticket_cc_names: "{{ticket.cc_names}}", // Ticket's CCs names
    ticket_tags: "{{ticket.tags}}", // Ticket's tags
    ticket_current_holiday_name: "{{ticket.current_holiday_name}}", // Displays the name of the current holiday on the ticket's schedule
    current_user_name: "{{current_user.name}}", // Your full name
    current_user_first_name: "{{current_user.first_name}}", // Your first name
    current_user_email: "{{current_user.email}}", // Your primary email
    current_user_organization_name: "{{current_user.organization.name}}", // Your default organization
    current_user_organization_notes: "{{current_user.organization.notes}}", // Your default organization's note
    current_user_organization_details: "{{current_user.organization.details}}", // Your default organization's details
    current_user_external_id: "{{current_user.external_id}}", // Your external ID
    current_user_phone: "{{current_user.phone}}", // Your phone number
    current_user_details: "{{current_user.details}}", // Your details, stored in your profile
    current_user_notes: "{{current_user.notes}}", // Your notes, stored in your profile
    current_user_language: "{{current_user.language}}", // Your chosen language
    satisfaction_current_rating: "{{satisfaction.current_rating}}", // The text of the current satisfaction rating
    satisfaction_current_comment: "{{satisfaction.current_comment}}" };

  // The text of the current satisfaction rating comment
  var DIDUENJOY_TARGET_BASE = {
    active: true,
    content_type: "application/json",
    method: 'post',
    target_url: _dueDashboardConfigEnvironment['default'].EmberENV.API_URL + '/api/v1/easy_dispatches_without_validations',
    password: null,
    type: 'url_target_v2',
    title: 'diduenjoy integration auto generated target'
  };

  // missing :
  // --requester_time_zone--

  var CONDITIONS_REFS = [{
    name: 'role',
    operators: operators.group_4
  }, {
    name: 'requester_role',
    operators: operators.group_4
  }, {
    name: 'ticket_due_date',
    operators: ['is'],
    values: ['true', 'false']
  }, {
    name: 'ticket_form_id',
    operators: operators.group_3
  }, {
    name: 'number_of_incidents',
    is_number: true,
    operators: operators.number
  }, {
    name: 'brand_id',
    operators: operators.group_1
  }, {
    name: 'group_id',
    operators: operators.group_1
  }, {
    name: 'assignee_id',
    operators: operators.group_1
  }, {
    name: 'organization_id',
    operators: operators.group_1
  }, {
    name: 'requester_id',
    operators: operators.group_1
  }, {
    name: 'current_tags',
    operators: ['includes', 'not_includes'],
    multi_input: true
  }, {
    name: 'via_id',
    operators: operators.group_4,
    values: via_channel
  }, {
    name: 'recipient',
    operators: ['is'],
    key: 'email'
  }, {
    name: 'type',
    operators: operators.group_1,
    values: ['question', 'incident', 'problem', 'task']
  }, {
    name: 'status',
    operators: operators.group_2,
    values: ['new', 'open', 'pending', 'hold', 'solved', 'closed']
  }, {
    name: 'priority',
    operators: operators.group_2,
    values: ['-', 'low', 'normal', 'high', 'urgent']
  }, {
    name: 'locale_id',
    operators: operators.group_4,
    key: 'native_name'
  }, {
    name: 'satisfaction_score',
    operators: operators.group_2,
    values: ['good_with_comment', 'good', 'bad_with_comment', 'bad', 'unoffered', 'offered']
  }, {
    name: 'subject_includes_word',
    operators: ['includes', 'not_includes', 'is', 'is_not'],
    is_string: true //string
  }, {
    name: 'comment_includes_word',
    operators: ['includes', 'not_includes', 'is', 'is_not'],
    is_string: true //string
  }, {
    name: 'current_via_id',
    operators: operators.group_4,
    values: via_channel
  }, {
    name: 'update_type',
    operators: ['is'],
    values: ['Create', 'Change']
  }, {
    name: 'comment_is_public',
    operators: ['is'],
    values: ['true', 'false', 'not_relevant', 'requester_can_see_comment']
  }, {
    name: 'ticket_is_public',
    operators: ['is'],
    values: ['public', 'private']
  }, {
    name: 'reopens',
    is_number: true,
    operators: operators.number
  }, {
    name: 'replies',
    is_number: true,
    operators: operators.number
  }, {
    name: 'agent_stations',
    is_number: true,
    operators: operators.number
  }, {
    name: 'group_stations',
    is_number: true,
    operators: operators.number
  }, {
    name: 'requester_twitter_followers_count',
    is_number: true,
    operators: operators.number
  }, {
    name: 'requester_twitter_statuses_count',
    is_number: true,
    operators: operators.number
  }, {
    name: 'requester_twitter_verified',
    operators: ['is'],
    values: ['true']
  }];

  exports['default'] = {
    DIDUENJOY_SEGMENTS: DIDUENJOY_SEGMENTS,
    DIDUENJOY_TARGET_BASE: DIDUENJOY_TARGET_BASE,
    CONDITIONS_REFS: CONDITIONS_REFS
  };
});