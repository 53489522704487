define("due-dashboard/templates/components/digests/filters/status-filter", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 10,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/digests/filters/status-filter.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat", ["digest.filter.", ["get", "status", ["loc", [null, [9, 33], [9, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 8], [9, 40]]], 0, 0]], [], ["loc", [null, [9, 4], [9, 42]]], 0, 0]],
          locals: ["status"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/filters/status-filter.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "due-select", [], ["value", ["subexpr", "@mut", [["get", "filter.value", ["loc", [null, [4, 10], [4, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "statusOptions", ["loc", [null, [5, 12], [5, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "containerSelector", ".content-settings", "onChange", ["subexpr", "action", ["changeStatus"], [], ["loc", [null, [7, 13], [7, 36]]], 0, 0]], 0, null, ["loc", [null, [3, 2], [10, 17]]]], ["inline", "due-btn", [], ["action", ["subexpr", "action", ["deleteFilter"], [], ["loc", [null, [11, 19], [11, 42]]], 0, 0], "icon", "tio-clear", "intent", "tertiary", "size", "medium"], ["loc", [null, [11, 2], [11, 93]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/digests/filters/status-filter.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "labeled-input", [], ["label", ["subexpr", "t", ["digest.filter.status"], [], ["loc", [null, [2, 23], [2, 49]]], 0, 0], "contentClass", "with-remove-button"], 0, null, ["loc", [null, [2, 0], [12, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});