define('due-dashboard/components/match-devices-scenario-condition', ['exports', 'ember', 'due-dashboard/components/scenario-condition-item'], function (exports, _ember, _dueDashboardComponentsScenarioConditionItem) {
  var setDiff = _ember['default'].computed.setDiff;
  exports['default'] = _dueDashboardComponentsScenarioConditionItem['default'].extend({
    devices: ['desktop', 'android_phone', 'android_tablet', 'iphone', 'ipad', 'other'],

    devicesLabel: {
      desktop: "components.match-devices-scenario-condition.desktop",
      android_phone: "components.match-devices-scenario-condition.android_phone",
      android_tablet: "components.match-devices-scenario-condition.android_tablet",
      iphone: "components.match-devices-scenario-condition.iphone",
      ipad: "components.match-devices-scenario-condition.ipad",
      other: "components.match-devices-scenario-condition.other"
    },

    remainingDevices: setDiff('devices', 'condition.devices')
  });
});