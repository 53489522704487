define("due-dashboard/templates/components/digests/single-custom-metric", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/digests/single-custom-metric.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content-child");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content-child");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content-child");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        return morphs;
      },
      statements: [["inline", "digests/parts/one-kpi-part", [], ["finalKpi", ["subexpr", "@mut", [["get", "comp.options.kpi", ["loc", [null, [2, 40], [2, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "kpis", ["subexpr", "@mut", [["get", "kpis", ["loc", [null, [2, 62], [2, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [2, 83], [2, 98]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 100]]], 0, 0], ["inline", "digests/parts/color-picker-part", [], ["defaultMax", 10, "options", ["subexpr", "@mut", [["get", "comp.options", ["loc", [null, [7, 12], [7, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "current_period_colors", "text", "digest.color_label", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [10, 20], [10, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [5, 2], [11, 4]]], 0, 0], ["inline", "digests/parts/periods-part", [], ["defaultMax", 10, "analysingYear", ["subexpr", "@mut", [["get", "analysingYear", ["loc", [null, [16, 18], [16, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "hideAverage", true, "period", ["subexpr", "@mut", [["get", "period", ["loc", [null, [18, 11], [18, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "comp", ["subexpr", "@mut", [["get", "comp", ["loc", [null, [19, 9], [19, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [20, 20], [20, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 2], [21, 4]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});