define('due-dashboard/components/dynamic-templates-dropdown', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    tagName: '',

    searchField: 'name',

    init: function init() {
      var _this = this;

      this._searchTemplates('', function (templates) {
        return _this.set('options', templates);
      }, null);
      this._super.apply(this, arguments);
    },

    _searchTemplates: function _searchTemplates(term, resolve, reject) {
      this.store.query('template', {
        filter: {
          name: term
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (templates) {
        return resolve(templates);
      }, reject);
    },

    actions: {

      searchTemplates: function searchTemplates(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this2, _this2._searchTemplates, term, resolve, reject, 600);
        });
      }

    }

  });
});