define('due-dashboard/serializers/message', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.data.attributes['created-at'];
      delete json.data.attributes['updated-at'];
      delete json.data.attributes['author'];
      delete json.data.attributes['translation'];

      return json;
    }
  });
});