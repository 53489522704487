define('due-dashboard/serializers/email-alert-scenario-action', ['exports', 'ember-data', 'due-dashboard/mixins/embedded-jsonapi-records'], function (exports, _emberData, _dueDashboardMixinsEmbeddedJsonapiRecords) {
  var JSONAPISerializer = _emberData['default'].JSONAPISerializer;
  exports['default'] = JSONAPISerializer.extend(_dueDashboardMixinsEmbeddedJsonapiRecords['default'], {

    attrs: {
      email_alert_scenario_actions_accounts: {
        serialize: 'records',
        deserialize: 'ids-and-type'
      },
      email_alert_scenario_actions_segments: {
        serialize: 'records',
        deserialize: 'ids-and-type'
      }
    }

  });
});