define("due-dashboard/serializers/chat", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].JSONAPISerializer.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      // TODO: clean/remove unused code unread
      delete json.data.attributes["unread"];
      delete json.data.attributes["chat-name"];
      delete json.data.attributes["chat-title"];
      delete json.data.attributes["last-message"];
      delete json.data.attributes["last-message-date"];
      delete json.data.attributes["preset-message"];
      delete json.data.attributes["total-pages"];
      delete json.data.attributes["updated-at"];

      return json;
    }
  });
});