define('due-dashboard/components/field-validation-display', ['exports', 'ember'], function (exports, _ember) {
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;
  var on = _ember['default'].on;
  exports['default'] = Component.extend({

    error_serv: inject.service('errors'),

    displayInvalid: true,
    meta: null,
    displayValid: true,
    enabled: true,
    notNew: _ember['default'].computed.not('model.isNew'),
    saved: _ember['default'].computed.oneWay('notNew'),
    _displayed: _ember['default'].computed.and('enabled', 'saved'),
    errors: _ember['default'].computed.alias('model.errors'),
    error: null,
    valid: _ember['default'].computed.none('error'),

    displayed: computed('_displayed', 'displayInvalid', 'displayValid', 'valid', function () {
      var valid = this.get('valid');
      return this.get('_displayed') && (this.get('displayInvalid') !== valid || this.get('displayValid') === valid);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var field = this.get('field');
      if (_ember['default'].isEmpty(field)) {
        throw 'Invalid empty field.';
      }
    },

    watchState: on('init', observer('model.currentState.stateName', function () {
      var _this = this;

      var state = this.get('model.currentState.stateName');
      // Maybe we could replace this by observing becameInvalid, didCreate and didUpdate
      if (state && state.match(/^root\.loaded\.(?:(?:created|updated)\.invalid|saved)$/)) {
        var field = this.get('field');
        var fieldKey = 'errors.' + field;
        _ember['default'].run.next(function () {
          if (_this.isDestroyed) {
            return;
          }
          if (_this.get('model.validations.attrs.' + field + '.isValid') === false) {
            _this.set('error', _this.get('model.validations.attrs.' + field));
          } else {
            var err = _this.get(fieldKey);
            if (err) {
              err = err.get('firstObject');
            }
            _this.set('error', err);
          }
          _this.get('error_serv').updateErrorByKey(field, _this.get('error'), _this.get('meta'));
          _this.set('saved', true);
        });
      }
    }))

  });
});