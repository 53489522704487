define('due-dashboard/components/v2/-survey-preview', ['exports', 'due-dashboard/config/environment', 'ember'], function (exports, _dueDashboardConfigEnvironment, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['due-survey-preview'],
    previewDevice: 'desktop',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_', new Date().getTime());
    },

    _triggerFullscreen: function _triggerFullscreen(device, previousDevice) {
      var leftTrigger = document.getElementById("editor-navbar-toggle-icon-left");
      var rightTrigger = document.getElementById("editor-navbar-toggle-icon-right");
      var leftSideIsOpen = !leftTrigger.parentElement.classList.value.includes('closed');
      var rightSideIsOpen = !rightTrigger.parentElement.classList.value.includes('closed');

      if (device === 'fullscreen') {
        if (leftSideIsOpen) leftTrigger.click(); // close only if opened, when activating fullscreen
        if (rightSideIsOpen) rightTrigger.click(); // same
      }
      if (previousDevice === 'fullscreen') {
        if (!leftSideIsOpen) leftTrigger.click(); // open only if closed, when quitting fullscreen
        if (!rightSideIsOpen) rightTrigger.click(); // same
      }
    },

    previewURL: computed('survey_draft', 'previewSelectedStepIndex', 'previewSelectedElementIndex', 'loadingPromise.isPending', 'currentLanguage', function () {
      if (this.get('survey_draft')) {
        var baseHost = _dueDashboardConfigEnvironment['default'].EmberENV.SURVEY_URL + '/' + this.get('currentLanguage').toLowerCase() + '/preview/' + this.get('survey_draft').id;
        var queryParams = {
          preview_selected_step_position: this.get('previewSelectedStepIndex'),
          preview_selected_element_position: this.get('previewSelectedElementIndex'),
          dashboard_language: this.get('currentAccount.content.language.tag'),
          _: this.get('_')
        };
        if (this.get('loadingPromise.isPending')) {
          this.set('_', new Date().getTime());
        }
        if (this.get('previewSelectedStepIndex') == -1 && this.get('previewStepIndex')) {
          queryParams['preview_current_step_position'] = this.get('previewStepIndex');
        }
        var formattedFilterSet = Object.entries(queryParams).map(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];
          return key + '=' + value;
        }).join('&');
        return baseHost + '?' + formattedFilterSet;
      }
    }),

    previewClass: computed('survey_draft', 'previewSelectedStepIndex', 'previewSelectedElementIndex', 'loadingPromise.isPending', 'previewDevice', function () {
      return ({
        'desktop': 'responsive-preview',
        'tablet': 'responsive-tablet-preview',
        'mobile': 'responsive-mobile-preview',
        'fullscreen': 'responsive-preview'
      })[this.get('previewDevice')];
    }),

    actions: {
      togglePreviewDevice: function togglePreviewDevice(device) {
        var previousDevice = this.get('previewDevice');

        this.set('previewDevice', device);
        if (device === 'fullscreen' || previousDevice === 'fullscreen') this._triggerFullscreen(device, previousDevice);
      },

      onLanguageChanged: function onLanguageChanged(language) {
        if (language) {
          this.set('currentLanguage', language);
        }
      }
    }
  });
});