define('due-dashboard/components/due-input-autocomplete', ['exports', 'ember', 'ember-cli-keyboard-nav/mixins/keyboard-nav'], function (exports, _ember, _emberCliKeyboardNavMixinsKeyboardNav) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_emberCliKeyboardNavMixinsKeyboardNav['default'], {
    store: service(),
    errors: service(),
    classNames: ['due-input-autocomplete'],
    name: computed.alias('model.id'),
    inputValue: computed.alias('model.text'),
    selectedTerm: '',
    options: [],
    highlightedOptionIndex: -1,
    hasResults: false,
    fromSelf: false,

    didInsertElement: function didInsertElement() {
      this.bindKeys(this.$('input[type="text"]'));
    },

    error: computed('errors.updateHook', function () {
      return this.get('errors').getMessageByKey(this.get('error_key') || this.get('value'));
    }),

    updateOptions: function updateOptions() {
      var _this = this;

      this.get('store').query('questions-suggestion', { filter: { text: this.get('inputValue') } }).then(function (questions) {
        _this.set('options', questions);
        _this.set('hasResults', _this.get('selectedTerm') !== _this.get('inputValue') && questions.toArray().length > 0);
      });
    },

    highlightedOption: computed('options.[]', 'highlightedOptionIndex', function () {
      return this.get('options').objectAt(this.get('highlightedOptionIndex'));
    }),

    onEnterPress: function onEnterPress() {
      var result = this.get("options").objectAt(this.get("highlightedOptionIndex"));
      if (result) {
        var value = result.get('text');
        this.set('selectedTerm', value);
        this.set('inputValue', value);
        this.set('model.text', value);
        this.onEscPress();
      }
    },

    onEscPress: function onEscPress() {
      this.set('options', []);
      this.set('highlightedOptionIndex', -1);
      this.set('hasResults', false);
      this.get('fieldTextChanged')();
    },

    onDownPress: function onDownPress() {
      var index = this.get("highlightedOptionIndex");
      if (index === this.get("options.length") - 1) {
        index = 0;
      } else {
        index++;
      }
      this.set("highlightedOptionIndex", index);
    },

    onUpPress: function onUpPress() {
      var lastItem = this.get("options.length") - 1,
          index = lastItem;
      if (this.get("highlightedOptionIndex") >= 0) {
        index = this.get("highlightedOptionIndex") - 1;
      }
      if (index < 0) {
        index = lastItem;
      }
      this.set("highlightedOptionIndex", index);
    },

    actions: {
      debounceSearchQuestion: function debounceSearchQuestion(_v, e) {
        if (e.keyCode !== 27) {
          run.debounce(this, this.updateOptions, 300);
        }
      },

      selectOption: function selectOption(option) {
        var value = option.get('text');
        this.set('selectedTerm', value);
        this.set('inputValue', value);
        this.set('model.text', value);
        this.onEscPress();
      },

      clickOutsideComponent: function clickOutsideComponent() {
        if (this.get('fromSelf')) {
          this.set('fromSelf', false);
          this.onEscPress();
        }
      },

      clickInsideComponent: function clickInsideComponent() {
        this.set('fromSelf', true);
      }
    }
  });
});