define('due-dashboard/components/digests/parts/segments-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    init: function init() {
      if (!this.get('comp.options.segments_to_display')) {
        this.set('comp.options.segments_to_display', []);
      }
      this._super.apply(this, arguments);
    },

    _searchSegment: function _searchSegment(term) {
      var _this = this;

      var searchPromise = this.store.query('segment', {
        filter: { name: term }
      });
      this.set('search_promise', searchPromise);
      searchPromise.then(function (segments) {
        return _this.set('filteredSegments', segments.filter(function (s) {
          return !_this.get('selectedSegments').includes(s);
        }));
      });
    },

    remainingSegments: computed.setDiff('segments', 'selectedSegments'),

    selectedSegments: computed('comp.options.segments_to_display', 'segments', function () {
      var segments_to_display = this.get('comp.options.segments_to_display');
      var segments = this.get('segments');
      return segments.filter(function (a) {
        return segments_to_display.includes(a.id);
      });
    }),

    dropdownSegments: computed('remainingSegments', 'filteredSegments', 'searchList', function () {
      return this.get('searchList') ? this.get('filteredSegments') : this.get('remainingSegments');
    }),

    actions: {

      setAddSegmentController: function setAddSegmentController(remoteController) {
        this.set('addSegmentController', remoteController);
      },

      addSegmentClick: function addSegmentClick() {
        this.get('addSegmentController').actions.open();
      },

      searchSegment: function searchSegment(term) {
        this.set('searchList', term && term !== '');
        this._searchSegment(term);
      },

      addSegment: function addSegment(segment) {
        var segments = this.get('comp.options.segments_to_display');
        segments.push(segment.id);
        this.set('comp.options.segments_to_display', segments);
        this.notifyPropertyChange('comp.options.segments_to_display');
        if (document.getElementsByClassName('ember-power-select-search-input')[0] !== undefined) {
          document.getElementsByClassName('ember-power-select-search-input')[0].value = '';
        }
        this.get('saveDigestDelay')();
      },

      removeSegment: function removeSegment(segment) {
        var segments = this.get('comp.options.segments_to_display');
        segments.splice(segments.indexOf(segment), 1);
        this.set('comp.options.segments_to_display', segments);
        this.notifyPropertyChange('comp.options.segments_to_display');
        this.get('saveDigestDelay')();
      }
    }
  });
});