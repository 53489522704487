define('due-dashboard/controllers/settings/integrations/woocommerce', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var and = _Ember$computed.and;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({

    integration: alias('model.company_integration'),
    submitEnabled: and('integration.api_version', 'integration.shop_url', 'integration.consumer_key', 'integration.consumer_secret', 'integration.post_confirmed_order_survey.id'),

    woocommerce_auth: computed('integration.{api_version,shop_url,consumer_key,consumer_secret,post_confirmed_order_survey}', function () {
      var version = this.get('integration.api_version');
      var url = this.get('integration.shop_url');
      var key = this.get('integration.consumer_key');
      var secret = this.get('integration.consumer_secret');
      var survey = this.get('integration.post_confirmed_order_survey.id');

      if (url !== '') {
        return _dueDashboardConfigEnvironment['default'].EmberENV.WOOCOMMERCE_CONNECT_URL + '/' + this.get('model.company.id') + '?version=' + version + '&url=' + url + '&key=' + key + '&secret=' + secret + '&survey=' + survey;
      } else {
        return "";
      }
    })

  });
});