define('due-dashboard/adapters/custom-stat', ['exports', 'due-dashboard/adapters/application'], function (exports, _dueDashboardAdaptersApplication) {
  exports['default'] = _dueDashboardAdaptersApplication['default'].extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      var res = this._super.apply(this, arguments);
      if (query.me === true) {
        res += '/me';
      }
      return res;
    }
  });
});