define('due-dashboard/routes/settings/digests/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var _this = this;

      var digest = this.store.find('due_digest', params.digest_id);
      var accounts = this.store.findAll('account');
      var segments = this.store.findAll('segment');
      var kpis = this.store.findAll('kpi');
      return this.store.query('language', { filter: { account_id: this.currentAccount.content.id } }).then(function (languages) {
        return _ember['default'].RSVP.hash({
          digest: digest,
          accounts: accounts,
          segments: segments,
          kpis: kpis,
          languages: languages,
          company: _this.get('currentAccount.content.company')
        });
      });
    },

    actions: {
      willTransition: function willTransition() {
        this.controller.set('errorMessage', null);
        this.controller.set('compErrorMessage', null);
        return true;
      }
    }
  });
});