define('due-dashboard/components/digests/parts/max-list-size-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['max-list-size'],
    classNameBindings: ['isContentChild:content-child'],
    isContentChild: false,

    /* Default props */
    'default': 10, // Default current value
    max: 20, // Max available value

    init: function init() {
      if (!this.get('value')) {
        this.set('value', this.get('default'));
      }
      this._super.apply(this, arguments);
    },

    computedValue: computed('value', {
      get: function get() {
        return this.get('value');
      }, set: function set(_, value) {
        var max = this.get('max');
        value = value > max ? max : value;
        this.set('value', value);
        this.get('saveDigestDelay')();
        return value;
      }
    })

  });
});