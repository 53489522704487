define('due-dashboard/serializers/bulk-dispatch', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({

    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      // do not serialize the attribute if readOnly!
      if (relationship.options && relationship.options.readOnly) {
        return;
      }
      this._super.apply(this, arguments);
    }

  });
});