define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/footer-design', ['exports', 'ember', 'due-dashboard/helpers/color-formating'], function (exports, _ember, _dueDashboardHelpersColorFormating) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    LOGO_COLORS: [{ value: 'black' }, { value: 'white' }],
    DEFAULT_COLOR: '#000000',
    DEFAULT_BG_COLOR: '#FFFFFF',
    COLOR_REGEX: /(#[0-9a-fA-F]*|rgba\((\d+,\s?){3}\d*\.\d*\))/g,

    currentDesignOptions: computed('isLoading', function () {
      return {
        logoColor: this._extractOption('designOptions.footer_logo_color', 'LOGO_COLORS'),
        textColor: this._extractColors(this.get('designOptions.footer_text_color'), this.get('DEFAULT_COLOR')),
        languageSelectorBgColor: this._extractColors(this.get('designOptions.footer_language_selector_bg_color'), this.get('DEFAULT_BG_COLOR'))
      };
    }),

    _extractColors: function _extractColors(color, defaultValue) {
      if (color == undefined) {
        return defaultValue;
      }
      var match = color.match(this.get('COLOR_REGEX'));
      if (match == undefined) {
        return defaultValue;
      }
      return match[0];
    },

    _extractOption: function _extractOption(designOptionName, optionsName) {
      var options = this.get(optionsName);
      var designOptions = this.get(designOptionName);
      return options.find(function (obj) {
        return obj.value === designOptions;
      }) || options[0];
    },

    actions: {
      selectDropdownValue: function selectDropdownValue(color) {
        this.set('currentDesignOptions.logoColor', color);
        this.sendAction('updateDesignOptions', { footer_logo_color: color.value });
      },

      setValue: function setValue(frontElement, designOption, value) {
        if (frontElement.toLowerCase().includes('color')) value = _dueDashboardHelpersColorFormating['default'].compute(value);
        this.set('currentDesignOptions.' + frontElement, value);
        this.sendAction('updateDesignOptions', _defineProperty({}, designOption, value));
      }
    }
  });
});