define('due-dashboard/components/change-end-text-scenario-action', ['exports', 'ember', 'due-dashboard/components/scenario-action-item'], function (exports, _ember, _dueDashboardComponentsScenarioActionItem) {
  var computed = _ember['default'].computed;
  var gt = computed.gt;
  var mapBy = computed.mapBy;
  var setDiff = computed.setDiff;
  var sort = computed.sort;
  exports['default'] = _dueDashboardComponentsScenarioActionItem['default'].extend({

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.store.findAll('language', { reload: true }).then(function (languages) {
        _this.set('languages', languages);
        if (_this.get('selectedLanguages').length === 0) {
          _this.addLanguage(languages.findBy('tag', _this.get('i18n.locale').toUpperCase()));
        }
      });
    },

    /*
    ** Error handling
    */

    text_error_key: new _ember['default'].Object(),
    has_many_language: true,
    language_tab_error_regexp: /change_end_text_scenario_action-[A-Z]/,

    errorObserverVars: {
      array_key: 'action.actions_languages',
      error_prefix: 'change_end_text_scenario_action',
      error_suffix: 'language.tag',
      error_target: 'validations.attrs.text.error'
    },

    error_keys: computed(function () {
      return this.get('errors.errorKeys').filter(function (a) {
        return !!a.match(/change_end_text_scenario_action-[A-Z]/);
      });
    }).volatile(),

    /*
    ** Core
    */

    actLanguageSort: ['language.tag'],
    languageSort: ['tag'],
    orderedActionLanguages: sort('action.actions_languages', 'actLanguageSort'),
    displayLanguageList: false,
    _selectedLanguages: mapBy('action.actions_languages', 'language.content'),
    _remainingLanguages: setDiff('languages', 'selectedLanguages'),
    selectedLanguages: sort('_selectedLanguages', 'languageSort'),
    remainingLanguages: sort('_remainingLanguages', 'languageSort'),
    canDeleteLanguage: gt('action.actions_languages.length', 1),

    addLanguage: function addLanguage(language) {
      var action = this.get('action');
      var at = this.store.createRecord('change-end-text-scenario-actions-language', {
        action: action,
        language: language
      });
      this.get('text_error_key').set(language.get('tag'), 'change_end_text_scenario_action-' + language.get('tag'));
      action.get('actions_languages').addObject(at);
      this.set('selectedLanguage', at);
    },

    removeLanguage: function removeLanguage(language) {
      this.get('errors').updateErrorByKey('change_end_text_scenario_action-' + language.get('tag'), null);
      this.get('action.actions_languages').removeObject(language);
      language.deleteRecord();
    },

    actions: {

      closeLanguageList: function closeLanguageList() {
        this.set('displayLanguageList', false);
      },

      openLanguageList: function openLanguageList() {
        this.set('displayLanguageList', true);
      },

      addLanguage: function addLanguage(language) {
        this.set('displayLanguageList', false);
        this.addLanguage(language);
      },

      removeLanguage: function removeLanguage(language) {
        this.removeLanguage(language);
      }
    }
  });
});