define('due-dashboard/helpers/default-survey-v2-translations', ['exports', 'ember'], function (exports, _ember) {
  exports.defaultSurveyV2Translations = defaultSurveyV2Translations;

  function defaultSurveyV2Translations(companyName) {
    var translations = defaultTranslations;
    Object.keys(translations).forEach(function (tag) {

      if (tag !== '_languages') {
        Object.keys(translations[tag].elements).forEach(function (stepIdx) {
          Object.keys(translations[tag].elements[stepIdx]).forEach(function (elemIdx) {
            if (translations[tag].elements[stepIdx][elemIdx].label && translations[tag].elements[stepIdx][elemIdx].label.includes("{company_name}")) {
              translations[tag].elements[stepIdx][elemIdx].label = translations[tag].elements[stepIdx][elemIdx].label.replace("{company_name}", companyName); // replace company name default var
            }
          });
        });
      }
    });

    return translations;
  }

  exports['default'] = _ember['default'].Helper.helper(defaultSurveyV2Translations);

  var defaultTranslations = {
    "en": {
      "elements": {
        "0": {
          "0": {
            "text": "It has been a pleasure to welcome you!"
          },
          "1": {
            "text": "Following your recent visit, we would like to learn more about your experience."
          },
          "2": {
            "label": "Would you recommend {company_name} to your friends and family?",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Absolutely not",
              "high_score": "10 - Yes, absolutely"
            }
          },
          "3": {
            "label": "Share your experience with us",
            "placeholder": "Write here"
          },
          "4": {
            "next_button_text": "Next"
          }
        },
        "1": {
          "0": {
            "text": "More about your most recent experience"
          },
          "1": {
            "text": "Please rate the following items"
          },
          "2": {
            "label": "The client advisor was knowledgeable",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Strongly disagree",
              "high_score": "10 - Strongly agree"
            }
          },
          "3": {
            "label": "I had a unique luxury store experience",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Strongly disagree",
              "high_score": "10 - Strongly agree"
            }
          },
          "4": {
            "previous_button_text": "Previous",
            "next_button_text": "Submit"
          }
        },
        "3": {
          "0": {
            "text": "Thank you we appreciate your feedback"
          },
          "1": {
            "text": "We look forward to see you again in our stores!"
          }
        }
      }
    },
    "fr": {
      "elements": {
        "0": {
          "0": {
            "text": "Ce fut un plaisir de vous accueillir !"
          },
          "1": {
            "text": "Après votre récente visite, nous aimerions en savoir plus sur votre expérience."
          },
          "2": {
            "label": "Recommenderiez-vous {company_name} à vos amis ou votre famille ?",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Non, pas du tout",
              "high_score": "10 - Oui, tout à fait"
            }
          },
          "3": {
            "label": "Partagez votre expérience avec nous",
            "placeholder": "Ecrire ici"
          },
          "4": {
            "next_button_text": "Suivant"
          }
        },
        "1": {
          "0": {
            "text": "En savoir plus sur votre expérience la plus récente"
          },
          "1": {
            "text": "Veuillez indiquer dans quelle mesure vous êtes d'accord avec les points suivants."
          },
          "2": {
            "label": "Le conseiller client était bien informé",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Pas du tout d’accord",
              "high_score": "10 - Tout à fait d’accord"
            }
          },
          "3": {
            "label": "J'ai vecu une expérience mémorable et unique en son genre",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Pas du tout d’accord",
              "high_score": "10 - Tout à fait d’accord"
            }
          },
          "4": {
            "previous_button_text": "Précédent",
            "next_button_text": "Soumettre"
          }
        },
        "3": {
          "0": {
            "text": "Merci de votre réponse, vos commentaires nous sont précieux"
          },
          "1": {
            "text": "Nous nous réjouissons de vous revoir très prochainement"
          }
        }
      }
    },
    "es": {
      "elements": {
        "0": {
          "0": {
            "text": "¡Ha sido un placer darte la bienvenida!"
          },
          "1": {
            "text": "Después de su visita reciente, nos gustaría aprender más sobre su experiencia."
          },
          "2": {
            "label": "¿Recomendaría {company_name} a sus amigos y familiares?",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - En absoluto",
              "high_score": "10 - Sí, claro"
            }
          },
          "3": {
            "label": "Comparta su experiencia con nosotros",
            "placeholder": "Escriba aquí"
          },
          "4": {
            "next_button_text": "Siguiente"
          }
        },
        "1": {
          "0": {
            "text": "Más sobre tu experiencia más reciente"
          },
          "1": {
            "text": "Por favor, valore su nivel de acuerdo con los siguientes puntos."
          },
          "2": {
            "label": "El asesor del cliente estaba bien informado",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Nada de acuerdo",
              "high_score": "10 - Totalmente de acuerdo"
            }
          },
          "3": {
            "label": "Tuve una experiencia memorable y única",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Nada de acuerdo",
              "high_score": "10 - Totalmente de acuerdo"
            }
          },
          "4": {
            "previous_button_text": "Anterior",
            "next_button_text": "Enviar"
          }
        },
        "3": {
          "0": {
            "text": "Gracias por compartir su opinión con nosotros. Apreciamos sus comentarios."
          },
          "1": {
            "text": "Esperamos verle pronto. Hasta entonces, le mandamos un cordial saludo."
          }
        }
      }
    },
    "nl": {
      "elements": {
        "0": {
          "0": {
            "text": "Het was een genoegen u te verwelkomen!"
          },
          "1": {
            "text": "Na uw recente bezoek krijgen wij graag meer informatie over uw ervaring."
          },
          "2": {
            "label": "Zou u {company_name} aan u aanbevelen aan uw vrienden en familie?",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Helemaal niet",
              "high_score": "10 - Ja, absoluut"
            }
          },
          "3": {
            "label": "Deel uw ervaring met ons",
            "placeholder": "Schrijf hier"
          },
          "4": {
            "next_button_text": "Volgende"
          }
        },
        "1": {
          "0": {
            "text": "Meer over uw meest recente ervaring"
          },
          "1": {
            "text": "Geef aan in hoeverre u het eens bent met de volgende uitspraken"
          },
          "2": {
            "label": "De klantadviseur was deskundig",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Zeer oneens",
              "high_score": "10 - Zeer eens"
            }
          },
          "3": {
            "label": "Ik had een gedenkwaardige en unieke ervaring",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - Zeer oneens",
              "high_score": "10 - Zeer eens"
            }
          },
          "4": {
            "previous_button_text": "Vorig",
            "next_button_text": "Versturen"
          }
        },
        "3": {
          "0": {
            "text": "Hartelijk dank voor het delen van uw ervaring, wij waarderen uw mening."
          },
          "1": {
            "text": "We kijken er naar uit onze reis samen te vervolgen!"
          }
        }
      }
    },
    "zh": {
      "elements": {
        "0": {
          "0": {
            "text": "很高兴欢迎您！"
          },
          "1": {
            "text": "在您最近的访问之后，我们想进一步了解您的经验。"
          },
          "2": {
            "label": "您会向您的朋友和家人推荐{company_name}吗？",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - 绝对不是",
              "high_score": "10 - 是的，当然"
            }
          },
          "3": {
            "label": "请与我们分享您的体验",
            "placeholder": "请在此处输入"
          },
          "4": {
            "next_button_text": "下一个"
          }
        },
        "1": {
          "0": {
            "text": "有关您的最新经历的更多信息"
          },
          "1": {
            "text": "请评定您对以下项目的同意程度。"
          },
          "2": {
            "label": "客户顾问知识渊博",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - 完全不同意",
              "high_score": "10 - 完全同意"
            }
          },
          "3": {
            "label": "我有一个令人难忘而独特的经历",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - 完全不同意",
              "high_score": "10 - 完全同意"
            }
          },
          "4": {
            "previous_button_text": "上一个",
            "next_button_text": "提交"
          }
        },
        "3": {
          "0": {
            "text": "感谢您与我们分享您的意见。我们感谢您的反馈。"
          },
          "1": {
            "text": "期待与您继续我们美好的旅程！"
          }
        }
      }
    },
    "zht": {
      "elements": {
        "0": {
          "0": {
            "text": "很高興歡迎您！"
          },
          "1": {
            "text": "在您最近的訪問之後，我們想進一步瞭解您的經驗。"
          },
          "2": {
            "label": "您會向您的朋友和家人推薦{company_name}嗎？",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - 絕對不是",
              "high_score": "10 - 是的，當然"
            }
          },
          "3": {
            "label": "與我們分享你的體驗",
            "placeholder": "請在此與我們分享您的想法"
          },
          "4": {
            "next_button_text": "下一步"
          }
        },
        "1": {
          "0": {
            "text": "有關您的最新經歷的更多信息"
          },
          "1": {
            "text": "請評定您對以下項目的同意程度。"
          },
          "2": {
            "label": "客戶顧問知識淵博",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - 完全不同意",
              "high_score": "10 - 完全同意"
            }
          },
          "3": {
            "label": "我有一個令人難忘而獨特的經歷",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - 完全不同意",
              "high_score": "10 - 完全同意"
            }
          },
          "4": {
            "previous_button_text": "上一步",
            "next_button_text": "送出"
          }
        },
        "3": {
          "0": {
            "text": "感謝您與我們分享您的意見, 您的反饋對我們非常重要。"
          },
          "1": {
            "text": "期待與您繼續我們美好的旅程！"
          }
        }
      }
    },
    "ko": {
      "elements": {
        "0": {
          "0": {
            "text": "당신을 환영하게되어 기뻤습니다!"
          },
          "1": {
            "text": "최근 방문 후, 우리는 귀하의 경험에 대해 더 배우고 싶습니다."
          },
          "2": {
            "label": "친구와 가족에게 {company_name}를 추천 하시겠습니까?",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - 아니오",
              "high_score": "10 - 네"
            }
          },
          "3": {
            "label": "귀하의 경험을 저희와 공유해 주십시오",
            "placeholder": "이곳에 작성"
          },
          "4": {
            "next_button_text": "다음"
          }
        },
        "1": {
          "0": {
            "text": "가장 최근의 경험에 대한 자세한 내용"
          },
          "1": {
            "text": "다음 항목에 대해 동의하는 정도를 표시해 주세요."
          },
          "2": {
            "label": "고객 고문은 지식이 풍부했습니다",
            "na_label": "N/A",
            "legend": {
              "low_score": "0점 = 전혀 동의하지 않는다",
              "high_score": "10점 = 전적으로 동의한다"
            }
          },
          "3": {
            "label": "나는 기억에 남고 독특한 경험을 가졌다",
            "na_label": "N/A",
            "legend": {
              "low_score": "0점 = 전혀 동의하지 않는다",
              "high_score": "10점 = 전적으로 동의한다"
            }
          },
          "4": {
            "previous_button_text": "이전",
            "next_button_text": "제출"
          }
        },
        "3": {
          "0": {
            "text": "설문조사에 응해주셔서 감사합니다."
          },
          "1": {
            "text": "저희와 함께할 여정을 기대합니다!"
          }
        }
      }
    },
    "ja": {
      "elements": {
        "0": {
          "0": {
            "text": "あなたを歓迎することは喜びでした！"
          },
          "1": {
            "text": "あなたの最近の訪問の後、私たちはあなたの経験についてもっと知りたいと思います。"
          },
          "2": {
            "label": "友達や家族に{company_name}をお勧めしますか？",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - いいえ、全く",
              "high_score": "10 - はい、とても"
            }
          },
          "3": {
            "label": "なぜそのように感じられたのか、店舗での体験を教えてください。",
            "placeholder": "コメントを入力"
          },
          "4": {
            "next_button_text": "次へ"
          }
        },
        "1": {
          "0": {
            "text": "あなたの最新の経験の詳細"
          },
          "1": {
            "text": "以下の項目について、あなたの同意度をお答えください。"
          },
          "2": {
            "label": "クライアントアドバイザーは知識が豊富でした",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 -まったくそう思わない",
              "high_score": "10 - とてもそう思う"
            }
          },
          "3": {
            "label": "私は思い出に残るユニークな経験をしました",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 -まったくそう思わない",
              "high_score": "10 - とてもそう思う"
            }
          },
          "4": {
            "previous_button_text": "前へ",
            "next_button_text": "送信"
          }
        },
        "3": {
          "0": {
            "text": "この度は、貴重なご意見をいただきまして、誠にありがとうございます。"
          },
          "1": {
            "text": "今後ともルイ･ヴィトンをご愛顧くださいますようお願いい申しあげます。"
          }
        }
      }
    },
    "th": {
      "elements": {
        "0": {
          "0": {
            "text": "มีความสุขที่ได้ต้อนรับคุณ!"
          },
          "1": {
            "text": "หลังจากการเยี่ยมชมล่าสุดของคุณเราต้องการเรียนรู้เพิ่มเติมเกี่ยวกับประสบการณ์ของคุณ"
          },
          "2": {
            "label": "คุณจะแนะนำ {company_name} ให้เพื่อนและครอบครัวของคุณหรือไม่?",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - ไม่อย่างแน่นอน",
              "high_score": "10 - ใช่เลย"
            }
          },
          "3": {
            "label": "แบ่งปันประสบการณ์ของคุณกับเรา",
            "placeholder": "เขียนที่นี่"
          },
          "4": {
            "next_button_text": "ต่อ"
          }
        },
        "1": {
          "0": {
            "text": "เพิ่มเติมเกี่ยวกับประสบการณ์ล่าสุดของคุณ"
          },
          "1": {
            "text": "โปรดให้คะแนนระดับความเห็นพ้องของคุณกับรายการต่อไปนี้"
          },
          "2": {
            "label": "ที่ปรึกษาลูกค้ามีความรู้",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - ไม่เห็นด้วยอย่างยิ่ง",
              "high_score": "10 - เห็นด้วยอย่างยิ่ง"
            }
          },
          "3": {
            "label": "ฉันมีประสบการณ์ที่น่าจดจำและเป็นเอกลักษณ์",
            "na_label": "N/A",
            "legend": {
              "low_score": "0 - ไม่เห็นด้วยอย่างยิ่ง",
              "high_score": "10 - เห็นด้วยอย่างยิ่ง"
            }
          },
          "4": {
            "previous_button_text": "ก่อนหน้า",
            "next_button_text": "ส่ง"
          }
        },
        "3": {
          "0": {
            "text": "เราขอขอบพระคุณท่านเป็นอย่างสูง สำหรับการแบ่งปันความคิดเห็นของท่านกับเรา"
          },
          "1": {
            "text": "เราหวังเป็นอย่างยิ่งว่าจะได้ดูแลคุณในโอกาสต่อไป"
          }
        }
      }
    }
  };
});