define('due-dashboard/models/limit-user-management-by-custom-role', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var RSVP = _ember['default'].RSVP;

  // import { computed } from '@ember/object';

  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;

  // const { RSVP } = Ember;

  exports['default'] = Model.extend({

    custom_role: belongsTo('account-custom-profile-right'),
    whitelisted_custom_role: belongsTo('account-custom-profile-right'),

    // displayedName: computed.alias('whitelisted_custom_role.name'),

    defineSavepoint: function defineSavepoint() {
      return RSVP.resolve(this);
    },
    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
    }

  });
});