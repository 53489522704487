define('due-dashboard/models/font', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    name: attr('string'),
    fallback: attr('string'),
    'font-raw-data': attr('string'),
    'font-content-type': attr('string'),
    created_at: attr('string'),
    font: attr('string')
  });
});