define('due-dashboard/components/black-tooltip', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['navbar-link'],
    classNamesBindings: ['hidden', 'data-content'],
    hidden: false,

    didInsertElement: function didInsertElement() {
      var text = this.get('text');
      $(this.$().parent()).tooltipster({
        theme: ['tooltipster-noir', 'tooltipster-noir-customized'],
        content: text.toString(),
        contentAsHTML: true,
        hideOnClick: true,
        position: 'right',
        animation: 'grow',
        delay: 150
      });
    },
    willDestroyElement: function willDestroyElement() {
      $(this.$().parent()).tooltipster('destroy');
    }
  });
});