define('due-dashboard/components/tags/add-tag', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    currentAccount: service(),
    tagManager: service(),
    tagObjectName: null,
    selectedTagColor: null,
    selectedTagLanguage: null,
    tagDueExclusive: false,
    feelingEnabled: false,
    selectedSurveys: [],
    tagSurveyLimited: false,
    tagExists: false,
    tagAssignability: 'standard',

    /** Private props */
    rootCauseColor: '#71899C',
    actionTakenColor: '#71899C',

    errorMsg: computed('tagObjectName', 'tagExists', function () {
      if (this.get('tagObjectName') && this.get('tagObjectName').includes('>')) return this.get('currentTab').error.name;
      if (this.get('tagExists')) {
        this.set('tagExists', false);
        return this.get('currentTab').error.duplicate;
      }
      return false;
    }),

    disabledSave: computed('tagObjectName', 'selectedTagColor', 'selectedTagLanguage', 'errorMsg', function () {
      if (!['root_cause', 'action_taken'].includes(this.get('kind'))) {
        return !this.get('tagObjectName') || !this.get('selectedTagColor') || !this.get('selectedTagLanguage') || this.get('errorMsg');
      }
      return !this.get('tagObjectName') || !this.get('selectedTagLanguage') || this.get('errorMsg');
    }),

    surveyFilterType: computed('tagSurveyLimited', {
      get: function get() {
        if (this.get('tagSurveyLimited')) {
          return 'only_selected';
        }
        return 'all';
      }, set: function set(_, value) {
        if (value === 'all') {
          this.set('selectedSurveys', []);
          this.set('tagSurveyLimited', false);
        }
        return value;
      }
    }),

    currentTab: computed('kind', function () {
      var i18n = this.get('i18n');
      var options = {
        'tag': {
          btn: i18n.t('settings.tags.add_tag_button'),
          title: i18n.t('settings.tags.add_tag_button'),
          inputName: i18n.t('settings.tags.tag_name'),
          assignability: { title: i18n.t('settings.tags.assignability.tag.title'), options: 'settings.tags.assignability.tag.' },
          color: i18n.t('settings.tags.tag_color'),
          error: {
            name: i18n.t('settings.tags.error.tag_name'),
            duplicate: i18n.t('settings.tags.error.tag_duplicated')
          }
        },
        'root_cause': {
          btn: i18n.t('settings.tags.add_root_cause'),
          title: i18n.t('settings.tags.add_root_cause'),
          inputName: i18n.t('settings.tags.root_cause_name'),
          assignability: { title: i18n.t('settings.tags.assignability.root_cause.title'), options: 'settings.tags.assignability.root_cause.' },
          color: i18n.t('settings.tags.root_cause_color'),
          error: {
            name: i18n.t('settings.tags.error.root_cause_name'),
            duplicate: i18n.t('settings.tags.error.root_cause_duplicate')
          }
        },
        'action_taken': {
          btn: i18n.t('settings.tags.add_action_taken'),
          title: i18n.t('settings.tags.add_action_taken'),
          inputName: i18n.t('settings.tags.action_taken_name'),
          assignability: { title: i18n.t('settings.tags.assignability.action_taken.title'), options: 'settings.tags.assignability.action_taken.' },
          color: i18n.t('settings.tags.action_taken_color'),
          error: {
            name: i18n.t('settings.tags.error.action_taken_name'),
            duplicate: i18n.t('settings.tags.error.action_taken_duplicate')
          }
        }
      };
      return options[this.get('kind')];
    }),

    closeModal: function closeModal() {
      this.set('tagObjectName', null);
      this.set('selectedTagColor', null);
      this.set('selectedTagLanguage', null);
      this.set('tagDueExclusive', false);
      this.set('feelingEnabled', false);
      this.set('tagSurveyLimited', false);
      this.set('selectedSurveys', []);
      this.set('tagAssignability', 'standard');
      this.set('modalIsOpened', false);
    },

    createTagRootCause: function createTagRootCause(name, color, language, surveys, surveyLimited, notAssignable, exclusive, feeling, kind) {
      var _this = this;

      this.get('store').createRecord('tag', {
        name: name,
        color: color,
        language: language,
        surveys: surveys,
        survey_limited: surveyLimited,
        not_assignable: notAssignable,
        due_exclusive: exclusive,
        feeling_enabled: feeling,
        kind: kind
      }).save().then(function (addedTag) {
        addedTag.set('displayName', name);
        addedTag.set('depth', 0);
        addedTag.set('subTags', []);
        _this.get('notifyTagCreation')(addedTag);
      });
    },

    actions: {
      openModal: function openModal() {
        this.set('selectedTagLanguage', this.get('currentAccount.content.company.language'));
        this.set('modalIsOpened', true);
      },

      closeModal: function closeModal() {
        this.closeModal();
      },

      setTagColor: function setTagColor(color) {
        this.set('selectedTagColor', color);
      },

      changeSurveyFilter: function changeSurveyFilter(value) {
        this.set('tagSurveyLimited', value === 'only_selected');
      },

      saveTag: function saveTag() {
        var _this2 = this;

        var tagName = this.get('tagObjectName');
        var kind = this.get('kind');
        var colors = {
          'tag': this.get('selectedTagColor'),
          'root_cause': this.get('rootCauseColor'),
          'action_taken': this.get('actionTakenColor')
        };
        this.get('tagManager').getAll({}, 'all').then(function (tags) {
          if (tags.find(function (tag) {
            return tag.get('name') === tagName;
          })) {
            _this2.set('tagExists', true);
          } else {
            _this2.createTagRootCause(tagName, colors[kind], _this2.get('selectedTagLanguage'), _this2.get('selectedSurveys'), _this2.get('tagSurveyLimited'), _this2.get('tagAssignability') === 'category', ['root_cause', 'action_taken'].includes(kind) ? false : _this2.get('tagDueExclusive'), ['root_cause', 'action_taken'].includes(kind) ? false : _this2.get('feelingEnabled'), kind);
            _this2.closeModal();
          }
        });
      },

      toggleTagAssignation: function toggleTagAssignation(option) {
        this.set('tagAssignability', option);
      },

      toggleDueExclusiveTag: function toggleDueExclusiveTag() {
        this.toggleProperty('tagDueExclusive');
      },

      toggleTagFeeling: function toggleTagFeeling() {
        this.toggleProperty('feelingEnabled');
      }
    }
  });
});