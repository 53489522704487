define('due-dashboard/components/scenario-click-rate-chart', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['scenario-click-rate-chart'],

    colors: {
      'cta': '#2fcc76',
      'snapchat': '#fffc00',
      'apple-store': '#398EE9',
      'blogger': '#F4943F',
      'facebook': '#3b5998',
      'fb-like': '#3b5998',
      'fb-share': '#3b5998',
      'google-play': '#a4c639',
      'google-plus': '#d34836',
      'instagram': '#d52869',
      'linkedin': '#007bb6',
      'pinterest': '#cb2027',
      'tumblr': '#32506d',
      'twitter': '#00aced',
      'tw-tweet': '#00aced',
      'tw-follow': '#00aced'
    },

    c3chart: computed({
      get: function get() {},
      set: function set(_, c3chart) {
        this.set('c3chartInstance', c3chart);
      }
    }),

    donutData: computed('columnsData', function () {
      var _this = this;

      return {
        columns: this.get('columnsData'),
        colors: this.get('colors'),
        order: null,
        sort: false,
        type: "donut",
        onmouseover: function onmouseover(d) {
          _this.send('focusData', d.id);
        },
        onmouseout: function onmouseout() {
          return _this.send('unfocusData');
        }
      };
    }),

    columnsData: computed('data', function () {
      var _this2 = this;

      var social_ratios = this.get('data.clicks_from');
      return Object.keys(social_ratios).map(function (key) {
        return [key, social_ratios[key], Math.round(social_ratios[key] / _this2.get('data.total_clicks') * 100)];
      });
    }),

    chunkedData: computed('columnsData', function () {
      var raw_array = this.get('columnsData').toArray();
      var chunks = [];
      while (raw_array.length) {
        chunks.push(raw_array.splice(0, 3));
      }
      return chunks;
    }),

    chartDonut: {
      expand: false,
      width: 9,
      label: {
        show: false
      }
    },

    actions: {
      focusData: function focusData(to_focus) {
        var _this3 = this;

        var $arcs = this.$('.c3-chart-arc.c3-target').toArray();
        $arcs.forEach(function ($elem) {
          if ($elem.classList.contains('c3-target-' + to_focus)) {
            $elem.classList.add('c3-focused');
            $elem.classList.remove('c3-defocused');
          } else {
            $elem.classList.remove('c3-focused');
            $elem.classList.add('c3-defocused');
          }
        });
        var $legends = this.$('.legend-wrapper').toArray();
        $legends.forEach(function ($elem) {
          if ($elem.classList.contains(to_focus)) {
            $elem.classList.add('focused');
            $elem.classList.remove('defocused');
          } else {
            $elem.classList.remove('focused');
            $elem.classList.add('defocused');
          }
          var $focused = _this3.$('.center-current-hovered').toArray()[0];
          $focused.classList.add(to_focus);
          $focused.style.backgroundColor = to_focus === 'fb-share' ? '#ffffff' : _this3.get('colors')[to_focus];
          $focused.style.border = to_focus === 'fb-share' ? "1px solid inset #3b5998" : "";
        });
      },

      unfocusData: function unfocusData() {
        var $arcs = this.$('.c3-chart-arc.c3-target').toArray();
        $arcs.forEach(function ($elem) {
          $elem.classList.remove('c3-focused');
          $elem.classList.remove('c3-defocused');
        });
        var $legends = this.$('.legend-wrapper').toArray();
        $legends.forEach(function ($elem) {
          $elem.classList.remove('focused');
          $elem.classList.remove('defocused');
        });
        var $focused = this.$('.center-current-hovered').toArray()[0];
        $focused.classList = ['center-current-hovered'];
        $focused.style.backgroundColor = '';
        $focused.style.border = "";
      }

    }

  });
});