define('due-dashboard/components/due-field-validation', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    errors: service(),

    classNames: ['due-field-validation'],
    type: 'text',

    error: computed('errors.updateHook', function () {
      this.get('updatedHook');
      this.set('true_display', true);
      var error = this.get('errors').getMessageByKey(this.get('error_key') || this.get('value'));
      var text = this.get('i18n').t('errors.' + error);
      return text.string ? text.string : error;
    }),

    modelValue: computed('model.{content,isLoaded}', 'value', {
      get: function get() {
        this.get('model.isLoaded');
        return this.get('model.' + this.get('value'));
      }, set: function set(_, value) {
        this.set('true_display', false);
        return this.set('model.' + this.get('value'), value);
      }
    }),

    fullClass: computed('class', 'error', 'true_display', function () {
      return (this.get('class') || '') + (this.get('true_display') ? this.get('error') ? ' error' : ' no-error' : this.get('error') ? ' min-error' : '');
    })

  });
});