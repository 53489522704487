define("due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/-title", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/-title.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "labeled-input-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "due-typo-h6");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["words.title"], [], ["loc", [null, [2, 28], [2, 47]]], 0, 0], ["inline", "component", ["v2/-editable-label"], ["value", ["subexpr", "@mut", [["get", "object.content.text", ["loc", [null, [4, 10], [4, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["updateContentField", "text"], [], ["loc", [null, [5, 13], [5, 49]]], 0, 0], "clickOutsideCallback", ["subexpr", "action", ["updateContentField", "text", -1], [], ["loc", [null, [6, 25], [6, 64]]], 0, 0], "placeholder", ["subexpr", "t", ["words.title"], [], ["loc", [null, [7, 16], [7, 33]]], 0, 0], "inputType", "textarea", "alwaysInput", true, "textAreaRows", 7], ["loc", [null, [3, 2], [11, 4]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});