define('due-dashboard/helpers/conca-t', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    i18n: _ember['default'].inject.service(),

    onLocalesInit: _ember['default'].observer('i18n.locales', 'i18n.locale', function () {
      this.recompute();
    }),

    compute: function compute(params) {
      return this.get('i18n').t(params.join(''));
    }

  });
});