define('due-dashboard/controllers/companies/overview', ['exports', 'ember', 'moment', 'due-dashboard/objects/number-animator'], function (exports, _ember, _moment, _dueDashboardObjectsNumberAnimator) {
  var Controller = /* inject */_ember['default'].Controller;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({

    companiesController: _ember['default'].inject.controller('companies'),
    filters: _ember['default'].inject.service(),
    currentAccount: _ember['default'].inject.service(),
    anim_note_step: 0,
    asCommented: computed.alias('model.stats.comments_history.filtered_comments'),
    dateRange: computed.alias('model.stats.date_range'),
    stats: computed.alias('model.stats'),
    graph: computed.alias('model.graph'),
    globalFilters: computed.oneWay('filters.queryFilters.filter'),
    company: computed.alias('currentAccount.content.company'),
    showAnswerDetails: computed.alias('currentAccount.content.company.show_answer_details'),

    init: function init() {
      var _this = this;

      this.set('currentAccount.newCompany', false);
      _ember['default'].run.later(function () {
        _this.set('anim_note_step', 1);
      }, 500);
      _ember['default'].run.later(function () {
        _this.set('anim_note_step', 2);
      }, 1000);
      this._super.apply(this, arguments);
      this.set('surveySizeAnimator', _dueDashboardObjectsNumberAnimator['default'].create({
        targetObject: this,
        targetPath: 'model.stats.survey_size'
      }));
      this.set('totalScoreAnimator', _dueDashboardObjectsNumberAnimator['default'].create({
        targetObject: this,
        targetPath: 'model.stats.satisfaction_score',
        decimalDigits: 1
      }));
    },

    satisfaction_subtitle: computed('i18n.locale', 'currentAccount.content.company.satisfaction_index_100', function () {
      if (this.get('currentAccount.content.company.satisfaction_index_100')) {
        return 'overview.satisfaction_subtitle_100';
      } else {
        return 'overview.satisfaction_subtitle_10';
      }
    }),

    gauge_tooltip: computed('i18n.locale', 'currentAccount.content.company.satisfaction_index_100', function () {
      var tootlip_text = undefined;
      if (this.get('currentAccount.content.company.satisfaction_index_100')) {
        tootlip_text = 'overview.gauge_tooltip_100';
      } else {
        tootlip_text = 'overview.gauge_tooltip_10';
      }
      return this.get('i18n').t(tootlip_text);
    }),

    languageChanged: observer('i18n.locale', function () {
      this.send('filtersUpdated');
    }),

    formattedStartDate: computed('model.stats.date_range.start_date', function () {
      var date = this.get('model.stats.date_range.start_date');
      return (0, _moment['default'])(date).format('LL');
    }),

    formattedEndDate: computed('model.stats.date_range.end_date', function () {
      var date = this.get('model.stats.date_range.end_date');
      return (0, _moment['default'])(date).format('LL');
    }),

    actions: {
      filtersUpdated: function filtersUpdated() {
        var filters = this.get('filters.queryFilters');
        this.set('globalFilters', filters.filter);
        return this.set('model.stats', this.store.queryRecord('stat', this.get('filters').get('queryFilters')));
        // let verbatimsParams = filters;
        // verbatimsParams.page = {
        //   number: 1,
        //   size: 5
        // };
        // return Ember.RSVP.hash({
        //   stats: this.store.queryRecord('stat', filters),
        //   lastVerbatims: (verbatimsParams['filter']['comment'] === 'without') ? [] : this.store.query('verbatim', verbatimsParams)
        // }).then(updatedData => {
        //   this.set('model.stats', updatedData.stats);
        //   this.set('model.lastVerbatims', updatedData.lastVerbatims);
        // });
      }
    }
  });
});