define('due-dashboard/controllers/companies/report', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({

    currentLanguage: computed.alias('currentAccount.content.language.tag'),

    reload: false,

    canView: computed('model.id', function () {
      var hasReportCustomRight = this.get('currentAccount.hasReportCustomRight'),
          reportRightsList = this.get('currentAccount.reportRightsList');

      return !hasReportCustomRight || reportRightsList.includes(this.get('report.id'));
    }),

    geoscopeName: computed('currentAccount.content', function () {
      if (this.get('currentAccount.content.profile') === 'admin' && !this.get('currentAccount.content.geoscope_name.length')) {
        return 'Worldwide';
      }
      return this.get('currentAccount.content.geoscope_name.length') > 0 ? this.get('currentAccount.content.geoscope_name') : '-';
    }),

    roleName: computed('currentAccount.content.account_custom_profile_right', function () {
      if (this.get('currentAccount.content.account_custom_profile_right.content')) {
        return this.get('currentAccount.content.account_custom_profile_right.name');
      } else {
        var profile = this.get('currentAccount.content.profile');
        return profile.charAt(0).toUpperCase() + profile.slice(1);
      }
    }),

    report: computed.alias('model'),

    actions: {
      toggleReload: function toggleReload() {
        this.toggleProperty('reload');
      },

      changeReportRoute: function changeReportRoute(route, params) {
        if (params) {
          this.transitionToRoute(route, params);
        } else {
          this.transitionToRoute(route);
        }
      },

      redirectToDashboard: function redirectToDashboard() {
        var sidebar = this.get('currentAccount.content.account_custom_profile_right.sidebar'),
            element = sidebar.get('elements').find(function (e) {
          if (e.type != 'title') {
            return e;
          }
        }),
            route = element.route,
            param = element.param;

        this.transitionToRoute(route, param);
      }
    }
  });
});