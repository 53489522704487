define("due-dashboard/templates/components/reports/-single-kpi", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-skeleton/due-text-skeleton", [], ["width", 150, "size", ["subexpr", "@mut", [["get", "small", ["loc", [null, [4, 58], [4, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [4, 75], [4, 82]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 6], [4, 84]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 6
              },
              "end": {
                "line": 8,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "title");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "title", ["loc", [null, [7, 28], [7, 37]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 9,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "title", ["loc", [null, [6, 12], [6, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 6], [8, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 6
            },
            "end": {
              "line": 14,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tio-info_outined");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "due-information-popup", [], ["title", ["subexpr", "@mut", [["get", "informationTitle", ["loc", [null, [13, 38], [13, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "@mut", [["get", "informationText", ["loc", [null, [13, 60], [13, 75]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 8], [13, 77]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "due-trend", [], ["isLoading", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [18, 26], [18, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "error", ["subexpr", "or", [["get", "missingTrend", ["loc", [null, [18, 46], [18, 58]]], 0, 0, 0, 0], ["get", "missingTotal", ["loc", [null, [18, 59], [18, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 42], [18, 72]]], 0, 0], "trend", ["subexpr", "@mut", [["get", "trend", ["loc", [null, [18, 79], [18, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "isEmpty", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [18, 93], [18, 100]]], 0, 0, 0, 0]], [], [], 0, 0], "colorsConfig", ["subexpr", "@mut", [["get", "trendConfig", ["loc", [null, [18, 114], [18, 125]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [18, 4], [18, 127]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "due-trend", [], ["isLoading", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [20, 26], [20, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "error", ["subexpr", "or", [["get", "missingVariation", ["loc", [null, [20, 46], [20, 62]]], 0, 0, 0, 0], ["get", "missingTotal", ["loc", [null, [20, 63], [20, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 42], [20, 76]]], 0, 0], "trend", ["subexpr", "@mut", [["get", "variation", ["loc", [null, [20, 83], [20, 92]]], 0, 0, 0, 0]], [], [], 0, 0], "isEmpty", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [20, 101], [20, 108]]], 0, 0, 0, 0]], [], [], 0, 0], "colorsConfig", ["subexpr", "@mut", [["get", "trendConfig", ["loc", [null, [20, 122], [20, 133]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 4], [20, 135]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 21,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "displayVariation", ["loc", [null, [19, 12], [19, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 2], [21, 2]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 6
              },
              "end": {
                "line": 28,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "loading-skeleton/due-number-skeleton", [], ["size", ["subexpr", "@mut", [["get", "big", ["loc", [null, [27, 52], [27, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [27, 67], [27, 74]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [27, 8], [27, 76]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 8
                },
                "end": {
                  "line": 31,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "placeholder-total");
              var el2 = dom.createTextNode("-");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 72
                  },
                  "end": {
                    "line": 32,
                    "column": 102
                  }
                },
                "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("%");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 10
                  },
                  "end": {
                    "line": 35,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "total-more");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(")");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["content", "totalMore", ["loc", [null, [34, 38], [34, 51]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 8
                },
                "end": {
                  "line": 36,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "total");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element1, 'style');
              morphs[1] = dom.createMorphAt(element1, 0, 0);
              morphs[2] = dom.createMorphAt(element1, 1, 1);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "style", ["concat", ["font-size: ", ["get", "fontSize", ["loc", [null, [32, 50], [32, 58]]], 0, 0, 0, 0], ";"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "total", ["loc", [null, [32, 63], [32, 72]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "totalType", ["loc", [null, [32, 82], [32, 91]]], 0, 0, 0, 0], "rate"], [], ["loc", [null, [32, 78], [32, 99]]], 0, 0]], [], 0, null, ["loc", [null, [32, 72], [32, 109]]]], ["block", "if", [["get", "displayTotalMore", ["loc", [null, [33, 16], [33, 32]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [33, 10], [35, 17]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 37,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "missingTotal", ["loc", [null, [29, 14], [29, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [29, 8], [36, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 2
            },
            "end": {
              "line": 39,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["total-container ", ["get", "totalType", ["loc", [null, [25, 34], [25, 43]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "or", [["get", "isEmpty", ["loc", [null, [26, 16], [26, 23]]], 0, 0, 0, 0], ["get", "isLoading", ["loc", [null, [26, 24], [26, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 12], [26, 34]]], 0, 0]], [], 0, 1, ["loc", [null, [26, 6], [37, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 6
              },
              "end": {
                "line": 44,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "loading-skeleton/due-number-skeleton", [], ["size", ["subexpr", "@mut", [["get", "small", ["loc", [null, [43, 52], [43, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [43, 69], [43, 76]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [43, 8], [43, 78]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 8
                },
                "end": {
                  "line": 47,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "graphs/due-mini-graph", [], ["placeholder", true, "depth", ["subexpr", "@mut", [["get", "depth", ["loc", [null, [46, 57], [46, 62]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [46, 10], [46, 64]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 8
                },
                "end": {
                  "line": 49,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "graphs/due-mini-graph", [], ["graph", ["subexpr", "@mut", [["get", "evolution", ["loc", [null, [48, 40], [48, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "depth", ["subexpr", "@mut", [["get", "depth", ["loc", [null, [48, 56], [48, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [48, 74], [48, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "trend", ["subexpr", "@mut", [["get", "trend", ["loc", [null, [48, 88], [48, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "colorsConfig", ["subexpr", "@mut", [["get", "graphConfig", ["loc", [null, [48, 107], [48, 118]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [48, 10], [48, 120]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 6
              },
              "end": {
                "line": 50,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "or", [["get", "missingEvolution", ["loc", [null, [45, 18], [45, 34]]], 0, 0, 0, 0], ["get", "missingTotal", ["loc", [null, [45, 35], [45, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 14], [45, 48]]], 0, 0]], [], 0, 1, ["loc", [null, [45, 8], [49, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 2
            },
            "end": {
              "line": 52,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["evolution-container ", ["subexpr", "if", [["get", "isLoading", ["loc", [null, [41, 41], [41, 50]]], 0, 0, 0, 0], "isLoading"], [], ["loc", [null, [41, 36], [41, 64]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "isLoading", ["loc", [null, [42, 12], [42, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [42, 6], [50, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 4
              },
              "end": {
                "line": 56,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "loading-skeleton/due-nps-line-skeleton", [], ["width", 150, "size", ["subexpr", "@mut", [["get", "small", ["loc", [null, [55, 62], [55, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [55, 79], [55, 86]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [55, 6], [55, 88]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 4
              },
              "end": {
                "line": 60,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "evolution-container distribution");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "breakdowns/due-distribution-breakdown", [], ["distribution", ["subexpr", "@mut", [["get", "distribution", ["loc", [null, [58, 61], [58, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "title", ["loc", [null, [58, 80], [58, 85]]], 0, 0, 0, 0]], [], [], 0, 0], "content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [58, 94], [58, 101]]], 0, 0, 0, 0]], [], [], 0, 0], "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [58, 118], [58, 133]]], 0, 0, 0, 0]], [], [], 0, 0], "tooltipContainer", ".bottom-line-container"], ["loc", [null, [58, 8], [58, 177]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 2
            },
            "end": {
              "line": 61,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["get", "isEmpty", ["loc", [null, [54, 14], [54, 21]]], 0, 0, 0, 0], ["get", "isLoading", ["loc", [null, [54, 22], [54, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [54, 10], [54, 32]]], 0, 0]], [], 0, 1, ["loc", [null, [54, 4], [60, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/reports/-single-kpi.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "top-line-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "title-container");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "informations-icon-container");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bottom-line-container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(fragment, [2]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element4, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element3, 3, 3);
        morphs[3] = dom.createMorphAt(element5, 1, 1);
        morphs[4] = dom.createMorphAt(element5, 2, 2);
        morphs[5] = dom.createMorphAt(element5, 3, 3);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "or", [["get", "isEmpty", ["loc", [null, [3, 14], [3, 21]]], 0, 0, 0, 0], ["get", "isLoading", ["loc", [null, [3, 22], [3, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 10], [3, 32]]], 0, 0]], [], 0, 1, ["loc", [null, [3, 4], [9, 11]]]], ["block", "if", [["subexpr", "or", [["get", "informationTitle", ["loc", [null, [11, 16], [11, 32]]], 0, 0, 0, 0], ["get", "informationText", ["loc", [null, [11, 33], [11, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 12], [11, 49]]], 0, 0]], [], 2, null, ["loc", [null, [11, 6], [14, 13]]]], ["block", "if", [["get", "displayTrend", ["loc", [null, [17, 8], [17, 20]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [17, 2], [21, 9]]]], ["block", "if", [["get", "displayTotal", ["loc", [null, [24, 8], [24, 20]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [24, 2], [39, 9]]]], ["block", "if", [["get", "displayEvolution", ["loc", [null, [40, 8], [40, 24]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [40, 2], [52, 9]]]], ["block", "if", [["get", "displayDistribution", ["loc", [null, [53, 8], [53, 27]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [53, 2], [61, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  })());
});