define('due-dashboard/components/tag-badge', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'li',
    classNames: ['tag-badge'],
    attributeBindings: ['style'],
    colorPanelLeft: 0,
    deleting: false,
    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    feelingColors: { positive: '#00B548', neutral: '#FAB005', negative: '#FA5252', allfeedback: '#71899C', nosentiment: '#71899C' },
    feelingIcons: { positive: 'thumb_up_outlined', neutral: 'thumb_left_outlined', negative: 'thumb_down_outlined', allfeedback: 'message_add_outlined', nosentiment: 'label_outlined' },

    rights: computed('from_due', 'canClose', 'canAutoClose', function () {
      return {
        displayAuto: this.get('from_due') ? "auto" : '',
        canBeClose: !this.get('readOnly') && (this.get('tag.due_exclusive') && this.get('canAutoClose') || !this.get('tag.due_exclusive') && this.get('canClose'))
      };
    }),

    feelingIcon: computed('feeling', function () {
      return this.get('feelingIcons')[this.get('feeling')];
    }),

    style: computed('tag.color', 'feeling', function () {
      var color = this.get('feelingColors')[this.get('feeling')] || this.get('tag.color') || this.get('color');
      if (color) {
        return 'background-color: ' + this.get('hexToRGBA')(color) + ';color: ' + color + ';border: 1px solid ' + color + ';';
      }
    }),

    showColorPanel: computed('tag.tempColor', function () {
      return this.get('tag.tempColor');
    }),

    name: computed('tag', 'tag.translations', 'currentLanguage', 'tag.name', function () {
      if (this.get('currentLanguage') && this.get('tag.translations')) {
        return this.get('tag.translations')[this.get('currentLanguage').toLowerCase()] ? this.get('tag.translations')[this.get('currentLanguage').toLowerCase()]['text'] : this.get('tag.name');
      }
      return this.get('tag.name');
    }),

    autoTagsRights: computed.alias('currentAccount.canUseAutoTags'),

    border: computed('tag', 'from_due', 'feeling', function () {
      var color = this.get('feelingColors')[this.get('feeling')] || this.get('tag.color') || this.get('color');
      return this.get('from_due') ? 'border: 1px solid ' + color + '80' : '';
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var pageWidth = _ember['default'].$('body > .ember-view').width(),
          spaceLeft = pageWidth - this.element.getBoundingClientRect().x;
      if (spaceLeft < 200) {
        this.set('colorPanelLeft', 180 - this.element.getBoundingClientRect().width);
      } else {
        this.set('colorPanelLeft', 0);
      }
    },

    hexToRGBA: function hexToRGBA(h) {
      var r = 0,
          g = 0,
          b = 0,
          a = 0.05;
      // 3 digits
      if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];
        // 6 digits
      } else if (h.length == 7) {
          r = "0x" + h[1] + h[2];
          g = "0x" + h[3] + h[4];
          b = "0x" + h[5] + h[6];
        }
      return "rgb(" + +r + "," + +g + "," + +b + "," + a + ")";
    },

    actions: {
      removeTag: function removeTag(e) {
        e.stopPropagation();
        this.set('deleting', true);
        this.get('removeTagAction')(this.get('tag'), this.get('feeling'));
      },

      selectColorAction: function selectColorAction(color) {
        this.store.findRecord('tag', this.get('tag.id'), { reload: true }).then(function (tag) {
          tag.color = color;
          tag.save();
        });
        this.set('tag.tempColor', false);
        this.set('tag.color', color);
      }
    }
  });
});