define("due-dashboard/templates/components/digests/parts/kpi-list-part", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/digests/parts/kpi-list-part.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "digests/parts/kpi-list-item-part", [], ["kpiItem", ["subexpr", "@mut", [["get", "kpiItem", ["loc", [null, [5, 12], [5, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "removeKpiItem", ["subexpr", "action", ["removeKpiItem"], [], ["loc", [null, [6, 18], [6, 42]]], 0, 0], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [7, 10], [7, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "kpis", ["subexpr", "@mut", [["get", "kpis", ["loc", [null, [8, 9], [8, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "currentTag", ["subexpr", "@mut", [["get", "currentTag", ["loc", [null, [9, 15], [9, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "compId", ["subexpr", "@mut", [["get", "compId", ["loc", [null, [10, 11], [10, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "period", ["subexpr", "@mut", [["get", "period", ["loc", [null, [11, 11], [11, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "translations", ["subexpr", "@mut", [["get", "translations", ["loc", [null, [12, 17], [12, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [13, 20], [13, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 2], [14, 4]]], 0, 0]],
          locals: ["kpiItem", "index"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/parts/kpi-list-part.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h6");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["digest.component.custom_columns"], [], ["loc", [null, [2, 6], [2, 45]]], 0, 0], ["block", "each", [["get", "kpiList", ["loc", [null, [3, 10], [3, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [15, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/digests/parts/kpi-list-part.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content-child");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "kpiList", ["loc", [null, [1, 6], [1, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [16, 7]]]], ["inline", "due-btn", [], ["action", ["subexpr", "action", ["addKpi"], [], ["loc", [null, [18, 19], [18, 36]]], 0, 0], "icon", "tio-add", "textKey", "digest.component.new_column_kpi", "intent", "secondary", "size", ["subexpr", "if", [["get", "text", ["loc", [null, [18, 122], [18, 126]]], 0, 0, 0, 0], "medium", "small"], [], ["loc", [null, [18, 118], [18, 144]]], 0, 0]], ["loc", [null, [18, 2], [18, 146]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});