define('due-dashboard/components/reports/popups/tags-list', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',

    sortOptions: computed(function () {
      return [{ key: 'most_recent', text: this.get('i18n').t('components.reports.array.sorts.most_recent') }, { key: 'lowest_rated', text: this.get('i18n').t('components.reports.array.sorts.lowest_rated') }, { key: 'top_rated', text: this.get('i18n').t('components.reports.array.sorts.top_rated') }, { key: 'comment_size', text: this.get('i18n').t('components.reports.array.sorts.comment_size') }];
    }),

    sort: computed(function () {
      return { key: 'most_recent', text: this.get('i18n').t('components.reports.array.sorts.most_recent') };
    }),

    pageIdx: computed('questionIdx', function () {
      return this.get('questionIdx') || 0;
    }),

    page: computed('pages', 'pageIdx', function () {
      var pages = this.get('pages'),
          pageIdx = this.get('pageIdx');

      return pages[pageIdx];
    }),

    pages: computed('verbatims', function () {
      var verbatims = this.get('verbatims'),
          max_pages = 3,
          pages = [{ verbatims: [] }];
      var i = 0;
      var page_i = 0;

      if (!verbatims) return [];

      verbatims.forEach(function (verbatim) {
        if (!pages[page_i].title && !pages[page_i].surveyName) {
          var qs = ['Share your experience with us', 'Who does it?', 'What is missing?'],
              sn = 'Retail Post Purchase';
          pages[page_i].title = qs[page_i];
          pages[page_i].surveyName = sn;
        }

        if (i < 5 && page_i < max_pages) {
          i += 1;
          pages[page_i].verbatims.push(verbatim);
        } else if (page_i < max_pages - 1) {
          i = 0;
          page_i += 1;
          pages.push({ verbatims: [] });
        }
      });

      return pages;
    }),

    sentimentTexts: computed(function () {
      return [{ text: this.get('i18n').t('words.positive'), color: '#00b548' }, { text: this.get('i18n').t('words.neutral'), color: '#FAB005' }, { text: this.get('i18n').t('words.negative'), color: '#FA5252' }, { text: this.get('i18n').t('tag_analysis.due_histogram.feeling.without_feeling'), color: '#E1E8EE' }];
    }),

    shareValues: computed(function () {
      var content_data = this.get('pie_content'),
          values = [];
      var total = 0;

      content_data.forEach(function (data) {
        total += data.total;
      });

      content_data.forEach(function (data) {
        values.push(Math.round(data.value / total * 100));
      });

      return values;
    }),

    actions: {
      closePopup: function closePopup() {
        this.get('closePopupAction')();
      },

      sortVerbatims: function sortVerbatims(selected) {
        this.set('sort', selected);
      },

      previousPage: function previousPage() {
        var pageIdx = this.get('pageIdx');

        if (pageIdx - 1 < 0) {
          pageIdx = this.get('pages').length - 1;
        } else {
          pageIdx -= 1;
        }

        this.set('pageIdx', pageIdx);
      },

      nextPage: function nextPage() {
        var pageIdx = this.get('pageIdx');

        if (pageIdx + 1 > this.get('pages').length - 1) {
          pageIdx = 0;
        } else {
          pageIdx += 1;
        }

        this.set('questionIdx', pageIdx);
      }
    },

    pie_content: [{ total: 98, color: '#00b548' }, { total: 47, color: '#FAB005' }, { total: 34, color: '#FA5252' }, { total: 27, color: '#E1E8EE' }],

    pie_content1: [{ value: 50, color: '#00b548' }, { value: 5, color: '#FAB005' }, { value: 5, color: '#FA5252' }, { value: 5, color: '#E1E8EE' }]
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{report/-questions-list}} question list popup
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */