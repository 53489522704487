define('due-dashboard/components/digests/parts/digest-column-translation-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'td',
    currentTitle: computed('tag', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.title', value);
        return value;
      }
    }),

    currentSubject: computed('tag', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.email_subject');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.email_subject', value);
        return value;
      }
    })

  });
});