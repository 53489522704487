define('due-dashboard/controllers/companies/graphs/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  exports['default'] = _ember['default'].Controller.extend({
    filterService: service('filters'),
    currentAccount: service(),

    graphs: computed.alias('model.graphs'),
    favoriteGraphs: computed.alias('model.favoriteGraphs'),
    globalFilters: computed.oneWay('filterService.queryFilters.filter'),

    limit: 3,
    currentPage: 1,

    pagesCount: computed('model.graphs.meta', '_page_count', function () {
      var _this = this;

      Promise.resolve(this.get('model.graphs')).then(function (graphs) {
        _this.set('_page_count', graphs.meta['page_count']);
      });
      return this.get('_page_count') || 1;
    }),

    pageObserver: observer('currentPage', function () {
      this.launchQuery();
    }),

    launchQuery: function launchQuery() {
      this.set('graphs', this.store.query('graph', { reload: true, sort: '-updated_at', page: { number: this.get('currentPage'), size: this.get('limit') } }));
    },

    actions: {
      filtersUpdated: function filtersUpdated() {
        var filters = this.get('filterService.queryFilters');
        this.set('globalFilters', filters.filter);
      }
    }
  });
});