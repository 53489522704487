define('due-dashboard/models/language', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    tag: _emberData['default'].attr('string'),
    is_available_company_side: _emberData['default'].attr('boolean'),

    lower_tag: _ember['default'].computed('tag', function () {
      return this.get('tag').toLowerCase();
    })
  });
});