define("due-dashboard/templates/components/icons/thumb-up-outlined", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/icons/thumb-up-outlined.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "width", "16");
        dom.setAttribute(el1, "height", "16");
        dom.setAttribute(el1, "viewBox", "0 0 16 16");
        dom.setAttribute(el1, "fill", "none");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M14.2002 6.72068C14.0128 6.49578 13.7784 6.31474 13.5135 6.19033C13.2485 6.06592 12.9595 6.00118 12.6668 6.00068H9.62683L10.0002 5.04735C10.1555 4.62994 10.2072 4.18108 10.1509 3.73929C10.0946 3.2975 9.93196 2.87596 9.67695 2.51083C9.42195 2.1457 9.08218 1.84788 8.68679 1.64291C8.2914 1.43794 7.85219 1.33194 7.40683 1.33401C7.27859 1.33428 7.15315 1.37153 7.04554 1.4413C6.93794 1.51106 6.85274 1.61038 6.80016 1.72735L4.90016 6.00068H3.3335C2.80306 6.00068 2.29436 6.21139 1.91928 6.58647C1.54421 6.96154 1.3335 7.47025 1.3335 8.00068V12.6673C1.3335 13.1978 1.54421 13.7065 1.91928 14.0816C2.29436 14.4566 2.80306 14.6673 3.3335 14.6673H11.8202C12.288 14.6672 12.741 14.503 13.1004 14.2034C13.4597 13.9037 13.7026 13.4876 13.7868 13.0273L14.6335 8.36068C14.6859 8.07227 14.6742 7.77587 14.5993 7.49246C14.5244 7.20906 14.3882 6.94558 14.2002 6.72068ZM4.66683 13.334H3.3335C3.15669 13.334 2.98712 13.2638 2.86209 13.1388C2.73707 13.0137 2.66683 12.8442 2.66683 12.6673V8.00068C2.66683 7.82387 2.73707 7.6543 2.86209 7.52928C2.98712 7.40425 3.15669 7.33401 3.3335 7.33401H4.66683V13.334ZM13.3335 8.12068L12.4868 12.7873C12.4584 12.9427 12.3758 13.0828 12.2538 13.1829C12.1317 13.2831 11.978 13.3366 11.8202 13.334H6.00016V6.80735L7.8135 2.72735C8.00015 2.78176 8.1735 2.87429 8.3226 2.99907C8.4717 3.12385 8.59331 3.27819 8.67976 3.45234C8.76621 3.62649 8.81562 3.81667 8.82487 4.01088C8.83412 4.20509 8.803 4.3991 8.7335 4.58068L8.38016 5.53401C8.30487 5.73552 8.27946 5.95225 8.30608 6.1657C8.3327 6.37916 8.41058 6.583 8.53305 6.75984C8.65553 6.93668 8.81897 7.08126 9.00943 7.18124C9.1999 7.28122 9.41172 7.33364 9.62683 7.33401H12.6668C12.7648 7.33385 12.8615 7.35528 12.9503 7.39676C13.039 7.43824 13.1175 7.49876 13.1802 7.57401C13.2444 7.64823 13.2914 7.73571 13.3179 7.83021C13.3444 7.92471 13.3498 8.02389 13.3335 8.12068Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(1);
        morphs[0] = dom.createAttrMorph(element0, 'fill');
        return morphs;
      },
      statements: [["attribute", "fill", ["concat", [["get", "hexColor", ["loc", [null, [2, 1854], [2, 1862]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});