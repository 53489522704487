define('due-dashboard/components/stripe-checkout', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  /* global StripeCheckout */

  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    click: function click() {
      var handler = StripeCheckout.configure({
        key: _dueDashboardConfigEnvironment['default'].stripe.key
      });
      var that = this;
      handler.open({
        name: 'DIDUENJOY ' + this.get('plan.name').toUpperCase(),
        description: this.get('i18n').t('subscription.entrepreneur.desc').toString(),
        currency: "eur",
        amount: this.get('plan.amount'),
        email: this.get('email'),
        allowRememberMe: false,
        token: function token(_token) {
          that.sendAction('action', _token.id, that.get('plan'));
        }
      });
    }
  });
});