define('due-dashboard/components/reports/-report-switcher', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-switcher'],
    classNameBindings: ['layout'],

    computedReports: computed('content.component.config', function () {
      var _this = this;

      var reports = this.get('content.component.config.switcher'),
          translations = this.get('content.component.config.translations');

      return reports.map(function (report) {
        return { params: report, text: translations[_this.get('currentLanguage')][report] };
      });
    }),

    reportName: computed('selectedReport', function () {
      return this.get('selectedReport.text');
    }),

    init: function init() {
      this._super();
      var translations = this.get('content.component.config.translations');
      var report_id = this.get('report.permalink') || this.get('report.id');
      this.set('selectedReport', { params: report_id, text: translations[this.get('currentLanguage')][report_id] });
    },

    actions: {
      selectReport: function selectReport(report) {
        this.set('selectedReport', report);
        this.get('redirectAction')('companies.report', report.params);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{report-switcher}} component to switch reports within page
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */