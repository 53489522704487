define('due-dashboard/components/subscription-plan', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    unique: false,
    selected: false,

    classNames: ['formula'],
    classNameBindings: ['plan.planClass', 'active', 'selected'],

    active: computed('plan.id', 'company.plan.id', function () {
      return this.get('plan.id') === this.get('company.plan.id');
    })
  });
});