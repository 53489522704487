define('due-dashboard/components/due-table/due-table-row-dynamic', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({
    classNames: ['table-line'],
    classNameBindings: ['displaySublines:opened-line'],

    lineHoverClass: 'line-container-hover',
    noTransitionClass: 'no-transition',
    disableHover: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._addClass(this.get('lineElems.id'), this.noTransitionClass);
    },

    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);
      run.later(function () {
        return _this._removeClass(_this.get('lineElems.id'), _this.noTransitionClass);
      }, 200);
    },

    displaySublines: computed('showLineComputed', function () {
      var id = this.get('lineElems.id');
      var showLineComputed = this.get('showLineComputed.lines');
      return id && showLineComputed && showLineComputed[id];
    }),

    chevronStateColor: computed('displaySublines', function () {
      var defaultColor = '#1B3448';
      if (this.get('elementDepth') == 0) {
        defaultColor = this.get('displaySublines') ? '#FFFFFF' : '#71899C';
      }
      return defaultColor;
    }),

    elementDepth: computed(function () {
      return this.get('lineElems.depth');
    }),

    filtered: computed(function () {
      return this.get('lineElems._metadata.filtered');
    }),

    lineId: computed(function () {
      return this.get('lineElems.id');
    }),

    valuesHierarchy: computed(function () {
      return this.get('lineElems._metadata.valuesHierarchy');
    }),

    indent: computed(function () {
      return Array.from({ length: this.get('elementDepth') }, function (_, i) {
        return i + 1;
      });
    }),

    nestedElement: computed(function () {
      return this.get('elementDepth') > 0;
    }),

    lastLevel: computed(function () {
      return this.get('maxDepth') == this.get('elementDepth') + 1;
    }),

    disableNav: computed(function () {
      return this.get('lineElems._metadata.navElement');
    }),

    _addClass: function _addClass(elementId, classToAdd) {
      var _arr = ['-left', '-right'];

      for (var _i = 0; _i < _arr.length; _i++) {
        var side = _arr[_i];
        var element = document.getElementById(elementId + side);
        if (element == undefined) {
          return;
        }
        element.classList.add(classToAdd);
      }
    },

    _removeClass: function _removeClass(elementId, classToRemove) {
      var _arr2 = ['-left', '-right'];

      for (var _i2 = 0; _i2 < _arr2.length; _i2++) {
        var side = _arr2[_i2];
        var element = document.getElementById(elementId + side);
        if (element == undefined) {
          return;
        }
        element.classList.remove(classToRemove);
      }
    },

    actions: {

      highlightLineFn: function highlightLineFn(elementId) {
        if (this.get('disableHover') || elementId == undefined) {
          return;
        }
        this._addClass(elementId, this.lineHoverClass);
      },

      lowlightLineFn: function lowlightLineFn(elementId) {
        if (this.get('disableHover') || elementId == undefined) {
          return;
        }
        this._removeClass(elementId, this.lineHoverClass);
      },

      showMore: function showMore(values_hierarchy) {
        var showMoreFn = this.get('showMore');
        if (showMoreFn) {
          showMoreFn(values_hierarchy);
        }
      },

      collapseLineTrigger: function collapseLineTrigger(id, values_hierarchy) {
        var collapseLineFn = this.get('collapseLine');
        if (collapseLineFn) {
          collapseLineFn(id, values_hierarchy);
        }
      }
    }
  });
});