define("due-dashboard/helpers/default-survey-v2-style", ["exports", "ember"], function (exports, _ember) {
  exports.defaultSurveyV2Style = defaultSurveyV2Style;

  function defaultSurveyV2Style() {
    return defaultStyle;
  }

  exports["default"] = _ember["default"].Helper.helper(defaultSurveyV2Style);

  var defaultStyle = {
    "no_logo": false,
    "accessibility_focus_border_color": "#0aa9ec",
    "text_color": "#71899C",
    "label_color": "#1B3448",
    "title_color": "#1B3448",
    "legend_color": "#9EB0BE",
    "card_alignment": "left",
    "next_btn_color": "#ffffff",
    "text_alignment": "left",
    "mobile_text_alignment": "center",
    "text_font_size": "16px",
    "label_font_size": "18px",
    "next_btn_shadow": "0px 0px 0px 0px #c6c6c6",
    "title_font_size": "24px",
    "btn_border_width": "0.5px",
    "card_full_height": true,
    "legend_font_size": "14px",
    "rating_btn_color": "#fff",
    "text_font_family": "Inter, sans-serif",
    "text_font_weight": "400",
    "text_line_height": "22px",
    "btn_border_radius": "10px",
    "footer_logo_color": "black",
    "footer_text_color": "#1B3448",
    "label_font_family": "Inter, sans-serif",
    "label_font_weight": "600",
    "label_line_height": "22px",
    "next_btn_bg_color": "#1B3448",
    "rating_btn_shadow": "none",
    "title_font_family": "Inter, sans-serif",
    "title_font_weight": "700",
    "title_line_height": "28px",
    "card_border_radius": "0px",
    "default_text_color": "#1B3448",
    "legend_font_family": "Inter, sans-serif",
    "legend_font_weight": "400",
    "legend_line_height": null,
    "next_btn_font_size": "16px",
    "previous_btn_color": "#1B3448",
    "multi_select_shadow": "0px 0px 0px 0px #FFFFFF",
    "previous_btn_shadow": "0px 0px 0px 0px #FFFFFF",
    "rating_btn_bg_color": "transparent",
    "single_select_color": "#1B3448",
    "text_letter_spacing": "0.4px",
    "label_letter_spacing": "0.4px",
    "next_btn_font_family": "Inter, sans-serif",
    "next_btn_font_weight": "700",
    "next_btn_line_height": "20px",
    "rating_btn_font_size": "15px",
    "single_select_shadow": "0px 0px 0px 0px #FFFFFF",
    "title_letter_spacing": "0.4px",
    "legend_letter_spacing": null,
    "long_text_input_color": "#1B3448",
    "multi_select_bg_color": "#ffffff",
    "next_btn_border_color": "#1B3448",
    "next_btn_border_width": "1px",
    "previous_btn_bg_color": "#ffffff",
    "default_text_font_size": "16px",
    "long_text_input_shadow": "0px 0px 0px 0px #FFFFFF",
    "mobile_card_background": "#fff",
    "next_btn_border_radius": "3px",
    "next_btn_focused_color": "#fff",
    "next_btn_hovered_color": "#fff",
    "previous_btn_font_size": "14px",
    "rating_btn_font_family": "Inter, sans-serif",
    "short_text_input_color": "#1B3448",
    "single_select_bg_color": "#ffffff",
    "tablet_card_background": "#FFFFFF",
    "desktop_background_size": "0",
    "desktop_card_background": "#FFFFFF",
    "next_btn_disabled_color": "#71899C",
    "next_btn_focused_shadow": "0px 0px 0px 0px #fff",
    "next_btn_hovered_shadow": "0px 0px 0px 0px #c6c6c6",
    "next_btn_letter_spacing": "0px",
    "rating_btn_border_color": "#fff",
    "short_text_input_shadow": "0px 0px 0px 0px #FFFFFF",
    "single_select_font_size": "16px",
    "components_border_radius": "0px",
    "default_text_font_family": "Inter, sans-serif",
    "default_text_line_height": "18px",
    "desktop_background_color": "#E1E8EE",
    "long_text_input_bg_color": "#ffffff",
    "multi_select_items_color": "#1B3448",
    "multiselect_option_color": "#000",
    "next_btn_disabled_shadow": "0px 0px 0px 0px #FFFFFF",
    "previous_btn_font_family": "Inter, sans-serif",
    "previous_btn_font_weight": "700",
    "previous_btn_line_height": "25px",
    "rating_btn_border_radius": "2px",
    "rating_btn_focused_color": "#000",
    "rating_btn_hovered_color": "#000",
    "desktop_background_repeat": "",
    "long_text_input_font_size": "14px",
    "multi_select_border_color": "#1B3448",
    "multi_select_border_width": "1px",
    "multi_select_items_shadow": "0px 0px 0px 0px #FFFFFF",
    "multiselect_option_shadow": "0px 0px 6px -5px #959595",
    "next_btn_focused_bg_color": "#0887BD",
    "next_btn_hovered_bg_color": "#0AA9EC",
    "previous_btn_border_color": "#1B3448",
    "previous_btn_border_width": "1px",
    "rating_btn_disabled_color": "#fff",
    "rating_btn_focused_shadow": "none",
    "rating_btn_hovered_shadow": "none",
    "rating_btn_letter_spacing": "0.4px",
    "rating_btn_selected_color": "#000",
    "short_text_input_bg_color": "#ffffff",
    "single_select_font_family": "Inter, sans-serif",
    "single_select_line_height": "32px",
    "multi_select_border_radius": "4px",
    "next_btn_disabled_bg_color": "#F6F8FB",
    "previous_btn_border_radius": "3px",
    "previous_btn_focused_color": "#1B3448",
    "previous_btn_hovered_color": "#1B3448",
    "rating_btn_disabled_shadow": "none",
    "rating_btn_selected_shadow": "none",
    "redirect_btn_primary_color": "#ffffff",
    "short_text_input_font_size": "16px",
    "single_select_border_color": "#1B3448",
    "single_select_border_width": "1px",
    "default_text_letter_spacing": "0.4px",
    "desktop_background_position": "",
    "long_text_input_font_family": "Inter, sans-serif",
    "long_text_input_font_weight": "400",
    "long_text_input_line_height": "23px",
    "multi_select_dropdown_color": "#1B3448",
    "multi_select_items_bg_color": "#ffffff",
    "multiselect_option_bg_color": "#fff",
    "previous_btn_disabled_color": "#CDD7DF",
    "previous_btn_focused_shadow": "none",
    "previous_btn_hovered_shadow": "none",
    "previous_btn_letter_spacing": "0.4px",
    "rating_btn_focused_bg_color": "#fff",
    "rating_btn_hovered_bg_color": "#fff",
    "redirect_btn_primary_shadow": "0px 0px 0px 0px #FFFFFF",
    "single_select_border_radius": "4px",
    "hide_all_questions_numbering": null,
    "hide_open_question_numbering": null,
    "long_text_input_border_color": "#E1E8EE",
    "long_text_input_border_width": "1px",
    "multi_select_dropdown_shadow": "0px 0px 0px 0px #FFFFFF",
    "multi_select_items_font_size": "16px",
    "multiselect_option_font_size": "16px",
    "next_btn_focused_font_family": "Inter, sans-serif",
    "next_btn_hovered_font_family": "Inter, sans-serif",
    "previous_btn_disabled_shadow": "none",
    "previous_btn_text_decoration": "none",
    "rating_btn_disabled_bg_color": "#ccc",
    "rating_btn_selected_bg_color": "#fff",
    "redirect_btn_secondary_color": "#000000",
    "short_text_input_font_family": "Inter, sans-serif",
    "short_text_input_line_height": "32px",
    "single_select_dropdown_color": "#1B3448",
    "single_select_letter_spacing": "0.4px",
    "desktop_background_attachment": "none",
    "desktop_background_image": "none",
    "desktop_card_background_color": "#FFFFFF",
    "long_text_input_border_radius": "6px",
    "next_btn_disabled_font_family": "Inter, sans-serif",
    "next_btn_focused_border_color": "#0887BD",
    "next_btn_hovered_border_color": "#0AA9EC",
    "previous_btn_focused_bg_color": "#E1E8EE",
    "previous_btn_hovered_bg_color": "#F6F8FB",
    "redirect_btn_primary_bg_color": "#1B3448",
    "redirect_btn_secondary_shadow": "0px 0px 0px 0px #FFFFFF",
    "short_text_input_border_color": "#1B3448",
    "short_text_input_border_width": "1px",
    "single_select_dropdown_shadow": "0px 0px 0px 0px #FFFFFF",
    "long_text_input_letter_spacing": "0.4px",
    "multi_select_dropdown_bg_color": "#ffffff",
    "multi_select_items_font_family": "Inter, sans-serif",
    "multi_select_items_line_height": "32px",
    "multi_select_placeholder_color": "#CDD7DF",
    "multiselect_option_font_family": "Inter, sans-serif",
    "multiselect_option_line_height": "22px",
    "next_btn_disabled_border_color": "#CDD7DF",
    "previous_btn_disabled_bg_color": "#F6F8FB",
    "redirect_btn_primary_font_size": "16px",
    "short_text_input_border_radius": "4px",
    "multi_select_items_border_color": "#1B3448",
    "multi_select_items_border_width": "1px",
    "multiselect_option_border_color": "#ccc",
    "rating_btn_focused_border_color": "#fff",
    "rating_btn_hovered_border_color": "#fff",
    "redirect_btn_secondary_bg_color": "#ffffff",
    "short_text_input_letter_spacing": "0.4px",
    "single_select_dropdown_bg_color": "#ffffff",
    "single_select_placeholder_color": "#CDD7DF",
    "rating_btn_disabled_border_color": "#ccc",
    "rating_btn_selected_border_color": "#fff",
    "redirect_btn_primary_font_family": "Inter, sans-serif",
    "redirect_btn_primary_font_weight": "700",
    "redirect_btn_primary_line_height": "32px",
    "redirect_btn_secondary_font_size": "16px",
    "single_select_dropdown_font_size": "16px",
    "footer_language_selector_bg_color": "#ffffff",
    "long_text_input_placeholder_color": "#9EB0BE",
    "multi_select_dropdown_font_family": "Inter, sans-serif",
    "multi_select_dropdown_line_height": "32px",
    "multi_select_items_letter_spacing": "0.4px",
    "multiselect_option_letter_spacing": null,
    "previous_btn_focused_border_color": "#1B3448",
    "previous_btn_hovered_border_color": "#1B3448",
    "redirect_btn_primary_border_color": "#1B3448",
    "redirect_btn_primary_border_width": "1px",
    "multi_select_dropdown_border_color": "#1B3448",
    "multi_select_dropdown_border_width": "1px",
    "multi_select_placeholder_font_size": "16px",
    "previous_btn_disabled_border_color": "#CDD7DF",
    "redirect_btn_primary_border_radius": "4px",
    "redirect_btn_primary_focused_color": "#1B3448",
    "redirect_btn_primary_hovered_color": "#1B3448",
    "redirect_btn_secondary_font_family": "Inter, sans-serif",
    "redirect_btn_secondary_line_height": "32px",
    "short_text_input_placeholder_color": "#CDD7DF",
    "single_select_dropdown_font_family": "Inter, sans-serif",
    "single_select_dropdown_line_height": "32px",
    "redirect_btn_primary_letter_spacing": "0.4px",
    "redirect_btn_secondary_border_color": "#000000",
    "redirect_btn_secondary_border_width": "1px",
    "single_select_dropdown_border_color": "#1B3448",
    "single_select_dropdown_border_width": "1px",
    "single_select_placeholder_font_size": "16px",
    "survey_has_alternative_rating_scale": false,
    "tablet_card_display_card_background": true,
    "desktop_card_display_card_background": true,
    "multi_select_dropdown_letter_spacing": "0.4px",
    "multi_select_placeholder_font_family": "Inter, sans-serif",
    "multi_select_placeholder_line_height": "32px",
    "previous_btn_hovered_text_decoration": "none",
    "redirect_btn_secondary_border_radius": "4px",
    "redirect_btn_secondary_focused_color": "#ffffff",
    "redirect_btn_secondary_hovered_color": "#ffffff",
    "single_select_dropdown_border_radius": "4px",
    "long_text_input_placeholder_font_size": "14px",
    "redirect_btn_primary_focused_bg_color": "#ffffff",
    "redirect_btn_primary_hovered_bg_color": "#fff",
    "single_select_dropdown_letter_spacing": "0.4px",
    "single_select_placeholder_font_family": "Inter, sans-serif",
    "single_select_placeholder_font_weight": "normal",
    "single_select_placeholder_line_height": "32px",
    "short_text_input_placeholder_font_size": "16px",
    "long_text_input_placeholder_font_family": "Inter, sans-serif",
    "long_text_input_placeholder_font_weight": "400",
    "long_text_input_placeholder_line_height": "32px",
    "multi_select_placeholder_letter_spacing": "0.4px",
    "redirect_btn_secondary_focused_bg_color": "#1B3448",
    "redirect_btn_secondary_hovered_bg_color": "#1B3448",
    "redirect_btn_primary_hovered_font_family": "Inter, sans-serif",
    "short_text_input_placeholder_font_family": "Inter, sans-serif",
    "short_text_input_placeholder_line_height": "32px",
    "single_select_placeholder_letter_spacing": "0.4px",
    "redirect_btn_primary_focused_border_color": "#1B3448",
    "redirect_btn_primary_hovered_border_color": "#1B3448",
    "long_text_input_placeholder_letter_spacing": "0.4px",
    "redirect_btn_secondary_focused_font_family": "Inter, sans-serif",
    "redirect_btn_secondary_hovered_font_family": "Inter, sans-serif",
    "redirect_btn_secondary_focused_border_color": "#ffffff",
    "redirect_btn_secondary_hovered_border_color": "#ffffff",
    "short_text_input_placeholder_letter_spacing": "0.4px"
  };
});