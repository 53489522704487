define("due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/title-design", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/elements/title-design.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "element-header-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "design-element-title-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "due-typo-h5 bold");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "t", [["subexpr", "concat", ["words.", ["get", "currentElement.name", ["loc", [null, [3, 56], [3, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 39], [3, 76]]], 0, 0]], [], ["loc", [null, [3, 35], [3, 78]]], 0, 0], ["inline", "component", ["v2/libraries/surveys/navbar/design/elements/components/-text"], ["state", ["subexpr", "@mut", [["get", "DEFAULT_STATE", ["loc", [null, [7, 8], [7, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [8, 7], [8, 11]]], 0, 0, 0, 0]], [], [], 0, 0], "styleName", ["subexpr", "@mut", [["get", "styleName", ["loc", [null, [9, 12], [9, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "designOptions", ["subexpr", "@mut", [["get", "currentDesignOptions", ["loc", [null, [10, 16], [10, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "updateDesignOptions", ["subexpr", "@mut", [["get", "updateDesignOptions", ["loc", [null, [11, 22], [11, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "customFonts", ["subexpr", "@mut", [["get", "customFonts", ["loc", [null, [12, 14], [12, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "displayTextDecoration", false], ["loc", [null, [6, 0], [14, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});