define('due-dashboard/components/messaging/status-filter-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',

    isSelected: computed('selected', 'status', function () {
      return this.get('selected') == this.get('status');
    }),

    actions: {
      selectOption: function selectOption(option) {
        this.get('selectOption')(option);
      }
    }
  });
});