define('due-dashboard/initializers/fragment-parent', ['exports', 'ember-data-model-fragments', 'ember'], function (exports, _emberDataModelFragments, _ember) {
  exports['default'] = {
    name: 'fragment-parent',
    initialize: function initialize() {
      _emberDataModelFragments['default'].Fragment.reopen({
        parent: _ember['default'].computed(function () {
          var owner = this._internalModel._owner;
          return this.store.peekRecord(_ember['default'].get(owner, 'modelName'), _ember['default'].get(owner, 'id'));
        })
      });
    }
  };
});