define('due-dashboard/transforms/template-content', ['exports', 'ember-data-model-fragments', 'ember'], function (exports, _emberDataModelFragments, _ember) {
  /* global _ */

  exports['default'] = _emberDataModelFragments['default'].FragmentArrayTransform.extend({

    EmberStore: _ember['default'].inject.service('store'),

    serialize: function serialize(data) {
      var res = {};
      data.forEach(function (data) {
        return res[data.record.get('language.tag').toLowerCase()] = data.record.get('content');
      });
      return res;
    },

    deserialize: function deserialize(data) {
      this.store = this.get('EmberStore');
      this.type = 'template-content';

      var _data = [],
          languages = this.store.peekAll('language');
      _.forEach(data, function (value, key) {
        data[key.underscore()] = value;
        if (key.underscore() !== key) {
          delete data[key];
        }
      });

      Object.keys(data).forEach(function (tag) {
        _data.push({
          language: languages.findBy('tag', tag.toString().toUpperCase()),
          content: data[tag]
        });
      });

      return this._super(_data);
    }

  });
});