define("due-dashboard/templates/components/digests/feedback-counter", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/components/digests/feedback-counter.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content-child");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h6");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content-child");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["digest.component.customize_counter"], [], ["loc", [null, [2, 6], [2, 48]]], 0, 0], ["inline", "digests/parts/color-picker-part", [], ["defaultMax", 999999, "options", ["subexpr", "@mut", [["get", "comp.options", ["loc", [null, [3, 62], [3, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "current_period_colors", "text", "digest.color_label", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [3, 147], [3, 162]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 2], [3, 164]]], 0, 0], ["inline", "digests/parts/periods-part", [], ["defaultMax", 999999, "analysingYear", ["subexpr", "@mut", [["get", "analysingYear", ["loc", [null, [6, 63], [6, 76]]], 0, 0, 0, 0]], [], [], 0, 0], "period", ["subexpr", "@mut", [["get", "period", ["loc", [null, [6, 84], [6, 90]]], 0, 0, 0, 0]], [], [], 0, 0], "comp", ["subexpr", "@mut", [["get", "comp", ["loc", [null, [6, 96], [6, 100]]], 0, 0, 0, 0]], [], [], 0, 0], "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [6, 117], [6, 132]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 2], [6, 134]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});