define('due-dashboard/components/reports/-sub-title', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-sub-title-container'],
    classNameBindings: ['layout'],

    subTitle: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].sub_title;
    })
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{sub-title}} component displaying sub title
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */