define('due-dashboard/models/company-subscription-plan-billing', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    created_at: attr('date'),
    paid_at: attr('date'),
    full_billing_number: attr('string'),
    amount: attr('number'),
    object: attr('string'),
    taxes_rate: attr('number')
  });
});