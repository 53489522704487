define('due-dashboard/components/due-navbar-dropdown', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-navbar-dropdown'],
    fromSelf: false,

    show_class: computed('showDropDown', function () {
      this.set('fromSelf', false);
      if (this.get('showDropDown')) {
        return 'show-class';
      }
      return 'hide-class';
    }),

    actions: {
      signout: function signout() {
        this.get('invalidateSession')();
      },

      clickOutsideComponent: function clickOutsideComponent() {
        if (this.set('fromSelf')) {
          this.set('showDropDown', true);
        } else {
          this.set('showDropDown', false);
        }
      },

      clickInsideComponent: function clickInsideComponent() {
        this.set('fromSelf', true);
      },

      triggerNavbarDropDown: function triggerNavbarDropDown() {
        this.toggleProperty('showDropDown');
      }
    }
  });
});