define("due-dashboard/templates/components/v2/libraries/surveys/navbar/design/-settings", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/design/-settings.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "design-container padding-inside");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["inline", "component", [["subexpr", "concat", ["v2/libraries/surveys/navbar/design/elements/", ["get", "currentElement.component", ["loc", [null, [2, 68], [2, 92]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 13], [2, 93]]], 0, 0]], ["surveyDraft", ["subexpr", "@mut", [["get", "surveyDraft", ["loc", [null, [3, 16], [3, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "designOptions", ["subexpr", "@mut", [["get", "designOptions", ["loc", [null, [4, 18], [4, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "updateDesignOptions", ["subexpr", "@mut", [["get", "updateDesignOptions", ["loc", [null, [5, 24], [5, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "updateSurveyDraft", ["subexpr", "@mut", [["get", "updateSurveyDraft", ["loc", [null, [6, 22], [6, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "customFonts", ["subexpr", "@mut", [["get", "customFonts", ["loc", [null, [7, 16], [7, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "isLoading", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [8, 14], [8, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "currentElement", ["subexpr", "@mut", [["get", "currentElement", ["loc", [null, [9, 19], [9, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 1], [10, 4]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});