define('due-dashboard/components/digests/parts/one-selector', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    attrName: computed('value', function () {
      if (typeof this.get('value') === 'object') {
        return 'options.' + this.get('value')[0];
      }
      return 'options.' + this.get('value');
    }),

    selected: computed('selectOptions', 'options', 'attrName', function () {
      var _this = this;

      var attrName = this.get('attrName');
      if (!this.get(attrName)) {
        return this.get('selectOptions')[0];
      }
      return this.get('selectOptions').filter(function (item) {
        return item.value == _this.get(attrName);
      })[0];
    }),

    actions: {
      changeSelection: function changeSelection(selected) {
        var _this2 = this;

        this.set('selected', selected);
        if (typeof this.get('value') === 'object') {
          this.get('value').forEach(function (profile) {
            _this2.set('options.' + profile, selected.value);
          });
        } else {
          this.set(this.get('attrName'), selected.value);
        }
        this.get('saveDigestDelay')();
      }
    }
  });
});