define("due-dashboard/templates/components/change-end-image-scenario-action", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/change-end-image-scenario-action.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "change-end-img-input");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "wrapper has-image");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(element2, [1, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createAttrMorph(element2, 'class');
          morphs[3] = dom.createAttrMorph(element3, 'src');
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "error", ["loc", [null, [6, 21], [6, 26]]], 0, 0, 0, 0], "nok"], [], ["loc", [null, [6, 16], [6, 34]]], 0, 0], " ", ["subexpr", "unless", [["get", "action.image", ["loc", [null, [6, 44], [6, 56]]], 0, 0, 0, 0], "full"], [], ["loc", [null, [6, 35], [6, 65]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "image-upload", [], ["src", ["subexpr", "@mut", [["get", "action.image", ["loc", [null, [7, 25], [7, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "max-file-size", 3145728, "inputClass", ["subexpr", "if", [["get", "error", ["loc", [null, [7, 75], [7, 80]]], 0, 0, 0, 0], "error"], [], ["loc", [null, [7, 71], [7, 89]]], 0, 0], "typeCheck", true, "fireLoaded", true, "loaded", ["subexpr", "action", ["handleLoad"], [], ["loc", [null, [7, 128], [7, 149]]], 0, 0]], ["loc", [null, [7, 6], [7, 151]]], 0, 0], ["attribute", "class", ["concat", ["image ", ["subexpr", "unless", [["get", "action.image", ["loc", [null, [9, 31], [9, 43]]], 0, 0, 0, 0], "hidden"], [], ["loc", [null, [9, 22], [9, 54]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["get", "action.image", ["loc", [null, [11, 19], [11, 31]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: ["error"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/change-end-image-scenario-action.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "t", ["components.change-end-image-scenario-action.title"], [], ["loc", [null, [2, 2], [2, 59]]], 0, 0], ["block", "due-field-validation", [], ["model", ["subexpr", "@mut", [["get", "action", ["loc", [null, [4, 30], [4, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "image", "yield", true, "error_key", "image_error_key"], 0, null, ["loc", [null, [4, 0], [15, 25]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});