define("due-dashboard/templates/settings/integrations/stripe/configure", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/stripe/configure.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [18, 18], [18, 30]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["completeStripeIntegration", ["get", "parent.login_url", ["loc", [null, [18, 70], [18, 86]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 33], [18, 89]]], 0, 0], ["content", "button.text", ["loc", [null, [18, 90], [18, 105]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 15
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/stripe/configure.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-block");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "configure-post-order-survey");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-wrapper survey-template");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0, 1, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createMorphAt(element1, 4, 4);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["settings.integrations.stripe.post_charge_survey"], [], ["loc", [null, [4, 10], [4, 65]]], 0, 0], ["inline", "dynamic-surveys-dropdown", [], ["triggerClass", "survey-new-integration", "selectedSurvey", ["subexpr", "@mut", [["get", "model.company_integration.post_charge_survey", ["loc", [null, [7, 23], [7, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "actionOnChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.company_integration.post_charge_survey", ["loc", [null, [8, 36], [8, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 31], [8, 81]]], 0, 0]], [], ["loc", [null, [8, 23], [8, 82]]], 0, 0]], ["loc", [null, [5, 6], [9, 8]]], 0, 0], ["inline", "dynamic-templates-dropdown", [], ["triggerClass", "survey-new-integration", "selectedTemplate", ["subexpr", "@mut", [["get", "model.company_integration.post_charge_template", ["loc", [null, [11, 25], [11, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "actionOnChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.company_integration.post_charge_template", ["loc", [null, [12, 36], [12, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 31], [12, 83]]], 0, 0]], [], ["loc", [null, [12, 23], [12, 84]]], 0, 0]], ["loc", [null, [9, 8], [13, 8]]], 0, 0], ["block", "due-button", [], ["type", "transition", "disabled", ["subexpr", "not", [["get", "parent.submitEnabled", ["loc", [null, [17, 46], [17, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 41], [17, 67]]], 0, 0], "promise", ["subexpr", "@mut", [["get", "parent.promise", ["loc", [null, [17, 76], [17, 90]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "t", ["settings.integrations.stripe.connect"], [], ["loc", [null, [17, 97], [17, 139]]], 0, 0]], 0, null, ["loc", [null, [17, 0], [19, 15]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});