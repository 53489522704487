define('due-dashboard/components/v2/-iframe', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['due-iframe'],

    didInsertElement: function didInsertElement() {
      if (this.get('src')) {
        this.notifyPropertyChange("src");
      }
      this._super.apply(this, arguments);
    },

    iframeContainer: computed(function () {
      return document.getElementById(this.elementId).getElementsByClassName('iframe-container')[0];
    }),

    classDidChange: _ember['default'].observer('class', function () {
      var container = this.get('iframeContainer');
      container.getElementsByTagName('iframe')[0].className = this.get('class');
    }),

    srcDidChange: _ember['default'].observer('src', 'reloadPreviewUrl', function () {
      var container = this.get('iframeContainer');
      var iframe = document.createElement('iframe');

      iframe.className = this.get('class');
      iframe.style.position = "absolute";
      iframe.style.opacity = "0";
      iframe.src = this.get('src');
      if (container) {
        container.appendChild(iframe);
      }

      iframe.addEventListener('load', function () {
        iframe.style.opacity = "1";
        var frames = container.getElementsByTagName('iframe');
        if (frames.length > 1) {
          container.removeChild(frames[0]);
        }
      });
    })
  });
});