define('due-dashboard/components/digests/parts/category-level-part', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    /* Default selected value */
    selected: 0,

    init: function init() {
      /* Set default value for tag breakdown component */
      if (!this.get('comp.options.category_level')) {
        this.set('comp.options.category_level', this.get('selected'));
      }
      this._super.apply(this, arguments);
    },

    /**
     * Find the option from current selected index
     */
    value: _ember['default'].computed('comp.options.category_level', function () {
      return this.get('options')[this.get('comp.options.category_level')];
    }),

    actions: {

      /**
       * Update the current option for category
       * @param {String} selected Selected option
       */
      changeCategory: function changeCategory(selected) {
        this.set('comp.options.category_level', this.get('options').indexOf(selected));
        this.get('saveDigestDelay')();
      }
    }

  });
});
/**
 * @author Eliott Martin <eliott@diduenjoy.com>
 * @overview Digest component for catgerory level
 * 
 * @created 01/12/2021 - Eliott Martin
 * @updated 01/12/2021 - Eliott Martin
 * 
 * @sprint https://www.notion.so/diduenjoy/New-Tags-sections-in-the-Digest-e1b964a005ae4f5289a2cce68bb47a4f
 */