define('due-dashboard/models/survey-element', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    position: attr('number'),
    text: attr('string'),
    localised_text: attr('string'),
    like: attr('string'),
    kind: attr('string'),
    new_question_id: attr('string'),

    survey: belongsTo('survey')
  });
});