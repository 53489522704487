define('due-dashboard/components/nav/top-bar', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    elementId: 'top-bar',
    navigator: service(),
    session: service(),
    sidebar: service(),

    title: computed('navigator.currentPath', function () {
      return 'titles.' + this.get('navigator.currentPath');
    }),

    icon: computed('navigator.currentPath', function () {
      return '' + this.get('navigator.currentPath').replace(/\./g, '-');
    }),

    inSettings: computed('navigator.currentPath', function () {
      return (/^settings\..*/.test(this.get('navigator.currentPath'))
      );
    }),

    actions: {

      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },

      toggleSidebar: function toggleSidebar() {
        this.toggleProperty('sidebar.opened');
      },

      toggleFilters: function toggleFilters() {
        this.toggleProperty('displayFilters');
      }
    }
  });
});