define('due-dashboard/models/question-template', ['exports', 'ember-data', 'due-dashboard/mixins/filthy-model', 'ember-cp-validations'], function (exports, _emberData, _dueDashboardMixinsFilthyModel, _emberCpValidations) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    activity_sector: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = Model.extend(_dueDashboardMixinsFilthyModel['default'], Validations, {
    text: attr('string'),
    position: attr(),
    language: attr(),
    activity_sector_id: attr()
  });
});