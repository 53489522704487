define('due-dashboard/initializers/peek-or-find', ['exports', 'ember', 'ember-data', 'due-dashboard/utils/promise-object'], function (exports, _ember, _emberData, _dueDashboardUtilsPromiseObject) {
  var Store = _emberData['default'].Store;
  exports['default'] = {
    name: 'peek-or-find',
    initialize: function initialize() {
      Store.reopen({
        peekOrFind: function peekOrFind(type, id) {
          return this.peekRecord(type, id) || this.findRecord(type, id);
        }
      });
    }
  };
});