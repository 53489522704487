define('due-dashboard/routes/companies/libraries/rating-scales/index', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({

    model: function model() {
      return null;
    },

    afterModel: function afterModel() {
      var controller = this.controllerFor('companies.libraries.rating-scales.index');
      setTimeout(function () {
        controller.send('setModel');
      }, 0);
    }
  });
});