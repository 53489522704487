define('due-dashboard/components/digests/parts/filters-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    allFilters: ['status', 'comment', 'profile', 'survey', 'tag', 'sentiment', 'segment'],

    addableFilters: computed('filters', function () {
      var _this = this;

      var finalFilters = [];
      var usedFilters = this.get('filters').map(function (filter) {
        return filter.type;
      });
      var v2Flag = this.get('currentAccount.content.company.content.enable_new_survey_dashboard');

      this.get('allFilters').forEach(function (filter) {
        var index = usedFilters.indexOf(filter);
        if (index < 0 && filter !== 'profile') {
          finalFilters.push(filter);
        } else if (index < 0 && filter === 'profile' && !v2Flag) {
          finalFilters.push(filter);
        }
      });
      return finalFilters.map(function (filter) {
        return { action: filter, textKey: 'digest.filter.' + filter, text: _this.get('i18n').t('digest.filter.' + filter) };
      });
    }),

    displayButton: computed('addableFilters', function () {
      if (this.get('addableFilters').length > 0) {
        return true;
      }
      return false;
    }),

    actions: {

      addFilter: function addFilter(type) {
        this.get('filters').addObject({ type: type });
        this.notifyPropertyChange('filters');
        this.get('saveDigestDelay')();
      },

      deleteFilter: function deleteFilter(filter) {
        this.get('filters').removeObject(filter);
        this.notifyPropertyChange('filters');
        this.get('saveDigestDelay')();
      }
    }
  });
});