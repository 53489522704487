define('due-dashboard/controllers/settings/integrations/magento', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({
    api_namespace: '/magento/v1/orders/',
    surveySet: _ember['default'].computed.notEmpty('model.company_integration.post_confirmed_order_survey.id'),
    segmentSet: _ember['default'].computed.notEmpty('model.company_integration.segment.id'),
    submitEnabled: _ember['default'].computed.or('surveySet', 'segmentSet'),
    magento_module_dl_link: _dueDashboardConfigEnvironment['default'].EmberENV.MAGENTO_MODULE_URL,
    showAvancedSettings: false,
    magentoSegments: ['magento_store_id', 'magento_website_id', 'magento_order_id', 'total_item_count', 'first_name', 'last_name', 'total', 'postcode', 'country', 'phone', 'city', 'magento_custom_0'],

    segmentfilterList: computed(function () {
      var _this = this;

      return this.store.query('segment', { filter: { magento: true } }).then(function (ss) {
        var filtered_segments = ss.filter(function (a) {
          return _this.get('magentoSegments').includes(a.get('name'));
        });
        filtered_segments = _this.createSegmentsIfNotExistings(filtered_segments);
        filtered_segments.unshift(null);
        return filtered_segments;
      });
    }),

    createSegmentsIfNotExistings: function createSegmentsIfNotExistings(filtered_segments) {
      var _this2 = this;

      if (filtered_segments.length !== this.get('magentoSegments').length) {
        (function () {
          var already_existing_segments = filtered_segments.mapBy('name');
          _this2.get('magentoSegments').filter(function (s) {
            return !already_existing_segments.includes(s);
          }).forEach(function (s_name) {
            var seg = _this2.store.createRecord('segment', {
              name: s_name,
              position: 0
            });
            seg.save();
            filtered_segments.push(seg);
          });
        })();
      }
      return filtered_segments;
    },

    selectedSegments: computed(function () {
      return [];
    })

  });
});