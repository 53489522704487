define('due-dashboard/components/default-color-picker', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    isChrome: computed(function () {
      return (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && navigator.platform.match('MacIntel')
      );
    }),

    defaultValueSetter: on("init", observer('defaultValue', function () {
      if (!this.get('target')) {
        var defaultValue = this.get('defaultValue');
        this.set('target', defaultValue);
        this.attrs.target.update(defaultValue);
      }
    }))
  });
});