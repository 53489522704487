define('due-dashboard/components/v2/libraries/surveys/navbar/translations/-step', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['survey-translations-step'],

    ELEMENT_COMPONENTS: {
      'rating': '-rating',
      'short_text_input': '-generic',
      'long_text_input': '-generic',
      'single_select': '-choice',
      'multiple_select': '-choice',
      'title': '-generic',
      'text': '-generic',
      'prev_next_btns': '-generic',
      'redirect_btn': '-generic'
    },

    stepPosition: computed('step', function () {
      return Number(this.get('step.position')) + 1;
    }),

    elements: computed('step', function () {
      var elementComponents = this.get('ELEMENT_COMPONENTS');
      return this.get('step.survey_elements_attributes').filter(function (element) {
        return element.kind in elementComponents;
      }).map(function (element) {
        return {
          data: element,
          component: elementComponents[element.kind]
        };
      });
    })
  });
});