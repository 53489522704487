define('due-dashboard/components/custom-stats-head', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',

    /**
     * - Remove parent from tag name
     * - Add return line
    */
    computedHeaders: _ember['default'].computed('header', function () {
      return this.get('headers').map(function (header) {
        if (!header.tag) return header;
        header.title = header.title.replace(/\n/g, "<br/>");
        var index = header.title.lastIndexOf(' > ');
        if (index === -1) return header;
        return _extends({}, header, { title: htmlSafe(header.title.substring(index + 3)), original: header.title }); /* +3 To delete space & > */
      });
    })
  });
});