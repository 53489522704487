define('due-dashboard/components/due-histogram-tooltip', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    attributeBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      var width = this.get('width');
      var content = '<div style="width: ' + width + 'px; font-size: 12px; padding-top: 10px; padding-bottom: 10px; padding-left: 5px; padding-right: 5px;>';
      if (this.get('data')) {
        var data = this.get('data');
        /** Add date */
        content += '\n        <div style="width: 100%; text-align: center">\n          <p style="text-align: center; font-weight: 500; font-size: 12px; color: #9EB0BE; margin-bottom: 10px;">' + data.popupDate + '</p>\n        </div>\n      ';

        /** Add values */
        if (data.values) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = data.values[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var value = _step.value;

              content += '\n            <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px;">\n              <div style="width: 5%;">\n                <div style="width: 5px; height: 5px; border-radius: 5px; background-color: ' + value.color + '; margin-right: 5px;"></div>\n              </div>\n              <div style="width: 65%; display: flex; flex-direction: row; justify-content: space-between; padding-left: 10px;">\n                <p style="color: white;">' + value.label + '</p>\n              </div>\n              <div style="width: 25%; display: flex; flex-direction: row; text-align: center;">\n                <p style="font-weight: 600;">' + value.nbr + ' <p style="color: #E1E8EE; padding-left: 4px;">(' + value.percent + '%)</p></p>\n              </div>\n            </div>\n          ';
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator['return']) {
                _iterator['return']();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }
      }
      content += '</div>';
      $(this.$().parent('.formatted-graph-row-hover > .formatted-graph-row')).tooltipster({
        theme: ['tooltipster-noir', 'tooltipster-noir-customized', 'tooltipster-noir-customized-centered'],
        content: content,
        contentAsHTML: true,
        animation: 'fade',
        delay: 1,
        speed: 100,
        trigger: 'hover',
        autoClose: true,
        arrow: false
      });
    }
  });
});
/**
 * @fileoverview {{due-histogram-tooltip}} Histogram Tooltip component
 * @author Eliott Martin <eliott@diduenjoy.com>
 * @figma https://www.figma.com/file/yyM8UbYEpUOiNn192BFktX/Tags-Analysis?node-id=690%3A19469
 * @company Diduenjoy
 */
/* global $ */