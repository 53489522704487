define('due-dashboard/models/export-request', ['exports', 'ember-data', 'ember', 'moment', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _ember, _moment, _emberDataModelFragmentsAttributes) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    sort: _emberData['default'].attr('string'),
    filter: _emberData['default'].attr('string'),
    ordered_columns: (0, _emberDataModelFragmentsAttributes.array)('string'),
    feedback_ids: (0, _emberDataModelFragmentsAttributes.array)('string'),
    export_type: _emberData['default'].attr('string'),
    file_file_name: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    created_at: _emberData['default'].attr('string', { readOnly: true }),
    url: _emberData['default'].attr('string', { readOnly: true }),

    prettyCreatedAt: computed('created_at', function () {
      return this.get('created_at') ? (0, _moment['default'])(this.get('created_at')).format('LLL') : '';
    })
  });
});