define("due-dashboard/translations/it", ["exports"], function (exports) {
  exports["default"] = {
    "it": {
      "survey_preview": {
        "social": {
          "title": "Grazie!"
        }
      }
    }
  };
});