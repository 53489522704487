define('due-dashboard/routes/settings/integrations/salesforce', ['exports', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({

    actions: {

      updateAccountObjectType: function updateAccountObjectType(option) {
        this.controllerFor('settings.integrations.salesforce').set('accountObject', option);
        this.currentModel.company_integration.set('no_object', option == 'None');
      },

      completeSalesforceIntegration: function completeSalesforceIntegration() {
        this.currentModel.company_integration.set('completed', false);
        this.controller.set('promise', this.currentModel.company_integration.save().then(function (salesforce_it) {
          window.location.replace(salesforce_it.get('oauth_url'));
        }));
      }

    }

  });
});