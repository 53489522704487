define('due-dashboard/components/graphs/timeline-table', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var service = _ember['default'].inject.service;
  var set = _ember['default'].set;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;

  var statTypes = ['feedback_rate', 'completion_rate', 'open_rate', 'click_rate', 'promoter_rate', 'passive_rate', 'detractor_rate', 'verbatim_rate', 'delivery_rate'];
  exports['default'] = _ember['default'].Component.extend({
    _global: service('feedback-search/global'),
    classNames: ['timeline-container'],

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.$('.scrolling-container').css('padding-left', this.$('.fixed-column').width());
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this.get('selectedIndex');
    },

    selectedIndexChanged: observer('selectedIndex', function () {
      var index = this.get('selectedIndex'),
          header_el = this.$('table th:nth-of-type(' + (index + 1) + ')');

      if (header_el) {
        header_el[0].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        });
      }
    }),

    getEndDateFromStepType: function getEndDateFromStepType(startDateTimestamp) {
      var step = this.get('stepType.code');
      return (0, _moment['default'])(startDateTimestamp).add(1, step + 's').format(); // step = day, but  moment() needs day(s)
    },

    dataRows: computed('data.curves.[]', 'graph.lines.@each.name', 'stepType', function () {
      var _this = this;

      var curves = this.get('data.curves') || [];
      var lines = this.get('graph.lines') ? this.get('graph.lines').toArray() : [];
      var timestamps = this.get('data.timestamps');

      return curves.map(function (curve, i) {
        var line = lines[i];
        return {
          type: line.get('type'),
          filters: line.get('filters'),
          header: line.get('name'),
          color: line.get('color'),
          unit: statTypes.indexOf(line.get('type')) !== -1 ? ' %' : '',
          values: curve.values.map(function (v, idx) {
            // TODO: Refactor me with due_graph.js
            var trueValue = Number(v),
                fixedValue = parseFloat(Number(trueValue).toFixed(1));
            return {
              value: fixedValue,
              dates: [timestamps[idx], timestamps[idx + 1] || _this.getEndDateFromStepType(timestamps[idx])]
            };
          })
        };
      });
    }),

    columnHeaders: computed('data.timestamps.[]', function () {
      var timestamps = this.get('data.timestamps') || [];
      var date_format = this.get('dateFormat');

      return timestamps.map(function (ts) {
        return date_format(new Date(ts));
      });
    }),

    getProfileFilter: function getProfileFilter(row) {
      var profile_types = ['count_detractor', 'count_passive', 'count_promoter'];

      if (profile_types.includes(row.type)) {
        var profile = row.type.split('_')[1];

        return [profile];
      }
      return [];
    },

    getSegmentFilter: function getSegmentFilter(row) {
      return row.filters.filter(function (filter) {
        return filter.filter_type === 'segment';
      }).uniqBy(function (filter) {
        return filter.get('segment.oid');
      }).reduce(function (acc, filter) {
        var id = filter.get('segment.oid'),
            values = filter.get('values');

        acc[id] = values;
        return acc;
      }, {});
    },

    actions: {
      transitToVerbatim: function transitToVerbatim(row) {
        var column = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

        var service = this.get('_global'),
            row_filters = {
          profiles: this.getProfileFilter(row),
          segments: this.getSegmentFilter(row)
        },
            updated = service.get('current').copy();

        updated.setProperties(row_filters);
        if (column.dates && column.dates[0] && column.dates[1]) {
          updated.set('start_date', column.dates[0]);
          updated.set('end_date', column.dates[1]);
        }
        service.updateCurrent(updated);
        getOwner(this).lookup('controller:application').transitionToRoute('companies.verbatim');
      }
    }
  });
});