define('due-dashboard/components/image-upload', ['exports', 'ember'], function (exports, _ember) {
  /* globals $ */

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['image-upload'],
    src: null,
    'max-file-size': null,
    error: null,
    typeCheck: false,
    fireLoaded: false,
    onUpdate: null,

    attributeBindings: ['src'],

    actions: {
      updateImage: function updateImage() {
        var _this = this;
        var input = this.$('input')[0];
        var file = $(input).prop('files')[0];
        if (file) {
          var max_size = this.get('max-file-size');
          if (max_size && file.size > max_size) {
            this.set('error', 'too_large');
            input.value = '';
            return;
          }
          if (this.get('typeCheck') && !file.type.match(/^image\//)) {
            this.set('error', 'invalid_type');
            input.value = '';
            return;
          }
          var reader = new FileReader();
          reader.onload = function (e) {
            _this.set('src', e.target.result);
            _this.set('error', false);
            if (_this.get('fireLoaded') === false) {
              return;
            }
            var deferred = _ember['default'].RSVP.defer();
            _ember['default'].run.next(function () {
              if (_this.get('onUpdate')) {
                _this.get('onUpdate')();
              }
              _this.sendAction('loaded', deferred);
            });
            deferred.promise.then(function () {
              input.value = '';
            });
          };
          reader.readAsDataURL(file);
        }
      }
    }

  });
});