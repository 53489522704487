define('due-dashboard/components/segment-fragment-wrapper', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    fragment: null,

    segmentPromise: computed('fragment.name', function () {
      var id = this.get('fragment.oid');

      if (id != null) {
        return _emberData['default'].PromiseObject.create({
          promise: this.store.find('segment', id)
        });
      }
    }),

    //  try to replace with promise.content alias
    segment: computed('segmentPromise.isFulfilled', function () {
      if (this.get('segmentPromise.isFulfilled')) {
        return this.get('segmentPromise.content');
      }
    })
  });
});