define('due-dashboard/controllers/settings/tags/translations', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;
  var RSVP = _ember['default'].RSVP;
  var keys = _ember['default'].keys;
  exports['default'] = Controller.extend({
    tagManager: _ember['default'].inject.service(),

    queryParams: ['kind', 'due_exclusive_edit'],
    kind: 'tag',
    due_exclusive_edit: null,
    updatedTags: [],
    usedLanguageKeys: [],
    forbiddenChars: ['>'],
    translatePromise: null,
    tags: [],
    languages: computed.alias('model.languages'),
    realTags: computed('tags', function () {
      var _this = this;

      return this.get('tags').map(function (tag, _, array) {
        var tagLang = tag.get('language.tag').toLowerCase(),
            transKeys = keys(tag.get('translations')),
            mergedLang = _this.get('usedLanguageKeys').concat(transKeys.concat([tagLang])),
            splitName = tag.get('name').split(' > '),
            subTagRegExp = "^" + tag.get('name').replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + " > [^>]*$";
        _this.set('usedLanguageKeys', mergedLang.filter(function (lang, idx) {
          return mergedLang.indexOf(lang) === idx;
        }));
        tag.set('depth', splitName.length - 1);
        tag.set('displayName', splitName[tag.get('depth')]);
        tag.set('iconClass', 'tio-label');
        var subLines = array.filter(function (item) {
          return item.get('name').match(subTagRegExp);
        });
        subLines = subLines.map(function (subLine) {
          subLine.set('parentLine', tag);
          return subLine;
        });
        tag.set('subLines', subLines);
        return tag;
      });
    }),

    init: function init() {
      var _this2 = this;

      this.get('tagManager').getAll().then(function (tags) {
        _this2.set('tags', tags);
        _this2.get('tagManager').getAll({}, 'root_cause').then(function (rootCauses) {
          _this2.set('rootCauses', rootCauses);
          _this2.set('loading', false);
        });
        _this2.get('tagManager').getAll({}, 'action_taken').then(function (actionsTaken) {
          _this2.set('actionsTaken', actionsTaken);
          _this2.set('loading', false);
        });
      });
    },

    kindObserver: _ember['default'].observer('kind', function () {
      var _this3 = this;

      this.get('tagManager').getAll({}, this.get('kind')).then(function (tags) {
        return _this3.set('tags', tags);
      });
    }),

    selectedLanguages: computed('languages', 'usedLanguageKeys', 'model.company.language', function () {
      var _this4 = this;

      var companyLanguage = this.get('model.company.language'),
          cmpTag = companyLanguage.get('tag'),
          usedLanguages = this.get('languages').filter(function (language) {
        return _this4.get('usedLanguageKeys').includes(language.get('tag').toLowerCase());
      });
      return usedLanguages.length > 0 ? usedLanguages.sort(function (a, b) {
        return a.get('tag') == cmpTag ? -1 : b.get('tag') == cmpTag ? 1 : 0;
      }) : this.get('languages').filter(function (language) {
        return cmpTag == language.get('tag');
      });
    }),

    _updateSubLines: function _updateSubLines(tag, propertyKey, depth, language, value) {
      var nameSplited = tag.get('name').split(' > ').map(function (subname) {
        return subname.trim();
      });
      nameSplited[depth] = value;
      if (tag.get(propertyKey)) {
        var translations = tag.get(propertyKey + '.text').split('>').map(function (subname) {
          return subname.trim();
        });
        translations[depth] = value;
        tag.set(propertyKey + '.text', translations.join(' > '));
        tag.set(propertyKey + '.auto', false);
      } else {
        tag.set('translations.' + language, { text: nameSplited.join(' > '), auto: false });
      }
      if (language === tag.get('language.tag').toLowerCase()) {
        tag.set('name', nameSplited.join(' > '));
      }
      this.set('updatedTags.' + tag.get('id'), tag);
      if (tag.get('subLines').length > 0) {
        for (var i = 0; i < tag.get('subLines').length; i++) {
          this._updateSubLines(tag.get('subLines')[i], propertyKey, depth, language, value);
        }
      }
    },

    _removeLanguage: function _removeLanguage(lang) {
      var _this5 = this;

      this.set('realTags', this.get('realTags').map(function (tag) {
        var translation = tag.get('translations');
        delete translation[lang];
        tag.set('translations', translation);
        _this5.get('updatedTags').pushObject(tag);
        return tag;
      }));
    },

    _addLanguage: function _addLanguage(lang) {
      var _this6 = this;

      this.set('realTags', this.get('realTags').map(function (tag) {
        var text = '',
            auto = '';
        if (tag.get('translations.' + lang)) {
          text = tag.get('translations.' + lang + '.text');
          auto = tag.get('translations.' + lang + '.auto');
        } else {
          text = '';
          auto = false;
        }
        tag.set('translations.' + lang, { text: text, auto: auto });
        _this6.get('updatedTags').pushObject(tag);
        return tag;
      }));
    },

    actions: {
      updateTranslation: function updateTranslation(line, language, value) {
        var depth = line.depth,
            attributeKey = 'translations.' + language;
        this._updateSubLines(line, attributeKey, depth, language, value);
      },

      addLanguage: function addLanguage(language) {
        this._addLanguage(language.get('tag').toLowerCase());
        this.get('selectedLanguages').pushObject(language);
      },

      removeLanguage: function removeLanguage(language) {
        this.get('selectedLanguages').removeObject(language);
        this._removeLanguage(language.get('tag').toLowerCase());
      },

      cancelAction: function cancelAction() {
        this.set('updatedTags', []);
        this.transitionToRoute('settings.tags');
      },

      saveTranslation: function saveTranslation() {
        var _this7 = this;

        var promises = [];
        Object.keys(this.get('updatedTags')).forEach(function (key, index) {
          promises[index] = _this7.get('updatedTags')[key].save();
          delete _this7.get('updatedTags').key;
        });
        this.set('translatePromise', RSVP.all(promises)).then(function () {
          return _this7.transitionToRoute('settings.tags');
        });
      }
    }
  });
});