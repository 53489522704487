define('due-dashboard/mixins/filterable-model', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;

  // Rename this
  exports['default'] = Mixin.create({

    params: null,
    model_name: null,

    model: computed('params', 'model_name', function () {
      var params = this.get('params');
      var model_name = this.get('model_name');

      return this.store.find(model_name, params);
    })

  });
});