define('due-dashboard/components/feedback-preview', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    originalTextDisplay: true,
    autoTranslate: computed.alias('currentAccount.content.company.verbatim_auto_translation'),
    verbatimTranslation: computed.alias('currentAccount.content.company.verbatim_translation'),
    translationKey: 'translate',
    hasComment: computed.empty('feedback.comment'),
    showTranslation: false,

    hasCommentText: computed('hasComment', function () {
      return this.get('hasComment') ? 'oui' : 'non';
    }),

    hasTranslation: computed('feedback.translation', 'verbatimTranslation', function () {
      return this.get('feedback.translation').length && this.get('verbatimTranslation');
    }),

    fromTrustpilot: computed('feedback.source', function () {
      return this.get('feedback.source') == "trustpilot_review" ? true : false;
    }),

    ellipsedComment: computed('feedback.comment', 'feedback.translation', 'autoTranslate', function () {
      var comment = this.get('feedback.comment');
      if (this.get('feedback.translation').length && this.get('autoTranslate')) {
        this.set('originalTextDisplay', false);
        this.set('translationKey', 'original_text');
        comment = htmlSafe(this.get('feedback.translation'));
      }
      return comment;
    }),

    ellipsedTranslation: computed('feedback.comment', 'feedback.translation', 'autoTranslate', function () {
      var comment = null;
      if (!this.get('autoTranslate') && this.get('feedback.translation').length) {
        comment = htmlSafe(this.get('feedback.translation'));
      } else if (this.get('autoTranslate')) {
        comment = this.get('feedback.comment');
      }
      return comment;
    }),

    prettyCreatedAt: computed('feedback.created_at', function () {
      return (0, _moment['default'])(this.get('feedback.created_at')).format('LLL');
    }),

    actions: {
      toggleCommentDisplayLanguage: function toggleCommentDisplayLanguage() {
        if (!this.get('showTranslation')) {
          this.set('translationKey', 'hide');
          if (this.get('originalTextDisplay')) {
            this.set('translationKey', 'hide_translation');
          } else {
            this.set('translationKey', 'hide_original');
          }
        } else if (this.get('originalTextDisplay')) {
          this.set('translationKey', 'translate');
        } else {
          this.set('translationKey', 'original_text');
        }
        this.toggleProperty('showTranslation');
      }
    }
  });
});