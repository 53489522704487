define('due-dashboard/components/due-trend-tooltip', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      var content = '\n      <div\n        style="display: flex;\n                flex-direction: row;\n                gap: 8px;\n                overflow: hidden;\n                padding: 10px;">\n        <div style="font-weight: 600;\n                    font-size: 14px;\n                    color: white;\n                    display: inline-block;\n                    max-width: 180px;\n                    width: fit-content;\n                    white-space: nowrap;\n                    overflow: hidden !important;\n                    text-overflow: ellipsis;">\n          ' + this.get('text') + '\n        </div>\n        <div style="margin-left: auto;\n                    font-size: 14px;">\n          ' + this.get('value') + '%\n        </div>\n      </div>\n    ';

      $(this.element).prev('div').tooltipster({
        theme: ['tooltipster-noir', 'tooltipster-noir-customized', 'tooltipster-noir-customized-centered'],
        content: content,
        contentAsHTML: true,
        animation: 'fade',
        delay: 300,
        speed: 100,
        hideOnClick: false,
        trigger: 'hover',
        autoClose: true,
        position: 'top',
        arrow: false
      });
    }

  });
});