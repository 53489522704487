define('due-dashboard/components/feedback-chat-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    tagName: 'li',
    // TODO: clean/remove unused code unread
    classNameBindings: ['isUnread:feedback-sidebar-list-unread-true:feedback-sidebar-list-unread-false', 'isSelected', 'profile', 'isLoading'],

    profile: computed.alias('feedback.profile'),
    autoTranslate: computed.alias('currentAccount.content.company.verbatim_auto_translation'),
    fromNewSurvey: computed.alias('feedback.is_from_new_survey'),

    isSelected: computed('clickedFeedback.id', 'feedback.id', 'isLoading', function () {
      return this.get('clickedFeedback.id') === this.get('feedback.id') && !this.get('isLoading');
    }),

    preview: computed('feedback.last_message', 'feedback.comment', 'feedback.translations', 'autoTranslate', function () {
      if (this.get("feedback.translations")[this.get('i18n.locale')] && this.get('autoTranslate')) {
        return this.get("feedback.translations")[this.get('i18n.locale')];
      }
      if (this.get('feedback.last_message')) {
        return this.get('feedback.last_message');
      } else {
        return this.get('feedback.comment') || '-';
      }
    }),

    fromTrustpilot: computed('feedback.source', function () {
      return this.get('feedback.source') == "trustpilot_review" ? true : false;
    }),

    trustpilotStyle: computed('fromTrustpilot', function () {
      return this.get('fromTrustpilot') ? "trustpilotStyle" : "";
    }),

    integrationImage: computed('feedback.from_external', function () {
      var _this = this;

      var from_external = this.get('feedback.from_external');
      if (from_external) {
        var stored = this.store.peekAll('integration').findBy('name', this.get('feedback.source').dasherize());
        if (stored) {
          return stored;
        }
        return this.store.findAll('integration').then(function (records) {
          _this.set('integrationImage', records.findBy('name', _this.get('feedback.source').dasherize()));
        });
      } else {
        return null;
      }
    }),

    click: function click() {
      this.sendAction('action', this.get('feedback'));
    }
  });
});