define('due-dashboard/components/digests/filters/segment-value-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    allValues: [],
    remainingValues: computed.setDiff('allValues', 'valuesFilter'),

    init: function init() {
      var _this = this;

      this.store.queryRecord('segment-value', { filter: { segment_id: this.get('segment.segment_id'), contains: '' } }).then(function (values) {
        return _this.set('allValues', values.get('values'));
      });
      this._super.apply(this, arguments);
    },

    valuesFilter: computed('segment.values', 'segment.values.length', 'allSurveys', {
      get: function get() {
        var values = this.get('segment.values');
        return values;
      }, set: function set(_, values) {
        this.set('segment.values', values);
        this.get('saveDigestDelay')();
        return values;
      }
    }),

    _searchValues: function _searchValues(term, resolve, reject) {
      this.store.queryRecord('segment-value', {
        filter: {
          segment_id: this.get('segment.segment_id'),
          contains: term
        }
      }).then(function (values) {
        return resolve(values.get('values'));
      }, reject);
    },

    actions: {

      searchValues: function searchValues(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          run.debounce(_this2, _this2._searchValues, term, resolve, reject, 600);
        });
      }
    }
  });
});