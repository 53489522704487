define('due-dashboard/controllers/settings/web-snippets/edit', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;

  var switcher = {
    lightness: {
      dark: 'light',
      light: 'dark'
    },
    snippet_type: {
      feedback_list: 'global_rating',
      global_rating: 'feedback_list'
    }
  };

  exports['default'] = Controller.extend({

    availableTypes: ['display_global_note', 'display_list'],

    availableOptions: ['display_date', 'display_comment'],

    selectedTypes: computed('model.{display_global_note,display_list}', {
      get: function get() {
        var _this = this;

        return this.get('availableTypes').filter(function (key) {
          return _this.get('model.' + key);
        });
      },
      set: function set(_, values) {
        var _this2 = this;

        this.get('availableTypes').forEach(function (key) {
          return _this2.set('model.' + key, false);
        });
        values.forEach(function (key) {
          return _this2.set('model.' + key, true);
        });
        return values;
      }
    }),

    selectedOptions: computed('model.{display_comment,display_date}', {
      get: function get() {
        var _this3 = this;

        return this.get('availableOptions').filter(function (key) {
          return _this3.get('model.' + key);
        });
      },
      set: function set(_, values) {
        var _this4 = this;

        this.get('availableOptions').forEach(function (key) {
          return _this4.set('model.' + key, false);
        });
        values.forEach(function (key) {
          return _this4.set('model.' + key, true);
        });
        return values;
      }
    }),

    disable_save: computed('model.{name,display_list,display_global_note}', function () {
      var _this5 = this;

      var model_has_not = function model_has_not(k) {
        return !_this5.get('model').get(k);
      };
      if (model_has_not('display_list') && model_has_not('display_global_note') || model_has_not('name')) {
        return true;
      }
      return false;
    }),

    actions: {

      'switch': function _switch(key) {
        var path = 'model.' + key;
        this.set(path, switcher[key][this.get(path)]);
      }

    }

  });
});