define('due-dashboard/components/text-field-to-segment-scenario-action', ['exports', 'ember', 'due-dashboard/components/scenario-action-item'], function (exports, _ember, _dueDashboardComponentsScenarioActionItem) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var mapBy = computed.mapBy;
  var setDiff = computed.setDiff;
  var gt = computed.gt;
  var sort = computed.sort;

  // from change_end_link_text
  exports['default'] = _dueDashboardComponentsScenarioActionItem['default'].extend({

    displayLanguageList: false,
    classNames: ['text-field-to-segment'],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.store.findAll('language', { reload: true }).then(function (languages) {
        _this.set('languages', languages);
        if (_this.get('selectedLanguages').length === 0) {
          _this.addLanguage(languages.findBy('tag', _this.get('i18n.locale').toUpperCase()));
        } else {
          _this.get('selectedLanguages').forEach(function (language) {
            _this.get('placeholder_error_key').set(language.get('tag'), 'text_field_to_segment-' + language.get('tag') + '-link');
          });
        }
      });
      this.store.query('segment', { filter: { all: true }, page: { number: 1, size: 20 } }).then(function (segments) {
        _this.set('segments', segments);
      });
    },

    _searchSegments: function _searchSegments(term, resolve, reject) {
      this.store.query('segment', {
        filter: {
          name: term,
          all: true
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (segments) {
        return resolve(segments);
      }, reject);
    },

    /*
    ** Error handling
    */

    placeholder_error_key: new _ember['default'].Object(),
    has_many_language: true,
    language_tab_error_regexp: /text_field_to_segment-[A-Z]+-[a-z]+/,

    errorObserverVars: {
      array_key: 'action.actions_languages',
      error_prefix: 'text_field_to_segment',
      error_suffix: 'language.tag',
      error_target: {
        'placeholder': 'validations.attrs.placeholder.error'
      }
    },

    error_keys: computed(function () {
      return ['text-field-to-segment'].concat(this.get('errors.errorKeys').filter(function (a) {
        return !!a.match(/text_field_to_segment-[A-Z]/);
      }));
    }).volatile(),

    errorObserver: observer('try_step', function () {
      var _this2 = this;

      var _get = this.get('errorObserverVars');

      var error_prefix = _get.error_prefix;
      var error_suffix = _get.error_suffix;
      var error_target = _get.error_target;
      var array_key = _get.array_key;

      this.get(array_key).map(function (elem) {
        elem.validate().then(function () {
          ['placeholder'].forEach(function (attr) {
            _this2.get('errors').updateErrorByKey(error_prefix + '-' + elem.get(error_suffix) + '-' + attr, elem.get(error_target[attr]), elem.get(error_suffix));
          });
        });
      });
    }),

    /*
    ** Core
    */

    actLanguageSort: ['language.tag'],
    languageSort: ['tag'],
    orderedActionLanguages: sort('action.actions_languages', 'actLanguageSort'),
    _selectedLanguages: mapBy('action.actions_languages', 'language.content'),
    _remainingLanguages: setDiff('languages', 'selectedLanguages'),
    selectedLanguages: sort('_selectedLanguages', 'languageSort'),
    remainingLanguages: sort('_remainingLanguages', 'languageSort'),
    canDeleteLanguage: gt('action.actions_languages.length', 1),

    addLanguage: function addLanguage(language) {
      var action = this.get('action');
      var at = this.store.createRecord('text-field-to-segment-scenario-actions-language', {
        action: action,
        language: language,
        link: this.get('action.actions_languages.firstObject.link')
      });
      this.get('placeholder_error_key').set(language.get('tag'), 'text_field_to_segment-' + language.get('tag') + '-link');
      action.get('actions_languages').addObject(at);
      this.set('selectedLanguage', at);
    },

    removeLanguage: function removeLanguage(language) {
      this.get('errors').updateErrorByKey('text_field_to_segment-' + language.get('tag') + '-link', null);
      this.get('errors').updateErrorByKey('text_field_to_segment-' + language.get('tag') + '-text', null);
      var index = this.get('orderedActionLanguages').indexOf(language);
      this.get('action.actions_languages').removeObject(language);
      if (this.get('selectedLanguage.language.tag') === language.get('language.tag')) {
        this.set('selectedLanguage', this.get('orderedActionLanguages').toArray()[index] || this.get('orderedActionLanguages').toArray()[index - 1]);
      }
      language.deleteRecord();
    },

    actions: {

      searchSegments: function searchSegments(term) {
        var _this3 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this3, _this3._searchSegments, term, resolve, reject, 600);
        });
      },

      openLanguageList: function openLanguageList() {
        this.set('displayLanguageList', true);
      },

      closeLanguageList: function closeLanguageList() {
        this.set('displayLanguageList', false);
      },

      addLanguage: function addLanguage(language) {
        this.set('displayLanguageList', false);
        this.addLanguage(language);
      },

      removeLanguage: function removeLanguage(language) {
        this.removeLanguage(language);
      }
    }

  });
});