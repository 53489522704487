define('due-dashboard/components/loading-skeleton/due-search-bar-skeleton', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;

  /**
   * @author Antoine YVROUD <antoine@diduenjoy.com>
   * @summary {{due-search-bar-skeleton}} Search bar skeleton component
   *
   * @module
   * @argument {string} [width] - specific width for search bar
   * @argument {string} [height] - specific height for search bar
   * @argument {boolean} [fitParentWidth] - boolean to auto compute width based on parent
   * @argument {string} [color] - specific color for search bar
   * @argument {string} [style] - specific style added to  the component initial style
   * @example <caption>Full usage example of {{due-search-bar-skeleton}} component</caption>
   * Usage :
   * ```hbs
   * {{loading-skeleton/due-search-bar-skeleton
   *   width=337
   *   height=36
   *   fitParentWidth=true
   *   color="#F6F8FB"
   *   style="margin: XXpx;"
   * }}
   * ```
   */

  exports['default'] = Component.extend({
    classNames: ['due-search-bar-skeleton'],
    attributeBindings: ['style'],

    rendered: false,
    color: '#F6F8FB',
    width: 337,
    height: 36,
    containerWidth: 339,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('animationUID', 'linear-gradient-' + guidFor(this));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('rendered', true);
      this.set('xOffset', this.element.querySelector('svg').getBoundingClientRect().x);
      this.set('windowWidth', $('body > .ember-view').width());
      this.set('parentWidth', this.element.parentNode.getBoundingClientRect().width - 10);
    },

    computedAnimationUID: computed('emptyState', function () {
      return this.get('emptyState') ? '#F6F8FB' : 'url(#' + this.get('animationUID') + ')';
    }),

    borderRectWidth: computed('width', 'parentWidth', function () {
      var computedWidth = (this.get('fitParentWidth') ? this.get('parentWidth') : this.get('width')) - 5;
      this.set('containerWidth', Math.min(computedWidth, this.get('parentWidth') || 334) + 5);
      return Math.min(computedWidth, (this.get('parentWidth') || 337) - 10);
    }),

    borderRectHeight: computed('height', function () {
      return this.get('height') - 2;
    }),

    widths: computed('containerWidth', function () {
      return {
        margin: this.get('containerWidth') * 0.05,
        placeholder: this.get('containerWidth') * 0.5
      };
    }),

    insideHeight: computed('height', function () {
      return this.get('height') / 3;
    })
  });
});