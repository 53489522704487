define("due-dashboard/templates/companies/templates/edit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/companies/templates/edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "dashboard-title", ["loc", [null, [1, 0], [1, 19]]], 0, 0, 0, 0], ["inline", "templates/edit-view", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 8], [3, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "saveTemplatePromise", ["subexpr", "@mut", [["get", "saveTemplatePromise", ["loc", [null, [4, 22], [4, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "saveTemplate", ["subexpr", "action", ["saveTempl"], [], ["loc", [null, [5, 15], [5, 35]]], 0, 0], "bulk_dispatch_id", ["subexpr", "@mut", [["get", "bulk_dispatch_id", ["loc", [null, [6, 19], [6, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "required_languages", ["subexpr", "@mut", [["get", "required_languages", ["loc", [null, [7, 21], [7, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "prevStep", ["subexpr", "action", ["prev"], [], ["loc", [null, [8, 11], [8, 26]]], 0, 0]], ["loc", [null, [2, 0], [9, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});