define('due-dashboard/components/segments/single-dropdown', ['exports', 'ember', 'due-dashboard/models/segment'], function (exports, _ember, _dueDashboardModelsSegment) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tag: '',

    DISABLED_SEGMENT_FILTERS: ['MC_CID', 'MC_EID', 'SPM_KEY', 'UTM_SOURCE', 'UTM_CAMPAIGN', 'UTM_CONTENT', 'UTM_MEDIUM', 'UTM_TERM', 'SCHEDULED_AT'],

    allSegments: [],
    allowClear: false,
    enabledSegments: computed.filter('allSegments', function (s) {
      return !this.DISABLED_SEGMENT_FILTERS.includes(s.get('name').toUpperCase());
    }),
    sortedEnabledSegments: computed.sort('enabledSegments.[]', _dueDashboardModelsSegment['default'].sort),

    init: function init() {
      var _this = this;

      this._searchSegments('', function (allSegments) {
        return _this.set('allSegments', allSegments.toArray());
      }, function () {});
      this._super.apply(this, arguments);
    },

    _searchSegments: function _searchSegments(term, resolve, reject) {
      this.store.query('segment', {
        filter: {
          all: false,
          name: term
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (segments) {
        return resolve(segments);
      }, reject);
    },

    actions: {
      searchSegments: function searchSegments(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this2, _this2._searchSegments, term, resolve, reject, 600);
        });
      }

    }

  });
});