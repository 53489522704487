define('due-dashboard/controllers/settings/management-question-category/index', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var gt = _ember['default'].computed.gt;
  var Controller = _ember['default'].Controller;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Controller.extend({
    currentStep: 1,
    isLoading: false,
    saveDisabled: true,
    searchValue: null,
    searchedCategories: null,
    categories: computed.alias('model'),
    disabledDragDrop: gt('searchValue.length', 0),

    _searchCategory: function _searchCategory() {
      var _this = this;

      var filters = { filter: {} },
          searchCategory = this.get('searchValue');
      if (searchCategory) {
        filters.filter.text = searchCategory;
      }
      this.get('store').query('management-question-category', filters).then(function (results) {
        return _this.set('model', results);
      });
    },

    updateScroll: function updateScroll() {
      var _this2 = this;

      this.set('currentStep', this.get('currentStep') + 1);
      var filters = {};
      filters.page = { number: this.get('currentStep') };
      var categories = this.get('model').toArray();
      this.get('store').query('management-question-category', filters).then(function (results) {
        categories = [].concat(_toConsumableArray(categories), _toConsumableArray(results.toArray()));
        _this2.set('model', categories);
        _this2.set('isLoading', false);
      });
    },

    resetQCPositions: function resetQCPositions(category, withOrdering) {
      var removedQCPosition = this.get('categories').indexOf(category);
      var qcs = this.get('categories'),
          unmovedQCs = qcs.slice(0, removedQCPosition),
          qcsToEdit = qcs.slice(removedQCPosition + 1),
          editedQCs = withOrdering ? qcsToEdit.map(function (qc) {
        qc.set('position', qc.get('position') - 1);
        return qc;
      }) : qcsToEdit;

      this.set('model', unmovedQCs.concat(editedQCs));
    },

    saveCategoriesOrder: function saveCategoriesOrder() {
      var qc = this.get('categories');
      this.get('store').createRecord('reorder-question-category', {
        qc_id_pos: qc.map(function (q) {
          return { qc_id: q.id, position: q.get('position') };
        }),
        position: -1
      }).save();
    },

    deleteCategoryCallback: function deleteCategoryCallback(position) {
      this.get('store').createRecord('reorder-question-category', { qc_id_pos: [], position: position }).save();
    },

    actions: {
      searchCategory: function searchCategory() {
        debounce(this, this._searchCategory, 700);
      },
      editCategoryManagement: function editCategoryManagement(category) {
        this.set('searchValue', '');
        this.send('modifyCategory', category);
      },

      updateCategoriesList: function updateCategoriesList(newOrderedCategories) {
        this.set('saveDisabled', false);
        this.set('model', newOrderedCategories);
      },

      saveCategories: function saveCategories() {
        if (!this.get('saveDisabled')) {
          this.set('saveDisabled', true);
          this.saveCategoriesOrder();
        }
      },

      createNewCategory: function createNewCategory() {
        this.set('searchValue', '');
        this.send('newCategory');
      },

      deleteCategory: function deleteCategory(category) {
        var _this3 = this;

        var withOrdering = category.get('position') !== null;
        this.resetQCPositions(category, withOrdering);
        if (withOrdering) {
          this.deleteCategoryCallback(category.get('position'));
        }
        category.destroyRecord().then(function () {
          if (_this3.get('model.length') === 1) {
            _this3.set('searchValue', '');
            _this3._searchCategory();
          }
        });
      },

      reloadCategories: function reloadCategories() {
        this.set('isLoading', true);
        _ember['default'].run.debounce(this, this.updateScroll, 200);
      },

      resetScrollSteps: function resetScrollSteps() {
        this.set('currentStep', 1);
      }
    }
  });
});