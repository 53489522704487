define('due-dashboard/components/v2/libraries/surveys/navbar/elements/-long-text-input', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content-element-rating'],
    focus: false,
    cachedValues: {},

    init: function init() {
      var _this = this;

      var content = this.get('object.content');
      Object.keys(content).map(function (key) {
        return _this.cachedValues[key] = content[key];
      });
      this._super.apply(this, arguments);
    },

    hasDefaultValueObj: computed('object.content.default_value', function () {
      var content = this.get('object.content');

      return content.default_value != null;
    }),

    actions: {
      onChangeContentField: function onChangeContentField() {
        var saveCallback = this.get('contentUpdateCallback');
        var content = this.get('object.content');

        if (saveCallback) {
          saveCallback(content);
        }
      },

      toggleContentField: function toggleContentField(field) {
        var content = this.get('object.content');

        set(content, field, !content[field]);
        this.send('onChangeContentField');
      },

      updateContentField: function updateContentField(field, value) {
        var content = this.get('object.content');
        var focus = this.get('focus');

        if (!focus && value !== -1) this.set('focus', true);
        if (value !== -1) {
          var valueChanged = this.cachedValues[field] && !(this.cachedValues[field] === value);
          this.cachedValues[field] = value;
          set(content, field, value);
          if (valueChanged) this.send('onChangeContentField');
        }
        if (value === -1 && this.get('focus')) this.set('focus', false);
      },

      updateDefaultValue: function updateDefaultValue() {
        var content = this.get('object.content');
        var hasDefaultValueObj = this.get('hasDefaultValueObj');
        var defaultValue = { from: 'url_param', url_param: '' };

        if (hasDefaultValueObj) {
          set(content, 'default_value', null);
        } else {
          set(content, 'default_value', defaultValue);
        }

        this.send('onChangeContentField');
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/libraries/surveys/navbar/elements/-short-text-input}} short text input element config component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */