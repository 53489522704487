define('due-dashboard/controllers/settings/integrations/google-my-business', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var service = _ember['default'].inject.service;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({
    google_it: alias('model.company_integration'),
    currentAccount: service(),
    hideSave: true,

    google_auth: computed(function () {
      return _dueDashboardConfigEnvironment['default']['EmberENV']['GOOGLE_CONNECT_URL'] + '/' + this.get('currentAccount.content.company.id');
    })

  });
});