define("due-dashboard/templates/components/question-detail-item", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 10
              },
              "end": {
                "line": 18,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/components/question-detail-item.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "c3-tooltip-container question-detail-hover");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("table");
            dom.setAttribute(el2, "class", "c3-tooltip");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tbody");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            dom.setAttribute(el4, "class", "c3-tooltip-name--promoter");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "name");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "style", "background-color:#2fcc76");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "value");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("% (");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(")");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1, 1, 1, 1]);
            var element11 = dom.childAt(element10, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element11, 0, 0);
            morphs[2] = dom.createMorphAt(element11, 2, 2);
            return morphs;
          },
          statements: [["inline", "t", ["overview.chart.legend.promoters"], [], ["loc", [null, [12, 83], [12, 122]]], 0, 0], ["content", "values.promoter.percent", ["loc", [null, [13, 38], [13, 65]]], 0, 0, 0, 0], ["content", "values.promoter.value", ["loc", [null, [13, 68], [13, 93]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 25,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/components/question-detail-item.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "note-graph progress-bar promoter");
          dom.setAttribute(el1, "role", "progressbar");
          dom.setAttribute(el1, "aria-valuemin", "0");
          dom.setAttribute(el1, "aria-valuemax", "100");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("% (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "on-mobile");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "/assets/images/icons/promoter.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("% (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var element13 = dom.childAt(element12, [3]);
          var element14 = dom.childAt(fragment, [3, 3]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element12, 'aria-valuenow');
          morphs[1] = dom.createUnsafeAttrMorph(element12, 'style');
          morphs[2] = dom.createMorphAt(element12, 1, 1);
          morphs[3] = dom.createMorphAt(element13, 0, 0);
          morphs[4] = dom.createMorphAt(element13, 2, 2);
          morphs[5] = dom.createMorphAt(element14, 0, 0);
          morphs[6] = dom.createMorphAt(element14, 2, 2);
          return morphs;
        },
        statements: [["attribute", "aria-valuenow", ["get", "values.promoter.percent", ["loc", [null, [6, 89], [6, 112]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["get", "values.promoter.barStyle", ["loc", [null, [6, 162], [6, 186]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "displayOnHover", ["loc", [null, [7, 16], [7, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 10], [18, 17]]]], ["content", "values.promoter.percent", ["loc", [null, [19, 29], [19, 56]]], 0, 0, 0, 0], ["content", "values.promoter.value", ["loc", [null, [19, 59], [19, 84]]], 0, 0, 0, 0], ["content", "values.promoter.percent", ["loc", [null, [23, 29], [23, 56]]], 0, 0, 0, 0], ["content", "values.promoter.value", ["loc", [null, [23, 59], [23, 84]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 10
              },
              "end": {
                "line": 39,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/components/question-detail-item.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "c3-tooltip-container question-detail-hover");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("table");
            dom.setAttribute(el2, "class", "c3-tooltip");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tbody");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            dom.setAttribute(el4, "class", "c3-tooltip-name--passive");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "name");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "style", "background-color:#23afcb");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "value");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("% (");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(")");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1, 1, 1, 1]);
            var element6 = dom.childAt(element5, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element6, 0, 0);
            morphs[2] = dom.createMorphAt(element6, 2, 2);
            return morphs;
          },
          statements: [["inline", "t", ["overview.chart.legend.passives"], [], ["loc", [null, [33, 83], [33, 121]]], 0, 0], ["content", "values.passive.percent", ["loc", [null, [34, 38], [34, 64]]], 0, 0, 0, 0], ["content", "values.passive.value", ["loc", [null, [34, 67], [34, 91]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 6
            },
            "end": {
              "line": 46,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/components/question-detail-item.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "note-graph progress-bar passive");
          dom.setAttribute(el1, "role", "progressbar");
          dom.setAttribute(el1, "aria-valuemin", "0");
          dom.setAttribute(el1, "aria-valuemax", "100");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("% (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "on-mobile");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "/assets/images/icons/passive.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("% (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [3]);
          var element9 = dom.childAt(fragment, [3, 3]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element7, 'aria-valuenow');
          morphs[1] = dom.createUnsafeAttrMorph(element7, 'style');
          morphs[2] = dom.createMorphAt(element7, 1, 1);
          morphs[3] = dom.createMorphAt(element8, 0, 0);
          morphs[4] = dom.createMorphAt(element8, 2, 2);
          morphs[5] = dom.createMorphAt(element9, 0, 0);
          morphs[6] = dom.createMorphAt(element9, 2, 2);
          return morphs;
        },
        statements: [["attribute", "aria-valuenow", ["get", "values.passive.percent", ["loc", [null, [27, 88], [27, 110]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["get", "values.passive.barStyle", ["loc", [null, [27, 160], [27, 183]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "displayOnHover", ["loc", [null, [28, 16], [28, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [28, 10], [39, 17]]]], ["content", "values.passive.percent", ["loc", [null, [40, 29], [40, 55]]], 0, 0, 0, 0], ["content", "values.passive.value", ["loc", [null, [40, 58], [40, 82]]], 0, 0, 0, 0], ["content", "values.passive.percent", ["loc", [null, [44, 29], [44, 55]]], 0, 0, 0, 0], ["content", "values.passive.value", ["loc", [null, [44, 58], [44, 82]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 10
              },
              "end": {
                "line": 60,
                "column": 10
              }
            },
            "moduleName": "due-dashboard/templates/components/question-detail-item.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "c3-tooltip-container question-detail-hover");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("table");
            dom.setAttribute(el2, "class", "c3-tooltip");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tbody");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            dom.setAttribute(el4, "class", "c3-tooltip-name--detractor");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "name");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "style", "background-color:#ee6352");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "value");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("% (");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(")");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1, 1, 1]);
            var element1 = dom.childAt(element0, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element1, 0, 0);
            morphs[2] = dom.createMorphAt(element1, 2, 2);
            return morphs;
          },
          statements: [["inline", "t", ["overview.chart.legend.detractors"], [], ["loc", [null, [54, 83], [54, 123]]], 0, 0], ["content", "values.detractor.percent", ["loc", [null, [55, 38], [55, 66]]], 0, 0, 0, 0], ["content", "values.detractor.value", ["loc", [null, [55, 69], [55, 95]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 6
            },
            "end": {
              "line": 67,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/components/question-detail-item.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "note-graph progress-bar detractor");
          dom.setAttribute(el1, "role", "progressbar");
          dom.setAttribute(el1, "aria-valuenow", "100");
          dom.setAttribute(el1, "aria-valuemin", "0");
          dom.setAttribute(el1, "aria-valuemax", "100");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("% (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "on-mobile");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "/assets/images/icons/detractor.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("% (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [3]);
          var element4 = dom.childAt(fragment, [3, 3]);
          var morphs = new Array(6);
          morphs[0] = dom.createUnsafeAttrMorph(element2, 'style');
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          morphs[2] = dom.createMorphAt(element3, 0, 0);
          morphs[3] = dom.createMorphAt(element3, 2, 2);
          morphs[4] = dom.createMorphAt(element4, 0, 0);
          morphs[5] = dom.createMorphAt(element4, 2, 2);
          return morphs;
        },
        statements: [["attribute", "style", ["get", "values.detractor.barStyle", ["loc", [null, [48, 141], [48, 166]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "displayOnHover", ["loc", [null, [49, 16], [49, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [49, 10], [60, 17]]]], ["content", "values.detractor.percent", ["loc", [null, [61, 29], [61, 57]]], 0, 0, 0, 0], ["content", "values.detractor.value", ["loc", [null, [61, 60], [61, 86]]], 0, 0, 0, 0], ["content", "values.detractor.percent", ["loc", [null, [65, 29], [65, 57]]], 0, 0, 0, 0], ["content", "values.detractor.value", ["loc", [null, [65, 60], [65, 86]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 71,
            "column": 115
          }
        },
        "moduleName": "due-dashboard/templates/components/question-detail-item.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "question-detail");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "note-score");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" / 10");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" - ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "note-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "note-container");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "quantity");
        var el2 = dom.createElement("span");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [0]);
        var element16 = dom.childAt(element15, [1]);
        var element17 = dom.childAt(element15, [3, 1]);
        var element18 = dom.childAt(fragment, [2]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element16, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(element16, 2, 2);
        morphs[2] = dom.createMorphAt(element17, 1, 1);
        morphs[3] = dom.createMorphAt(element17, 2, 2);
        morphs[4] = dom.createMorphAt(element17, 3, 3);
        morphs[5] = dom.createAttrMorph(element18, 'data-th');
        morphs[6] = dom.createMorphAt(dom.childAt(element18, [0]), 0, 0);
        return morphs;
      },
      statements: [["content", "precisionValue", ["loc", [null, [2, 31], [2, 49]]], 0, 0, 0, 0], ["content", "question.name", ["loc", [null, [2, 64], [2, 81]]], 0, 0, 0, 0], ["block", "if", [["get", "values.promoter.value", ["loc", [null, [5, 12], [5, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 6], [25, 13]]]], ["block", "if", [["get", "values.passive.value", ["loc", [null, [26, 12], [26, 32]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [26, 6], [46, 13]]]], ["block", "if", [["get", "values.detractor.value", ["loc", [null, [47, 12], [47, 34]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [47, 6], [67, 13]]]], ["attribute", "data-th", ["subexpr", "t", ["questions.list.total_answers"], [], ["loc", [null, [null, null], [71, 49]]], 0, 0], 0, 0, 0, 0], ["content", "question.number_of_choices", ["loc", [null, [71, 73], [71, 103]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});