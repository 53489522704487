define('due-dashboard/routes/settings/integrations/slack', ['exports', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({

    model: function model() {
      var _this = this;

      var p_model = this._super.apply(this, arguments);
      p_model.then(function (model) {
        model.channels = _this.store.findAll('slack-company-channel');
      });
      return p_model;
    }

  });
});