define('due-dashboard/components/translation-button', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['translation-button'],
    classNameBindings: ['isBlue:is-blue'],
    isBlue: false,

    showTranslated: computed('translationKey', function () {
      var options = ['original_text', 'hide', 'see', 'hide_original', 'show_original'];
      return options.includes(this.get('translationKey'));
    })
  });
});