define('due-dashboard/models/zendesk-trigger', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    name: attr(),
    description: attr(),
    conditions: attr(),
    actions: attr(),
    target_title: attr(),
    target_url: attr(),
    segments: attr(),
    survey: attr(),
    template: attr(),
    active: attr()
  });
});