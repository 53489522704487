define("due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/-prev-next-btns", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/-prev-next-btns.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "labeled-input-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "due-typo-h6");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "labeled-input-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "due-typo-h6");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["words.previous_button_label"], [], ["loc", [null, [2, 28], [2, 63]]], 0, 0], ["inline", "component", ["v2/-editable-label"], ["value", ["subexpr", "@mut", [["get", "object.content.previous_button_text", ["loc", [null, [4, 10], [4, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["updateContentField", "previous_button_text"], [], ["loc", [null, [5, 13], [5, 65]]], 0, 0], "clickOutsideCallback", ["subexpr", "action", ["updateContentField", "previous_button_text", -1], [], ["loc", [null, [6, 25], [6, 80]]], 0, 0], "placeholder", ["subexpr", "t", ["words.label_previous"], [], ["loc", [null, [7, 16], [7, 42]]], 0, 0], "inputType", "text", "alwaysInput", true], ["loc", [null, [3, 2], [10, 4]]], 0, 0], ["inline", "t", ["words.next_button_label"], [], ["loc", [null, [13, 28], [13, 59]]], 0, 0], ["inline", "component", ["v2/-editable-label"], ["value", ["subexpr", "@mut", [["get", "object.content.next_button_text", ["loc", [null, [15, 10], [15, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["updateContentField", "next_button_text"], [], ["loc", [null, [16, 13], [16, 61]]], 0, 0], "clickOutsideCallback", ["subexpr", "action", ["updateContentField", "next_button_text", -1], [], ["loc", [null, [17, 25], [17, 76]]], 0, 0], "placeholder", ["subexpr", "t", ["words.label_next"], [], ["loc", [null, [18, 16], [18, 38]]], 0, 0], "inputType", "text", "alwaysInput", true], ["loc", [null, [14, 2], [21, 4]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});