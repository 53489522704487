define('due-dashboard/serializers/scenario', ['exports', 'ember-data', 'due-dashboard/mixins/embedded-jsonapi-records'], function (exports, _emberData, _dueDashboardMixinsEmbeddedJsonapiRecords) {
  var JSONAPISerializer = _emberData['default'].JSONAPISerializer;
  exports['default'] = JSONAPISerializer.extend(_dueDashboardMixinsEmbeddedJsonapiRecords['default'], {
    attrs: {
      scenario_conditions: { embedded: 'always' },
      scenario_actions: { embedded: 'always' },
      company: { serialize: 'ids-and-type' }
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      delete json.data.attributes['category_hook_key'];
      return json;
    }
  });
});