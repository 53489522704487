define('due-dashboard/components/aircall-filter', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['aircall-filter'],

    actions: {
      deleteFilter: function deleteFilter() {
        this.model.deleteRecord();
      }
    }
  });
});