define('due-dashboard/initializers/model-name', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = {
    name: 'model-Name',
    initialize: function initialize() {
      _emberData['default'].Model.reopen({
        modelName: _ember['default'].computed(function () {
          return this.get('constructor.modelName');
        })
      });
    }
  };
});