define('due-dashboard/components/digests/parts/sort-by-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    selectedOption: computed('sortOptions', 'comp.options', 'comp.options.sort_by', function () {
      return this.get('comp.options.sort_by') ? this.get('comp.options.sort_by') : this.get('sortOptions')[0];
    }),

    actions: {
      changeSort: function changeSort(sort) {
        this.set('comp.options.sort_by', sort);
        this.get('saveDigestDelay')();
      }
    }
  });
});