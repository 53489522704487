define('due-dashboard/helpers/deep-freeze', ['exports'], function (exports) {
  exports['default'] = deepFreeze;

  function deepFreeze(object) {
    if (typeof object === 'object' && object !== null) {
      Object.values(object).forEach(deepFreeze);
    }
    return Object.freeze(object);
  }

  ;
});