define('due-dashboard/routes/settings/integrations/shopify', ['exports', 'due-dashboard/config/environment', 'due-dashboard/routes/settings/integrations/integration-parent'], function (exports, _dueDashboardConfigEnvironment, _dueDashboardRoutesSettingsIntegrationsIntegrationParent) {
  exports['default'] = _dueDashboardRoutesSettingsIntegrationsIntegrationParent['default'].extend({

    includeSurveys: 'post-order-survey',

    actions: {
      createShopifyIntegration: function createShopifyIntegration() {
        var url = _dueDashboardConfigEnvironment['default'].EmberENV.SHOPIFY_CONNECT_URL + '?state=' + this.currentModel.company.get('id') + ('&shop_name=' + this.currentModel.company_integration.get('company_shopify_url')) + ('&survey=' + this.currentModel.company_integration.get('post_order_survey.id'));
        window.location.replace(url);
      }

    }
  });
});