define('due-dashboard/components/reports/-single-kpi-v2', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set', 'due-dashboard/helpers/thousand-separator', 'due-dashboard/helpers/compute-duration', 'due-dashboard/helpers/current-timezone', 'moment'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet, _dueDashboardHelpersThousandSeparator, _dueDashboardHelpersComputeDuration, _dueDashboardHelpersCurrentTimezone, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var service = _ember['default'].inject.service;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['single-kpi-v2'],
    classNameBindings: ['layout', 'v_layout'],

    /* ### Services props ### */
    globalFilters: service('feedback-search/global'),
    store: service(),

    /* ### Private props ### */
    isLoading: true,
    isEmpty: false,

    /* ### Title props ### */
    title: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].title;
    }),

    /* ### Information popup props ### */
    informationTitle: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].information.title;
    }),

    informationText: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].information.text;
    }),

    /* ### Total props ### */
    totalType: 'count',
    main_rate: false,

    displayTotal: computed('content_data', function () {
      var attrs = this.get('content.component').properties[0].attrs;
      return attrs.includes('total') || attrs.includes('rate');
    }),

    displayVariation: computed('content_data', function () {
      return this.get('content_data.variation') != undefined;
    }),

    variation: computed('content_data', function () {
      return { variation: this.get('content_data.attrs.variation') };
    }),

    variationType: computed('content_data', function () {
      var variation_type = this.get('content_data.variation');
      return variation_type || 'percent';
    }),

    variationPeriod: computed('content_data', function () {
      return this.get('i18n').t('words.' + this.get('content_data.variation_period'));
    }),

    total: computed('content_data', 'displayTotal', function () {
      var content_data = this.get('content_data'),
          displayTotal = this.get('displayTotal');
      if (displayTotal && content_data && content_data.attrs) {
        var total_type = content_data.type;
        if (content_data.format === 'duration') {
          this.set('fontSize', '24px');
          return _dueDashboardHelpersComputeDuration['default'].compute(this.get('i18n'), content_data.attrs.total);
        }
        if (total_type === 'main_rate') {
          this.set('main_rate', true);
          total_type = 'rate';
        } else {
          this.set('main_rate', false);
        }
        this.set('totalType', total_type);
        if (this.get('main_rate') && content_data.attrs.rate) {
          return _dueDashboardHelpersThousandSeparator['default'].compute(content_data.attrs.rate);
        }
        return _dueDashboardHelpersThousandSeparator['default'].compute(content_data.attrs.rate || content_data.attrs.total || 0);
      }
    }),

    missingTotal: computed('content_data', function () {
      var propTotal = this.get('content.component').properties[0].attrs.includes('total'),
          contentTotal = this.get('content_data') && this.get('content_data').hasOwnProperty('attrs') && this.get('content_data').attrs.total !== null;

      return propTotal && !contentTotal;
    }),

    displayTotalMore: computed('content_data', function () {
      if (this.get('content_data.type') == 'main_rate') {
        return false;
      }
      return this.get('content.component').properties[0].attrs.includes('rate');
    }),

    totalMore: computed('content_data', 'displayTotalMore', function () {
      var content_data = this.get('content_data'),
          displayTotalMore = this.get('displayTotalMore');

      if (displayTotalMore && content_data && content_data.attrs && Object.keys(content_data.attrs).includes('total') && Object.keys(content_data.attrs).includes('rate')) {
        var total = content_data.attrs.total;
        if (total == undefined) {
          return '-';
        } else {
          return content_data.display_zero ? total : total || '-';
        }
      }
    }),

    init: function init() {
      this._super();
      this._queryData();
    },

    reloadObserver: observer('reload', function () {
      this._queryData();
    }),

    _queryData: function _queryData() {
      var _this = this;

      this.set('isLoading', true);

      // Get all filters and data from component & get global filters
      var global_filters = this.get('globalFilters.current'),
          report_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('reportFilters')),
          component_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('content.component.filters')),
          dataQueryFilters = global_filters.merge(report_filters, component_filters).toDataQueryFormat(),
          dataQuery = this.get('store').createRecord('data-query'),
          dateRange = {
        from: '',
        to: ''
      };

      // Set date_range if present in filters
      if (dataQueryFilters.start_date && dataQueryFilters.end_date) {
        dateRange.from = (0, _moment['default'])(dataQueryFilters.start_date[0][0]).format().split('T')[0];
        dateRange.to = (0, _moment['default'])(dataQueryFilters.end_date[0][0]).format().split('T')[0];
      } else {
        // Set past 30 days if not in fitlers
        var date = new Date();
        dateRange.to = (0, _moment['default'])(Date.now()).format().split('T')[0];
        date.setDate(date.getDate() - 30);
        dateRange.from = (0, _moment['default'])(date).format().split('T')[0];
      }

      // Remove date range from filters
      dataQuery.set('date_range', dateRange);
      delete dataQueryFilters.start_date;
      delete dataQueryFilters.end_date;

      dataQuery.set('filters', dataQueryFilters);
      dataQuery.set('properties', this.get('content.component.properties'));
      dataQuery.set('object', 'single-kpi');
      dataQuery.set('tz', _dueDashboardHelpersCurrentTimezone['default'].compute());

      if (this.get('canView')) {
        dataQuery.save().then(function (response) {
          _this.set('content_data', response.get('content_data')[0]);
          _this.set('isLoading', false);
          _this.set('isEmpty', false);
        })['catch'](function (error) {
          _this.set('isLoading', false);
          _this.set('isEmpty', true);
        });
      } else {
        this.set('isEmpty', true);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{single-kpi}} component displaying a main value w/ title + trend indicator + evolution graph
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */