define('due-dashboard/components/reports/array/-positive-impact', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-positive-impact'],

    hideValue: computed('content', function () {
      var component = this.get('content.component');

      return component.config.positive_impact && component.config.positive_impact.hideValue;
    }),

    compValue: computed('value', function () {
      var value = this.get('value');

      if (value.total == null) {
        value.total = '-';
      }

      return value;
    })
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{reports/array/-positive-impact}} positive-impact column used by {{reports/-array}}
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */