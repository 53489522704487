define('due-dashboard/components/v2/-single-select', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-single-select-v2'],
    dropdownIsOpen: false,

    selectedValue: computed('selected', 'displayKey', function () {
      var selected = this.get('selected');
      return selected ? selected[this.get('displayKey')] : null;
    }),

    actions: {
      toggleDropdownOpen: function toggleDropdownOpen() {
        var disabled = this.get('disabled');
        if (!disabled) this.toggleProperty('dropdownIsOpen');
      },

      onSelect: function onSelect(option) {
        var callback = this.get('onSelect');
        if (callback) callback(option);
        this.send('toggleDropdownOpen');
      }
    }
  });
});