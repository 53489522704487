define('due-dashboard/controllers/forgotten-password', ['exports', 'ember', 'due-dashboard/helpers/email-validator'], function (exports, _ember, _dueDashboardHelpersEmailValidator) {
  exports['default'] = _ember['default'].Controller.extend({
    minError: false,

    disableButton: _ember['default'].computed('model', 'model.email', function () {
      return !_dueDashboardHelpersEmailValidator['default'].compute(this.get('model.email'));
    }),

    actions: {
      minifyError: function minifyError() {
        this.set('minError', true);
      },

      backToLogin: function backToLogin() {
        this.set('displayInboxMessage', false);
        this.transitionToRoute('sign-in');
      },

      displayInputField: function displayInputField() {
        this.set('displayInboxMessage', false);
      },

      sendRecoveryEmail: function sendRecoveryEmail() {
        var _this = this;

        var email = this.get('model.email');
        this.set('error_message', null);
        this.set('promise', this.get('model').save()).then(function () {
          _this.set('model', _this.store.createRecord('password-reset', {}));
          _this.set('model.email', email);
          _this.set('displayInboxMessage', true);
        })['catch'](function () {
          _this.set('displayInboxMessage', true);
        });
      }
    }
  });
});