define("due-dashboard/templates/components/v2/-navigator", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/-navigator.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "due-btn", [], ["icon", ["subexpr", "concat", ["tio-", ["get", "leftIcon", ["loc", [null, [2, 22], [2, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 7], [2, 31]]], 0, 0], "size", "small", "intent", "secondary", "disabled", ["subexpr", "@mut", [["get", "leftButtonDisabled", ["loc", [null, [5, 11], [5, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", ["leftAction"], [], ["loc", [null, [6, 9], [6, 30]]], 0, 0]], ["loc", [null, [1, 0], [7, 2]]], 0, 0], ["content", "yield", ["loc", [null, [8, 2], [8, 11]]], 0, 0, 0, 0], ["inline", "due-btn", [], ["icon", ["subexpr", "concat", ["tio-", ["get", "rightIcon", ["loc", [null, [10, 22], [10, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 7], [10, 32]]], 0, 0], "size", "small", "intent", "secondary", "disabled", ["subexpr", "@mut", [["get", "rightButtonDisabled", ["loc", [null, [13, 11], [13, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", ["rightAction"], [], ["loc", [null, [14, 9], [14, 31]]], 0, 0]], ["loc", [null, [9, 0], [15, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});