define('due-dashboard/routes/companies/scenarios/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    keyManager: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('buildingStep', 1);
      controller.set('actionCategoryHookKey', model.get('dasherizedHookKey'));
    },

    model: function model(params) {
      return this.get('store').findRecord('scenario', params.scenario_id);
    },

    afterModel: function afterModel(scenario) {
      scenario.defineSavepoint();
      return this._super.apply(this, arguments);
    },

    actions: {

      // didTransition() {
      // const enterEvent = this.get('keyManager').addMacro({
      //   executionKey: 'enter',
      //   callback: Ember.run.bind(this, function() {
      //     this.send('sendNextStep');
      //   }),
      //   keyEvent: 'keydown',
      //   priority: 10,
      // });
      // this.set('enterEvent', enterEvent);
      // const backspaceEvent = this.get('keyManager').addMacro({
      //   executionKey: 'backspace',
      //   callback: Ember.run.bind(this, function() {
      //     this.send('sendPreviousStep');
      //   }),
      //   keyEvent: 'keydown',
      //   priority: 10,
      // });
      // this.set('backspaceEvent', backspaceEvent);
      // },

      // willTransition() {
      //   const kM = this.get('keyManager');
      //   ['enterEvent', 'backspaceEvent'].forEach(registered => kM.removeMacro(this.get(registered)));
      //   return this._super(...arguments);
      // },

    }

  });
});