define('due-dashboard/initializers/setup-current-account', ['exports'], function (exports) {
  exports['default'] = {

    initialize: function initialize(application) {
      application.inject('route', 'currentAccount', 'service:current-account');
      application.inject('controller', 'currentAccount', 'service:current-account');
      application.inject('component', 'currentAccount', 'service:current-account');
      application.inject('view', 'currentAccount', 'service:current-account');
    }

  };
});