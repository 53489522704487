define('due-dashboard/components/digests/parts/send-test-email-part', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Component.extend({
    filteredSubscriptions: [],
    searchInput: '',
    pageCounter: 2,
    filterPageCounter: 2,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // TO REMOVE
    },

    periods: ['previous_day', 'previous_week', 'previous_month', 'previous_quarter', 'previous_semester', 'previous_year', 'current_day', 'current_week', 'current_month', 'current_quarter', 'current_semester', 'current_year'],

    account: computed('accounts', function () {
      if (this.get('accounts')) {
        return this.get('accounts')[0].get('account');
      }
    }),

    selectedAccounts: computed('accounts.[]', 'filteredAccounts', function () {
      var accounts = this.get('accounts');
      return this.get('searchInput.length') === 0 ? accounts.map(function (acc) {
        return acc.get('account');
      }) : this.get('filteredAccounts').map(function (acc) {
        return acc.get('account');
      });
    }),

    displayWarning: computed('subscriptions', function () {
      if (!this.get('subscriptions').length) {
        return true;
      }
      return false;
    }),

    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    disabled: computed('account', 'sendingTo', function () {
      if (this.get('sendingTo') && this.get('sendingTo').length > 0 && this.get('account')) {
        return false;
      }
      return true;
    }),

    filterSubscriptions: function filterSubscriptions() {
      var _this = this;

      var term = this.get('searchInput'),
          filters = { filter: { due_digest_id: this.get('dueDigestId'), email: term, live: false }, include: 'account' };
      var filteredAccounts = this.get('filteredAccounts'),
          subscriptions = this.get('accounts');
      filters.page = { number: this.get(term.length ? 'filterPageCounter' : 'pageCounter'), size: 50 };
      this.set('isLoading', true);
      var promise = this.get('store').query('due_digest_subscription', filters);
      this.set('loadMorePromise', promise);
      promise.then(function (results) {
        if (_this.get('searchInput.length') > 0) {
          _this.set('filterPageCounter', _this.get('filterPageCounter') + 1);
          filteredAccounts = [].concat(_toConsumableArray(filteredAccounts), _toConsumableArray(results.toArray()));
          _this.set('filteredAccounts', filteredAccounts);
        } else {
          _this.set('pageCounter', _this.get('pageCounter') + 1);
          subscriptions = [].concat(_toConsumableArray(subscriptions), _toConsumableArray(results.toArray()));
          _this.set('accounts', subscriptions);
          _this.set('filteredAccounts', []);
        }
        _this.set('isLoading', false);
      });
    },

    actions: {

      openModal: function openModal() {
        if (this.get('selectedPeriod')) {
          this.set('timeframe', this.get('selectedPeriod'));
        } else {
          this.set('timeframe', this.get('period'));
        }
        this.set('modalIsOpened', true);
      },

      closeModal: function closeModal() {
        this.set('modalIsOpened', false);
      },

      changeAccount: function changeAccount(account) {
        this.set('account', account);
      },

      checkEmail: function checkEmail(email) {
        if (!this.validateEmail(email)) {
          return "invalid_format";
        }
        return null;
      },

      sendEmailTest: function sendEmailTest() {
        var sendingTo = this.get('sendingTo').join();
        var testEmail = this.get('store').createRecord('send-test-email', {
          send_to: sendingTo,
          account_id: this.get('account.id'),
          due_digest_id: this.get('dueDigestId'),
          timeframe: this.get('timeframe'),
          id: Date.now()
        });
        testEmail.save();
        this.set('modalIsOpened', false);
        this.set('selectedPeriod', this.get('timeframe'));
      },

      searchUsers: function searchUsers(value) {
        var _this2 = this;

        this.set('searchInput', value || '');
        this.set('filteredAccounts', []);
        this.set('isLoading', true);
        if (value && value.length > 0) {
          this.set('filterPageCounter', 2);
          var promise = this.get('store').query('due-digest-subscription', {
            filter: { due_digest_id: this.get('dueDigestId'), email: value }
          });
          this.set('searchPromise', promise);
          promise.then(function (subs) {
            _this2.set('filteredAccounts', subs.toArray());
            _this2.set('isLoading', false);
          });
        }
      },

      loadMoreUsers: function loadMoreUsers() {
        debounce(this, this.filterSubscriptions, 600);
      }

    }
  });
});