define('due-dashboard/components/due-tooltip', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      var content = '\n      <div style="display: flex; flex-direction: column; min-width: 260px; overflow: hidden; padding: 10px;">\n        <div style="width: 100%; font-weight: 600; margin-bottom: 10px; font-size: 14px;">' + this.get('title') + '</div>\n        <div style="width: 100%; color: #71899c; font-size: 12px;">' + this.get('text') + '</div>\n      </div>\n    ';

      $(this.element).prev('div').tooltipster({
        content: content,
        contentAsHTML: true,
        animation: 'fade',
        delay: 300,
        speed: 100,
        hideOnClick: false,
        trigger: 'hover',
        autoClose: true,
        position: 'top',
        arrow: false
      });
    }

  });
});