define("due-dashboard/transforms/object", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
  exports["default"] = _emberData["default"].Transform.extend({
    deserialize: function deserialize(serialized) {
      return _ember["default"].isBlank(serialized) ? {} : serialized;
    },

    serialize: function serialize(deserialized) {
      return _ember["default"].isBlank(deserialized) ? {} : deserialized;
    }
  });
});