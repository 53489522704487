define('due-dashboard/components/match-surveys-scenario-condition', ['exports', 'ember', 'due-dashboard/components/scenario-condition-item'], function (exports, _ember, _dueDashboardComponentsScenarioConditionItem) {
  var _Ember$computed = _ember['default'].computed;
  var setDiff = _Ember$computed.setDiff;
  var filterBy = _Ember$computed.filterBy;
  exports['default'] = _dueDashboardComponentsScenarioConditionItem['default'].extend({
    surveys: [],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.attrs.scenarioStore.value.findAll('survey').then(function (surveys) {
        _this.set('surveys', surveys);
      });
    },

    liveSurveys: filterBy('surveys', 'status', 'live'),

    remainingSurveys: setDiff('liveSurveys', 'condition.condition_surveys'),

    _searchSurvey: function _searchSurvey(term, resolve, reject) {
      this.store.query('survey', {
        filter: {
          name: term
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (surveys) {
        return resolve(surveys);
      }, reject);
    },

    actions: {
      selectSurveys: function selectSurveys(surveys) {
        this.get('condition').updateSurveys(surveys);
      },

      searchSurveys: function searchSurveys(term) {
        var _this2 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this2, _this2._searchSurvey, term, resolve, reject, 600);
        });
      }
    }

  });
});