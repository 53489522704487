define('due-dashboard/models/graph-line', ['exports', 'ember-data-model-fragments', 'ember-data', 'ember'], function (exports, _emberDataModelFragments, _emberData, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberDataModelFragments['default'].Fragment.extend({
    name: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string'),
    'chart-type': _emberData['default'].attr('string', { defaultValue: 'spline' }),
    color: _emberData['default'].attr('string', { defaultValue: '#f48fb1' }),
    filters: _emberDataModelFragments['default'].fragmentArray('graph-line-filter'),

    segmentFilters: computed('filters.@each.{segment,values}', function () {
      return this.get('filters').map(function (f) {
        var segment_id = f.get('segment.oid'),
            values = f.get('values');

        if (segment_id && values.length > 0) {
          return {
            segment_id: segment_id,
            values: values
          };
        }
      }).compact();
    })
  });
});