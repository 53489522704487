define('due-dashboard/components/pagination-block', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    classNames: ['pagination-block'],

    tmpCurrentPage: 1,
    minCurrentPage: 1,

    totalPages: computed('total_pages', function () {
      return this.get('total_pages') || 1;
    }),

    updateCurrentPage: observer('currentPage', function () {
      if ($('.feedback-sidebar-list')[0]) {
        window.scroll($('.feedback-sidebar-list')[0].offsetTop, 0);
      }
      return this.set('tmpCurrentPage', this.get('currentPage'));
    }).on('init'),

    hasPreviousPage: computed('currentPage', 'total_pages', function () {
      return this.get('currentPage') > 1;
    }),

    hasNextPage: computed('currentPage', 'total_pages', function () {
      return this.get('currentPage') < this.get('total_pages');
    }),

    canChange: function canChange(page) {
      if (this.get('currentPage') === page) {
        return false;
      } else if (page > this.get('total_pages') || page < 1) {
        this.set('tmpCurrentPage', this.get('currentPage'));
        return false;
      }
      return true;
    },

    actions: {
      setCurrentPage: function setCurrentPage(page) {
        if (this.canChange(page)) {
          this.set('currentPage', page);
        }
      },

      loadFirstPage: function loadFirstPage() {
        this.set('currentPage', this.get('minCurrentPage'));
      },

      loadLastPage: function loadLastPage() {
        this.set('currentPage', this.get('totalPages'));
      },

      loadNextPage: function loadNextPage() {
        if (this.canChange(parseInt(this.get('tmpCurrentPage')) + 1)) {
          this.incrementProperty('currentPage');
        }
      },

      loadPreviousPage: function loadPreviousPage() {
        if (this.canChange(parseInt(this.get('tmpCurrentPage')) - 1)) {
          this.decrementProperty('currentPage');
        }
      }
    }

  });
});