define('due-dashboard/components/dashboard-navbar', ['exports', 'ember', 'due-dashboard/helpers/get-text-color'], function (exports, _ember, _dueDashboardHelpersGetTextColor) {
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    sidebar: service(),
    session: service(),
    currentAccount: service(),

    elementId: 'dashboard-navbar',
    classNameBindings: ['sidebarClass', 'hasDashboardTheme', 'version'],
    attributeBindings: ['style'],

    loaded: false,

    sidebarClass: 'sidebar',
    showMyAccountDropdown: false,
    showPoweredBy: true,
    hasDashboardTheme: alias('currentAccount.content.company.has_dashboard_theme'),
    redirections: computed(function () {
      return [{ icon: "settings", page: "Settings" }, { icon: "sign_out", page: "Log out" }];
    }),
    customRights: alias('currentAccount.rights'),

    init: function init() {
      var _this = this;

      this._super();
      var setSidebarElements = function setSidebarElements(sidebar) {
        if (sidebar && sidebar.get('elements').length > 0) {
          _this.set('customFirstElement', sidebar.get('elements').find(function (element) {
            return element.route;
          }));
          _this.set('customSidebar', sidebar.get('elements'));
        }
        _this.set('loaded', true);
      };
      if (this.get('currentAccount.content.profile') != 'custom') {
        this.store.query('profile-sidebar', {
          filter: { profile: this.get('currentAccount.content.profile') }
        }).then(function (sidebars) {
          var sidebar = sidebars.get('firstObject');
          setSidebarElements(sidebar);
        });
      } else {
        Promise.resolve(this.get('currentAccount.content.account_custom_profile_right.sidebar')).then(function (sidebar) {
          setSidebarElements(sidebar);
        });
      }
    },

    backToDashboardDisplayed: computed('customFirstElement', 'currentAccount.content.account_custom_profile_right.sidebar', function () {
      var customFirstElement = this.get('customFirstElement');
      var profile = this.get('currentAccount.content.profile');
      var sidebarElements = this.get('currentAccount.content.account_custom_profile_right.sidebar.elements');
      var customFirstElementRoute = this.get('customFirstElement.route');
      if (!customFirstElement || profile != 'custom' || !(sidebarElements.length === 1 && customFirstElementRoute === 'settings.users')) {
        return true;
      }
      return false;
    }),

    sidebarLogo: computed('hasDashboardTheme', 'currentAccount.content.company.dashboard_logo', function () {
      var logo = this.get('currentAccount.content.company.dashboard_logo');
      if (this.get('hasDashboardTheme') && logo) {
        return logo;
      } else if (this.get('hasDashboardTheme') && this.get('sidebarBgColor') !== null && _dueDashboardHelpersGetTextColor['default'].compute([this.get('sidebarBgColor')]) === '#ffffff') {
        return '/assets/images/diduenjoy-mobile-logo-white-blue.png';
      }
      return '/assets/images/diduenjoy-mobile-logo-grey-blue.png';
    }),

    sidebarBgColor: computed('hasDashboardTheme', 'currentAccount.content.company.dashboard_bg_color', function () {
      var bgColor = this.get('currentAccount.content.company.dashboard_bg_color');
      if (this.get('hasDashboardTheme') && bgColor) {
        return bgColor;
      }
      return null;
    }),

    poweredByImage: computed('hasDashboardTheme', 'currentAccount.content.company.dashboard_logo', 'sidebarBgColor', function () {
      var logo = this.get('currentAccount.content.company.dashboard_logo');
      if (this.get('hasDashboardTheme') && logo) {
        this.set('showPoweredBy', true);
        if (this.get('sidebarBgColor') !== null && _dueDashboardHelpersGetTextColor['default'].compute([this.get('sidebarBgColor')]) === '#ffffff') {
          return '/assets/images/diduenjoy-mobile-logo-white-blue.png';
        } else {
          return '/assets/images/diduenjoy-mobile-logo-black-blue.png';
        }
      } else {
        this.set('showPoweredBy', false);
        return null;
      }
    }),

    style: computed('hasDashboardTheme', 'currentAccount.content.company.dashboard_banner', function () {
      if (this.get('hasDashboardTheme')) {
        return 'background-image: url(' + this.get('currentAccount.content.company.dashboard_banner') + ')';
      }
    }),

    textColor: computed('hasDashboardTheme', 'currentAccount.content.company.dashboard_menu_color', function () {
      var menuColor = this.get('currentAccount.content.company.dashboard_menu_color');
      if (this.get('hasDashboardTheme') && menuColor) {
        return htmlSafe(menuColor);
      }
      return htmlSafe('#4E677A');
    }),

    separatorColor: computed('hasDashboardTheme', 'currentAccount.content.company.dashboard_menu_color', function () {
      var menuColor = this.get('currentAccount.content.company.dashboard_menu_color');
      if (this.get('hasDashboardTheme') && menuColor) {
        return htmlSafe(menuColor);
      }
      return htmlSafe('#71899c');
    }),

    isDashboardLanguageFrench: computed('currentAccount.content.language.tag', function () {
      return this.get('currentAccount.content.language.tag') === "FR";
    }),

    displayHelpDeskSeparator: computed('currentAccount', 'customRights', function () {
      return !this.get('customRights') || this.get('customRights').get('chats_rights') !== 'none';
    }),

    displayContentSeparator: computed('currentAccount', 'customRights', function () {
      return !this.get('customRights') || this.get('customRights').get('scenarios_rights') === true || this.get('customRights').get('templates_rights') !== 'none' || this.get('customRights').get('surveys_rights') !== 'none';
    }),

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },

      toggleState: function toggleState() {
        this.toggleProperty('sidebar.opened');
      },

      clickOutside: function clickOutside() {
        if (this.get('sidebar.opened')) {
          this.get('sidebar').close();
        }
      }
    }
  });
});