define('due-dashboard/components/reports/-tag-breakdown', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set', 'moment', 'due-dashboard/helpers/current-timezone'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet, _moment, _dueDashboardHelpersCurrentTimezone) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-tag-breakdown'],
    classNameBindings: ['layout'],

    globalFilters: service('feedback-search/global'),

    isLoading: true,
    isEmpty: false,

    headers: computed('content', 'content_data', function () {
      var _this = this;

      var content = this.get('content'),
          nameColumn = { type: 'label-with-sub', headerLabel: content.component.config.translations[this.get('currentLanguage')].headers[0] },
          headers = { left: [nameColumn], right: [] };

      content.component.properties.forEach(function (column, index) {
        var headerObj = { type: column.type, headerLabel: content.component.config.translations[_this.get('currentLanguage')].headers[index + 1], main_column: content.component.properties[index].main_column };
        if (headerObj.main_column) {
          headers.left.push(headerObj);
        } else {
          headers.right.push(headerObj);
        }
      });
      return headers;
    }),

    emptyHeader: {
      left: [{ type: 'label-with-sub', component: 'text', width: 80 }],
      right: [{ type: 'label-with-sub', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'text', width: 80 }]
    },

    tagBreakdown: computed('content_data', function () {
      return this._computeTagBreakdown(this.get('content_data'));
    }),

    _computeTagBreakdown: function _computeTagBreakdown(flatBreakdown) {
      var _this2 = this;

      var res = [];

      if (flatBreakdown) {
        flatBreakdown.forEach(function (tag, _, array) {
          _this2._computeTags(array, tag, res);
        });
      }
      return res;
    },

    _computeTags: function _computeTags(tags, tag, res) {
      var _this3 = this;

      var splitName = tag[0].value.original_name.split(' > ');
      var depth = splitName.length - 1;
      var subTagRegExp = "^" + tag[0].value.original_name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + " > [^>]*$";
      var subTags = tags.filter(function (item) {
        return item[0].value.original_name.match(subTagRegExp);
      });

      subTags = subTags.map(function (subTag) {
        _this3._computeTags(tags, subTag, res);
        return subTag;
      });

      if (subTags.length > 0) {
        tag[0].subLines = subTags;
      }

      if (depth === 0) {
        res.push(tag);
      }
    },

    init: function init() {
      this._super();
      this._queryTagBreakdown();
    },

    _queryTagBreakdown: function _queryTagBreakdown() {
      var _this4 = this;

      this.set('isLoading', true);

      // Get all filters and data from component & get global filters
      var global_filters = this.get('globalFilters.current'),
          report_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('reportFilters')),
          component_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('content.component.filters')),
          dataQueryFilters = global_filters.merge(report_filters, component_filters).toDataQueryFormat(),
          dataQuery = this.get('store').createRecord('data-query'),
          dateRange = {
        from: '',
        to: ''
      };

      // Set date_range if present in filters
      if (dataQueryFilters.start_date && dataQueryFilters.end_date) {
        dateRange.from = (0, _moment['default'])(dataQueryFilters.start_date[0][0]).format().split('T')[0];
        dateRange.to = (0, _moment['default'])(dataQueryFilters.end_date[0][0]).format().split('T')[0];
      } else {
        // Set past 30 days if not in fitlers
        var date = new Date();
        dateRange.to = (0, _moment['default'])(Date.now()).format().split('T')[0];
        date.setDate(date.getDate() - 30);
        dateRange.from = (0, _moment['default'])(date).format().split('T')[0];
      }

      // Remove date range from filters
      dataQuery.set('date_range', dateRange);
      delete dataQueryFilters.start_date;
      delete dataQueryFilters.end_date;

      dataQuery.set('filters', dataQueryFilters);
      dataQuery.set('properties', this.get('content.component.properties'));
      dataQuery.set('front_format', true);
      dataQuery.set('group', this.get('content.component.view_type'));
      dataQuery.set('object_ids', this.get('content.component.config.object_ids'));
      dataQuery.set('object_id', this.get('content.component.config.object_id'));
      dataQuery.set('sort', this.get('content.component.config.sort'));
      dataQuery.set('lvls', this.get('content.component.config.lvls'));
      dataQuery.set('kind', this.get('content.component.config.kind'));
      dataQuery.set('subkind', this.get('content.component.config.subkind'));
      dataQuery.set('page_number', this.get('content.component.config.page_number'));
      dataQuery.set('page_size', this.get('content.component.config.page_size'));
      dataQuery.set('tz', _dueDashboardHelpersCurrentTimezone['default'].compute());

      if (this.get('canView')) {
        dataQuery.save().then(function (response) {
          _this4.set('content_data', response.get('content_data'));
          _this4.set('isLoading', false);
          _this4.set('isEmpty', response.get('content_data.length') === 0);
        })['catch'](function () {
          // Empty state when catched errors
          _this4.set('isLoading', false);
          _this4.set('isEmpty', true);
        });
      } else {
        this.set('isEmpty', true);
      }
    },

    reloadObserver: observer('reload', function () {
      this._queryTagBreakdown();
    }),

    actions: {}
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{report/-tag-array}} array component for collapsed tines (tag breakdowns)
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */