define('due-dashboard/components/link-to-t', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    beginin: computed('i18n.locale', 't', function () {
      var ret = this.get('i18n').t(this.get('t'));
      var arr = ret.string.match(/(.*?)<link-to-t\>(.*?)<\/link-to-t\>(.*)/);
      this.set('linkTo', arr[2]);
      this.set('end', arr[3]);
      return arr[1];
    })
  });

  /*
  
   this component prevent hardcore transitions.
     
   -- yml file -->
  
    in_yml:
      string: blablabla <link-to-t>click me</link-to-t> bliblibli
  
   <--
  
   -- hbs file -->
  
    {{link-to-t t='in_yml.string' href='settings.billing'}}
  
   <--
  
   -- rendered -->
  
    blablabla {{#link-to 'settings.billing'}}click me{{/link-to}} bliblibli
  
   <--
  
  */
});