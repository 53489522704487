define('due-dashboard/components/digests/tag-top-flop', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    includeOptions: ['included', 'excluded'],
    orderOptions: ['variation', 'volume', 'sentiment'],
    respondantOptions: ['volume', 'percent'],

    categoryOptions: computed(function () {
      return ["All", 'Main', 'Sub', 'Sub-sub', 'Sub-sub-sub'];
    }),

    selectedIncludeOption: computed('includeOptions', 'comp.options', 'comp.options.tag_include', function () {
      return this.get('comp.options.tag_include') ? this.get('comp.options.tag_include') : this.get('includeOptions')[0];
    }),

    shareCounterName: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.share_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.share_column_name', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    PositiveTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.positive_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.positive_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    NegativeTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.negative_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.negative_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    TagName: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.tag_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.tag_column_name', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    PositiveName: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.positive_mention_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.positive_mention_column_name', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    NegativeName: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.negative_mention_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.negative_mention_column_name', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    VariationName: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.variation_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.variation_column_name', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    actions: {
      changeInclude: function changeInclude(opt) {
        this.set('comp.options.tag_include', opt);
        this.get('saveDigestDelay')();
      }
    }
  });
});