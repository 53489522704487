define('due-dashboard/components/graphs/graph-list-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['graph-box'],

    currentAccount: service(),

    graphActions: computed('graph', function () {
      var actions = this.get('currentAccount.canEditGraphs') ? [{ icon: 'gears', action: 'edit', textKey: 'graphs.button.edit' }] : [];

      if (this.get('currentAccount.content').get('favorite_graph.id') != this.get('graph.id')) {
        actions.push({ icon: 'star', action: 'favorite', textKey: 'graphs.button.favorite' });
      }

      if (!this.get('graph.is_favorite') && this.get('currentAccount.canEditGraphs')) {
        actions.push({ icon: 'trash', action: 'delete', textKey: 'graphs.button.delete' });
      }
      return actions;
    }),

    actions: {
      triggerAction: function triggerAction(action) {
        this.sendAction(action, this.get('graph'));
      }
    }
  });
});