define('due-dashboard/models/stat', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  var Model = _emberData['default'].Model;
  var fragment = _emberDataModelFragments['default'].fragment;
  exports['default'] = Model.extend({
    date_range: fragment('date-range'),
    survey_size: _emberData['default'].attr(),
    extremas: fragment('extremas'),
    satisfaction_score: _emberData['default'].attr(),
    recommend_counts: _emberDataModelFragments['default'].fragmentArray('note-count'),
    comments_history: fragment('comments-history')
  });
});