define('due-dashboard/serializers/company', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({

    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize the attribute if readOnly!
      if (attribute.options && attribute.options.readOnly) {
        return;
      }
      this._super.apply(this, arguments);
    }

  });
});