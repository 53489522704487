define('due-dashboard/components/graphs/due-mini-graph', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var guidFor = _ember['default'].guidFor;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-mini-graph'],

    /* ### Style props #### */
    width: 135,
    height: 45,

    placeholderColor: '#E1E8EE',

    colors: {
      'green': '#00B548',
      'neutral': '#9EB0BE',
      'red': '#FA5252'
    },

    graphGradientColor: '',
    graphLineColor: '',

    graphLineWeight: 2,

    gradient: true,

    gradientStartOpacity: 0.01,
    gradientStopOpacity: .2,

    /* ### Private props ### */
    placeholderEvolution: [0, 13, 17, 10, 28, 17, 43, 47, 68],

    init: function init() {
      this._super();
      this._computeStyleProps();
    },

    didInsertElement: function didInsertElement() {
      this.set('uid', guidFor(this));
    },

    didRender: function didRender() {
      this._initMiniGraph(d3, this.get('graph'));
    },

    getFirstNonNull: function getFirstNonNull(array) {
      return array.find(function (g) {
        return g != null;
      });
    },

    getLastNonNull: function getLastNonNull(array) {
      var index = array.length;

      while (index-- && !array[index]);
      return array[index];
    },

    _computeStyleProps: function _computeStyleProps() {
      var graph = this.get('placeholder') ? this.get('placeholderEvolution') : this.get('graph'),
          config = this.get('colorsConfig'),
          placeholder = this.get('placeholder'),
          graphArrayType = typeof this.getFirstNonNull(graph),
          positiveTrend = !placeholder && this.get('trend') > config.neutral_threshold_max,
          neutralTrend = !placeholder && this.get('trend') >= config.neutral_threshold_min && this.get('trend') <= config.neutral_threshold_max;

      if (neutralTrend && !placeholder) {
        this.set('graphGradientColor', this.get('colors')['neutral']);
        this.set('graphLineColor', this.get('colors')['neutral']);
      } else if (positiveTrend && !placeholder) {
        this.set('graphGradientColor', this.get('colors')[config.up]);
        this.set('graphLineColor', this.get('colors')[config.up]);
      } else if (!placeholder) {
        this.set('graphGradientColor', this.get('colors')[config.down]);
        this.set('graphLineColor', this.get('colors')[config.down]);
      } else {
        this.set('graphGradientColor', this.get('placeholderColor'));
        this.set('graphLineColor', this.get('placeholderColor'));
      }
      this.set('graphArrayType', graphArrayType);
      this.set('positiveTrend', positiveTrend);
      this.set('neutralTrend', neutralTrend);
    },

    /**
     * Create mini-graph using global d3
     * @param {Object} d3 global variable
     * @param {Array} graph array of values
     */
    _initMiniGraph: function _initMiniGraph(d3, graph) {
      var _this = this;

      var graphArrayType = this.get('graphArrayType');
      var config = this.get('colorsConfig');
      var gradiantID = this.get('placeholder') ? 'placeholder-gradient' : this.get('neutralTrend') ? 'neutral-gradient' : this.get('positiveTrend') ? 'positive-gradient-' + config.up : 'negative-gradient-' + config.down;
      graph = this.get('placeholder') ? this.get('placeholderEvolution') : graph;

      /**
       * Create colored gradient under graph line
       * @param {Object} select svg container
       */
      var createGradient = function createGradient(select) {
        var gradient = select.select('defs').append('linearGradient').attr('id', gradiantID).attr('x1', '0%').attr('y1', '100%').attr('x2', '0%').attr('y2', '0%');
        gradient.append('stop').attr('offset', '0%').attr('style', 'stop-color: ' + _this.get('graphGradientColor') + '; stop-opacity: ' + _this.get('gradientStartOpacity'));
        gradient.append('stop').attr('offset', '100%').attr('style', 'stop-color :' + _this.get('graphGradientColor') + '; stop-opacity: ' + _this.get('gradientStopOpacity'));
      };

      /**
       * Append new svg section with configurated size
       */
      var parentContainerWidth = this.element.parentElement.getBoundingClientRect().width,
          minHeight = Math.min.apply(Math, _toConsumableArray(graph)),
          maxHeight = Math.max.apply(Math, _toConsumableArray(graph));
      var computedWidth = parentContainerWidth - parentContainerWidth * 0.3,
          computedHeight = Math.abs(maxHeight - minHeight);

      if (computedWidth > 282) {
        computedWidth = 282;
      }
      if (computedWidth < 90) {
        computedWidth = 90;
      }
      if (computedHeight < 30) {
        computedHeight = 30;
      }
      if (computedHeight > 45 && (!this.get('depth') || this.get('depth') === 0)) {
        computedHeight = 45;
      }
      if (computedHeight > 25 && this.get('depth') > 0) {
        computedHeight = 25;
      }

      var pathCenteringTranslation = (computedHeight + 5 - computedHeight) / 2;
      var svg = d3.select('#mini-graph-svg-' + this.get('uid')).append('svg')
      // .attr('viewBox', `0 0 ${computedHeight + 5} ${computedWidth + 1}`)
      .attr('width', computedWidth + 1).attr('height', computedHeight + 5);

      if (this.get('gradient')) {
        svg.append('defs');
        svg.call(createGradient);
      }

      /**
       * Setting up functions & objects for computed values
       */
      var parseTime = d3.timeParse('%Y-%m-%d');
      /* Object used by d3 */
      var parsedData = [{
        values: []
      }];
      parsedData[0].values = graph.map(function (point, index) {
        return {
          value: graphArrayType === 'object' ? point.value || 0 : point || 0,
          timestamp: graphArrayType === 'object' && point.timestamp ? parseTime(point.timestamp.split(' ')[0]) : index
        };
      });

      /* Getting min/max points for x axis */
      var xScale = d3.scaleTime().domain([d3.min(parsedData, function (points) {
        return d3.min(points.values, function (point) {
          return point.timestamp;
        });
      }), d3.max(parsedData, function (points) {
        return d3.max(points.values, function (point) {
          return point.timestamp;
        });
      })]).range([0, computedWidth]);
      /* Getting min/max points for y axis */
      var yScale = d3.scaleLinear().domain([d3.min(parsedData, function (points) {
        return d3.min(points.values, function (point) {
          return point.value || 0;
        });
      }), d3.max(parsedData, function (points) {
        return d3.max(points.values, function (point) {
          return point.value || 0;
        });
      })]).range([computedHeight, 0]);
      /* Getting string values for svg */
      var line = d3.line().x(function (d) {
        return xScale(d.timestamp);
      }).y(function (d) {
        return yScale(d.value || 0);
      });
      // TO BE USED FOR ROUNDED GRAPHS (SMOOTH LINE)
      // .curve(d3.curveCatmullRom.alpha(0.5));

      /**
       * Draw svg path for the gradient area + applying gradient
       */
      svg.selectAll('.line').data(parsedData).enter().append('path').attr('transform', 'translate(0, ' + pathCenteringTranslation + ')').attr('d', function (point) {
        if (point.values.length > 0) {
          var lineValues = line(point.values).slice(1);
          var splitedValues = lineValues.split(',');

          return 'M0,' + computedHeight + ',' + lineValues + ',l0,' + (computedHeight - _this.getLastNonNull(splitedValues));
        }
      }).style('fill', 'url(#' + gradiantID + ')');

      /**
       * Draw svg path for the line + applying style
       */
      svg.selectAll('.line').data(parsedData).enter().append('path').attr('transform', 'translate(0, ' + pathCenteringTranslation + ')').attr('d', function (d) {
        return line(d.values);
      }).attr('stroke-width', this.get('graphLineWeight')).style('fill', 'none').attr('stroke', this.get('graphLineColor'));
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{due-mini-graph}} simple mini graph with only a line
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=vjgnVmi6pM05lhHW-0
 */