define('due-dashboard/models/change-end-social-networks-scenario-actions-social-network', ['exports', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _emberData, _ember, _emberCpValidations) {

  var url_regexp = /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    link: [(0, _emberCpValidations.validator)('format', {
      regex: url_regexp,
      message: "Bad Url"
    })]
  });

  exports['default'] = Model.extend(Validations, {
    action: belongsTo('change-end-social-networks-scenario-action', { async: true }),
    social_network: belongsTo('SocialNetwork', { async: true }),
    link: attr('string', { defaultValue: 'http://' }),

    linkIsValid: computed('link', function () {
      return url_regexp.test(this.get('link'));
    })
  });
});