define('due-dashboard/controllers/companies/chats', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  var set = _ember['default'].set;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({
    filters: service(),
    _global: service('feedback-search/global'),
    tagManager: _ember['default'].inject.service(),

    chatSidebar: true,
    selectedFeedbackId: null,
    queryParams: ['selectedFeedbackId', 'selectedChatId', 'auto_tag_hard_delete'],
    auto_tag_hard_delete: null,

    showSatisfaction: false,

    currentPage: 1,
    limit: 20,

    current_status: 'ongoing',

    selectedStatusFilter: computed('feedbackStatusFilters.@each.translate', 'feedbackStatusFilters.@each.status', 'current_status', function () {
      if (this.get('feedbackStatusFilters')) {
        return this.get('feedbackStatusFilters').findBy('status', this.get('current_status'));
      }
    }),

    feedbackStatusFilters: computed('model.repartition.{all_count,new_count,resolved_count,ongoing_count,deleted_count},', 'model.repartition.isPending', function () {
      var _this = this;

      var filterStatus = ['all', 'new', 'resolved', 'ongoing', 'deleted'],
          dataPending = this.get('model.repartition.isPending'),
          repartition = this.get('model.repartition');

      return filterStatus.map(function (status) {
        var key = status + '_count';
        var translate_key = 'chats.' + status + '_filter';
        return {
          translate: _this.get('i18n').t(translate_key) + ' ' + (dataPending ? '' : '(' + repartition.get(key) + ')'),
          status: status
        };
      });
    }),

    retroCompat: observer('selectedChatId', function () {
      if ((this.get('selectedChatId.length') || 0) > 1) {
        this.set('selectedFeedbackId', this.get('selectedChatId'));
      }
    }),

    pagesCount: computed('model.feedbacks.content.meta', function () {
      var meta = this.get('model.feedbacks.content.meta');
      return meta ? meta['page_count'] : 0;
    }),

    selectFeedbackFromParams: observer('selectedFeedbackId', function () {
      var _this2 = this;

      var sid = this.get('selectedFeedbackId');
      if (sid) {
        this.store.findRecord('feedback', sid).then(function (feedback) {
          return _this2.send('clickFeedback', feedback);
        });
      }
    }),

    descUpdatedAt: ['chat_updated_at:desc'],
    sortedFeedbacks: computed.sort('feedbacks', 'descUpdatedAt'),

    feedbacks: computed('model.feedbacks.[]', function () {
      var first_feedback = this.get('model.feedbacks.firstObject');
      if (first_feedback && !(this.get('selectedFeedbackId') && this.get('selectedFeedback'))) {
        this.send('clickFeedback', first_feedback, false);
      } else if (!(this.get('selectedFeedbackId') && this.get('selectedFeedback.id'))) {
        this.set('selectedFeedback', null);
      }
      return this.get('model.feedbacks').toArray();
    }),

    pageChanged: observer('currentPage', function () {
      if (this.get('doNotQuery')) {
        this.set('doNotQuery', false);
      } else {
        this.launchQuery();
      }
    }),

    launchQuery: function launchQuery() {
      var _this3 = this;

      var filters = this.get('filters').get('queryFilters');
      filters.page = { number: this.get('currentPage'), size: this.get('limit') };
      filters.filter.status = this.get('selectedStatusFilter.status');
      if (this.get('selectedStatusFilter.status') === 'deleted') {
        filters.filter.deleted = 'true';
      }
      set(filters, 'include', 'client');
      set(filters, 'sort', '-chat-updated-at');
      if (filters.filter.completed == 'all') {
        filters.filter.completed = 'true';
      }

      var feedbacks = filters.filter.completed == 'false' ? [] : this.store.query('feedback', filters);
      this.set('model.feedbacks', feedbacks);
      delete filters.page;
      delete filters.include;
      delete filters.sort;
      this.store.queryRecord('chat-repartition', filters).then(function (record) {
        return _this3.set('model.repartition.content', record);
      });
      return feedbacks;
    },

    actions: {

      toggleChatsSidebar: function toggleChatsSidebar() {
        this.toggleProperty('chatSidebar');
      },

      removeFeedbackFromList: function removeFeedbackFromList(feedback) {
        this.get('feedbacks').removeObject(this.get('feedbacks').findBy('id', feedback.get('id')));
      },

      addFeedbackToList: function addFeedbackToList(feedback) {
        if (!this.get('feedbacks').findBy('id', feedback.get('id'))) {
          this.get('feedbacks').addObject(feedback);
        }
      },

      debouncedSearchText: function debouncedSearchText() {
        var service = this.get('_global'),
            updated = service.get('current').copy();

        updated.set('search_keyword', this.get('feedbackSearchText'));
        service.updateCurrent(updated);
        if (this.get('currentPage') !== 1) {
          this.set('doNotQuery', true);
          this.set('currentPage', 1);
        }
        run.debounce(this, this.launchQuery, 300);
      },

      updateStatusCounter: function updateStatusCounter(new_status, old_status) {
        var repartition = this.get('model.repartition.content');
        repartition.update_counters(new_status, old_status);
      },

      changeStatusFilter: function changeStatusFilter(status) {
        this.set('current_status', status.status);
        this.send('filtersUpdated');
      },

      filtersUpdated: function filtersUpdated() {
        if (this.get('currentPage') !== 1) {
          this.set('doNotQuery', true);
          this.set('currentPage', 1);
        }
        return this.launchQuery();
      },

      clickFeedback: function clickFeedback(feedback) {
        var _this4 = this;

        var from_real_click = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        if (from_real_click) {
          this.toggleProperty('chatSidebar');
        }
        this.get('tagManager').getAll({ survey: feedback.get('survey.id'), dropdown_list_restrictions: ['hidden', 'none', 'read'] }).then(function (tags) {
          _this4.set('tags', tags);
          _this4.get('tagManager').getAll({ survey: feedback.get('survey.id'), dropdown_list_restrictions: ['hidden', 'none', 'read'] }, 'root_cause').then(function (rootCauses) {
            return _this4.set('rootCauses', rootCauses);
          });
          _this4.get('tagManager').getAll({ survey: feedback.get('survey.id'), dropdown_list_restrictions: ['hidden', 'none', 'read'] }, 'action_taken').then(function (actionsTaken) {
            return _this4.set('actionsTaken', actionsTaken);
          });
        });
        this.set('selectedFeedback', feedback);
      }

    }
  });
});