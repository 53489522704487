define('due-dashboard/components/loading-skeleton/due-score-skeleton', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var Component = _ember['default'].Component;
  var guidFor = _ember['default'].guidFor;
  var computed = _ember['default'].computed;

  /**
   * @author Antoine YVROUD <antoine@diduenjoy.com>
   * @summary {{due-score-skeleton}} Score skeleton component
   *
   * @module
   * @argument {string} [type] - Score type to display ("sat-score" || "nps-score")
   * @argument {string} [style] - specific style added to  the component initial style
   * @argument {string} [color] - specific color for SVG
   * @example <caption>Full usage example of {{due-score-skeleton}} component</caption>
   * Usage :
   * ```hbs
   * {{loading-skeleton/due-score-skeleton
   *   type="sat-score"
   *   customStyle="margin: XXpx;"
   *   color="#F6F8FB"
   * }}
   * ```
   */

  exports['default'] = Component.extend({
    classNames: ['due-score-skeleton'],
    classNameBindings: ['type', 'customClass'],
    attributeBindings: ['style'],

    satSize: 30,
    npsSize: 85,
    rendered: false,
    color: '#F6F8FB',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('satAnimationUID', 'sat-linear-gradient-' + guidFor(this));
      this.set('npsAnimationUID', 'nps-linear-gradient-' + guidFor(this));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('xOffset', this.element.querySelector('svg').getBoundingClientRect().left);
      this.set('windowWidth', $('body > .ember-view').width());
      this.set('rendered', true);
    },

    computedSatAnimationUID: computed('emptyState', function () {
      return this.get('emptyState') ? '#F6F8FB' : 'url(#' + this.get('satAnimationUID') + ')';
    }),

    computedNpsAnimationUID: computed('emptyState', function () {
      return this.get('emptyState') ? '#F6F8FB' : 'url(#' + this.get('npsAnimationUID') + ')';
    })
  });
});