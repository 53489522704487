define('due-dashboard/routes/companies/scenarios/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    controllerName: 'companies.scenarios.edit',
    templateName: 'companies/scenarios/edit',
    keyManager: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('buildingStep', 0);
    },

    model: function model() {
      var defaultName = this.get('i18n').t('settings.scenarios.default_scenario_name');
      var company = this.get('currentAccount.content.company');
      var scenarios = this.modelFor('companies.scenarios').scenarios;
      var position = scenarios.get('length') + 1;
      var scenario = this.store.createRecord('scenario', {
        name: defaultName + ' #' + position,
        company: company,
        position: position
      });
      var defaultCondition = this.store.createRecord('unconditional-scenario-condition');
      scenario.get('scenario_conditions').addObject(defaultCondition);
      return scenario;
    },

    actions: {

      didTransition: function didTransition() {
        // const enterEvent = this.get('keyManager').addMacro({
        //   executionKey: 'enter',
        //   callback: Ember.run.bind(this, function() {
        //     this.send('sendNextStep');
        //   }),
        //   keyEvent: 'keydown',
        //   priority: 10,
        // });
        // this.set('enterEvent', enterEvent);
        // const backspaceEvent = this.get('keyManager').addMacro({
        //   executionKey: 'backspace',
        //   callback: Ember.run.bind(this, function() {
        //     this.send('sendPreviousStep');
        //   }),
        //   keyEvent: 'keydown',
        //   priority: 10,
        // });
        // this.set('backspaceEvent', backspaceEvent);
      },

      willTransition: function willTransition() {
        // const kM = this.get('keyManager');
        // ['enterEvent', 'backspaceEvent'].forEach(registered => kM.removeMacro(this.get(registered)));
        if (this.currentModel) {
          this.currentModel.rollbackAttributes();
        }
        return this._super.apply(this, arguments);
      }
    }
  });
});