define('due-dashboard/components/trending-keywords/show-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'li',
    classNames: ['keyword'],
    classNameBindings: ['rankClass'],

    rankClass: computed('keyword.rank', function () {
      return 'rank-' + this.get('keyword.rank');
    }),

    mouseMove: function mouseMove(e) {
      _ember['default'].$('.c3-tooltip-container.keyword-detail-hover').offset({ left: e.pageX + 5, top: e.pageY + 5 });
    },

    values: computed('keyword.{detractors_counter,passives_counter,promoters_counter,counter}', function () {
      var keyword_counter = this.get('keyword.counter'),
          detractors = '#FA5252',
          passives = '#FFAB00',
          promoters = '#00b548',
          detractors_count = this.get('keyword.detractors_counter'),
          passives_count = this.get('keyword.passives_counter'),
          promoters_count = this.get('keyword.promoters_counter');
      return {
        promoter: {
          value: promoters_count,
          percent: (promoters_count / keyword_counter * 100).toFixed(),
          barStyle: 'background-color: ' + promoters + '; width: ' + (promoters_count / keyword_counter * 100).toFixed() + '%',
          percentInlineStyle: (promoters_count / keyword_counter * 100).toFixed() <= 6 ? "display: none" : "display: initial"
        },
        passive: {
          value: passives_count,
          percent: (passives_count / keyword_counter * 100).toFixed(),
          barStyle: 'background-color: ' + passives + '; width: ' + (passives_count / keyword_counter * 100).toFixed() + '%',
          percentInlineStyle: (passives_count / keyword_counter * 100).toFixed() <= 6 ? "display: none" : "display: initial"
        },
        detractor: {
          value: detractors_count,
          percent: (detractors_count / keyword_counter * 100).toFixed(),
          barStyle: 'background-color: ' + detractors + '; width: ' + (detractors_count / keyword_counter * 100).toFixed() + '%',
          percentInlineStyle: (detractors_count / keyword_counter * 100).toFixed() <= 6 ? "display: none" : "display: initial"
        }
      };
    })
  });
});