define('due-dashboard/components/question-detail-item-satisfaction', ['exports', 'ember', 'due-dashboard/components/question-detail-item'], function (exports, _ember, _dueDashboardComponentsQuestionDetailItem) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardComponentsQuestionDetailItem['default'].extend({
    classNames: ['question-detail-item-satisfaction'],
    tagName: 'div',

    precisionValue: computed('question.score', function () {
      var ret = this.get('question.score') || 0;
      return ret === 100 || ret === -100 ? ret : ret.toPrecision(2);
    })
  });
});