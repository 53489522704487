define('due-dashboard/components/increment-for', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;

  /**
   * @author Antoine YVROUD <antoine@diduenjoy.com>
   * @summary {{increment-for}} 'Increment for' component
   *
   * @module
   * @argument {integer} [times] - loop time
   * @example <caption>Full usage example of {{increment-for}} component</caption>
   * Usage :
   * ```hbs
   * {{#increment-for times=2}}
   *  {{whatever-you-whant-to-display}}
   * {{/increment-for}}
   * ```
   */

  exports['default'] = Component.extend({
    tagName: '',
    numOfTimes: computed('times', function () {
      return new Array(parseInt(this.get('times'))).fill(0);
    })
  });
});