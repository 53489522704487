define('due-dashboard/components/breakdowns/due-distribution-breakdown', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-distribution-breakdown'],

    total: 0,

    computedBreakdown: computed('distribution', function () {
      var _this = this;

      var distribution = this.get('distribution');
      var content = this.get('content');
      var colIdx = content.component.type === 'tag-breakdown' || ['questions', 'segments'].includes(content.component.view_type) ? this.get('colIndex') : this.get('colIndex') - 1;
      var computedBreakdown = {};
      var total = 0;

      if (!distribution) {
        return [];
      }
      if (distribution['_total_multi'] !== undefined) {
        total = distribution['_total_multi'];
        delete distribution['_total_multi'];
      } else {
        Object.values(distribution).forEach(function (sectionTotal) {
          total += sectionTotal;
        });
      }
      this.set('total', total);

      computedBreakdown = Object.keys(distribution).map(function (sectionKey, idx) {
        var sectionTotal = distribution[sectionKey];
        var sectionPercentage = sectionTotal / total * 100;
        var color = '';
        var title = '';

        if (_this.get('source') === 'array' && content.component.view_type !== 'segments' && content.component.properties[colIdx].kpi && content.component.properties[colIdx].kpi.includes('sentiment')) {
          title = content.component.config.translations[_this.get('currentLanguage') || 'EN'].sentiments[sectionKey].title;
        } else if (_this.get('source') === 'array' && (content.component.view_type === 'questions' || content.component.properties[colIdx].kpi && content.component.properties[colIdx].kpi.includes('breakdown'))) {
          title = sectionKey;
        } else if (_this.get('source') === 'array' && content.component.view_type !== 'segments' && content.component.properties[colIdx].kpi && !content.component.properties[colIdx].kpi.includes('sentiment') && content.component.properties[colIdx].segment_id) {
          title = content.component.config.translations[_this.get('currentLanguage') || 'EN']['distribution_' + content.component.properties[colIdx].segment_id][idx].title;
        } else {
          title = content.component.config.translations[_this.get('currentLanguage') || 'EN'].distribution[idx].title;
        }

        var color_e = null;
        if (_this.get('source') === 'array' && content.component.view_type !== 'segments' && content.component.properties[colIdx].kpi && content.component.properties[colIdx].kpi.includes('sentiment')) {
          color_e = content.component.properties[colIdx].distribution[sectionKey];
        } else if (_this.get('source') === 'array' && content.component.properties[colIdx].segment_id && content.component.config['distribution_' + content.component.properties[colIdx].segment_id]) {
          color_e = content.component.config['distribution_' + content.component.properties[colIdx].segment_id][idx % content.component.config['distribution_' + content.component.properties[colIdx].segment_id].length];
        } else if (_this.get('source') === 'array') {
          color_e = content.component.properties[colIdx].distribution[idx % content.component.properties[colIdx].distribution.length];
        } else {
          color_e = content.component.config.distribution[idx % content.component.config.distribution.length];
        }
        color = color_e ? color_e.color : '#e6e6e6'; // default color for missing distribution entries

        return {
          color: color,
          percentage: Math.round(sectionPercentage),
          style: 'width: ' + sectionPercentage + '%; background-color: ' + color + ';',
          title: title,
          total: sectionTotal || 0
        };
      });

      return computedBreakdown;
    }),

    didRender: function didRender() {
      this.set('width', this.element.getBoundingClientRect().width);
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{due-distribution-breakdown}} inline colored breakdown
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2755%3A334944&t=vWBqgIrAQLBSPNiX-0
 */

/**
 * @module due-distribution-breakdown
 * @argument {Array} distribution (Mandatory) List of distribution values
 * @argument {Object} content (Mandatory) Object of configuration's props
 */