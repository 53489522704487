define('due-dashboard/routes/settings/users', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        company: this.get('currentAccount.content.company'),
        all_accounts: this.store.query('account', { page: { number: 1, size: 50 } })
      }).then(function (h) {
        return _ember['default'].Object.create(h);
      });
    }

  });
});