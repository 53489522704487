define('due-dashboard/components/v2/-switch', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['due-switch'],

    actions: {
      onToggleSwitchState: function onToggleSwitchState() {
        var readonly = this.get('readonly');
        if (!readonly) {
          var on = this.get('on');
          var onSwitch = this.get('onSwitch');

          if (onSwitch) {
            onSwitch(!on);
          }
        }
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-alert}} switch component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */