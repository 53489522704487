define('due-dashboard/controllers/companies/graphs/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    filterService: _ember['default'].inject.service('filters'),
    globalFilters: _ember['default'].computed.oneWay('filterService.queryFilters.filter'),

    saveGraph: function saveGraph() {
      var promise = this.get('model.graph').save();
      this.set('savePromise', promise);
      return promise;
    },

    actions: {
      filtersUpdated: function filtersUpdated() {
        var filters = this.get('filterService.queryFilters');

        this.set('globalFilters', filters.filter);
      },

      cancelCustomGraph: function cancelCustomGraph() {
        this.transitionToRoute('companies.graphs');
      },

      saveCustomGraph: function saveCustomGraph() {
        this.saveGraph();
      },

      saveAndTransition: function saveAndTransition() {
        var _this = this;

        this.saveGraph().then(function () {
          return _this.transitionToRoute('companies.graphs');
        });
      }
    }

  });
});