define('due-dashboard/components/rating-scale-preview', ['exports', 'ember'], function (exports, _ember) {
  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    observeScale: _ember['default'].on('init', observer('content.scale', 'content.ratings', function () {
      if (this.get('content.scale') === 11) {
        var ratings = this.get('content.ratings').toArray();
        this.setProperties({ bigScaleBeginin: [ratings[0], ratings[1]], bigScaleEnd: [ratings[9], ratings[10]] });
      }
    }))
  });
});