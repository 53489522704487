define('due-dashboard/components/digests/parts/component-column-translation-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'td',

    currentTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.title', value);
        return value;
      }
    }),

    currentSubTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.subtitle');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.subtitle', value);
        return value;
      }
    }),

    currentTagsTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.tags_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.tags_name', value);
        return value;
      }
    }),

    currentSegmentTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.segment_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.segment_column_title', value);
        return value;
      }
    }),

    currentQuestionTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.question_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.question_column_title', value);
        return value;
      }
    }),

    currentScoreTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.score_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.score_column_title', value);
        return value;
      }
    }),

    currentChangeTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.change_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.change_column_title', value);
        return value;
      }
    }),

    currentCounterTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.counter_column_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.counter_column_title', value);
        return value;
      }
    }),

    currentButtonTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.button_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.button_title', value);
        return value;
      }
    }),

    currentTagFeedbackVolumeTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.feedback_volume_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.feedback_volume_name', value);
        return value;
      }
    }),

    currentTagVolumeVariationTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.volume_variation_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.volume_variation_name', value);
        return value;
      }
    }),

    currentTagScoreTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.score_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.score_name', value);
        return value;
      }
    }),

    currentTagScoreVariationTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.score_variation_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.score_variation_name', value);
        return value;
      }
    }),

    currentTagRespondantsTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.respondants_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.respondants_name', value);
        return value;
      }
    }),

    currentTagFeelingsTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.feelings_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.feelings_name', value);
        return value;
      }
    }),

    shareCounterName: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.share_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.share_column_name', value);
        return value;
      }
    }),

    PositiveTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.positive_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.positive_title', value);
        return value;
      }
    }),

    NegativeTitle: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.negative_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.negative_title', value);
        return value;
      }
    }),

    TagName: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.tag_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.tag_column_name', value);
        return value;
      }
    }),

    PositiveName: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.positive_mention_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.positive_mention_column_name', value);
        return value;
      }
    }),

    NegativeName: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.negative_mention_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.negative_mention_column_name', value);
        return value;
      }
    }),

    VariationName: computed('tag', 'compId', {
      get: function get() {
        return this.get('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.variation_column_name');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('tag').toLowerCase() + '.components.' + this.get('compId') + '.variation_column_name', value);
        return value;
      }
    })
  });
});