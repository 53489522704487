define('due-dashboard/components/digests/parts/component-row-translation-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'tr',
    displaySegmentBreakdown: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'segment_breakdown';
      }
    }),

    displaySegRespondants: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'segment_breakdown' && this.get('comp.options.breakdown_respondants');
      }
    }),

    displayClosedQuestion: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'closed_question';
      }
    }),

    displayTagTopFlop: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_top_flop';
      }
    }),

    displayshareTitle: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_top_flop' && this.get('comp.options.share_of_mention');
      }
    }),

    displayfeelingTitle: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_top_flop' && this.get('comp.options.feeling_mention');
      }
    }),

    displayVariationTitle: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_top_flop' && this.get('comp.options.variation');
      }
    }),

    displayChangeTitle: computed('comp', {
      get: function get() {
        return (this.get('comp.type') == 'segment_breakdown' || this.get('comp.type') == 'closed_question') && this.get('comp.options.change');
      }
    }),

    displayCounterTitle: computed('comp', {
      get: function get() {
        return (this.get('comp.type') == 'segment_breakdown' || this.get('comp.type') == 'closed_question') && this.get('comp.options.counter');
      }
    }),

    displayButtonTitle: computed('comp', {
      get: function get() {
        if (this.get('comp.type') == 'redirection_button') {
          return true;
        }
        return false;
      }
    }),

    displayTagsName: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_breakdown';
      }
    }),

    displayTagFeedbackVolume: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_breakdown' && this.get('comp.options.feedback_volume');
      }
    }),

    displayTagVolumeVariation: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_breakdown' && this.get('comp.options.volume_variation');
      }
    }),

    displayTagScore: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_breakdown' && this.get('comp.options.score');
      }
    }),

    displayTagScoreVariation: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_breakdown' && this.get('comp.options.score_variation');
      }
    }),

    displayTagRespondants: computed('comp', {
      get: function get() {
        return this.get('comp.type') == 'tag_breakdown' && this.get('comp.options.breakdown_respondants');
      }
    }),

    displayTagFeelings: computed('comp', {
      get: function get() {
        return (this.get('comp.type') == 'tag_breakdown' || this.get('comp.type') == 'segment_breakdown') && this.get('comp.options.breakdown_feelings');
      }
    })

  });
});