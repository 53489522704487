define('due-dashboard/serializers/template', ['exports', 'due-dashboard/serializers/application'], function (exports, _dueDashboardSerializersApplication) {

  /* global _ */

  exports['default'] = _dueDashboardSerializersApplication['default'].extend({
    serialize: function serialize() {
      var super_res = this._super.apply(this, arguments);
      var attrs = super_res.data.attributes['dynamic-attributes'];
      var langTags = Object.keys(super_res.data.attributes['text-contents']);

      _.forEach(attrs, function (value, key) {
        if (!langTags.includes(key)) {
          attrs[key.dasherize()] = value;
        } else {
          attrs[key] = value;
        }
        if (key.dasherize() !== key && !langTags.includes(key)) {
          // if key is one word
          delete attrs[key];
        }
      });
      delete super_res.data.attributes['integrations-logos'];
      return super_res;
    },

    normalize: function normalize(typeClass, hash) {
      _.forEach(hash.attributes['dynamic-attributes'], function (value, key) {
        hash.attributes['dynamic-attributes'][key.underscore()] = value;
        if (key.underscore() !== key) {
          // if key is one word
          delete hash.attributes['dynamic-attributes'][key];
        }
      });
      var super_res = this._super.apply(this, arguments);
      return super_res;
    }

  });
});