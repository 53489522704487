define('due-dashboard/components/v2/libraries/surveys/navbar/elements/-single-select', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content-element-single-select'],
    newDropdownOptionValue: '',
    doubleKeyError: false,
    cachedValues: { keys: {} },

    init: function init() {
      var _this = this;

      var content = this.get('object.content');
      Object.keys(content).map(function (key) {
        return _this.cachedValues[key] = content[key];
      });
      this._deserializeOptions();
      this._super.apply(this, arguments);
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._deserializeOptions();
    },

    hasDefaultValueObj: computed('object.content.default_value', function () {
      var content = this.get('object.content');

      return content.default_value != null;
    }),

    isMultipleSelect: computed('object.kind', function () {
      var kind = this.get('object.kind');

      return kind === 'multiple_select';
    }),

    _deserializeOptions: function _deserializeOptions() {
      var options = this.get('object.content.options') || [];
      if (Array.isArray(options)) {
        this.set('optionIndex', Math.max.apply(Math, _toConsumableArray(options.map(function (o) {
          return isNaN(o.key) ? 0 : o.key;
        })).concat([0])));
        this.set('computedOptions', options.map(function (o) {
          return _extends({}, o);
        }));
      }
    },

    _serializeOptions: function _serializeOptions(content) {
      set(content, 'options', this.get('computedOptions').map(function (o) {
        return _extends({}, o);
      }));
      return content;
    },

    actions: {
      onChangeContentField: function onChangeContentField() {
        var saveCallback = this.get('contentUpdateCallback');
        var content = this.get('object.content');
        var kind = this.get('object.kind');
        var cmptdContent = this._serializeOptions(content);

        if (saveCallback) {
          saveCallback(cmptdContent, kind);
        }
      },

      toggleContentField: function toggleContentField(field) {
        var content = this.get('object.content');

        set(content, field, !content[field]);
        this.send('onChangeContentField');
      },

      updateContentField: function updateContentField(field, value) {
        var content = this.get('object.content');
        var focus = this.get('focus');

        if (!focus && value !== -1) this.set('focus', true);
        if (value !== -1) {
          var valueChanged = !(this.cachedValues[field] === value);
          this.cachedValues[field] = value;
          set(content, field, value);
          if (valueChanged) this.send('onChangeContentField');
        }
        if (value === -1 && this.get('focus')) this.set('focus', false);
      },

      updateDefaultValue: function updateDefaultValue() {
        var content = this.get('object.content');
        var hasDefaultValueObj = this.get('hasDefaultValueObj');
        var defaultValue = { from: 'url_param', url_param: '' };

        if (hasDefaultValueObj) {
          set(content, 'default_value', null);
        } else {
          set(content, 'default_value', defaultValue);
        }

        this.send('onChangeContentField');
      },

      updateSelectOption: function updateSelectOption(key, value) {
        var content = this.get('object.content');
        var focus = this.get('focusOptions');

        if (!focus && value !== -1) this.set('focusOptions', true);
        if (content.options == null) {
          set(content, 'options', []);
        }
        if (typeof value == 'string' && value.length && value !== -1 && !this.isDestroyed) {
          var trimedValue = value.trim();
          var option = content.options[content.options.findIndex(function (o) {
            return o.key === key;
          })];
          var valueChanged = !(option && option['value'] === trimedValue);
          if (valueChanged) {
            option['value'] = trimedValue;
            this.send('onChangeContentField');
          }
        }

        if (value === -1 && this.get('focusOptions')) this.set('focusOptions', false);
      },

      addOption: function addOption() {
        var content = this.get('object.content');
        var value = this.get('newDropdownOptionValue').trim();

        if (content.options == null) {
          set(content, 'options', []);
        }

        if (content.options.findIndex(function (x) {
          return x.value == value;
        }) != -1) {
          this.set('doubleKeyError', true);
          return;
        }

        var index = this.get('optionIndex') + 1;
        var option = { key: '' + index, value: value };
        content.options.pushObject(option);
        this.set('object.content', content);
        this.notifyPropertyChange('object.content');
        this.get('computedOptions').pushObject(option);
        this.send('onChangeContentField');
        this.set('newDropdownOptionValue', '');
        this.set('optionIndex', index);
      },

      deleteOption: function deleteOption(key) {
        var content = this.get('object.content');

        content.options.splice(content.options.findIndex(function (x) {
          return x.key == key;
        }), 1);
        this.set('object.content', content);
        this._deserializeOptions();
        this.notifyPropertyChange('object.content');
        this.send('onChangeContentField');
      },

      toggleDropdownKind: function toggleDropdownKind() {
        var kind = this.get('object.kind');

        if (kind === 'single_select') this.set('object.kind', 'multiple_select');
        if (kind === 'multiple_select') this.set('object.kind', 'single_select');
        this.send('onChangeContentField');
      },

      onNewOptionChange: function onNewOptionChange() {
        this.set('doubleKeyError', false);
      },

      reorderOptions: function reorderOptions(itemModels, reorderOption) {
        this.set('computedOptions', itemModels);
        this.send('onChangeContentField');
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/libraries/surveys/navbar/elements/-single-select}} single select element config component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */