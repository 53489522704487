define('due-dashboard/components/loading-skeleton/due-bar-charts-skeleton', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;

  /**
   * @author Antoine YVROUD <antoine@diduenjoy.com>
   * @summary {{due-bar-charts-skeleton}} Bar charts skeleton component
   *
   * @module
   * @argument {string} [width] - bar charts width
   * @argument {string} [height] - bar charts height
   * @argument {string} [color] - specific color for bar charts
   * @argument {string} [style] - specific style added to  the component initial style
   * @example <caption>Full usage example of {{due-bar-charts-skeleton}} component</caption>
   * Usage :
   * ```hbs
   * {{loading-skeleton/due-bar-charts-skeleton
   *   width=138
   *   height=147
   *   color="#F6F8FB"
   *   style="margin: XXpx;"
   * }}
   * ```
   */

  exports['default'] = Component.extend({
    classNames: ['due-bar-charts-skeleton'],
    attributeBindings: ['style'],

    width: 138,
    height: 147,
    color: '#F6F8FB',
    rendered: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('animationUID', 'linear-gradient-' + guidFor(this));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('xOffset', this.element.querySelector('svg').getBoundingClientRect().left);
      this.set('windowWidth', $('body > .ember-view').width());
      this.set('rendered', true);
    },

    computedAnimationUID: computed('emptyState', function () {
      return this.get('emptyState') ? '#F6F8FB' : 'url(#' + this.get('animationUID') + ')';
    }),

    barWidth: computed('width', function () {
      return this.get('width') / 3;
    }),

    middleBarHeight: computed('height', function () {
      return this.get('height') * 0.3;
    }),

    littleBarHeight: computed('height', function () {
      return this.get('height') * 0.18;
    }),

    xDoubleMargin: computed('width', function () {
      return this.get('width') * 2 / 3;
    }),

    y1: computed('height', 'middleBarHeight', function () {
      return this.get('height') * 0.7;
    }),

    y2: computed('height', 'littleBarHeight', function () {
      return this.get('height') * 0.82;
    })
  });
});