define('due-dashboard/components/loading-skeleton/due-nps-line-skeleton', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;

  /**
   * @author Antoine YVROUD <antoine@diduenjoy.com>
   * @summary {{due-nps-line-skeleton}} NPS Line skeleton component
   *
   * @module
   * @argument {string}  [width] - specific width for SVG
   * @argument {string}  [height] - specific height for SVG
   * @argument {boolean} [fitParentWidth] - boolean to auto compute width based on parent
   * @argument {string}  [color] - specific color for SVG
   * @argument {string}  [style] - specific style added to  the component initial style
   * @example <caption>Full usage example of {{due-nps-line-skeleton}} component</caption>
   * Usage :
   * ```hbs
   * {{loading-skeleton/due-nps-line-skeleton
   *   width=390
   *   height=8
   *   fitParentWidth=true
   *   color="#F6F8FB"
   *   style="margin: XXpx;"
   * }}
   * ```
   */

  exports['default'] = Component.extend({
    classNames: ['due-nps-line-skeleton'],
    attributeBindings: ['style'],

    color: "#F6F8FB",
    rendered: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('animationUID', 'linear-gradient-' + guidFor(this));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('rendered', true);
      this.set('xOffset', this.element.querySelector('svg').getBoundingClientRect().x);
      this.set('windowWidth', $('body > .ember-view').width());
      this.set('parentWidth', this.element.parentElement.getBoundingClientRect().width);
    },

    computedAnimationUID: computed('emptyState', function () {
      return this.get('emptyState') ? '#F6F8FB' : 'url(#' + this.get('animationUID') + ')';
    }),

    lineWidth: computed('width', 'parentWidth', function () {
      return this.get('fitParentWidth') ? this.get('parentWidth') - 5 : Math.min(this.get('parentWidth') - 5 || 390, this.get('width') || 390);
    }),

    lineHeight: computed('height', function () {
      return this.get('height') || 8;
    }),

    firstLineEnd: computed('lineWidth', function () {
      return this.get('lineWidth') * 4 / 7;
    }),

    secondLineEnd: computed('lineWidth', function () {
      return this.get('lineWidth') * 6 / 7;
    }),

    border: computed('lineHeight', function () {
      return this.get('lineHeight') - 2;
    })
  });
});