define("due-dashboard/templates/components/v2/libraries/surveys/navbar/translations/-step", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 2
              },
              "end": {
                "line": 22,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/translations/-step.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "element-section-container");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["inline", "component", ["v2/libraries/surveys/navbar/translations/-badge"], ["name", ["subexpr", "@mut", [["get", "element.data.name", ["loc", [null, [8, 13], [8, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "kind", ["subexpr", "@mut", [["get", "element.data.kind", ["loc", [null, [9, 13], [9, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "type", ["subexpr", "@mut", [["get", "element.data.content.type", ["loc", [null, [10, 13], [10, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "position", ["subexpr", "my-math", [["get", "element.data.position", ["loc", [null, [11, 26], [11, 47]]], 0, 0, 0, 0], "+", 1], [], ["loc", [null, [11, 17], [11, 54]]], 0, 0]], ["loc", [null, [7, 6], [12, 8]]], 0, 0], ["inline", "component", [["subexpr", "concat", ["v2/libraries/surveys/navbar/translations/elements/", ["get", "element.component", ["loc", [null, [13, 79], [13, 96]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 18], [13, 97]]], 0, 0]], ["languagesToDisplay", ["subexpr", "@mut", [["get", "languagesToDisplay", ["loc", [null, [14, 27], [14, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "surveyElement", ["subexpr", "@mut", [["get", "element.data", ["loc", [null, [15, 22], [15, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "mainLanguage", ["subexpr", "@mut", [["get", "mainLanguage", ["loc", [null, [16, 21], [16, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "translations", ["subexpr", "@mut", [["get", "translations", ["loc", [null, [17, 21], [17, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "stepPosition", ["subexpr", "@mut", [["get", "step.position", ["loc", [null, [18, 21], [18, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "updateSurveyTranslations", ["subexpr", "@mut", [["get", "updateSurveyTranslations", ["loc", [null, [19, 33], [19, 57]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 6], [20, 8]]], 0, 0]],
          locals: ["element"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 23,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/translations/-step.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "elements", ["loc", [null, [5, 10], [5, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 2], [22, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/translations/-step.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "sections-separator");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "component", ["v2/-collapse-section"], ["label", ["subexpr", "@mut", [["get", "step.name", ["loc", [null, [2, 8], [2, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "collapsed", true], 0, null, ["loc", [null, [1, 0], [23, 14]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});