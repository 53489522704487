define('due-dashboard/components/extremum-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    init: function init() {
      this._super.apply(this, arguments);
      var oldScore = this.get('score');
      this.set('score', 0);
      _ember['default'].run.next(this, function () {
        if (!this.isDestroyed) {
          this.set('score', oldScore);
        }
      });
    }

  });
});