define('due-dashboard/utils/zendesk/client-api', ['exports', 'npm:https', 'due-dashboard/utils/zendesk/constants', 'ember'], function (exports, _npmHttps, _dueDashboardUtilsZendeskConstants, _ember) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  /*
  ** Private functions
  */

  // (De)serialization

  var serialize_value_for_current_tags = function serialize_value_for_current_tags(value) {
    return value.join(' ');
  };
  var deserialize_value_for_current_tags = function deserialize_value_for_current_tags(value) {
    return value.split(' ');
  };

  var de_serializers = {
    serialize_value_for_current_tags: serialize_value_for_current_tags,
    deserialize_value_for_current_tags: deserialize_value_for_current_tags
  };

  var serialize_condition = function serialize_condition(condition) {
    var value_serializer = de_serializers['serialize_value_for_' + condition.type.name];
    var value = condition.value;
    if (typeof value_serializer === 'function') {
      value = value_serializer(value);
    }
    return {
      "field": condition.type.name,
      "operator": condition.operator,
      "value": value && (value.id || value.id === 0 && '0' || value)
    };
  };

  var deserialize_condition = function deserialize_condition(condition) {
    var value_deserializer = de_serializers['deserialize_value_for_' + condition.field];
    var value = condition.value;
    if (typeof value_deserializer === 'function') {
      value = value_deserializer(value);
    }
    condition.value = value;
    return condition;
  };

  // requests
  var sendRequest = function sendRequest(options) {
    var req = _npmHttps['default'].request(options);
    return new _ember['default'].RSVP.Promise(function (resolve, reject) {
      req.on('response', function (res) {
        var data = '';
        res.on('data', function (chunk) {
          return data += chunk;
        });
        res.on('end', function () {
          return resolve(data === "" ? null : JSON.parse(data));
        });
        res.on('error', function (err) {
          return reject(err);
        });
      });
      req.on('error', function (err) {
        return reject(err);
      });
      if (options.postData) {
        req.write(options.postData);
      }
      req.end();
    });
  };

  // instance methods
  var _buildAuthString = Symbol('buildAuthString');
  var _buildRequestOptions = Symbol('buildRequestOptions');

  /*
  ** Main
  */

  var ZendeskApiClient = (function () {
    function ZendeskApiClient(options) {
      _classCallCheck(this, ZendeskApiClient);

      this.hostname = options.hostname;
      this.username = options.username;
      this.apiToken = options.apiToken;
      this.password = options.password;
    }

    // HTTP verbs

    _createClass(ZendeskApiClient, [{
      key: 'get',
      value: function get(path, callback) {
        var options = this[_buildRequestOptions]({
          method: 'GET',
          path: path
        });
        return sendRequest(options, callback);
      }
    }, {
      key: 'put',
      value: function put(path, postData, callback) {
        var options = this[_buildRequestOptions]({
          method: 'PUT',
          path: path,
          headers: {
            'Content-Type': 'application/json'
          }
        });
        options.postData = JSON.stringify(postData);
        return sendRequest(options, callback);
      }
    }, {
      key: 'post',
      value: function post(path, postData, callback) {
        var options = this[_buildRequestOptions]({
          method: 'POST',
          path: path,
          headers: {
            'Content-Type': 'application/json'
          }
        });
        options.postData = JSON.stringify(postData);
        return sendRequest(options, callback);
      }
    }, {
      key: 'delete',
      value: function _delete(path, callback) {
        var options = this[_buildRequestOptions]({
          method: 'DELETE',
          path: path
        });
        return sendRequest(options, callback);
      }
    }, {
      key: _buildAuthString,

      //private instance methods

      value: function value() {
        return 'Bearer ' + this.apiToken;
      }
    }, {
      key: _buildRequestOptions,
      value: function value(args) {
        var headers = args.headers || {};
        headers['Accept'] = 'application/json';

        var options = {
          hostname: this.hostname,
          method: args.method,
          path: "/api/v2/" + args.path,
          headers: Object.assign(headers, {
            'Authorization': this[_buildAuthString]()
          })
        };
        return options;
      }
    }], [{
      key: 'deserialize_conditions',
      value: function deserialize_conditions(conditions) {
        return conditions.filter(function (c) {
          return c.field !== 'requester_time_zone';
        }).map(function (c) {
          return deserialize_condition(c);
        });
      }
    }, {
      key: 'serialize_conditions',
      value: function serialize_conditions(conditions) {
        return conditions.filter(function (c) {
          return c.value || c.operator === 'not_changed';
        }).map(function (c) {
          return serialize_condition(c);
        });
      }
    }, {
      key: 'DIDUENJOY_SEGMENTS',
      get: function get() {
        return _dueDashboardUtilsZendeskConstants['default'].DIDUENJOY_SEGMENTS;
      }
    }, {
      key: 'DIDUENJOY_TARGET_BASE',
      get: function get() {
        return _dueDashboardUtilsZendeskConstants['default'].DIDUENJOY_TARGET_BASE;
      }
    }, {
      key: 'CONDITIONS_REFS',
      get: function get() {
        return _dueDashboardUtilsZendeskConstants['default'].CONDITIONS_REFS;
      }
    }]);

    return ZendeskApiClient;
  })();

  exports['default'] = ZendeskApiClient;
});