define("due-dashboard/templates/components/satisfaction-donut", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/satisfaction-donut.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "c3-chart", [], ["data", ["subexpr", "@mut", [["get", "satisfactionData", ["loc", [null, [1, 17], [1, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "donut", ["subexpr", "@mut", [["get", "satisfactionDonut", ["loc", [null, [2, 18], [2, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "legend", ["subexpr", "hash", [], ["show", false], ["loc", [null, [3, 19], [3, 36]]], 0, 0], "tooltip", ["subexpr", "@mut", [["get", "tooltip", ["loc", [null, [4, 20], [4, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "transition", ["subexpr", "hash", [], ["duration", 500], ["loc", [null, [5, 23], [5, 42]]], 0, 0]], ["loc", [null, [1, 0], [5, 44]]], 0, 0], ["attribute", "class", ["concat", ["stat-total-score ", ["subexpr", "if", [["subexpr", "eq", [["get", "totalScoreAnimator.fixedCurrentValue", ["loc", [null, [6, 38], [6, 74]]], 0, 0, 0, 0], -100], [], ["loc", [null, [6, 34], [6, 80]]], 0, 0], "max-min"], [], ["loc", [null, [6, 29], [6, 92]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "formatedSatIndex", ["loc", [null, [7, 6], [7, 26]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});