define("due-dashboard/templates/components/v2/libraries/surveys/navbar/-design", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/-design.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "component", ["v2/libraries/surveys/navbar/design/-overview"], ["backToIndexCallback", ["subexpr", "@mut", [["get", "backToIndexCallback", ["loc", [null, [3, 24], [3, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "selectCurrentElement", ["subexpr", "action", ["updateCurrentElement"], [], ["loc", [null, [4, 25], [4, 56]]], 0, 0], "previewStepIndex", ["subexpr", "@mut", [["get", "previewStepIndex", ["loc", [null, [5, 21], [5, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "currentElement", ["subexpr", "@mut", [["get", "selectedDesignElement", ["loc", [null, [6, 19], [6, 40]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [7, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/-design.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "component", ["v2/libraries/surveys/navbar/design/-settings"], ["selectCurrentElement", ["subexpr", "action", ["updateCurrentElement"], [], ["loc", [null, [10, 25], [10, 56]]], 0, 0], "currentElement", ["subexpr", "@mut", [["get", "selectedDesignElement", ["loc", [null, [11, 19], [11, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "surveyDraft", ["subexpr", "@mut", [["get", "surveyDraft", ["loc", [null, [12, 16], [12, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "customFonts", ["subexpr", "@mut", [["get", "group.customFonts", ["loc", [null, [13, 16], [13, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "updateDesignOptions", ["subexpr", "action", ["updateDesignOptions"], [], ["loc", [null, [14, 24], [14, 54]]], 0, 0], "updateSurveyDraft", ["subexpr", "action", ["updateSurveyDraft"], [], ["loc", [null, [15, 22], [15, 50]]], 0, 0], "previewStepIndex", ["subexpr", "@mut", [["get", "previewStepIndex", ["loc", [null, [16, 21], [16, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "isLoading", ["subexpr", "@mut", [["get", "group.isLoading", ["loc", [null, [17, 14], [17, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [9, 2], [18, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/-design.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "not", [["get", "navBarRightPanel", ["loc", [null, [1, 11], [1, 27]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 6], [1, 28]]], 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [19, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});