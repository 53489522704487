define('due-dashboard/models/trustpilot-company-integration', ['exports', 'due-dashboard/models/company-integration', 'ember-data'], function (exports, _dueDashboardModelsCompanyIntegration, _emberData) {
  exports['default'] = _dueDashboardModelsCompanyIntegration['default'].extend({
    name: 'trustpilot',
    auth_key: _emberData['default'].attr(),
    encription_key: _emberData['default'].attr(),
    refresh_token: _emberData['default'].attr(),
    access_token: _emberData['default'].attr(),
    domain: _emberData['default'].attr(),
    activate_retrieve: _emberData['default'].attr('boolean'),
    activate_scenario: _emberData['default'].attr('boolean')

  });
});