define('due-dashboard/initializers/key-manager-config', ['exports', 'due-dashboard/config/environment', 'ember'], function (exports, _dueDashboardConfigEnvironment, _ember) {
  exports.initialize = initialize;
  var get = _ember['default'].get;

  function initialize(application) {
    var keyManagerConfig = get(_dueDashboardConfigEnvironment['default'], 'keyManagerConfig') || {};
    application.register('main:key-manager-config', keyManagerConfig, {
      instantiate: false
    });
  }

  exports['default'] = {
    name: 'key-manager-config',
    initialize: initialize
  };
});