define("due-dashboard/templates/components/click-rate-chart", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/click-rate-chart.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "c3-chart", [], ["c3chart", ["subexpr", "@mut", [["get", "c3chart", ["loc", [null, [2, 21], [2, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "data", ["subexpr", "@mut", [["get", "data", ["loc", [null, [3, 19], [3, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "donut", ["subexpr", "@mut", [["get", "chartDonut", ["loc", [null, [4, 20], [4, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "legend", ["subexpr", "hash", [], ["show", false], ["loc", [null, [5, 21], [5, 38]]], 0, 0], "tooltip", true, "transition", ["subexpr", "hash", [], ["duration", 500], ["loc", [null, [7, 25], [7, 44]]], 0, 0]], ["loc", [null, [2, 2], [7, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 7
          }
        },
        "moduleName": "due-dashboard/templates/components/click-rate-chart.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "data", ["loc", [null, [1, 6], [1, 10]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [8, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});