define('due-dashboard/components/management-question-category/new-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var _Ember$run = _ember['default'].run;
  var debounce = _Ember$run.debounce;
  var later = _Ember$run.later;
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    selected: null,
    questions: [],
    suggestedQuestions: [],
    loadSuggestionsPromise: null,

    init: function init() {
      var _this = this;

      this.store.query('questions-suggestion', {
        page: { number: 1, size: 20 }
      }).then(function (questions) {
        return _this.set('defaultQuestions', questions.toArray());
      });
      this._super.apply(this, arguments);
    },

    availableQuestions: computed('model.questions.[]', 'defaultQuestions', function () {
      var initialQuestions = this.get('defaultQuestions') || [];
      var questions = this.get('model.questions');

      return initialQuestions.filter(function (iq) {
        var text = iq.get('text');
        return !questions.find(function (q) {
          return q.get('text') === text;
        });
      });
    }),

    _searchQuestion: function _searchQuestion(term, resolve, reject) {
      var questions = [];
      this.get('questions').forEach(function (q) {
        return questions.push(q.text);
      });
      this.get('model.questions').toArray().forEach(function (q) {
        return questions.push(q.get('text'));
      });
      this.get('store').query('questions-suggestion', {
        filter: {
          text: term,
          excluded_questions: questions.join('#$#')
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (questions) {
        return resolve(questions);
      }, reject);
    },

    _searchQuestionCategorySuggestion: function _searchQuestionCategorySuggestion(term, addFlag) {
      var _this2 = this;

      var excluded = [],
          suggestions = this.get('suggestedQuestions');
      this.get('questions').forEach(function (q) {
        return excluded.push(q.text);
      });
      this.get('model.questions').toArray().forEach(function (q) {
        return excluded.push(q.get('text'));
      });
      if (addFlag) {
        this.get('suggestedQuestions').toArray().forEach(function (sq) {
          return excluded.push(sq.text);
        });
      }
      this.set('disabledSave', true);
      this.get('store').query('question-category-suggestion', {
        filter: {
          text: term,
          excluded_questions: excluded.join('#$#')
        }
      }).then(function (questions) {
        _this2.set('disabledSave', false);
        addFlag ? questions.forEach(function (question) {
          return suggestions.pushObject(question);
        }) : questions.forEach(function (question) {
          return suggestions.removeObject(suggestions.find(function (s) {
            return s.get('text') === question.get('text');
          }));
        });
        _this2.set('suggestedQuestions', suggestions);
      });
    },

    closeQuestionModal: function closeQuestionModal() {
      this.set('questions', []);
      this.set('suggestedQuestions', []);
      this.set('questionsModalIsOpened', false);
    },

    actions: {
      setquestionsSelectRemoteController: function setquestionsSelectRemoteController(remoteController) {
        this.set('questionsSelectRemoteController', remoteController);
      },

      blurQuestionSelector: function blurQuestionSelector() {
        this.set('question_selector_just_closed', true);
        later(this, function () {
          this.set('question_selector_just_closed', false);
        }, 500);
        return true;
      },

      addQuestionsClick: function addQuestionsClick() {
        if (!this.get('question_selector_just_closed')) {
          this.set('question_selector_just_closed', false);
          this.get('questionsSelectRemoteController').actions.open();
        }
      },

      searchQuestions: function searchQuestions(term) {
        var _this3 = this;

        return new RSVP.Promise(function (resolve, reject) {
          debounce(_this3, _this3._searchQuestion, term, resolve, reject, 600);
        });
      },

      addQuestion: function addQuestion(question) {
        var data = question.get('data'),
            questions = this.get('questions');
        questions.push({ tag: data.tag, text: data.text });
        this.set('questions', questions);
        this._searchQuestionCategorySuggestion(data.text, true);
        this.notifyPropertyChange('questions');
      },

      removeSuggestion: function removeSuggestion(suggestion) {
        var propQuestion = this.get('suggestedQuestions');
        propQuestion.removeObject(suggestion);
        this.set('suggestedQuestions', propQuestion);
      },

      removeQuestion: function removeQuestion(question) {
        var questions = this.get('questions');
        questions.removeObject(question);
        this.set('questions', questions);
        this._searchQuestionCategorySuggestion(question.text, false);
      },

      openQuestionsModal: function openQuestionsModal() {
        this.set('questions', []);
        this.set('suggestedQuestions', []);
        this.set('questionsModalIsOpened', true);
      },

      closeQuestionsModal: function closeQuestionsModal() {
        this.closeQuestionModal();
      },

      saveQuestion: function saveQuestion() {
        var _this4 = this;

        this.get('questions').forEach(function (question) {
          _this4.get('model.questions').createFragment({ tag: question.tag, text: question.text });
        });
        this.get('suggestedQuestions').forEach(function (question) {
          _this4.get('model.questions').createFragment({ tag: question.get('tag'), text: question.get('text') });
        });
        this.closeQuestionModal();
      }
    }
  });
});