define('due-dashboard/components/center-tooltip', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  exports['default'] = _ember['default'].Component.extend({
    classNames: 'due-center-tooltip',
    classNamesBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      var text = this.get('text');
      var theme = ['tooltipster-basic'];
      if (this.get('theme') != 'default') {
        theme = ['tooltipster-noir', 'tooltipster-noir-customized', 'tooltipster-noir-customized-centered'];
      }
      $(this.$().parent()).tooltipster({
        theme: theme,
        side: this.get('side') || 'right',
        content: text.toString(),
        contentAsHTML: true,
        hideOnClick: false,
        animation: 'grow',
        delay: 150
      });
    },
    willDestroyElement: function willDestroyElement() {
      $(this.$().parent()).tooltipster('destroy');
    }
  });
});