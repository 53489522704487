define('due-dashboard/components/due-tag-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',
    currentLanguage: computed.alias('currentAccount.content.language.tag'),

    isHighlighted: computed('index', 'highlightedOptionIndex', 'tag', function () {
      return this.get('index') === this.get('highlightedOptionIndex');
    }),

    name: computed('tag', function () {
      return this.get('tag.translations')[this.get('currentLanguage').toLowerCase()] ? this.get('tag.translations')[this.get('currentLanguage').toLowerCase()]['text'] : this.get('tag.name');
    }),

    actions: {
      selectOption: function selectOption(option) {
        this.get('selectOption')(option);
      }
    }

  });
});