define('due-dashboard/controllers/companies/surveys/edit/mailing-external', ['exports', 'ember', 'due-dashboard/mixins/help-zone'], function (exports, _ember, _dueDashboardMixinsHelpZone) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend(_dueDashboardMixinsHelpZone['default'], {

    selectedProviderLink: computed('selectedProvider.email_placeholder', 'model.survey.surveyURL', function () {
      var url = this.get('model.survey.surveyURL');
      return url + '?ref_mail=' + this.get('selectedProvider.email_placeholder');
    })

  });
});