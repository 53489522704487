define('due-dashboard/helpers/get-text-color', ['exports', 'ember', 'npm:chroma-js'], function (exports, _ember, _npmChromaJs) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  /* globals _ */

  exports['default'] = _ember['default'].Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1);

    var color = _ref2[0];

    var ret = '#ffffff';
    if (color) {
      var brightness = function brightness(color) {
        var _rgb = new _npmChromaJs['default'](color).rgb();

        var _rgb2 = _slicedToArray(_rgb, 3);

        var r = _rgb2[0];
        var g = _rgb2[1];
        var b = _rgb2[2];

        return (r * 299 + g * 587 + b * 114) / 1000.0;
      };
      var brighten = function brighten(color) {
        var rgb = new _npmChromaJs['default'](color).rgb();
        var amount = Math.round(255 * (20 / 100.0));
        return new _npmChromaJs['default'](rgb.map(function (c) {
          return _.max([0, _.min([255, c - amount])]);
        })).hex();
      };
      ret = brightness(brighten(new _npmChromaJs['default'](color))) < 128 ? '#ffffff' : '#333333';
    }
    return ret;
  });
});