define('due-dashboard/controllers/companies/scenarios/index', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var filterBy = _Ember$computed.filterBy;
  var alias = _Ember$computed.alias;
  exports['default'] = Controller.extend({

    filterOption: [{ key: 'all', value: 'all' }, { key: 'survey_end', value: 'survey_end' }, { key: 'company_new_feedback', value: 'company_new_feedback' }, { key: 'survey_send', value: 'survey_send' }],

    scenarioFilter: computed(function () {
      return this.get('filterOption')[0];
    }),

    persistentScenarios: filterBy('model.scenarios', 'isDeleted', false),

    filteredScenarios: computed('persistentScenarios', 'scenarioFilter', function () {
      var filter = this.get('scenarioFilter.key');
      if (filter === 'all') {
        return this.get('persistentScenarios');
      } else {
        return this.get('persistentScenarios').filterBy('category_hook_key', filter);
      }
    }),

    filterOption: computed('currentAccount.isFromChina', function () {
      if (this.get('currentAccount.isFromChina')) {
        return [{ key: 'survey_end', value: 'survey_end' }];
      }
      return [{ key: 'all', value: 'all' }, { key: 'survey_end', value: 'survey_end' }, { key: 'company_new_feedback', value: 'company_new_feedback' }, { key: 'survey_send', value: 'survey_send' }];
    }),

    canSwitchPosition: computed.equal('scenarioFilter.key', 'all'),

    canEditScenario: computed('currentAccount.content.profile', 'currentAccount.content.account_custom_profile_right.scenarios_rights', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return this.get('currentAccount.content.account_custom_profile_right.scenarios_rights') === true;
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),
    canCreateScenario: alias('canEditScenario'),
    canDeleteScenario: alias('canEditScenario'),

    actions: {
      switchPosition: function switchPosition(scenario, direction) {
        var reorderedScenarios = this.get('persistentScenarios').toArray();
        var sc_position = reorderedScenarios.indexOf(scenario);
        if (sc_position + direction < 0 || sc_position + direction === reorderedScenarios.length) {
          return;
        }
        var tmp = reorderedScenarios[sc_position];
        reorderedScenarios[sc_position] = reorderedScenarios[sc_position + direction];
        reorderedScenarios[sc_position + direction] = tmp;
        this.send('reorderScenarios', this.get('model.company'), reorderedScenarios, scenario);
      }
    }

  });
});