define('due-dashboard/components/dashboard-title', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['dashboard-title-filters-and-actions'],
    i18n: service(),
    navigator: service(),

    displayedTitle: computed('navigator.currentPath', 'title', function () {
      return this.get('title') || this.get('i18n').t('titles.' + this.get('navigator.currentPath'));
    })
  });
});