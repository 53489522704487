define('due-dashboard/controllers/companies/surveys/edit/bulk-dispatch/index', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {

  /* global $ */

  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    queryParams: ['type'],
    type: 'email',

    survey: computed.alias('model.survey'),
    survey_revision: computed.alias('model.survey_revision'),
    csv_upload: computed.alias('model.csv_upload'),
    csvError: computed.alias('model.csvError'),
    csvSaved: computed.alias('model.csvSaved'),
    csvIsUploading: computed.alias('model.IsUploading'),
    raw_content: computed.alias('model.raw_content'),

    bulk_dispatch: _ember['default'].computed.alias('csv_upload.bulk_dispatch'),

    canGoToNextStep: computed('csvError', 'csv_upload.bulk_dispatch.csv_body.[]', function () {
      return !this.get('csvError') && this.get('csv_upload.bulk_dispatch.csv_body.length');
    }),

    multiLanguages: computed('survey_revision.survey_languages', function () {
      return this.get('survey_revision.survey_languages').toArray().length > 1;
    }),

    sendCSV: function sendCSV() {
      var _this = this;

      this.set('csvError', null);
      this.set('csvSaved', null);
      this.set('csvIsUploading', true);
      this.set('csv_upload.type', this.get('content_type') + '/' + this.get('type'));
      return this.get('csv_upload').save().then(function (response) {
        // TODO: ??
        return response.belongsTo('bulk_dispatch').reload().then(function () {
          if (!_this.get('csv_upload.bulk_dispatch.isLoading')) {
            _this.get('csv_upload.bulk_dispatch.content').reload();
          }
          _this.checkLanguages();
          _this.set('csvSaved', true);
        });
      })['catch'](function (response) {
        _this.set('csvError', response.errors.map(function (error) {
          return '' + (error.meta && error.meta.line ? "error line " + error.meta.line + " : " : "") + (error.detail || error.title);
        }).join("<br>")); // TODO: i18n
        if (_this.get('csvToUpload')) {
          var input = $('#upload-csv-input')[0];
          input.attributes.src.value = "";
          input.firstChild.firstElementChild.value = "";
        }
        throw response;
      })['finally'](function () {
        _this.set('csvIsUploading', false);
      });
    },

    checkLanguages: function checkLanguages() {
      if (this.get('model.survey.active_revision.survey_languages.length') > 1 && !this.get('csv_upload.bulk_dispatch.csv_headers')) {
        this.set('warningLanguage', "Your survey handles many languages, you should use use a 'Language' column in your list"); // TODO: i18n
      } else {
          this.set('warningLanguage', null);
        }
    },

    sendContactList: function sendContactList() {
      var csv = this.get('csvToUpload');
      if (!csv) {
        return _rsvp['default'].reject('empty_file');
      }
      var match = csv.match('^data:(.*?);base64,(.*)$');
      this.set('content_type', 'base64');
      this.set('csv_upload.content', match[2]);
      return this.sendCSV();
    },

    handleError: function handleError(error) {
      var errorText;
      if (error.errors) {
        errorText = error.errors.map(function (error) {
          return error.title + ': ' + error.detail;
        }).join('<br>');
        if (errorText.match(/.*DOCTYPE html.*/)) {
          errorText = this.get('i18n').t('errors.unkown_csv_error');
        }
      } else {
        errorText = this.get('i18n').t('errors.' + error);
      }
      this.set('csvError', errorText);
      this.set('csvIsUploading', false);
    },

    actions: {

      goToTemplate: function goToTemplate() {
        var _this2 = this;

        this.get('store').findAll('template').then(function (templates) {
          if (templates.content.length > 0) {
            _this2.transitionToRoute('companies.surveys.edit.bulk-dispatch.template', _this2.get('model.survey.id'), _this2.get('csv_upload.bulk_dispatch.id'));
          } else {
            var template = _this2.store.createRecord('template');
            var bulk_dispatch_id = _this2.get('csv_upload.bulk_dispatch.id');
            _this2.transitionToRoute('companies.templates.edit', template, { queryParams: { bulk_dispatch_id: bulk_dispatch_id } });
          }
        });
      },

      sendCsv: function sendCsv() {
        if (!this.get('raw_content')) {
          return _ember['default'].run.later(this, function () {
            return this.handleError('empty_file');
          }, 10);
        }
        this.set('csv_upload.content', this.get('raw_content'));
        this.set('content_type', 'plain');
        this.sendCSV();
      },

      handleLoad: function handleLoad(deferred) {
        var _this3 = this;

        this.sendContactList().then(function () {
          deferred.resolve();
        })['catch'](function (error) {
          deferred.reject(error);
        });
        deferred.promise['catch'](function (error) {
          _this3.handleError(error);
        });
      }
    }

  });
});