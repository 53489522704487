define('due-dashboard/models/magento-company-integration', ['exports', 'due-dashboard/models/company-integration', 'ember-data', 'ember'], function (exports, _dueDashboardModelsCompanyIntegration, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _dueDashboardModelsCompanyIntegration['default'].extend({
    name: 'magento',
    post_confirmed_order_delay_days: attr('number', { defaultValue: 7 }),
    post_confirmed_order_survey: belongsTo('Survey', { async: true }),
    post_confirmed_order_template: belongsTo('template', { async: true }),
    magento_survey_filters: hasMany('MagentoSurveyFilters'),
    send_on_null: attr({ defaultValue: true }),
    segment: belongsTo('Segment'),

    save: function save() {
      return this._super(arguments).then(function (magento_ci) {
        var to_delete = magento_ci.get('magento_survey_filters').filter(function (msf) {
          return (msf.get('segment_value.length') || 0) === 0 || !msf.get('survey.content');
        });
        to_delete.forEach(function (msf) {
          if (msf.get('isNew')) {
            msf.deleteRecord();
          } else {
            msf.destroyRecord();
          }
        });
        return _ember['default'].RSVP.all(magento_ci.get('magento_survey_filters').filterBy('isDeleted', false).uniqBy('segment_value').map(function (msf) {
          return msf.save();
        }));
      });
    }

  });
});