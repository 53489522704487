define("due-dashboard/helpers/get-rgba-from-hex", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Helper.helper(function (hex, opacity) {
    var r = 0,
        g = 0,
        b = 0,
        a = opacity || 0.2;
    // 3 digits
    if (hex.length == 4) {
      r = "0x" + hex[1] + hex[1];
      g = "0x" + hex[2] + hex[2];
      b = "0x" + hex[3] + hex[3];
      // 6 digits
    } else if (hex.length == 7) {
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
      }
    return "rgb(" + +r + "," + +g + "," + +b + "," + a + ")";
  });
});