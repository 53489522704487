define('due-dashboard/serializers/resources', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'resources';
    },

    normalize: function normalize(typeClass, hash) {
      var defalutAttrs = ['templates-default-bodies', 'templates-default-subjects', 'templates-wordings'];
      defalutAttrs.forEach(function (attributeName) {
        _.forEach(hash.attributes[attributeName], function (value, key) {
          hash.attributes[attributeName][key.underscore()] = value;
          if (key.underscore() !== key) {
            delete hash.attributes[attributeName][key];
          }
        });
      });

      return this._super.apply(this, arguments);
    }
  });
});