define('due-dashboard/components/change-end-social-networks-scenario-action', ['exports', 'ember', 'due-dashboard/components/scenario-action-item'], function (exports, _ember, _dueDashboardComponentsScenarioActionItem) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var and = computed.and;
  var empty = computed.empty;
  var mapBy = computed.mapBy;
  var setDiff = computed.setDiff;
  exports['default'] = _dueDashboardComponentsScenarioActionItem['default'].extend({

    social_networks: [],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.store.findAll('social-network', { reload: true }).then(function (sn) {
        _this.set('social_networks', sn);
      });
    },

    /*
    ** Error handling
    */

    errorObserverVars: {
      array_key: 'action.social_networks',
      error_prefix: 'change_end_sn_link',
      error_suffix: 'social_network.tag',
      error_target: 'validations.attrs.link.error'
    },

    emptySn: empty('action.social_networks'),
    noSnError: and('emptySn', 'display_error'),
    link_error_key: new _ember['default'].Object(),

    error_keys: computed(function () {
      return this.get('errors.errorKeys').filter(function (a) {
        return !!a.match(/change_end_sn_link-[A-Z]/);
      });
    }).volatile(),

    displayError: observer('try_step', function () {
      this.set('display_error', true);
    }),

    /*
    ** Core
    */

    selectedNetworks: mapBy('action.social_networks', 'social_network.content'),
    remainingNetworks: setDiff('social_networks', 'selectedNetworks'),

    actions: {

      addSocialNetwork: function addSocialNetwork(social_network) {
        var action = this.get('action');
        var act_sn = this.store.createRecord('change-end-social-networks-scenario-actions-social-network', {
          action: action,
          social_network: social_network
        });
        this.get('link_error_key').set(social_network.get('tag'), 'change_end_sn_link-' + social_network.get('tag'));
        action.get('social_networks').addObject(act_sn);
      },

      removeSocialNetwork: function removeSocialNetwork(act_sn) {
        var action = this.get('action');
        this.get('link_error_key').set(act_sn.get('social_network.tag'), 'change_end_sn_link-' + act_sn.get('social_network.tag'));
        action.get('social_networks').removeObject(act_sn);
      }
    }
  });
});