define('due-dashboard/services/sidebar', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  exports['default'] = Service.extend({

    handleResize: function handleResize() {
      var old_ww = this.get('w_w');
      this.set('w_w', $(window).width());
      if (old_ww && old_ww > this.get('w_w')) {
        this.close();
      }
    },

    bindResizeEvent: (function () {
      this.handleResize();
      $(window).on('resize', _ember['default'].run.bind(this, this.handleResize));
    }).on('init'),

    opened: computed.oneWay('initiallyOpened'),
    initiallyOpened: computed(function () {
      return $(window).width() > 1200;
    }),

    dashboardSection: true,

    close: function close() {
      if ($(window).width() < 1200) {
        this.set('opened', false);
      }
    }

  });
});