define("due-dashboard/templates/settings/integrations/zapier/manage", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/zapier/manage.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-block instructions zapier manage");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ol");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://zapier.com/developer/invite/104132/b72f1d904e871b92e9509bdff9357df8/");
        dom.setAttribute(el4, "target", "_blank");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://zapier.com/app/editor");
        dom.setAttribute(el4, "target", "_blank");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element1, [5]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element2, 0, 0);
        morphs[2] = dom.createMorphAt(element2, 3, 3);
        morphs[3] = dom.createMorphAt(element3, 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [2]), 0, 0);
        morphs[5] = dom.createMorphAt(element3, 4, 4);
        morphs[6] = dom.createMorphAt(element4, 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element4, [2]), 0, 0);
        morphs[8] = dom.createMorphAt(element4, 4, 4);
        morphs[9] = dom.createMorphAt(dom.childAt(element1, [7]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["settings.integrations.zapier.instructions.title"], [], ["loc", [null, [2, 6], [2, 61]]], 0, 0], ["inline", "t", ["settings.integrations.zapier.instructions.api_key"], [], ["loc", [null, [4, 8], [4, 65]]], 0, 0], ["inline", "api-key-field", [], ["apiKey", ["subexpr", "@mut", [["get", "model.company_integration.api_key.id", ["loc", [null, [5, 27], [5, 63]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [5, 4], [5, 65]]], 0, 0], ["inline", "t", ["settings.integrations.zapier.instructions.install_app.start"], [], ["loc", [null, [6, 8], [6, 75]]], 0, 0], ["inline", "t", ["settings.integrations.zapier.instructions.install_app.link"], [], ["loc", [null, [6, 179], [6, 245]]], 0, 0], ["inline", "t", ["settings.integrations.zapier.instructions.install_app.end"], [], ["loc", [null, [6, 250], [6, 315]]], 0, 0], ["inline", "t", ["settings.integrations.zapier.instructions.create_zap.start"], [], ["loc", [null, [7, 8], [7, 74]]], 0, 0], ["inline", "t", ["settings.integrations.zapier.instructions.create_zap.link"], [], ["loc", [null, [7, 131], [7, 196]]], 0, 0], ["inline", "t", ["settings.integrations.zapier.instructions.create_zap.end"], [], ["loc", [null, [7, 201], [7, 265]]], 0, 0], ["inline", "t", ["settings.integrations.zapier.instructions.past_api_key"], [], ["loc", [null, [8, 8], [8, 70]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});