define("due-dashboard/templates/components/due-dropdown", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "arrow top-arrow");
          var el2 = dom.createElement("div");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element1, 'style');
          return morphs;
        },
        statements: [["attribute", "style", ["get", "arrowLeftProperty", ["loc", [null, [3, 41], [3, 58]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "option-search");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "due-input", [], ["icon", "search", "name", "dropdown-search", "type", "text", "placeholder", ["subexpr", "t", [["get", "searchPlaceholderValue", ["loc", [null, [7, 82], [7, 104]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 79], [7, 105]]], 0, 0], "value", ["subexpr", "@mut", [["get", "searchValue", ["loc", [null, [7, 112], [7, 123]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "@mut", [["get", "triggerSearch", ["loc", [null, [7, 133], [7, 146]]], 0, 0, 0, 0]], [], [], 0, 0], "showLoading", true], ["loc", [null, [7, 6], [7, 165]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 16,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "loading-line");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "loader");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "text-loader");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["words.loading"], [], ["loc", [null, [14, 34], [14, 55]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 6
              },
              "end": {
                "line": 19,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "no-result");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["words.no_result"], [], ["loc", [null, [18, 33], [18, 57]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 4
            },
            "end": {
              "line": 20,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "options.length", ["loc", [null, [17, 16], [17, 30]]], 0, 0, 0, 0], 0], [], ["loc", [null, [17, 12], [17, 33]]], 0, 0]], [], 0, null, ["loc", [null, [17, 6], [19, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 10
                  },
                  "end": {
                    "line": 35,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "yield", [["get", "option", ["loc", [null, [34, 20], [34, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [34, 12], [34, 28]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 8
                },
                "end": {
                  "line": 36,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "due-dropdown-element", [], ["valueKey", ["subexpr", "@mut", [["get", "valueKey", ["loc", [null, [33, 43], [33, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "option", ["subexpr", "@mut", [["get", "option", ["loc", [null, [33, 59], [33, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [33, 75], [33, 83]]], 0, 0, 0, 0]], [], [], 0, 0], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [33, 90], [33, 95]]], 0, 0, 0, 0]], [], [], 0, 0], "highlightedIndex", ["subexpr", "@mut", [["get", "hoveredOptIdx", ["loc", [null, [33, 113], [33, 126]]], 0, 0, 0, 0]], [], [], 0, 0], "displayAttribute", ["subexpr", "@mut", [["get", "displayAttribute", ["loc", [null, [33, 144], [33, 160]]], 0, 0, 0, 0]], [], [], 0, 0], "triggerOption", ["subexpr", "@mut", [["get", "triggerAction", ["loc", [null, [33, 175], [33, 188]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [33, 10], [35, 35]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 8
                },
                "end": {
                  "line": 38,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "due-dropdown-element", [], ["valueKey", ["subexpr", "@mut", [["get", "valueKey", ["loc", [null, [37, 42], [37, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "option", ["subexpr", "@mut", [["get", "option", ["loc", [null, [37, 58], [37, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [37, 74], [37, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [37, 89], [37, 94]]], 0, 0, 0, 0]], [], [], 0, 0], "highlightedIndex", ["subexpr", "@mut", [["get", "hoveredOptIdx", ["loc", [null, [37, 112], [37, 125]]], 0, 0, 0, 0]], [], [], 0, 0], "displayAttribute", ["subexpr", "@mut", [["get", "displayAttribute", ["loc", [null, [37, 143], [37, 159]]], 0, 0, 0, 0]], [], [], 0, 0], "triggerOption", ["subexpr", "@mut", [["get", "triggerAction", ["loc", [null, [37, 174], [37, 187]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [37, 10], [37, 189]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 6
              },
              "end": {
                "line": 39,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [32, 14], [32, 22]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [32, 8], [38, 15]]]]],
          locals: ["option"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 4
            },
            "end": {
              "line": 40,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "infinite-scroll", [], ["tagName", "div", "parentScroll", ".due-dropdown .due-dropdown-menu ul", "spinnerTagName", "li", "spinnerClassNames", "hide-spinner", "loadMore", ["subexpr", "@mut", [["get", "loadMoreAction", ["loc", [null, [26, 17], [26, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "records", ["subexpr", "@mut", [["get", "options", ["loc", [null, [27, 16], [27, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "maxHeight", ["subexpr", "@mut", [["get", "computedMaxHeight", ["loc", [null, [28, 18], [28, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "fromDropdown", true], 0, null, ["loc", [null, [22, 6], [39, 26]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 12
                    },
                    "end": {
                      "line": 46,
                      "column": 12
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "yield", [["get", "option", ["loc", [null, [45, 22], [45, 28]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 14], [45, 30]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 10
                  },
                  "end": {
                    "line": 47,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "due-dropdown-element", [], ["valueKey", ["subexpr", "@mut", [["get", "valueKey", ["loc", [null, [44, 45], [44, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "option", ["subexpr", "@mut", [["get", "option", ["loc", [null, [44, 61], [44, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [44, 77], [44, 85]]], 0, 0, 0, 0]], [], [], 0, 0], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [44, 92], [44, 97]]], 0, 0, 0, 0]], [], [], 0, 0], "highlightedIndex", ["subexpr", "@mut", [["get", "hoveredOptIdx", ["loc", [null, [44, 115], [44, 128]]], 0, 0, 0, 0]], [], [], 0, 0], "displayAttribute", ["subexpr", "@mut", [["get", "displayAttribute", ["loc", [null, [44, 146], [44, 162]]], 0, 0, 0, 0]], [], [], 0, 0], "triggerOption", ["subexpr", "@mut", [["get", "triggerAction", ["loc", [null, [44, 177], [44, 190]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [44, 12], [46, 37]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 47,
                    "column": 10
                  },
                  "end": {
                    "line": 49,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "due-dropdown-element", [], ["valueKey", ["subexpr", "@mut", [["get", "valueKey", ["loc", [null, [48, 44], [48, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "option", ["subexpr", "@mut", [["get", "option", ["loc", [null, [48, 60], [48, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [48, 76], [48, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [48, 91], [48, 96]]], 0, 0, 0, 0]], [], [], 0, 0], "highlightedIndex", ["subexpr", "@mut", [["get", "hoveredOptIdx", ["loc", [null, [48, 114], [48, 127]]], 0, 0, 0, 0]], [], [], 0, 0], "displayAttribute", ["subexpr", "@mut", [["get", "displayAttribute", ["loc", [null, [48, 145], [48, 161]]], 0, 0, 0, 0]], [], [], 0, 0], "triggerOption", ["subexpr", "@mut", [["get", "triggerAction", ["loc", [null, [48, 176], [48, 189]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [48, 12], [48, 191]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 8
                },
                "end": {
                  "line": 50,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [43, 16], [43, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [43, 10], [49, 17]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 8
                },
                "end": {
                  "line": 52,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "group-separator");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 6
              },
              "end": {
                "line": 53,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "not", [["subexpr", "eq", [["get", "option.type", ["loc", [null, [42, 23], [42, 34]]], 0, 0, 0, 0], "separator"], [], ["loc", [null, [42, 19], [42, 47]]], 0, 0]], [], ["loc", [null, [42, 14], [42, 48]]], 0, 0]], [], 0, 1, ["loc", [null, [42, 8], [52, 15]]]]],
          locals: ["option", "index"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 4
            },
            "end": {
              "line": 54,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "options", ["loc", [null, [41, 14], [41, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [41, 6], [53, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 4
            },
            "end": {
              "line": 60,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "loading-line");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "loader");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "text-loader");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["words.loading"], [], ["loc", [null, [58, 34], [58, 55]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 2
            },
            "end": {
              "line": 64,
              "column": 2
            }
          },
          "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "arrow bottom-arrow");
          var el2 = dom.createElement("div");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'style');
          return morphs;
        },
        statements: [["attribute", "style", ["get", "arrowLeftProperty", ["loc", [null, [63, 44], [63, 61]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 66,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/due-dropdown.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [4]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element2, 'class');
        morphs[1] = dom.createAttrMorph(element2, 'style');
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(element2, 2, 2);
        morphs[4] = dom.createAttrMorph(element3, 'style');
        morphs[5] = dom.createMorphAt(element3, 1, 1);
        morphs[6] = dom.createMorphAt(element3, 2, 2);
        morphs[7] = dom.createMorphAt(element3, 3, 3);
        morphs[8] = dom.createMorphAt(element2, 6, 6);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["due-dropdown-menu ", ["get", "show_class", ["loc", [null, [1, 32], [1, 42]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["subexpr", "eq", [["get", "fullWidth", ["loc", [null, [1, 54], [1, 63]]], 0, 0, 0, 0], true], [], ["loc", [null, [1, 50], [1, 69]]], 0, 0], "width-100"], [], ["loc", [null, [1, 45], [1, 83]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["get", "dropDownStyle", ["loc", [null, [1, 93], [1, 106]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["get", "showArrow", ["loc", [null, [2, 13], [2, 22]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "displayOnTop", ["loc", [null, [2, 28], [2, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 23], [2, 41]]], 0, 0]], [], ["loc", [null, [2, 8], [2, 42]]], 0, 0]], [], 0, null, ["loc", [null, [2, 2], [4, 9]]]], ["block", "if", [["get", "enableSearch", ["loc", [null, [5, 8], [5, 20]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [5, 2], [9, 9]]]], ["attribute", "style", ["get", "maxHeight", ["loc", [null, [10, 14], [10, 23]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "promise.isPending", ["loc", [null, [11, 10], [11, 27]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [11, 4], [20, 11]]]], ["block", "if", [["get", "loadMoreAction", ["loc", [null, [21, 10], [21, 24]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [21, 4], [54, 11]]]], ["block", "if", [["get", "loadMorePromise.isPending", ["loc", [null, [55, 10], [55, 35]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [55, 4], [60, 11]]]], ["block", "if", [["subexpr", "and", [["get", "showArrow", ["loc", [null, [62, 13], [62, 22]]], 0, 0, 0, 0], ["get", "displayOnTop", ["loc", [null, [62, 23], [62, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [62, 8], [62, 36]]], 0, 0]], [], 7, null, ["loc", [null, [62, 2], [64, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  })());
});