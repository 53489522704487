define('due-dashboard/components/dashboard-filters/languages', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    init: function init() {
      this._super.apply(this, arguments);
      this._searchLanguage('');
    },

    allLanguages: [],
    selectedLanguages: computed.map('filter.value', function (id) {
      return this.get('store').peekOrFind('language', id);
    }),
    availableLanguages: computed.setDiff('allLanguages', 'selectedLanguages'),

    dueInputProps: computed('i18n.locale', 'current_company', function () {
      var i18n = this.get('i18n');

      return {
        'class': 'filter-label',
        label: i18n.t('sidebar.filters.languages.title'),
        placeholder: i18n.t('global.choose_languages')
      };
    }),

    // private:

    _searchLanguage: function _searchLanguage(term) {
      var _this = this;

      return this.store.query('language', { filter: { name: term }, page: { number: 1, size: 20 } }).then(function (languages) {
        return _this.set('allLanguages', languages);
      });
    },

    actions: {
      /**
       * Search languages in the list from its name
       * @param {String} term Language name to search
       */
      searchLanguages: function searchLanguages(term) {
        run.debounce(this, this._searchLanguage, term, 600, true);
      },

      /**
       * Add the selected language to the filter list
       * @param {Model<Language>} language Language selected from dropdown
       */
      addLanguage: function addLanguage(language) {
        var value_copy = [].concat(_toConsumableArray(this.get('filter.value')));

        value_copy.addObject(language.get('id'));
        this.set('filter.value', value_copy);
      },

      /**
       * Delete a language from the filters
       * @param {Model<Language>} language Language to delete
       */
      removeLanguage: function removeLanguage(language) {
        var value_copy = [].concat(_toConsumableArray(this.get('filter.value')));

        value_copy.removeObject(language.get('id'));
        this.set('filter.value', value_copy);
      },

      /**
       * Delete the language filter from dashboard filters
       */
      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});