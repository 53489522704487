define('due-dashboard/components/due-dropdown', ['exports', 'ember', 'ember-data', 'ember-cli-keyboard-nav/mixins/keyboard-nav'], function (exports, _ember, _emberData, _emberCliKeyboardNavMixinsKeyboardNav) {

  /**
   * @author Youba SALMI <youba@diduenjoy.com>
   * @summary {{due-dropdown}} dropdown pannel
   *
   * @module
   * @argument {boolean} openDropdown - boolean to toggle dorpdown state
   * @argument {array} options - array of objects to display in the dropdown
   * @argument {object} [selected] - selected item
   * @argument {boolean} showArrow - show/hide the arrow
   * @argument {boolean} enableSearch - enable and show search field
   * @argument {string} displayAttribute - name of the property to display
   * @argument {string} [containerSelector] - DOM selector of the container that shoud enclosure the dropdown
   * @argument {number} triggerClientX - X coordinates of the trigger element
   * @argument {number} triggerClientY - Y coordinates of the trigger element
   * @argument {number} triggerHeight - height of the trigger element
   * @argument {number} [triggerWidth] - width of the trigger element
   * @argument {number} [dpMaxHeight] - maximum height of the dropdown in pixels
   * @argument {promise} [searchPromise] - search promise
   * @argument {function} triggerAction - handler of select action
   * @argument {function} [triggerSearch] - handler of search action
   * @example <caption>Full usage example of {{due-dropdown}} component</caption>
   * Usage :
   * ```hbs
   * {{due-dropdown
   *   openDropdown=openDropdown
   *   options=buttonActions
   *   showArrow=true
   *   enableSearch=enableSearch
   *   displayAttribute=displayAttribute
   *   containerSelector=containerSelector
   *   triggerClientX=triggerClientX
   *   triggerClientY=triggerClientY
   *   triggerHeight=triggerHeight
   *   dpMaxHeight=dpMaxHeight
   *   searchPromise=searchPromise
   *   triggerAction=(action "triggerAction" triggerAction)
   *   triggerSearch=(action 'triggerSearch' triggerSearch)
   * }}
   * ```
   */

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var htmlSafe = _ember['default'].String.htmlSafe;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Component.extend(_emberCliKeyboardNavMixinsKeyboardNav['default'], {
    tagName: 'div',
    classNames: ['due-dropdown'],
    attributeBindings: ['style'],
    left: 0,
    bottom: 0,
    dropDownWidth: 0,
    searchValue: null,
    updateWidth: false,
    displayOnTop: false,

    didInsertElement: function didInsertElement() {
      this.bindKeys(this.$('.due-dropdown-menu'));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.element) {
        var cntSelector = this.get('containerSelector'),
            searchInput = this.element.querySelector('input'),
            cntWidth = cntSelector && _ember['default'].$('body > .ember-view').find(cntSelector).length ? _ember['default'].$('body > .ember-view').find(cntSelector)[0].getBoundingClientRect().width : _ember['default'].$('body > .ember-view')[0].getBoundingClientRect().width;
        if (this.element.querySelector('.due-dropdown-menu')) {
          var width = Math.min(this.element.querySelector('.due-dropdown-menu').getBoundingClientRect().width, cntWidth);
          if (this.get('dropDownWidth') !== width && this.get('openDropdown') === true && this.get('showArrow')) {
            this.set('dropDownWidth', width);
            this.toggleProperty('updateWidth');
          }
          this.set('dropDownWidth', width);
        }
        if (searchInput) {
          searchInput.focus();
        }
      }
    },

    _updateDropDownPosition: function _updateDropDownPosition() {
      var cntSelector = this.get('containerSelector'),
          parent = cntSelector ? _ember['default'].$('body > .ember-view').find(cntSelector)[0] : _ember['default'].$('body > .ember-view')[0];
      if (parent && this.element) {
        var pageWidth = parent.clientWidth + parent.getBoundingClientRect().x,
            spaceLeft = pageWidth - this.get('triggerClientX'),
            heightLeft = parent.scrollHeight - this.get('triggerClientY'),
            dpHeight = this.element.querySelector('.due-dropdown-menu').getBoundingClientRect().height,
            dpWidth = this.element.querySelector('.due-dropdown-menu').getBoundingClientRect().width,
            width = this.get('dropDownWidth'),
            arrowPosition = this.get('arrowPosition');

        var realHeightLeft = parent.clientHeight - dpHeight;
        if (arrowPosition === 'center') {
          this.set('left', dpWidth / 2 - 20);
        }

        if (spaceLeft < width + 30 && !this.get('rightAlign')) {
          this.set('left', width + 10 - spaceLeft);
        } else if (spaceLeft < width + 30 && this.get('rightAlign')) {
          this.set('left', width - spaceLeft);
        }
        if (realHeightLeft < dpHeight + 20) {
          var bottom = this.get('triggerHeight') + (this.get('showArrow') ? +15 : 5);
          this.set('bottom', bottom);
        } else {
          this.set('bottom', 0);
        }
        this.set('displayOnTop', heightLeft < dpHeight + 20);
      }
    },

    _setDefaultValues: function _setDefaultValues() {
      this.set('searchValue', null);
      this.set('hoveredOptIdx', -1);
      if (this.get('enableSearch')) {
        this.get('triggerSearch')(null);
      }
    },

    dropDownStyle: computed('dropDownWidth', 'triggerClientX', 'triggerClientY', 'triggerWidth', 'updateWidth', 'showArrow', function () {
      this._updateDropDownPosition();
      var width = this.get('dropDownWidth');
      var bottom = this.get('bottom'),
          left = this.get('left'),
          showArrow = this.get('showArrow');
      var dpStyle = 'margin-top:' + (showArrow ? 10 : 5) + 'px;';
      if (!showArrow) dpStyle += 'min-width:' + this.get('triggerWidth') + 'px;';
      if (width && width !== 0) {
        dpStyle += 'width:' + width + 'px;';
      }
      if (left) {
        dpStyle += 'left: -' + left + 'px;';
      }
      if (bottom) {
        dpStyle += 'bottom:' + bottom + 'px;';
      }
      return htmlSafe(dpStyle);
    }),

    arrowLeftProperty: computed('left', 'dropDownWidth', function () {
      var threshold = this.get('arrowPosition') == 'center' ? '9px + ' : '10px + ';
      return htmlSafe('left: min(calc(' + threshold + this.get('left') + 'px), ' + this.get('dropDownWidth') + 'px);');
    }),

    show_class: computed('openDropdown', function () {
      this._setDefaultValues();
      return this.get('openDropdown') ? 'show-class' : 'hide-class';
    }),

    maxHeight: computed('dpMaxHeight', function () {
      var maxHeight = this.get('dpMaxHeight') || 200;
      this.set('computedMaxHeight', maxHeight);
      return htmlSafe('max-height:' + maxHeight + 'px;');
    }),

    promise: computed('searchPromise', function () {
      var promise = [this.get('searchPromise')];
      return _emberData['default'].PromiseArray.create({
        promise: new RSVP.Promise(function (resolve) {
          RSVP.allSettled(promise).then(function () {
            setTimeout(function () {
              resolve();
            }, 500);
          });
        })
      });
    }),

    hoveredOptIdx: computed('filterValue', function () {
      return this.get('filterValue') ? 0 : -1;
    }),

    searchPlaceholderValue: computed('searchPlaceholder', function () {
      return this.get('searchPlaceholder') ? this.get('searchPlaceholder') : 'words.search';
    }),

    onEscPress: function onEscPress() {
      this.set('openDropdown', false);
    },

    onDownPress: function onDownPress() {
      var index = this.get("hoveredOptIdx");
      this.set("hoveredOptIdx", ++index % this.get('options.length'));
      this._scrollToPosition();
    },

    onUpPress: function onUpPress() {
      var index = this.get('hoveredOptIdx');
      index--;
      if (index < 0) {
        index = this.get('options.length') - 1;
      }
      this.set("hoveredOptIdx", index);
      this._scrollToPosition();
    },

    onEnterPress: function onEnterPress() {
      var option = this.get("options").objectAt(this.get("hoveredOptIdx"));
      if (option) {
        this.get('triggerAction')(option);
        this.onEscPress();
      }
    },

    _scrollToPosition: function _scrollToPosition() {
      if (this.get('hoveredOptIdx') !== -1) {
        this.$('.due-dropdown-menu > ul').animate({
          scrollTop: this.get('hoveredOptIdx') * this.$('li').first().outerHeight()
        }, 200);
      }
    }
  });
});