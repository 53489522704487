define('due-dashboard/routes/companies/surveys/edit/mailing-external', ['exports', 'ember', 'due-dashboard/mixins/survey-step'], function (exports, _ember, _dueDashboardMixinsSurveyStep) {
  exports['default'] = _ember['default'].Route.extend(_dueDashboardMixinsSurveyStep['default'], {

    model: function model() {
      return _ember['default'].RSVP.hash({
        survey: this.modelFor('companies.surveys.edit').survey,
        mailing_providers: [{ id: 1, name: 'Mail Chimp', email_placeholder: '*|URL:EMAIL|*' }, { id: 2, name: 'Mail Jet', email_placeholder: '[[EMAIL_TO]]' }, { id: 3, name: 'Emarsys', email_placeholder: '$E-mail$' }, { id: 4, name: 'Send in Blue', email_placeholder: '{EMAIL}' }, { id: 5, name: 'Campaign Monitor', email_placeholder: '[email]' }, { id: 6, name: 'Selligent', email_placeholder: '~EMAIL~' }, { id: 7, name: 'Zendesk', email_placeholder: '{{ticket.requester.email}}' }, { id: 8, name: 'SalesForce', email_placeholder: '{!Contact.Email}' }, { id: 9, name: 'direct URL', email_placeholder: 'EMAIL' }]
      });
    },

    stepConfig: {
      layout: 'preview'
    },

    actions: {
      nextStep: function nextStep() {
        this.replaceWith('companies.overview');
      }
    }

  });
});