define("due-dashboard/templates/companies/graphs/edit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/companies/graphs/edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "dashboard-filters", [], ["filtersUpdated", ["subexpr", "action", ["filtersUpdated"], [], ["loc", [null, [2, 38], [2, 63]]], 0, 0]], ["loc", [null, [2, 3], [2, 65]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 12,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/companies/graphs/edit.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [9, 22], [9, 34]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["saveAndTransition"], [], ["loc", [null, [9, 37], [9, 67]]], 0, 0], ["content", "button.text", ["loc", [null, [10, 8], [10, 23]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/companies/graphs/edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "graphs-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "saving-box");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [1]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(element2, 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "dashboard-title", [], ["title", ["subexpr", "t", ["titles.companies.graphs.index"], [], ["loc", [null, [1, 25], [1, 60]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [3, 20]]]], ["inline", "graphs/edit-view", [], ["graph", ["subexpr", "@mut", [["get", "model.graph", ["loc", [null, [5, 27], [5, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "filters", ["subexpr", "@mut", [["get", "globalFilters", ["loc", [null, [5, 47], [5, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "saveAction", ["subexpr", "action", ["saveCustomGraph"], [], ["loc", [null, [5, 72], [5, 98]]], 0, 0]], ["loc", [null, [5, 2], [5, 100]]], 0, 0], ["inline", "previous-button", [], ["prevStep", ["subexpr", "action", ["cancelCustomGraph"], [], ["loc", [null, [7, 31], [7, 59]]], 0, 0]], ["loc", [null, [7, 4], [7, 61]]], 0, 0], ["block", "due-button", [], ["type", "transition", "icon", "save", "text", ["subexpr", "t", ["words.save"], [], ["loc", [null, [8, 53], [8, 69]]], 0, 0], "promise", ["subexpr", "@mut", [["get", "savePromise", ["loc", [null, [8, 78], [8, 89]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [8, 4], [12, 19]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});