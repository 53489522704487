define('due-dashboard/controllers/companies/templates/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {

      callModifyTemplate: function callModifyTemplate(template) {
        this.send('modifyTemplate', template);
      }

    }
  });
});