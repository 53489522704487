define("due-dashboard/templates/components/digests/parts/one-optional-title-part", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/components/digests/parts/one-optional-title-part.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "optional");
        var el3 = dom.createTextNode("(");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(")");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element0, 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [2]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", [["get", "label", ["loc", [null, [1, 11], [1, 16]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 7], [1, 18]]], 0, 0], ["inline", "t", ["digest.optional"], [], ["loc", [null, [1, 43], [1, 66]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [3, 20], [3, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", [["get", "placeholder", ["loc", [null, [3, 41], [3, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 38], [3, 53]]], 0, 0], "onChange", ["subexpr", "action", ["saveDigestDelay"], [], ["loc", [null, [3, 63], [3, 89]]], 0, 0]], ["loc", [null, [3, 2], [3, 91]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});