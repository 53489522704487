define('due-dashboard/routes/companies/surveys/edit/bulk-dispatch/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    stepConfig: {
      layout: 'preview'
    },

    model: function model() {
      var _this = this;

      var survey = this.modelFor('companies.surveys.edit').survey;
      return survey.get('active_revision').then(function (active_revision) {
        _this.store.unloadAll('CsvUpload');
        var csv_upload = _this.store.createRecord('CsvUpload');
        csv_upload.set('survey_revision', active_revision);
        return _ember['default'].RSVP.hash({
          survey: _this.modelFor('companies.surveys.edit').survey,
          survey_revision: active_revision,
          csv_upload: csv_upload,
          csvError: null,
          csvSaved: null,
          csvIsUploading: false,
          raw_content: ''
        });
      });
    },

    actions: {
      prevStep: function prevStep() {
        this.transitionTo('companies.surveys.edit.publishing');
      }
    }

  });
});