define("due-dashboard/templates/settings/integrations/hubspot/configure", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/settings/integrations/hubspot/configure.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "button.class", ["loc", [null, [5, 18], [5, 30]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "button.text", ["loc", [null, [5, 33], [5, 48]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 15
          }
        },
        "moduleName": "due-dashboard/templates/settings/integrations/hubspot/configure.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-block instructions hubspot");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["settings.integrations.hubspot.instructions"], [], ["loc", [null, [2, 2], [2, 52]]], 0, 0], ["block", "due-button", [], ["class", "create-connection", "type", "transition", "promise", ["subexpr", "@mut", [["get", "parent.promise", ["loc", [null, [4, 66], [4, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "t", ["settings.integrations.hubspot.create_connection"], [], ["loc", [null, [4, 86], [4, 139]]], 0, 0], "href", ["subexpr", "@mut", [["get", "parent.login_url", ["loc", [null, [4, 145], [4, 161]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [4, 0], [6, 15]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});