define('due-dashboard/components/v2/libraries/surveys/navbar/steps/steps-item', ['exports', 'ember', 'ember-sortable/mixins/sortable-item'], function (exports, _ember, _emberSortableMixinsSortableItem) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_emberSortableMixinsSortableItem['default'], {
    classNameBindings: ["selected"]
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/surveys-library/navbar/content}} alert component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */