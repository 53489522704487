define('due-dashboard/serializers/account-custom-profile-right', ['exports', 'ember-data', 'due-dashboard/mixins/embedded-jsonapi-records'], function (exports, _emberData, _dueDashboardMixinsEmbeddedJsonapiRecords) {
  var JSONAPISerializer = _emberData['default'].JSONAPISerializer;
  exports['default'] = JSONAPISerializer.extend(_dueDashboardMixinsEmbeddedJsonapiRecords['default'], {
    attrs: {
      feedback_filters: { embedded: 'always' },
      limit_user_management_by_custom_roles: { embedded: 'always' }
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.data.attributes["updated-at"];

      return json;
    }

  });
});