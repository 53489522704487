define('due-dashboard/routes/companies/surveys/edit', ['exports', 'ember', 'ember-data', 'due-dashboard/mixins/survey'], function (exports, _ember, _emberData, _dueDashboardMixinsSurvey) {
  /* globals $ */

  var service = _ember['default'].inject.service;
  var RSVP = _ember['default'].RSVP;
  var Route = _ember['default'].Route;
  var computed = _ember['default'].computed;
  exports['default'] = Route.extend(_dueDashboardMixinsSurvey['default'], {

    errors: service(),
    keyManager: service(),

    model: function model(params) {
      return RSVP.hash({
        stepGroups: this.get('computedStepGroups'),
        currentStep: this.get('currentStep'),
        survey: this.store.find('survey', params.survey_id),
        surveyDraft: this.store.find('survey-draft', params.survey_id)
      });
    },

    survey_draft: computed.alias('currentModel.surveyDraft'),
    survey: computed.alias('currentModel.survey'),

    saveSurvey: function saveSurvey() {
      return this.get('survey').save();
      // todo promise status
    },

    // ---------------------------------------------------------

    actions: {

      sendNextStep: function sendNextStep() {
        switch (this.get('currentStep')) {
          case 'main':
          case 'layout':
          case 'summary':
          case 'publishing':
          case 'mailing-due':
            $('#save-survey-step').click();
            return;
        }
      },

      sendPreviousStep: function sendPreviousStep() {
        switch (this.get('currentStep')) {
          case 'main':
            this.transitionTo('companies.surveys');
            break;
          case 'publishing':
            $('#back-to-overview').click();
            break;
          default:
            this.send('prevStep');
            break;
        }
        return false;
      },

      didTransition: function didTransition() {
        this.controller.resetSavingStates();
        // const enterEvent = this.get('keyManager').addMacro({
        //   executionKey: 'enter',
        //   callback: run.bind(this, function() {
        //     this.send('sendNextStep');
        //   }),
        //   keyEvent: 'keydown',
        //   priority: 10,
        // });
        // this.set('enterEvent', enterEvent);
        // const backspaceEvent = this.get('keyManager').addMacro({
        //   executionKey: 'backspace',
        //   callback: run.bind(this, function() {
        //     this.send('sendPreviousStep');
        //   }),
        //   keyEvent: 'keydown',
        //   priority: 10,
        // });
        // this.set('backspaceEvent', backspaceEvent);
        return true; // Bubble the didTransition event
      },

      willTransition: function willTransition(a) {
        // const kM = this.get('keyManager');
        // ['enterEvent', 'backspaceEvent'].forEach(registered => kM.removeMacro(this.get(registered)));
        if (a.targetName.split('.')[2] !== "edit") {
          this.currentModel.survey.reload();
        }
        return true;
      },

      surveyLanguagesChanged: function surveyLanguagesChanged() {
        return this.saveDraftRecursive();
      },

      surveyChanged: function surveyChanged() {
        this.saveSurvey()['catch'](function (e) {
          if (!(e instanceof _emberData['default'].InvalidError)) {
            throw e;
          }
        });
      },

      validateAndNext: function validateAndNext() {
        this.send('nextStep');
      }

    }

  });
});