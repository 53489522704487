define('due-dashboard/initializers/model-rollback-attribute', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = {
    name: 'model-rollback-attribute',
    initialize: function initialize() {
      _emberData['default'].Model.reopen({
        /**
          Discards any unsaved changes to the given attribute.
          Taken from Ember canary feature.
           Example
           ```javascript
          record.get('name'); // 'Untitled Document'
          record.set('name', 'Doc 1');
          record.get('name'); // 'Doc 1'
          record.rollbackAttribute('name');
          record.get('name'); // 'Untitled Document'
          ```
           @method rollbackAttribute
        */
        rollbackAttribute: function rollbackAttribute(key) {
          var internal = this._internalModel;

          if (key in internal._attributes) {
            var value = key in internal._inFlightAttributes ? internal._inFlightAttributes[key] : internal._data[key];

            this.set(key, value);
          }
        }
      });
    }
  };
});