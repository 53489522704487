define('due-dashboard/models/match-surveys-feedback-filter', ['exports', 'ember-data', 'due-dashboard/utils/has-many-through', 'due-dashboard/models/feedback-filter'], function (exports, _emberData, _dueDashboardUtilsHasManyThrough, _dueDashboardModelsFeedbackFilter) {
  var hasMany = _emberData['default'].hasMany;

  var M = _dueDashboardModelsFeedbackFilter['default'].extend({
    match_surveys_feedback_filters_surveys: hasMany('match-surveys-feedback-filters-survey', { async: true }),

    defineSavepoint: function defineSavepoint() {
      var _this = this;

      return this.get('match_surveys_feedback_filters_surveys').then(function (filters_surveys) {
        _this.set('_savepoint_filters_surveys', filters_surveys.toArray());
        return _this;
      });
    },

    rollbackToSavepoint: function rollbackToSavepoint() {
      this.rollbackAttributes();
      var savepoint = this.get('_savepoint_filters_surveys');
      if (savepoint) {
        this.set('match_surveys_feedback_filters_surveys', savepoint);
        savepoint.map(function (a) {
          return a.rollbackAttributes();
        });
      }
    }
  });

  _dueDashboardUtilsHasManyThrough['default'].define(M, 'match_surveys_feedback_filters_surveys', {
    foreignKey: 'filter',
    hasManyKey: 'filter_surveys',
    sourceKey: 'survey'
  });

  exports['default'] = M;
});