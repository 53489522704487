define('due-dashboard/mixins/embedded-jsonapi-records', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var EmbeddedRecordsMixin = _emberData['default'].EmbeddedRecordsMixin;
  exports['default'] = _ember['default'].Mixin.create(EmbeddedRecordsMixin, {
    _translateRelationshipToJSONAPI: function _translateRelationshipToJSONAPI(snapshot, json, relationship) {
      var serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
      if (json['relationships'] === undefined) {
        json['relationships'] = {};
      }
      if (json[serializedKey]) {
        json['relationships'][serializedKey] = relationship.kind === "belongsTo" ? json[serializedKey] : { "data": json[serializedKey] };
        delete json[serializedKey];
      }
    },

    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      this._super.apply(this, arguments);
      this._translateRelationshipToJSONAPI(snapshot, json, relationship);
    },

    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      this._super.apply(this, arguments);
      this._translateRelationshipToJSONAPI(snapshot, json, relationship);
    },

    normalize: function normalize(modelClass, resourceHash) {
      if (resourceHash.attributes) {
        this.normalizeUsingDeclaredMapping(modelClass, resourceHash.attributes);
      }

      if (resourceHash.relationships) {
        this.normalizeUsingDeclaredMapping(modelClass, resourceHash.relationships);
      }

      var data = {
        id: this.extractId(modelClass, resourceHash),
        type: this._extractType(modelClass, resourceHash),
        attributes: this.extractAttributes(modelClass, resourceHash),
        relationships: this.extractRelationships(modelClass, resourceHash)
      };

      this.applyTransforms(modelClass, data.attributes);

      return { data: data };
    }

  });
});