define('due-dashboard/components/scenarios/target-picker', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['scenario-action-margin'],
    sendingOptions: ['email', 'sms'],

    setTypeToEmail: computed(function () {
      this.set('model.sending_type', 'email');
    }),

    sendingOption: computed('model.sending_type', {
      get: function get() {
        var sp = this.get('model.sending_type');
        return sp === 'sms' ? 'sms' : !sp ? null : 'email';
      }, set: function set(_, value) {
        if (value === 'sms') {
          this.set('model.sending_type', 'sms');
        } else {
          this.set('model.sending_type', 'email');
        }
        return value;
      }
    })

  });
});