define('due-dashboard/components/scenarios/dispatch-delay-hours', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    daysDelay: computed('delay', {
      get: function get() {
        return !this.get('delay') ? 0 : Math.floor(this.get('delay') / 24);
      }, set: function set(_, value) {
        this.send('updateDelay', 'days', value);
        return value;
      }
    }),

    hoursDelay: computed('delay', {
      get: function get() {
        return !this.get('delay') ? 0 : this.get('delay') % 24;
      }, set: function set(_, value) {
        this.send('updateDelay', 'hours', value);
        return value;
      }
    }),

    actions: {
      updateDelay: function updateDelay(type, value) {
        if (type === 'days') {
          this.set('delay', Math.floor(value * 24) + parseInt(this.get('hoursDelay')));
        } else {
          this.set('delay', Math.floor(this.get('daysDelay') * 24) + parseInt(value));
        }
      }
    }

  });
});