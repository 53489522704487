define('due-dashboard/components/template-list', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;

  var sortTemplates = function sortTemplates(lastUsedTemplateId) {
    return function (t1, t2) {
      if (lastUsedTemplateId) {
        if (t1.get('id') === lastUsedTemplateId) {
          return -1;
        } else if (t2.get('id') === lastUsedTemplateId) {
          return 1;
        }
      }
      if (t1.get('is_favorite')) {
        return -1;
      } else if (t2.get('is_favorite')) {
        return 1;
      }
      return 0;
    };
  };

  exports['default'] = Component.extend({

    classNames: ['templates-list'],

    typeFiltersReadOnly: false,

    typeFilterOptions: [{ key: 'all', value: 'all' }, { key: 'sms', value: 'sms' }, { key: 'email', value: 'email' }],

    emailFilterOptions: computed('sortedTemplates', function () {
      var templates = this.get('sortedTemplates') || [],
          optionsArray = [{ key: 'all', value: 'all' }];
      var includeNps = false,
          includeHtml = false,
          includeBasic = false;

      templates.forEach(function (template) {
        if (template.get('sending_type') === 'nps') {
          includeNps = true;
        }
        if (template.get('sending_type') === 'html') {
          includeHtml = true;
        }
        if (template.get('sending_type') === 'basic') {
          includeBasic = true;
        }
      });

      if (includeBasic) {
        optionsArray.push({ key: 'basic', value: 'basic' });
      }
      if (includeNps) {
        optionsArray.push({ key: 'nps', value: 'nps' });
      }
      if (includeHtml) {
        optionsArray.push({ key: 'html', value: 'html' });
      }
      return optionsArray;
    }),

    store: _ember['default'].inject.service(),

    selected_id: "",
    has_other: true,
    has_favourite: true,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setTemplateType();
      this.set('templateEmailType', this.getWithDefault('templateEmailType', this.get('emailFilterOptions').findBy('key', 'all')));
    },

    setTemplateType: function setTemplateType() {
      var key = this.get('bulk_dispatch.sending_type');
      key = key ? key === 'sms' ? 'sms' : 'email' : 'all';
      this.set('templateType', this.getWithDefault('templateType', this.get('typeFilterOptions').findBy('key', key)));
    },

    setTemplateTypeEmail: computed(function () {
      this.set('templateType', this.get('typeFilterOptions').findBy('key', 'email'));
    }),

    templatesQuery: computed(function () {
      return this.store.findAll('template', { reload: true });
    }),

    sortedTemplates: computed('templatesQuery.isPending', 'survey.last_used_template.id', function () {
      var templates = this.get('templatesQuery');
      if (!templates.get('isPending')) {
        var lastUsedTemplateId = this.get('survey.last_used_template.id');
        return templates.toArray().sort(sortTemplates(lastUsedTemplateId));
      }
    }),

    filteredSortedTemplates: computed('sortedTemplates', 'templateType', 'templateEmailType', function () {
      var sortedTemplates = this.get('sortedTemplates'),
          template_type = this.get('templateType.value'),
          template_email_type = this.get('templateEmailType.value');

      if (sortedTemplates) {
        var res = sortedTemplates;
        if (template_type !== 'all') {
          res = sortedTemplates.filterBy('isSms', template_type === 'sms');
        }
        if (template_type !== 'sms' && template_email_type && template_email_type !== 'all' && template_type !== 'all') {
          res = res.filterBy('sending_type', template_email_type);
        }
        return res.filterBy('isDeleted', false);
      }
    }),

    actions: {
      select: function select(template) {
        var action = this.get('selectTemplate');
        if (action) {
          action(template);
        }
      },

      deleteTemplate: function deleteTemplate(template) {
        var _this = this;

        if (template !== null) {
          template.destroyRecord().then(function () {
            _this.notifyPropertyChange('filteredSortedTemplates');
          });
        }
        return false;
      },

      duplicateTemplate: function duplicateTemplate(template) {
        var _this2 = this;

        if (template !== null) {
          this.store.createRecord('template', {
            duplicate_from: template.get('id')
          }).save().then(function (savedTemplate) {
            savedTemplate.set('duplicate_from', undefined);
            _this2.notifyPropertyChange('templatesQuery');
          });
        }
      }
    }
  });
});