define('due-dashboard/initializers/fragment-array-transform', ['exports', 'due-dashboard/transforms/template-content'], function (exports, _dueDashboardTransformsTemplateContent) {
  exports.initialize = initialize;

  function initialize(application) {
    application.register('transform:-mf-fragment-array$template-content', _dueDashboardTransformsTemplateContent['default']);
  }

  exports['default'] = {
    name: 'fragment-array-transform',
    initialize: initialize
  };
});