define('due-dashboard/controllers/companies/questions', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var Controller = _ember['default'].Controller;
  var run = _ember['default'].run;
  exports['default'] = Controller.extend({

    filters: service(),
    searchedQuestion: null,
    hideMainQuestion: false,

    currentPage: 1,

    languageChanged: observer('i18n.locale', function () {
      this.send('filtersUpdated');
    }),

    pageChanged: observer('currentPage', function () {
      if (this.get('doNotQuery')) {
        this.set('doNotQuery', false);
      } else {
        this.launchQuery();
      }
    }),

    launchQuery: function launchQuery() {
      var _this = this;

      this.set('isLoading', true);
      var filters = this.get('filters').get('queryFilters');
      filters.filter.page_number = this.get('currentPage');
      return this.store.query('questions-benchmark', filters).then(function (a) {
        _this.set('model.main_question', a.get('firstObject.main_question'));
        _this.set('model.questions', a.get('firstObject.questions'));
        _this.set('model.pages_count', a.meta['page_count']);
      })['finally'](function () {
        return _this.set('isLoading', false);
      });
    },

    gauge_tooltip: computed('i18n.locale', 'currentAccount.content.company.satisfaction_index_100', function () {
      var tootlip_text = undefined;
      if (this.get('currentAccount.content.company.satisfaction_index_100')) {
        tootlip_text = 'overview.gauge_tooltip_100';
      } else {
        tootlip_text = 'overview.gauge_tooltip_10';
      }
      return this.get('i18n').t(tootlip_text);
    }),

    updateQuestionsList: function updateQuestionsList() {
      var _this2 = this;

      var filters = this.get('filters').get('queryFilters');
      var searchedQuestion = this.get('searchedQuestion');
      var hideFlag = false;
      if (searchedQuestion) {
        filters.filter.text = searchedQuestion;
        hideFlag = true;
      }
      this.set('isLoading', true);
      this.set('hideMainQuestion', false);
      return this.store.query('questions-benchmark', filters).then(function (a) {
        _this2.set('model.main_question', a.get('firstObject.main_question'));
        _this2.set('model.questions', a.get('firstObject.questions'));
        _this2.set('model.pages_count', a.meta['page_count']);
      })['finally'](function () {
        _this2.set('isLoading', false);
        _this2.set('hideMainQuestion', hideFlag);
      });
    },

    actions: {
      filtersUpdated: function filtersUpdated() {
        if (this.get('currentPage') !== 1) {
          this.set('doNotQuery', true);
          this.set('currentPage', 1);
        }
        return this.launchQuery();
      },

      debouncedSearchQuestion: function debouncedSearchQuestion() {
        run.debounce(this, this.updateQuestionsList, 500);
      }
    }
  });
});