define('due-dashboard/routes/companies/libraries/surveys/edit', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    controllerName: 'companies.libraries.surveys.new',

    model: function model() {
      return null;
    },

    afterModel: function afterModel(_, transition) {
      var controller = this.controllerFor('companies.libraries.surveys.new');
      var survey_id = transition.params['companies.libraries.surveys.edit'].survey_id;
      controller._resetPage();

      setTimeout(function () {
        controller.send('initModel', survey_id);
      }, 0);
    },

    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controllerFor('companies.libraries.surveys.new');
        var canTransit = controller._checkUnsavedChangesBeforeTransition();
        if (!canTransit) {
          transition.abort();
          window.history.pushState("", "", "/companies/libraries/surveys/edit/" + controller.get('surveyId'));
        } else {
          return true;
        }
      }
    }
  });
});