define('due-dashboard/models/change-end-tw-follow-scenario-action', ['exports', 'due-dashboard/models/scenario-action', 'ember-data', 'ember-cp-validations'], function (exports, _dueDashboardModelsScenarioAction, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    account_name: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports['default'] = _dueDashboardModelsScenarioAction['default'].extend(Validations, {
    account_name: attr({ defaultValue: '@' })
  });
});