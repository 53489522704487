define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/multiple-select-design', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    name: 'multi_select',
    BUTTON_STATES: [{ value: 'items', name: '_items' }, { value: 'dropdown', name: '_dropdown' }, { value: 'placeholder', name: '_placeholder' }],
    selectedStyleName: '',

    currentDesignOptions: computed('isLoading', 'designOptions', function () {
      return this.get('designOptions');
    }),

    state: computed(function () {
      return this.BUTTON_STATES[0];
    }),

    styleName: computed('selectedStyleName', function () {
      return '' + this.name + this.get('selectedStyleName');
    }),

    isNotPlaceholder: computed('state', function () {
      return this.get('state.value') !== 'placeholder';
    }),

    isNotSelect: computed('state', function () {
      return this.get('state.value') !== 'select';
    }),

    actions: {
      setState: function setState(newState) {
        this.set('state', newState);
        this.set('selectedStyleName', newState.name);
      }
    }
  });
});