define('due-dashboard/components/digests/redirection-button', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var PromiseArray = _emberData['default'].PromiseArray;
  exports['default'] = Component.extend({

    currentButtonTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.button_title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('comp.id') + '.button_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    typeOptions: ['diduenjoy', 'external'],

    pageOptions: computed('currentAccount.content.company.{show_answer_details,show_custom_stats,show_verbatim}', 'exportableReportQueries.[]', function () {
      var pageOptions = ['overview', 'graphs', 'exports', 'chats', 'surveys', 'templates'];

      if (this.get('currentAccount.content.company.show_answer_details')) {
        pageOptions.push('questions');
      }
      if (this.get('currentAccount.content.company.show_custom_stats')) {
        pageOptions.push('custom_stats');
        pageOptions.push('dl_custom_stats');
      }
      if (this.get('currentAccount.content.company.show_verbatim')) {
        pageOptions.push('verbatim');
      }
      if (this.get('exportableReportQueries.length') > 0) {
        pageOptions.push('dl_segment_table');
      }
      return pageOptions;
    }),

    init: function init() {
      this.set('exportableReportQueries', PromiseArray.create({ promise: this.store.findAll('exportable-report-query') }));
      if (!this.get('comp.options.type')) {
        this.set('comp.options.type', 'diduenjoy');
      }
      if (!this.get('comp.options.page')) {
        this.set('comp.options.page', 'overview');
      }
      this._super.apply(this, arguments);
    },

    actions: {
      mutType: function mutType(typeOption) {
        this.set('comp.options.type', typeOption);
        this.get('saveDigestDelay')();
      },

      mutPage: function mutPage(pageOption) {
        this.set('comp.options.page', pageOption);
        this.get('saveDigestDelay')();
      }
    }
  });
});