define('due-dashboard/components/tab-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tab-list',
    tabs: [],
    tabIndex: 0,

    actions: {
      registerTab: function registerTab(tab) {
        if (this.get('tabs.length') === 0) {
          tab.set('active', true);
        }
        this.get('tabs').addObject(tab);
        return false;
      }
    }
  });
});