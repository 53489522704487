define('due-dashboard/models/billing-profile', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    company_name: attr('string'),
    address: attr('string'),
    zip_code: attr('string'),
    city: attr('string'),
    country: attr('string'),
    recipient: attr('string')
  });
});