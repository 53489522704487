define('due-dashboard/controllers/companies/surveys/edit/bulk-dispatch/send-survey', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {
      sendSurvey: function sendSurvey() {
        var _this = this;

        var bulk_dispatch = this.get('model.bulk_dispatch');
        var msg = this.get('i18n').t('survey.email_due.recipients.send_count', {
          count: bulk_dispatch.get('csv_body.length')
        });
        if (confirm(msg)) {
          bulk_dispatch.set('template', this.get('model.template'));
          bulk_dispatch.set('active', true);
          var promise = bulk_dispatch.save();
          this.set('promise', promise);
          promise.then(function () {
            bulk_dispatch.unloadRecord();
            _this.send('afterSave');
          });
        }
      },

      prevStep: function prevStep() {
        this.send('previousStep');
      }
    }

  });
});