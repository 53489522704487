define('due-dashboard/components/tags/import-csv', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    exports['default'] = Component.extend({
        modalIsOpened: false,

        closeModal: function closeModal() {
            this.set('modalIsOpened', false);
        },

        actions: {
            openModal: function openModal() {
                this.set('modalIsOpened', true);
            },

            importTags: function importTags() {
                this.closeModal();
            },

            closeModal: function closeModal() {
                this.closeModal();
            }
        }
    });
});