define('due-dashboard/components/due-tab-bar', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Ex {{due-tab-bar
   *  tabs -> {Array<Object>} Tab information
   *      - icon: {string} Tio icon
   *      - label: {string} Text to display
   *      - suffix: {any} (Optional) Suffix to display after label
   *
   *  onChangeTab -> {function} Callback when user change tab
   *                 return: {index, Object}
   * }}
   */

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['due-tab-bar'],

    /* Optional */
    selected: 0,

    actions: {
      onClickTab: function onClickTab(index) {
        this.set('selected', index);
        var onChangetabe = this.get('onChangeTab');
        if (onChangetabe) onChangetabe(index, this.get('tabs')[index]);
      }
    }
  });
});
/**
 * @autor Eliott Martin
 * @email eliott@diduenjoy.com
 *
 * @created_at 17/06/2022
 * @decription Component navigation
 */