define('due-dashboard/components/graphs/due-histogram', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var computed = _ember['default'].computed;

  /**
   *  Full usage:
   *
   * {{grpahs/due-histogram
   *    title: {String} Histogram's title
   *    subtitle: {String} Histogram's subtitle
   *    range: {Object} Range for histogram
   *      - start: {DateTime} Date & Time for the begening
   *      - end: {DateTime} Date & Time for the end
   *      - stepType: {String} Range between each data (ex: day, week, month, year)
   *      - time: {Number} Number of range (ex: 1 month, 3 week, ...)
   *    count: {Object} Total of values with legend
   *      - legend: {String}
   *      - values: {Array<Object>} Total values
   *        * bgColor: {Color} Style background color
   *        * color: {Color} Style text color
   *        * icon: {Object} Label icon
   *          + name: {String} Tio name icon
   *          + color: {String} Icon color
   *        * total: {Number} Total value
   *    legend: {Array<Object>} Graph legend
   *      - title: {String} Legend title
   *      - color: {Color} Square color
   *    graph: {Object} Graph data for the histogram
   *      - stepType: {String} Range legend (ex: Week, Month, Year)
   *      - empty: {Boolean} True graph has no data to display
   *      - data: {Array<Object>}
   *        * values: {Array<Object>} Sicks values hight and label
   *          + label: {String} Value label
   *          + nbr: {Number} Value number
   *        * step: {DateTime} Date step
   *        * timeserieStep: {DateTime} Date time serie step
   *    loadingTimesExperience: {Number} (Optional) Number of loading wave
   *    labelEmptyGraph: {String} (Optional) Label displayed when graph has no data
   *    tooltipWidth: {Number} (Optional) Tooltip width
   * }}
   */

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['graphs', 'profile-histogram'],

    /** Public props */
    loadingTimesExperience: 5,
    labelEmptyGraph: '',
    tooltipWidth: 170,

    /** Private props */
    maxAxisY: 0,
    refDateSerialize: {
      'hour': function hour(mom, dateFormat) {
        return mom.format(dateFormat);
      },
      'day': function day(mom, dateFormat) {
        return mom.format(dateFormat);
      },
      'week': function week(mom, dateFormat) {
        return dateFormat.replace('%x%', mom.week());
      },
      'month': function month(mom, dateFormat) {
        return mom.format(dateFormat);
      },
      'year': function year(mom, dateFormat) {
        return mom.format(dateFormat);
      }
    },

    init: function init() {
      this.set('labelEmptyGraph', this.get('i18n').t('global.no_data_in_range'));
      this._super();
    },

    /** Calculate date range for the histogram */
    dateRange: computed('range', function () {
      var _get = this.get('range');

      var start = _get.start;
      var end = _get.end;
      var stepType = _get.stepType;
      var time = _get.time;

      if (start && end && stepType && time) {
        return end.split('T')[0] === moment().format().split('T')[0] ? this.get('i18n').t('tag_analysis.step_types.past.' + stepType, { time: time }) : this.get('i18n').t('tag_analysis.step_types.range', { startDate: moment(start).format('LL'), endDate: moment(end).format('LL') });
      }
    }),

    /** Check if data are still loading */
    graphLoading: computed('graph', function () {
      return !this.get('graph');
    }),

    /** Check if data are loaded but empty */
    graphEmpty: computed('graph', function () {
      var graph = this.get('graph');
      return !graph || graph && (graph.empty || graph.data.length === 0);
    }),

    /** Calculat the Y axis for the graph */
    axisY: computed('graph', function () {
      var graph = this.get('graph');
      if (!graph || graph.data.length === 0) return { max: 0, mid: 0, min: 0 };

      var max = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = graph.data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var data = _step.value;

          var total = data.values.reduce(function (a, b) {
            return a + b.nbr;
          }, 0);
          if (total > max) max = total;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.set('maxAxisY', max);
      return { max: max, mid: max / 2, min: 0 };
    }),

    /** Build sticks for the histogram */
    sticks: computed('graph', 'maxAxisY', function () {
      var _this = this;

      var graph = this.get('graph');
      if (!graph) return [];
      var sticks = [];
      var stepType = graph.stepType;
      var data = graph.data;
      var dateFormat = this.get('i18n').t('tag_analysis.due_histogram.date_formats.' + stepType).string;
      var splitRatio = stepType === 'hour' ? Math.round(data.length / 6) : Math.round(data.length / 4);
      var dateSerialize = this.get('refDateSerialize')[stepType];
      var index = -1;

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        var _loop = function () {
          var stick = _step2.value;

          index += 1;
          var date = dateSerialize ? dateSerialize(moment(stick.timeserieStep), dateFormat) : '';
          var total = stick.values.reduce(function (a, b) {
            return a + b.nbr;
          }, 0);
          sticks.push({
            values: stick.values.map(function (value, index) {
              return _extends({}, value, {
                percent: value.nbr !== 0 ? (value.nbr / total * 100).toFixed() : 0,
                marginBottom: stick.values.findIndex(function (v, i) {
                  return i > index && v.nbr !== 0;
                }) !== -1 ? '5px' : '0px'
              });
            }),
            height: Math.min(total * 100 / _this.get('maxAxisY'), 100) || 0,
            date: index % splitRatio === 0 ? date : '',
            popupDate: date
          });
        };

        for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2['return']) {
            _iterator2['return']();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return sticks;
    }),

    /** Avanced style for graph */
    graphStyle: computed('graph', function () {
      var graph = this.get('graph');
      if (!graph) return { width: 0, widthDate: 0 };
      var stepType = graph.stepType;
      var data = graph.data;

      var splitRatio = stepType === 'hour' ? Math.round(data.length / 6) : Math.round(data.length / 4);
      return { width: 100 / data.length, widthDate: 100 / splitRatio };
    }),

    actions: {
      showTooltipster: function showTooltipster() {
        $(this.$('.graph-card .graph-card-content .graph-card-content-pre-container .graph-card-content-container .formatted-graph-container .formatted-graph-row-hover:hover .formatted-graph-row')).tooltipster('show');
      },
      hideTooltipster: function hideTooltipster() {
        $(this.$('.graph-card .graph-card-content .graph-card-content-pre-container .graph-card-content-container .formatted-graph-container .formatted-graph-row')).tooltipster('hide');
      }
    }
  });
});
/**
 * @fileoverview {{due-histogram}} Histogram component
 * @author Eliott Martin <eliott@diduenjoy.com>
 * @figma https://www.figma.com/file/yyM8UbYEpUOiNn192BFktX/Tags-Analysis?node-id=690%3A19469
 * @company Diduenjoy
 */