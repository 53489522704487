define('due-dashboard/initializers/model-fragments', ['exports', 'ember-data-model-fragments', 'ember-data-model-fragments/transforms/fragment', 'ember-data-model-fragments/transforms/fragment-array', 'ember-data-model-fragments/transforms/array'], function (exports, _emberDataModelFragments, _emberDataModelFragmentsTransformsFragment, _emberDataModelFragmentsTransformsFragmentArray, _emberDataModelFragmentsTransformsArray) {
  exports['default'] = {
    name: 'fragmentTransform',
    before: 'ember-data',

    initialize: function initialize(application) {
      application.register('transform:fragment', _emberDataModelFragmentsTransformsFragment['default']);
      application.register('transform:fragment-array', _emberDataModelFragmentsTransformsFragmentArray['default']);
      application.register('transform:array', _emberDataModelFragmentsTransformsArray['default']);
    }
  };
});
// Import the full module to ensure monkey-patchs are applied before any store
// instances are created. Sad face for side-effects :(