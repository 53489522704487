define('due-dashboard/components/web-snippet-code-preview', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['web-snippet-code-preview'],
    expended: false,

    snippetUrl: _dueDashboardConfigEnvironment['default'].EmberENV.SNIPPET_URL,

    iframe: computed('model.{id,segment_filter.name}', 'snippetUrl', function () {
      var filter = this.get('model.segment_filter.name');
      var res = '<iframe  src="' + this.get('snippetUrl') + '/' + this.get('model.id');
      if (filter) {
        res += '?filters[' + filter + ']={{value}}';
      }
      res += '" scrolling="no"></iframe>';
      if (filter) {
        res += '\n<!-- replace the {{value}} with your page\'s related ' + filter + ' -->';
      }
      return res;
    }),

    actions: {
      clickedAction: function clickedAction() {
        var _this = this;

        this.set('clicked', true);
        this.$('.copy-btn')[0].style.backgroundColor = '#2699da';
        run.later(function () {
          _this.$('.copy-btn')[0].style.backgroundColor = '';
          _this.set('clicked', false);
        }, 1000);
      }
    }
  });
});