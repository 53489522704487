define('due-dashboard/controllers/settings/integrations/stripe', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/controllers/settings/integrations/integration-parent'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardControllersSettingsIntegrationsIntegrationParent) {
  var computed = _ember['default'].computed;
  exports['default'] = _dueDashboardControllersSettingsIntegrationsIntegrationParent['default'].extend({

    submitEnabled: computed.notEmpty('model.company_integration.post_charge_survey.id'),

    login_url: computed('model.company.id', function () {
      return _dueDashboardConfigEnvironment['default'].EmberENV.STRIPE_CONNECT_URL + '?state=' + this.get('model.company.id');
    })

  });
});