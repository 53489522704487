define('due-dashboard/components/donut-chart', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    classNames: ['donut-chart'],
    classNameBindings: ['hidden'],
    hasMultipleLegends: false,

    hidden: computed.equal('totalCount', 0),

    defaultSelectedA: computed('filteredData', 'defaultSelectedKey', function () {
      var _this = this;

      return this.get('filteredData').filter(function (stat) {
        return stat.key === _this.get('defaultSelectedKey');
      });
    }),

    selected: computed.oneWay('defaultSelectedA.firstObject'),

    chartDonut: {
      expand: false,
      label: {
        show: false
      },
      width: 8
    },

    filteredData: computed('data.[]', 'hasMultipleLegends', 'defaultSelectedKey', 'colors', function () {
      var _this2 = this;

      var a = this.get('data').filter(function (stat) {
        var keep = get(stat, 'name') !== 'total';
        if (_this2.get('hasMultipleLegends')) {
          keep = keep && get(stat, 'count') !== 0;
        }
        keep = keep || get(stat, 'name') === _this2.get('defaultSelectedKey');
        return keep;
      }).map(function (stat) {
        return {
          color: _this2.get('colors')[get(stat, 'name')],
          count: get(stat, 'count'),
          key: get(stat, 'name')
        };
      });
      return a;
    }),

    totalA: computed.filterBy('data', 'name', 'total'),
    totalCount: computed.alias('totalA.firstObject.count'),

    c3chart: computed({
      get: function get() {},
      set: function set(_, c3chart) {
        this.set('c3chartInstance', c3chart);
      }
    }),

    selectedChanged: observer('selected.key', 'c3chartInstance', function () {
      if (this.get('c3chartInstance.select')) {
        this.get('c3chartInstance').focus([this.get('selected.key')]);
      }
    }),

    percentage: computed('selected.count', 'totalCount', function () {
      return (this.get('selected.count') / this.get('totalCount') * 100).toFixed(0);
    }),

    chartData: computed('filteredData', function () {
      var colors = this.get('filteredData').reduce(function (h, e) {
        h[e.key] = e.color;return h;
      }, {});
      return {
        type: 'donut',
        columns: this.get('filteredData').map(function (d) {
          return [d.key, d.count];
        }),
        order: null,
        sort: false,
        colors: colors
      };
    }),

    tooltip: computed('i18n.locale', function () {
      return {
        show: true,
        format: {
          name: function name(value) {
            return value;
          },
          value: function value(_value, ratio) {
            var percentage = Math.round(ratio * 100);
            return percentage + ' % (' + _value + ')';
          }
        }
      };
    }),

    indexOfSelectedData: computed('filteredData.[]', 'selected', function () {
      return this.get('filteredData').indexOf(this.get('selected'));
    }),

    actions: {
      switchData: function switchData(offset) {
        var idx = this.get('indexOfSelectedData');
        idx = (idx + offset) % this.get('filteredData.length');
        if (idx === -1) {
          idx = this.get('filteredData.length') - 1;
        }
        this.set('selected', this.get('filteredData')[idx]);
      }
    }
  });
});