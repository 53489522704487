define('due-dashboard/controllers/companies/surveys/edit/bulk-dispatch/template', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  /* global _ */

  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({
    queryParams: ['template_id'],
    template_id: null,

    missingLanguages: function missingLanguages(template) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        var languages = _this.get('model.bulk_dispatch.csv_body').map(function (b) {
          return b.language;
        }).filter(function (a) {
          return a;
        }).uniq();
        var _resolve = function _resolve() {
          return resolve([languages, _.difference(languages, (template.get('text_contents') || []).mapBy('language.tag')).length]);
        };
        if (languages.length) {
          _resolve();
        }
        _this.store.findRecord('survey-revision', _this.get('model.bulk_dispatch.survey_revision.id')).then(function (sr) {
          sr.get('survey_languages').then(function (sl) {
            languages = sl.mapBy('language.tag');
            _resolve();
          });
        });
      });
    },

    actions: {

      editTemplate: function editTemplate(template, languages) {
        var _this2 = this;

        var _resolve = function _resolve() {
          return _this2.send('edit', template, _this2.get('model.bulk_dispatch_id'), languages.join(','));
        };
        template = template || this.store.createRecord('template');
        if (languages) {
          return _resolve();
        }
        this.missingLanguages(template).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 1);

          var _languages = _ref2[0];

          languages = _languages;
          _resolve();
        });
      },

      selectTemplate: function selectTemplate(template) {
        var _this3 = this;

        this.missingLanguages(template).then(function (_ref3) {
          var _ref32 = _slicedToArray(_ref3, 2);

          var languages = _ref32[0];
          var missing = _ref32[1];

          if (missing) {
            return _this3.send('editTemplate', template, languages);
          }
          _this3.set('template_id', template.get('id'));
          _this3.transitionToRoute('companies.surveys.edit.bulk-dispatch.send-survey', _this3.get('model.bulk_dispatch_id'), _this3.get('template_id'));
        });
      }
    }
  });
});