define("due-dashboard/templates/components/api-custom-header", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 5
          }
        },
        "moduleName": "due-dashboard/templates/components/api-custom-header.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("li");
        dom.setAttribute(el1, "class", "http-header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        return morphs;
      },
      statements: [["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [4, 12], [4, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "key of the header", "type", "text"], ["loc", [null, [3, 4], [7, 6]]], 0, 0], ["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "model.value", ["loc", [null, [11, 12], [11, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "value of the header", "type", "text"], ["loc", [null, [10, 4], [14, 6]]], 0, 0], ["inline", "due-btn", [], ["icon", "tio-clear", "size", "medium", "intent", "tertiary", "action", ["subexpr", "@mut", [["get", "deleteHeader", ["loc", [null, [21, 13], [21, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 4], [22, 6]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});